
import { computed, defineComponent, PropType } from "vue";
import BaseCustomDropdown from "@/components/dropdowns/BaseCustomDropdown/BaseCustomDropdown.vue";
import { DropdownItem } from "@/ts/app/dropdown-item";
import { ColorLabel, labelToColor } from "@/ts/objects/value/color-label";

export default defineComponent({
  name: "MenuDropdown",
  components: { BaseCustomDropdown },
  props: {
    items: { type: Array as PropType<DropdownItem[]>, required: true },
    enabled: { type: Boolean, required: true },

    color: {
      type: String as PropType<ColorLabel>,
      default: "light-black",
      required: false,
    },
    size: { type: Number, default: 26, required: false },
    iconSize: { type: Number, default: 15, required: false },

    optionsZIndex: { type: Number, default: 10, required: false },
    optionsWidth: { type: Number, default: 120, required: false },
    optionsAlign: {
      type: String as PropType<"left" | "center" | "right">,
      default: "left",
      required: false,
    },
    /**
     * optionsAlignを適用した後、さらに左右にずらしたければ、指定する。
     * 正の値だと右にずれる。
     */
    optionsHorizontalPosition: { type: Number, default: 0, required: false },

    onSelect: {
      type: Function as PropType<(value: string | null) => void>,
      required: true,
    },
  },
  setup(props) {
    return {
      styles: computed(() => ({
        "--color": labelToColor(props.color),
        "--size": `${props.size}px`,
        "--icon-size": `${props.iconSize}px`,
      })),
    };
  },
});
