import { UserColumnVisibility } from "@/ts/app/columns/visibility/user-column-visibility";
import {
  guardianProfileColumnGroupName,
  GuardianProfileColumnGroupName,
} from "@/ts/app/columns/def/guardian/guardian-profile-column";
import {
  guardianNotesColumnGroupName,
  GuardianNotesColumnGroupName,
} from "@/ts/app/columns/def/guardian/guardian-notes-column";
import { PartialDeep } from "type-fest";
import {
  GuardianListNotesColumnId,
  GuardianListProfileColumnId,
} from "@/ts/app/columns/user-list/guardian-list-columns";

export type GuardianColumnVisibilityAll = {
  profileColumnVisibility: GuardianProfileColumnVisibility;
  notesColumnVisibility: GuardianNotesColumnVisibility;
};

export type GuardianColumnVisibility =
  | GuardianProfileColumnVisibility
  | GuardianNotesColumnVisibility;

export type GuardianProfileColumnVisibility = UserColumnVisibility<
  GuardianProfileColumnGroupName,
  GuardianListProfileColumnId
>;

export type GuardianNotesColumnVisibility = UserColumnVisibility<
  GuardianNotesColumnGroupName,
  GuardianListNotesColumnId
>;

/**
 * デフォルトの保護者プロフィール列表示状態を取得する。
 * @param checked trueならすべてチェック済、falseならすべて未チェックの状態を返す。
 */
export function defaultGuardianProfileColumnVisibility(
  checked: boolean,
): GuardianProfileColumnVisibility {
  return {
    groupName: guardianProfileColumnGroupName,
    state: {
      userId: checked,
      googleMail: checked,
      name1: checked,

      children: checked,

      // Userのnameが、name1。
      nameKana1: checked,
      familyName1: checked,
      givenName1: checked,
      name2: checked,
      nameKana2: checked,
      familyName2: checked,
      givenName2: checked,
      email1: checked,
      email2: checked,
      mobilePhone1: checked,
      mobilePhone2: checked,
      landlinePhone1: checked,
      landlinePhone2: checked,
      job1: checked,
      job2: checked,
      workPlace1: checked,
      workPlace2: checked,
      pictureGcsUrl1: checked,
      pictureGcsUrl2: checked,
      emergencyContactRelationship1: checked,
      emergencyContactPhone1: checked,
      emergencyContactRelationship2: checked,
      emergencyContactPhone2: checked,
      emergencyContactRelationship3: checked,
      emergencyContactPhone3: checked,
    },
  };
}

/**
 * デフォルトの保護者備考列表示状態を取得する。
 * @param checked trueならすべてチェック済、falseならすべて未チェックの状態を返す。
 */
export function defaultGuardianNotesColumnVisibility(
  checked: boolean,
): GuardianNotesColumnVisibility {
  return {
    groupName: guardianNotesColumnGroupName,
    state: {
      note01: checked,
      note02: checked,
      note03: checked,
      note04: checked,
      note05: checked,
      note06: checked,
      note07: checked,
      note08: checked,
      note09: checked,
      note10: checked,
    },
  };
}

/**
 * デフォルトの保護者列表示状態を取得する。
 * @param checked trueならすべてチェック済、falseならすべて未チェックの状態を返す。
 */
export function defaultGuardianColumnVisibility(
  checked: boolean,
): GuardianColumnVisibilityAll {
  return {
    profileColumnVisibility: defaultGuardianProfileColumnVisibility(checked),
    notesColumnVisibility: defaultGuardianNotesColumnVisibility(checked),
  };
}

export function getGuardianColumnVisibility(
  partial: PartialDeep<GuardianColumnVisibilityAll> = {},
): GuardianColumnVisibilityAll {
  return {
    profileColumnVisibility: {
      groupName: guardianProfileColumnGroupName,
      state: {
        ...defaultGuardianProfileColumnVisibility(false).state,
        ...partial?.profileColumnVisibility?.state,
      },
    },
    notesColumnVisibility: {
      groupName: guardianNotesColumnGroupName,
      state: {
        ...defaultGuardianNotesColumnVisibility(false).state,
        ...partial?.notesColumnVisibility?.state,
      },
    },
  };
}
