import log from "loglevel";
import { DebugConfig } from "@/ts/init/initialize-app";
import { LoginInfo } from "@/ts/objects/value/login-info";

/**
 * AuthServiceを取得する。
 * デバッグモードならモックを返す。
 */
export async function createAuthService(
  clientId: string | null,
  debugConfig: DebugConfig,
): Promise<AuthService> {
  if (debugConfig.debugging) {
    return new AuthServiceMock({
      isLoggedIn: true,
      googleIdToken: "fake-google-id-token",
      userId: debugConfig.fakeUserInfo.userId,
      googleMail: debugConfig.fakeUserInfo.googleMail,
      imageUrl: debugConfig.fakeUserInfo.imageUrl,
    });
  }

  if (clientId === null)
    throw new Error("getGapiAuthInstance: clientId must not be null.");

  try {
    await new Promise((resolve) => gapi.load("auth2", resolve)); // gapi.auth2を使う前に、loadが必要。
    await new Promise((resolve, reject) =>
      gapiAuth2InitWrapper(clientId, resolve, reject),
    );
    log.debug("Initialized gapi.auth2.");

    return new AuthServiceImpl();
  } catch (e: unknown) {
    if (e instanceof Error) {
      log.error(
        `getGapiAuthInstance: Failed to sign in to Google: name=${e.name}, message=${e.message}`,
      );
      throw new Error(
        `getGapiAuthInstance: Failed to sign in to Google: name=${e.name}, message=${e.message}`,
      );
    } else {
      throw e;
    }
  }
}

export abstract class AuthService {
  abstract goLogin(): Promise<void>;

  abstract changeGoogleAccount(): Promise<void>;

  abstract getLoginInfo(forceReloadAuthResponse: boolean): Promise<LoginInfo>;
}

export class AuthServiceImpl extends AuthService {
  readonly authInstance: gapi.auth2.GoogleAuth;

  constructor() {
    super();
    this.authInstance = gapi.auth2.getAuthInstance();
  }

  async goLogin(this: this): Promise<void> {
    await this.authInstance.signIn();
  }

  async changeGoogleAccount(this: this): Promise<void> {
    log.debug(`AuthServiceImpl: changeGoogleAccount`);
    await this.authInstance.signIn({
      prompt: "select_account",
    });
  }

  async getLoginInfo(
    this: this,
    forceReloadAuthResponse: boolean,
  ): Promise<LoginInfo> {
    try {
      if (!this.authInstance.isSignedIn.get())
        return {
          isLoggedIn: false,
          googleIdToken: null,
          userId: null,
          googleMail: null,
          imageUrl: null,
        };

      const googleUser = this.authInstance.currentUser.get();

      const googleIdToken = forceReloadAuthResponse
        ? (await googleUser.reloadAuthResponse()).id_token
        : googleUser.getAuthResponse().id_token;

      const profile = googleUser.getBasicProfile();
      const userId = profile.getId();
      const googleMail = profile.getEmail();
      const imageUrl = profile.getImageUrl();

      log.debug(
        `AuthServiceImpl.getLoginInfo: userId=${userId}, googleIdToken=${googleIdToken}`,
      );

      // Vue.prototype.$googleIdToken = googleIdToken;
      // await this.setAppUser(userId);

      return {
        isLoggedIn: true,
        googleIdToken,
        userId,
        googleMail,
        imageUrl,
      };
    } catch (e: unknown) {
      if (e instanceof Error) {
        log.error(
          `AuthService.getLoginInfo: Failed to sign in to Google: name=${e.name}, message=${e.message}`,
        );
      } else {
        log.error(`AuthService.getLoginInfo: Unknown error!: ${e}`);
      }

      return {
        isLoggedIn: false,
        googleIdToken: null,
        userId: null,
        googleMail: null,
        imageUrl: null,
      };
    }
  }
}

export class AuthServiceMock extends AuthService {
  constructor(
    private fakeLoginInfo: LoginInfo = {
      isLoggedIn: true,
      googleIdToken: "fake-google-id-token",
      userId: "admin000",
      googleMail: "admin000@seto-solan.ed.jp",
      imageUrl: "https://avatars.dicebear.com/api/bottts/anbtrewqhb.svg",
    },
  ) {
    super();
  }

  async goLogin(this: this): Promise<void> {
    log.debug(`AuthServiceMock: goLogin`);
  }

  async changeGoogleAccount(this: this): Promise<void> {
    log.debug(
      `AuthServiceMock.changeGoogleAccount: You cannot change the account in debug mode.`,
    );
  }

  async getLoginInfo(
    this: this,
    _forceReloadAuthResponse: boolean,
  ): Promise<LoginInfo> {
    return this.fakeLoginInfo;
  }
}

// gapi.auth2.initが返すのはPromiseではない。古いライブラリなので・・・。
// 参照: https://stackoverflow.com/questions/46720597/javascript-async-function-that-calls-and-waits-for-google-auth
// よって、ラッパーを作る。
function gapiAuth2InitWrapper(
  clientId: string,
  onSucceed: (value: unknown) => void,
  onFail: (err: any) => void,
) {
  gapi.auth2
    .init({
      client_id: clientId,
      fetch_basic_profile: true,
      hosted_domain: "seto-solan.ed.jp", // この制限はUI上のものでしかない。
      ux_mode: "redirect",
      redirect_uri: `https://${window.location.host}`,
    })
    .then(onSucceed)
    .catch(onFail);
}
