import { computed, ComputedRef, Ref, ref } from "vue";
import { useRoute } from "vue-router";
import { asIntOrNull, asTextOrNull } from "@/ts/utils/common-util";
import { asPageNameOrNull, PageName } from "@/ts/app/pages/pages";
import {
  asStudentSubPageNameOrNull,
  StudentSubPageName,
} from "@/ts/app/pages/student-sub-pages";
import {
  SearchConditionClass,
  searchConditionClassFromQueryParams,
} from "@/ts/objects/search-condition/search-condition-class";
import {
  StudentSortCondition,
  studentSortConditionFromQueryParams,
} from "@/ts/objects/search-condition/student-sort-condition";
import {
  SearchConditionInSchoolState,
  searchConditionInSchoolStateFromQueryParams,
} from "@/ts/objects/search-condition/search-condition-in-school-state";
import { DateValue } from "@/ts/objects/value/date-value";
import {
  NumResultsCondition,
  numResultsConditionFromQueryParams,
} from "@/ts/objects/search-condition/num-results-condition";

export function useStudentRoute(
  today: DateValue,
  currentSchoolYear: number,
): {
  pageName: ComputedRef<PageName | null>;
  subPageName: ComputedRef<StudentSubPageName | null>;
  userId: ComputedRef<string | null>;
  searchPageNum: Ref<number>;
  queryText: Ref<string>;
  schoolYear: Ref<number>;
  searchConditionClass: Ref<SearchConditionClass>;
  searchConditionInSchoolState: Ref<SearchConditionInSchoolState>;
  searchConditionSort: Ref<StudentSortCondition>;
  searchConditionNumResults: Ref<NumResultsCondition>;
} {
  // APIリファレンス: https://next.router.vuejs.org/api/#routelocationnormalized
  const route = useRoute();
  const splitPath = computed(() => route.path.split("/"));
  const query = computed(() => route.query);
  const params = computed(() => route.params);

  const pageName = computed(() => {
    return asPageNameOrNull(splitPath.value[1]);
  });
  const subPageName = computed(() => {
    return asStudentSubPageNameOrNull(splitPath.value[2]);
  });

  const userId = computed(() => asTextOrNull(params.value["userId"]));
  const searchPageNum = ref(asIntOrNull(query.value["page"], 1, 99999) ?? 1);

  const queryText = ref(asTextOrNull(query.value["q"]) ?? "");

  const schoolYear = ref(
    asIntOrNull(query.value["schoolYear"], 1, 99999) ?? currentSchoolYear,
  );

  const searchConditionClass = ref<SearchConditionClass>(
    searchConditionClassFromQueryParams(schoolYear.value, query.value),
  );
  const searchConditionInSchoolState = ref<SearchConditionInSchoolState>(
    searchConditionInSchoolStateFromQueryParams(today, query.value),
  );
  const searchConditionSort = ref<StudentSortCondition>(
    studentSortConditionFromQueryParams(schoolYear.value, query.value),
  );
  const searchConditionNumResults = ref<NumResultsCondition>(
    numResultsConditionFromQueryParams(query.value),
  );

  return {
    pageName,
    subPageName,
    userId,
    searchPageNum,
    queryText,
    schoolYear,
    searchConditionClass,
    searchConditionInSchoolState,
    searchConditionSort,
    searchConditionNumResults,
  };
}
