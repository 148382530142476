import { computed, ComputedRef } from "vue";
import { asPageNameOrNull, PageName } from "@/ts/app/pages/pages";
import { asSchoolTypeOrNull, SchoolType } from "@/ts/objects/value/school-type";
import {
  asCurriculumSubPageNameOrNull,
  CurriculumSubPageName,
} from "@/ts/app/pages/curriculum-sub-pages";
import { useRoute } from "vue-router";
import { asIntOrNull } from "@/ts/utils/common-util";

export function useCurriculumRoute(currentSchoolYear: number): {
  pageName: ComputedRef<PageName | null>;
  subPageName: ComputedRef<CurriculumSubPageName | null>;
  schoolType: ComputedRef<SchoolType>;
  schoolYear: ComputedRef<number>;
} {
  const route = useRoute();
  const splitPath = computed(() => route.path.split("/"));
  const query = computed(() => route.query);

  const pageName = computed(() => {
    return asPageNameOrNull(splitPath.value[1]);
  });
  const subPageName = computed(() => {
    return asCurriculumSubPageNameOrNull(splitPath.value[2]);
  });

  const schoolType = computed(
    () => asSchoolTypeOrNull(query.value["schoolType"]) ?? "elementary",
  );
  const schoolYear = computed(
    () => asIntOrNull(query.value["schoolYear"], 1, 99999) ?? currentSchoolYear,
  );

  return {
    pageName,
    subPageName,
    schoolType,
    schoolYear,
  };
}
