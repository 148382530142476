import { combineResults, Result } from "@/ts/app/result";
import { ObjectInternalName, ObjectName } from "@/ts/app/object-name";
import { errors } from "@/ts/app/error/app-error";
import { isNullish } from "@/ts/utils/common-util";

/**
 * CSV行の列名を列IDに変換する。
 * その際、列名または列IDが不正であればエラーを吐く。
 *
 * @param csvObjectName CSVオブジェクト名。エラーメッセージ等で用いる。
 * @param rawRow 読み込んだままの行。
 * @param nameToId 列名から列IDへのマッピング。
 * @param validColIds 有効な列ID。これ以外が含まれていたらエラーとする。
 */
export function validateAndRenameColsOfRow<RawRow>(
  csvObjectName: ObjectName,
  rawRow: RawRow,
  nameToId: Map<string, ObjectInternalName>,
  validColIds: readonly ObjectInternalName[],
): Result<Record<ObjectInternalName, string>> {
  const colNames = Object.keys(rawRow);

  const extraColFound = colNames.some(
    (colName) => colName === "__parsed_extra",
  );
  if (extraColFound) {
    return {
      ok: false,
      error: errors.validationFailed.other(
        undefined,
        csvObjectName,
        `${csvObjectName.i} has extra columns beyond headers`,
        `CSVのフォーマットが不正です。余分な列を含む行が存在する可能性があります。`,
      ),
    };
  }

  const checkResult: Result<true[]> = combineResults(
    colNames.map((colName) => {
      const colId = nameToId.get(colName);
      if (isNullish(colId))
        return {
          ok: false,
          error: errors.validationFailed.other(
            undefined,
            csvObjectName,
            `${csvObjectName.i} has unrecognized column name ${colName}`,
            `CSVが使用できない列名（${colName}）を含んでいます。`,
          ),
        };

      if (!validColIds.includes(colId)) {
        return {
          ok: false,
          error: errors.validationFailed.other(
            undefined,
            csvObjectName,
            `${csvObjectName.i} has invalid column ${colId} (${colName})`,
            `CSVが使用できない列名（${colName}）を含んでいます。`,
          ),
        };
      }

      return { ok: true, data: true };
    }),
  );
  if (!checkResult.ok) return checkResult;

  const row: Record<ObjectInternalName, string> = Object.fromEntries(
    Object.entries(rawRow).map(([colName, value]) => [
      nameToId.get(colName),
      value,
    ]),
  );

  return { ok: true, data: row };
}
