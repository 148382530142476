
import { defineComponent, PropType } from "vue";
import EditSaveButton from "@/components/buttons/EditSaveButton/EditSaveButton.vue";
import EditCancelButton from "@/components/buttons/EditCancelButton/EditCancelButton.vue";

export default defineComponent({
  name: "EditButtonSet",
  components: { EditCancelButton, EditSaveButton },
  props: {
    editing: { type: Boolean, required: true },

    enabled: { type: Boolean, default: true },

    onClickEdit: { type: Function as PropType<() => void>, required: true },
    onClickSave: { type: Function as PropType<() => void>, required: true },
    onClickCancel: { type: Function as PropType<() => void>, required: true },
  },
});
