
import { computed, defineComponent, PropType } from "vue";
import CollapseButtonLR from "@/components/buttons/CollapseButtonLR/CollapseButtonLR.vue";
import UserColumnsSelectSection from "@/components/other/UserColumnsSelectSection/UserColumnsSelectSection.vue";
import { UserColumnVisibility } from "@/ts/app/columns/visibility/user-column-visibility";
import { TeacherColumnVisibilityAll } from "@/ts/app/columns/visibility/teacher-column-visibility";
import { names, ObjectInternalName } from "@/ts/app/object-name";
import { teacherListProfileColumnIds } from "@/ts/app/columns/user-list/teacher-list-columns";
import { getTeacherColumnDefsGrouped } from "@/ts/app/columns/def/teacher/teacher-column";

export default defineComponent({
  name: "TeacherColumnsSelect",
  components: { CollapseButtonLR, UserColumnsSelectSection },
  props: {
    schoolYear: { type: Number, required: true },
    columnVisibility: {
      type: Object as PropType<TeacherColumnVisibilityAll>,
      required: true,
    },
    open: { type: Boolean, required: true },

    onClickCollapseButton: {
      type: Function as PropType<() => void>,
      required: true,
    },
    onChangeColumnVisibility: {
      type: Function as PropType<
        (state: UserColumnVisibility<ObjectInternalName, string>) => void
      >,
      required: true,
    },
  },
  setup(props) {
    const columnDefsGrouped = computed(() =>
      getTeacherColumnDefsGrouped(props.schoolYear),
    );

    const profileSectionTitle =
      names[props.columnVisibility.profileColumnVisibility.groupName].d;
    const profileColumnIds: string[] = teacherListProfileColumnIds;
    const profileColumnDefs = computed(() =>
      columnDefsGrouped.value.profileColumnDefGroup.columns.filter((c) =>
        profileColumnIds.includes(c.id),
      ),
    );

    return {
      profileSectionTitle,
      profileColumnDefs,
    };
  },
});
