
import { computed, defineComponent, PropType } from "vue";
import CurriculumEditTableCell from "@/views/curriculum/CurriculumEdit/CurriculumEditTable/CurriculumEditTableRow/CurriculumEditTableRowSection/CurriculumEditTableCell/CurriculumEditTableCell.vue";
import {
  CurriculumEvalType,
  curriculumEvalTypes,
} from "@/ts/objects/value/curriculum-eval-type";
import { CheckFailed } from "@/ts/app/error/check-error";
import { ColorLabel, labelToColor } from "@/ts/objects/value/color-label";
import AddButtonSmall from "@/components/buttons/AddButtonSmall/AddButtonSmall.vue";
import {
  createNewCurriculumEditItemData,
  CurriculumEditItemData,
  CurriculumEditRowSectionData,
} from "@/views/curriculum/CurriculumEdit/curriculum-edit-data";
import { arrayMoveOne, hasValue, updateFirst } from "@/ts/utils/common-util";

export default defineComponent({
  name: "CurriculumEditTableRowSection",
  components: { AddButtonSmall, CurriculumEditTableCell },
  props: {
    /**
     * データ。
     * 選択中の年度、学校タイプ、学年、evalTypeの教科のみが含まれる前提。ここでチェックはしない。
     */
    data: {
      type: Object as PropType<
        CurriculumEditRowSectionData<CurriculumEvalType>
      >,
      required: true,
    },

    editing: { type: Boolean, required: true },

    onUpdate: {
      type: Function as PropType<
        (data: CurriculumEditRowSectionData<CurriculumEvalType>) => void
      >,
      required: true,
    },
  },
  setup(props) {
    const evalTypeJapanese = computed(
      () => curriculumEvalTypes[props.data.evalType],
    );
    const bgColor = computed<ColorLabel>(() => {
      switch (props.data.evalType) {
        case "numeric":
          return "table-cell-0";
        case "essay":
          return "light-yellow";
        default:
          throw new CheckFailed();
      }
    });

    const onUpdateInternal = (
      updated: CurriculumEditItemData<CurriculumEvalType>[],
    ) =>
      props.onUpdate({ evalType: props.data.evalType, curriculums: updated });

    const updateName = (
      evalType: CurriculumEvalType,
      clientSideId: string,
      name: string,
    ) => {
      if (!props.editing || evalType !== props.data.evalType) return;

      const [updated] = updateFirst(
        props.data.curriculums,
        (c) => c.evalType === evalType && c.clientSideId === clientSideId,
        (c) => ({
          ...c,
          name: { value: name, hash: c.name.hash },
        }),
      );
      onUpdateInternal(updated);
    };

    const moveCurriculum = (
      evalType: CurriculumEvalType,
      clientSideId: string,
      up: boolean,
    ) => {
      if (!props.editing) return;

      const updated = arrayMoveOne(
        props.data.curriculums,
        (c) => c.evalType === evalType && c.clientSideId === clientSideId,
        up,
      );
      onUpdateInternal(updated);
    };
    const addCurriculum = () => {
      if (!props.editing) return;

      const newCurriculum = createNewCurriculumEditItemData(
        props.data.evalType,
        "",
      );
      const updated = [...props.data.curriculums, newCurriculum];
      onUpdateInternal(updated);
    };
    const deleteCurriculum = (
      evalType: CurriculumEvalType,
      clientSideId: string,
    ) => {
      if (!props.editing || evalType !== props.data.evalType) return;

      const deletingCurriculumIdx = props.data.curriculums.findIndex(
        (c) => c.evalType === evalType && c.clientSideId === clientSideId,
      );
      if (deletingCurriculumIdx < 0) return;

      // 既存教科は警告してから論理削除。新規作成したばかりの教科はすぐに物理削除。
      if (hasValue(props.data.curriculums[deletingCurriculumIdx].id)) {
        // 既存教科の場合。
        if (
          !window.confirm(
            "教科を削除すると、その下の観点・内容構成・評価等もすべて削除されます。\nこの教科を削除してよろしいですか？\n一度保存すると、この操作は元に戻せません。",
          )
        )
          return;

        const [updated] = updateFirst(
          props.data.curriculums,
          (c) => c.evalType === evalType && c.clientSideId === clientSideId,
          (c) => ({
            ...c,
            deleted: true,
          }),
        );
        onUpdateInternal(updated);
      } else {
        // 新規作成したばかりの教科の場合。
        const updated = props.data.curriculums.slice();
        updated.splice(deletingCurriculumIdx, 1);
        onUpdateInternal(updated);
      }
    };

    return {
      evalTypeJapanese,

      updateName,
      moveCurriculum,
      addCurriculum,
      deleteCurriculum,

      styles: computed(() => ({
        "--background-color": labelToColor(bgColor.value),
      })),
    };
  },
});
