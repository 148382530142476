import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5536f052"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]
const _hoisted_2 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "school-type-tabs-container",
    role: "tablist",
    "aria-label": "School Type Tabs",
    style: _normalizeStyle(_ctx.styles)
  }, [
    _createElementVNode("button", {
      type: "button",
      class: _normalizeClass(["tab elementary-school-tab", { active: _ctx.currentTab === 'elementary', disabled: !_ctx.enabled }]),
      role: "tab",
      onClick: _cache[0] || (_cache[0] = () => _ctx.changeTabInternal('elementary')),
      disabled: !_ctx.enabled || _ctx.currentTab === 'elementary'
    }, " 小学校 ", 10, _hoisted_1),
    _createElementVNode("button", {
      type: "button",
      class: _normalizeClass(["tab junior-high-school-tab", { active: _ctx.currentTab === 'juniorhigh', disabled: !_ctx.enabled }]),
      role: "tab",
      onClick: _cache[1] || (_cache[1] = () => _ctx.changeTabInternal('juniorhigh')),
      disabled: !_ctx.enabled || _ctx.currentTab === 'juniorhigh'
    }, " 中学校 ", 10, _hoisted_2)
  ], 4))
}