import { Grade, gradeValueToGrade } from "@/ts/objects/value/grade";
import {
  NECurriculum as NECurriculumResp,
  EECurriculum as EECurriculumResp,
} from "@/ts/api/curriculum-service";
import { HashedString } from "@/ts/objects/value/hashed-string";

export type Curriculum = NECurriculum | EECurriculum;

export type NECurriculum = {
  readonly evalType: "numeric";
  readonly id: string;
  readonly schoolYear: number;
  readonly grade: Grade;
  readonly name: HashedString;
  readonly orderNum: number; // 0始まり。
  readonly syllabusFileGcsUrl: string | null;
};

export type EECurriculum = {
  readonly evalType: "essay";
  readonly id: string;
  readonly schoolYear: number;
  readonly grade: Grade;
  readonly name: HashedString;
  readonly orderNum: number; // 0始まり。
  readonly syllabusFileGcsUrl: string | null;
};

export function curriculumFromNECRespOrError(
  resp: NECurriculumResp,
): NECurriculum {
  return {
    evalType: "numeric",
    id: resp.necId,
    schoolYear: resp.schoolYear,
    grade: gradeValueToGrade(resp.grade),
    name: { value: resp.name.value, hash: resp.name.hash },
    orderNum: resp.orderNum,
    syllabusFileGcsUrl: resp.syllabusFileGcsObjectPath ?? null,
  };
}

export function curriculumFromEECRespOrError(
  resp: EECurriculumResp,
): EECurriculum {
  return {
    evalType: "essay",
    id: resp.eecId,
    schoolYear: resp.schoolYear,
    grade: gradeValueToGrade(resp.grade),
    name: { value: resp.name.value, hash: resp.name.hash },
    orderNum: resp.orderNum,
    syllabusFileGcsUrl: resp.syllabusFileGcsObjectPath ?? null,
  };
}
