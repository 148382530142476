
import { computed, defineComponent, PropType, Ref, ref } from "vue";
import { User } from "@/ts/objects/entity/user";
import debounce from "lodash/debounce";
import { ColorLabel, labelToColor } from "@/ts/objects/value/color-label";
import SelectableSearchBox from "@/components/textboxes/SelectableSearchBox/SelectableSearchBox.vue";
import { hasValue } from "@/ts/utils/common-util";

export default defineComponent({
  name: "UserSearchBox",
  components: { SelectableSearchBox },
  props: {
    width: { type: Number, default: 360 },
    height: { type: Number, default: 36 },
    placeholder: {
      type: String,
      default: "名前・Google Mail・ユーザーIDで検索",
    },
    placeholderFontSize: { type: Number, default: 12 },
    borderColor: {
      type: String as PropType<ColorLabel>,
      default: "light-gray",
    },
    borderColorOnFocus: {
      type: String as PropType<ColorLabel>,
      default: "dark-gray",
    },

    /**
     * 検索結果のうち、表示する数。
     */
    numSearchResults: { type: Number, default: 10 },

    /**
     * ユーザーを検索する関数。
     * 検索対象を、例えば児童生徒のみに絞りたいなどの場合は、この関数側で対応すること。
     */
    searchUsers: {
      type: Function as PropType<(searchText: string) => Promise<User[]>>,
      required: true,
    },
    /**
     * ユーザーを選択したときに呼ばれる関数。
     */
    onSelectUser: {
      type: Function as PropType<(user: User) => void>,
      required: true,
    },
    /**
     * ユーザーの選択を解除したときに呼ばれる関数。
     */
    onUnselectUser: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
  setup(props) {
    const focused = ref(false);
    const searchResults: Ref<User[] | null> = ref(null);
    const selectedUser: Ref<User | null> = ref(null);

    const debouncedSearch = debounce(async (searchText: string) => {
      if (searchText.trim() === "") return;
      searchResults.value = await props.searchUsers(searchText);
    }, 1000);
    const reset = () => {
      const unselectingUser = hasValue(selectedUser.value);

      debouncedSearch.cancel();
      selectedUser.value = null;
      searchResults.value = null;

      if (unselectingUser) props.onUnselectUser();
    };

    return {
      focused,
      searchResults,
      searchResultsTopN: computed(
        () => searchResults.value?.slice(0, props.numSearchResults) ?? null,
      ),
      selectedUser,

      selectedUserName: computed(() => selectedUser.value?.name ?? null),

      onFocus: () => {
        focused.value = true;
      },
      onInput: (text: string) => {
        if (text.trim() === "") {
          reset();
          return;
        }
        debouncedSearch(text);
      },
      onClickReset: reset,
      onSelectUserInternal: (user: User) => {
        debouncedSearch.cancel();
        selectedUser.value = user;
        props.onSelectUser(user);
      },
      onClickOutside: () => {
        focused.value = false;
      },

      styles: computed(() => ({
        "--width": `${props.width}px`,
        "--height": `${props.height}px`,
        "--border-color": labelToColor(props.borderColor),
        // "--border-color-on-focus": labelToColor(props.borderColorOnFocus),
      })),
    };
  },
});
