import {
  SearchConditionClass,
  searchConditionClassDefault,
  SearchConditionClassRaw,
  searchConditionClassToQueryParams,
  searchConditionClassToRaw,
} from "@/ts/objects/search-condition/search-condition-class";
import {
  SearchConditionInSchoolState,
  searchConditionInSchoolStateDefault,
  SearchConditionInSchoolStateRaw,
  searchConditionInSchoolStateToQueryParams,
  searchConditionInSchoolStateToRaw,
} from "@/ts/objects/search-condition/search-condition-in-school-state";
import {
  NumResultsCondition,
  numResultsConditionDefault,
  numResultsConditionToQueryParams,
} from "@/ts/objects/search-condition/num-results-condition";
import {
  UserSortCondition,
  userSortConditionDefault,
  UserSortConditionRaw,
  userSortConditionToQueryParams,
  userSortConditionToRaw,
} from "@/ts/objects/search-condition/user-sort-condition";
import { DateValue } from "@/ts/objects/value/date-value";

export type GuardianSearchCondition = {
  readonly schoolYear: number;
  readonly queryText: string;
  readonly class: SearchConditionClass;
  readonly inSchoolState: SearchConditionInSchoolState;
  readonly sort: UserSortCondition;
  readonly numResults: NumResultsCondition;
};

export function guardianSearchConditionDefault(
  schoolYear: number,
  date: DateValue,
): GuardianSearchCondition {
  return {
    schoolYear,
    queryText: "",
    class: searchConditionClassDefault(schoolYear),
    inSchoolState: searchConditionInSchoolStateDefault(date),
    sort: userSortConditionDefault(),
    numResults: numResultsConditionDefault(),
  };
}

export function guardianSearchConditionToQueryParams(
  cond: GuardianSearchCondition,
): Record<string, string | number | undefined> {
  return {
    schoolYear: cond.schoolYear,
    q: cond.queryText,
    ...searchConditionClassToQueryParams(cond.class),
    ...searchConditionInSchoolStateToQueryParams(cond.inSchoolState),
    ...userSortConditionToQueryParams(cond.sort),
    ...numResultsConditionToQueryParams(cond.numResults),
  };
}

export type GuardianSearchConditionRaw = {
  readonly schoolYear: number;
  readonly queryText: string;
  readonly class: SearchConditionClassRaw;
  readonly inSchoolState: SearchConditionInSchoolStateRaw;
  readonly sort: UserSortConditionRaw;
  readonly numResults: NumResultsCondition;
};

export function guardianSearchConditionToRaw(
  cond: GuardianSearchCondition,
): GuardianSearchConditionRaw {
  return {
    schoolYear: cond.schoolYear,
    queryText: cond.queryText,
    class: searchConditionClassToRaw(cond.class),
    inSchoolState: searchConditionInSchoolStateToRaw(cond.inSchoolState),
    sort: userSortConditionToRaw(cond.sort),
    numResults: cond.numResults,
  };
}
