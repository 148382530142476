import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d40262ba"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "school-edit-container" }
const _hoisted_2 = { class: "body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SchoolEditHeader = _resolveComponent("SchoolEditHeader")!
  const _component_SchoolEditTable = _resolveComponent("SchoolEditTable")!
  const _component_LoadingBlock = _resolveComponent("LoadingBlock")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SchoolEditHeader, {
      class: "header",
      "is-admin": _ctx.isAdmin,
      editing: _ctx.editing,
      "on-click-edit": _ctx.startEditing,
      "on-click-cancel": _ctx.cancelEditing,
      "on-click-save": _ctx.save
    }, null, 8, ["is-admin", "editing", "on-click-edit", "on-click-cancel", "on-click-save"]),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.tableModel != null)
        ? (_openBlock(), _createBlock(_component_SchoolEditTable, {
            key: 0,
            class: "table",
            model: _ctx.tableModel,
            "logo-updated-at": _ctx.logoUpdatedAt,
            "on-input-school-name": _ctx.onInputSchoolName,
            "on-input-logo": _ctx.onInputLogo,
            editing: _ctx.editing
          }, null, 8, ["model", "logo-updated-at", "on-input-school-name", "on-input-logo", "editing"]))
        : (_openBlock(), _createBlock(_component_LoadingBlock, { key: 1 }))
    ])
  ]))
}