
import { defineComponent, Ref } from "vue";
import { isNullish } from "@/ts/utils/common-util";
import { useCursorPosition } from "@/composables/provide-cursor-position";

export default defineComponent({
  name: "TextBalloon",
  props: {
    text: { type: String, required: true },

    /**
     * カーソルの位置に表示したい場合、true。
     */
    onCursor: { type: Boolean, default: false },

    top: { type: Number, default: null },
    right: { type: Number, default: null },
    bottom: { type: Number, default: null },
    left: { type: Number, default: null },

    minWidth: { type: Number, default: 10 },
    maxWidth: { type: Number, default: 200 },

    zIndex: { type: Number, default: 10 },

    fontSize: { type: Number, default: 12 },
  },
  setup(props) {
    const { cursorX, cursorY } = useCursorPosition();
    const pos = getPosition(
      props.onCursor,
      cursorX,
      cursorY,
      props.top,
      props.right,
      props.bottom,
      props.left,
    );
    return {
      styles: {
        "--top": pos.top,
        "--right": pos.right,
        "--bottom": pos.bottom,
        "--left": pos.left,

        "--min-width": `${props.minWidth}px`,
        "--max-width": `${props.maxWidth}px`,

        "--z-index": props.zIndex,

        "--font-size": `${props.fontSize}px`,
      },
    };
  },
});

function getPosition(
  onCursor: boolean,
  cursorX: Ref<number>,
  cursorY: Ref<number>,
  top: number | null | undefined,
  right: number | null | undefined,
  bottom: number | null | undefined,
  left: number | null | undefined,
): {
  top: string;
  right: string;
  bottom: string;
  left: string;
} {
  if (onCursor) {
    return {
      top: `${cursorY.value + 13}px`,
      right: "auto",
      bottom: "auto",
      left: `${cursorX.value + 13}px`,
    };
  }
  return {
    top: isNullish(top) ? "auto" : `${top}px`,
    right: isNullish(right) ? "auto" : `${right}px`,
    bottom: isNullish(bottom) ? "auto" : `${bottom}px`,
    left: isNullish(left) ? "auto" : `${left}px`,
  };
}
