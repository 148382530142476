
import { defineComponent } from "vue";

export default defineComponent({
  name: "UserListTableHeaderCell",
  props: {
    text: { type: String, required: true },
    width: { type: Number, required: true },
    height: { type: Number, required: true },
  },
  setup(props) {
    return {
      styles: {
        "--width": `${props.width}px`,
        "--height": `${props.height}px`,
      },
    };
  },
});
