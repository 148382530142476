import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-72b80682"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("button", {
    type: "button",
    class: _normalizeClass(["base-small-button-container", { disabled: !_ctx.enabled }]),
    style: _normalizeStyle(_ctx.styles),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClickInternal && _ctx.onClickInternal(...args))),
    disabled: !_ctx.enabled
  }, [
    _createVNode(_component_font_awesome_icon, {
      class: "icon",
      icon: [_ctx.iconType, _ctx.icon]
    }, null, 8, ["icon"])
  ], 14, _hoisted_1))
}