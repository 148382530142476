import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createBlock(_component_BaseButton, {
    class: "class-list-table-row-add-button-container",
    width: 55,
    height: 26,
    "padding-right": 8,
    "padding-left": 9,
    icon: "plus",
    "icon-size": 11,
    label: "Add",
    "font-size": 11,
    color: "black",
    "border-width": 0,
    "border-radius": 9,
    "background-color": "light-green-transparent",
    "on-click": _ctx.onClick
  }, null, 8, ["on-click"]))
}