import { useRoute } from "vue-router";
import { computed, ComputedRef } from "vue";
import { isPageName, PageName } from "@/ts/app/pages/pages";

// TODO これはちゃんと動くので、この感じでvue-router-syncの代用を作る。画面ごとに別のcomposableにしても良いかも。
export function useAppRoute(): {
  pageName: ComputedRef<PageName | null>;
} {
  // APIリファレンス: https://next.router.vuejs.org/api/#routelocationnormalized
  const route = useRoute();
  const splitPath = computed(() => route.path.split("/")); // TODO paramsを使うべき？

  const pageName = computed(() => {
    const pageNameParam = splitPath.value[1];
    if (!isPageName(pageNameParam)) return null;
    return pageNameParam;
  });

  return { pageName };
}
