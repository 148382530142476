
import { defineComponent, PropType } from "vue";
import EditableItemDate from "@/components/editable-items/EditableItemDate/EditableItemDate.vue";
import { DateValue } from "@/ts/objects/value/date-value";

export default defineComponent({
  name: "TermEditTableCellContent",
  components: { EditableItemDate },
  props: {
    date: {
      type: Object as PropType<DateValue>,
      required: false, // 削除、ダミーの場合にnullが入るため
    },
    onChangeTerm: {
      type: Function as PropType<(newDate: DateValue | null) => void>,
      required: true,
    },
    editing: { type: Boolean, required: true },
    placeholder: { type: String, required: true },
  },
});
