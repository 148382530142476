
import { computed, defineComponent, Ref, ref } from "vue";
import SchoolEdit from "@/views/school/SchoolEdit/SchoolEdit.vue";
import { useUserService } from "@/composables/provide-user-service";
import {
  applyResult,
  LoadableData,
  loadableDataNull,
  toLoading,
} from "@/ts/app/loadable-data";
import { School } from "@/ts/objects/entity/school";
import { UnsavedSchoolEditData } from "@/views/school/SchoolEdit/school-edit-data";
import { hasValue, isNullish } from "@/ts/utils/common-util";
import { useAppToast } from "@/composables/use-app-toast";
import { useAppStore } from "@/store/app-store";
import { UserService } from "@/ts/services/user-service";

export default defineComponent({
  name: "SchoolEditContainer",
  components: { SchoolEdit },
  setup() {
    const appStore = useAppStore();
    const userService = useUserService();
    const { showError, showSuccess } = useAppToast();

    const isAdmin = computed(() => appStore.isAdmin);

    const school = ref<LoadableData<School>>(loadableDataNull());

    const fetchSchool = async () => {
      school.value = toLoading(school.value);
      const result = await userService.getSchool(true);
      school.value = applyResult(school.value, result);
    };
    fetchSchool();

    const unsavedData = ref<UnsavedSchoolEditData | null>(null);

    const onInputSchoolName = (value: string) => {
      const _unsavedData = unsavedData.value;
      if (isNullish(_unsavedData)) return;

      _unsavedData.schoolName = value;
    };

    const logoUpdatedAt = ref<Date | null>(null); // ただロゴの変更をすぐに反映するためだけに用いる。
    const onInputLogo = async (file: File | null) => {
      const _unsavedData = unsavedData.value;
      if (isNullish(_unsavedData)) return;

      await uploadOrDeleteLogo(
        userService,
        file,
        showError,
        showSuccess,
        school,
        logoUpdatedAt,
      );
    };

    const startEditing = () => {
      const _school = school.value;

      // すでに編集中か、schoolが新鮮なデータでないなら、編集開始しない。
      if (hasValue(unsavedData.value) || !_school.hasFreshData) return;

      unsavedData.value = {
        schoolName: _school.data.schoolName,
      };
    };
    const cancelEditing = () => {
      unsavedData.value = null;
    };
    const save = async () => {
      const _unsavedData = unsavedData.value;
      if (isNullish(_unsavedData)) return;

      school.value = toLoading(school.value);
      unsavedData.value = null;

      const result = await userService.patchSchool(_unsavedData);
      if (result.ok) {
        showSuccess(
          "変更を保存しました。(反映には時間が掛かる場合があります。)",
        );
      } else {
        showError(`変更の保存に失敗しました: ${result.error.displayMessage}`);
      }

      school.value = applyResult(school.value, result);
      appStore.setSchool(userService); // キャッシュがあるので更新されない可能性が高いが、念のため。

      await fetchSchool();
    };

    return {
      isAdmin,

      school,
      unsavedData,

      logoUpdatedAt,

      onInputSchoolName,
      onInputLogo,

      startEditing,
      cancelEditing,
      save,
    };
  },
});

async function uploadOrDeleteLogo(
  userService: UserService,
  file: File | null,
  showError: (message: string) => void,
  showSuccess: (message: string) => void,
  school: Ref<LoadableData<School>>,
  logoUpdatedAt: Ref<Date | null>,
) {
  school.value = toLoading(school.value);

  if (hasValue(file)) {
    const result = await userService.uploadSchoolLogo(file);
    if (!result.ok) {
      showError(
        `学校ロゴのアップロードに失敗しました: ${result.error.displayMessage}`,
      );
      return;
    }
    showSuccess(
      "学校ロゴをアップロードしました。(反映には時間が掛かる場合があります。)",
    );
    school.value = applyResult(school.value, result);
  } else {
    const result = await userService.deleteSchoolLogo();
    if (!result.ok) {
      showError(`学校ロゴの削除に失敗しました: ${result.error.displayMessage}`);
      return;
    }
    showSuccess(
      "学校ロゴを削除しました。(反映には時間が掛かる場合があります。)",
    );
    school.value = applyResult(school.value, result);
  }

  logoUpdatedAt.value = new Date();
}
