
import { defineComponent, PropType } from "vue";
import SchoolYearSwitch from "@/components/other/SchoolYearSwitch/SchoolYearSwitch.vue";
import { names } from "@/ts/app/object-name";

export default defineComponent({
  name: "StudentImportSchoolYearArea",
  components: { SchoolYearSwitch },
  props: {
    schoolYear: { type: Number, default: null, required: false },
    enabled: { type: Boolean, required: true },
    onInput: {
      type: Function as PropType<(value: number) => void>,
      required: true,
    },
  },
  setup() {
    return {
      googleMailDisplayName: names.googleMail.d,
    };
  },
});
