
import { defineComponent, PropType } from "vue";
import RemoveButton from "@/components/buttons/RemoveButton/RemoveButton.vue";

export default defineComponent({
  name: "TermEditTableHeader",
  components: { RemoveButton },
  props: {
    maxTermNumber: { type: Number, required: true },
    /**
     * 最後の学期を削除する。
     * 例えば4学期まで存在するときは、4学期目を削除する。
     */
    deleteTermNumber: {
      type: Function as PropType<() => void>,
      required: true,
    },
    editing: { type: Boolean, required: true },

    yearCellWidth: { type: Number, required: true },
    cellWidth: { type: Number, required: true },
  },
  setup(props) {
    return {
      styles: {
        "--year-cell-width": `${props.yearCellWidth}px`,
        "--cell-width": `${props.cellWidth}px`,
      },
    };
  },
});
