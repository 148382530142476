import { useToast } from "vue-toastification";

export function useAppToast(
  infoTimeoutMillis: number | false = 8000,
  errorTimeoutMillis: number | false = 16000,
  successTimeoutMillis: number | false = 8000,
): {
  showInfo: (message: string, timeoutMillis?: number) => void;
  showError: (message: string, timeoutMillis?: number) => void;
  showSuccess: (message: string, timeoutMillis?: number) => void;
} {
  const toast = useToast();

  const showInfo = (message: string, timeoutMillis?: number) => {
    toast.info(message, { timeout: timeoutMillis ?? infoTimeoutMillis });
  };
  const showError = (message: string, timeoutMillis?: number) => {
    toast.error(message, { timeout: timeoutMillis ?? errorTimeoutMillis });
  };
  const showSuccess = (message: string, timeoutMillis?: number) => {
    toast.success(message, { timeout: timeoutMillis ?? successTimeoutMillis });
  };

  return { showInfo, showError, showSuccess };
}
