
import { computed, defineComponent, PropType, ref } from "vue";
import ClassDetailsTableRow from "@/views/class/ClassDetails/ClassDetailsTable/ClassDetailsTableRow/ClassDetailsTableRow.vue";
import {
  ClassDetailsRowData,
  classDetailsRowDataFromStudentBasicUserInfo,
  classDetailsRowDataFromTeacherBasicUserInfo,
  ClassDetailsTableData,
} from "@/views/class/ClassDetails/class-details-data";
import ClassDetailsTableAddButton from "@/views/class/ClassDetails/ClassDetailsTable/ClassDetailsTableAddButton/ClassDetailsTableAddButton.vue";
import UserSelectPopup from "@/components/popups/UserSelectPopup/UserSelectPopup.vue";
import { User } from "@/ts/objects/entity/user";
import { isNullish, updateFirst } from "@/ts/utils/common-util";
import { useAppToast } from "@/composables/use-app-toast";
import { names } from "@/ts/app/object-name";

type Row = {
  readonly theme: 0 | 1;
  readonly data: ClassDetailsRowData;
};

export default defineComponent({
  name: "ClassDetailsTable",
  components: {
    UserSelectPopup,
    ClassDetailsTableAddButton,
    ClassDetailsTableRow,
  },
  props: {
    titleIcon: { type: String, required: true },
    title: { type: String, required: true },
    data: { type: Object as PropType<ClassDetailsTableData>, required: true },

    editing: { type: Boolean, required: true },

    onUpdate: {
      type: Function as PropType<(updated: ClassDetailsTableData) => void>,
      required: true,
    },
  },
  setup(props) {
    const { showInfo } = useAppToast();

    const rows = computed<Row[]>(() =>
      props.data.users.map((user, idx) => ({
        theme: (idx % 2) as 0 | 1,
        data: user,
      })),
    );

    const userSelectPopupMessage = computed(
      () => `${names[props.data.userType].d}を選択してください。`,
    );

    const isUserSelectPopupActive = ref(false);
    const openUserSelectPopup = () => (isUserSelectPopupActive.value = true);
    const closeUserSelectPopup = () => (isUserSelectPopupActive.value = false);
    const onConfirmUserSelectPopup = (user: User) => {
      if (!props.editing) return;

      isUserSelectPopupActive.value = false;

      const data = props.data;

      if (data.users.some((u) => u.userId === user.userId)) {
        showInfo("同じユーザーを登録することはできません。");
        return;
      }

      if (data.userType === "teacher") {
        const updated = [
          ...data.users,
          classDetailsRowDataFromTeacherBasicUserInfo(user),
        ];
        props.onUpdate({ userType: "teacher", users: updated });
      } else {
        const updated = [
          ...data.users,
          classDetailsRowDataFromStudentBasicUserInfo(user),
        ];
        props.onUpdate({ userType: "student", users: updated });
      }
    };

    const onChangeRowTitle = (
      userId: string,
      value: string | number | null,
    ) => {
      if (!props.editing) return;

      if (props.data.userType === "teacher" && typeof value === "string") {
        const [updated] = updateFirst(
          props.data.users,
          (u) => u.userId === userId,
          (u) => ({ ...u, inChargeType: value }),
        );
        props.onUpdate({ userType: "teacher", users: updated });
      } else if (
        props.data.userType === "student" &&
        (typeof value === "number" || isNullish(value))
      ) {
        const [updated] = updateFirst(
          props.data.users,
          (u) => u.userId === userId,
          (u) => ({ ...u, studentNumber: value ?? null }),
        );
        props.onUpdate({ userType: "student", users: updated });
      }
    };
    const onClickRemove = (userId: string) => {
      if (!props.editing) return;

      if (props.data.userType === "teacher") {
        const updated = props.data.users.filter((u) => u.userId !== userId);
        props.onUpdate({ userType: "teacher", users: updated });
      } else {
        const updated = props.data.users.filter((u) => u.userId !== userId);
        props.onUpdate({ userType: "student", users: updated });
      }
    };

    return {
      rows,

      userSelectPopupMessage,

      isUserSelectPopupActive,
      openUserSelectPopup,
      closeUserSelectPopup,
      onConfirmUserSelectPopup,

      onChangeRowTitle,
      onClickRemove,
    };
  },
});
