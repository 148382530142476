
import { defineComponent, PropType } from "vue";
import BaseButton from "@/components/buttons/BaseButton/BaseButton.vue";

export default defineComponent({
  name: "SyncWithGWSButton",
  components: { BaseButton },
  props: {
    enabled: { type: Boolean, default: true },
    onClick: { type: Function as PropType<() => void>, required: true },
  },
});
