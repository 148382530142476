
import { defineComponent, PropType, ref, watchEffect } from "vue";
import EditableItemNumber from "@/components/editable-items/EditableItemNumber/EditableItemNumber.vue";
import { useAppStore } from "@/store/app-store";
import { hasValue, isNullish } from "@/ts/utils/common-util";
import clamp from "lodash/clamp";

export default defineComponent({
  name: "SchoolYearSwitch",
  components: { EditableItemNumber },
  props: {
    schoolYear: { type: Number, required: true },
    enabled: { type: Boolean, required: true },
    onChange: {
      type: Function as PropType<(value: number) => void>,
      required: true,
    },
    /**
     * input内でエンターキーを押したときに呼び出される。
     */
    onEnter: { type: Function as PropType<() => void>, required: false },

    numberFontSize: { type: Number, required: false, default: 16 },
    unitFontSize: { type: Number, required: false, default: 14 },
    arrowSize: { type: Number, required: false, default: 16 },
    bold: { type: Boolean, required: false, default: false },
  },
  setup(props) {
    const appStore = useAppStore();
    const currentSchoolYear = appStore.currentSchoolYear;

    const schoolYearInternal = ref<number | null>(props.schoolYear);
    watchEffect(() => (schoolYearInternal.value = props.schoolYear));

    const emitSchoolYear = () => {
      if (!props.enabled) return;

      const value = schoolYearInternal.value;
      if (isNullish(value)) {
        props.onChange(currentSchoolYear);
        return;
      }
      props.onChange(clamp(value, 1970, 9999));
    };

    const onChangeInternal = (value: number | null) => {
      if (!props.enabled) return;

      schoolYearInternal.value = value;

      if (hasValue(value) && 1970 <= value && value <= 9999) {
        emitSchoolYear();
      }
    };

    const onBlur = () => {
      emitSchoolYear();
    };

    return {
      currentSchoolYear,

      schoolYearInternal,

      onChangeInternal,
      onBlur,

      toPrevYear: () => {
        schoolYearInternal.value = props.schoolYear - 1;
        emitSchoolYear();
      },
      toNextYear: () => {
        schoolYearInternal.value = props.schoolYear + 1;
        emitSchoolYear();
      },

      styles: {
        "--number-font-size": `${props.numberFontSize}px`,
        "--unit-font-size": `${props.unitFontSize}px`,
        "--arrow-size": `${props.arrowSize}px`,
        "--font-weight": props.bold ? "bold" : "normal",
      },
    };
  },
});
