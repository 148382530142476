import { useToast } from "vue-toastification";
import { ToastID } from "vue-toastification/dist/types/types";

export function useCopyToClipboard(
  messageOnSuccess: string = "クリップボードにコピーしました。",
  massageOnFail: string = "クリップボードへのコピーに失敗しました。",
): {
  copyToClipboard: (text: string) => Promise<ToastID>;
} {
  const toast = useToast();
  const copyToClipboard = (text: string) =>
    navigator.clipboard.writeText(text).then(
      () => toast(messageOnSuccess, { timeout: 3000 }),
      () => toast.error(massageOnFail, { timeout: 3000 }),
    );
  return { copyToClipboard };
}
