import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SubPageHeading = _resolveComponent("SubPageHeading")!

  return (_openBlock(), _createBlock(_component_SubPageHeading, {
    class: "student-sub-page-heading-container",
    "main-icon": "child",
    "main-label": "Students",
    "sub-label": _ctx.subLabel,
    "main-page-heading-link-path": _ctx.mainPageHeadingLinkPath,
    "on-click-back": _ctx.onClickBack
  }, null, 8, ["sub-label", "main-page-heading-link-path", "on-click-back"]))
}