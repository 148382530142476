import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3e300276"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "term-edit-table-cell-container",
  role: "cell"
}
const _hoisted_2 = {
  key: 0,
  class: "connector"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TermEditTableCellContent = _resolveComponent("TermEditTableCellContent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TermEditTableCellContent, {
      class: "cell-content",
      "data-testid": "start-date-cell-content",
      date: _ctx.startDate,
      "on-change-term": (newDate) => _ctx.onChangeTerm(true, newDate),
      editing: _ctx.editing,
      placeholder: "From"
    }, null, 8, ["date", "on-change-term", "editing"]),
    (_ctx.editing || _ctx.startDate != null || _ctx.endDate != null)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, " ～ "))
      : _createCommentVNode("", true),
    _createVNode(_component_TermEditTableCellContent, {
      class: "cell-content",
      "data-testid": "end-date-cell-content",
      date: _ctx.endDate,
      "on-change-term": (newDate) => _ctx.onChangeTerm(false, newDate),
      editing: _ctx.editing,
      placeholder: "To"
    }, null, 8, ["date", "on-change-term", "editing"])
  ]))
}