/**
 * ロジックエラー（バグ）があった場合のみ投げらるエラー。
 */
export class CheckFailed extends Error {
  constructor() {
    super();

    this.name = "CheckFailed";
    this.message = "check failed";
  }
}
