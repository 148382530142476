import { Sex } from "@/ts/objects/value/sex";
import { SchoolType } from "@/ts/objects/value/school-type";
import { TeacherType } from "@/ts/objects/value/teacher-type";
import { UserType } from "@/ts/objects/value/user-type";
import { HasLunchValue } from "@/ts/objects/value/has-lunch-value";

export type ColumnTypeCategory = ColumnType<any>["category"];

export type ColumnType<Value = never> =
  | {
      readonly category:
        | "string"
        | "multi line string"
        | "number"
        | "date"
        | "image"
        | "student-classes"
        | "teacher-classes";
    }
  | {
      readonly category: "enum";
      readonly possibleValues: {
        readonly value: Value;
        readonly name: string;
      }[];
    }
  | {
      readonly category: "user";
      readonly userType: UserType;
    }
  | {
      readonly category: "users";
      readonly userType: UserType;
    };

export const StringType: ColumnType = {
  category: "string",
};

export const StringOrNullType: ColumnType = {
  category: "string",
};

export const IntegerType: ColumnType = {
  category: "number",
};

export const IntegerOrNullType: ColumnType = {
  category: "number",
};

export const DateOrNullType: ColumnType = {
  category: "date",
};

export const ImageType: ColumnType = {
  category: "image",
};

export const SexType: ColumnType<Sex> = {
  category: "enum",
  possibleValues: [
    { value: "male", name: "male" },
    { value: "female", name: "female" },
    { value: "other", name: "other" },
  ],
};

export const HasLunchType: ColumnType<HasLunchValue> = {
  category: "enum",
  possibleValues: [
    { value: "yes", name: "yes" },
    { value: "no", name: "no" },
  ],
};

export const TeacherTypeType: ColumnType<TeacherType> = {
  category: "enum",
  possibleValues: [
    { value: "staff", name: "staff" },
    { value: "teacher", name: "teacher" },
  ],
};

export const GuardianOrNullType: ColumnType = {
  category: "user",
  userType: "guardian",
};

export const ChildrenType: ColumnType = {
  category: "users",
  userType: "student",
};

export const StudentClassesType: ColumnType = {
  category: "student-classes",
};

export const TeacherClassesType: ColumnType = {
  category: "teacher-classes",
};

// TODO SchoolTypeOrNullTypeは、たぶん選択するところがない（つまりクラスごと選択する）のだが、どうするか・・・。
export const SchoolTypeOrNullType: ColumnType<SchoolType | null> = {
  category: "enum",
  possibleValues: [
    { value: "elementary", name: "elementary" },
    { value: "juniorhigh", name: "juniorhigh" },
    { value: null, name: "なし" },
  ],
};
