
import SyncWithGWSView from "@/views/_components/SyncWithGWSView/SyncWithGWSView.vue";
import { defineComponent } from "vue";
import TeacherSubPageHeading from "@/views/teacher/_components/TeacherSubPageHeading/TeacherSubPageHeading.vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "TeacherSync",
  components: { SyncWithGWSView, TeacherSubPageHeading },
  setup() {
    const router = useRouter();

    const onClickBack = () => router.push("/teacher");

    return {
      onClickBack,
    };
  },
});
