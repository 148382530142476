
import { computed, defineComponent, PropType } from "vue";
import TermEditTableRow from "@/views/term/TermEdit/TermEditTable/TermEditTableRow/TermEditTableRow.vue";
import { DateValue } from "@/ts/objects/value/date-value";
import { TermTableModel } from "@/views/term/TermEdit/term-edit-data";
import range from "lodash/range";
import { useHovering } from "@/composables/use-hovering";
import TermEditTableHeader from "@/views/term/TermEdit/TermEditTable/TermEditTableHeader/TermEditTableHeader.vue";
import log from "loglevel";

export default defineComponent({
  name: "TermEditTable",
  components: {
    TermEditTableHeader,
    TermEditTableRow,
  },
  props: {
    model: {
      type: Object as PropType<TermTableModel>,
      required: true,
    },
    onChangeTerm: {
      type: Function as PropType<
        (
          schoolYear: number,
          termNumber: number,

          changedStartDate: boolean,
          newDate: DateValue | null,
        ) => void
      >,
      required: true,
    },
    maxTermNumber: { type: Number, required: true },
    minSchoolYear: { type: Number, required: true },
    maxSchoolYear: { type: Number, required: true },

    /**
     * 最後の学期を削除する。
     * 例えば4学期まで存在するときは、4学期目を削除する。
     */
    deleteTermNumber: {
      type: Function as PropType<() => void>,
      required: true,
    },
    /**
     * 最後の年度を削除する。
     * 例えば2015年度まで存在するときは、2015年度を削除する。
     */
    deleteSchoolYear: {
      type: Function as PropType<() => void>,
      required: true,
    },
    editing: { type: Boolean, required: true },
  },

  setup(props) {
    log.debug(`TermEditTable: model=${JSON.stringify(props.model)}`);

    const schoolYears = computed(() =>
      range(props.maxSchoolYear, props.minSchoolYear - 1, -1),
    ); // 逆順。min=2001, max=2003なら、 [2003, 2002, 2001] 。
    const { hovering, onMouseEnter, onMouseLeave } = useHovering();

    const yearCellWidth = 80;
    const cellWidth = 210;

    return {
      schoolYears,

      hovering,
      onMouseEnter,
      onMouseLeave,

      yearCellWidth,
      cellWidth,

      styles: {
        "--cell-width": `${cellWidth}px`,
      },
    };
  },
});
