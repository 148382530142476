import { Grade, gradeFromRespOrError } from "@/ts/objects/value/grade";
import { Class as ClassResp, ClassWrite } from "@/ts/api/user-service";

export type Class = {
  readonly classId: string;

  readonly schoolYear: number;
  readonly grade: Grade;
  readonly classNo: number; // (年度, 学年)内での、並び順。1始まり。
  readonly name: string;
};

export function classFromRespOrError(r: ClassResp): Class {
  return {
    classId: r.classId,

    schoolYear: r.schoolYear,
    grade: gradeFromRespOrError(r.grade),
    classNo: r.orderNum,
    name: r.name,
  };
}

export function classToReq(c: Partial<Class>): ClassWrite {
  return {
    schoolYear: c.schoolYear,
    grade: c.grade,
    orderNum: c.classNo,
    name: c.name,
  };
}
