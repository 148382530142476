
import { computed, defineComponent, PropType } from "vue";
import { hasValue, isNullish } from "@/ts/utils/common-util";
import range from "lodash/range";
import PagerPageNumber from "@/components/other/Pager/PagerPageNumber/PagerPageNumber.vue";

export default defineComponent({
  name: "Pager",
  components: { PagerPageNumber },
  props: {
    activePage: { type: Number, default: null },
    numAllPages: { type: Number, required: true },

    movePageTo: {
      type: Function as PropType<(pageNumber: number) => void>,
      required: true,
    },
  },
  setup(props) {
    const isActivePageNumberValid = computed(
      () =>
        hasValue(props.activePage) &&
        1 <= props.activePage &&
        props.activePage <= props.numAllPages,
    );

    return {
      pagesToShow: computed(() =>
        getPagesToShow(props.activePage, props.numAllPages),
      ),
      prevEnabled: computed(
        () => isActivePageNumberValid.value && props.activePage >= 2,
      ),
      nextEnabled: computed(
        () =>
          isActivePageNumberValid.value && props.activePage < props.numAllPages,
      ),
      onClickPrev: () => props.movePageTo(props.activePage - 1),
      onClickNext: () => props.movePageTo(props.activePage + 1),
    };
  },
});

function getPagesToShow(
  activePage: number | null,
  numAllPages: number,
): number[] {
  const n = 5; // 1度に表示するページ数の半分。

  if (isNullish(activePage) || isNaN(activePage) || activePage <= 0) {
    return range(1, Math.min(n * 2, numAllPages) + 1);
  }

  if (activePage <= n) {
    return range(1, Math.min(n * 2, numAllPages) + 1);
  }

  if (numAllPages - activePage <= n) {
    return range(Math.max(numAllPages - n * 2 + 1, 1), numAllPages + 1);
  }

  return range(Math.max(activePage - n, 1), activePage + n);
}
