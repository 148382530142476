import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2a35a1a7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "curriculum-edit-container" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "body" }
const _hoisted_4 = { class: "body-header" }
const _hoisted_5 = { class: "controls" }
const _hoisted_6 = {
  key: 0,
  class: "table-wrap"
}
const _hoisted_7 = {
  key: 1,
  class: "table-wrap"
}
const _hoisted_8 = {
  key: 2,
  class: "table-wrap loading"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CurriculumPageHeading = _resolveComponent("CurriculumPageHeading")!
  const _component_EditButtonSet = _resolveComponent("EditButtonSet")!
  const _component_SchoolTypeTabs = _resolveComponent("SchoolTypeTabs")!
  const _component_SchoolYearSwitch = _resolveComponent("SchoolYearSwitch")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_CurriculumEditTable = _resolveComponent("CurriculumEditTable")!
  const _component_LoadingBlock = _resolveComponent("LoadingBlock")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_CurriculumPageHeading, { class: "page-heading" }),
      (_ctx.isAdmin)
        ? (_openBlock(), _createBlock(_component_EditButtonSet, {
            key: 0,
            class: "edit-button-set",
            editing: _ctx.editState.editing,
            enabled: _ctx.data.hasFreshData || _ctx.editState.editing,
            "on-click-edit": _ctx.startEditing,
            "on-click-save": _ctx.save,
            "on-click-cancel": _ctx.cancelEditing
          }, null, 8, ["editing", "enabled", "on-click-edit", "on-click-save", "on-click-cancel"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_SchoolTypeTabs, {
          class: "tabs",
          "change-tab": _ctx.changeSchoolType,
          "current-tab": _ctx.schoolType,
          enabled: !_ctx.editState.editing
        }, null, 8, ["change-tab", "current-tab", "enabled"]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_SchoolYearSwitch, {
            class: "school-year-switch",
            "school-year": _ctx.schoolYear,
            enabled: !_ctx.editState.editing,
            "on-change": _ctx.changeSchoolYear
          }, null, 8, ["school-year", "enabled", "on-change"]),
          (_ctx.editState.editing)
            ? (_openBlock(), _createBlock(_component_BaseButton, {
                key: 0,
                class: "copy-from-previous-year-button",
                label: "Copy from previous year",
                color: "light-black",
                "background-color-on-hover": "gray-transparent",
                "icon-type": "far",
                icon: "copy",
                "icon-size": 13,
                "border-width": 0,
                "font-size": 13,
                bold: false,
                width: 180,
                height: 25,
                "on-click": _ctx.copyFromPreviousYear
              }, null, 8, ["on-click"]))
            : _createCommentVNode("", true)
        ])
      ]),
      (_ctx.editState.editing)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_CurriculumEditTable, {
              class: "table",
              data: _ctx.editState.data,
              editing: true,
              "on-update": _ctx.onUpdate
            }, null, 8, ["data", "on-update"])
          ]))
        : (_ctx.data.hasData)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createVNode(_component_CurriculumEditTable, {
                class: "table",
                data: _ctx.viewData,
                editing: false
              }, null, 8, ["data"])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createVNode(_component_LoadingBlock)
            ]))
    ])
  ]))
}