
import { computed, defineComponent, PropType } from "vue";
import ClassSubPageHeading from "@/views/class/_components/ClassSubPageHeading/ClassSubPageHeading.vue";
import SchoolYearSwitch from "@/components/other/SchoolYearSwitch/SchoolYearSwitch.vue";
import { Class } from "@/ts/objects/entity/class";
import EditButtonSet from "@/components/other/EditButtonSet/EditButtonSet.vue";
import ClassDetailsTable from "@/views/class/ClassDetails/ClassDetailsTable/ClassDetailsTable.vue";
import { LoadableData } from "@/ts/app/loadable-data";
import {
  ClassDetailsData,
  ClassDetailsEditState,
  ClassDetailsTableData,
} from "@/views/class/ClassDetails/class-details-data";
import { schoolTypes } from "@/ts/objects/value/school-type";
import SimpleTextItem0 from "@/components/texts/SimpleTextItem0/SimpleTextItem0.vue";
import ClassDetailsTitle from "@/views/class/ClassDetails/ClassDetailsTitle/ClassDetailsTitle.vue";
import { names } from "@/ts/app/object-name";
import DeleteButton from "@/components/buttons/DeleteButton/DeleteButton.vue";

export default defineComponent({
  name: "ClassDetails",
  components: {
    DeleteButton,
    ClassDetailsTitle,
    SimpleTextItem0,
    ClassDetailsTable,
    EditButtonSet,
    SchoolYearSwitch,
    ClassSubPageHeading,
  },
  props: {
    isAdmin: { type: Boolean, required: true },

    cls: { type: Object as PropType<LoadableData<Class>>, required: true },

    /**
     * 閲覧モード時に用いるデータ。
     *
     * 編集中には表示に使わないし、変更もされない。
     * 編集を開始したとき、このデータが編集用データとして複製される。
     * 編集をキャンセルしたとき、このデータに戻る。
     * 編集をセーブしたとき、このデータが上書きされる。
     */
    data: {
      type: Object as PropType<LoadableData<ClassDetailsData>>,
      required: true,
    },
    /**
     * 編集状態。
     * editingフラグと、編集モード時に用いるデータを持つ。
     */
    editState: {
      type: Object as PropType<ClassDetailsEditState>,
      required: true,
    },

    onUpdate: {
      type: Function as PropType<(updated: ClassDetailsTableData) => void>,
      required: true,
    },

    onClickBack: { type: Function as PropType<() => void>, required: true },
    startEditing: { type: Function as PropType<() => void>, required: true },
    save: { type: Function as PropType<() => void>, required: true },
    cancelEditing: { type: Function as PropType<() => void>, required: true },
    onClickDelete: { type: Function as PropType<() => void>, required: true },
  },
  setup(props) {
    const schoolTypeText = computed(() => {
      if (!props.cls.hasData) return "";
      return schoolTypes[props.cls.data.grade.schoolType];
    });

    const classIdDisplayName = names.classId.d;
    const teacherDisplayName = names.classTeachers.d;
    const studentDisplayName = names.classStudents.d;

    return {
      schoolTypeText,

      classIdDisplayName,
      teacherDisplayName,
      studentDisplayName,

      onUpdateInternal: (updated: ClassDetailsTableData) => {
        if (!props.editState.editing) return;
        props.onUpdate(updated);
      },
    };
  },
});
