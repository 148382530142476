
import { computed, defineComponent, PropType } from "vue";
import ClassPageHeading from "@/views/class/_components/ClassPageHeading/ClassPageHeading.vue";
import SchoolYearSwitch from "@/components/other/SchoolYearSwitch/SchoolYearSwitch.vue";
import ClassListTable from "@/views/class/ClassList/ClassListTable/ClassListTable.vue";
import { Class } from "@/ts/objects/entity/class";
import { LoadableData } from "@/ts/app/loadable-data";
import EditButtonSet from "@/components/other/EditButtonSet/EditButtonSet.vue";
import {
  ClassListData,
  classListDataFromClasses,
  ClassListEditState,
  ClassListItemData,
} from "@/views/class/ClassList/class-list-data";
import { SchoolType } from "@/ts/objects/value/school-type";
import SchoolTypeTabs from "@/components/other/SchoolTypeTabs/SchoolTypeTabs.vue";

export default defineComponent({
  name: "ClassList",
  components: {
    SchoolTypeTabs,
    EditButtonSet,
    ClassListTable,
    SchoolYearSwitch,
    ClassPageHeading,
  },
  props: {
    isAdmin: { type: Boolean, required: true },

    /**
     * 現在選択中の学校タイプ。
     */
    schoolType: { type: String as PropType<SchoolType>, required: true },
    /**
     * 学校タイプを変更する。
     */
    changeSchoolType: {
      type: Function as PropType<(schoolType: SchoolType) => void>,
      required: true,
    },

    schoolYear: { type: Number, required: true },
    changeSchoolYear: {
      type: Function as PropType<(schoolYear: number) => void>,
      required: true,
    },

    /**
     * 閲覧モード時に用いるデータ。
     *
     * 編集中には表示に使わないし、変更もされない。
     * 編集を開始したとき、このデータが編集用データとして複製される。
     * 編集をキャンセルしたとき、このデータに戻る。
     * 編集をセーブしたとき、このデータが上書きされる。
     */
    data: {
      type: Object as PropType<LoadableData<Class[]>>,
      required: true,
    },
    /**
     * 編集状態。
     * editingフラグと、編集モード時に用いるデータを持つ。
     */
    editState: {
      type: Object as PropType<ClassListEditState>,
      required: true,
    },

    onUpdateRow: {
      type: Function as PropType<
        (
          schoolType: SchoolType,
          gradeNumber: number,
          classes: ClassListItemData[],
        ) => void
      >,
      required: true,
    },
    startEditing: { type: Function as PropType<() => void>, required: true },
    cancelEditing: { type: Function as PropType<() => void>, required: true },
    save: { type: Function as PropType<() => void>, required: true },
  },
  setup(props) {
    const viewData = computed<ClassListData>(() =>
      classListDataFromClasses(props.data.data ?? []),
    );
    return {
      viewData,
      onUpdateRowInternal: (
        schoolType: SchoolType,
        gradeNumber: number,
        classes: ClassListItemData[],
      ) => {
        if (!props.editState.editing) return;
        props.onUpdateRow(schoolType, gradeNumber, classes);
      },
    };
  },
});
