
import { computed, defineComponent, PropType } from "vue";
import SearchConditionRadio from "@/components/radios/SearchConditionRadio/SearchConditionRadio.vue";
import EditableItemEnum from "@/components/editable-items/EditableItemEnum/EditableItemEnum.vue";
import { SchoolType, schoolTypes } from "@/ts/objects/value/school-type";
import { Class } from "@/ts/objects/entity/class";
import { hasValue, isNullish, parseIntOrNull } from "@/ts/utils/common-util";
import {
  gradeNumbersOfSchoolType,
  isElementaryGradeNumber,
  isJuniorhighGradeNumber,
} from "@/ts/objects/value/grade-number";

const GRADE_UNSELECTED = "__GRADE_UNSELECTED__";
const CLASS_UNSELECTED = "__CLASS_UNSELECTED__";

export default defineComponent({
  name: "SearchConditionPopupClassSelectRadio",
  components: { EditableItemEnum, SearchConditionRadio },
  props: {
    /**
     * ラジオボタングループの名前。
     */
    radioGroupName: { type: String, required: true },
    /**
     * ラジオボタンを選択した時に送信される値。
     */
    radioValue: { type: String, required: true },
    /**
     * ラジオボタングループの中で選択中の値。
     */
    selectedRadioValue: { type: String, required: true },

    /**
     * 選択中の学校タイプ。
     */
    schoolType: { type: String as PropType<SchoolType>, required: true },
    /**
     * 選択中の学年。
     */
    gradeNumber: { type: Number, default: null },
    /**
     * 選択中のクラスID。
     */
    classId: { type: String, default: null },

    /**
     * 選択肢に現れる可能性のあるすべてのクラス。
     *
     * （選択中の年度の）すべての学校タイプ・学年のクラスを含んでいること。
     * 適切な並び順になっていること。
     */
    allClasses: { type: Array as PropType<Class[]>, required: true },

    /**
     * このラジオボタンが選択されたときに呼び出される。
     */
    onSelect: {
      type: Function as PropType<(radioValue: string) => void>,
      required: true,
    },
    /**
     * 学校タイプ・学年・クラスが変更されたときに呼び出される。
     */
    onChange: {
      type: Function as PropType<
        (
          schoolType: SchoolType, // 選択中の学校タイプ。
          gradeNumber: number | null, // 選択中の学年。
          cls: Class | null, // 選択中のクラス。
        ) => void
      >,
      required: true,
    },
  },
  setup(props) {
    // クラス。
    const getClasses = (
      schoolType: SchoolType,
      gradeNumber: number | null,
      classId: string | null,
    ): Class[] => {
      if (hasValue(classId)) {
        // クラスID指定の場合。
        const cls = props.allClasses.find((c) => c.classId === classId);
        if (
          isNullish(cls) ||
          cls.grade.schoolType !== schoolType ||
          (hasValue(gradeNumber) && cls.grade.gradeNumber !== gradeNumber)
        )
          return [];
        return [cls];
      } else if (hasValue(gradeNumber)) {
        // クラスID未指定で、学年指定の場合。
        return props.allClasses.filter(
          (c) =>
            c.grade.gradeNumber === gradeNumber &&
            c.grade.schoolType === schoolType,
        );
      } else {
        // クラスID未指定、学年未指定の場合。
        return props.allClasses.filter(
          (c) => c.grade.schoolType === schoolType,
        );
      }
    };

    const selectableClasses = computed(() => {
      const schoolType = props.schoolType;
      const gradeNumber = props.gradeNumber;
      if (isNullish(gradeNumber)) return [];

      return props.allClasses.filter(
        (c) =>
          c.grade.gradeNumber === gradeNumber &&
          c.grade.schoolType === schoolType,
      );
    });
    const classOptions = computed(() => [
      { value: CLASS_UNSELECTED, name: "－" },
      ...selectableClasses.value.map((c) => ({
        value: c.classId,
        name: c.name,
      })),
    ]);
    const classInputEnabled = computed(
      () => selectableClasses.value.length > 0,
    );
    const classIdSelectValue = computed(() => {
      if (isNullish(props.classId)) return CLASS_UNSELECTED;
      return props.classId;
    });

    const update = (
      schoolTypeInput: SchoolType,
      gradeNumberInput: number | null,
      classIdInput: string | null,
    ) => {
      // このラジオボタンを選択状態にする。
      props.onSelect(props.radioValue);

      const [schoolType, gradeNumber] = sanitize(
        schoolTypeInput,
        gradeNumberInput,
      );

      if (isNullish(classIdInput)) {
        props.onChange(schoolType, gradeNumber, null);
        return;
      }

      const classes = getClasses(schoolType, gradeNumber, classIdInput);
      const cls = classes.length > 0 ? classes[0] : null;
      props.onChange(schoolType, gradeNumber, cls);
    };

    return {
      schoolTypeOptions: Object.entries(schoolTypes).map(([k, v]) => ({
        name: v,
        value: k,
      })),

      gradeNumberSelectValue: computed(
        () => props.gradeNumber?.toString(10) ?? GRADE_UNSELECTED,
      ),
      gradeNumberOptions: computed(() => [
        { value: GRADE_UNSELECTED, name: "－" },
        ...gradeNumbersOfSchoolType[props.schoolType].map((gradeNumber) => ({
          name: gradeNumber.toString(10),
          value: gradeNumber.toString(10),
        })),
      ]),

      classOptions,
      classInputEnabled,
      classIdSelectValue,

      onSelectInternal: (_radioValue: string) => {
        update(props.schoolType, props.gradeNumber, props.classId);
      },
      onInputSchoolTypeInternal: (value: SchoolType) => {
        update(value, props.gradeNumber, props.classId);
      },
      onInputGradeNumberInternal: (value: string) => {
        const intValue = parseIntOrNull(value);
        update(props.schoolType, intValue, props.classId);
      },
      onInputClassInternal: (value: string) => {
        update(
          props.schoolType,
          props.gradeNumber,
          value === CLASS_UNSELECTED ? null : value,
        );
      },
    };
  },
});

function sanitize(
  schoolType: SchoolType,
  gradeNumber: number | null,
): [SchoolType, number | null] {
  if (isNullish(gradeNumber)) return [schoolType, null];

  if (schoolType === "elementary") {
    if (isElementaryGradeNumber(gradeNumber))
      return ["elementary", gradeNumber];
    return ["elementary", null];
  } else {
    if (isJuniorhighGradeNumber(gradeNumber))
      return ["juniorhigh", gradeNumber];
    return ["juniorhigh", null];
  }
}
