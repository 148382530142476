import { AxiosResponse } from "axios";
import log from "loglevel";
import { combineResults, Result } from "@/ts/app/result";
import { parse, ParseResult, unparse } from "papaparse";
import { errors } from "@/ts/app/error/app-error";
import {
  ExportingRawCSVRow,
  ImportedRawCSVRow,
} from "@/ts/app/columns/csv/raw-csv";
import { hasValue } from "@/ts/utils/common-util";
import mapKeys from "lodash/mapKeys";
import { dateToDateValue, DateValue } from "@/ts/objects/value/date-value";
import { ObjectInternalName } from "@/ts/app/object-name";

/**
 * axiosリクエストを実行する。
 *
 * @param func 実行すべきaxiosリクエスト。
 * @param defaultErrorMessage ユーザー向けのデフォルトエラーメッセージ。
 */
export async function doReq<T>(
  func: () => Promise<AxiosResponse<T>>,
  defaultErrorMessage: string = "エラーが発生しました。",
): Promise<Result<T>> {
  try {
    const resp = await func();
    if (resp.status < 100 || 300 <= resp.status)
      return {
        ok: false,
        error: errors.requestFailed(
          `Error response from service: ${resp.status}: ${resp.statusText}`,
          (resp.data as any).message ?? defaultErrorMessage,
        ),
      };
    log.debug(
      `Successful response from service: ${resp.status}, ${JSON.stringify(
        resp.data,
      )}`,
    );
    return {
      ok: true,
      data: resp.data,
    };
  } catch (e) {
    // TODO ちゃんと400とか500エラーをキャッチできるか？
    console.trace();
    const errorString = JSON.stringify(e);
    log.debug(`Error: ${errorString}`);
    log.error(`Error from server: ${JSON.stringify(e.response)}`);
    return {
      ok: false,
      error: errors.requestFailed(
        errorString,
        e.response?.data?.message ?? defaultErrorMessage,
      ),
    };
  }
}

/**
 * CSVファイルをパースする。
 *
 * @param file
 * @param parseRow
 * @param transformHeaderWith これらの項目定義を使って、ヘッダに含まれるnameをcolIdに変換する。
 * @param errorIfEmpty trueの場合、空（0行）のCSVはエラーとする。
 */
export async function parseCSV<
  T,
  RawRow extends ImportedRawCSVRow,
  ColDef extends { id: ObjectInternalName; name: string },
>(
  file: File,
  parseRow: (
    filename: string,
    rowNumber: number,
    row: RawRow,
    nameToId: Map<string, ObjectInternalName>,
  ) => Result<T>,
  transformHeaderWith: readonly ColDef[],
  errorIfEmpty: boolean = false,
): Promise<Result<T[]>> {
  const csvMimeTypes = [
    "text/csv",
    "application/csv",
    "application/octet-stream",
    "application/vnd.ms-excel",
  ];
  if (!csvMimeTypes.includes(file.type))
    return {
      ok: false,
      error: errors.readCSVFailed(
        `${file.name} is not a CSV file`,
        `CSVファイルを指定してください。`,
      ),
    };

  const nameToId = new Map(transformHeaderWith.map((n) => [n.name, n.id]));

  const result = await new Promise<Result<T[]>>((resolve, _reject) => {
    try {
      parse<RawRow>(file, {
        header: true,
        // transformHeader: (header: string, _index: number): string =>
        //   nameToId[header] ?? header,
        delimiter: ",",
        skipEmptyLines: true,
        complete: (results: ParseResult<RawRow>, _file: Blob) => {
          log.debug(`parseCSV: results.data=${JSON.stringify(results.data)}`);
          const parsedRows = results.data.map((row, idx) =>
            parseRow(file.name, idx + 1, row, nameToId),
          );
          resolve(combineResults(parsedRows));
        },
        error: (error: Error, _file: Blob) => {
          log.error(
            `Failed to parse as CSV (${file.name}): error.name=${error.name}, message=${error.message}`,
          );
          resolve({
            ok: false,
            error: errors.readCSVFailed(
              `parsing csv ${file.name} failed`,
              `CSVを読み込めませんでした。`,
            ),
          });
        },
      });
    } catch (e) {
      log.error(`Failed to parse as CSV (${file.name}): ${JSON.stringify(e)}`);
      resolve({
        ok: false,
        error: errors.readCSVFailed(
          `parsing csv ${file.name} failed`,
          `CSVを読み込めませんでした。`,
        ),
      });
    }
  });

  if (errorIfEmpty && result.ok && result.data.length === 0) {
    return {
      ok: false,
      error: errors.readCSVFailed(
        `${file.name} has zero rows`,
        `CSVファイルの内容が空です。`,
      ),
    };
  }

  return result;
}

/**
 * オブジェクトをunparseし、CSV文字列に変換する。
 *
 * @param rows
 * @param unparseRow
 * @param transformHeaderWith これらの項目定義を使って、colIdをnameに変換してヘッダとする。また、quotesの有無を指定する。
 */
export function unparseCSV<
  T,
  ColDef extends { id: string; name: string; forceQuotes: boolean },
>(
  rows: T[],
  unparseRow: (row: T) => ExportingRawCSVRow,
  transformHeaderWith: ColDef[],
): string {
  const uparsedRows = rows.map(unparseRow);

  const idToColDef = Object.fromEntries(
    transformHeaderWith.map((def) => [def.id, def]),
  );

  const headerTransformedRows = uparsedRows.map((r) =>
    mapKeys(r, (_v, k) => {
      const colDef = idToColDef[k];
      const name = colDef?.name;
      return hasValue(name) ? name : k;
    }),
  );

  const quotesArr =
    uparsedRows.length > 0
      ? Object.keys(uparsedRows[0]).map(
          (k) => idToColDef[k]?.forceQuotes ?? false,
        )
      : undefined;
  return unparse(headerTransformedRows, {
    quotes: quotesArr,
    escapeFormulae: true,
  });
}

export function downloadBlob(blob: Blob, filename: string): void {
  const a = document.createElement("a");
  a.href = URL.createObjectURL(blob);
  a.download = filename;
  a.target = "_blank";
  a.click();
  URL.revokeObjectURL(a.href);
}

export function downloadAsCSV(csvText: string, filename: string): void {
  // BOMはエクセル対策。参考: https://qiita.com/wadahiro/items/eb50ac6bbe2e18cf8813
  const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
  const blob = new Blob([bom, csvText], { type: "text/plain" });
  downloadBlob(blob, filename);
}

export function getToday(): DateValue {
  const date = new Date();
  return dateToDateValue(date);
}
