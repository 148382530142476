import { Result } from "@/ts/app/result";
import {
  FirebaseApp,
  initializeApp as initializeFirebaseApp,
} from "firebase/app";
import { FirebaseStorage, getStorage } from "firebase/storage";
import log from "loglevel";
import { errors } from "@/ts/app/error/app-error";
import { UserService } from "@/ts/services/user-service";
import { isNullish } from "@/ts/utils/common-util";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithCredential,
} from "firebase/auth";
import { AppConfig, DebugConfig } from "@/ts/init/initialize-app";
import { LoginInfo } from "@/ts/objects/value/login-info";

export async function initializeFirebase(
  conf: AppConfig,
  debugConfig: DebugConfig,
  loginInfo: LoginInfo,
  userService: UserService,
): Promise<{
  firebaseApp: FirebaseApp | null;
  firebaseStorage: FirebaseStorage | null;
}> {
  if (debugConfig.debugging)
    return { firebaseApp: null, firebaseStorage: null };

  const initResult = await init(conf.storageBucket);
  if (!initResult.ok) {
    throw new Error("Firebase initialization failed.");
  }

  await signIn(userService, loginInfo.googleIdToken);

  return initResult.data;
}

async function init(
  storageBucketUrl: string,
): Promise<
  Result<{ firebaseApp: FirebaseApp; firebaseStorage: FirebaseStorage }>
> {
  log.debug(`Initializing Firebase.`);

  try {
    const initResp = await fetch("/__/firebase/init.json");
    const initJson = await initResp.json();
    const firebaseApp = initializeFirebaseApp(initJson);
    const firebaseStorage = getStorage(firebaseApp, storageBucketUrl);

    log.debug(`Initialized Firebase with ${JSON.stringify(initJson)}`);

    return { ok: true, data: { firebaseApp, firebaseStorage } };
  } catch (e) {
    const message = `Failed to initialize Firebase: name=${e.name}, message=${e.message}`;
    log.error(message);
    return { ok: false, error: errors.internal(message) };
  }
}

async function signIn(
  userService: UserService,
  googleIdToken: string | null,
): Promise<Result<{ signedIn: boolean }>> {
  if (isNullish(googleIdToken)) {
    log.debug(
      `signInToFirebase: googleIdToken is null. Skipping signing in to Firebase.`,
    );
    return { ok: true, data: { signedIn: false } };
  }

  const firebaseOAuthCredential = GoogleAuthProvider.credential(googleIdToken);

  try {
    const auth = getAuth();

    // ※ もしFirebaseにID登録されていなければ(初回起動時)、IDを自動的に作成してからログインする。
    const firebaseUserCredential = await signInWithCredential(
      auth,
      firebaseOAuthCredential,
    );
    const firebaseIdToken = await firebaseUserCredential.user?.getIdToken(); // たぶん使わないが・・・。
    log.debug(
      `signInToFirebase: Firebase auth completed: firebaseIdToken=${firebaseIdToken}`,
    );

    const result = await userService.updateFirebaseUser();
    if (!result.ok) return result;

    // FirebaseUser情報(customClaims)が更新されたらページリロードする（firebaseIdTokenを最新化するため）
    // 初回ログイン時はreloadが発生！
    if (result.data.updated) {
      window.location.reload();
      return { ok: true, data: { signedIn: false } };
    }

    return { ok: true, data: { signedIn: true } };
  } catch (e) {
    const message = `Failed to sign in to Firebase: name=${e.name}, message=${e.message}`;
    log.error(message);
    return { ok: false, error: errors.internal(message) };
  }
}
