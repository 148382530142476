import { App } from "vue";
import vClickOutside from "click-outside-vue3";
import Toast, {
  PluginOptions as ToastPluginOptions,
  POSITION,
} from "vue-toastification";

export function setupVueApp<T>(app: App<T>): App<T> {
  app.use(vClickOutside);

  const toastOptions: ToastPluginOptions = {
    position: POSITION.BOTTOM_CENTER,
    timeout: 5000,
    closeOnClick: true,
    pauseOnFocusLoss: false,
    pauseOnHover: true,
    draggable: false,
    transition: "Vue-Toastification__fade",
    maxToasts: 3,
    newestOnTop: false,
    hideProgressBar: true,
  };
  app.use(Toast, toastOptions);
  return app;
}
