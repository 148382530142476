import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1e5b2621"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "user-edit-table-item-readonly-users-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextBalloon = _resolveComponent("TextBalloon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.editing)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "user-edit-table-item-readonly-users-container",
        onMouseenter: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onMouseEnter && _ctx.onMouseEnter(...args))),
        onMouseleave: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onMouseLeave && _ctx.onMouseLeave(...args)))
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
          return (_openBlock(), _createElementBlock("div", {
            key: item.userId,
            class: "item"
          }, _toDisplayString(item.displayValue), 1))
        }), 128)),
        (_ctx.hovering)
          ? (_openBlock(), _createBlock(_component_TextBalloon, {
              key: 0,
              text: "この項目は読み取り専用です。",
              "on-cursor": true
            }))
          : _createCommentVNode("", true)
      ], 32))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
          return (_openBlock(), _createBlock(_component_router_link, {
            key: item.userId,
            class: "item link",
            to: item.linkPath
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(item.displayValue), 1)
            ]),
            _: 2
          }, 1032, ["to"]))
        }), 128))
      ]))
}