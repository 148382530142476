import { computed, ComputedRef } from "vue";
import { asPageNameOrNull, PageName } from "@/ts/app/pages/pages";
import { asSchoolTypeOrNull, SchoolType } from "@/ts/objects/value/school-type";
import {
  asTermSubPageNameOrNull,
  TermSubPageName,
} from "@/ts/app/pages/term-sub-pages";
import { useRoute } from "vue-router";

export function useTermRoute(): {
  pageName: ComputedRef<PageName | null>;
  subPageName: ComputedRef<TermSubPageName | null>;
  schoolType: ComputedRef<SchoolType>;
} {
  const route = useRoute();
  const splitPath = computed(() => route.path.split("/"));
  const query = computed(() => route.query);

  const pageName = computed(() => {
    return asPageNameOrNull(splitPath.value[1]);
  });
  const subPageName = computed(() => {
    return asTermSubPageNameOrNull(splitPath.value[2]);
  });

  const schoolType = computed(
    () => asSchoolTypeOrNull(query.value["schoolType"]) ?? "elementary",
  );

  return {
    pageName,
    subPageName,
    schoolType,
  };
}
