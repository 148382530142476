import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseRadio = _resolveComponent("BaseRadio")!

  return (_openBlock(), _createBlock(_component_BaseRadio, {
    "radio-group-name": _ctx.radioGroupName,
    "radio-value": _ctx.radioValue,
    "selected-radio-value": _ctx.selectedRadioValue,
    "font-size": 13,
    "radio-button-scale": 0.8,
    "label-margin-left": 4,
    "on-select": _ctx.onSelect
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["radio-group-name", "radio-value", "selected-radio-value", "radio-button-scale", "on-select"]))
}