import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2d70552b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "class-list-container" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "body" }
const _hoisted_4 = { class: "body-header" }
const _hoisted_5 = { class: "controls" }
const _hoisted_6 = {
  key: 0,
  class: "table-wrap"
}
const _hoisted_7 = {
  key: 1,
  class: "table-wrap"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ClassPageHeading = _resolveComponent("ClassPageHeading")!
  const _component_EditButtonSet = _resolveComponent("EditButtonSet")!
  const _component_SchoolTypeTabs = _resolveComponent("SchoolTypeTabs")!
  const _component_SchoolYearSwitch = _resolveComponent("SchoolYearSwitch")!
  const _component_ClassListTable = _resolveComponent("ClassListTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ClassPageHeading, { class: "page-heading" }),
      (_ctx.isAdmin)
        ? (_openBlock(), _createBlock(_component_EditButtonSet, {
            key: 0,
            class: "edit-button-set",
            editing: _ctx.editState.editing,
            enabled: _ctx.data.hasFreshData || _ctx.editState.editing,
            "on-click-edit": _ctx.startEditing,
            "on-click-save": _ctx.save,
            "on-click-cancel": _ctx.cancelEditing
          }, null, 8, ["editing", "enabled", "on-click-edit", "on-click-save", "on-click-cancel"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_SchoolTypeTabs, {
          class: "tabs",
          "change-tab": _ctx.changeSchoolType,
          "current-tab": _ctx.schoolType,
          enabled: !_ctx.editState.editing
        }, null, 8, ["change-tab", "current-tab", "enabled"]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_SchoolYearSwitch, {
            class: "school-year-switch",
            "school-year": _ctx.schoolYear,
            enabled: !_ctx.editState.editing,
            "on-change": _ctx.changeSchoolYear
          }, null, 8, ["school-year", "enabled", "on-change"])
        ])
      ]),
      (_ctx.editState.editing)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            (_openBlock(), _createBlock(_component_ClassListTable, {
              key: _ctx.schoolType,
              class: "table",
              data: _ctx.editState.data[_ctx.schoolType],
              editing: true,
              "on-update-row": 
            (gradeNumber, classes) =>
              _ctx.onUpdateRowInternal(_ctx.schoolType, gradeNumber, classes)
          
            }, null, 8, ["data", "on-update-row"]))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_7, [
            (_openBlock(), _createBlock(_component_ClassListTable, {
              key: _ctx.schoolType,
              class: "table",
              data: _ctx.viewData[_ctx.schoolType],
              editing: false
            }, null, 8, ["data"]))
          ]))
    ])
  ]))
}