import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-26f28d34"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "user-edit-body-head-upper-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserEditHeaderUserName = _resolveComponent("UserEditHeaderUserName")!
  const _component_SimpleTextItem0 = _resolveComponent("SimpleTextItem0")!
  const _component_DeleteButton = _resolveComponent("DeleteButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_UserEditHeaderUserName, {
      "photo-url": _ctx.photoUrl,
      name: _ctx.displayName.value,
      "name-color": _ctx.displayName.color
    }, null, 8, ["photo-url", "name", "name-color"]),
    _createVNode(_component_SimpleTextItem0, {
      class: "item",
      name: _ctx.googleMailDisplayName,
      value: _ctx.googleMail,
      copyable: true
    }, null, 8, ["name", "value"]),
    _createVNode(_component_SimpleTextItem0, {
      class: "item",
      name: _ctx.userIdDisplayName,
      value: _ctx.userId,
      copyable: true
    }, null, 8, ["name", "value"]),
    (_ctx.editing)
      ? (_openBlock(), _createBlock(_component_DeleteButton, {
          key: 0,
          class: "delete-button",
          enabled: _ctx.editing,
          "on-click": _ctx.deleteUser
        }, null, 8, ["enabled", "on-click"]))
      : _createCommentVNode("", true)
  ]))
}