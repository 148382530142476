import {
  Student,
  StudentCustom,
  StudentHealth,
  StudentProfile,
} from "@/ts/objects/entity/student";
import { fakeGuardianBasicInfos } from "@/ts/objects/entity/guardian-fake";
import { fakeStudentCustomColumnNames } from "@/ts/objects/value/student-custom-column-names-fake";
import { fakeClasses } from "@/ts/objects/entity/class-fake";

export function createFakeStudent(partial: Partial<Student> = {}): Student {
  const defaultUserInfo = {
    userType: "student",
    userId: "student000",
    googleMail: "student000@example.com",
    photoUrl: "https://avatars.dicebear.com/api/jdenticon/aaar.svg",
    name: "瀬戸 空治郎",
  } as const;

  const defaultStudentProfile: StudentProfile = {
    classes: [
      {
        ...fakeClasses["class-2000-e1-1"],
        studentNumber: 14,
      },
      {
        ...fakeClasses["class-2001-e2-1"],
        studentNumber: 15,
      },
      {
        ...fakeClasses["class-2002-j1-1"],
        studentNumber: 16,
      },
    ],
    guardian: fakeGuardianBasicInfos.guardian000,

    nameKana: "せと そらじろう",
    nameRome: "Seto Sorajiro",
    familyName: "瀬戸",
    familyNameKana: "せと",
    givenName: "空治郎",
    givenNameKana: "そらじろう",
    sex: "male",
    nickname: "瀬戸っち",
    nicknameKana: "せとっち",
    birthday: { year: 1991, month: 1, day: 1 },
    applicationDate: { year: 1992, month: 2, day: 2 },
    kindergartenEntranceDate: { year: 1993, month: 3, day: 3 },
    elementarySchoolEntranceDate: { year: 1994, month: 4, day: 4 },
    juniorHighSchoolEntranceDate: { year: 1995, month: 5, day: 5 },
    transferDate: { year: 1996, month: 6, day: 6 },
    graduationDate: { year: 1997, month: 7, day: 7 },
    kindergarten: "瀬戸幼稚園",
    previousSchool: "前瀬戸小学校",
    zipcode: "1234567",
    address:
      "アメリカ合衆国カリフォルニア州シリコンバレー1-2-3瀬戸ハイツ505号室",
    email: "student000@example.com",
    mobilePhone: "09012345678",
    pictureGcsUrl: "https://example.com/pictures/student000",
    country: "日本",
    religion: "仏教",
    commutingBy: "バス",
    bus: "123系統",
    nearestStation: "京橋駅",
    hasLunch: "no",
    activityMonday: "月曜の集い",
    activityTuesday: "火曜の集い",
    activityWednesday: "水曜の集い",
    activityThursday: "木曜の集い",
    activityFriday: "金曜の集い",
  };

  const defaultStudentHealth: StudentHealth = {
    bloodType: "AB",
    normalBodyTemperature: "36.0",
    inoculation: "おたふくかぜ",
    medicalHistory: "至って健康",
    homeDoctor: "高階 経徳",
    foodAllergy: "卵",
    anaphylaxis: "スズメバチ",
    otherAllergy: "うるし",
    asthma: "なし",
  };

  const fakeColNames = fakeStudentCustomColumnNames();
  const defaultStudentCustom: StudentCustom = {
    custom01: { name: fakeColNames.custom01, value: "カスタム項目値01" },
    custom02: { name: fakeColNames.custom02, value: "カスタム項目値02" },
    custom03: { name: fakeColNames.custom03, value: "カスタム項目値03" },
    custom04: { name: fakeColNames.custom04, value: "カスタム項目値04" },
    custom05: { name: fakeColNames.custom05, value: "カスタム項目値05" },
    custom06: { name: fakeColNames.custom06, value: "カスタム項目値06" },
    custom07: { name: fakeColNames.custom07, value: "カスタム項目値07" },
    custom08: { name: fakeColNames.custom08, value: "カスタム項目値08" },
    custom09: { name: fakeColNames.custom09, value: "カスタム項目値09" },
    custom10: { name: fakeColNames.custom10, value: "カスタム項目値10" },
    custom11: { name: fakeColNames.custom11, value: "カスタム項目値11" },
    custom12: { name: fakeColNames.custom12, value: "カスタム項目値12" },
    custom13: { name: fakeColNames.custom13, value: "カスタム項目値13" },
    custom14: { name: fakeColNames.custom14, value: "カスタム項目値14" },
    custom15: { name: fakeColNames.custom15, value: "カスタム項目値15" },
    custom16: { name: fakeColNames.custom16, value: "カスタム項目値16" },
    custom17: { name: fakeColNames.custom17, value: "カスタム項目値17" },
    custom18: { name: fakeColNames.custom18, value: "カスタム項目値18" },
    custom19: { name: fakeColNames.custom19, value: "カスタム項目値19" },
    custom20: { name: fakeColNames.custom20, value: "カスタム項目値20" },
    custom21: { name: fakeColNames.custom21, value: "カスタム項目値21" },
    custom22: { name: fakeColNames.custom22, value: "カスタム項目値22" },
    custom23: { name: fakeColNames.custom23, value: "カスタム項目値23" },
    custom24: { name: fakeColNames.custom24, value: "カスタム項目値24" },
    custom25: { name: fakeColNames.custom25, value: "カスタム項目値25" },
    custom26: { name: fakeColNames.custom26, value: "カスタム項目値26" },
    custom27: { name: fakeColNames.custom27, value: "カスタム項目値27" },
    custom28: { name: fakeColNames.custom28, value: "カスタム項目値28" },
    custom29: { name: fakeColNames.custom29, value: "カスタム項目値29" },
    custom30: { name: fakeColNames.custom30, value: "カスタム項目値30" },
    custom31: { name: fakeColNames.custom31, value: "カスタム項目値31" },
    custom32: { name: fakeColNames.custom32, value: "カスタム項目値32" },
    custom33: { name: fakeColNames.custom33, value: "カスタム項目値33" },
    custom34: { name: fakeColNames.custom34, value: "カスタム項目値34" },
    custom35: { name: fakeColNames.custom35, value: "カスタム項目値35" },
    custom36: { name: fakeColNames.custom36, value: "カスタム項目値36" },
    custom37: { name: fakeColNames.custom37, value: "カスタム項目値37" },
    custom38: { name: fakeColNames.custom38, value: "カスタム項目値38" },
    custom39: { name: fakeColNames.custom39, value: "カスタム項目値39" },
    custom40: { name: fakeColNames.custom40, value: "カスタム項目値40" },
  };

  return {
    ...defaultUserInfo,
    ...defaultStudentProfile,
    ...defaultStudentHealth,
    ...defaultStudentCustom,
    ...partial,
  };
}

export const fakeStudentBasicInfos = {
  student000: {
    userId: "student000",
    googleMail: "student000@example.com",
    userType: "student",
    name: "瀬戸 空治郎",
    photoUrl: "https://avatars.dicebear.com/api/jdenticon/aaar.svg",
  },
  student001: {
    userId: "student001",
    googleMail: "student001@example.com",
    userType: "student",
    name: "山口 みずえ",
    photoUrl: "https://avatars.dicebear.com/api/jdenticon/q.svg",
  },
  student002: {
    userId: "student002",
    googleMail: "student002@example.com",
    userType: "student",
    name: "",
    photoUrl: "",
  },
  student003: {
    userId: "student003",
    googleMail: "student003@example.com",
    userType: "student",
    name: "John Doe",
    photoUrl: "https://avatars.dicebear.com/api/jdenticon/qqz.svg",
  },
  student004: {
    userId: "student004",
    googleMail: "student004@example.com",
    userType: "student",
    name: "John Doe But With veryveryverylonglonglongname!!!!!!!!!!!!!",
    photoUrl: "https://avatars.dicebear.com/api/jdenticon/qqz.svg",
  },
  student005: {
    userId: "student005",
    googleMail: "student005@example.com",
    userType: "student",
    name: "John Doe",
    photoUrl: "https://avatars.dicebear.com/api/jdenticon/qqz.svg",
  },
  student006: {
    userId: "student006",
    googleMail: "student006@example.com",
    userType: "student",
    name: "John Doe",
    photoUrl: "https://avatars.dicebear.com/api/jdenticon/qqz.svg",
  },
  student007: {
    userId: "student007",
    googleMail: "student007@example.com",
    userType: "student",
    name: "John Doe",
    photoUrl: "https://avatars.dicebear.com/api/jdenticon/qqz.svg",
  },
  student008: {
    userId: "student008",
    googleMail: "student008@example.com",
    userType: "student",
    name: "John Doe",
    photoUrl: "https://avatars.dicebear.com/api/jdenticon/qqz.svg",
  },
  student009: {
    userId: "student009",
    googleMail: "student009@example.com",
    userType: "student",
    name: "John Doe",
    photoUrl: "https://avatars.dicebear.com/api/jdenticon/qqz.svg",
  },
  student010: {
    userId: "student010",
    googleMail: "student010@example.com",
    userType: "student",
    name: "Lonely Student",
    photoUrl: "https://avatars.dicebear.com/api/jdenticon/r.svg",
  },
} as const;

export function fakeStudents(): {
  [userId in keyof typeof fakeStudentBasicInfos]: Student;
} {
  return {
    student000: createFakeStudent({
      ...fakeStudentBasicInfos.student000,
      guardian: fakeGuardianBasicInfos.guardian000,
    }),
    student001: createFakeStudent({
      ...fakeStudentBasicInfos.student001,
      guardian: fakeGuardianBasicInfos.guardian001,
    }),
    student002: createFakeStudent({
      ...fakeStudentBasicInfos.student002,
      guardian: fakeGuardianBasicInfos.guardian002,
    }),
    student003: createFakeStudent({
      ...fakeStudentBasicInfos.student003,
      guardian: fakeGuardianBasicInfos.guardian003,
    }),
    student004: createFakeStudent({
      ...fakeStudentBasicInfos.student004,
      guardian: fakeGuardianBasicInfos.guardian003,
    }),
    student005: createFakeStudent({
      ...fakeStudentBasicInfos.student005,
      guardian: fakeGuardianBasicInfos.guardian003,
    }),
    student006: createFakeStudent({
      ...fakeStudentBasicInfos.student006,
      guardian: fakeGuardianBasicInfos.guardian003,
    }),
    student007: createFakeStudent({
      ...fakeStudentBasicInfos.student007,
      guardian: fakeGuardianBasicInfos.guardian003,
    }),
    student008: createFakeStudent({
      ...fakeStudentBasicInfos.student008,
      guardian: fakeGuardianBasicInfos.guardian003,
    }),
    student009: createFakeStudent({
      ...fakeStudentBasicInfos.student009,
      guardian: fakeGuardianBasicInfos.guardian003,
    }),
    student010: createFakeStudent({
      ...fakeStudentBasicInfos.student010,
      guardian: null,
    }),
  };
}
