
import { computed, defineComponent, PropType } from "vue";
import TeacherSubPageHeading from "@/views/teacher/_components/TeacherSubPageHeading/TeacherSubPageHeading.vue";
import EditButtonSet from "@/components/other/EditButtonSet/EditButtonSet.vue";
import UserEditHeaderUpper from "@/components/table/edit/UserEditHeaderUpper/UserEditHeaderUpper.vue";
import TeacherEditTable from "@/views/teacher/TeacherEdit/TeacherEditTable/TeacherEditTable.vue";
import { isNullish } from "@/ts/utils/common-util";
import {
  getTeacherColumnsGrouped,
  TeacherColumnId,
} from "@/ts/app/columns/def/teacher/teacher-column";
import { TeacherEditTableTabValue } from "@/views/teacher/TeacherEdit/teacher-edit-table-tab-value";
import { LoadableData } from "@/ts/app/loadable-data";
import {
  TeacherEditData,
  TeacherEditState,
} from "@/views/teacher/TeacherEdit/teacher-edit-state";
import { Teacher } from "@/ts/objects/entity/teacher";
import UserEditHeaderRole from "@/components/table/edit/UserEditHeaderRole/UserEditHeaderRole.vue";

export default defineComponent({
  name: "TeacherEdit",
  components: {
    UserEditHeaderRole,
    TeacherEditTable,
    UserEditHeaderUpper,
    EditButtonSet,
    TeacherSubPageHeading,
  },
  props: {
    isAdmin: { type: Boolean, required: true },

    schoolYear: { type: Number, required: true },

    /**
     * 閲覧モード時に用いるデータ。
     *
     * 編集中には表示に使わないし、変更もされない。
     * 編集を開始したとき、このデータが編集用データとして複製される。
     * 編集をキャンセルしたとき、このデータに戻る。
     * 編集をセーブしたとき、このデータが上書きされる。
     */
    data: {
      type: Object as PropType<LoadableData<TeacherEditData>>,
      required: true,
    },

    /**
     * 編集状態。
     * editingフラグと、編集モード時に用いるデータを持つ。
     */
    editState: {
      type: Object as PropType<TeacherEditState>,
      required: true,
    },

    selectedTab: {
      type: String as PropType<TeacherEditTableTabValue>,
      required: true,
    },

    onClickBack: { type: Function as PropType<() => void>, required: true },
    startEditing: { type: Function as PropType<() => void>, required: true },
    cancelEditing: { type: Function as PropType<() => void>, required: true },
    save: { type: Function as PropType<() => void>, required: true },
    deleteUser: { type: Function as PropType<() => void>, required: true },

    onInput: {
      type: Function as PropType<
        (columnId: TeacherColumnId, value: any) => void
      >,
      required: true,
    },
    onToggleAdmin: {
      type: Function as PropType<(isAdmin: boolean) => void>,
      required: true,
    },

    onSelectTab: {
      type: Function as PropType<(tab: TeacherEditTableTabValue) => void>,
      required: true,
    },
  },
  setup(props) {
    const teacher = computed<Teacher | null>(() => {
      if (props.editState.editing) return props.editState.data.teacher;
      if (props.data.hasData) return props.data.data.teacher;
      return null;
    });
    const isThisTeacherAdmin = computed<boolean | null>(() => {
      if (props.editState.editing) return props.editState.data.isAdmin;
      if (props.data.hasData) return props.data.data.isAdmin;
      return null;
    });

    const teacherColumns = computed(() => {
      const _teacher = teacher.value;
      if (isNullish(_teacher)) return null;
      return getTeacherColumnsGrouped(props.schoolYear, _teacher);
    });

    const profileColumnGroup = computed(
      () => teacherColumns.value?.profileColumnGroup ?? null,
    );

    return {
      teacher,
      isThisTeacherAdmin,

      profileColumnGroup,
    };
  },
});
