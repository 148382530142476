
import { computed, defineComponent, PropType } from "vue";
import SimpleTextItem1 from "@/components/texts/SimpleTextItem1/SimpleTextItem1.vue";
import { isNullish } from "@/ts/utils/common-util";
import { StudentProfileColumnGroup } from "@/ts/app/columns/def/student/student-profile-column";
import { names } from "@/ts/app/object-name";
import {
  isSchoolType,
  schoolTypeToDisplayValue,
} from "@/ts/objects/value/school-type";
import { userEditPagePath } from "@/router/path-constructors";

export default defineComponent({
  name: "StudentEditHeaderLower",
  components: { SimpleTextItem1 },
  props: {
    profileColumnGroup: {
      type: Object as PropType<StudentProfileColumnGroup>,
      required: true,
    },
    linkEnabled: { type: Boolean, required: true },
  },
  setup(props) {
    const schoolType = computed(() => {
      const _schoolType = props.profileColumnGroup.columns.find(
        (col) => col.id === "schoolType",
      )?.value;
      if (!isSchoolType(_schoolType)) return "";
      return schoolTypeToDisplayValue(_schoolType);
    });
    const grade = computed(() => {
      const grade = props.profileColumnGroup.columns.find(
        (col) => col.id === "gradeNumber",
      );
      if (isNullish(grade) || isNullish(grade.value)) return "";
      return `${grade.value}年`;
    });
    const cls = computed(
      () =>
        props.profileColumnGroup.columns.find((col) => col.id === "className")
          ?.value ?? "",
    );
    const studentNumber = computed(
      () =>
        props.profileColumnGroup.columns
          .find((col) => col.id === "studentNumber")
          ?.value?.toString(10) ?? "",
    );

    const guardianColumn = computed(() =>
      props.profileColumnGroup.columns.find((col) => col.id === "guardian"),
    );
    const guardian = computed(() => guardianColumn.value?.value?.name ?? "");
    const guardianLinkPath = computed(() => {
      const userId = guardianColumn.value?.value?.userId;
      if (isNullish(userId)) return null;
      return userEditPagePath("guardian", userId);
    });

    const gradeDisplayName = names.grade.d;
    const classDisplayName = names.class.d;
    const studentNumberDisplayName = names.studentNumber.d;
    const guardianDisplayName = names.guardian.d;

    return {
      schoolType,
      grade,
      cls,
      studentNumber,
      guardian,
      guardianLinkPath,

      gradeDisplayName,
      classDisplayName,
      studentNumberDisplayName,
      guardianDisplayName,
    };
  },
});
