import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CurriculumEdit = _resolveComponent("CurriculumEdit")!

  return (_openBlock(), _createBlock(_component_CurriculumEdit, {
    "is-admin": _ctx.isAdmin,
    "school-type": _ctx.schoolType,
    "change-school-type": _ctx.changeSchoolType,
    "school-year": _ctx.schoolYear,
    "change-school-year": _ctx.changeSchoolYear,
    data: _ctx.curriculums,
    "edit-state": _ctx.editState,
    "on-update": _ctx.onUpdate,
    "copy-from-previous-year": _ctx.copyFromPreviousYear,
    "start-editing": _ctx.startEditing,
    "cancel-editing": _ctx.cancelEditing,
    save: _ctx.save
  }, null, 8, ["is-admin", "school-type", "change-school-type", "school-year", "change-school-year", "data", "edit-state", "on-update", "copy-from-previous-year", "start-editing", "cancel-editing", "save"]))
}