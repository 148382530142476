
import { computed, defineComponent } from "vue";
import { useFileUrl } from "@/composables/provide-firebase-app";

export default defineComponent({
  name: "UserListTableCellImage",
  props: {
    gcsUrl: { type: String, required: false },
  },
  setup(props) {
    const url = useFileUrl(computed(() => props.gcsUrl));

    return {
      url,
    };
  },
});
