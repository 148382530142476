import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_BaseCustomDropdown = _resolveComponent("BaseCustomDropdown")!

  return (_openBlock(), _createBlock(_component_BaseCustomDropdown, {
    class: "menu-dropdown-container",
    "data-testid": "menu-dropdown",
    items: _ctx.items,
    enabled: _ctx.enabled,
    "options-z-index": _ctx.optionsZIndex,
    "options-width": _ctx.optionsWidth,
    "options-align": _ctx.optionsAlign,
    "options-horizontal-position": _ctx.optionsHorizontalPosition,
    "on-select": _ctx.onSelect
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: "wrap",
        style: _normalizeStyle(_ctx.styles)
      }, [
        _createVNode(_component_font_awesome_icon, {
          class: "icon",
          icon: ['fas', 'ellipsis-v']
        })
      ], 4)
    ]),
    _: 1
  }, 8, ["items", "enabled", "options-z-index", "options-width", "options-align", "options-horizontal-position", "on-select"]))
}