
import { computed, defineComponent, PropType } from "vue";
import { StudentClass } from "@/ts/objects/value/student-class";
import { schoolTypeToDisplayValue } from "@/ts/objects/value/school-type";
import { hasValue, isStringNotBlank } from "@/ts/utils/common-util";
import { classDetailsPagePath } from "@/router/path-constructors";

export default defineComponent({
  name: "StudentClassText",
  props: {
    studentClass: { type: Object as PropType<StudentClass>, required: true },

    /**
     * trueなら、クラス詳細画面へのリンクとなる。
     */
    isLink: { type: Boolean, required: true },

    fontSizeSmaller: { type: Number, required: false, default: 10 },
    fontSizeSmall: { type: Number, required: false, default: 11 },
    fontSize: { type: Number, required: false, default: 12 },
    fontSizeBig: { type: Number, required: false, default: 13 },
    wrap: { type: Boolean, required: false, default: true },
  },
  setup(props) {
    const schoolTypeDisplayValue = computed(() =>
      schoolTypeToDisplayValue(props.studentClass.grade.schoolType),
    );
    const className = computed(() =>
      isStringNotBlank(props.studentClass.name)
        ? props.studentClass.name
        : "－",
    );
    const hasStudentNumber = computed(() =>
      hasValue(props.studentClass.studentNumber),
    );
    const studentNumberDisplayValue = computed(() => {
      const _studentNumber = props.studentClass.studentNumber;
      return hasValue(_studentNumber) ? _studentNumber.toString(10) : "(未定)";
    });

    const linkPath = computed(() =>
      classDetailsPagePath(props.studentClass.classId),
    );

    return {
      schoolTypeDisplayValue,
      className,
      hasStudentNumber,
      studentNumberDisplayValue,

      linkPath,

      styles: {
        "--font-size-smaller": `${props.fontSizeSmaller}px`,
        "--font-size-small": `${props.fontSizeSmall}px`,
        "--font-size": `${props.fontSize}px`,
        "--font-size-big": `${props.fontSizeBig}px`,
        "--wrap": props.wrap ? "wrap" : "norwap",
        "--name-white-space": props.wrap ? "normal" : "norwap",
      },
    };
  },
});
