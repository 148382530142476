import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9f6f531e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["aria-label"]
const _hoisted_2 = {
  class: "school-year-cell",
  role: "rowheader"
}
const _hoisted_3 = { class: "text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RemoveButton = _resolveComponent("RemoveButton")!
  const _component_TermEditTableCell = _resolveComponent("TermEditTableCell")!

  return (_openBlock(), _createElementBlock("div", {
    class: "term-edit-table-row-container",
    style: _normalizeStyle(_ctx.styles),
    role: "row",
    "aria-label": _ctx.schoolYear
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.schoolYear), 1),
      (_ctx.editing && _ctx.hasDeleteButton)
        ? (_openBlock(), _createBlock(_component_RemoveButton, {
            key: 0,
            class: "remove-button",
            "aria-label": "Delete School Year",
            "on-click": _ctx.deleteSchoolYearInternal
          }, null, 8, ["on-click"]))
        : _createCommentVNode("", true)
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.maxTermNumber, (termNumber) => {
      return (_openBlock(), _createBlock(_component_TermEditTableCell, {
        key: termNumber,
        class: "cell",
        "aria-label": `${_ctx.formatOrdinalsInternal(termNumber)} Term`,
        model: _ctx.cells[termNumber],
        "on-change-term": 
        (changedStartDate, newDate) =>
          _ctx.onChangeTerm(termNumber, changedStartDate, newDate)
      ,
        editing: _ctx.editing
      }, null, 8, ["aria-label", "model", "on-change-term", "editing"]))
    }), 128))
  ], 12, _hoisted_1))
}