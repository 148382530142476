
import { computed, defineComponent, PropType } from "vue";
import { toUserDisplayName } from "@/ts/objects/value/user-display-name";
import { ClassDetailsRowData } from "@/views/class/ClassDetails/class-details-data";
import { labelToColor } from "@/ts/objects/value/color-label";
import { useHovering } from "@/composables/use-hovering";
import RemoveButton from "@/components/buttons/RemoveButton/RemoveButton.vue";
import EditableItemText from "@/components/editable-items/EditableItemText/EditableItemText.vue";
import EditableItemNumber from "@/components/editable-items/EditableItemNumber/EditableItemNumber.vue";

export default defineComponent({
  name: "ClassDetailsTableRow",
  components: { EditableItemNumber, EditableItemText, RemoveButton },
  props: {
    theme: { type: Number as PropType<0 | 1>, required: true },
    data: { type: Object as PropType<ClassDetailsRowData>, required: true },

    editing: { type: Boolean, required: true },

    onChangeRowTitle: {
      type: Function as PropType<(value: string | number | null) => void>,
      required: true,
    },
    onClickRemove: { type: Function as PropType<() => void>, required: true },
  },
  setup(props) {
    const { hovering, onMouseEnter, onMouseLeave } = useHovering();

    const rowTitle = computed<string>(() => {
      switch (props.data.userType) {
        case "teacher": {
          return props.data.inChargeType;
        }
        case "student": {
          return props.data.studentNumber?.toString(10) ?? "";
        }
        default:
          return "";
      }
    });
    const rowTitleUnit = computed(() =>
      props.data.userType === "student" ? "番" : "",
    );
    const photoUrl = computed(() => props.data.photoUrl);
    const displayName = computed(() =>
      toUserDisplayName(props.data.name, props.editing ? "black" : "link-text"),
    );
    const userEditPath = computed(() => {
      switch (props.data.userType) {
        case "teacher":
          return `/teacher/edit/${props.data.userId}`;
        case "student":
          return `/student/edit/${props.data.userId}`;
        default:
          return "";
      }
    });
    const googleMail = computed(() => props.data.googleMail);

    const onChangeRowTitleInternal = (value: string | number | null) => {
      if (!props.editing) return;
      props.onChangeRowTitle(value);
    };
    const onClickRemoveInternal = () => {
      if (!props.editing) return;
      props.onClickRemove();
    };

    return {
      hovering,
      onMouseEnter,
      onMouseLeave,

      rowTitle,
      rowTitleUnit,

      photoUrl,
      displayName,
      userEditPath,
      googleMail,

      onChangeRowTitleInternal,
      onClickRemoveInternal,

      styles: {
        "--display-name-color": labelToColor(displayName.value.color),
      },
    };
  },
});
