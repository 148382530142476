
import SearchConditionPopupFrame from "@/components/search-condition/SearchConditionPopupFrame/SearchConditionPopupFrame.vue";
import { defineComponent, PropType, ref } from "vue";
import { NumResultsCondition } from "@/ts/objects/search-condition/num-results-condition";
import { isNullish } from "@/ts/utils/common-util";
import SearchConditionRadioSimple from "@/components/radios/SearchConditionRadioSimple/SearchConditionRadioSimple.vue";

export default defineComponent({
  name: "SearchConditionPopupNumResults",
  components: { SearchConditionRadioSimple, SearchConditionPopupFrame },
  props: {
    /**
     * 現在の検索条件。
     * ポップアップの初期値に使う。
     */
    searchCondition: {
      type: Object as PropType<NumResultsCondition>,
      required: true,
    },

    /**
     * キャンセルして閉じる。
     */
    cancel: { type: Function as PropType<() => void>, required: true },
    /**
     * 確定して閉じる。
     */
    confirm: {
      type: Function as PropType<(cond: NumResultsCondition) => void>,
      required: true,
    },
  },
  setup(props) {
    const selectedRadioValue = ref(props.searchCondition.n.toString(10));
    return {
      radioGroupName: "num-results",
      selectedRadioValue,

      onSelect: (value: string) => {
        selectedRadioValue.value = value;
      },
      onClickConfirm: () => {
        const numValue = parseInt(selectedRadioValue.value, 10);
        if (
          isNullish(numValue) ||
          isNaN(numValue) ||
          numValue < 1 ||
          numValue > 1000
        ) {
          props.cancel();
          return;
        }
        props.confirm({ n: numValue });
      },
    };
  },
});
