
import { defineComponent, PropType } from "vue";
import PageHeading from "@/components/other/PageHeading/PageHeading.vue";
import EditButtonSet from "@/components/other/EditButtonSet/EditButtonSet.vue";

export default defineComponent({
  name: "SchoolEditHeader",
  components: { EditButtonSet, PageHeading },
  props: {
    isAdmin: { type: Boolean, required: true },

    editing: { type: Boolean, required: true },
    onClickEdit: { type: Function as PropType<() => void>, required: true },
    onClickCancel: { type: Function as PropType<() => void>, required: true },
    onClickSave: { type: Function as PropType<() => void>, required: true },
  },
});
