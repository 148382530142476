import { SortOrderValue } from "@/ts/objects/value/sort-order";
import { LocationQuery, LocationQueryValue } from "vue-router";

const _subTypes = {
  gradeClassStudentNumber: "gradeClassStudentNumber",
  nameKanaAsc: "nameKanaAsc",
  nameKanaDesc: "nameKanaDesc",
  createdAtDesc: "createdAtDesc",
  createdAtAsc: "createdAtAsc",
  updatedAtDesc: "updatedAtDesc",
  updatedAtAsc: "updatedAtAsc",
} as const;

export const studentSortConditionSubTypes = Object.values(_subTypes);

export type StudentSortConditionSubType =
  typeof studentSortConditionSubTypes[number];

export function isStudentSortConditionSubType(
  value: unknown,
): value is StudentSortConditionSubType {
  return (
    typeof value === "string" &&
    studentSortConditionSubTypes.some((v) => v === value)
  );
}

export function asStudentSortConditionSubTypeOrNull(
  value: unknown,
): StudentSortConditionSubType | null {
  if (isStudentSortConditionSubType(value)) return value;
  return null;
}

export type StudentSortCondition = {
  searchConditionType: "sort-student";
} & (
  | {
      subType: typeof _subTypes["gradeClassStudentNumber"];
      schoolYear: number;
    }
  | {
      subType: typeof _subTypes["nameKanaAsc"];
    }
  | {
      subType: typeof _subTypes["nameKanaDesc"];
    }
  | {
      subType: typeof _subTypes["createdAtDesc"];
    }
  | {
      subType: typeof _subTypes["createdAtAsc"];
    }
  | {
      subType: typeof _subTypes["updatedAtDesc"];
    }
  | {
      subType: typeof _subTypes["updatedAtAsc"];
    }
);

export function studentSortConditionDefault(
  schoolYear: number,
): StudentSortCondition {
  return {
    searchConditionType: "sort-student",
    subType: "gradeClassStudentNumber",
    schoolYear,
  };
}

export function studentSortConditionToDisplayValue(
  cond: StudentSortCondition,
): string {
  switch (cond.subType) {
    case "gradeClassStudentNumber":
      return "学年クラス出席番号順";
    case "nameKanaAsc":
      return "表示名かな昇順";
    case "nameKanaDesc":
      return "表示名かな降順";
    case "createdAtDesc":
      return "作成が新しい順";
    case "createdAtAsc":
      return "作成が古い順";
    case "updatedAtDesc":
      return "更新が新しい順";
    case "updatedAtAsc":
      return "更新が古い順";
  }
}

type QueryParams = {
  sortSubType: string | undefined;
};

export function studentSortConditionFromQueryParams(
  schoolYear: number,
  query: LocationQuery,
): StudentSortCondition {
  const _query = query as Record<
    keyof QueryParams,
    LocationQueryValue | LocationQueryValue[] | undefined
  >;
  const subType = asStudentSortConditionSubTypeOrNull(_query.sortSubType);

  const commonAttr = {
    searchConditionType: "sort-student" as const,
  };
  switch (subType) {
    case "gradeClassStudentNumber":
      return {
        ...commonAttr,
        subType,
        schoolYear,
      };
    case null:
      return studentSortConditionDefault(schoolYear);
    default:
      return {
        ...commonAttr,
        subType,
      };
  }
}

export function studentSortConditionToQueryParams(
  cond: StudentSortCondition,
): QueryParams {
  return {
    sortSubType: cond.subType,
  };
}

export type StudentSortConditionRaw = {
  readonly sortBy:
    | "gradeClassStudentNumber"
    | "nameKana"
    | "createdAt"
    | "updatedAt";
  readonly sortOrder: SortOrderValue;
  readonly schoolYear?: number;
};

export function studentSortConditionToRaw(
  cond: StudentSortCondition,
): StudentSortConditionRaw {
  switch (cond.subType) {
    case "gradeClassStudentNumber":
      return {
        sortBy: "gradeClassStudentNumber",
        sortOrder: "asc",
        schoolYear: cond.schoolYear,
      };
    case "nameKanaAsc":
      return { sortBy: "nameKana", sortOrder: "asc" };
    case "nameKanaDesc":
      return { sortBy: "nameKana", sortOrder: "desc" };
    case "createdAtDesc":
      return { sortBy: "createdAt", sortOrder: "desc" };
    case "createdAtAsc":
      return { sortBy: "createdAt", sortOrder: "asc" };
    case "updatedAtDesc":
      return { sortBy: "updatedAt", sortOrder: "desc" };
    case "updatedAtAsc":
      return { sortBy: "updatedAt", sortOrder: "asc" };
  }
}
