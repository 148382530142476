/* tslint:disable */
/* eslint-disable */
/**
 * ユーザーサービス
 * ユーザならびに学校の情報を取得する
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AuthorizeRequest
 */
export interface AuthorizeRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthorizeRequest
     */
    'redirectUrl'?: string;
}
/**
 * 
 * @export
 * @interface AuthorizeResponse
 */
export interface AuthorizeResponse {
    /**
     * 
     * @type {boolean}
     * @memberof AuthorizeResponse
     */
    'needAuth': boolean;
    /**
     * 
     * @type {string}
     * @memberof AuthorizeResponse
     */
    'authUrl'?: string;
}
/**
 * 
 * @export
 * @interface BasicUserInfo
 */
export interface BasicUserInfo {
    /**
     * 
     * @type {string}
     * @memberof BasicUserInfo
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof BasicUserInfo
     */
    'googleMail': string;
    /**
     * 
     * @type {string}
     * @memberof BasicUserInfo
     */
    'userType': string;
    /**
     * 
     * @type {string}
     * @memberof BasicUserInfo
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BasicUserInfo
     */
    'photoUrl': string;
}
/**
 * 
 * @export
 * @interface BasicUserInfoPartial
 */
export interface BasicUserInfoPartial {
    /**
     * 
     * @type {string}
     * @memberof BasicUserInfoPartial
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof BasicUserInfoPartial
     */
    'googleMail'?: string;
    /**
     * 
     * @type {string}
     * @memberof BasicUserInfoPartial
     */
    'userType'?: string;
    /**
     * 
     * @type {string}
     * @memberof BasicUserInfoPartial
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof BasicUserInfoPartial
     */
    'photoUrl'?: string;
}
/**
 * 子供の検診情報
 * @export
 * @interface ChildHealthV1
 */
export interface ChildHealthV1 {
    /**
     * 血液型
     * @type {string}
     * @memberof ChildHealthV1
     */
    'bloodType'?: string;
    /**
     * 平熱
     * @type {string}
     * @memberof ChildHealthV1
     */
    'favor'?: string;
    /**
     * 予防接種
     * @type {string}
     * @memberof ChildHealthV1
     */
    'inoculation'?: string;
    /**
     * 既往歴
     * @type {string}
     * @memberof ChildHealthV1
     */
    'medicalHistory'?: string;
    /**
     * かかりつけ医
     * @type {string}
     * @memberof ChildHealthV1
     */
    'homeDoctor'?: string;
    /**
     * 食物アレルギー
     * @type {string}
     * @memberof ChildHealthV1
     */
    'foodAllergy'?: string;
    /**
     * アナフィラキシー
     * @type {string}
     * @memberof ChildHealthV1
     */
    'anaphylaxia'?: string;
    /**
     * その他アレルギー
     * @type {string}
     * @memberof ChildHealthV1
     */
    'otherAllergy'?: string;
    /**
     * 喘息
     * @type {string}
     * @memberof ChildHealthV1
     */
    'asthma'?: string;
}
/**
 * 子供のプロファイル情報
 * @export
 * @interface ChildProfileV1
 */
export interface ChildProfileV1 {
    /**
     * 氏名
     * @type {string}
     * @memberof ChildProfileV1
     */
    'name'?: string;
    /**
     * 氏名よみ
     * @type {string}
     * @memberof ChildProfileV1
     */
    'nameKana'?: string;
    /**
     * 通称名
     * @type {string}
     * @memberof ChildProfileV1
     */
    'nickName'?: string;
    /**
     * 通称名よみ
     * @type {string}
     * @memberof ChildProfileV1
     */
    'nickNameKana'?: string;
    /**
     * 誕生日 (yyyyMMdd)
     * @type {string}
     * @memberof ChildProfileV1
     */
    'birthday'?: string;
    /**
     * 性別
     * @type {string}
     * @memberof ChildProfileV1
     */
    'sex'?: ChildProfileV1SexEnum;
    /**
     * 志願申込日
     * @type {string}
     * @memberof ChildProfileV1
     */
    'entryDate'?: string;
    /**
     * 入園年月日 (yyyyMMdd)
     * @type {string}
     * @memberof ChildProfileV1
     */
    'kindergartenEnterDate'?: string;
    /**
     * 小学校入学年月日 (yyyyMMdd)
     * @type {string}
     * @memberof ChildProfileV1
     */
    'primarySchoolEnterDate'?: string;
    /**
     * 中学校入学年月日 (yyyyMMdd)
     * @type {string}
     * @memberof ChildProfileV1
     */
    'juniorHighSchoolEnterDate'?: string;
    /**
     * 転学年月日 (yyyyMMdd)
     * @type {string}
     * @memberof ChildProfileV1
     */
    'transfarDate'?: string;
    /**
     * 卒業年月日 (yyyyMMdd)
     * @type {string}
     * @memberof ChildProfileV1
     */
    'graduationDate'?: string;
    /**
     * 一つ前の学校
     * @type {string}
     * @memberof ChildProfileV1
     */
    'previosSchool'?: string;
    /**
     * 郵便番号
     * @type {string}
     * @memberof ChildProfileV1
     */
    'zip'?: string;
    /**
     * 住所
     * @type {string}
     * @memberof ChildProfileV1
     */
    'address'?: string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof ChildProfileV1
     */
    'mail'?: string;
    /**
     * 携帯番号
     * @type {string}
     * @memberof ChildProfileV1
     */
    'mobilePhone'?: string;
    /**
     * 写真保存パス
     * @type {string}
     * @memberof ChildProfileV1
     */
    'picPath'?: string;
    /**
     * 通学方法
     * @type {string}
     * @memberof ChildProfileV1
     */
    'commuting'?: string;
    /**
     * 国籍
     * @type {string}
     * @memberof ChildProfileV1
     */
    'country'?: string;
    /**
     * 宗教・宗派
     * @type {string}
     * @memberof ChildProfileV1
     */
    'religion'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ChildProfileV1SexEnum {
    Male = 'male',
    Female = 'female',
    Other = 'other'
}

/**
 * クラス。
 * @export
 * @interface Class
 */
export interface Class {
    /**
     * 
     * @type {string}
     * @memberof Class
     */
    'classId': string;
    /**
     * 
     * @type {number}
     * @memberof Class
     */
    'schoolYear': number;
    /**
     * 
     * @type {Grade}
     * @memberof Class
     */
    'grade': Grade;
    /**
     * 
     * @type {number}
     * @memberof Class
     */
    'orderNum': number;
    /**
     * 
     * @type {string}
     * @memberof Class
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ClassStudent
 */
export interface ClassStudent {
    /**
     * 
     * @type {string}
     * @memberof ClassStudent
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof ClassStudent
     */
    'googleMail': string;
    /**
     * 
     * @type {string}
     * @memberof ClassStudent
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ClassStudent
     */
    'photoUrl': string;
    /**
     * 
     * @type {number}
     * @memberof ClassStudent
     */
    'studentNumber'?: number;
}
/**
 * クラス情報
 * @export
 * @interface ClassV1
 */
export interface ClassV1 {
    /**
     * Class識別ID
     * @type {string}
     * @memberof ClassV1
     */
    'id': string;
    /**
     * 小中区分
     * @type {string}
     * @memberof ClassV1
     */
    'schoolType': ClassV1SchoolTypeEnum;
    /**
     * 学年
     * @type {number}
     * @memberof ClassV1
     */
    'grade': number;
    /**
     * クラス番号 (1から始まる連番）
     * @type {number}
     * @memberof ClassV1
     */
    'classNo': number;
    /**
     * クラス通称名（さくら、かえで、など）
     * @type {string}
     * @memberof ClassV1
     */
    'nickName': string;
    /**
     * 担任教師ID
     * @type {number}
     * @memberof ClassV1
     */
    'mainTeacherId'?: number;
    /**
     * 副担任教師ID
     * @type {number}
     * @memberof ClassV1
     */
    'subTeacherId'?: number;
    /**
     * 年度
     * @type {number}
     * @memberof ClassV1
     */
    'year': number;
}

/**
    * @export
    * @enum {string}
    */
export enum ClassV1SchoolTypeEnum {
    Primary = 'Primary',
    Juniorhigh = 'Juniorhigh'
}

/**
 * クラス（書き込み用）。
 * @export
 * @interface ClassWrite
 */
export interface ClassWrite {
    /**
     * 
     * @type {number}
     * @memberof ClassWrite
     */
    'schoolYear'?: number;
    /**
     * 
     * @type {Grade}
     * @memberof ClassWrite
     */
    'grade'?: Grade;
    /**
     * 
     * @type {number}
     * @memberof ClassWrite
     */
    'orderNum'?: number;
    /**
     * 
     * @type {string}
     * @memberof ClassWrite
     */
    'name'?: string;
}
/**
 * カスタム項目の定義と値。
 * @export
 * @interface CustomColumn
 */
export interface CustomColumn {
    /**
     * 
     * @type {string}
     * @memberof CustomColumn
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CustomColumn
     */
    'value': string;
}
/**
 * カスタム項目の定義。
 * @export
 * @interface CustomColumnDef
 */
export interface CustomColumnDef {
    /**
     * 
     * @type {number}
     * @memberof CustomColumnDef
     */
    'number': number;
    /**
     * 
     * @type {string}
     * @memberof CustomColumnDef
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface CustomColumnDefWrite
 */
export interface CustomColumnDefWrite {
    /**
     * 
     * @type {string}
     * @memberof CustomColumnDefWrite
     */
    'name': string;
}
/**
 * 学年。
 * @export
 * @interface Grade
 */
export interface Grade {
    /**
     * `elementary`: 小学校, `juniorhigh`: 中学校
     * @type {string}
     * @memberof Grade
     */
    'schoolType': string;
    /**
     * 小学校は `1`-`6`, 中学校は `1`-`3`。
     * @type {number}
     * @memberof Grade
     */
    'gradeNumber': number;
}
/**
 * 保護者。
 * @export
 * @interface Guardian
 */
export interface Guardian {
    /**
     * 
     * @type {string}
     * @memberof Guardian
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof Guardian
     */
    'googleMail': string;
    /**
     * 
     * @type {string}
     * @memberof Guardian
     */
    'photoUrl': string;
    /**
     * 
     * @type {string}
     * @memberof Guardian
     */
    'name1': string;
    /**
     * 
     * @type {string}
     * @memberof Guardian
     */
    'nameKana1': string;
    /**
     * 
     * @type {string}
     * @memberof Guardian
     */
    'name2': string;
    /**
     * 
     * @type {string}
     * @memberof Guardian
     */
    'nameKana2': string;
    /**
     * 
     * @type {string}
     * @memberof Guardian
     */
    'familyName1': string;
    /**
     * 
     * @type {string}
     * @memberof Guardian
     */
    'givenName1': string;
    /**
     * 
     * @type {string}
     * @memberof Guardian
     */
    'familyName2': string;
    /**
     * 
     * @type {string}
     * @memberof Guardian
     */
    'givenName2': string;
    /**
     * 
     * @type {string}
     * @memberof Guardian
     */
    'email1': string;
    /**
     * 
     * @type {string}
     * @memberof Guardian
     */
    'email2': string;
    /**
     * 
     * @type {string}
     * @memberof Guardian
     */
    'mobilePhone1': string;
    /**
     * 
     * @type {string}
     * @memberof Guardian
     */
    'mobilePhone2': string;
    /**
     * 
     * @type {string}
     * @memberof Guardian
     */
    'landlinePhone1': string;
    /**
     * 
     * @type {string}
     * @memberof Guardian
     */
    'landlinePhone2': string;
    /**
     * 
     * @type {string}
     * @memberof Guardian
     */
    'job1': string;
    /**
     * 
     * @type {string}
     * @memberof Guardian
     */
    'job2': string;
    /**
     * 
     * @type {string}
     * @memberof Guardian
     */
    'workPlace1': string;
    /**
     * 
     * @type {string}
     * @memberof Guardian
     */
    'workPlace2': string;
    /**
     * 
     * @type {string}
     * @memberof Guardian
     */
    'pictureGcsUrl1'?: string;
    /**
     * 
     * @type {string}
     * @memberof Guardian
     */
    'pictureGcsUrl2'?: string;
    /**
     * 
     * @type {string}
     * @memberof Guardian
     */
    'emergencyContactRelationship1': string;
    /**
     * 
     * @type {string}
     * @memberof Guardian
     */
    'emergencyContactPhone1': string;
    /**
     * 
     * @type {string}
     * @memberof Guardian
     */
    'emergencyContactRelationship2': string;
    /**
     * 
     * @type {string}
     * @memberof Guardian
     */
    'emergencyContactPhone2': string;
    /**
     * 
     * @type {string}
     * @memberof Guardian
     */
    'emergencyContactRelationship3': string;
    /**
     * 
     * @type {string}
     * @memberof Guardian
     */
    'emergencyContactPhone3': string;
    /**
     * 
     * @type {string}
     * @memberof Guardian
     */
    'note01': string;
    /**
     * 
     * @type {string}
     * @memberof Guardian
     */
    'note02': string;
    /**
     * 
     * @type {string}
     * @memberof Guardian
     */
    'note03': string;
    /**
     * 
     * @type {string}
     * @memberof Guardian
     */
    'note04': string;
    /**
     * 
     * @type {string}
     * @memberof Guardian
     */
    'note05': string;
    /**
     * 
     * @type {string}
     * @memberof Guardian
     */
    'note06': string;
    /**
     * 
     * @type {string}
     * @memberof Guardian
     */
    'note07': string;
    /**
     * 
     * @type {string}
     * @memberof Guardian
     */
    'note08': string;
    /**
     * 
     * @type {string}
     * @memberof Guardian
     */
    'note09': string;
    /**
     * 
     * @type {string}
     * @memberof Guardian
     */
    'note10': string;
    /**
     * 
     * @type {Array<Student>}
     * @memberof Guardian
     */
    'children': Array<Student>;
}
/**
 * 保護者のプロファイル情報 プロファイルは２名分 子供のプロファイルは児童生徒プロファイルで取得する必要がある。
 * @export
 * @interface GuardianProfileV1
 */
export interface GuardianProfileV1 {
    /**
     * 保護者ID
     * @type {number}
     * @memberof GuardianProfileV1
     */
    'id'?: number;
    /**
     * 保護者プロファイル（最大２名分）
     * @type {Array<GuardianProfileV1Profile>}
     * @memberof GuardianProfileV1
     */
    'profile'?: Array<GuardianProfileV1Profile>;
}
/**
 * 
 * @export
 * @interface GuardianProfileV1Profile
 */
export interface GuardianProfileV1Profile {
    /**
     * 連番
     * @type {number}
     * @memberof GuardianProfileV1Profile
     */
    'seq'?: number;
    /**
     * 氏名
     * @type {string}
     * @memberof GuardianProfileV1Profile
     */
    'name'?: string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof GuardianProfileV1Profile
     */
    'mail'?: string;
    /**
     * 携帯電話番号
     * @type {string}
     * @memberof GuardianProfileV1Profile
     */
    'mobilePhone'?: string;
    /**
     * 職業
     * @type {string}
     * @memberof GuardianProfileV1Profile
     */
    'job'?: string;
    /**
     * 勤務地
     * @type {string}
     * @memberof GuardianProfileV1Profile
     */
    'workPlace'?: string;
    /**
     * 写真保存パス
     * @type {string}
     * @memberof GuardianProfileV1Profile
     */
    'picPath'?: string;
}
/**
 * 
 * @export
 * @interface GuardianSearchResult
 */
export interface GuardianSearchResult {
    /**
     * 
     * @type {Array<Guardian>}
     * @memberof GuardianSearchResult
     */
    'data': Array<Guardian>;
    /**
     * 
     * @type {number}
     * @memberof GuardianSearchResult
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof GuardianSearchResult
     */
    'offset': number;
    /**
     * 
     * @type {number}
     * @memberof GuardianSearchResult
     */
    'totalCount': number;
}
/**
 * 保護者基本情報
 * @export
 * @interface GuardianV1
 */
export interface GuardianV1 {
    /**
     * 保護者ID
     * @type {string}
     * @memberof GuardianV1
     */
    'id'?: string;
    /**
     * Gsuiteアカウント
     * @type {string}
     * @memberof GuardianV1
     */
    'account'?: string;
    /**
     * 保護者１氏名
     * @type {string}
     * @memberof GuardianV1
     */
    'name1'?: string;
    /**
     * 保護者２氏名
     * @type {string}
     * @memberof GuardianV1
     */
    'name2'?: string;
    /**
     * 子供情報
     * @type {Array<Student>}
     * @memberof GuardianV1
     */
    'children'?: Array<Student>;
}
/**
 * 保護者（書き込み用）。
 * @export
 * @interface GuardianWrite
 */
export interface GuardianWrite {
    /**
     * 
     * @type {string}
     * @memberof GuardianWrite
     */
    'name1'?: string;
    /**
     * 
     * @type {string}
     * @memberof GuardianWrite
     */
    'nameKana1'?: string;
    /**
     * 
     * @type {string}
     * @memberof GuardianWrite
     */
    'name2'?: string;
    /**
     * 
     * @type {string}
     * @memberof GuardianWrite
     */
    'nameKana2'?: string;
    /**
     * 
     * @type {string}
     * @memberof GuardianWrite
     */
    'familyName1'?: string;
    /**
     * 
     * @type {string}
     * @memberof GuardianWrite
     */
    'givenName1'?: string;
    /**
     * 
     * @type {string}
     * @memberof GuardianWrite
     */
    'familyName2'?: string;
    /**
     * 
     * @type {string}
     * @memberof GuardianWrite
     */
    'givenName2'?: string;
    /**
     * 
     * @type {string}
     * @memberof GuardianWrite
     */
    'email1'?: string;
    /**
     * 
     * @type {string}
     * @memberof GuardianWrite
     */
    'email2'?: string;
    /**
     * 
     * @type {string}
     * @memberof GuardianWrite
     */
    'mobilePhone1'?: string;
    /**
     * 
     * @type {string}
     * @memberof GuardianWrite
     */
    'mobilePhone2'?: string;
    /**
     * 
     * @type {string}
     * @memberof GuardianWrite
     */
    'landlinePhone1'?: string;
    /**
     * 
     * @type {string}
     * @memberof GuardianWrite
     */
    'landlinePhone2'?: string;
    /**
     * 
     * @type {string}
     * @memberof GuardianWrite
     */
    'job1'?: string;
    /**
     * 
     * @type {string}
     * @memberof GuardianWrite
     */
    'job2'?: string;
    /**
     * 
     * @type {string}
     * @memberof GuardianWrite
     */
    'workPlace1'?: string;
    /**
     * 
     * @type {string}
     * @memberof GuardianWrite
     */
    'workPlace2'?: string;
    /**
     * 
     * @type {string}
     * @memberof GuardianWrite
     */
    'emergencyContactRelationship1'?: string;
    /**
     * 
     * @type {string}
     * @memberof GuardianWrite
     */
    'emergencyContactPhone1'?: string;
    /**
     * 
     * @type {string}
     * @memberof GuardianWrite
     */
    'emergencyContactRelationship2'?: string;
    /**
     * 
     * @type {string}
     * @memberof GuardianWrite
     */
    'emergencyContactPhone2'?: string;
    /**
     * 
     * @type {string}
     * @memberof GuardianWrite
     */
    'emergencyContactRelationship3'?: string;
    /**
     * 
     * @type {string}
     * @memberof GuardianWrite
     */
    'emergencyContactPhone3'?: string;
    /**
     * 
     * @type {string}
     * @memberof GuardianWrite
     */
    'note01'?: string;
    /**
     * 
     * @type {string}
     * @memberof GuardianWrite
     */
    'note02'?: string;
    /**
     * 
     * @type {string}
     * @memberof GuardianWrite
     */
    'note03'?: string;
    /**
     * 
     * @type {string}
     * @memberof GuardianWrite
     */
    'note04'?: string;
    /**
     * 
     * @type {string}
     * @memberof GuardianWrite
     */
    'note05'?: string;
    /**
     * 
     * @type {string}
     * @memberof GuardianWrite
     */
    'note06'?: string;
    /**
     * 
     * @type {string}
     * @memberof GuardianWrite
     */
    'note07'?: string;
    /**
     * 
     * @type {string}
     * @memberof GuardianWrite
     */
    'note08'?: string;
    /**
     * 
     * @type {string}
     * @memberof GuardianWrite
     */
    'note09'?: string;
    /**
     * 
     * @type {string}
     * @memberof GuardianWrite
     */
    'note10'?: string;
}
/**
 * 保護者（書き込み用）とユーザーIDのペア。一括更新で用いる。
 * @export
 * @interface GuardianWriteWithUserId
 */
export interface GuardianWriteWithUserId {
    /**
     * 
     * @type {string}
     * @memberof GuardianWriteWithUserId
     */
    'userId': string;
    /**
     * 
     * @type {GuardianWrite}
     * @memberof GuardianWriteWithUserId
     */
    'request': GuardianWrite;
}
/**
 * get-identity問い合わせへの返却値。
 * @export
 * @interface Identity
 */
export interface Identity {
    /**
     * `teacher`, `staff`, `student`, `guardian`, `none`のいずれか。
     * @type {string}
     * @memberof Identity
     */
    'userType': string;
    /**
     * `teacher`, `staff`, `none`の場合はnull。
     * @type {object}
     * @memberof Identity
     */
    'info'?: object;
}
/**
 * 
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
    /**
     * 
     * @type {Array<string>}
     * @memberof InlineObject
     */
    'userIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface InlineObject1
 */
export interface InlineObject1 {
    /**
     * 
     * @type {Array<string>}
     * @memberof InlineObject1
     */
    'userIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface InlineObject3
 */
export interface InlineObject3 {
    /**
     * 
     * @type {Array<string>}
     * @memberof InlineObject3
     */
    'userIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * 
     * @type {boolean}
     * @memberof InlineResponse200
     */
    'updated'?: boolean;
}
/**
 * ロール紐づけ。
 * @export
 * @interface RoleBinding
 */
export interface RoleBinding {
    /**
     * 
     * @type {string}
     * @memberof RoleBinding
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof RoleBinding
     */
    'roleId': string;
}
/**
 * 
 * @export
 * @interface School
 */
export interface School {
    /**
     * 
     * @type {string}
     * @memberof School
     */
    'schoolName': string;
    /**
     * 
     * @type {string}
     * @memberof School
     */
    'logoGcsUrl'?: string;
}
/**
 * 学校基本情報
 * @export
 * @interface SchoolV1
 */
export interface SchoolV1 {
    /**
     * 学校名
     * @type {string}
     * @memberof SchoolV1
     */
    'name'?: string;
    /**
     * 学校ロゴパス
     * @type {string}
     * @memberof SchoolV1
     */
    'logo'?: string;
    /**
     * 現在の年度
     * @type {number}
     * @memberof SchoolV1
     */
    'currentYear'?: number;
    /**
     * 学期情報
     * @type {Array<SemesterV1>}
     * @memberof SchoolV1
     */
    'semesters'?: Array<SemesterV1>;
}
/**
 * 
 * @export
 * @interface SchoolWrite
 */
export interface SchoolWrite {
    /**
     * 
     * @type {string}
     * @memberof SchoolWrite
     */
    'schoolName'?: string;
}
/**
 * 学期
 * @export
 * @interface SemesterV1
 */
export interface SemesterV1 {
    /**
     * 年度
     * @type {number}
     * @memberof SemesterV1
     */
    'year': number;
    /**
     * 学校タイプ（小学校 or 中学校）
     * @type {string}
     * @memberof SemesterV1
     */
    'schoolType': string;
    /**
     * 学期
     * @type {number}
     * @memberof SemesterV1
     */
    'semester': number;
    /**
     * 開始年月日 (yyyyMMdd)
     * @type {string}
     * @memberof SemesterV1
     */
    'startDate': string;
    /**
     * 終了年月日 (yyyyMMdd)
     * @type {string}
     * @memberof SemesterV1
     */
    'endDate': string;
}
/**
 * 教職員情報
 * @export
 * @interface StaffV1
 */
export interface StaffV1 {
    /**
     * 教職員ID
     * @type {string}
     * @memberof StaffV1
     */
    'id'?: string;
    /**
     * Gsuiteアカウント
     * @type {string}
     * @memberof StaffV1
     */
    'account'?: string;
    /**
     * 
     * @type {StaffV1Profile}
     * @memberof StaffV1
     */
    'profile'?: StaffV1Profile;
}
/**
 * プロファイル情報
 * @export
 * @interface StaffV1Profile
 */
export interface StaffV1Profile {
    /**
     * 氏名
     * @type {string}
     * @memberof StaffV1Profile
     */
    'name'?: string;
    /**
     * 郵便番号
     * @type {string}
     * @memberof StaffV1Profile
     */
    'zip'?: string;
    /**
     * 住所
     * @type {string}
     * @memberof StaffV1Profile
     */
    'address'?: string;
    /**
     * 電話番号
     * @type {string}
     * @memberof StaffV1Profile
     */
    'phoneNo'?: string;
    /**
     * 外国人タイプ (0:日本人 1:外国人)
     * @type {string}
     * @memberof StaffV1Profile
     */
    'foeighType'?: StaffV1ProfileFoeighTypeEnum;
    /**
     * 教職員タイプ
     * @type {string}
     * @memberof StaffV1Profile
     */
    'teacherType'?: StaffV1ProfileTeacherTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum StaffV1ProfileFoeighTypeEnum {
    Japanese = 'japanese',
    Foeigher = 'foeigher'
}
/**
    * @export
    * @enum {string}
    */
export enum StaffV1ProfileTeacherTypeEnum {
    Teacher = 'teacher',
    Staff = 'staff',
    Other = 'other'
}

/**
 * 児童生徒。
 * @export
 * @interface Student
 */
export interface Student {
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    'userId': string;
    /**
     * 
     * @type {BasicUserInfoPartial}
     * @memberof Student
     */
    'guardian'?: BasicUserInfoPartial;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    'googleMail': string;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    'photoUrl': string;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    'nameKana': string;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    'nameRome': string;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    'familyName': string;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    'familyNameKana': string;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    'givenName': string;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    'givenNameKana': string;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    'sex': string;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    'nickname': string;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    'nicknameKana': string;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    'birthday'?: string;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    'applicationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    'kindergartenEntranceDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    'elementarySchoolEntranceDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    'juniorHighSchoolEntranceDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    'transferDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    'graduationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    'kindergarten': string;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    'previousSchool': string;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    'zipcode': string;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    'mobilePhone': string;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    'pictureGcsUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    'religion': string;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    'commutingBy': string;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    'bus': string;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    'nearestStation': string;
    /**
     * `yes`, `no` のいずれか。
     * @type {string}
     * @memberof Student
     */
    'hasLunch': string;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    'activityMonday': string;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    'activityTuesday': string;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    'activityWednesday': string;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    'activityThursday': string;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    'activityFriday': string;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    'bloodType': string;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    'normalBodyTemperature': string;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    'inoculation': string;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    'medicalHistory': string;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    'homeDoctor': string;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    'foodAllergy': string;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    'anaphylaxis': string;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    'otherAllergy': string;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    'asthma': string;
    /**
     * 
     * @type {CustomColumn}
     * @memberof Student
     */
    'custom01': CustomColumn;
    /**
     * 
     * @type {CustomColumn}
     * @memberof Student
     */
    'custom02': CustomColumn;
    /**
     * 
     * @type {CustomColumn}
     * @memberof Student
     */
    'custom03': CustomColumn;
    /**
     * 
     * @type {CustomColumn}
     * @memberof Student
     */
    'custom04': CustomColumn;
    /**
     * 
     * @type {CustomColumn}
     * @memberof Student
     */
    'custom05': CustomColumn;
    /**
     * 
     * @type {CustomColumn}
     * @memberof Student
     */
    'custom06': CustomColumn;
    /**
     * 
     * @type {CustomColumn}
     * @memberof Student
     */
    'custom07': CustomColumn;
    /**
     * 
     * @type {CustomColumn}
     * @memberof Student
     */
    'custom08': CustomColumn;
    /**
     * 
     * @type {CustomColumn}
     * @memberof Student
     */
    'custom09': CustomColumn;
    /**
     * 
     * @type {CustomColumn}
     * @memberof Student
     */
    'custom10': CustomColumn;
    /**
     * 
     * @type {CustomColumn}
     * @memberof Student
     */
    'custom11': CustomColumn;
    /**
     * 
     * @type {CustomColumn}
     * @memberof Student
     */
    'custom12': CustomColumn;
    /**
     * 
     * @type {CustomColumn}
     * @memberof Student
     */
    'custom13': CustomColumn;
    /**
     * 
     * @type {CustomColumn}
     * @memberof Student
     */
    'custom14': CustomColumn;
    /**
     * 
     * @type {CustomColumn}
     * @memberof Student
     */
    'custom15': CustomColumn;
    /**
     * 
     * @type {CustomColumn}
     * @memberof Student
     */
    'custom16': CustomColumn;
    /**
     * 
     * @type {CustomColumn}
     * @memberof Student
     */
    'custom17': CustomColumn;
    /**
     * 
     * @type {CustomColumn}
     * @memberof Student
     */
    'custom18': CustomColumn;
    /**
     * 
     * @type {CustomColumn}
     * @memberof Student
     */
    'custom19': CustomColumn;
    /**
     * 
     * @type {CustomColumn}
     * @memberof Student
     */
    'custom20': CustomColumn;
    /**
     * 
     * @type {CustomColumn}
     * @memberof Student
     */
    'custom21': CustomColumn;
    /**
     * 
     * @type {CustomColumn}
     * @memberof Student
     */
    'custom22': CustomColumn;
    /**
     * 
     * @type {CustomColumn}
     * @memberof Student
     */
    'custom23': CustomColumn;
    /**
     * 
     * @type {CustomColumn}
     * @memberof Student
     */
    'custom24': CustomColumn;
    /**
     * 
     * @type {CustomColumn}
     * @memberof Student
     */
    'custom25': CustomColumn;
    /**
     * 
     * @type {CustomColumn}
     * @memberof Student
     */
    'custom26': CustomColumn;
    /**
     * 
     * @type {CustomColumn}
     * @memberof Student
     */
    'custom27': CustomColumn;
    /**
     * 
     * @type {CustomColumn}
     * @memberof Student
     */
    'custom28': CustomColumn;
    /**
     * 
     * @type {CustomColumn}
     * @memberof Student
     */
    'custom29': CustomColumn;
    /**
     * 
     * @type {CustomColumn}
     * @memberof Student
     */
    'custom30': CustomColumn;
    /**
     * 
     * @type {CustomColumn}
     * @memberof Student
     */
    'custom31': CustomColumn;
    /**
     * 
     * @type {CustomColumn}
     * @memberof Student
     */
    'custom32': CustomColumn;
    /**
     * 
     * @type {CustomColumn}
     * @memberof Student
     */
    'custom33': CustomColumn;
    /**
     * 
     * @type {CustomColumn}
     * @memberof Student
     */
    'custom34': CustomColumn;
    /**
     * 
     * @type {CustomColumn}
     * @memberof Student
     */
    'custom35': CustomColumn;
    /**
     * 
     * @type {CustomColumn}
     * @memberof Student
     */
    'custom36': CustomColumn;
    /**
     * 
     * @type {CustomColumn}
     * @memberof Student
     */
    'custom37': CustomColumn;
    /**
     * 
     * @type {CustomColumn}
     * @memberof Student
     */
    'custom38': CustomColumn;
    /**
     * 
     * @type {CustomColumn}
     * @memberof Student
     */
    'custom39': CustomColumn;
    /**
     * 
     * @type {CustomColumn}
     * @memberof Student
     */
    'custom40': CustomColumn;
    /**
     * 
     * @type {Array<StudentClass>}
     * @memberof Student
     */
    'classes': Array<StudentClass>;
}
/**
 * 児童生徒クラス。
 * @export
 * @interface StudentClass
 */
export interface StudentClass {
    /**
     * 
     * @type {string}
     * @memberof StudentClass
     */
    'classId': string;
    /**
     * 
     * @type {number}
     * @memberof StudentClass
     */
    'schoolYear': number;
    /**
     * 
     * @type {Grade}
     * @memberof StudentClass
     */
    'grade': Grade;
    /**
     * 
     * @type {number}
     * @memberof StudentClass
     */
    'orderNum': number;
    /**
     * 
     * @type {string}
     * @memberof StudentClass
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof StudentClass
     */
    'studentNumber'?: number;
}
/**
 * 
 * @export
 * @interface StudentClassV1
 */
export interface StudentClassV1 {
    /**
     * 
     * @type {string}
     * @memberof StudentClassV1
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof StudentClassV1
     */
    'schoolType': string;
    /**
     * 
     * @type {number}
     * @memberof StudentClassV1
     */
    'grade': number;
    /**
     * 
     * @type {number}
     * @memberof StudentClassV1
     */
    'classNo': number;
    /**
     * 
     * @type {string}
     * @memberof StudentClassV1
     */
    'nickName': string;
    /**
     * 
     * @type {string}
     * @memberof StudentClassV1
     */
    'mainTeacherId': string;
    /**
     * 
     * @type {string}
     * @memberof StudentClassV1
     */
    'subTeacherId': string;
    /**
     * 
     * @type {number}
     * @memberof StudentClassV1
     */
    'year': number;
    /**
     * 
     * @type {number}
     * @memberof StudentClassV1
     */
    'studentNumber': number;
}
/**
 * 
 * @export
 * @interface StudentCustomColumnDefSet
 */
export interface StudentCustomColumnDefSet {
    /**
     * 
     * @type {CustomColumnDef}
     * @memberof StudentCustomColumnDefSet
     */
    'custom01': CustomColumnDef;
    /**
     * 
     * @type {CustomColumnDef}
     * @memberof StudentCustomColumnDefSet
     */
    'custom02': CustomColumnDef;
    /**
     * 
     * @type {CustomColumnDef}
     * @memberof StudentCustomColumnDefSet
     */
    'custom03': CustomColumnDef;
    /**
     * 
     * @type {CustomColumnDef}
     * @memberof StudentCustomColumnDefSet
     */
    'custom04': CustomColumnDef;
    /**
     * 
     * @type {CustomColumnDef}
     * @memberof StudentCustomColumnDefSet
     */
    'custom05': CustomColumnDef;
    /**
     * 
     * @type {CustomColumnDef}
     * @memberof StudentCustomColumnDefSet
     */
    'custom06': CustomColumnDef;
    /**
     * 
     * @type {CustomColumnDef}
     * @memberof StudentCustomColumnDefSet
     */
    'custom07': CustomColumnDef;
    /**
     * 
     * @type {CustomColumnDef}
     * @memberof StudentCustomColumnDefSet
     */
    'custom08': CustomColumnDef;
    /**
     * 
     * @type {CustomColumnDef}
     * @memberof StudentCustomColumnDefSet
     */
    'custom09': CustomColumnDef;
    /**
     * 
     * @type {CustomColumnDef}
     * @memberof StudentCustomColumnDefSet
     */
    'custom10': CustomColumnDef;
    /**
     * 
     * @type {CustomColumnDef}
     * @memberof StudentCustomColumnDefSet
     */
    'custom11': CustomColumnDef;
    /**
     * 
     * @type {CustomColumnDef}
     * @memberof StudentCustomColumnDefSet
     */
    'custom12': CustomColumnDef;
    /**
     * 
     * @type {CustomColumnDef}
     * @memberof StudentCustomColumnDefSet
     */
    'custom13': CustomColumnDef;
    /**
     * 
     * @type {CustomColumnDef}
     * @memberof StudentCustomColumnDefSet
     */
    'custom14': CustomColumnDef;
    /**
     * 
     * @type {CustomColumnDef}
     * @memberof StudentCustomColumnDefSet
     */
    'custom15': CustomColumnDef;
    /**
     * 
     * @type {CustomColumnDef}
     * @memberof StudentCustomColumnDefSet
     */
    'custom16': CustomColumnDef;
    /**
     * 
     * @type {CustomColumnDef}
     * @memberof StudentCustomColumnDefSet
     */
    'custom17': CustomColumnDef;
    /**
     * 
     * @type {CustomColumnDef}
     * @memberof StudentCustomColumnDefSet
     */
    'custom18': CustomColumnDef;
    /**
     * 
     * @type {CustomColumnDef}
     * @memberof StudentCustomColumnDefSet
     */
    'custom19': CustomColumnDef;
    /**
     * 
     * @type {CustomColumnDef}
     * @memberof StudentCustomColumnDefSet
     */
    'custom20': CustomColumnDef;
    /**
     * 
     * @type {CustomColumnDef}
     * @memberof StudentCustomColumnDefSet
     */
    'custom21': CustomColumnDef;
    /**
     * 
     * @type {CustomColumnDef}
     * @memberof StudentCustomColumnDefSet
     */
    'custom22': CustomColumnDef;
    /**
     * 
     * @type {CustomColumnDef}
     * @memberof StudentCustomColumnDefSet
     */
    'custom23': CustomColumnDef;
    /**
     * 
     * @type {CustomColumnDef}
     * @memberof StudentCustomColumnDefSet
     */
    'custom24': CustomColumnDef;
    /**
     * 
     * @type {CustomColumnDef}
     * @memberof StudentCustomColumnDefSet
     */
    'custom25': CustomColumnDef;
    /**
     * 
     * @type {CustomColumnDef}
     * @memberof StudentCustomColumnDefSet
     */
    'custom26': CustomColumnDef;
    /**
     * 
     * @type {CustomColumnDef}
     * @memberof StudentCustomColumnDefSet
     */
    'custom27': CustomColumnDef;
    /**
     * 
     * @type {CustomColumnDef}
     * @memberof StudentCustomColumnDefSet
     */
    'custom28': CustomColumnDef;
    /**
     * 
     * @type {CustomColumnDef}
     * @memberof StudentCustomColumnDefSet
     */
    'custom29': CustomColumnDef;
    /**
     * 
     * @type {CustomColumnDef}
     * @memberof StudentCustomColumnDefSet
     */
    'custom30': CustomColumnDef;
    /**
     * 
     * @type {CustomColumnDef}
     * @memberof StudentCustomColumnDefSet
     */
    'custom31': CustomColumnDef;
    /**
     * 
     * @type {CustomColumnDef}
     * @memberof StudentCustomColumnDefSet
     */
    'custom32': CustomColumnDef;
    /**
     * 
     * @type {CustomColumnDef}
     * @memberof StudentCustomColumnDefSet
     */
    'custom33': CustomColumnDef;
    /**
     * 
     * @type {CustomColumnDef}
     * @memberof StudentCustomColumnDefSet
     */
    'custom34': CustomColumnDef;
    /**
     * 
     * @type {CustomColumnDef}
     * @memberof StudentCustomColumnDefSet
     */
    'custom35': CustomColumnDef;
    /**
     * 
     * @type {CustomColumnDef}
     * @memberof StudentCustomColumnDefSet
     */
    'custom36': CustomColumnDef;
    /**
     * 
     * @type {CustomColumnDef}
     * @memberof StudentCustomColumnDefSet
     */
    'custom37': CustomColumnDef;
    /**
     * 
     * @type {CustomColumnDef}
     * @memberof StudentCustomColumnDefSet
     */
    'custom38': CustomColumnDef;
    /**
     * 
     * @type {CustomColumnDef}
     * @memberof StudentCustomColumnDefSet
     */
    'custom39': CustomColumnDef;
    /**
     * 
     * @type {CustomColumnDef}
     * @memberof StudentCustomColumnDefSet
     */
    'custom40': CustomColumnDef;
}
/**
 * 
 * @export
 * @interface StudentCustomColumnDefSetWrite
 */
export interface StudentCustomColumnDefSetWrite {
    /**
     * 
     * @type {CustomColumnDefWrite}
     * @memberof StudentCustomColumnDefSetWrite
     */
    'custom01'?: CustomColumnDefWrite;
    /**
     * 
     * @type {CustomColumnDefWrite}
     * @memberof StudentCustomColumnDefSetWrite
     */
    'custom02'?: CustomColumnDefWrite;
    /**
     * 
     * @type {CustomColumnDefWrite}
     * @memberof StudentCustomColumnDefSetWrite
     */
    'custom03'?: CustomColumnDefWrite;
    /**
     * 
     * @type {CustomColumnDefWrite}
     * @memberof StudentCustomColumnDefSetWrite
     */
    'custom04'?: CustomColumnDefWrite;
    /**
     * 
     * @type {CustomColumnDefWrite}
     * @memberof StudentCustomColumnDefSetWrite
     */
    'custom05'?: CustomColumnDefWrite;
    /**
     * 
     * @type {CustomColumnDefWrite}
     * @memberof StudentCustomColumnDefSetWrite
     */
    'custom06'?: CustomColumnDefWrite;
    /**
     * 
     * @type {CustomColumnDefWrite}
     * @memberof StudentCustomColumnDefSetWrite
     */
    'custom07'?: CustomColumnDefWrite;
    /**
     * 
     * @type {CustomColumnDefWrite}
     * @memberof StudentCustomColumnDefSetWrite
     */
    'custom08'?: CustomColumnDefWrite;
    /**
     * 
     * @type {CustomColumnDefWrite}
     * @memberof StudentCustomColumnDefSetWrite
     */
    'custom09'?: CustomColumnDefWrite;
    /**
     * 
     * @type {CustomColumnDefWrite}
     * @memberof StudentCustomColumnDefSetWrite
     */
    'custom10'?: CustomColumnDefWrite;
    /**
     * 
     * @type {CustomColumnDefWrite}
     * @memberof StudentCustomColumnDefSetWrite
     */
    'custom11'?: CustomColumnDefWrite;
    /**
     * 
     * @type {CustomColumnDefWrite}
     * @memberof StudentCustomColumnDefSetWrite
     */
    'custom12'?: CustomColumnDefWrite;
    /**
     * 
     * @type {CustomColumnDefWrite}
     * @memberof StudentCustomColumnDefSetWrite
     */
    'custom13'?: CustomColumnDefWrite;
    /**
     * 
     * @type {CustomColumnDefWrite}
     * @memberof StudentCustomColumnDefSetWrite
     */
    'custom14'?: CustomColumnDefWrite;
    /**
     * 
     * @type {CustomColumnDefWrite}
     * @memberof StudentCustomColumnDefSetWrite
     */
    'custom15'?: CustomColumnDefWrite;
    /**
     * 
     * @type {CustomColumnDefWrite}
     * @memberof StudentCustomColumnDefSetWrite
     */
    'custom16'?: CustomColumnDefWrite;
    /**
     * 
     * @type {CustomColumnDefWrite}
     * @memberof StudentCustomColumnDefSetWrite
     */
    'custom17'?: CustomColumnDefWrite;
    /**
     * 
     * @type {CustomColumnDefWrite}
     * @memberof StudentCustomColumnDefSetWrite
     */
    'custom18'?: CustomColumnDefWrite;
    /**
     * 
     * @type {CustomColumnDefWrite}
     * @memberof StudentCustomColumnDefSetWrite
     */
    'custom19'?: CustomColumnDefWrite;
    /**
     * 
     * @type {CustomColumnDefWrite}
     * @memberof StudentCustomColumnDefSetWrite
     */
    'custom20'?: CustomColumnDefWrite;
    /**
     * 
     * @type {CustomColumnDefWrite}
     * @memberof StudentCustomColumnDefSetWrite
     */
    'custom21'?: CustomColumnDefWrite;
    /**
     * 
     * @type {CustomColumnDefWrite}
     * @memberof StudentCustomColumnDefSetWrite
     */
    'custom22'?: CustomColumnDefWrite;
    /**
     * 
     * @type {CustomColumnDefWrite}
     * @memberof StudentCustomColumnDefSetWrite
     */
    'custom23'?: CustomColumnDefWrite;
    /**
     * 
     * @type {CustomColumnDefWrite}
     * @memberof StudentCustomColumnDefSetWrite
     */
    'custom24'?: CustomColumnDefWrite;
    /**
     * 
     * @type {CustomColumnDefWrite}
     * @memberof StudentCustomColumnDefSetWrite
     */
    'custom25'?: CustomColumnDefWrite;
    /**
     * 
     * @type {CustomColumnDefWrite}
     * @memberof StudentCustomColumnDefSetWrite
     */
    'custom26'?: CustomColumnDefWrite;
    /**
     * 
     * @type {CustomColumnDefWrite}
     * @memberof StudentCustomColumnDefSetWrite
     */
    'custom27'?: CustomColumnDefWrite;
    /**
     * 
     * @type {CustomColumnDefWrite}
     * @memberof StudentCustomColumnDefSetWrite
     */
    'custom28'?: CustomColumnDefWrite;
    /**
     * 
     * @type {CustomColumnDefWrite}
     * @memberof StudentCustomColumnDefSetWrite
     */
    'custom29'?: CustomColumnDefWrite;
    /**
     * 
     * @type {CustomColumnDefWrite}
     * @memberof StudentCustomColumnDefSetWrite
     */
    'custom30'?: CustomColumnDefWrite;
    /**
     * 
     * @type {CustomColumnDefWrite}
     * @memberof StudentCustomColumnDefSetWrite
     */
    'custom31'?: CustomColumnDefWrite;
    /**
     * 
     * @type {CustomColumnDefWrite}
     * @memberof StudentCustomColumnDefSetWrite
     */
    'custom32'?: CustomColumnDefWrite;
    /**
     * 
     * @type {CustomColumnDefWrite}
     * @memberof StudentCustomColumnDefSetWrite
     */
    'custom33'?: CustomColumnDefWrite;
    /**
     * 
     * @type {CustomColumnDefWrite}
     * @memberof StudentCustomColumnDefSetWrite
     */
    'custom34'?: CustomColumnDefWrite;
    /**
     * 
     * @type {CustomColumnDefWrite}
     * @memberof StudentCustomColumnDefSetWrite
     */
    'custom35'?: CustomColumnDefWrite;
    /**
     * 
     * @type {CustomColumnDefWrite}
     * @memberof StudentCustomColumnDefSetWrite
     */
    'custom36'?: CustomColumnDefWrite;
    /**
     * 
     * @type {CustomColumnDefWrite}
     * @memberof StudentCustomColumnDefSetWrite
     */
    'custom37'?: CustomColumnDefWrite;
    /**
     * 
     * @type {CustomColumnDefWrite}
     * @memberof StudentCustomColumnDefSetWrite
     */
    'custom38'?: CustomColumnDefWrite;
    /**
     * 
     * @type {CustomColumnDefWrite}
     * @memberof StudentCustomColumnDefSetWrite
     */
    'custom39'?: CustomColumnDefWrite;
    /**
     * 
     * @type {CustomColumnDefWrite}
     * @memberof StudentCustomColumnDefSetWrite
     */
    'custom40'?: CustomColumnDefWrite;
}
/**
 * 
 * @export
 * @interface StudentSearchResult
 */
export interface StudentSearchResult {
    /**
     * 
     * @type {Array<Student>}
     * @memberof StudentSearchResult
     */
    'data': Array<Student>;
    /**
     * 
     * @type {number}
     * @memberof StudentSearchResult
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof StudentSearchResult
     */
    'offset': number;
    /**
     * 
     * @type {number}
     * @memberof StudentSearchResult
     */
    'totalCount': number;
}
/**
 * 児童生徒情報（学校内で使用するのはこちらのみ）
 * @export
 * @interface StudentV1
 */
export interface StudentV1 {
    /**
     * 児童生徒ID
     * @type {string}
     * @memberof StudentV1
     */
    'id': string;
    /**
     * Gsuiteアカウント
     * @type {string}
     * @memberof StudentV1
     */
    'account': string;
    /**
     * Gsuiteプロフィール写真URL
     * @type {string}
     * @memberof StudentV1
     */
    'photoUrl': string;
    /**
     * 児童生徒氏名（通称名があればそちら）
     * @type {string}
     * @memberof StudentV1
     */
    'name': string;
    /**
     * 児童生徒よみ（通称名よみがあればそちら）
     * @type {string}
     * @memberof StudentV1
     */
    'nameKana': string;
    /**
     * 性別
     * @type {string}
     * @memberof StudentV1
     */
    'sex': StudentV1SexEnum;
    /**
     * 
     * @type {Class}
     * @memberof StudentV1
     */
    'class'?: Class;
    /**
     * 出席番号
     * @type {number}
     * @memberof StudentV1
     */
    'number'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum StudentV1SexEnum {
    Male = 'male',
    Female = 'female',
    Other = 'other'
}

/**
 * 児童生徒（書き込み用）。
 * @export
 * @interface StudentWrite
 */
export interface StudentWrite {
    /**
     * nullに更新するには、空文字列を指定する。
     * @type {string}
     * @memberof StudentWrite
     */
    'guardianUserId'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'nameKana'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'nameRome'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'familyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'familyNameKana'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'givenName'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'givenNameKana'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'sex'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'nickname'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'nicknameKana'?: string;
    /**
     * nullに更新するには、空文字列を指定する。
     * @type {string}
     * @memberof StudentWrite
     */
    'birthday'?: string;
    /**
     * nullに更新するには、空文字列を指定する。
     * @type {string}
     * @memberof StudentWrite
     */
    'applicationDate'?: string;
    /**
     * nullに更新するには、空文字列を指定する。
     * @type {string}
     * @memberof StudentWrite
     */
    'kindergartenEntranceDate'?: string;
    /**
     * nullに更新するには、空文字列を指定する。
     * @type {string}
     * @memberof StudentWrite
     */
    'elementarySchoolEntranceDate'?: string;
    /**
     * nullに更新するには、空文字列を指定する。
     * @type {string}
     * @memberof StudentWrite
     */
    'juniorHighSchoolEntranceDate'?: string;
    /**
     * nullに更新するには、空文字列を指定する。
     * @type {string}
     * @memberof StudentWrite
     */
    'transferDate'?: string;
    /**
     * nullに更新するには、空文字列を指定する。
     * @type {string}
     * @memberof StudentWrite
     */
    'graduationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'kindergarten'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'previousSchool'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'zipcode'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'mobilePhone'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'religion'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'commutingBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'bus'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'nearestStation'?: string;
    /**
     * `yes`, `no` のいずれか。
     * @type {string}
     * @memberof StudentWrite
     */
    'hasLunch'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'activityMonday'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'activityTuesday'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'activityWednesday'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'activityThursday'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'activityFriday'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'bloodType'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'normalBodyTemperature'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'inoculation'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'medicalHistory'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'homeDoctor'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'foodAllergy'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'anaphylaxis'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'otherAllergy'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'asthma'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'custom01'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'custom02'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'custom03'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'custom04'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'custom05'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'custom06'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'custom07'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'custom08'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'custom09'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'custom10'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'custom11'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'custom12'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'custom13'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'custom14'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'custom15'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'custom16'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'custom17'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'custom18'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'custom19'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'custom20'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'custom21'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'custom22'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'custom23'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'custom24'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'custom25'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'custom26'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'custom27'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'custom28'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'custom29'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'custom30'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'custom31'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'custom32'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'custom33'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'custom34'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'custom35'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'custom36'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'custom37'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'custom38'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'custom39'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentWrite
     */
    'custom40'?: string;
    /**
     * 
     * @type {Array<StudentWriteClasses>}
     * @memberof StudentWrite
     */
    'classes'?: Array<StudentWriteClasses>;
}
/**
 * 
 * @export
 * @interface StudentWriteClasses
 */
export interface StudentWriteClasses {
    /**
     * 
     * @type {string}
     * @memberof StudentWriteClasses
     */
    'classId': string;
    /**
     * 出席番号。`null`を指定した場合、未設定に更新する。
     * @type {number}
     * @memberof StudentWriteClasses
     */
    'studentNumber'?: number;
}
/**
 * 児童生徒（書き込み用）とユーザーIDのペア。一括更新で用いる。
 * @export
 * @interface StudentWriteWithUserId
 */
export interface StudentWriteWithUserId {
    /**
     * 
     * @type {string}
     * @memberof StudentWriteWithUserId
     */
    'userId': string;
    /**
     * 
     * @type {StudentWrite}
     * @memberof StudentWriteWithUserId
     */
    'request': StudentWrite;
}
/**
 * Googleワークスペースとの同期により、作成されたユーザー。
 * @export
 * @interface SyncWithGWSCreatedUser
 */
export interface SyncWithGWSCreatedUser {
    /**
     * 
     * @type {string}
     * @memberof SyncWithGWSCreatedUser
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof SyncWithGWSCreatedUser
     */
    'googleMail': string;
    /**
     * 
     * @type {string}
     * @memberof SyncWithGWSCreatedUser
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SyncWithGWSCreatedUser
     */
    'familyName': string;
    /**
     * 
     * @type {string}
     * @memberof SyncWithGWSCreatedUser
     */
    'givenName': string;
}
/**
 * 
 * @export
 * @interface SyncWithGWSRequest
 */
export interface SyncWithGWSRequest {
    /**
     * 同期したいユーザータイプ。`teacher`, `student`, `guardian` のいずれか。指定しなければ、すべてを対象とする。
     * @type {string}
     * @memberof SyncWithGWSRequest
     */
    'userType'?: string;
}
/**
 * Googleワークスペースとの同期のレスポンス。
 * @export
 * @interface SyncWithGWSResponse
 */
export interface SyncWithGWSResponse {
    /**
     * 
     * @type {SyncWithGWSResponseCreated}
     * @memberof SyncWithGWSResponse
     */
    'created': SyncWithGWSResponseCreated;
    /**
     * 
     * @type {SyncWithGWSResponseUpdated}
     * @memberof SyncWithGWSResponse
     */
    'updated': SyncWithGWSResponseUpdated;
}
/**
 * 
 * @export
 * @interface SyncWithGWSResponseCreated
 */
export interface SyncWithGWSResponseCreated {
    /**
     * 
     * @type {Array<SyncWithGWSCreatedUser>}
     * @memberof SyncWithGWSResponseCreated
     */
    'teachers': Array<SyncWithGWSCreatedUser>;
    /**
     * 
     * @type {Array<SyncWithGWSCreatedUser>}
     * @memberof SyncWithGWSResponseCreated
     */
    'students': Array<SyncWithGWSCreatedUser>;
    /**
     * 
     * @type {Array<SyncWithGWSCreatedUser>}
     * @memberof SyncWithGWSResponseCreated
     */
    'guardians': Array<SyncWithGWSCreatedUser>;
}
/**
 * 
 * @export
 * @interface SyncWithGWSResponseUpdated
 */
export interface SyncWithGWSResponseUpdated {
    /**
     * 
     * @type {Array<SyncWithGWSUpdatedUser>}
     * @memberof SyncWithGWSResponseUpdated
     */
    'teachers': Array<SyncWithGWSUpdatedUser>;
    /**
     * 
     * @type {Array<SyncWithGWSUpdatedUser>}
     * @memberof SyncWithGWSResponseUpdated
     */
    'students': Array<SyncWithGWSUpdatedUser>;
    /**
     * 
     * @type {Array<SyncWithGWSUpdatedUser>}
     * @memberof SyncWithGWSResponseUpdated
     */
    'guardians': Array<SyncWithGWSUpdatedUser>;
}
/**
 * Googleワークスペースとの同期により、更新されたユーザー。  同期による更新では、name, familyName, givenNameは更新しないため、本モデルに含まれない。
 * @export
 * @interface SyncWithGWSUpdatedUser
 */
export interface SyncWithGWSUpdatedUser {
    /**
     * 
     * @type {string}
     * @memberof SyncWithGWSUpdatedUser
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof SyncWithGWSUpdatedUser
     */
    'googleMail': string;
}
/**
 * 教職員。
 * @export
 * @interface Teacher
 */
export interface Teacher {
    /**
     * 
     * @type {string}
     * @memberof Teacher
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof Teacher
     */
    'googleMail': string;
    /**
     * 
     * @type {string}
     * @memberof Teacher
     */
    'photoUrl': string;
    /**
     * 
     * @type {string}
     * @memberof Teacher
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Teacher
     */
    'nameKana': string;
    /**
     * 
     * @type {string}
     * @memberof Teacher
     */
    'familyName': string;
    /**
     * 
     * @type {string}
     * @memberof Teacher
     */
    'givenName': string;
    /**
     * 
     * @type {string}
     * @memberof Teacher
     */
    'teacherType': string;
    /**
     * 
     * @type {string}
     * @memberof Teacher
     */
    'zipcode': string;
    /**
     * 
     * @type {string}
     * @memberof Teacher
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof Teacher
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof Teacher
     */
    'mobilePhone': string;
    /**
     * 
     * @type {string}
     * @memberof Teacher
     */
    'landlinePhone': string;
    /**
     * 
     * @type {string}
     * @memberof Teacher
     */
    'foreignType': string;
    /**
     * 
     * @type {Array<TeacherClass>}
     * @memberof Teacher
     */
    'classes': Array<TeacherClass>;
}
/**
 * 教職員の基本情報。
 * @export
 * @interface TeacherBasic
 */
export interface TeacherBasic {
    /**
     * 
     * @type {string}
     * @memberof TeacherBasic
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof TeacherBasic
     */
    'googleMail': string;
    /**
     * 
     * @type {string}
     * @memberof TeacherBasic
     */
    'photoUrl': string;
    /**
     * 
     * @type {string}
     * @memberof TeacherBasic
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof TeacherBasic
     */
    'nameKana': string;
    /**
     * 
     * @type {Array<Class>}
     * @memberof TeacherBasic
     */
    'classes': Array<Class>;
}
/**
 * 教職員クラス。
 * @export
 * @interface TeacherClass
 */
export interface TeacherClass {
    /**
     * 
     * @type {string}
     * @memberof TeacherClass
     */
    'classId': string;
    /**
     * 
     * @type {number}
     * @memberof TeacherClass
     */
    'schoolYear': number;
    /**
     * 
     * @type {Grade}
     * @memberof TeacherClass
     */
    'grade': Grade;
    /**
     * 
     * @type {number}
     * @memberof TeacherClass
     */
    'orderNum': number;
    /**
     * 
     * @type {string}
     * @memberof TeacherClass
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof TeacherClass
     */
    'inChargeType': string;
}
/**
 * 
 * @export
 * @interface TeacherSearchResult
 */
export interface TeacherSearchResult {
    /**
     * 
     * @type {Array<Teacher>}
     * @memberof TeacherSearchResult
     */
    'data': Array<Teacher>;
    /**
     * 
     * @type {number}
     * @memberof TeacherSearchResult
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof TeacherSearchResult
     */
    'offset': number;
    /**
     * 
     * @type {number}
     * @memberof TeacherSearchResult
     */
    'totalCount': number;
}
/**
 * 教師情報
 * @export
 * @interface TeacherV1
 */
export interface TeacherV1 {
    /**
     * 教師ID
     * @type {string}
     * @memberof TeacherV1
     */
    'id'?: string;
    /**
     * Gsuiteアカウント
     * @type {string}
     * @memberof TeacherV1
     */
    'account'?: string;
    /**
     * Gsuiteプロフィール写真URL
     * @type {string}
     * @memberof TeacherV1
     */
    'photoUrl'?: string;
    /**
     * 氏名
     * @type {string}
     * @memberof TeacherV1
     */
    'name'?: string;
    /**
     * 外国人タイプ
     * @type {string}
     * @memberof TeacherV1
     */
    'foreignType'?: TeacherV1ForeignTypeEnum;
    /**
     * 受け持つクラス情報
     * @type {Array<Class>}
     * @memberof TeacherV1
     */
    'classes'?: Array<Class>;
}

/**
    * @export
    * @enum {string}
    */
export enum TeacherV1ForeignTypeEnum {
    Japanese = 'japanese',
    Foreigner = 'foreigner'
}

/**
 * 教職員（書き込み用）。
 * @export
 * @interface TeacherWrite
 */
export interface TeacherWrite {
    /**
     * 
     * @type {string}
     * @memberof TeacherWrite
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherWrite
     */
    'nameKana'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherWrite
     */
    'familyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherWrite
     */
    'givenName'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherWrite
     */
    'zipcode'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherWrite
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherWrite
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherWrite
     */
    'mobilePhone'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherWrite
     */
    'landlinePhone'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherWrite
     */
    'foreignType'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherWrite
     */
    'teacherType'?: string;
    /**
     * 
     * @type {Array<TeacherWriteClasses>}
     * @memberof TeacherWrite
     */
    'classes'?: Array<TeacherWriteClasses>;
}
/**
 * 
 * @export
 * @interface TeacherWriteClasses
 */
export interface TeacherWriteClasses {
    /**
     * 
     * @type {string}
     * @memberof TeacherWriteClasses
     */
    'classId': string;
    /**
     * 担任タイプ。char型。学校ごとに、値の意味は異なる。 指定しない場合、「`null`に更新」を表す。
     * @type {string}
     * @memberof TeacherWriteClasses
     */
    'inChargeType'?: string;
}
/**
 * 教職員（書き込み用）とユーザーIDのペア。一括更新で用いる。
 * @export
 * @interface TeacherWriteWithUserId
 */
export interface TeacherWriteWithUserId {
    /**
     * 
     * @type {string}
     * @memberof TeacherWriteWithUserId
     */
    'userId': string;
    /**
     * 
     * @type {TeacherWrite}
     * @memberof TeacherWriteWithUserId
     */
    'request': TeacherWrite;
}
/**
 * 学期。
 * @export
 * @interface Term
 */
export interface Term {
    /**
     * 
     * @type {number}
     * @memberof Term
     */
    'schoolYear': number;
    /**
     * 学校タイプ。値は `elementary`: 小学校, `juniorhigh`: 中学校。
     * @type {string}
     * @memberof Term
     */
    'schoolType': string;
    /**
     * 
     * @type {number}
     * @memberof Term
     */
    'termNumber': number;
    /**
     * inclusive.
     * @type {string}
     * @memberof Term
     */
    'startDate': string;
    /**
     * inclusive.
     * @type {string}
     * @memberof Term
     */
    'endDate': string;
}
/**
 * 学期（書き込み用）。
 * @export
 * @interface TermWrite
 */
export interface TermWrite {
    /**
     * 
     * @type {string}
     * @memberof TermWrite
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof TermWrite
     */
    'endDate': string;
}
/**
 * 
 * @export
 * @interface UserInfo
 */
export interface UserInfo {
    /**
     * teacher / student / guardian / staff / none のいずれか。
     * @type {string}
     * @memberof UserInfo
     */
    'userType': string;
    /**
     * 
     * @type {UserInfoTeacher | UserInfoStudent | UserInfoGuardian}
     * @memberof UserInfo
     */
    'details': UserInfoTeacher | UserInfoStudent | UserInfoGuardian;
}
/**
 * 
 * @export
 * @interface UserInfoGuardian
 */
export interface UserInfoGuardian {
    /**
     * 
     * @type {string}
     * @memberof UserInfoGuardian
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserInfoGuardian
     */
    'googleMail': string;
    /**
     * 
     * @type {string}
     * @memberof UserInfoGuardian
     */
    'name1': string;
    /**
     * 
     * @type {string}
     * @memberof UserInfoGuardian
     */
    'name2': string;
    /**
     * 
     * @type {Array<UserInfoStudent>}
     * @memberof UserInfoGuardian
     */
    'students': Array<UserInfoStudent>;
}
/**
 * 
 * @export
 * @interface UserInfoStudent
 */
export interface UserInfoStudent {
    /**
     * 
     * @type {string}
     * @memberof UserInfoStudent
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserInfoStudent
     */
    'googleMail': string;
    /**
     * 
     * @type {string}
     * @memberof UserInfoStudent
     */
    'photoUrl': string;
    /**
     * 
     * @type {string}
     * @memberof UserInfoStudent
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UserInfoStudent
     */
    'nameKana': string;
    /**
     * 
     * @type {string}
     * @memberof UserInfoStudent
     */
    'sex': string;
    /**
     * 
     * @type {string}
     * @memberof UserInfoStudent
     */
    'primarySchoolEnterDate': string;
    /**
     * 
     * @type {Array<StudentClass>}
     * @memberof UserInfoStudent
     */
    'classes': Array<StudentClass>;
}
/**
 * 
 * @export
 * @interface UserInfoTeacher
 */
export interface UserInfoTeacher {
    /**
     * 
     * @type {string}
     * @memberof UserInfoTeacher
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserInfoTeacher
     */
    'googleMail': string;
    /**
     * 
     * @type {string}
     * @memberof UserInfoTeacher
     */
    'photoUrl': string;
    /**
     * 
     * @type {string}
     * @memberof UserInfoTeacher
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UserInfoTeacher
     */
    'foreignType': string;
    /**
     * 
     * @type {Array<TeacherClass>}
     * @memberof UserInfoTeacher
     */
    'classes': Array<TeacherClass>;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 保護者を一括更新する
         * @param {Array<GuardianWriteWithUserId>} [guardianWriteWithUserId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchPatchGuardian: async (guardianWriteWithUserId?: Array<GuardianWriteWithUserId>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/guardians`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(guardianWriteWithUserId, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 児童生徒を一括更新する
         * @param {Array<StudentWriteWithUserId>} [studentWriteWithUserId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchPatchStudent: async (studentWriteWithUserId?: Array<StudentWriteWithUserId>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/students`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(studentWriteWithUserId, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 教職員を一括更新する
         * @param {Array<TeacherWriteWithUserId>} [teacherWriteWithUserId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchPatchTeacher: async (teacherWriteWithUserId?: Array<TeacherWriteWithUserId>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/teachers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(teacherWriteWithUserId, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary クラスを削除する
         * @param {string} classId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteClass: async (classId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'classId' is not null or undefined
            assertParamExists('deleteClass', 'classId', classId)
            const localVarPath = `/v2/classes/{classId}`
                .replace(`{${"classId"}}`, encodeURIComponent(String(classId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 保護者を削除する
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGuardian: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteGuardian', 'userId', userId)
            const localVarPath = `/v2/guardians/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 保護者の写真1を削除する
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGuardianPicture1: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteGuardianPicture1', 'userId', userId)
            const localVarPath = `/v2/guardians/{userId}/deletePicture1`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 保護者の写真2を削除する
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGuardianPicture2: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteGuardianPicture2', 'userId', userId)
            const localVarPath = `/v2/guardians/{userId}/deletePicture2`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ロール紐づけを削除する
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRolebinding: async (key: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('deleteRolebinding', 'key', key)
            const localVarPath = `/v2/rolebindings/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 学校ロゴを削除する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSchoolLogo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/school/deleteLogo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 児童生徒を削除する
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStudent: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteStudent', 'userId', userId)
            const localVarPath = `/v2/students/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 児童生徒の写真を削除する
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStudentPicture: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteStudentPicture', 'userId', userId)
            const localVarPath = `/v2/students/{userId}/deletePicture`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 教職員を削除する
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTeacher: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteTeacher', 'userId', userId)
            const localVarPath = `/v2/teachers/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * `{key}` = `{schoolYear}:{schoolType}:{termNumber}`
         * @summary 学期を削除する
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTerm: async (key: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('deleteTerm', 'key', key)
            const localVarPath = `/v2/terms/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Firebase権限情報を登録する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        firebaseAuthUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/firebaseAuthUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ユーザー情報を返却する。 photoUrlはブラウザキャッシュが行われる(10分)
         * @summary ユーザー基本情報を取得
         * @param {string} userId 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBasicUserInfo: async (userId: string, body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getBasicUserInfo', 'userId', userId)
            const localVarPath = `/basicUserInfo/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary クラスを取得する
         * @param {string} classId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClass: async (classId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'classId' is not null or undefined
            assertParamExists('getClass', 'classId', classId)
            const localVarPath = `/v2/classes/{classId}`
                .replace(`{${"classId"}}`, encodeURIComponent(String(classId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * クラスに所属する児童生徒を出席番号順に取得する。
         * @summary クラスの児童生徒一覧を取得する
         * @param {string} classId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClassStudentsV1: async (classId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'classId' is not null or undefined
            assertParamExists('getClassStudentsV1', 'classId', classId)
            const localVarPath = `/classes/{classId}/students`
                .replace(`{${"classId"}}`, encodeURIComponent(String(classId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した クラス情報を取得する。
         * @summary クラスを取得する
         * @param {string} classId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClassesClassIdV1: async (classId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'classId' is not null or undefined
            assertParamExists('getClassesClassIdV1', 'classId', classId)
            const localVarPath = `/classes/{classId}`
                .replace(`{${"classId"}}`, encodeURIComponent(String(classId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * クラスを検索し、学校区分、学年、クラス番号順に取得する。
         * @summary クラスの一覧を取得する
         * @param {number} [grade] 学年を指定する。 
         * @param {string} [schoolType] 小中区分を指定する。
         * @param {number} [schoolYear] 年度（省略時は今年度）
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClassesV1: async (grade?: number, schoolType?: string, schoolYear?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/classes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (grade !== undefined) {
                localVarQueryParameter['grade'] = grade;
            }

            if (schoolType !== undefined) {
                localVarQueryParameter['schoolType'] = schoolType;
            }

            if (schoolYear !== undefined) {
                localVarQueryParameter['schoolYear'] = schoolYear;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 保護者を取得する
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGuardian: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getGuardian', 'userId', userId)
            const localVarPath = `/v2/guardians/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定の保護者に紐付く児童生徒を指定して児童生徒の検診情報を取得する。 児童生徒の保護者、教師のみアクセスできる。
         * @summary 保護者に紐付く児童生徒の検診プ情報を取得する
         * @param {string} guardianId 
         * @param {string} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGuardiansGuardianIdChildrenChildIdHealthV1: async (guardianId: string, childId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guardianId' is not null or undefined
            assertParamExists('getGuardiansGuardianIdChildrenChildIdHealthV1', 'guardianId', guardianId)
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('getGuardiansGuardianIdChildrenChildIdHealthV1', 'childId', childId)
            const localVarPath = `/guardians/{guardianId}/children/{childId}/health`
                .replace(`{${"guardianId"}}`, encodeURIComponent(String(guardianId)))
                .replace(`{${"childId"}}`, encodeURIComponent(String(childId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定の保護者に紐付く児童生徒を指定して児童生徒プロファイル情報を取得する。 児童生徒の保護者、教師のみアクセスできる。
         * @summary 保護者に紐付く児童生徒プロファイル情報を取得する
         * @param {string} guardianId 
         * @param {string} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGuardiansGuardianIdChildrenChildIdV1: async (guardianId: string, childId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guardianId' is not null or undefined
            assertParamExists('getGuardiansGuardianIdChildrenChildIdV1', 'guardianId', guardianId)
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('getGuardiansGuardianIdChildrenChildIdV1', 'childId', childId)
            const localVarPath = `/guardians/{guardianId}/children/{childId}/profile`
                .replace(`{${"guardianId"}}`, encodeURIComponent(String(guardianId)))
                .replace(`{${"childId"}}`, encodeURIComponent(String(childId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 保護者の保護下にある児童生徒一覧を取得する。 保護者もしくは教師のみアクセスできる。
         * @summary 保護者に紐付く児童生徒一覧を取得する。
         * @param {string} guardianId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGuardiansGuardianIdChildrenV1: async (guardianId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guardianId' is not null or undefined
            assertParamExists('getGuardiansGuardianIdChildrenV1', 'guardianId', guardianId)
            const localVarPath = `/guardians/{guardianId}/children`
                .replace(`{${"guardianId"}}`, encodeURIComponent(String(guardianId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 保護者プロファイル情報を取得する
         * @param {string} guardianId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGuardiansGuardianIdProfile: async (guardianId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guardianId' is not null or undefined
            assertParamExists('getGuardiansGuardianIdProfile', 'guardianId', guardianId)
            const localVarPath = `/guardians/{guardianId}/profile`
                .replace(`{${"guardianId"}}`, encodeURIComponent(String(guardianId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 保護者もしくは教師のみアクセスできる。
         * @summary 保護者情報を取得する
         * @param {string} guardianId 保護者ID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaentsGuardianIdV1: async (guardianId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guardianId' is not null or undefined
            assertParamExists('getPaentsGuardianIdV1', 'guardianId', guardianId)
            const localVarPath = `/guardians/{guardianId}`
                .replace(`{${"guardianId"}}`, encodeURIComponent(String(guardianId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 学校を取得する
         * @param {boolean} [noCache] &#x60;true&#x60;の場合、レスポンスをキャッシュさせない。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSchool: async (noCache?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/school`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (noCache !== undefined) {
                localVarQueryParameter['noCache'] = noCache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 現在の学校情報を取得する。
         * @summary 学校情報を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSchoolV1: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/school`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 現在の学期を取得する。
         * @summary Your GET endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSemesterV1: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/semester`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 教職員プロファイル情報を取得する。 教職員のみアクセスできる。
         * @summary 教職員プロファイル情報を取得する
         * @param {string} staffId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStaffStaffIdV1: async (staffId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'staffId' is not null or undefined
            assertParamExists('getStaffStaffIdV1', 'staffId', staffId)
            const localVarPath = `/staff/{staffId}`
                .replace(`{${"staffId"}}`, encodeURIComponent(String(staffId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 児童生徒を取得する
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudent: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getStudent', 'userId', userId)
            const localVarPath = `/v2/students/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 児童生徒カスタム項目の定義を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudentCustomColumnDef: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/studentCustomColumnDef`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した児童生徒IDの児童生徒情報を取得する。 児童生徒本人、児童生徒の保護者、教師のみアクセスできる。
         * @summary 児童生徒を取得する
         * @param {string} studentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudentsStudentIdV1: async (studentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'studentId' is not null or undefined
            assertParamExists('getStudentsStudentIdV1', 'studentId', studentId)
            const localVarPath = `/students/{studentId}`
                .replace(`{${"studentId"}}`, encodeURIComponent(String(studentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 教職員を取得する
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeacher: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getTeacher', 'userId', userId)
            const localVarPath = `/v2/teachers/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した教師IDの教師情報を取得する。
         * @summary 教師情報を取得する
         * @param {string} teacherId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeachersTeacherIdV1: async (teacherId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teacherId' is not null or undefined
            assertParamExists('getTeachersTeacherIdV1', 'teacherId', teacherId)
            const localVarPath = `/teachers/{teacherId}`
                .replace(`{${"teacherId"}}`, encodeURIComponent(String(teacherId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した日時の学期を取得する。  日時未指定の場合、現在の学期を取得する。
         * @summary 指定した日時の学期を取得する
         * @param {string} schoolType 学校タイプ。&#x60;elementary&#x60;, &#x60;juniorhigh&#x60;のいずれか。
         * @param {string} [at] 日時。例: &#x60;2021-11-25T13:58:24+09:00&#x60;
         * @param {string} [noCache] &#x60;true&#x60;の場合、レスポンスをキャッシュさせない。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTermByTime: async (schoolType: string, at?: string, noCache?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'schoolType' is not null or undefined
            assertParamExists('getTermByTime', 'schoolType', schoolType)
            const localVarPath = `/v2/terms/getByTime`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (schoolType !== undefined) {
                localVarQueryParameter['schoolType'] = schoolType;
            }

            if (at !== undefined) {
                localVarQueryParameter['at'] = at;
            }

            if (noCache !== undefined) {
                localVarQueryParameter['noCache'] = noCache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ユーザー情報を取得する。
         * @summary Your GET endpoint
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInfo: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserInfo', 'userId', userId)
            const localVarPath = `/userInfo/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ユーザーについて、以下2点を返す。  * ユーザータイプ。（教師`teacher` / 職員`staff` / 児童生徒`student` / 保護者`guardian` / 該当なし`none`） * ユーザータイプに応じた、権限判定に関わる情報。   * `teacher`の場合、何もなし。   * `staff`の場合、何もなし。   * `student`の場合、所属クラス一覧(※年度毎にクラスは異なるが、過去のものを含めてすべて返却する)。   * `guardian`の場合、自分の子供(`student`)の一覧。さらに、それら`student`が、それぞれ所属クラス一覧を持つ。   * `none`の場合、何もなし。  # ユーザー別制約  * 児童生徒と保護者   * 自分自身のみ取得可能。 * 教師   * なんでも取得可能。
         * @summary Your GET endpoint
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identity: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('identity', 'userId', userId)
            const localVarPath = `/identity/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary クラスのリストを取得する
         * @param {number} [schoolYear] 年度。
         * @param {string} [schoolType] 学校タイプ。値は &#x60;elementary&#x60;, &#x60;juniorhigh&#x60;。
         * @param {number} [gradeNumber] 学年（数値）。小学校は&#x60;1&#x60;-&#x60;6&#x60;、中学校は&#x60;1&#x60;-&#x60;3&#x60;。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClass: async (schoolYear?: number, schoolType?: string, gradeNumber?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/classes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (schoolYear !== undefined) {
                localVarQueryParameter['schoolYear'] = schoolYear;
            }

            if (schoolType !== undefined) {
                localVarQueryParameter['schoolType'] = schoolType;
            }

            if (gradeNumber !== undefined) {
                localVarQueryParameter['gradeNumber'] = gradeNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary クラスの児童生徒基本情報一覧を取得する
         * @param {string} classId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClassStudents: async (classId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'classId' is not null or undefined
            assertParamExists('listClassStudents', 'classId', classId)
            const localVarPath = `/v2/classes/{classId}/students`
                .replace(`{${"classId"}}`, encodeURIComponent(String(classId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 保護者のリストを取得する
         * @param {Array<string>} [userId] ユーザーID。複数指定可。
         * @param {string} [q] クエリ文字列。
         * @param {string} [schoolType] 学校タイプ。
         * @param {number} [gradeNumber] 学年数値。&#x60;schoolType&#x60;を指定した場合のみ指定可能。
         * @param {Array<string>} [classId] クラスID。複数指定可。
         * @param {boolean} [noClass] &#x60;true&#x60;なら、クラス未設定の児童生徒を1人以上持つ保護者のみを検索する。
         * @param {string} [inSchoolState] 在学状態。値は &#x60;inSchool&#x60;, &#x60;graduated&#x60;, &#x60;transferred&#x60;, &#x60;graduatedOrTransferred&#x60;。指定する場合、&#x60;inSchoolStateOn&#x60;の指定が必須。
         * @param {string} [inSchoolStateOn] &#x60;inSchoolState&#x60;を計算する日付。この日付の在学状態で検索する。
         * @param {number} [classSchoolYear] 検索条件の&#x60;schoolType&#x60;、&#x60;gradeNumber&#x60;を指定した場合は必須で、この年度のクラスを使用して検索を行う。
         * @param {string} [sortBy] ソート用項目。値は &#x60;nameKana&#x60;, &#x60;createdAt&#x60;, &#x60;updatedAt&#x60;。
         * @param {string} [sortOrder] 並び順。値は &#x60;asc&#x60;, &#x60;desc&#x60;。
         * @param {number} [limit] 検索上限件数。
         * @param {number} [offset] 検索オフセット。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGuardian: async (userId?: Array<string>, q?: string, schoolType?: string, gradeNumber?: number, classId?: Array<string>, noClass?: boolean, inSchoolState?: string, inSchoolStateOn?: string, classSchoolYear?: number, sortBy?: string, sortOrder?: string, limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/guardians`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId) {
                localVarQueryParameter['userId'] = userId;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (schoolType !== undefined) {
                localVarQueryParameter['schoolType'] = schoolType;
            }

            if (gradeNumber !== undefined) {
                localVarQueryParameter['gradeNumber'] = gradeNumber;
            }

            if (classId) {
                localVarQueryParameter['classId'] = classId;
            }

            if (noClass !== undefined) {
                localVarQueryParameter['noClass'] = noClass;
            }

            if (inSchoolState !== undefined) {
                localVarQueryParameter['inSchoolState'] = inSchoolState;
            }

            if (inSchoolStateOn !== undefined) {
                localVarQueryParameter['inSchoolStateOn'] = inSchoolStateOn;
            }

            if (classSchoolYear !== undefined) {
                localVarQueryParameter['classSchoolYear'] = classSchoolYear;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * `list-guardian` と同じ働きをするが、以下が異なる。  - postリクエストでbodyを受け取る方式。 - 受け取れるパラメタはuserIdsのみ。  通常のListGuardianはgetリクエストなので、URL長制限に引っかかる場合があり、その回避策として用いるもの。
         * @summary 保護者のリストを取得する
         * @param {InlineObject3} [inlineObject3] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGuardianByPost: async (inlineObject3?: InlineObject3, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/guardians/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject3, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ロール紐づけのリストを取得する
         * @param {string} [userId] ユーザーID。
         * @param {string} [roleId] ロールID。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRolebinding: async (userId?: string, roleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/rolebindings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (roleId !== undefined) {
                localVarQueryParameter['roleId'] = roleId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 児童生徒のリストを取得する
         * @param {Array<string>} [userId] ユーザーID。複数指定可。
         * @param {string} [q] クエリ文字列。
         * @param {string} [guardianUserId] 保護者ユーザーID。
         * @param {string} [schoolType] 学校タイプ。
         * @param {number} [gradeNumber] 学年数値。&#x60;schoolType&#x60;を指定した場合のみ指定可能。
         * @param {Array<string>} [classId] クラスID。複数指定可。
         * @param {boolean} [noClass] &#x60;true&#x60;なら、クラス未設定の児童生徒のみを検索する。
         * @param {string} [inSchoolState] 在学状態。値は &#x60;inSchool&#x60;, &#x60;graduated&#x60;, &#x60;transferred&#x60;, &#x60;graduatedOrTransferred&#x60;。指定する場合、&#x60;inSchoolStateOn&#x60;の指定が必須。
         * @param {string} [inSchoolStateOn] &#x60;inSchoolState&#x60;を計算する日付。この日付の在学状態で検索する。
         * @param {number} [classSchoolYear] 検索条件の&#x60;schoolType&#x60;、&#x60;gradeNumber&#x60;、または&#x60;sortBy&#x60;に&#x60;gradeClassStudentNumber&#x60;を指定した場合は必須で、この年度のクラスを使用して検索・並び替えを行う。
         * @param {string} [sortBy] ソート用項目。値は &#x60;gradeClassStudentNumber&#x60;, &#x60;nameKana&#x60;, &#x60;createdAt&#x60;, &#x60;updatedAt&#x60;。
         * @param {string} [sortOrder] 並び順。値は &#x60;asc&#x60;, &#x60;desc&#x60;。
         * @param {number} [limit] 検索上限件数。
         * @param {number} [offset] 検索オフセット。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStudent: async (userId?: Array<string>, q?: string, guardianUserId?: string, schoolType?: string, gradeNumber?: number, classId?: Array<string>, noClass?: boolean, inSchoolState?: string, inSchoolStateOn?: string, classSchoolYear?: number, sortBy?: string, sortOrder?: string, limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/students`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId) {
                localVarQueryParameter['userId'] = userId;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (guardianUserId !== undefined) {
                localVarQueryParameter['guardianUserId'] = guardianUserId;
            }

            if (schoolType !== undefined) {
                localVarQueryParameter['schoolType'] = schoolType;
            }

            if (gradeNumber !== undefined) {
                localVarQueryParameter['gradeNumber'] = gradeNumber;
            }

            if (classId) {
                localVarQueryParameter['classId'] = classId;
            }

            if (noClass !== undefined) {
                localVarQueryParameter['noClass'] = noClass;
            }

            if (inSchoolState !== undefined) {
                localVarQueryParameter['inSchoolState'] = inSchoolState;
            }

            if (inSchoolStateOn !== undefined) {
                localVarQueryParameter['inSchoolStateOn'] = inSchoolStateOn;
            }

            if (classSchoolYear !== undefined) {
                localVarQueryParameter['classSchoolYear'] = classSchoolYear;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * `list-student` と同じ働きをするが、以下が異なる。  - postリクエストでbodyを受け取る方式。 - 受け取れるパラメタはuserIdsのみ。  通常のListStudentはgetリクエストなので、URL長制限に引っかかる場合があり、その回避策として用いるもの。
         * @summary 児童生徒のリストを取得する
         * @param {InlineObject1} [inlineObject1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStudentByPost: async (inlineObject1?: InlineObject1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/students/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 教職員のリストを取得する
         * @param {Array<string>} [userId] ユーザーID。複数指定可。
         * @param {string} [q] クエリ文字列。
         * @param {string} [teacherType] 教職員タイプ。値は &#x60;0&#x60;: staff, &#x60;1&#x60;: teacher。
         * @param {number} [classSchoolYear] 検索条件の&#x60;schoolType&#x60;、&#x60;gradeNumber&#x60;を指定した場合は必須で、この年度のクラスを使用して検索・並び替えを行う。
         * @param {string} [schoolType] 学校タイプ。
         * @param {number} [gradeNumber] 学年数値。&#x60;schoolType&#x60;を指定した場合のみ指定可能。
         * @param {Array<string>} [classId] クラスID。複数指定可。
         * @param {boolean} [noClass] &#x60;true&#x60;なら、クラス未設定の教職員のみを検索する。
         * @param {string} [sortBy] ソート用項目。値は &#x60;nameKana&#x60;, &#x60;createdAt&#x60;, &#x60;updatedAt&#x60;。 
         * @param {string} [sortOrder] 並び順。値は &#x60;asc&#x60;: 昇順, &#x60;desc&#x60;: 降順。
         * @param {number} [limit] 検索上限件数。
         * @param {number} [offset] 検索オフセット。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTeacher: async (userId?: Array<string>, q?: string, teacherType?: string, classSchoolYear?: number, schoolType?: string, gradeNumber?: number, classId?: Array<string>, noClass?: boolean, sortBy?: string, sortOrder?: string, limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/teachers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId) {
                localVarQueryParameter['userId'] = userId;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (teacherType !== undefined) {
                localVarQueryParameter['teacherType'] = teacherType;
            }

            if (classSchoolYear !== undefined) {
                localVarQueryParameter['classSchoolYear'] = classSchoolYear;
            }

            if (schoolType !== undefined) {
                localVarQueryParameter['schoolType'] = schoolType;
            }

            if (gradeNumber !== undefined) {
                localVarQueryParameter['gradeNumber'] = gradeNumber;
            }

            if (classId) {
                localVarQueryParameter['classId'] = classId;
            }

            if (noClass !== undefined) {
                localVarQueryParameter['noClass'] = noClass;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * `list-teacher` と同じ働きをするが、以下が異なる。  - postリクエストでbodyを受け取る方式。 - 受け取れるパラメタはuserIdsのみ。  通常のListTeacherはgetリクエストなので、URL長制限に引っかかる場合があり、その回避策として用いるもの。
         * @summary 教職員のリストを取得する
         * @param {InlineObject} [inlineObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTeacherByPost: async (inlineObject?: InlineObject, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/teachers/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 学期のリストを取得する
         * @param {string} [schoolType] 学校タイプ。&#x60;elementary&#x60;, &#x60;juniorhigh&#x60;。
         * @param {number} [schoolYear] 学年。
         * @param {number} [termNumber] 学期番号。
         * @param {boolean} [noCache] &#x60;true&#x60;の場合、レスポンスをキャッシュさせない。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTerm: async (schoolType?: string, schoolYear?: number, termNumber?: number, noCache?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/terms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (schoolType !== undefined) {
                localVarQueryParameter['schoolType'] = schoolType;
            }

            if (schoolYear !== undefined) {
                localVarQueryParameter['schoolYear'] = schoolYear;
            }

            if (termNumber !== undefined) {
                localVarQueryParameter['termNumber'] = termNumber;
            }

            if (noCache !== undefined) {
                localVarQueryParameter['noCache'] = noCache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ユーザーのアクセストークンをアプリが取得済かを判定する。  * 取得済なら、何もしない。 * 未取得なら、OAuthフローを開始する。
         * @summary ユーザーのアクセストークンをアプリが取得済かを判定する
         * @param {AuthorizeRequest} [authorizeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauthAuthorize: async (authorizeRequest?: AuthorizeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/oauth/authorize`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authorizeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary クラスを更新する
         * @param {string} classId 
         * @param {ClassWrite} [classWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchClass: async (classId: string, classWrite?: ClassWrite, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'classId' is not null or undefined
            assertParamExists('patchClass', 'classId', classId)
            const localVarPath = `/v2/classes/{classId}`
                .replace(`{${"classId"}}`, encodeURIComponent(String(classId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(classWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 保護者を更新する
         * @param {string} userId 
         * @param {GuardianWrite} [guardianWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchGuardian: async (userId: string, guardianWrite?: GuardianWrite, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('patchGuardian', 'userId', userId)
            const localVarPath = `/v2/guardians/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(guardianWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} guardianId 
         * @param {string} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchGuardiansGuardianIdChildrenChildId: async (guardianId: string, childId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guardianId' is not null or undefined
            assertParamExists('patchGuardiansGuardianIdChildrenChildId', 'guardianId', guardianId)
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('patchGuardiansGuardianIdChildrenChildId', 'childId', childId)
            const localVarPath = `/guardians/{guardianId}/children/{childId}/profile`
                .replace(`{${"guardianId"}}`, encodeURIComponent(String(guardianId)))
                .replace(`{${"childId"}}`, encodeURIComponent(String(childId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 対象の児童生徒の検診情報を更新する
         * @param {string} guardianId 
         * @param {string} childId 
         * @param {ChildHealthV1} [childHealthV1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchGuardiansGuardianIdChildrenChildIdHealth: async (guardianId: string, childId: string, childHealthV1?: ChildHealthV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guardianId' is not null or undefined
            assertParamExists('patchGuardiansGuardianIdChildrenChildIdHealth', 'guardianId', guardianId)
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('patchGuardiansGuardianIdChildrenChildIdHealth', 'childId', childId)
            const localVarPath = `/guardians/{guardianId}/children/{childId}/health`
                .replace(`{${"guardianId"}}`, encodeURIComponent(String(guardianId)))
                .replace(`{${"childId"}}`, encodeURIComponent(String(childId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(childHealthV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 保護者プロファイル情報を更新する。
         * @param {string} guardianId 
         * @param {GuardianProfileV1} [guardianProfileV1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchGuardiansGuardianIdProfile: async (guardianId: string, guardianProfileV1?: GuardianProfileV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guardianId' is not null or undefined
            assertParamExists('patchGuardiansGuardianIdProfile', 'guardianId', guardianId)
            const localVarPath = `/guardians/{guardianId}/profile`
                .replace(`{${"guardianId"}}`, encodeURIComponent(String(guardianId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(guardianProfileV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 学校を更新する
         * @param {SchoolWrite} [schoolWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchSchool: async (schoolWrite?: SchoolWrite, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/school`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(schoolWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} staffId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchStaffStaffId: async (staffId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'staffId' is not null or undefined
            assertParamExists('patchStaffStaffId', 'staffId', staffId)
            const localVarPath = `/staff/{staffId}`
                .replace(`{${"staffId"}}`, encodeURIComponent(String(staffId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 児童生徒を更新する
         * @param {string} userId 
         * @param {StudentWrite} [studentWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchStudent: async (userId: string, studentWrite?: StudentWrite, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('patchStudent', 'userId', userId)
            const localVarPath = `/v2/students/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(studentWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 児童生徒カスタム項目の定義を更新する
         * @param {StudentCustomColumnDefSetWrite} [studentCustomColumnDefSetWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchStudentCustomColumnDef: async (studentCustomColumnDefSetWrite?: StudentCustomColumnDefSetWrite, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/studentCustomColumnDef`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(studentCustomColumnDefSetWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 教職員を更新する
         * @param {string} userId 
         * @param {TeacherWrite} [teacherWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchTeacher: async (userId: string, teacherWrite?: TeacherWrite, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('patchTeacher', 'userId', userId)
            const localVarPath = `/v2/teachers/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(teacherWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary クラスを作成する
         * @param {ClassWrite} [classWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postClass: async (classWrite?: ClassWrite, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/classes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(classWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ロール紐づけを作成・更新する。  現仕様では、教職員以外にロールを紐づけても効果が無い。(紐づけ自体は可能)  `{key}` = `{userId}:{roleId}`
         * @summary ロール紐づけを作成・更新する
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putRolebinding: async (key: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('putRolebinding', 'key', key)
            const localVarPath = `/v2/rolebindings/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * `{key}` = `{schoolYear}:{schoolType}:{termNumber}`
         * @summary 学期を作成・更新する
         * @param {string} key 
         * @param {TermWrite} [termWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTerm: async (key: string, termWrite?: TermWrite, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('putTerm', 'key', key)
            const localVarPath = `/v2/terms/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(termWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 普段は行う必要なし。 移行時や手修正後などに使う。
         * @summary データ整合性を回復する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreDataConsistency: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/admin/restoreDataConsistency`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary GWSとの同期を行う
         * @param {SyncWithGWSRequest} [syncWithGWSRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncWithGWS: async (syncWithGWSRequest?: SyncWithGWSRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/admin/syncWithGWS`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(syncWithGWSRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 保護者の写真1をアップロードする
         * @param {string} userId 
         * @param {any} upfile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadGuardianPicture1: async (userId: string, upfile: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('uploadGuardianPicture1', 'userId', userId)
            // verify required parameter 'upfile' is not null or undefined
            assertParamExists('uploadGuardianPicture1', 'upfile', upfile)
            const localVarPath = `/v2/guardians/{userId}/uploadPicture1`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (upfile !== undefined) { 
                localVarFormParams.append('upfile', upfile as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 保護者の写真2をアップロードする
         * @param {string} userId 
         * @param {any} upfile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadGuardianPicture2: async (userId: string, upfile: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('uploadGuardianPicture2', 'userId', userId)
            // verify required parameter 'upfile' is not null or undefined
            assertParamExists('uploadGuardianPicture2', 'upfile', upfile)
            const localVarPath = `/v2/guardians/{userId}/uploadPicture2`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (upfile !== undefined) { 
                localVarFormParams.append('upfile', upfile as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 学校ロゴをアップロードする
         * @param {any} upfile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadSchoolLogo: async (upfile: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'upfile' is not null or undefined
            assertParamExists('uploadSchoolLogo', 'upfile', upfile)
            const localVarPath = `/v2/school/uploadLogo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (upfile !== undefined) { 
                localVarFormParams.append('upfile', upfile as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 児童生徒の写真をアップロードする
         * @param {string} userId 
         * @param {any} upfile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadStudentPicture: async (userId: string, upfile: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('uploadStudentPicture', 'userId', userId)
            // verify required parameter 'upfile' is not null or undefined
            assertParamExists('uploadStudentPicture', 'upfile', upfile)
            const localVarPath = `/v2/students/{userId}/uploadPicture`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (upfile !== undefined) { 
                localVarFormParams.append('upfile', upfile as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 保護者を一括更新する
         * @param {Array<GuardianWriteWithUserId>} [guardianWriteWithUserId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchPatchGuardian(guardianWriteWithUserId?: Array<GuardianWriteWithUserId>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchPatchGuardian(guardianWriteWithUserId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 児童生徒を一括更新する
         * @param {Array<StudentWriteWithUserId>} [studentWriteWithUserId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchPatchStudent(studentWriteWithUserId?: Array<StudentWriteWithUserId>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchPatchStudent(studentWriteWithUserId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 教職員を一括更新する
         * @param {Array<TeacherWriteWithUserId>} [teacherWriteWithUserId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchPatchTeacher(teacherWriteWithUserId?: Array<TeacherWriteWithUserId>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchPatchTeacher(teacherWriteWithUserId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary クラスを削除する
         * @param {string} classId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteClass(classId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteClass(classId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 保護者を削除する
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteGuardian(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteGuardian(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 保護者の写真1を削除する
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteGuardianPicture1(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteGuardianPicture1(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 保護者の写真2を削除する
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteGuardianPicture2(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteGuardianPicture2(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ロール紐づけを削除する
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRolebinding(key: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRolebinding(key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 学校ロゴを削除する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSchoolLogo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<School>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSchoolLogo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 児童生徒を削除する
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteStudent(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteStudent(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 児童生徒の写真を削除する
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteStudentPicture(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteStudentPicture(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 教職員を削除する
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTeacher(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTeacher(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * `{key}` = `{schoolYear}:{schoolType}:{termNumber}`
         * @summary 学期を削除する
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTerm(key: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTerm(key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Firebase権限情報を登録する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async firebaseAuthUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.firebaseAuthUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ユーザー情報を返却する。 photoUrlはブラウザキャッシュが行われる(10分)
         * @summary ユーザー基本情報を取得
         * @param {string} userId 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBasicUserInfo(userId: string, body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BasicUserInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBasicUserInfo(userId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary クラスを取得する
         * @param {string} classId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClass(classId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Class>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClass(classId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * クラスに所属する児童生徒を出席番号順に取得する。
         * @summary クラスの児童生徒一覧を取得する
         * @param {string} classId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClassStudentsV1(classId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StudentV1>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClassStudentsV1(classId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した クラス情報を取得する。
         * @summary クラスを取得する
         * @param {string} classId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClassesClassIdV1(classId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClassV1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClassesClassIdV1(classId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * クラスを検索し、学校区分、学年、クラス番号順に取得する。
         * @summary クラスの一覧を取得する
         * @param {number} [grade] 学年を指定する。 
         * @param {string} [schoolType] 小中区分を指定する。
         * @param {number} [schoolYear] 年度（省略時は今年度）
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClassesV1(grade?: number, schoolType?: string, schoolYear?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClassV1>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClassesV1(grade, schoolType, schoolYear, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 保護者を取得する
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGuardian(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Guardian>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGuardian(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定の保護者に紐付く児童生徒を指定して児童生徒の検診情報を取得する。 児童生徒の保護者、教師のみアクセスできる。
         * @summary 保護者に紐付く児童生徒の検診プ情報を取得する
         * @param {string} guardianId 
         * @param {string} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGuardiansGuardianIdChildrenChildIdHealthV1(guardianId: string, childId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChildHealthV1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGuardiansGuardianIdChildrenChildIdHealthV1(guardianId, childId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定の保護者に紐付く児童生徒を指定して児童生徒プロファイル情報を取得する。 児童生徒の保護者、教師のみアクセスできる。
         * @summary 保護者に紐付く児童生徒プロファイル情報を取得する
         * @param {string} guardianId 
         * @param {string} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGuardiansGuardianIdChildrenChildIdV1(guardianId: string, childId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChildProfileV1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGuardiansGuardianIdChildrenChildIdV1(guardianId, childId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 保護者の保護下にある児童生徒一覧を取得する。 保護者もしくは教師のみアクセスできる。
         * @summary 保護者に紐付く児童生徒一覧を取得する。
         * @param {string} guardianId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGuardiansGuardianIdChildrenV1(guardianId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StudentV1>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGuardiansGuardianIdChildrenV1(guardianId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 保護者プロファイル情報を取得する
         * @param {string} guardianId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGuardiansGuardianIdProfile(guardianId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuardianProfileV1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGuardiansGuardianIdProfile(guardianId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 保護者もしくは教師のみアクセスできる。
         * @summary 保護者情報を取得する
         * @param {string} guardianId 保護者ID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaentsGuardianIdV1(guardianId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuardianV1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaentsGuardianIdV1(guardianId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 学校を取得する
         * @param {boolean} [noCache] &#x60;true&#x60;の場合、レスポンスをキャッシュさせない。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSchool(noCache?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<School>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSchool(noCache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 現在の学校情報を取得する。
         * @summary 学校情報を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSchoolV1(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SchoolV1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSchoolV1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 現在の学期を取得する。
         * @summary Your GET endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSemesterV1(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SemesterV1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSemesterV1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 教職員プロファイル情報を取得する。 教職員のみアクセスできる。
         * @summary 教職員プロファイル情報を取得する
         * @param {string} staffId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStaffStaffIdV1(staffId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffV1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStaffStaffIdV1(staffId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 児童生徒を取得する
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStudent(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Student>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStudent(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 児童生徒カスタム項目の定義を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStudentCustomColumnDef(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudentCustomColumnDefSet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStudentCustomColumnDef(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した児童生徒IDの児童生徒情報を取得する。 児童生徒本人、児童生徒の保護者、教師のみアクセスできる。
         * @summary 児童生徒を取得する
         * @param {string} studentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStudentsStudentIdV1(studentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudentV1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStudentsStudentIdV1(studentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 教職員を取得する
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeacher(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Teacher>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeacher(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した教師IDの教師情報を取得する。
         * @summary 教師情報を取得する
         * @param {string} teacherId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeachersTeacherIdV1(teacherId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeacherV1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeachersTeacherIdV1(teacherId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した日時の学期を取得する。  日時未指定の場合、現在の学期を取得する。
         * @summary 指定した日時の学期を取得する
         * @param {string} schoolType 学校タイプ。&#x60;elementary&#x60;, &#x60;juniorhigh&#x60;のいずれか。
         * @param {string} [at] 日時。例: &#x60;2021-11-25T13:58:24+09:00&#x60;
         * @param {string} [noCache] &#x60;true&#x60;の場合、レスポンスをキャッシュさせない。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTermByTime(schoolType: string, at?: string, noCache?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Term>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTermByTime(schoolType, at, noCache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ユーザー情報を取得する。
         * @summary Your GET endpoint
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserInfo(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserInfo(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ユーザーについて、以下2点を返す。  * ユーザータイプ。（教師`teacher` / 職員`staff` / 児童生徒`student` / 保護者`guardian` / 該当なし`none`） * ユーザータイプに応じた、権限判定に関わる情報。   * `teacher`の場合、何もなし。   * `staff`の場合、何もなし。   * `student`の場合、所属クラス一覧(※年度毎にクラスは異なるが、過去のものを含めてすべて返却する)。   * `guardian`の場合、自分の子供(`student`)の一覧。さらに、それら`student`が、それぞれ所属クラス一覧を持つ。   * `none`の場合、何もなし。  # ユーザー別制約  * 児童生徒と保護者   * 自分自身のみ取得可能。 * 教師   * なんでも取得可能。
         * @summary Your GET endpoint
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identity(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Identity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identity(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary クラスのリストを取得する
         * @param {number} [schoolYear] 年度。
         * @param {string} [schoolType] 学校タイプ。値は &#x60;elementary&#x60;, &#x60;juniorhigh&#x60;。
         * @param {number} [gradeNumber] 学年（数値）。小学校は&#x60;1&#x60;-&#x60;6&#x60;、中学校は&#x60;1&#x60;-&#x60;3&#x60;。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listClass(schoolYear?: number, schoolType?: string, gradeNumber?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Class>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listClass(schoolYear, schoolType, gradeNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary クラスの児童生徒基本情報一覧を取得する
         * @param {string} classId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listClassStudents(classId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClassStudent>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listClassStudents(classId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 保護者のリストを取得する
         * @param {Array<string>} [userId] ユーザーID。複数指定可。
         * @param {string} [q] クエリ文字列。
         * @param {string} [schoolType] 学校タイプ。
         * @param {number} [gradeNumber] 学年数値。&#x60;schoolType&#x60;を指定した場合のみ指定可能。
         * @param {Array<string>} [classId] クラスID。複数指定可。
         * @param {boolean} [noClass] &#x60;true&#x60;なら、クラス未設定の児童生徒を1人以上持つ保護者のみを検索する。
         * @param {string} [inSchoolState] 在学状態。値は &#x60;inSchool&#x60;, &#x60;graduated&#x60;, &#x60;transferred&#x60;, &#x60;graduatedOrTransferred&#x60;。指定する場合、&#x60;inSchoolStateOn&#x60;の指定が必須。
         * @param {string} [inSchoolStateOn] &#x60;inSchoolState&#x60;を計算する日付。この日付の在学状態で検索する。
         * @param {number} [classSchoolYear] 検索条件の&#x60;schoolType&#x60;、&#x60;gradeNumber&#x60;を指定した場合は必須で、この年度のクラスを使用して検索を行う。
         * @param {string} [sortBy] ソート用項目。値は &#x60;nameKana&#x60;, &#x60;createdAt&#x60;, &#x60;updatedAt&#x60;。
         * @param {string} [sortOrder] 並び順。値は &#x60;asc&#x60;, &#x60;desc&#x60;。
         * @param {number} [limit] 検索上限件数。
         * @param {number} [offset] 検索オフセット。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listGuardian(userId?: Array<string>, q?: string, schoolType?: string, gradeNumber?: number, classId?: Array<string>, noClass?: boolean, inSchoolState?: string, inSchoolStateOn?: string, classSchoolYear?: number, sortBy?: string, sortOrder?: string, limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuardianSearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listGuardian(userId, q, schoolType, gradeNumber, classId, noClass, inSchoolState, inSchoolStateOn, classSchoolYear, sortBy, sortOrder, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * `list-guardian` と同じ働きをするが、以下が異なる。  - postリクエストでbodyを受け取る方式。 - 受け取れるパラメタはuserIdsのみ。  通常のListGuardianはgetリクエストなので、URL長制限に引っかかる場合があり、その回避策として用いるもの。
         * @summary 保護者のリストを取得する
         * @param {InlineObject3} [inlineObject3] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listGuardianByPost(inlineObject3?: InlineObject3, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuardianSearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listGuardianByPost(inlineObject3, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ロール紐づけのリストを取得する
         * @param {string} [userId] ユーザーID。
         * @param {string} [roleId] ロールID。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listRolebinding(userId?: string, roleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RoleBinding>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listRolebinding(userId, roleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 児童生徒のリストを取得する
         * @param {Array<string>} [userId] ユーザーID。複数指定可。
         * @param {string} [q] クエリ文字列。
         * @param {string} [guardianUserId] 保護者ユーザーID。
         * @param {string} [schoolType] 学校タイプ。
         * @param {number} [gradeNumber] 学年数値。&#x60;schoolType&#x60;を指定した場合のみ指定可能。
         * @param {Array<string>} [classId] クラスID。複数指定可。
         * @param {boolean} [noClass] &#x60;true&#x60;なら、クラス未設定の児童生徒のみを検索する。
         * @param {string} [inSchoolState] 在学状態。値は &#x60;inSchool&#x60;, &#x60;graduated&#x60;, &#x60;transferred&#x60;, &#x60;graduatedOrTransferred&#x60;。指定する場合、&#x60;inSchoolStateOn&#x60;の指定が必須。
         * @param {string} [inSchoolStateOn] &#x60;inSchoolState&#x60;を計算する日付。この日付の在学状態で検索する。
         * @param {number} [classSchoolYear] 検索条件の&#x60;schoolType&#x60;、&#x60;gradeNumber&#x60;、または&#x60;sortBy&#x60;に&#x60;gradeClassStudentNumber&#x60;を指定した場合は必須で、この年度のクラスを使用して検索・並び替えを行う。
         * @param {string} [sortBy] ソート用項目。値は &#x60;gradeClassStudentNumber&#x60;, &#x60;nameKana&#x60;, &#x60;createdAt&#x60;, &#x60;updatedAt&#x60;。
         * @param {string} [sortOrder] 並び順。値は &#x60;asc&#x60;, &#x60;desc&#x60;。
         * @param {number} [limit] 検索上限件数。
         * @param {number} [offset] 検索オフセット。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listStudent(userId?: Array<string>, q?: string, guardianUserId?: string, schoolType?: string, gradeNumber?: number, classId?: Array<string>, noClass?: boolean, inSchoolState?: string, inSchoolStateOn?: string, classSchoolYear?: number, sortBy?: string, sortOrder?: string, limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudentSearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listStudent(userId, q, guardianUserId, schoolType, gradeNumber, classId, noClass, inSchoolState, inSchoolStateOn, classSchoolYear, sortBy, sortOrder, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * `list-student` と同じ働きをするが、以下が異なる。  - postリクエストでbodyを受け取る方式。 - 受け取れるパラメタはuserIdsのみ。  通常のListStudentはgetリクエストなので、URL長制限に引っかかる場合があり、その回避策として用いるもの。
         * @summary 児童生徒のリストを取得する
         * @param {InlineObject1} [inlineObject1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listStudentByPost(inlineObject1?: InlineObject1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudentSearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listStudentByPost(inlineObject1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 教職員のリストを取得する
         * @param {Array<string>} [userId] ユーザーID。複数指定可。
         * @param {string} [q] クエリ文字列。
         * @param {string} [teacherType] 教職員タイプ。値は &#x60;0&#x60;: staff, &#x60;1&#x60;: teacher。
         * @param {number} [classSchoolYear] 検索条件の&#x60;schoolType&#x60;、&#x60;gradeNumber&#x60;を指定した場合は必須で、この年度のクラスを使用して検索・並び替えを行う。
         * @param {string} [schoolType] 学校タイプ。
         * @param {number} [gradeNumber] 学年数値。&#x60;schoolType&#x60;を指定した場合のみ指定可能。
         * @param {Array<string>} [classId] クラスID。複数指定可。
         * @param {boolean} [noClass] &#x60;true&#x60;なら、クラス未設定の教職員のみを検索する。
         * @param {string} [sortBy] ソート用項目。値は &#x60;nameKana&#x60;, &#x60;createdAt&#x60;, &#x60;updatedAt&#x60;。 
         * @param {string} [sortOrder] 並び順。値は &#x60;asc&#x60;: 昇順, &#x60;desc&#x60;: 降順。
         * @param {number} [limit] 検索上限件数。
         * @param {number} [offset] 検索オフセット。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listTeacher(userId?: Array<string>, q?: string, teacherType?: string, classSchoolYear?: number, schoolType?: string, gradeNumber?: number, classId?: Array<string>, noClass?: boolean, sortBy?: string, sortOrder?: string, limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeacherSearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listTeacher(userId, q, teacherType, classSchoolYear, schoolType, gradeNumber, classId, noClass, sortBy, sortOrder, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * `list-teacher` と同じ働きをするが、以下が異なる。  - postリクエストでbodyを受け取る方式。 - 受け取れるパラメタはuserIdsのみ。  通常のListTeacherはgetリクエストなので、URL長制限に引っかかる場合があり、その回避策として用いるもの。
         * @summary 教職員のリストを取得する
         * @param {InlineObject} [inlineObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listTeacherByPost(inlineObject?: InlineObject, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeacherSearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listTeacherByPost(inlineObject, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 学期のリストを取得する
         * @param {string} [schoolType] 学校タイプ。&#x60;elementary&#x60;, &#x60;juniorhigh&#x60;。
         * @param {number} [schoolYear] 学年。
         * @param {number} [termNumber] 学期番号。
         * @param {boolean} [noCache] &#x60;true&#x60;の場合、レスポンスをキャッシュさせない。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listTerm(schoolType?: string, schoolYear?: number, termNumber?: number, noCache?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Term>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listTerm(schoolType, schoolYear, termNumber, noCache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ユーザーのアクセストークンをアプリが取得済かを判定する。  * 取得済なら、何もしない。 * 未取得なら、OAuthフローを開始する。
         * @summary ユーザーのアクセストークンをアプリが取得済かを判定する
         * @param {AuthorizeRequest} [authorizeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async oauthAuthorize(authorizeRequest?: AuthorizeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthorizeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.oauthAuthorize(authorizeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary クラスを更新する
         * @param {string} classId 
         * @param {ClassWrite} [classWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchClass(classId: string, classWrite?: ClassWrite, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Class>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchClass(classId, classWrite, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 保護者を更新する
         * @param {string} userId 
         * @param {GuardianWrite} [guardianWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchGuardian(userId: string, guardianWrite?: GuardianWrite, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Guardian>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchGuardian(userId, guardianWrite, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} guardianId 
         * @param {string} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchGuardiansGuardianIdChildrenChildId(guardianId: string, childId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchGuardiansGuardianIdChildrenChildId(guardianId, childId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 対象の児童生徒の検診情報を更新する
         * @param {string} guardianId 
         * @param {string} childId 
         * @param {ChildHealthV1} [childHealthV1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchGuardiansGuardianIdChildrenChildIdHealth(guardianId: string, childId: string, childHealthV1?: ChildHealthV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchGuardiansGuardianIdChildrenChildIdHealth(guardianId, childId, childHealthV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 保護者プロファイル情報を更新する。
         * @param {string} guardianId 
         * @param {GuardianProfileV1} [guardianProfileV1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchGuardiansGuardianIdProfile(guardianId: string, guardianProfileV1?: GuardianProfileV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchGuardiansGuardianIdProfile(guardianId, guardianProfileV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 学校を更新する
         * @param {SchoolWrite} [schoolWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchSchool(schoolWrite?: SchoolWrite, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<School>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchSchool(schoolWrite, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} staffId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchStaffStaffId(staffId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchStaffStaffId(staffId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 児童生徒を更新する
         * @param {string} userId 
         * @param {StudentWrite} [studentWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchStudent(userId: string, studentWrite?: StudentWrite, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Student>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchStudent(userId, studentWrite, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 児童生徒カスタム項目の定義を更新する
         * @param {StudentCustomColumnDefSetWrite} [studentCustomColumnDefSetWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchStudentCustomColumnDef(studentCustomColumnDefSetWrite?: StudentCustomColumnDefSetWrite, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudentCustomColumnDefSet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchStudentCustomColumnDef(studentCustomColumnDefSetWrite, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 教職員を更新する
         * @param {string} userId 
         * @param {TeacherWrite} [teacherWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchTeacher(userId: string, teacherWrite?: TeacherWrite, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Teacher>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchTeacher(userId, teacherWrite, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary クラスを作成する
         * @param {ClassWrite} [classWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postClass(classWrite?: ClassWrite, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Class>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postClass(classWrite, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ロール紐づけを作成・更新する。  現仕様では、教職員以外にロールを紐づけても効果が無い。(紐づけ自体は可能)  `{key}` = `{userId}:{roleId}`
         * @summary ロール紐づけを作成・更新する
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putRolebinding(key: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleBinding>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putRolebinding(key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * `{key}` = `{schoolYear}:{schoolType}:{termNumber}`
         * @summary 学期を作成・更新する
         * @param {string} key 
         * @param {TermWrite} [termWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putTerm(key: string, termWrite?: TermWrite, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Term>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putTerm(key, termWrite, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 普段は行う必要なし。 移行時や手修正後などに使う。
         * @summary データ整合性を回復する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restoreDataConsistency(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restoreDataConsistency(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary GWSとの同期を行う
         * @param {SyncWithGWSRequest} [syncWithGWSRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncWithGWS(syncWithGWSRequest?: SyncWithGWSRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SyncWithGWSResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncWithGWS(syncWithGWSRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 保護者の写真1をアップロードする
         * @param {string} userId 
         * @param {any} upfile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadGuardianPicture1(userId: string, upfile: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Guardian>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadGuardianPicture1(userId, upfile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 保護者の写真2をアップロードする
         * @param {string} userId 
         * @param {any} upfile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadGuardianPicture2(userId: string, upfile: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Guardian>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadGuardianPicture2(userId, upfile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 学校ロゴをアップロードする
         * @param {any} upfile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadSchoolLogo(upfile: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<School>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadSchoolLogo(upfile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 児童生徒の写真をアップロードする
         * @param {string} userId 
         * @param {any} upfile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadStudentPicture(userId: string, upfile: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Student>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadStudentPicture(userId, upfile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @summary 保護者を一括更新する
         * @param {Array<GuardianWriteWithUserId>} [guardianWriteWithUserId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchPatchGuardian(guardianWriteWithUserId?: Array<GuardianWriteWithUserId>, options?: any): AxiosPromise<void> {
            return localVarFp.batchPatchGuardian(guardianWriteWithUserId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 児童生徒を一括更新する
         * @param {Array<StudentWriteWithUserId>} [studentWriteWithUserId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchPatchStudent(studentWriteWithUserId?: Array<StudentWriteWithUserId>, options?: any): AxiosPromise<void> {
            return localVarFp.batchPatchStudent(studentWriteWithUserId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 教職員を一括更新する
         * @param {Array<TeacherWriteWithUserId>} [teacherWriteWithUserId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchPatchTeacher(teacherWriteWithUserId?: Array<TeacherWriteWithUserId>, options?: any): AxiosPromise<void> {
            return localVarFp.batchPatchTeacher(teacherWriteWithUserId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary クラスを削除する
         * @param {string} classId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteClass(classId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteClass(classId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 保護者を削除する
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGuardian(userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteGuardian(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 保護者の写真1を削除する
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGuardianPicture1(userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteGuardianPicture1(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 保護者の写真2を削除する
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGuardianPicture2(userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteGuardianPicture2(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ロール紐づけを削除する
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRolebinding(key: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteRolebinding(key, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 学校ロゴを削除する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSchoolLogo(options?: any): AxiosPromise<School> {
            return localVarFp.deleteSchoolLogo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 児童生徒を削除する
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStudent(userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteStudent(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 児童生徒の写真を削除する
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStudentPicture(userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteStudentPicture(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 教職員を削除する
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTeacher(userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTeacher(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * `{key}` = `{schoolYear}:{schoolType}:{termNumber}`
         * @summary 学期を削除する
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTerm(key: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTerm(key, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Firebase権限情報を登録する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        firebaseAuthUser(options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.firebaseAuthUser(options).then((request) => request(axios, basePath));
        },
        /**
         * ユーザー情報を返却する。 photoUrlはブラウザキャッシュが行われる(10分)
         * @summary ユーザー基本情報を取得
         * @param {string} userId 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBasicUserInfo(userId: string, body?: object, options?: any): AxiosPromise<BasicUserInfo> {
            return localVarFp.getBasicUserInfo(userId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary クラスを取得する
         * @param {string} classId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClass(classId: string, options?: any): AxiosPromise<Class> {
            return localVarFp.getClass(classId, options).then((request) => request(axios, basePath));
        },
        /**
         * クラスに所属する児童生徒を出席番号順に取得する。
         * @summary クラスの児童生徒一覧を取得する
         * @param {string} classId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClassStudentsV1(classId: string, options?: any): AxiosPromise<Array<StudentV1>> {
            return localVarFp.getClassStudentsV1(classId, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した クラス情報を取得する。
         * @summary クラスを取得する
         * @param {string} classId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClassesClassIdV1(classId: string, options?: any): AxiosPromise<ClassV1> {
            return localVarFp.getClassesClassIdV1(classId, options).then((request) => request(axios, basePath));
        },
        /**
         * クラスを検索し、学校区分、学年、クラス番号順に取得する。
         * @summary クラスの一覧を取得する
         * @param {number} [grade] 学年を指定する。 
         * @param {string} [schoolType] 小中区分を指定する。
         * @param {number} [schoolYear] 年度（省略時は今年度）
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClassesV1(grade?: number, schoolType?: string, schoolYear?: number, options?: any): AxiosPromise<Array<ClassV1>> {
            return localVarFp.getClassesV1(grade, schoolType, schoolYear, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 保護者を取得する
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGuardian(userId: string, options?: any): AxiosPromise<Guardian> {
            return localVarFp.getGuardian(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定の保護者に紐付く児童生徒を指定して児童生徒の検診情報を取得する。 児童生徒の保護者、教師のみアクセスできる。
         * @summary 保護者に紐付く児童生徒の検診プ情報を取得する
         * @param {string} guardianId 
         * @param {string} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGuardiansGuardianIdChildrenChildIdHealthV1(guardianId: string, childId: string, options?: any): AxiosPromise<ChildHealthV1> {
            return localVarFp.getGuardiansGuardianIdChildrenChildIdHealthV1(guardianId, childId, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定の保護者に紐付く児童生徒を指定して児童生徒プロファイル情報を取得する。 児童生徒の保護者、教師のみアクセスできる。
         * @summary 保護者に紐付く児童生徒プロファイル情報を取得する
         * @param {string} guardianId 
         * @param {string} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGuardiansGuardianIdChildrenChildIdV1(guardianId: string, childId: string, options?: any): AxiosPromise<ChildProfileV1> {
            return localVarFp.getGuardiansGuardianIdChildrenChildIdV1(guardianId, childId, options).then((request) => request(axios, basePath));
        },
        /**
         * 保護者の保護下にある児童生徒一覧を取得する。 保護者もしくは教師のみアクセスできる。
         * @summary 保護者に紐付く児童生徒一覧を取得する。
         * @param {string} guardianId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGuardiansGuardianIdChildrenV1(guardianId: string, options?: any): AxiosPromise<Array<StudentV1>> {
            return localVarFp.getGuardiansGuardianIdChildrenV1(guardianId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 保護者プロファイル情報を取得する
         * @param {string} guardianId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGuardiansGuardianIdProfile(guardianId: string, options?: any): AxiosPromise<GuardianProfileV1> {
            return localVarFp.getGuardiansGuardianIdProfile(guardianId, options).then((request) => request(axios, basePath));
        },
        /**
         * 保護者もしくは教師のみアクセスできる。
         * @summary 保護者情報を取得する
         * @param {string} guardianId 保護者ID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaentsGuardianIdV1(guardianId: string, options?: any): AxiosPromise<GuardianV1> {
            return localVarFp.getPaentsGuardianIdV1(guardianId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 学校を取得する
         * @param {boolean} [noCache] &#x60;true&#x60;の場合、レスポンスをキャッシュさせない。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSchool(noCache?: boolean, options?: any): AxiosPromise<School> {
            return localVarFp.getSchool(noCache, options).then((request) => request(axios, basePath));
        },
        /**
         * 現在の学校情報を取得する。
         * @summary 学校情報を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSchoolV1(options?: any): AxiosPromise<SchoolV1> {
            return localVarFp.getSchoolV1(options).then((request) => request(axios, basePath));
        },
        /**
         * 現在の学期を取得する。
         * @summary Your GET endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSemesterV1(options?: any): AxiosPromise<SemesterV1> {
            return localVarFp.getSemesterV1(options).then((request) => request(axios, basePath));
        },
        /**
         * 教職員プロファイル情報を取得する。 教職員のみアクセスできる。
         * @summary 教職員プロファイル情報を取得する
         * @param {string} staffId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStaffStaffIdV1(staffId: string, options?: any): AxiosPromise<StaffV1> {
            return localVarFp.getStaffStaffIdV1(staffId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 児童生徒を取得する
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudent(userId: string, options?: any): AxiosPromise<Student> {
            return localVarFp.getStudent(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 児童生徒カスタム項目の定義を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudentCustomColumnDef(options?: any): AxiosPromise<StudentCustomColumnDefSet> {
            return localVarFp.getStudentCustomColumnDef(options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した児童生徒IDの児童生徒情報を取得する。 児童生徒本人、児童生徒の保護者、教師のみアクセスできる。
         * @summary 児童生徒を取得する
         * @param {string} studentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudentsStudentIdV1(studentId: string, options?: any): AxiosPromise<StudentV1> {
            return localVarFp.getStudentsStudentIdV1(studentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 教職員を取得する
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeacher(userId: string, options?: any): AxiosPromise<Teacher> {
            return localVarFp.getTeacher(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した教師IDの教師情報を取得する。
         * @summary 教師情報を取得する
         * @param {string} teacherId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeachersTeacherIdV1(teacherId: string, options?: any): AxiosPromise<TeacherV1> {
            return localVarFp.getTeachersTeacherIdV1(teacherId, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した日時の学期を取得する。  日時未指定の場合、現在の学期を取得する。
         * @summary 指定した日時の学期を取得する
         * @param {string} schoolType 学校タイプ。&#x60;elementary&#x60;, &#x60;juniorhigh&#x60;のいずれか。
         * @param {string} [at] 日時。例: &#x60;2021-11-25T13:58:24+09:00&#x60;
         * @param {string} [noCache] &#x60;true&#x60;の場合、レスポンスをキャッシュさせない。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTermByTime(schoolType: string, at?: string, noCache?: string, options?: any): AxiosPromise<Term> {
            return localVarFp.getTermByTime(schoolType, at, noCache, options).then((request) => request(axios, basePath));
        },
        /**
         * ユーザー情報を取得する。
         * @summary Your GET endpoint
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInfo(userId: string, options?: any): AxiosPromise<UserInfo> {
            return localVarFp.getUserInfo(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * ユーザーについて、以下2点を返す。  * ユーザータイプ。（教師`teacher` / 職員`staff` / 児童生徒`student` / 保護者`guardian` / 該当なし`none`） * ユーザータイプに応じた、権限判定に関わる情報。   * `teacher`の場合、何もなし。   * `staff`の場合、何もなし。   * `student`の場合、所属クラス一覧(※年度毎にクラスは異なるが、過去のものを含めてすべて返却する)。   * `guardian`の場合、自分の子供(`student`)の一覧。さらに、それら`student`が、それぞれ所属クラス一覧を持つ。   * `none`の場合、何もなし。  # ユーザー別制約  * 児童生徒と保護者   * 自分自身のみ取得可能。 * 教師   * なんでも取得可能。
         * @summary Your GET endpoint
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identity(userId: string, options?: any): AxiosPromise<Identity> {
            return localVarFp.identity(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary クラスのリストを取得する
         * @param {number} [schoolYear] 年度。
         * @param {string} [schoolType] 学校タイプ。値は &#x60;elementary&#x60;, &#x60;juniorhigh&#x60;。
         * @param {number} [gradeNumber] 学年（数値）。小学校は&#x60;1&#x60;-&#x60;6&#x60;、中学校は&#x60;1&#x60;-&#x60;3&#x60;。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClass(schoolYear?: number, schoolType?: string, gradeNumber?: number, options?: any): AxiosPromise<Array<Class>> {
            return localVarFp.listClass(schoolYear, schoolType, gradeNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary クラスの児童生徒基本情報一覧を取得する
         * @param {string} classId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClassStudents(classId: string, options?: any): AxiosPromise<Array<ClassStudent>> {
            return localVarFp.listClassStudents(classId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 保護者のリストを取得する
         * @param {Array<string>} [userId] ユーザーID。複数指定可。
         * @param {string} [q] クエリ文字列。
         * @param {string} [schoolType] 学校タイプ。
         * @param {number} [gradeNumber] 学年数値。&#x60;schoolType&#x60;を指定した場合のみ指定可能。
         * @param {Array<string>} [classId] クラスID。複数指定可。
         * @param {boolean} [noClass] &#x60;true&#x60;なら、クラス未設定の児童生徒を1人以上持つ保護者のみを検索する。
         * @param {string} [inSchoolState] 在学状態。値は &#x60;inSchool&#x60;, &#x60;graduated&#x60;, &#x60;transferred&#x60;, &#x60;graduatedOrTransferred&#x60;。指定する場合、&#x60;inSchoolStateOn&#x60;の指定が必須。
         * @param {string} [inSchoolStateOn] &#x60;inSchoolState&#x60;を計算する日付。この日付の在学状態で検索する。
         * @param {number} [classSchoolYear] 検索条件の&#x60;schoolType&#x60;、&#x60;gradeNumber&#x60;を指定した場合は必須で、この年度のクラスを使用して検索を行う。
         * @param {string} [sortBy] ソート用項目。値は &#x60;nameKana&#x60;, &#x60;createdAt&#x60;, &#x60;updatedAt&#x60;。
         * @param {string} [sortOrder] 並び順。値は &#x60;asc&#x60;, &#x60;desc&#x60;。
         * @param {number} [limit] 検索上限件数。
         * @param {number} [offset] 検索オフセット。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGuardian(userId?: Array<string>, q?: string, schoolType?: string, gradeNumber?: number, classId?: Array<string>, noClass?: boolean, inSchoolState?: string, inSchoolStateOn?: string, classSchoolYear?: number, sortBy?: string, sortOrder?: string, limit?: number, offset?: number, options?: any): AxiosPromise<GuardianSearchResult> {
            return localVarFp.listGuardian(userId, q, schoolType, gradeNumber, classId, noClass, inSchoolState, inSchoolStateOn, classSchoolYear, sortBy, sortOrder, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * `list-guardian` と同じ働きをするが、以下が異なる。  - postリクエストでbodyを受け取る方式。 - 受け取れるパラメタはuserIdsのみ。  通常のListGuardianはgetリクエストなので、URL長制限に引っかかる場合があり、その回避策として用いるもの。
         * @summary 保護者のリストを取得する
         * @param {InlineObject3} [inlineObject3] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGuardianByPost(inlineObject3?: InlineObject3, options?: any): AxiosPromise<GuardianSearchResult> {
            return localVarFp.listGuardianByPost(inlineObject3, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ロール紐づけのリストを取得する
         * @param {string} [userId] ユーザーID。
         * @param {string} [roleId] ロールID。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRolebinding(userId?: string, roleId?: string, options?: any): AxiosPromise<Array<RoleBinding>> {
            return localVarFp.listRolebinding(userId, roleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 児童生徒のリストを取得する
         * @param {Array<string>} [userId] ユーザーID。複数指定可。
         * @param {string} [q] クエリ文字列。
         * @param {string} [guardianUserId] 保護者ユーザーID。
         * @param {string} [schoolType] 学校タイプ。
         * @param {number} [gradeNumber] 学年数値。&#x60;schoolType&#x60;を指定した場合のみ指定可能。
         * @param {Array<string>} [classId] クラスID。複数指定可。
         * @param {boolean} [noClass] &#x60;true&#x60;なら、クラス未設定の児童生徒のみを検索する。
         * @param {string} [inSchoolState] 在学状態。値は &#x60;inSchool&#x60;, &#x60;graduated&#x60;, &#x60;transferred&#x60;, &#x60;graduatedOrTransferred&#x60;。指定する場合、&#x60;inSchoolStateOn&#x60;の指定が必須。
         * @param {string} [inSchoolStateOn] &#x60;inSchoolState&#x60;を計算する日付。この日付の在学状態で検索する。
         * @param {number} [classSchoolYear] 検索条件の&#x60;schoolType&#x60;、&#x60;gradeNumber&#x60;、または&#x60;sortBy&#x60;に&#x60;gradeClassStudentNumber&#x60;を指定した場合は必須で、この年度のクラスを使用して検索・並び替えを行う。
         * @param {string} [sortBy] ソート用項目。値は &#x60;gradeClassStudentNumber&#x60;, &#x60;nameKana&#x60;, &#x60;createdAt&#x60;, &#x60;updatedAt&#x60;。
         * @param {string} [sortOrder] 並び順。値は &#x60;asc&#x60;, &#x60;desc&#x60;。
         * @param {number} [limit] 検索上限件数。
         * @param {number} [offset] 検索オフセット。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStudent(userId?: Array<string>, q?: string, guardianUserId?: string, schoolType?: string, gradeNumber?: number, classId?: Array<string>, noClass?: boolean, inSchoolState?: string, inSchoolStateOn?: string, classSchoolYear?: number, sortBy?: string, sortOrder?: string, limit?: number, offset?: number, options?: any): AxiosPromise<StudentSearchResult> {
            return localVarFp.listStudent(userId, q, guardianUserId, schoolType, gradeNumber, classId, noClass, inSchoolState, inSchoolStateOn, classSchoolYear, sortBy, sortOrder, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * `list-student` と同じ働きをするが、以下が異なる。  - postリクエストでbodyを受け取る方式。 - 受け取れるパラメタはuserIdsのみ。  通常のListStudentはgetリクエストなので、URL長制限に引っかかる場合があり、その回避策として用いるもの。
         * @summary 児童生徒のリストを取得する
         * @param {InlineObject1} [inlineObject1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStudentByPost(inlineObject1?: InlineObject1, options?: any): AxiosPromise<StudentSearchResult> {
            return localVarFp.listStudentByPost(inlineObject1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 教職員のリストを取得する
         * @param {Array<string>} [userId] ユーザーID。複数指定可。
         * @param {string} [q] クエリ文字列。
         * @param {string} [teacherType] 教職員タイプ。値は &#x60;0&#x60;: staff, &#x60;1&#x60;: teacher。
         * @param {number} [classSchoolYear] 検索条件の&#x60;schoolType&#x60;、&#x60;gradeNumber&#x60;を指定した場合は必須で、この年度のクラスを使用して検索・並び替えを行う。
         * @param {string} [schoolType] 学校タイプ。
         * @param {number} [gradeNumber] 学年数値。&#x60;schoolType&#x60;を指定した場合のみ指定可能。
         * @param {Array<string>} [classId] クラスID。複数指定可。
         * @param {boolean} [noClass] &#x60;true&#x60;なら、クラス未設定の教職員のみを検索する。
         * @param {string} [sortBy] ソート用項目。値は &#x60;nameKana&#x60;, &#x60;createdAt&#x60;, &#x60;updatedAt&#x60;。 
         * @param {string} [sortOrder] 並び順。値は &#x60;asc&#x60;: 昇順, &#x60;desc&#x60;: 降順。
         * @param {number} [limit] 検索上限件数。
         * @param {number} [offset] 検索オフセット。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTeacher(userId?: Array<string>, q?: string, teacherType?: string, classSchoolYear?: number, schoolType?: string, gradeNumber?: number, classId?: Array<string>, noClass?: boolean, sortBy?: string, sortOrder?: string, limit?: number, offset?: number, options?: any): AxiosPromise<TeacherSearchResult> {
            return localVarFp.listTeacher(userId, q, teacherType, classSchoolYear, schoolType, gradeNumber, classId, noClass, sortBy, sortOrder, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * `list-teacher` と同じ働きをするが、以下が異なる。  - postリクエストでbodyを受け取る方式。 - 受け取れるパラメタはuserIdsのみ。  通常のListTeacherはgetリクエストなので、URL長制限に引っかかる場合があり、その回避策として用いるもの。
         * @summary 教職員のリストを取得する
         * @param {InlineObject} [inlineObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTeacherByPost(inlineObject?: InlineObject, options?: any): AxiosPromise<TeacherSearchResult> {
            return localVarFp.listTeacherByPost(inlineObject, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 学期のリストを取得する
         * @param {string} [schoolType] 学校タイプ。&#x60;elementary&#x60;, &#x60;juniorhigh&#x60;。
         * @param {number} [schoolYear] 学年。
         * @param {number} [termNumber] 学期番号。
         * @param {boolean} [noCache] &#x60;true&#x60;の場合、レスポンスをキャッシュさせない。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTerm(schoolType?: string, schoolYear?: number, termNumber?: number, noCache?: boolean, options?: any): AxiosPromise<Array<Term>> {
            return localVarFp.listTerm(schoolType, schoolYear, termNumber, noCache, options).then((request) => request(axios, basePath));
        },
        /**
         * ユーザーのアクセストークンをアプリが取得済かを判定する。  * 取得済なら、何もしない。 * 未取得なら、OAuthフローを開始する。
         * @summary ユーザーのアクセストークンをアプリが取得済かを判定する
         * @param {AuthorizeRequest} [authorizeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauthAuthorize(authorizeRequest?: AuthorizeRequest, options?: any): AxiosPromise<AuthorizeResponse> {
            return localVarFp.oauthAuthorize(authorizeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary クラスを更新する
         * @param {string} classId 
         * @param {ClassWrite} [classWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchClass(classId: string, classWrite?: ClassWrite, options?: any): AxiosPromise<Class> {
            return localVarFp.patchClass(classId, classWrite, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 保護者を更新する
         * @param {string} userId 
         * @param {GuardianWrite} [guardianWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchGuardian(userId: string, guardianWrite?: GuardianWrite, options?: any): AxiosPromise<Guardian> {
            return localVarFp.patchGuardian(userId, guardianWrite, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} guardianId 
         * @param {string} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchGuardiansGuardianIdChildrenChildId(guardianId: string, childId: string, options?: any): AxiosPromise<void> {
            return localVarFp.patchGuardiansGuardianIdChildrenChildId(guardianId, childId, options).then((request) => request(axios, basePath));
        },
        /**
         * 対象の児童生徒の検診情報を更新する
         * @param {string} guardianId 
         * @param {string} childId 
         * @param {ChildHealthV1} [childHealthV1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchGuardiansGuardianIdChildrenChildIdHealth(guardianId: string, childId: string, childHealthV1?: ChildHealthV1, options?: any): AxiosPromise<void> {
            return localVarFp.patchGuardiansGuardianIdChildrenChildIdHealth(guardianId, childId, childHealthV1, options).then((request) => request(axios, basePath));
        },
        /**
         * 保護者プロファイル情報を更新する。
         * @param {string} guardianId 
         * @param {GuardianProfileV1} [guardianProfileV1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchGuardiansGuardianIdProfile(guardianId: string, guardianProfileV1?: GuardianProfileV1, options?: any): AxiosPromise<void> {
            return localVarFp.patchGuardiansGuardianIdProfile(guardianId, guardianProfileV1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 学校を更新する
         * @param {SchoolWrite} [schoolWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchSchool(schoolWrite?: SchoolWrite, options?: any): AxiosPromise<School> {
            return localVarFp.patchSchool(schoolWrite, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} staffId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchStaffStaffId(staffId: string, options?: any): AxiosPromise<void> {
            return localVarFp.patchStaffStaffId(staffId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 児童生徒を更新する
         * @param {string} userId 
         * @param {StudentWrite} [studentWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchStudent(userId: string, studentWrite?: StudentWrite, options?: any): AxiosPromise<Student> {
            return localVarFp.patchStudent(userId, studentWrite, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 児童生徒カスタム項目の定義を更新する
         * @param {StudentCustomColumnDefSetWrite} [studentCustomColumnDefSetWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchStudentCustomColumnDef(studentCustomColumnDefSetWrite?: StudentCustomColumnDefSetWrite, options?: any): AxiosPromise<StudentCustomColumnDefSet> {
            return localVarFp.patchStudentCustomColumnDef(studentCustomColumnDefSetWrite, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 教職員を更新する
         * @param {string} userId 
         * @param {TeacherWrite} [teacherWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchTeacher(userId: string, teacherWrite?: TeacherWrite, options?: any): AxiosPromise<Teacher> {
            return localVarFp.patchTeacher(userId, teacherWrite, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary クラスを作成する
         * @param {ClassWrite} [classWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postClass(classWrite?: ClassWrite, options?: any): AxiosPromise<Class> {
            return localVarFp.postClass(classWrite, options).then((request) => request(axios, basePath));
        },
        /**
         * ロール紐づけを作成・更新する。  現仕様では、教職員以外にロールを紐づけても効果が無い。(紐づけ自体は可能)  `{key}` = `{userId}:{roleId}`
         * @summary ロール紐づけを作成・更新する
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putRolebinding(key: string, options?: any): AxiosPromise<RoleBinding> {
            return localVarFp.putRolebinding(key, options).then((request) => request(axios, basePath));
        },
        /**
         * `{key}` = `{schoolYear}:{schoolType}:{termNumber}`
         * @summary 学期を作成・更新する
         * @param {string} key 
         * @param {TermWrite} [termWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTerm(key: string, termWrite?: TermWrite, options?: any): AxiosPromise<Term> {
            return localVarFp.putTerm(key, termWrite, options).then((request) => request(axios, basePath));
        },
        /**
         * 普段は行う必要なし。 移行時や手修正後などに使う。
         * @summary データ整合性を回復する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreDataConsistency(options?: any): AxiosPromise<void> {
            return localVarFp.restoreDataConsistency(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary GWSとの同期を行う
         * @param {SyncWithGWSRequest} [syncWithGWSRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncWithGWS(syncWithGWSRequest?: SyncWithGWSRequest, options?: any): AxiosPromise<SyncWithGWSResponse> {
            return localVarFp.syncWithGWS(syncWithGWSRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 保護者の写真1をアップロードする
         * @param {string} userId 
         * @param {any} upfile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadGuardianPicture1(userId: string, upfile: any, options?: any): AxiosPromise<Guardian> {
            return localVarFp.uploadGuardianPicture1(userId, upfile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 保護者の写真2をアップロードする
         * @param {string} userId 
         * @param {any} upfile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadGuardianPicture2(userId: string, upfile: any, options?: any): AxiosPromise<Guardian> {
            return localVarFp.uploadGuardianPicture2(userId, upfile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 学校ロゴをアップロードする
         * @param {any} upfile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadSchoolLogo(upfile: any, options?: any): AxiosPromise<School> {
            return localVarFp.uploadSchoolLogo(upfile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 児童生徒の写真をアップロードする
         * @param {string} userId 
         * @param {any} upfile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadStudentPicture(userId: string, upfile: any, options?: any): AxiosPromise<Student> {
            return localVarFp.uploadStudentPicture(userId, upfile, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @summary 保護者を一括更新する
     * @param {Array<GuardianWriteWithUserId>} [guardianWriteWithUserId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public batchPatchGuardian(guardianWriteWithUserId?: Array<GuardianWriteWithUserId>, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).batchPatchGuardian(guardianWriteWithUserId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 児童生徒を一括更新する
     * @param {Array<StudentWriteWithUserId>} [studentWriteWithUserId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public batchPatchStudent(studentWriteWithUserId?: Array<StudentWriteWithUserId>, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).batchPatchStudent(studentWriteWithUserId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 教職員を一括更新する
     * @param {Array<TeacherWriteWithUserId>} [teacherWriteWithUserId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public batchPatchTeacher(teacherWriteWithUserId?: Array<TeacherWriteWithUserId>, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).batchPatchTeacher(teacherWriteWithUserId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary クラスを削除する
     * @param {string} classId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteClass(classId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteClass(classId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 保護者を削除する
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteGuardian(userId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteGuardian(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 保護者の写真1を削除する
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteGuardianPicture1(userId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteGuardianPicture1(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 保護者の写真2を削除する
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteGuardianPicture2(userId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteGuardianPicture2(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ロール紐づけを削除する
     * @param {string} key 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteRolebinding(key: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteRolebinding(key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 学校ロゴを削除する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteSchoolLogo(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteSchoolLogo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 児童生徒を削除する
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteStudent(userId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteStudent(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 児童生徒の写真を削除する
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteStudentPicture(userId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteStudentPicture(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 教職員を削除する
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteTeacher(userId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteTeacher(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * `{key}` = `{schoolYear}:{schoolType}:{termNumber}`
     * @summary 学期を削除する
     * @param {string} key 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteTerm(key: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteTerm(key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Firebase権限情報を登録する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public firebaseAuthUser(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).firebaseAuthUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ユーザー情報を返却する。 photoUrlはブラウザキャッシュが行われる(10分)
     * @summary ユーザー基本情報を取得
     * @param {string} userId 
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getBasicUserInfo(userId: string, body?: object, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getBasicUserInfo(userId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary クラスを取得する
     * @param {string} classId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getClass(classId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getClass(classId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * クラスに所属する児童生徒を出席番号順に取得する。
     * @summary クラスの児童生徒一覧を取得する
     * @param {string} classId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getClassStudentsV1(classId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getClassStudentsV1(classId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した クラス情報を取得する。
     * @summary クラスを取得する
     * @param {string} classId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getClassesClassIdV1(classId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getClassesClassIdV1(classId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * クラスを検索し、学校区分、学年、クラス番号順に取得する。
     * @summary クラスの一覧を取得する
     * @param {number} [grade] 学年を指定する。 
     * @param {string} [schoolType] 小中区分を指定する。
     * @param {number} [schoolYear] 年度（省略時は今年度）
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getClassesV1(grade?: number, schoolType?: string, schoolYear?: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getClassesV1(grade, schoolType, schoolYear, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 保護者を取得する
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getGuardian(userId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getGuardian(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定の保護者に紐付く児童生徒を指定して児童生徒の検診情報を取得する。 児童生徒の保護者、教師のみアクセスできる。
     * @summary 保護者に紐付く児童生徒の検診プ情報を取得する
     * @param {string} guardianId 
     * @param {string} childId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getGuardiansGuardianIdChildrenChildIdHealthV1(guardianId: string, childId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getGuardiansGuardianIdChildrenChildIdHealthV1(guardianId, childId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定の保護者に紐付く児童生徒を指定して児童生徒プロファイル情報を取得する。 児童生徒の保護者、教師のみアクセスできる。
     * @summary 保護者に紐付く児童生徒プロファイル情報を取得する
     * @param {string} guardianId 
     * @param {string} childId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getGuardiansGuardianIdChildrenChildIdV1(guardianId: string, childId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getGuardiansGuardianIdChildrenChildIdV1(guardianId, childId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 保護者の保護下にある児童生徒一覧を取得する。 保護者もしくは教師のみアクセスできる。
     * @summary 保護者に紐付く児童生徒一覧を取得する。
     * @param {string} guardianId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getGuardiansGuardianIdChildrenV1(guardianId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getGuardiansGuardianIdChildrenV1(guardianId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 保護者プロファイル情報を取得する
     * @param {string} guardianId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getGuardiansGuardianIdProfile(guardianId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getGuardiansGuardianIdProfile(guardianId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 保護者もしくは教師のみアクセスできる。
     * @summary 保護者情報を取得する
     * @param {string} guardianId 保護者ID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getPaentsGuardianIdV1(guardianId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getPaentsGuardianIdV1(guardianId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 学校を取得する
     * @param {boolean} [noCache] &#x60;true&#x60;の場合、レスポンスをキャッシュさせない。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getSchool(noCache?: boolean, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getSchool(noCache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 現在の学校情報を取得する。
     * @summary 学校情報を取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getSchoolV1(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getSchoolV1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 現在の学期を取得する。
     * @summary Your GET endpoint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getSemesterV1(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getSemesterV1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 教職員プロファイル情報を取得する。 教職員のみアクセスできる。
     * @summary 教職員プロファイル情報を取得する
     * @param {string} staffId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getStaffStaffIdV1(staffId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getStaffStaffIdV1(staffId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 児童生徒を取得する
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getStudent(userId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getStudent(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 児童生徒カスタム項目の定義を取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getStudentCustomColumnDef(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getStudentCustomColumnDef(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した児童生徒IDの児童生徒情報を取得する。 児童生徒本人、児童生徒の保護者、教師のみアクセスできる。
     * @summary 児童生徒を取得する
     * @param {string} studentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getStudentsStudentIdV1(studentId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getStudentsStudentIdV1(studentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 教職員を取得する
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getTeacher(userId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getTeacher(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した教師IDの教師情報を取得する。
     * @summary 教師情報を取得する
     * @param {string} teacherId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getTeachersTeacherIdV1(teacherId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getTeachersTeacherIdV1(teacherId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した日時の学期を取得する。  日時未指定の場合、現在の学期を取得する。
     * @summary 指定した日時の学期を取得する
     * @param {string} schoolType 学校タイプ。&#x60;elementary&#x60;, &#x60;juniorhigh&#x60;のいずれか。
     * @param {string} [at] 日時。例: &#x60;2021-11-25T13:58:24+09:00&#x60;
     * @param {string} [noCache] &#x60;true&#x60;の場合、レスポンスをキャッシュさせない。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getTermByTime(schoolType: string, at?: string, noCache?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getTermByTime(schoolType, at, noCache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ユーザー情報を取得する。
     * @summary Your GET endpoint
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getUserInfo(userId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getUserInfo(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ユーザーについて、以下2点を返す。  * ユーザータイプ。（教師`teacher` / 職員`staff` / 児童生徒`student` / 保護者`guardian` / 該当なし`none`） * ユーザータイプに応じた、権限判定に関わる情報。   * `teacher`の場合、何もなし。   * `staff`の場合、何もなし。   * `student`の場合、所属クラス一覧(※年度毎にクラスは異なるが、過去のものを含めてすべて返却する)。   * `guardian`の場合、自分の子供(`student`)の一覧。さらに、それら`student`が、それぞれ所属クラス一覧を持つ。   * `none`の場合、何もなし。  # ユーザー別制約  * 児童生徒と保護者   * 自分自身のみ取得可能。 * 教師   * なんでも取得可能。
     * @summary Your GET endpoint
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public identity(userId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).identity(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary クラスのリストを取得する
     * @param {number} [schoolYear] 年度。
     * @param {string} [schoolType] 学校タイプ。値は &#x60;elementary&#x60;, &#x60;juniorhigh&#x60;。
     * @param {number} [gradeNumber] 学年（数値）。小学校は&#x60;1&#x60;-&#x60;6&#x60;、中学校は&#x60;1&#x60;-&#x60;3&#x60;。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listClass(schoolYear?: number, schoolType?: string, gradeNumber?: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listClass(schoolYear, schoolType, gradeNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary クラスの児童生徒基本情報一覧を取得する
     * @param {string} classId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listClassStudents(classId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listClassStudents(classId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 保護者のリストを取得する
     * @param {Array<string>} [userId] ユーザーID。複数指定可。
     * @param {string} [q] クエリ文字列。
     * @param {string} [schoolType] 学校タイプ。
     * @param {number} [gradeNumber] 学年数値。&#x60;schoolType&#x60;を指定した場合のみ指定可能。
     * @param {Array<string>} [classId] クラスID。複数指定可。
     * @param {boolean} [noClass] &#x60;true&#x60;なら、クラス未設定の児童生徒を1人以上持つ保護者のみを検索する。
     * @param {string} [inSchoolState] 在学状態。値は &#x60;inSchool&#x60;, &#x60;graduated&#x60;, &#x60;transferred&#x60;, &#x60;graduatedOrTransferred&#x60;。指定する場合、&#x60;inSchoolStateOn&#x60;の指定が必須。
     * @param {string} [inSchoolStateOn] &#x60;inSchoolState&#x60;を計算する日付。この日付の在学状態で検索する。
     * @param {number} [classSchoolYear] 検索条件の&#x60;schoolType&#x60;、&#x60;gradeNumber&#x60;を指定した場合は必須で、この年度のクラスを使用して検索を行う。
     * @param {string} [sortBy] ソート用項目。値は &#x60;nameKana&#x60;, &#x60;createdAt&#x60;, &#x60;updatedAt&#x60;。
     * @param {string} [sortOrder] 並び順。値は &#x60;asc&#x60;, &#x60;desc&#x60;。
     * @param {number} [limit] 検索上限件数。
     * @param {number} [offset] 検索オフセット。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listGuardian(userId?: Array<string>, q?: string, schoolType?: string, gradeNumber?: number, classId?: Array<string>, noClass?: boolean, inSchoolState?: string, inSchoolStateOn?: string, classSchoolYear?: number, sortBy?: string, sortOrder?: string, limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listGuardian(userId, q, schoolType, gradeNumber, classId, noClass, inSchoolState, inSchoolStateOn, classSchoolYear, sortBy, sortOrder, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * `list-guardian` と同じ働きをするが、以下が異なる。  - postリクエストでbodyを受け取る方式。 - 受け取れるパラメタはuserIdsのみ。  通常のListGuardianはgetリクエストなので、URL長制限に引っかかる場合があり、その回避策として用いるもの。
     * @summary 保護者のリストを取得する
     * @param {InlineObject3} [inlineObject3] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listGuardianByPost(inlineObject3?: InlineObject3, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listGuardianByPost(inlineObject3, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ロール紐づけのリストを取得する
     * @param {string} [userId] ユーザーID。
     * @param {string} [roleId] ロールID。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listRolebinding(userId?: string, roleId?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listRolebinding(userId, roleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 児童生徒のリストを取得する
     * @param {Array<string>} [userId] ユーザーID。複数指定可。
     * @param {string} [q] クエリ文字列。
     * @param {string} [guardianUserId] 保護者ユーザーID。
     * @param {string} [schoolType] 学校タイプ。
     * @param {number} [gradeNumber] 学年数値。&#x60;schoolType&#x60;を指定した場合のみ指定可能。
     * @param {Array<string>} [classId] クラスID。複数指定可。
     * @param {boolean} [noClass] &#x60;true&#x60;なら、クラス未設定の児童生徒のみを検索する。
     * @param {string} [inSchoolState] 在学状態。値は &#x60;inSchool&#x60;, &#x60;graduated&#x60;, &#x60;transferred&#x60;, &#x60;graduatedOrTransferred&#x60;。指定する場合、&#x60;inSchoolStateOn&#x60;の指定が必須。
     * @param {string} [inSchoolStateOn] &#x60;inSchoolState&#x60;を計算する日付。この日付の在学状態で検索する。
     * @param {number} [classSchoolYear] 検索条件の&#x60;schoolType&#x60;、&#x60;gradeNumber&#x60;、または&#x60;sortBy&#x60;に&#x60;gradeClassStudentNumber&#x60;を指定した場合は必須で、この年度のクラスを使用して検索・並び替えを行う。
     * @param {string} [sortBy] ソート用項目。値は &#x60;gradeClassStudentNumber&#x60;, &#x60;nameKana&#x60;, &#x60;createdAt&#x60;, &#x60;updatedAt&#x60;。
     * @param {string} [sortOrder] 並び順。値は &#x60;asc&#x60;, &#x60;desc&#x60;。
     * @param {number} [limit] 検索上限件数。
     * @param {number} [offset] 検索オフセット。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listStudent(userId?: Array<string>, q?: string, guardianUserId?: string, schoolType?: string, gradeNumber?: number, classId?: Array<string>, noClass?: boolean, inSchoolState?: string, inSchoolStateOn?: string, classSchoolYear?: number, sortBy?: string, sortOrder?: string, limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listStudent(userId, q, guardianUserId, schoolType, gradeNumber, classId, noClass, inSchoolState, inSchoolStateOn, classSchoolYear, sortBy, sortOrder, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * `list-student` と同じ働きをするが、以下が異なる。  - postリクエストでbodyを受け取る方式。 - 受け取れるパラメタはuserIdsのみ。  通常のListStudentはgetリクエストなので、URL長制限に引っかかる場合があり、その回避策として用いるもの。
     * @summary 児童生徒のリストを取得する
     * @param {InlineObject1} [inlineObject1] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listStudentByPost(inlineObject1?: InlineObject1, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listStudentByPost(inlineObject1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 教職員のリストを取得する
     * @param {Array<string>} [userId] ユーザーID。複数指定可。
     * @param {string} [q] クエリ文字列。
     * @param {string} [teacherType] 教職員タイプ。値は &#x60;0&#x60;: staff, &#x60;1&#x60;: teacher。
     * @param {number} [classSchoolYear] 検索条件の&#x60;schoolType&#x60;、&#x60;gradeNumber&#x60;を指定した場合は必須で、この年度のクラスを使用して検索・並び替えを行う。
     * @param {string} [schoolType] 学校タイプ。
     * @param {number} [gradeNumber] 学年数値。&#x60;schoolType&#x60;を指定した場合のみ指定可能。
     * @param {Array<string>} [classId] クラスID。複数指定可。
     * @param {boolean} [noClass] &#x60;true&#x60;なら、クラス未設定の教職員のみを検索する。
     * @param {string} [sortBy] ソート用項目。値は &#x60;nameKana&#x60;, &#x60;createdAt&#x60;, &#x60;updatedAt&#x60;。 
     * @param {string} [sortOrder] 並び順。値は &#x60;asc&#x60;: 昇順, &#x60;desc&#x60;: 降順。
     * @param {number} [limit] 検索上限件数。
     * @param {number} [offset] 検索オフセット。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listTeacher(userId?: Array<string>, q?: string, teacherType?: string, classSchoolYear?: number, schoolType?: string, gradeNumber?: number, classId?: Array<string>, noClass?: boolean, sortBy?: string, sortOrder?: string, limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listTeacher(userId, q, teacherType, classSchoolYear, schoolType, gradeNumber, classId, noClass, sortBy, sortOrder, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * `list-teacher` と同じ働きをするが、以下が異なる。  - postリクエストでbodyを受け取る方式。 - 受け取れるパラメタはuserIdsのみ。  通常のListTeacherはgetリクエストなので、URL長制限に引っかかる場合があり、その回避策として用いるもの。
     * @summary 教職員のリストを取得する
     * @param {InlineObject} [inlineObject] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listTeacherByPost(inlineObject?: InlineObject, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listTeacherByPost(inlineObject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 学期のリストを取得する
     * @param {string} [schoolType] 学校タイプ。&#x60;elementary&#x60;, &#x60;juniorhigh&#x60;。
     * @param {number} [schoolYear] 学年。
     * @param {number} [termNumber] 学期番号。
     * @param {boolean} [noCache] &#x60;true&#x60;の場合、レスポンスをキャッシュさせない。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listTerm(schoolType?: string, schoolYear?: number, termNumber?: number, noCache?: boolean, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listTerm(schoolType, schoolYear, termNumber, noCache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ユーザーのアクセストークンをアプリが取得済かを判定する。  * 取得済なら、何もしない。 * 未取得なら、OAuthフローを開始する。
     * @summary ユーザーのアクセストークンをアプリが取得済かを判定する
     * @param {AuthorizeRequest} [authorizeRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public oauthAuthorize(authorizeRequest?: AuthorizeRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).oauthAuthorize(authorizeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary クラスを更新する
     * @param {string} classId 
     * @param {ClassWrite} [classWrite] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public patchClass(classId: string, classWrite?: ClassWrite, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).patchClass(classId, classWrite, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 保護者を更新する
     * @param {string} userId 
     * @param {GuardianWrite} [guardianWrite] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public patchGuardian(userId: string, guardianWrite?: GuardianWrite, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).patchGuardian(userId, guardianWrite, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} guardianId 
     * @param {string} childId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public patchGuardiansGuardianIdChildrenChildId(guardianId: string, childId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).patchGuardiansGuardianIdChildrenChildId(guardianId, childId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 対象の児童生徒の検診情報を更新する
     * @param {string} guardianId 
     * @param {string} childId 
     * @param {ChildHealthV1} [childHealthV1] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public patchGuardiansGuardianIdChildrenChildIdHealth(guardianId: string, childId: string, childHealthV1?: ChildHealthV1, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).patchGuardiansGuardianIdChildrenChildIdHealth(guardianId, childId, childHealthV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 保護者プロファイル情報を更新する。
     * @param {string} guardianId 
     * @param {GuardianProfileV1} [guardianProfileV1] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public patchGuardiansGuardianIdProfile(guardianId: string, guardianProfileV1?: GuardianProfileV1, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).patchGuardiansGuardianIdProfile(guardianId, guardianProfileV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 学校を更新する
     * @param {SchoolWrite} [schoolWrite] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public patchSchool(schoolWrite?: SchoolWrite, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).patchSchool(schoolWrite, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} staffId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public patchStaffStaffId(staffId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).patchStaffStaffId(staffId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 児童生徒を更新する
     * @param {string} userId 
     * @param {StudentWrite} [studentWrite] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public patchStudent(userId: string, studentWrite?: StudentWrite, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).patchStudent(userId, studentWrite, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 児童生徒カスタム項目の定義を更新する
     * @param {StudentCustomColumnDefSetWrite} [studentCustomColumnDefSetWrite] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public patchStudentCustomColumnDef(studentCustomColumnDefSetWrite?: StudentCustomColumnDefSetWrite, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).patchStudentCustomColumnDef(studentCustomColumnDefSetWrite, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 教職員を更新する
     * @param {string} userId 
     * @param {TeacherWrite} [teacherWrite] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public patchTeacher(userId: string, teacherWrite?: TeacherWrite, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).patchTeacher(userId, teacherWrite, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary クラスを作成する
     * @param {ClassWrite} [classWrite] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postClass(classWrite?: ClassWrite, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).postClass(classWrite, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ロール紐づけを作成・更新する。  現仕様では、教職員以外にロールを紐づけても効果が無い。(紐づけ自体は可能)  `{key}` = `{userId}:{roleId}`
     * @summary ロール紐づけを作成・更新する
     * @param {string} key 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public putRolebinding(key: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).putRolebinding(key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * `{key}` = `{schoolYear}:{schoolType}:{termNumber}`
     * @summary 学期を作成・更新する
     * @param {string} key 
     * @param {TermWrite} [termWrite] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public putTerm(key: string, termWrite?: TermWrite, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).putTerm(key, termWrite, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 普段は行う必要なし。 移行時や手修正後などに使う。
     * @summary データ整合性を回復する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public restoreDataConsistency(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).restoreDataConsistency(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary GWSとの同期を行う
     * @param {SyncWithGWSRequest} [syncWithGWSRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public syncWithGWS(syncWithGWSRequest?: SyncWithGWSRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).syncWithGWS(syncWithGWSRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 保護者の写真1をアップロードする
     * @param {string} userId 
     * @param {any} upfile 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public uploadGuardianPicture1(userId: string, upfile: any, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).uploadGuardianPicture1(userId, upfile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 保護者の写真2をアップロードする
     * @param {string} userId 
     * @param {any} upfile 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public uploadGuardianPicture2(userId: string, upfile: any, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).uploadGuardianPicture2(userId, upfile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 学校ロゴをアップロードする
     * @param {any} upfile 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public uploadSchoolLogo(upfile: any, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).uploadSchoolLogo(upfile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 児童生徒の写真をアップロードする
     * @param {string} userId 
     * @param {any} upfile 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public uploadStudentPicture(userId: string, upfile: any, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).uploadStudentPicture(userId, upfile, options).then((request) => request(this.axios, this.basePath));
    }
}


