import { vModelText as _vModelText, withKeys as _withKeys, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9d9decae"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["placeholder", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["base-search-box-container", { disabled: !_ctx.enabled, focused: _ctx.focused }]),
    style: _normalizeStyle(_ctx.styles)
  }, [
    _withDirectives(_createElementVNode("input", {
      type: "text",
      class: "input",
      placeholder: _ctx.placeholder,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.text) = $event)),
      disabled: !_ctx.enabled,
      onFocus: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onFocusInternal && _ctx.onFocusInternal(...args))),
      onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onBlurInternal && _ctx.onBlurInternal(...args))),
      onKeypress: _cache[3] || (_cache[3] = _withKeys(
//@ts-ignore
(...args) => (_ctx.onConfirmInternal && _ctx.onConfirmInternal(...args)), ["enter"]))
    }, null, 40, _hoisted_1), [
      [_vModelText, _ctx.text]
    ]),
    (_ctx.hasAnyText)
      ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
          key: 0,
          class: "icon icon--reset",
          icon: ['fas', 'times'],
          onClick: _ctx.resetText
        }, null, 8, ["onClick"]))
      : (_openBlock(), _createBlock(_component_font_awesome_icon, {
          key: 1,
          class: "icon icon--search",
          icon: ['fas', 'search']
        }))
  ], 6))
}