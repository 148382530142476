
import { defineComponent, PropType } from "vue";
import BaseButton from "@/components/buttons/BaseButton/BaseButton.vue";
import { ColorLabel } from "@/ts/objects/value/color-label";

export default defineComponent({
  name: "BaseLargeButton",
  components: { BaseButton },
  props: {
    color: { type: String as PropType<ColorLabel>, default: "black" },
    backgroundColor: { type: String as PropType<ColorLabel>, default: "white" },
    backgroundColorOnHover: { type: String as PropType<ColorLabel> },

    iconType: { type: String, default: "fas" }, // fas or far
    icon: { type: String, default: null },

    label: { type: String, required: true },

    borderColor: { type: String as PropType<ColorLabel>, default: "gray" },

    width: { type: Number, default: null },

    enabled: { type: Boolean, default: true },
    onClick: { type: Function as PropType<() => void>, required: true },
  },
});
