
import { defineComponent } from "vue";
import StudentSubPageHeading from "@/views/student/_components/StudentSubPageHeading/StudentSubPageHeading.vue";
import { useRouter } from "vue-router";
import SyncWithGWSView from "@/views/_components/SyncWithGWSView/SyncWithGWSView.vue";

export default defineComponent({
  name: "StudentSync",
  components: { SyncWithGWSView, StudentSubPageHeading },
  setup() {
    const router = useRouter();

    const onClickBack = () => router.push("/student");

    return {
      onClickBack,
    };
  },
});
