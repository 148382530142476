import {
  DateValue,
  dateValueToDisplayValue,
  displayValueToDateValueOrError,
} from "@/ts/objects/value/date-value";
import { User } from "@/ts/objects/entity/user";
import {
  StudentClass,
  studentClassFromRespOrError,
} from "@/ts/objects/value/student-class";
import { Sex, sexFromStringOrError } from "@/ts/objects/value/sex";
import { Student as StudentResp, StudentWrite } from "@/ts/api/user-service";
import { dropAllUndefinedFields, hasValue } from "@/ts/utils/common-util";
import {
  BasicUserInfoPartial,
  basicUserInfoPartialFromRespOrError,
} from "@/ts/objects/value/basic-user-info";
import { CustomColumn } from "@/ts/objects/value/custom-column";
import { pick } from "lodash";
import {
  HasLunchValue,
  hasLunchValueFromStringOrError,
} from "@/ts/objects/value/has-lunch-value";

export type Student = { readonly userType: "student" } & User &
  StudentProfile &
  StudentHealth &
  StudentCustom;

export type StudentProfile = {
  readonly classes: readonly StudentClass[];
  readonly guardian: BasicUserInfoPartial | null;

  readonly nameKana: string;
  readonly nameRome: string;
  readonly familyName: string;
  readonly familyNameKana: string;
  readonly givenName: string;
  readonly givenNameKana: string;
  readonly sex: Sex;
  readonly nickname: string;
  readonly nicknameKana: string;
  readonly birthday: DateValue | null;
  readonly applicationDate: DateValue | null;
  readonly kindergartenEntranceDate: DateValue | null;
  readonly elementarySchoolEntranceDate: DateValue | null;
  readonly juniorHighSchoolEntranceDate: DateValue | null;
  readonly transferDate: DateValue | null;
  readonly graduationDate: DateValue | null;
  readonly kindergarten: string;
  readonly previousSchool: string;
  readonly zipcode: string;
  readonly address: string;
  readonly email: string;
  readonly mobilePhone: string;
  readonly pictureGcsUrl: string | null;
  readonly country: string;
  readonly religion: string;
  readonly commutingBy: string;
  readonly bus: string;
  readonly nearestStation: string;
  readonly hasLunch: HasLunchValue;
  readonly activityMonday: string;
  readonly activityTuesday: string;
  readonly activityWednesday: string;
  readonly activityThursday: string;
  readonly activityFriday: string;
};

export type StudentHealth = {
  readonly bloodType: string;
  readonly normalBodyTemperature: string;
  readonly inoculation: string;
  readonly medicalHistory: string;
  readonly homeDoctor: string;
  readonly foodAllergy: string;
  readonly anaphylaxis: string;
  readonly otherAllergy: string;
  readonly asthma: string;
};

export type StudentCustom = {
  readonly custom01: CustomColumn;
  readonly custom02: CustomColumn;
  readonly custom03: CustomColumn;
  readonly custom04: CustomColumn;
  readonly custom05: CustomColumn;
  readonly custom06: CustomColumn;
  readonly custom07: CustomColumn;
  readonly custom08: CustomColumn;
  readonly custom09: CustomColumn;
  readonly custom10: CustomColumn;
  readonly custom11: CustomColumn;
  readonly custom12: CustomColumn;
  readonly custom13: CustomColumn;
  readonly custom14: CustomColumn;
  readonly custom15: CustomColumn;
  readonly custom16: CustomColumn;
  readonly custom17: CustomColumn;
  readonly custom18: CustomColumn;
  readonly custom19: CustomColumn;
  readonly custom20: CustomColumn;
  readonly custom21: CustomColumn;
  readonly custom22: CustomColumn;
  readonly custom23: CustomColumn;
  readonly custom24: CustomColumn;
  readonly custom25: CustomColumn;
  readonly custom26: CustomColumn;
  readonly custom27: CustomColumn;
  readonly custom28: CustomColumn;
  readonly custom29: CustomColumn;
  readonly custom30: CustomColumn;
  readonly custom31: CustomColumn;
  readonly custom32: CustomColumn;
  readonly custom33: CustomColumn;
  readonly custom34: CustomColumn;
  readonly custom35: CustomColumn;
  readonly custom36: CustomColumn;
  readonly custom37: CustomColumn;
  readonly custom38: CustomColumn;
  readonly custom39: CustomColumn;
  readonly custom40: CustomColumn;
};

export function studentFromRespOrError(r: StudentResp): Student {
  return {
    userType: "student",

    userId: r.userId,
    googleMail: r.googleMail,
    photoUrl: r.photoUrl,
    name: r.name,

    classes: r.classes.map(studentClassFromRespOrError),
    guardian: hasValue(r.guardian)
      ? basicUserInfoPartialFromRespOrError(r.guardian)
      : null,

    nameKana: r.nameKana,
    nameRome: r.nameRome,
    familyName: r.familyName,
    familyNameKana: r.familyNameKana,
    givenName: r.givenName,
    givenNameKana: r.givenNameKana,
    sex: sexFromStringOrError(r.sex),
    nickname: r.nickname,
    nicknameKana: r.nicknameKana,
    birthday: hasValue(r.birthday)
      ? displayValueToDateValueOrError(r.birthday)
      : null,
    applicationDate: hasValue(r.applicationDate)
      ? displayValueToDateValueOrError(r.applicationDate)
      : null,
    kindergartenEntranceDate: hasValue(r.kindergartenEntranceDate)
      ? displayValueToDateValueOrError(r.kindergartenEntranceDate)
      : null,
    elementarySchoolEntranceDate: hasValue(r.elementarySchoolEntranceDate)
      ? displayValueToDateValueOrError(r.elementarySchoolEntranceDate)
      : null,
    juniorHighSchoolEntranceDate: hasValue(r.juniorHighSchoolEntranceDate)
      ? displayValueToDateValueOrError(r.juniorHighSchoolEntranceDate)
      : null,
    transferDate: hasValue(r.transferDate)
      ? displayValueToDateValueOrError(r.transferDate)
      : null,
    graduationDate: hasValue(r.graduationDate)
      ? displayValueToDateValueOrError(r.graduationDate)
      : null,
    kindergarten: r.kindergarten,
    previousSchool: r.previousSchool,
    zipcode: r.zipcode,
    address: r.address,
    email: r.email,
    mobilePhone: r.mobilePhone,
    pictureGcsUrl: r.pictureGcsUrl ?? null,
    country: r.country,
    religion: r.religion,
    commutingBy: r.commutingBy,
    bus: r.bus,
    nearestStation: r.nearestStation,
    hasLunch: hasLunchValueFromStringOrError(r.hasLunch),
    activityMonday: r.activityMonday,
    activityTuesday: r.activityTuesday,
    activityWednesday: r.activityWednesday,
    activityThursday: r.activityThursday,
    activityFriday: r.activityFriday,

    bloodType: r.bloodType,
    normalBodyTemperature: r.normalBodyTemperature,
    inoculation: r.inoculation,
    medicalHistory: r.medicalHistory,
    homeDoctor: r.homeDoctor,
    foodAllergy: r.foodAllergy,
    anaphylaxis: r.anaphylaxis,
    otherAllergy: r.otherAllergy,
    asthma: r.asthma,

    custom01: r.custom01,
    custom02: r.custom02,
    custom03: r.custom03,
    custom04: r.custom04,
    custom05: r.custom05,
    custom06: r.custom06,
    custom07: r.custom07,
    custom08: r.custom08,
    custom09: r.custom09,
    custom10: r.custom10,
    custom11: r.custom11,
    custom12: r.custom12,
    custom13: r.custom13,
    custom14: r.custom14,
    custom15: r.custom15,
    custom16: r.custom16,
    custom17: r.custom17,
    custom18: r.custom18,
    custom19: r.custom19,
    custom20: r.custom20,
    custom21: r.custom21,
    custom22: r.custom22,
    custom23: r.custom23,
    custom24: r.custom24,
    custom25: r.custom25,
    custom26: r.custom26,
    custom27: r.custom27,
    custom28: r.custom28,
    custom29: r.custom29,
    custom30: r.custom30,
    custom31: r.custom31,
    custom32: r.custom32,
    custom33: r.custom33,
    custom34: r.custom34,
    custom35: r.custom35,
    custom36: r.custom36,
    custom37: r.custom37,
    custom38: r.custom38,
    custom39: r.custom39,
    custom40: r.custom40,
  };
}

export function studentToReq(s: Partial<Student>): StudentWrite {
  const transformGuardianValue = (
    v: BasicUserInfoPartial | null | undefined,
  ): string | undefined => {
    if (v === undefined) return undefined; // 未指定の場合は、未指定とするためにundefinedとする。
    if (v === null) return ""; // nullに更新するには、空文字列を指定する。
    return v.userId; // 普通の値に更新するには、普通にuserIdを指定する。
  };
  const transformDateValue = (
    v: DateValue | null | undefined,
  ): string | undefined => {
    if (v === undefined) return undefined; // 未指定の場合は、未指定とするためにundefinedとする。
    if (v === null) return ""; // nullに更新するには、空文字列を指定する。
    return dateValueToDisplayValue(v); // 普通の値に更新するには、displayValueに変換する。
  };

  const req = {
    name: s.name,

    guardianUserId: transformGuardianValue(s.guardian),
    nameKana: s.nameKana,
    nameRome: s.nameRome,
    familyName: s.familyName,
    familyNameKana: s.familyNameKana,
    givenName: s.givenName,
    givenNameKana: s.givenNameKana,
    sex: s.sex, // 現状、sexはnullに更新はできない。(otherがデフォルト値。)
    nickname: s.nickname,
    nicknameKana: s.nicknameKana,
    birthday: transformDateValue(s.birthday),
    applicationDate: transformDateValue(s.applicationDate),
    kindergartenEntranceDate: transformDateValue(s.kindergartenEntranceDate),
    elementarySchoolEntranceDate: transformDateValue(
      s.elementarySchoolEntranceDate,
    ),
    juniorHighSchoolEntranceDate: transformDateValue(
      s.juniorHighSchoolEntranceDate,
    ),
    transferDate: transformDateValue(s.transferDate),
    graduationDate: transformDateValue(s.graduationDate),
    kindergarten: s.kindergarten,
    previousSchool: s.previousSchool,
    zipcode: s.zipcode,
    address: s.address,
    email: s.email,
    mobilePhone: s.mobilePhone,
    // pictureGcsUrlは、直接書き込み不可。
    country: s.country,
    religion: s.religion,
    commutingBy: s.commutingBy,
    bus: s.bus,
    nearestStation: s.nearestStation,
    hasLunch: s.hasLunch, // // 現状、hasLunchはnullに更新はできない。(falseがデフォルト値。)
    activityMonday: s.activityMonday,
    activityTuesday: s.activityTuesday,
    activityWednesday: s.activityWednesday,
    activityThursday: s.activityThursday,
    activityFriday: s.activityFriday,

    bloodType: s.bloodType,
    normalBodyTemperature: s.normalBodyTemperature,
    inoculation: s.inoculation,
    medicalHistory: s.medicalHistory,
    homeDoctor: s.homeDoctor,
    foodAllergy: s.foodAllergy,
    anaphylaxis: s.anaphylaxis,
    otherAllergy: s.otherAllergy,
    asthma: s.asthma,

    custom01: s.custom01?.value,
    custom02: s.custom02?.value,
    custom03: s.custom03?.value,
    custom04: s.custom04?.value,
    custom05: s.custom05?.value,
    custom06: s.custom06?.value,
    custom07: s.custom07?.value,
    custom08: s.custom08?.value,
    custom09: s.custom09?.value,
    custom10: s.custom10?.value,
    custom11: s.custom11?.value,
    custom12: s.custom12?.value,
    custom13: s.custom13?.value,
    custom14: s.custom14?.value,
    custom15: s.custom15?.value,
    custom16: s.custom16?.value,
    custom17: s.custom17?.value,
    custom18: s.custom18?.value,
    custom19: s.custom19?.value,
    custom20: s.custom20?.value,
    custom21: s.custom21?.value,
    custom22: s.custom22?.value,
    custom23: s.custom23?.value,
    custom24: s.custom24?.value,
    custom25: s.custom25?.value,
    custom26: s.custom26?.value,
    custom27: s.custom27?.value,
    custom28: s.custom28?.value,
    custom29: s.custom29?.value,
    custom30: s.custom30?.value,
    custom31: s.custom31?.value,
    custom32: s.custom32?.value,
    custom33: s.custom33?.value,
    custom34: s.custom34?.value,
    custom35: s.custom35?.value,
    custom36: s.custom36?.value,
    custom37: s.custom37?.value,
    custom38: s.custom38?.value,
    custom39: s.custom39?.value,
    custom40: s.custom40?.value,

    classes: s.classes?.map((c) => ({
      classId: c.classId,
      studentNumber: c.studentNumber ?? undefined,
    })),
  };
  return dropAllUndefinedFields(req);
}

export type UpdatingStudent = {
  readonly student: Student;
  readonly updatingFields: (keyof Student)[];
};

export function updatingStudentToPartial(
  s: UpdatingStudent,
): Partial<Student> & { readonly userId: string } {
  const partialStudent: Partial<Student> = pick(s.student, s.updatingFields);
  return { ...partialStudent, userId: s.student.userId };
}

export function studentToBasicUserInfo(s: Student): User {
  return {
    userId: s.userId,
    googleMail: s.googleMail,
    userType: "student",
    name: s.name,
    photoUrl: s.photoUrl,
  };
}
