import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-49cea74d"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["name", "value", "checked"]
const _hoisted_2 = { class: "label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", {
    class: "base-radio-button-container",
    style: _normalizeStyle(_ctx.styles)
  }, [
    _createElementVNode("input", {
      type: "radio",
      class: "radio",
      name: _ctx.radioGroupName,
      value: _ctx.radioValue,
      checked: _ctx.radioValue === _ctx.selectedRadioValue,
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onSelectInternal && _ctx.onSelectInternal(...args)))
    }, null, 40, _hoisted_1),
    _createElementVNode("span", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ], 4))
}