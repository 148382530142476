import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bb2bbe3c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 9 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserListTableCellNormal = _resolveComponent("UserListTableCellNormal")!
  const _component_UserListTableCellDate = _resolveComponent("UserListTableCellDate")!
  const _component_UserListTableCellImage = _resolveComponent("UserListTableCellImage")!
  const _component_UserListTableCellTeacherClasses = _resolveComponent("UserListTableCellTeacherClasses")!
  const _component_UserListTableCellUser = _resolveComponent("UserListTableCellUser")!
  const _component_UserListTableCellUsers = _resolveComponent("UserListTableCellUsers")!

  return (_openBlock(), _createElementBlock("div", {
    class: "user-list-table-cell-container",
    style: _normalizeStyle(_ctx.styles)
  }, [
    (_ctx.userColumn.type.category === 'string')
      ? (_openBlock(), _createBlock(_component_UserListTableCellNormal, {
          key: 0,
          value: _ctx.userColumn.value
        }, null, 8, ["value"]))
      : (_ctx.userColumn.type.category === 'multi line string')
        ? (_openBlock(), _createBlock(_component_UserListTableCellNormal, {
            key: 1,
            value: _ctx.userColumn.value
          }, null, 8, ["value"]))
        : (_ctx.userColumn.type.category === 'number')
          ? (_openBlock(), _createBlock(_component_UserListTableCellNormal, {
              key: 2,
              value: _ctx.userColumn.value
            }, null, 8, ["value"]))
          : (_ctx.userColumn.type.category === 'date')
            ? (_openBlock(), _createBlock(_component_UserListTableCellDate, {
                key: 3,
                date: _ctx.userColumn.value
              }, null, 8, ["date"]))
            : (_ctx.userColumn.type.category === 'image')
              ? (_openBlock(), _createBlock(_component_UserListTableCellImage, {
                  key: 4,
                  "gcs-url": _ctx.userColumn.value
                }, null, 8, ["gcs-url"]))
              : (_ctx.userColumn.type.category === 'teacher-classes')
                ? (_openBlock(), _createBlock(_component_UserListTableCellTeacherClasses, {
                    key: 5,
                    "teacher-classes": _ctx.userColumn.value,
                    "is-link": _ctx.linkEnabled
                  }, null, 8, ["teacher-classes", "is-link"]))
                : (_ctx.userColumn.type.category === 'enum')
                  ? (_openBlock(), _createBlock(_component_UserListTableCellNormal, {
                      key: 6,
                      value: _ctx.userColumn.value
                    }, null, 8, ["value"]))
                  : (_ctx.userColumn.type.category === 'user')
                    ? (_openBlock(), _createBlock(_component_UserListTableCellUser, {
                        key: 7,
                        user: _ctx.userColumn.value,
                        "is-link": _ctx.linkEnabled
                      }, null, 8, ["user", "is-link"]))
                    : (_ctx.userColumn.type.category === 'users')
                      ? (_openBlock(), _createBlock(_component_UserListTableCellUsers, {
                          key: 8,
                          users: _ctx.userColumn.value,
                          "is-link": _ctx.linkEnabled
                        }, null, 8, ["users", "is-link"]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_1, "Unsupported type."))
  ], 4))
}