
import { defineComponent, PropType, ref } from "vue";
import PopupConfirmButton from "@/components/buttons/PopupConfirmButton/PopupConfirmButton.vue";
import UserSearchBox from "@/components/textboxes/UserSearchBox/UserSearchBox.vue";
import { isNullish } from "@/ts/utils/common-util";
import { UserType } from "@/ts/objects/value/user-type";
import log from "loglevel";
import { UserService } from "@/ts/services/user-service";
import { DateValue } from "@/ts/objects/value/date-value";
import { studentSearchConditionDefault } from "@/ts/objects/search-condition/student-search-condition";
import { studentToBasicUserInfo } from "@/ts/objects/entity/student";
import { guardianSearchConditionDefault } from "@/ts/objects/search-condition/guardian-search-condition";
import { guardianToBasicUserInfo } from "@/ts/objects/entity/guardian";
import { useAppStore } from "@/store/app-store";
import { useUserService } from "@/composables/provide-user-service";
import { User } from "@/ts/objects/entity/user";
import { teacherSearchConditionDefault } from "@/ts/objects/search-condition/teacher-search-condition";
import { teacherToBasicUserInfo } from "@/ts/objects/entity/teacher";
import { getToday } from "@/ts/utils/app-util";

export default defineComponent({
  name: "UserSelectPopup",
  components: { UserSearchBox, PopupConfirmButton },
  props: {
    text: { type: String, default: "ユーザーを選択してください。" },
    searchBoxPlaceholder: {
      type: String,
      default: "名前・Google Mail・ユーザーIDで前方一致検索",
    },
    searchBoxPlaceholderFontSize: { type: Number, default: 12 },

    width: { type: Number, default: 380 },

    userType: { type: String as PropType<UserType>, required: true },
    onConfirm: {
      type: Function as PropType<(user: User) => void>,
      required: true,
    },
  },
  setup(props) {
    const appStore = useAppStore();
    const userService = useUserService();

    const maxSearchResults = 8;

    const selectedUser = ref<User | null>(null);
    const searchUsers = getSearchUsers(
      props.userType,
      userService,
      appStore.currentSchoolYear,
      getToday(),
      maxSearchResults,
    );
    return {
      maxSearchResults,
      selectedUser,
      searchUsers,

      onSelectUser: (user: User) => (selectedUser.value = user),
      onUnselectUser: () => (selectedUser.value = null),
      onConfirmInternal: () => {
        const user = selectedUser.value;
        log.debug(`onConfirmInternal: user=${JSON.stringify(user)}`);
        if (isNullish(user)) return;
        if (user.userType !== props.userType) {
          log.error(`UserSelectPopup: invalid userType: ${user.userType}`);
          return;
        }
        props.onConfirm(user);
      },

      searchBoxWidth: props.width - 20,
      styles: {
        "--width": `${props.width}px`,
      },
    };
  },
});

function getSearchUsers(
  userType: UserType,
  userService: UserService,
  schoolYear: number,
  date: DateValue,
  maxSearchResults: number,
): (searchText: string) => Promise<User[]> {
  switch (userType) {
    case "teacher":
      return async (queryText: string) => {
        const result = await userService.listTeachersPaged(
          {
            ...teacherSearchConditionDefault(schoolYear),
            queryText,
            numResults: { n: maxSearchResults },
          },
          1,
        );
        if (!result.ok) return [];
        return result.data.data.map(teacherToBasicUserInfo);
      };
    case "student":
      return async (queryText: string) => {
        const result = await userService.listStudentsPaged(
          {
            ...studentSearchConditionDefault(schoolYear, date),
            queryText,
            numResults: { n: maxSearchResults },
          },
          1,
        );
        if (!result.ok) return [];
        return result.data.data.map(studentToBasicUserInfo);
      };
    case "guardian":
      return async (queryText: string) => {
        const result = await userService.listGuardiansPaged(
          {
            ...guardianSearchConditionDefault(schoolYear, date),
            queryText,
            numResults: { n: maxSearchResults },
          },
          1,
        );
        if (!result.ok) return [];
        return result.data.data.map(guardianToBasicUserInfo);
      };
  }
}
