import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-50ce38de"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserListTableHeader = _resolveComponent("UserListTableHeader")!
  const _component_TeacherListTableRow = _resolveComponent("TeacherListTableRow")!

  return (_openBlock(), _createElementBlock("div", {
    class: "teacher-list-table-container",
    style: _normalizeStyle(_ctx.styles)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_UserListTableHeader, {
        class: "table-header",
        "horizontal-margin": _ctx.horizontalMargin,
        "icon-cell-width": _ctx.iconCellWidth,
        "name-cell-width": _ctx.nameCellWidth,
        height: 40,
        "all-columns": _ctx.allColumns,
        "column-visibility": _ctx.flattenedColumnVisibility
      }, null, 8, ["horizontal-margin", "icon-cell-width", "name-cell-width", "all-columns", "column-visibility"]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.teachers, (teacher, idx) => {
        return (_openBlock(), _createBlock(_component_TeacherListTableRow, {
          key: teacher.userId,
          class: "table-row",
          "data-testid": `row-${teacher.userId}`,
          "horizontal-margin": _ctx.horizontalMargin,
          "icon-cell-width": _ctx.iconCellWidth,
          "name-cell-width": _ctx.nameCellWidth,
          "min-height": 32,
          "max-height": 80,
          "style-type": idx % 2,
          "school-year": _ctx.schoolYear,
          teacher: teacher,
          "column-visibility": _ctx.flattenedColumnVisibility,
          "user-id-to-edit-path": _ctx.userIdToEditPath,
          "link-enabled": _ctx.linkEnabled
        }, null, 8, ["data-testid", "horizontal-margin", "icon-cell-width", "name-cell-width", "style-type", "school-year", "teacher", "column-visibility", "user-id-to-edit-path", "link-enabled"]))
      }), 128))
    ])
  ], 4))
}