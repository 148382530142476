import {
  faArrowLeft,
  faArrowRight,
  faBars,
  faBook,
  faCalendarAlt,
  faChalkboardTeacher,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faChild,
  faCloudUploadAlt,
  faCog,
  faDownload,
  faEdit,
  faEllipsisV,
  faExternalLinkAlt,
  faFileDownload,
  faFileUpload,
  faPlus,
  faSave,
  faSchool,
  faSearch,
  faSync,
  faTimes,
  faTrash,
  faUpload,
  faUserGraduate,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { App } from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faCopy as faCopyReg } from "@fortawesome/free-regular-svg-icons";

// アルファベット順。
library.add(
  faArrowLeft,
  faArrowRight,
  faBars,
  faBook,
  faCalendarAlt,
  faChalkboardTeacher,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faChild,
  faCloudUploadAlt,
  faCog,
  faCopyReg,
  faDownload,
  faEdit,
  faEllipsisV,
  faExternalLinkAlt,
  faFileDownload,
  faFileUpload,
  faPlus,
  faSave,
  faSchool,
  faSearch,
  faSync,
  faTimes,
  faTrash,
  faUpload,
  faUserGraduate,
  faUserTie,
);

export function setupIcons<T>(app: App<T>): App<T> {
  return app.component("font-awesome-icon", FontAwesomeIcon);
}
