
import { computed, defineComponent, PropType } from "vue";
import CurriculumEditTableRow from "@/views/curriculum/CurriculumEdit/CurriculumEditTable/CurriculumEditTableRow/CurriculumEditTableRow.vue";
import { gradeNumbersOfSchoolType } from "@/ts/objects/value/grade-number";
import {
  CurriculumEditRowData,
  CurriculumEditTableData,
} from "@/views/curriculum/CurriculumEdit/curriculum-edit-data";
import { CheckFailed } from "@/ts/app/error/check-error";
import { isNullish } from "@/ts/utils/common-util";

export default defineComponent({
  name: "CurriculumEditTable",
  components: { CurriculumEditTableRow },
  props: {
    /**
     * データ。
     * 選択中の年度、学校タイプのもののみが含まれる前提。ここでチェックはしない。
     */
    data: { type: Object as PropType<CurriculumEditTableData>, required: true },

    editing: { type: Boolean, required: true },

    onUpdate: {
      type: Function as PropType<(data: CurriculumEditTableData) => void>,
      required: false,
    },
  },
  setup(props) {
    const rows = computed<CurriculumEditRowData<1 | 2 | 3 | 4 | 5 | 6>[]>(
      () => {
        const _data = props.data;
        switch (_data.schoolType) {
          case "elementary": {
            return gradeNumbersOfSchoolType["elementary"].map(
              (gn) => _data[`row${gn}`],
            );
          }
          case "juniorhigh": {
            return gradeNumbersOfSchoolType["juniorhigh"].map(
              (gn) => _data[`row${gn}`],
            );
          }
          default:
            throw new CheckFailed();
        }
      },
    );

    const onUpdateRow = (
      rowData: CurriculumEditRowData<1 | 2 | 3 | 4 | 5 | 6>,
    ) => {
      if (!props.editing || isNullish(props.onUpdate)) return;

      props.onUpdate({
        ...props.data,
        [`row${rowData.gradeNumber}`]: rowData,
      });
    };

    return {
      rows,

      onUpdateRow,
    };
  },
});
