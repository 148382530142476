
import { computed, defineComponent, PropType } from "vue";
import { ColorLabel, labelToColor } from "@/ts/objects/value/color-label";
import { hasValue } from "@/ts/utils/common-util";

export default defineComponent({
  name: "BaseCheckbox",
  props: {
    label: { type: String, required: true },

    checked: { type: Boolean, required: true },
    onInput: {
      type: Function as PropType<(checked: boolean) => void>,
      required: true,
    },

    enabled: { type: Boolean, required: false, default: true },

    color: {
      type: String as PropType<ColorLabel>,
      required: false,
      default: "black",
    },
    /**
     * 未チェック時の色。
     * 未設定の場合、colorと同じ。
     */
    colorOnUnchecked: { type: String as PropType<ColorLabel>, required: false },
    fontSize: { type: Number, required: false, default: 12 },
    /**
     * trueなら、未チェック時、打消し線を表示。
     */
    strikethroughOnUnchecked: {
      type: Boolean,
      required: false,
      default: false,
    },

    checkColor: {
      type: String as PropType<ColorLabel>,
      required: false,
      default: "cobalt",
    },
    boxColor: {
      type: String as PropType<ColorLabel>,
      required: false,
      default: "light-black",
    },
    checkSize: { type: Number, required: false, default: 12 },

    backgroundColorOnHover: {
      type: String as PropType<ColorLabel>,
      required: false,
      default: "gray-transparent",
    },

    paddingTop: { type: Number, required: false, default: 4 },
    paddingRight: { type: Number, required: false, default: 7 },
    paddingBottom: { type: Number, required: false, default: 4 },
    paddingLeft: { type: Number, required: false, default: 6 },

    borderRadius: { type: Number, default: 4 },
  },
  setup(props) {
    const colorOnUnchecked = computed(() =>
      hasValue(props.colorOnUnchecked) ? props.colorOnUnchecked : props.color,
    );

    const onInputInternal = () => {
      if (!props.enabled) return;

      props.onInput(!props.checked);
    };

    return {
      onInputInternal,

      styles: computed(() => ({
        "--color": labelToColor(props.color),
        "--color-on-unchecked": labelToColor(colorOnUnchecked.value),
        "--font-size": `${props.fontSize}px`,

        "--check-color": labelToColor(props.checkColor),
        "--box-color": labelToColor(props.boxColor),
        "--check-size": `${props.checkSize}px`,

        "--background-color-on-hover": labelToColor(
          props.backgroundColorOnHover,
        ),

        "--padding-top": `${props.paddingTop}px`,
        "--padding-right": `${props.paddingRight}px`,
        "--padding-bottom": `${props.paddingBottom}px`,
        "--padding-left": `${props.paddingLeft}px`,

        "--border-radius": `${props.borderRadius}px`,
      })),
    };
  },
});
