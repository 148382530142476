import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a970e030"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "value-area" }
const _hoisted_2 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StudentClassText = _resolveComponent("StudentClassText")!
  const _component_StudentClassesSelectPopup = _resolveComponent("StudentClassesSelectPopup")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["editable-item-student-class-container", { boxed: _ctx.editable || !_ctx.hideBoxOnDisable }]),
    style: _normalizeStyle(_ctx.styles)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("button", {
        type: "button",
        class: "items",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openPopup && _ctx.openPopup(...args))),
        disabled: !_ctx.editable
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.value, (studentClass) => {
          return (_openBlock(), _createBlock(_component_StudentClassText, {
            key: studentClass.classId,
            class: "item",
            "student-class": studentClass,
            "is-link": !_ctx.editable && _ctx.isLinkOnDisable
          }, null, 8, ["student-class", "is-link"]))
        }), 128))
      ], 8, _hoisted_2)
    ]),
    (_ctx.active)
      ? (_openBlock(), _createBlock(_component_StudentClassesSelectPopup, {
          key: 0,
          "on-click-outside": _ctx.closePopup,
          "initial-value": _ctx.value,
          text: "クラスを選択してください。",
          "on-confirm": _ctx.onConfirm
        }, null, 8, ["on-click-outside", "initial-value", "on-confirm"]))
      : _createCommentVNode("", true)
  ], 6))
}