export const curriculumSubPageNames = ["edit"] as const;
export type CurriculumSubPageName = typeof curriculumSubPageNames[number];

export function isCurriculumSubPageName(
  value: unknown,
): value is CurriculumSubPageName {
  return (
    typeof value === "string" && curriculumSubPageNames.some((v) => v === value)
  );
}

export function asCurriculumSubPageNameOrNull(
  value: unknown,
): CurriculumSubPageName | null {
  if (isCurriculumSubPageName(value)) return value;
  return null;
}
