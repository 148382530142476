import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseLinkButton = _resolveComponent("BaseLinkButton")!

  return (_openBlock(), _createBlock(_component_BaseLinkButton, {
    label: "Import",
    color: "light-black",
    "background-color": "transparent",
    "background-color-on-hover": "gray-transparent",
    icon: "file-upload",
    "icon-size": 16,
    "icon-padding-bottom": 1,
    "border-width": 0,
    "border-radius": 13,
    width: 90,
    height: 26,
    "padding-horizontal": 12,
    "label-padding-bottom": 0,
    "font-size": 12,
    enabled: _ctx.enabled,
    to: _ctx.to
  }, null, 8, ["enabled", "to"]))
}