import { App, Plugin } from "vue";

// 参考: https://github.com/vuejs/devtools/issues/1244#issuecomment-690915714
// しかし効いているのか不明。
const devtools: Plugin = {
  install(app: App) {
    if (
      process.env.NODE_ENV === "development" &&
      // @ts-ignore
      window.__VUE_DEVTOOLS_GLOBAL_HOOK__
    ) {
      // @ts-ignore
      window.__VUE_DEVTOOLS_GLOBAL_HOOK__.Vue = app;
    }
  },
};

export { devtools };
