import { defineStore } from "pinia";
import {
  defaultGuardianNotesColumnVisibility,
  defaultGuardianProfileColumnVisibility,
  getGuardianColumnVisibility,
  GuardianColumnVisibility,
  GuardianColumnVisibilityAll,
} from "@/ts/app/columns/visibility/guardian-column-visibility";
import { SearchResult } from "@/ts/app/search-result";
import { Guardian } from "@/ts/objects/entity/guardian";
import { Class } from "@/ts/objects/entity/class";
import { GuardianSearchCondition } from "@/ts/objects/search-condition/guardian-search-condition";
import { UserService } from "@/ts/services/user-service";
import { AppError } from "@/ts/app/error/app-error";
import log from "loglevel";
import { RemovableRef, useLocalStorage } from "@vueuse/core";
import { localStorageKeys } from "@/ts/app/constant";
import pick from "lodash/pick";
import {
  guardianListNotesColumnIds,
  guardianListProfileColumnIds,
} from "@/ts/app/columns/user-list/guardian-list-columns";

export const useGuardianStore = defineStore("guardian", {
  state: (): {
    columnVisibility: RemovableRef<GuardianColumnVisibilityAll>;
    guardianSearchResult: SearchResult<Guardian> | null;
    allClassesOnSchoolYear: Class[] | null;
  } => ({
    columnVisibility: useLocalStorage<GuardianColumnVisibilityAll>(
      localStorageKeys.guardianColumnVisibility,
      getGuardianColumnVisibility({
        profileColumnVisibility: {
          state: {
            googleMail: true,
            children: true,
            name2: true,
          },
        },
      }),
    ),
    guardianSearchResult: null,
    allClassesOnSchoolYear: null,
  }),
  actions: {
    updateColumnVisibility(v: GuardianColumnVisibility) {
      // わざわざpickする理由は、仕様変更によりlocalStorage内のcolumnVisibilityが古くなっている可能性があるから。
      // 読み出すときに修正したいがややこしそうなので、せめてここで修正していく。
      switch (v.groupName) {
        case "profile": {
          this.columnVisibility = {
            ...this.columnVisibility,
            profileColumnVisibility: {
              groupName: "profile",
              state: {
                ...defaultGuardianProfileColumnVisibility(false).state,
                ...pick(v.state, guardianListProfileColumnIds),
              },
            },
          };
          break;
        }
        case "notes": {
          this.columnVisibility = {
            ...this.columnVisibility,
            notesColumnVisibility: {
              groupName: "notes",
              state: {
                ...defaultGuardianNotesColumnVisibility(false).state,
                ...pick(v.state, guardianListNotesColumnIds),
              },
            },
          };
          break;
        }
      }
    },
    clearGuardians() {
      this.guardianSearchResult = null;
    },
    async fetchGuardians(
      userService: UserService,
      searchCondition: GuardianSearchCondition,
      page: number,
      onSuccess: () => void = () => {},
      onFail: (e: AppError) => void = () => {},
    ) {
      log.debug(
        `guardianStore: fetchGuardians: searchCondition=${JSON.stringify(
          searchCondition,
        )}, page=${page}`,
      );
      const resp = await userService.listGuardiansPaged(searchCondition, page);
      if (!resp.ok) {
        onFail(resp.error);
        return;
      }

      this.guardianSearchResult = resp.data;
      onSuccess();
    },
    clearClasses() {
      this.allClassesOnSchoolYear = null;
    },
    async fetchClasses(
      userService: UserService,
      schoolYear: number,
      onSuccess: () => void = () => {},
      onFail: (e: AppError) => void = () => {},
    ) {
      const resp = await userService.listClasses(schoolYear);
      if (!resp.ok) {
        onFail(resp.error);
        return;
      }

      this.allClassesOnSchoolYear = resp.data;
      onSuccess();
    },
  },
});
