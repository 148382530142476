
import { computed, defineComponent, PropType } from "vue";
import UserEditTableBody from "@/components/table/edit/UserEditTableBody/UserEditTableBody.vue";
import UserEditTableTabs from "@/components/table/edit/UserEditTableTabs/UserEditTableTabs.vue";
import {
  GuardianNotesColumnGroup,
  guardianNotesColumnGroupName,
} from "@/ts/app/columns/def/guardian/guardian-notes-column";
import {
  GuardianProfileColumnGroup,
  guardianProfileColumnGroupName,
} from "@/ts/app/columns/def/guardian/guardian-profile-column";
import { GuardianEditTableTabValue } from "@/views/guardian/GuardianEdit/guardian-edit-table-tab-value";
import { GuardianColumnId } from "@/ts/app/columns/def/guardian/guardian-column";

export default defineComponent({
  name: "GuardianEditTable",
  components: { UserEditTableTabs, UserEditTableBody },
  props: {
    columnGroups: {
      type: Object as PropType<{
        profileColumnGroup: GuardianProfileColumnGroup;
        notesColumnGroup: GuardianNotesColumnGroup;
      }>,
      required: true,
    },
    selectedTab: {
      type: String as PropType<GuardianEditTableTabValue>,
      required: true,
    },
    editing: { type: Boolean, required: true },

    onInput: {
      type: Function as PropType<
        (columnId: GuardianColumnId, value: any) => void
      >,
      required: true,
    },
    onSelectTab: {
      type: Function as PropType<(tab: GuardianEditTableTabValue) => void>,
      required: true,
    },
  },
  setup(props) {
    const selectedGroupColumns = computed(
      () =>
        Object.values(props.columnGroups).find(
          (g) => g.groupName === props.selectedTab,
        )?.columns ?? null,
    );

    return {
      columnGroupNames: [
        guardianProfileColumnGroupName,
        guardianNotesColumnGroupName,
      ],
      selectedGroupColumns,
    };
  },
});
