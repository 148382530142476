
import { computed, defineComponent, PropType, ref } from "vue";
import { BasicUserInfoPartial } from "@/ts/objects/value/basic-user-info";
import { UserType } from "@/ts/objects/value/user-type";
import { ColorLabel, labelToColor } from "@/ts/objects/value/color-label";
import { isNullish, isStringBlank } from "@/ts/utils/common-util";
import UserSelectPopup from "@/components/popups/UserSelectPopup/UserSelectPopup.vue";
import { userEditPagePathOrHome } from "@/router/path-constructors";

export default defineComponent({
  name: "EditableItemUser",
  components: { UserSelectPopup },
  props: {
    value: { type: Object as PropType<BasicUserInfoPartial>, default: null },
    /**
     * 編集可能状態であればtrue。
     */
    editable: { type: Boolean, required: true },

    paddingTop: { type: Number, default: 4, required: false },
    paddingRight: { type: Number, default: 4, required: false },
    paddingBottom: { type: Number, default: 4, required: false },
    paddingLeft: { type: Number, default: 4, required: false },
    fontSize: { type: Number, default: 14 },
    fontColor: { type: String as PropType<ColorLabel>, default: "black" },
    fontColorLight: { type: String as PropType<ColorLabel>, default: "gray" },
    /**
     * trueにした場合、
     * editableがfalseの間、入力ボックスを消して、中身の値だけを普通のテキストのように表示する。
     */
    hideBoxOnDisable: { type: Boolean, required: false, default: true },
    /**
     * trueにした場合、
     * editableがfalseの間、ユーザー詳細ページへのリンクになる。
     */
    isLinkOnDisable: { type: Boolean, required: false, default: false },

    promptText: { type: String, default: "ユーザーを選択します。" },
    userType: { type: String as PropType<UserType>, required: true },
    onInput: {
      type: Function as PropType<(user: BasicUserInfoPartial | null) => void>,
      required: true,
    },
  },
  setup(props) {
    const isNameBlank = computed(() => isStringBlank(props.value?.name));
    const displayValueAndColor = computed<{ value: string; color: ColorLabel }>(
      () => {
        if (isNameBlank.value) {
          return {
            value: props.value?.googleMail ?? props.value?.userId ?? "",
            color: props.fontColorLight,
          };
        }
        return { value: props.value?.name ?? "", color: props.fontColor };
      },
    );
    const active = ref(false);

    const linkPath = computed<string | null>(() => {
      const _user = props.value;
      if (isNullish(_user)) return null;
      return userEditPagePathOrHome(_user.userType, _user.userId);
    });

    const clear = () => {
      if (props.editable) props.onInput(null);
    };

    const openPopup = () => (active.value = true);
    const closePopup = () => (active.value = false);
    const onConfirm = (user: BasicUserInfoPartial) => {
      active.value = false;
      if (props.editable) props.onInput(user);
    };

    return {
      displayValue: computed(() => displayValueAndColor.value.value),
      active,
      linkPath,

      clear,

      openPopup,
      closePopup,
      onConfirm,

      styles: computed(() => ({
        "--padding-top": `${props.paddingTop}px`,
        "--padding-right": `${props.paddingRight}px`,
        "--padding-bottom": `${props.paddingBottom}px`,
        "--padding-left": `${props.paddingLeft}px`,
        "--font-size": `${props.fontSize}px`,
        "--color": labelToColor(displayValueAndColor.value.color),
      })),
    };
  },
});
