import { Class } from "@/ts/objects/entity/class";
import { v4 as uuidv4 } from "uuid";

export type ClassListEditState =
  | {
      readonly editing: false;
    }
  | {
      readonly editing: true;
      readonly data: ClassListData;
    };

export type ClassListData = {
  readonly elementary: ClassListTableDataElementary;
  readonly juniorhigh: ClassListTableDataJuniorhigh;
};

/**
 * クラスのリストから、クラスリストデータを作成する。
 * @param classes クラスのリスト。すべて同じ年度に属する必要がある。
 */
export function classListDataFromClasses(classes: Class[]): ClassListData {
  if (classes.length > 0) {
    const schoolYear = classes[0].schoolYear;
    if (classes.some((c) => c.schoolYear !== schoolYear))
      throw new Error(
        "classListDataFromClasses: all classes must have the same schoolYear",
      );
  }

  const elementaryClasses = classes.filter(
    (c) => c.grade.schoolType === "elementary",
  );
  const juniorhighClasses = classes.filter(
    (c) => c.grade.schoolType === "juniorhigh",
  );

  const rowClasses = (classes: Class[], gradeNumber: number) =>
    classes
      .filter((c) => c.grade.gradeNumber === gradeNumber)
      .map((c) => classListItemDataFromClass(c));

  return {
    elementary: {
      schoolType: "elementary",
      row1: {
        gradeNumber: 1,
        classes: rowClasses(elementaryClasses, 1),
      },
      row2: {
        gradeNumber: 2,
        classes: rowClasses(elementaryClasses, 2),
      },
      row3: {
        gradeNumber: 3,
        classes: rowClasses(elementaryClasses, 3),
      },
      row4: {
        gradeNumber: 4,
        classes: rowClasses(elementaryClasses, 4),
      },
      row5: {
        gradeNumber: 5,
        classes: rowClasses(elementaryClasses, 5),
      },
      row6: {
        gradeNumber: 6,
        classes: rowClasses(elementaryClasses, 6),
      },
    },
    juniorhigh: {
      schoolType: "juniorhigh",
      row1: {
        gradeNumber: 1,
        classes: rowClasses(juniorhighClasses, 1),
      },
      row2: {
        gradeNumber: 2,
        classes: rowClasses(juniorhighClasses, 2),
      },
      row3: {
        gradeNumber: 3,
        classes: rowClasses(juniorhighClasses, 3),
      },
    },
  };
}

export type ClassListTableData =
  | ClassListTableDataElementary
  | ClassListTableDataJuniorhigh;

export type ClassListTableDataElementary = {
  readonly schoolType: "elementary";
  row1: { readonly gradeNumber: 1; classes: ClassListItemData[] };
  row2: { readonly gradeNumber: 2; classes: ClassListItemData[] };
  row3: { readonly gradeNumber: 3; classes: ClassListItemData[] };
  row4: { readonly gradeNumber: 4; classes: ClassListItemData[] };
  row5: { readonly gradeNumber: 5; classes: ClassListItemData[] };
  row6: { readonly gradeNumber: 6; classes: ClassListItemData[] };
};

export type ClassListTableDataJuniorhigh = {
  readonly schoolType: "juniorhigh";
  row1: { readonly gradeNumber: 1; classes: ClassListItemData[] };
  row2: { readonly gradeNumber: 2; classes: ClassListItemData[] };
  row3: { readonly gradeNumber: 3; classes: ClassListItemData[] };
};

export type ClassListRowData = {
  readonly gradeNumber: 1 | 2 | 3 | 4 | 5 | 6;
  classes: ClassListItemData[];
};

export type ClassListItemData = {
  readonly classId: string | null; // 新規作成アイテムの場合、null。
  readonly clientSideId: string; // 新規作成アイテムの場合、ランダム生成 + "_new"。それ以外の場合、classIdと同じ値。

  readonly name: string;
};

export function createNewClassListItemData(name?: string): ClassListItemData {
  return {
    classId: null,
    clientSideId: `${uuidv4()}_new`,
    name: name ?? "",
  };
}

export function classListItemDataFromClass(cls: Class): ClassListItemData {
  return {
    classId: cls.classId,
    clientSideId: cls.classId,
    name: cls.name,
  };
}
