import { createGrade, Grade } from "@/ts/objects/value/grade";
import {
  asSchoolTypeOrNull,
  SchoolType,
  schoolTypeToDisplayValue,
} from "@/ts/objects/value/school-type";
import { asIntOrNull, asTextOrNull, isNullish } from "@/ts/utils/common-util";
import { LocationQuery, LocationQueryValue } from "vue-router";

const _subTypes = {
  all: "all",
  "school-type": "school-type",
  grade: "grade",
  class: "class",
  "no-class": "no-class",
} as const;

export const searchConditionClassSubTypes = Object.values(_subTypes);

export type SearchConditionClassSubType =
  typeof searchConditionClassSubTypes[number];

export function isSearchConditionClassSubType(
  value: unknown,
): value is SearchConditionClassSubType {
  return (
    typeof value === "string" &&
    searchConditionClassSubTypes.some((v) => v === value)
  );
}

export function asSearchConditionClassSubTypeOrNull(
  value: unknown,
): SearchConditionClassSubType | null {
  if (isSearchConditionClassSubType(value)) return value;
  return null;
}

export type SearchConditionClass = {
  searchConditionType: "class";
  schoolYear: number; // 年度。一部の検索に用いる。
} & (
  | {
      subType: typeof _subTypes["all"];
    } // クラス不問。すべての生徒。
  | {
      subType: typeof _subTypes["school-type"];
      schoolType: SchoolType;
    } // その年度にその学校タイプに所属する。
  | {
      subType: typeof _subTypes["grade"];
      grade: Grade;
    } // その年度にその学年に所属する。クラス未割当の児童生徒は学年を判定できないことに注意(例: 来年を指定したとき、クラス未割当の来年度1年生は検索に引っかからない)。
  | {
      subType: typeof _subTypes["class"];
      grade: Grade; // 学年。表示に使う。検索には使わない。
      className: string; // クラス名。表示に使う。検索には使わない。
      classId: string;
    } // そのクラスに所属する。
  | {
      subType: typeof _subTypes["no-class"];
    }
); // すべての年度について、クラスが未割当。

export function searchConditionClassDefault(
  schoolYear: number,
): SearchConditionClass {
  return {
    searchConditionType: "class",
    schoolYear,
    subType: "all",
  };
}

export function searchConditionClassToDisplayValue(
  cond: SearchConditionClass,
): string {
  switch (cond.subType) {
    case "all":
      return "クラス不問";
    case "school-type":
      return schoolTypeToDisplayValue(cond.schoolType);
    case "grade":
      return `${schoolTypeToDisplayValue(cond.grade.schoolType)}${
        cond.grade.gradeNumber
      }年`;
    case "class":
      return `${schoolTypeToDisplayValue(cond.grade.schoolType)}${
        cond.grade.gradeNumber
      }年${cond.className}組`;
    case "no-class":
      return "クラス未割当";
  }
}

type QueryParams = {
  classSubType: string | undefined;
  classSchoolType: string | undefined;
  classGradeNumber: number | undefined;
  className: string | undefined;
  classId: string | undefined;
};

export function searchConditionClassFromQueryParams(
  schoolYear: number,
  query: LocationQuery,
): SearchConditionClass {
  const _query = query as Record<
    keyof QueryParams,
    LocationQueryValue | LocationQueryValue[] | undefined
  >;
  const subType = asSearchConditionClassSubTypeOrNull(_query.classSubType);
  const schoolType = asSchoolTypeOrNull(_query.classSchoolType);
  const gradeNumber = asIntOrNull(_query.classGradeNumber, 1, 99);
  const className = asTextOrNull(_query.className);
  const classId = asTextOrNull(_query.classId);

  const defaultValue = searchConditionClassDefault(schoolYear);

  const commonAttr = {
    searchConditionType: "class" as const,
    schoolYear,
  };
  switch (subType) {
    case "all": {
      return {
        ...commonAttr,
        subType: "all",
      };
    }
    case "school-type":
      if (isNullish(schoolType)) return defaultValue;
      return {
        ...commonAttr,
        subType: "school-type",
        schoolType,
      };
    case "grade": {
      const gradeResult = createGrade(schoolType, gradeNumber);
      if (!gradeResult.ok) return defaultValue;
      return {
        ...commonAttr,
        subType: "grade",
        grade: gradeResult.data,
      };
    }
    case "class": {
      const gradeResult = createGrade(schoolType, gradeNumber);
      if (!gradeResult.ok) return defaultValue;

      if (isNullish(className) || isNullish(classId)) return defaultValue;
      return {
        ...commonAttr,
        subType: "class",
        grade: gradeResult.data,
        className,
        classId,
      };
    }
    case "no-class":
      return {
        ...commonAttr,
        subType: "no-class",
      };
    case null:
      return defaultValue;
  }
}

export function searchConditionClassToQueryParams(
  cond: SearchConditionClass,
): QueryParams {
  switch (cond.subType) {
    case "all":
      return {
        classSubType: undefined,
        classSchoolType: undefined,
        classGradeNumber: undefined,
        className: undefined,
        classId: undefined,
      };
    case "school-type":
      return {
        classSubType: cond.subType,
        classSchoolType: cond.schoolType,
        classGradeNumber: undefined,
        className: undefined,
        classId: undefined,
      };
    case "grade":
      return {
        classSubType: cond.subType,
        classSchoolType: cond.grade.schoolType,
        classGradeNumber: cond.grade.gradeNumber,
        className: undefined,
        classId: undefined,
      };
    case "class":
      return {
        classSubType: cond.subType,
        classSchoolType: cond.grade.schoolType,
        classGradeNumber: cond.grade.gradeNumber,
        className: cond.className,
        classId: cond.classId,
      };
    case "no-class":
      return {
        classSubType: cond.subType,
        classSchoolType: undefined,
        classGradeNumber: undefined,
        className: undefined,
        classId: undefined,
      };
  }
}

// すべてand。
export type SearchConditionClassRaw = {
  classSchoolYear?: number;
  schoolType?: SchoolType;
  gradeNumber?: number;
  classIds?: string[];
  noClass?: boolean;
};

export function searchConditionClassToRaw(
  cond: SearchConditionClass,
): SearchConditionClassRaw {
  switch (cond.subType) {
    case "all":
      return {};
    case "school-type":
      return {
        classSchoolYear: cond.schoolYear,
        schoolType: cond.schoolType,
      };
    case "grade":
      return {
        classSchoolYear: cond.schoolYear,
        schoolType: cond.grade.schoolType,
        gradeNumber: cond.grade.gradeNumber,
      };
    case "class":
      return {
        classIds: [cond.classId],
      };
    case "no-class":
      return { noClass: true };
  }
}
