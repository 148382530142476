import {
  UserColumnDefGroup,
  UserColumnGroup,
} from "@/ts/app/columns/def/user-column-group";
import { Guardian, GuardianNotes } from "@/ts/objects/entity/guardian";
import { GuardianColumnDef } from "@/ts/app/columns/def/guardian/guardian-column";
import { UserColumn } from "@/ts/app/columns/def/user-column";
import { genUserColumnDefs } from "@/ts/app/columns/def/user-column-def";
import { StringType } from "@/ts/app/columns/def/column-types";
import { names } from "@/ts/app/object-name";

export const guardianNotesColumnGroupName = names.notes.i;
export type GuardianNotesColumnGroupName = typeof guardianNotesColumnGroupName;

export type GuardianNotesColumnId = keyof GuardianNotes;

export type GuardianNotesColumnDef<T> = GuardianColumnDef<
  GuardianNotesColumnId,
  T
>;

export type GuardianNotesColumn<T> = UserColumn<
  GuardianNotesColumnId,
  Guardian,
  T
>;

export type GuardianNotesColumnDefGroup = UserColumnDefGroup<
  GuardianNotesColumnGroupName,
  GuardianNotesColumnId,
  Guardian
>;

export type GuardianNotesColumnGroup = UserColumnGroup<
  GuardianNotesColumnGroupName,
  GuardianNotesColumnId,
  Guardian
>;

export const guardianNotesColumnDefs: GuardianNotesColumnDef<any>[] =
  genUserColumnDefs<GuardianNotesColumnId, Guardian, any>([
    {
      id: "note01",
      width: 120,
      type: StringType,
      getValue: (g) => g.note01,
      setValue: (g, v) => ({ ...g, note01: v }),
    },
    {
      id: "note02",
      width: 120,
      type: StringType,
      getValue: (g) => g.note02,
      setValue: (g, v) => ({ ...g, note02: v }),
    },
    {
      id: "note03",
      width: 120,
      type: StringType,
      getValue: (g) => g.note03,
      setValue: (g, v) => ({ ...g, note03: v }),
    },
    {
      id: "note04",
      width: 120,
      type: StringType,
      getValue: (g) => g.note04,
      setValue: (g, v) => ({ ...g, note04: v }),
    },
    {
      id: "note05",
      width: 120,
      type: StringType,
      getValue: (g) => g.note05,
      setValue: (g, v) => ({ ...g, note05: v }),
    },
    {
      id: "note06",
      width: 120,
      type: StringType,
      getValue: (g) => g.note06,
      setValue: (g, v) => ({ ...g, note06: v }),
    },
    {
      id: "note07",
      width: 120,
      type: StringType,
      getValue: (g) => g.note07,
      setValue: (g, v) => ({ ...g, note07: v }),
    },
    {
      id: "note08",
      width: 120,
      type: StringType,
      getValue: (g) => g.note08,
      setValue: (g, v) => ({ ...g, note08: v }),
    },
    {
      id: "note09",
      width: 120,
      type: StringType,
      getValue: (g) => g.note09,
      setValue: (g, v) => ({ ...g, note09: v }),
    },
    {
      id: "note10",
      width: 120,
      type: StringType,
      getValue: (g) => g.note10,
      setValue: (g, v) => ({ ...g, note10: v }),
    },
  ]);
