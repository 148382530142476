import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-88983a4e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["aria-label"]
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "sections" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CurriculumEditTableRowSection = _resolveComponent("CurriculumEditTableRowSection")!

  return (_openBlock(), _createElementBlock("li", {
    class: "curriculum-edit-table-row-container",
    "aria-label": `${_ctx.data.gradeNumber}年`
  }, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.data.gradeNumber) + "年", 1),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_CurriculumEditTableRowSection, {
        class: "section numeric-section",
        "aria-label": "数値評価教科",
        data: _ctx.data.numeric,
        editing: _ctx.editing,
        "on-update": _ctx.onUpdateSection
      }, null, 8, ["data", "editing", "on-update"]),
      _createVNode(_component_CurriculumEditTableRowSection, {
        class: "section essay-section",
        "aria-label": "文書評価教科",
        data: _ctx.data.essay,
        editing: _ctx.editing,
        "on-update": _ctx.onUpdateSection
      }, null, 8, ["data", "editing", "on-update"])
    ])
  ], 8, _hoisted_1))
}