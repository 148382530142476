export const teacherSubPageNames = ["list", "edit"] as const;
export type TeacherSubPageName = typeof teacherSubPageNames[number];

export function isTeacherSubPageName(
  value: unknown,
): value is TeacherSubPageName {
  return (
    typeof value === "string" && teacherSubPageNames.some((v) => v === value)
  );
}

export function asTeacherSubPageNameOrNull(
  value: unknown,
): TeacherSubPageName | null {
  if (isTeacherSubPageName(value)) return value;
  return null;
}
