import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-34cce5a4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "value-area"
}
const _hoisted_2 = {
  key: 2,
  class: "value-area"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_UserSelectPopup = _resolveComponent("UserSelectPopup")!
  const _component_router_link = _resolveComponent("router-link")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["editable-item-user-container", { boxed: _ctx.editable || !_ctx.hideBoxOnDisable }]),
    style: _normalizeStyle(_ctx.styles)
  }, [
    (_ctx.editable)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("button", {
            type: "button",
            class: "value",
            "aria-label": "Select User",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openPopup && _ctx.openPopup(...args)))
          }, _toDisplayString(_ctx.displayValue), 1),
          _createElementVNode("button", {
            type: "button",
            class: "clear-button",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.clear && _ctx.clear(...args))),
            "aria-label": "Clear"
          }, [
            _createVNode(_component_font_awesome_icon, {
              class: "icon",
              icon: ['fas', 'times']
            })
          ]),
          (_ctx.active)
            ? _withDirectives((_openBlock(), _createBlock(_component_UserSelectPopup, {
                key: 0,
                class: "user-select-popup",
                text: _ctx.promptText,
                width: 300,
                "search-box-placeholder": "名前・Google Mail・ユーザーIDで前方一致検索",
                "search-box-placeholder-font-size": 11,
                "user-type": _ctx.userType,
                "on-confirm": _ctx.onConfirm
              }, null, 8, ["text", "user-type", "on-confirm"])), [
                [_directive_click_outside, _ctx.closePopup]
              ])
            : _createCommentVNode("", true)
        ]))
      : (_ctx.isLinkOnDisable && _ctx.linkPath != null)
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 1,
            class: "value-area link",
            to: _ctx.linkPath
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.displayValue), 1)
            ]),
            _: 1
          }, 8, ["to"]))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.displayValue), 1))
  ], 6))
}