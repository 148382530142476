import { HashedString } from "@/ts/objects/value/hashed-string";
import { CurriculumEvalType } from "@/ts/objects/value/curriculum-eval-type";
import {
  Curriculum,
  EECurriculum,
  NECurriculum,
} from "@/ts/objects/entity/curriculum";
import { CheckFailed } from "@/ts/app/error/check-error";
import { SchoolType } from "@/ts/objects/value/school-type";
import { v4 as uuidv4 } from "uuid";
import { gradeNumbersOfSchoolType } from "@/ts/objects/value/grade-number";

export type CurriculumEditState =
  | {
      readonly editing: false;
    }
  | {
      readonly editing: true;
      readonly data: CurriculumEditTableData;
    };

/**
 * 教科のリストから、教科編集データを作成する。
 *
 * @param schoolYear
 * @param schoolType
 * @param curriculums すべての教科のリスト。余計なものが混ざっていても良い。
 * @param createAsNewData trueの場合、新規データ扱いで作成する。つまり、idを空にして作成する。
 */
export function curriculumEditTableDataFromCurriculums(
  schoolYear: number,
  schoolType: SchoolType,
  curriculums: Curriculum[],
  createAsNewData: boolean = false,
): CurriculumEditTableData {
  const _curriculums = curriculums.filter(
    (c) => c.schoolYear === schoolYear && c.grade.schoolType === schoolType,
  );

  const necs: NECurriculum[] = _curriculums.filter(
    (c): c is NECurriculum => c.evalType === "numeric",
  );
  const eecs: EECurriculum[] = _curriculums.filter(
    (c): c is EECurriculum => c.evalType === "essay",
  );

  const rowNecs = (_necs: NECurriculum[], gradeNumber: number) =>
    _necs
      .filter((c) => c.grade.gradeNumber === gradeNumber)
      .map((c) =>
        curriculumEditItemDataNumericFromCurriculum(c, createAsNewData),
      );
  const rowEecs = (_eecs: EECurriculum[], gradeNumber: number) =>
    _eecs
      .filter((c) => c.grade.gradeNumber === gradeNumber)
      .map((c) =>
        curriculumEditItemDataEssayFromCurriculum(c, createAsNewData),
      );

  switch (schoolType) {
    case "elementary": {
      return {
        schoolType: "elementary",
        row1: {
          gradeNumber: 1,
          numeric: { evalType: "numeric", curriculums: rowNecs(necs, 1) },
          essay: { evalType: "essay", curriculums: rowEecs(eecs, 1) },
        },
        row2: {
          gradeNumber: 2,
          numeric: { evalType: "numeric", curriculums: rowNecs(necs, 2) },
          essay: { evalType: "essay", curriculums: rowEecs(eecs, 2) },
        },
        row3: {
          gradeNumber: 3,
          numeric: { evalType: "numeric", curriculums: rowNecs(necs, 3) },
          essay: { evalType: "essay", curriculums: rowEecs(eecs, 3) },
        },
        row4: {
          gradeNumber: 4,
          numeric: { evalType: "numeric", curriculums: rowNecs(necs, 4) },
          essay: { evalType: "essay", curriculums: rowEecs(eecs, 4) },
        },
        row5: {
          gradeNumber: 5,
          numeric: { evalType: "numeric", curriculums: rowNecs(necs, 5) },
          essay: { evalType: "essay", curriculums: rowEecs(eecs, 5) },
        },
        row6: {
          gradeNumber: 6,
          numeric: { evalType: "numeric", curriculums: rowNecs(necs, 6) },
          essay: { evalType: "essay", curriculums: rowEecs(eecs, 6) },
        },
      };
    }
    case "juniorhigh": {
      return {
        schoolType: "juniorhigh",
        row1: {
          gradeNumber: 1,
          numeric: { evalType: "numeric", curriculums: rowNecs(necs, 1) },
          essay: { evalType: "essay", curriculums: rowEecs(eecs, 1) },
        },
        row2: {
          gradeNumber: 2,
          numeric: { evalType: "numeric", curriculums: rowNecs(necs, 2) },
          essay: { evalType: "essay", curriculums: rowEecs(eecs, 2) },
        },
        row3: {
          gradeNumber: 3,
          numeric: { evalType: "numeric", curriculums: rowNecs(necs, 3) },
          essay: { evalType: "essay", curriculums: rowEecs(eecs, 3) },
        },
      };
    }
    default:
      throw new CheckFailed();
  }
}

export type CurriculumEditTableData =
  | CurriculumEditTableDataElementary
  | CurriculumEditTableDataJuniorhigh;

export type CurriculumEditTableDataElementary = {
  readonly schoolType: "elementary";
  row1: CurriculumEditRowData<1>;
  row2: CurriculumEditRowData<2>;
  row3: CurriculumEditRowData<3>;
  row4: CurriculumEditRowData<4>;
  row5: CurriculumEditRowData<5>;
  row6: CurriculumEditRowData<6>;
};

export type CurriculumEditTableDataJuniorhigh = {
  readonly schoolType: "juniorhigh";
  row1: CurriculumEditRowData<1>;
  row2: CurriculumEditRowData<2>;
  row3: CurriculumEditRowData<3>;
};

export function allItemsOfCurriculumEditTableData(
  schoolYear: number,
  data: CurriculumEditTableData,
): (CurriculumEditItemData<CurriculumEvalType> & {
  schoolYear: number;
  schoolType: SchoolType;
  gradeNumber: 1 | 2 | 3 | 4 | 5 | 6;
  orderNum: number | null;
})[] {
  const allRows: (CurriculumEditRowData<1 | 2 | 3 | 4 | 5 | 6> & {
    schoolType: SchoolType;
  })[] =
    data.schoolType === "elementary"
      ? gradeNumbersOfSchoolType["elementary"].map((gn) => ({
          ...data[`row${gn}`],
          schoolType: "elementary",
        }))
      : gradeNumbersOfSchoolType["juniorhigh"].map((gn) => ({
          ...data[`row${gn}`],
          schoolType: "juniorhigh",
        }));
  return allRows.flatMap((row) => {
    let numericOrderNum = -1;
    const numericItems = row.numeric.curriculums.map((c) => {
      if (!c.deleted) numericOrderNum += 1;
      return {
        ...c,
        schoolYear,
        schoolType: row.schoolType,
        gradeNumber: row.gradeNumber,
        orderNum: c.deleted ? null : numericOrderNum,
      };
    });

    let essayOrderNum = -1;
    const essayItems = row.essay.curriculums.map((c) => {
      if (!c.deleted) essayOrderNum += 1;
      return {
        ...c,
        schoolYear,
        schoolType: row.schoolType,
        gradeNumber: row.gradeNumber,
        orderNum: c.deleted ? null : essayOrderNum,
      };
    });

    return [...numericItems, ...essayItems];
  });
}

export type CurriculumEditRowData<GradeNumber extends 1 | 2 | 3 | 4 | 5 | 6> = {
  readonly gradeNumber: GradeNumber;
  numeric: CurriculumEditRowSectionData<"numeric">;
  essay: CurriculumEditRowSectionData<"essay">;
};

export type CurriculumEditRowSectionData<EvalType extends CurriculumEvalType> =
  {
    readonly evalType: EvalType;
    curriculums: CurriculumEditItemData<EvalType>[];
  };

export type CurriculumEditItemData<EvalType extends CurriculumEvalType> = {
  readonly evalType: EvalType;
  readonly id: string | null; // 新規作成アイテムの場合、null。
  readonly clientSideId: string; // 新規作成アイテムの場合、ランダム生成 + "_new"。それ以外の場合、idと同じ値。

  name: HashedString;

  /**
   * trueなら削除済を表す。
   */
  deleted: boolean;
};

function newUuid(): string {
  return `${uuidv4()}_new`;
}

export function createNewCurriculumEditItemData<
  EvalType extends CurriculumEvalType,
>(evalType: EvalType, name?: string): CurriculumEditItemData<EvalType> {
  return {
    evalType,
    id: null,
    clientSideId: newUuid(),
    name: { value: name ?? "", hash: "" }, // TODO hashって最初は空で良かったと思うけど要確認。
    deleted: false,
  };
}

export function curriculumEditItemDataNumericFromCurriculum(
  c: NECurriculum,
  createAsNewData: boolean,
): CurriculumEditItemData<"numeric"> {
  return {
    evalType: "numeric",
    id: createAsNewData ? null : c.id,
    clientSideId: createAsNewData ? newUuid() : c.id,
    name: c.name,
    deleted: false,
  };
}

export function curriculumEditItemDataEssayFromCurriculum(
  c: EECurriculum,
  createAsNewData: boolean,
): CurriculumEditItemData<"essay"> {
  return {
    evalType: "essay",
    id: createAsNewData ? null : c.id,
    clientSideId: createAsNewData ? newUuid() : c.id,
    name: c.name,
    deleted: false,
  };
}
