
import { defineComponent } from "vue";
import BaseLinkButton from "@/components/buttons/BaseLinkButton/BaseLinkButton.vue";

export default defineComponent({
  name: "ImportButton",
  components: { BaseLinkButton },
  props: {
    enabled: { type: Boolean, default: true },
    to: { type: String, required: true },
  },
});
