import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5554b709"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "cell row-title-cell" }
const _hoisted_2 = { key: 2 }
const _hoisted_3 = { key: 3 }
const _hoisted_4 = { class: "cell user-icon-cell" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "cell name-cell" }
const _hoisted_7 = {
  key: 0,
  class: "name-text"
}
const _hoisted_8 = { class: "cell google-mail-cell" }
const _hoisted_9 = {
  key: 0,
  class: "controls"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EditableItemText = _resolveComponent("EditableItemText")!
  const _component_EditableItemNumber = _resolveComponent("EditableItemNumber")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_RemoveButton = _resolveComponent("RemoveButton")!

  return (_openBlock(), _createElementBlock("li", {
    class: _normalizeClass(["class-details-table-row-container", `theme${_ctx.theme}`]),
    style: _normalizeStyle(_ctx.styles),
    onMouseenter: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onMouseEnter && _ctx.onMouseEnter(...args))),
    onMouseleave: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onMouseLeave && _ctx.onMouseLeave(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.editing && _ctx.data.userType === 'teacher')
        ? (_openBlock(), _createBlock(_component_EditableItemText, {
            key: 0,
            value: _ctx.data.inChargeType,
            editable: true,
            type: "input",
            "font-size": 13,
            "font-color": "black",
            "max-length": 1,
            "on-input": _ctx.onChangeRowTitleInternal
          }, null, 8, ["value", "on-input"]))
        : (_ctx.editing && _ctx.data.userType === 'student')
          ? (_openBlock(), _createBlock(_component_EditableItemNumber, {
              key: 1,
              value: _ctx.data.studentNumber,
              editable: true,
              "font-size": 13,
              "font-color": "black",
              "on-input": _ctx.onChangeRowTitleInternal
            }, null, 8, ["value", "on-input"]))
          : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.rowTitle), 1)),
      (_ctx.editing || _ctx.rowTitle !== '')
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.rowTitleUnit), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.photoUrl !== '')
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            class: "icon",
            src: _ctx.photoUrl,
            alt: "user icon"
          }, null, 8, _hoisted_5))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_6, [
      (_ctx.editing)
        ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.displayName.value), 1))
        : (_openBlock(), _createBlock(_component_router_link, {
            key: 1,
            class: "name-text",
            to: _ctx.userEditPath
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.displayName.value), 1)
            ]),
            _: 1
          }, 8, ["to"]))
    ]),
    _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.googleMail), 1),
    (_ctx.editing && _ctx.hovering)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createVNode(_component_RemoveButton, {
            "aria-label": "Delete",
            "on-click": _ctx.onClickRemoveInternal
          }, null, 8, ["on-click"])
        ]))
      : _createCommentVNode("", true)
  ], 38))
}