import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createBlock(_component_BaseButton, {
    label: 'Sync with Google Workspace',
    color: "light-black",
    "background-color": "dark-white",
    "background-color-on-hover": "light-gray",
    icon: "sync",
    "icon-size": 14,
    "icon-padding-bottom": 0,
    "icon-margin-right": 5,
    "label-padding-bottom": 1,
    width: 256,
    height: 33,
    "padding-left": 12,
    "padding-right": 8,
    "border-width": 0,
    "border-radius": 8,
    "font-size": 15,
    bold: false,
    enabled: _ctx.enabled,
    "on-click": _ctx.onClick
  }, null, 8, ["enabled", "on-click"]))
}