export const gradeNumbersOfSchoolType = {
  elementary: [1, 2, 3, 4, 5, 6],
  juniorhigh: [1, 2, 3],
} as const;

export type ElementaryGradeNumber =
  typeof gradeNumbersOfSchoolType["elementary"][number];

export type JuniorhighGradeNumber =
  typeof gradeNumbersOfSchoolType["juniorhigh"][number];

export function isElementaryGradeNumber(
  value: unknown,
): value is ElementaryGradeNumber {
  return (
    typeof value === "number" &&
    gradeNumbersOfSchoolType.elementary.some((v) => v === value)
  );
}

export function isJuniorhighGradeNumber(
  value: unknown,
): value is JuniorhighGradeNumber {
  return (
    typeof value === "number" &&
    gradeNumbersOfSchoolType.juniorhigh.some((v) => v === value)
  );
}
