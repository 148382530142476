import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fc3271d6"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "buttons-area-left"
}
const _hoisted_2 = {
  key: 1,
  class: "buttons-area-right"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LeftButton = _resolveComponent("LeftButton")!
  const _component_EditableItemText = _resolveComponent("EditableItemText")!
  const _component_RightButton = _resolveComponent("RightButton")!
  const _component_MenuDropdown = _resolveComponent("MenuDropdown")!

  return (_openBlock(), _createElementBlock("li", {
    class: _normalizeClass(["curriculum-edit-table-cell-container", { editable: _ctx.editable, deleted: _ctx.deleted }]),
    "data-testid": "curriculum-edit-table-cell",
    onMouseenter: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onMouseEnter && _ctx.onMouseEnter(...args))),
    onMouseleave: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onMouseLeave && _ctx.onMouseLeave(...args)))
  }, [
    (_ctx.editable)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_LeftButton, {
            class: "left-button",
            "aria-label": "Move Left",
            color: _ctx.buttonsColor,
            "icon-size": 10,
            size: 12,
            "on-click": () => _ctx.moveInternal(true)
          }, null, 8, ["color", "on-click"])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_EditableItemText, {
      class: _normalizeClass(["editable-item", { editable: _ctx.editable }]),
      value: _ctx.name,
      editable: _ctx.editable,
      type: "input",
      "font-size": 13,
      "font-color": "black",
      "hide-box-on-disable": true,
      "hide-box-on-editing": true,
      "adjust-content-on-disable": true,
      "on-input": _ctx.onInputNameInternal
    }, null, 8, ["class", "value", "editable", "on-input"]),
    (_ctx.editable)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_RightButton, {
            class: "right-button",
            "aria-label": "Move Right",
            color: _ctx.buttonsColor,
            "icon-size": 10,
            size: 12,
            "on-click": () => _ctx.moveInternal(false)
          }, null, 8, ["color", "on-click"]),
          _createVNode(_component_MenuDropdown, {
            class: "menu-button",
            "aria-label": "Extra Menu",
            color: _ctx.buttonsColor,
            size: 12,
            "icon-size": 10,
            items: _ctx.menuItems,
            enabled: _ctx.editable,
            "options-width": 60,
            "options-align": "left",
            "on-select": _ctx.onSelectMenuItem
          }, null, 8, ["color", "items", "enabled", "on-select"])
        ]))
      : _createCommentVNode("", true)
  ], 34))
}