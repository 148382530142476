export type ObjectName = {
  i: string; // internal name。内部向けの名称。
  d: string; // display name。表示用の名称。
};

// アルファベット順。
export const names = {
  activityFriday: {
    i: "activityFriday",
    d: "Extracurricular Activities (Fri)",
  }, // 課外活動-金
  activityMonday: {
    i: "activityMonday",
    d: "Extracurricular Activities (Mon)",
  }, // 課外活動-月
  activityThursday: {
    i: "activityThursday",
    d: "Extracurricular Activities (Thur)",
  }, // 課外活動-木
  activityTuesday: {
    i: "activityTuesday",
    d: "Extracurricular Activities (Tue)",
  }, // 課外活動-火
  activityWednesday: {
    i: "activityWednesday",
    d: "Extracurricular Activities (Wed)",
  }, // 課外活動-水
  address: { i: "address", d: "Address" }, // 住所
  anaphylaxis: { i: "anaphylaxis", d: "Anaphylaxis" }, // アナフィラキシー
  applicationDate: { i: "applicationDate", d: "Application Date" }, // 志願申込日
  asthma: { i: "asthma", d: "Asthma" }, // ぜんそく

  basicUserInfo: { i: "basicUserInfo", d: "Basic User Info" }, // ユーザー基本情報
  birthday: { i: "birthday", d: "Birthday" }, // 誕生日
  bloodType: { i: "bloodType", d: "Blood Type" }, // 血液型
  bus: { i: "bus", d: "Bus" }, // バス乗車便

  children: { i: "children", d: "Children" }, // 児童生徒
  class: { i: "class", d: "Class" }, // クラス
  classes: { i: "classes", d: "Classes" }, // クラス
  classesOfTheYear: { i: "classesOfTheYear", d: "Classes" }, // その年度のクラス
  classId: { i: "classId", d: "Class ID" }, // クラスID
  className: { i: "className", d: "Class Name" }, // クラス名
  classStudents: { i: "classStudents", d: "Students" }, // 所属児童生徒
  classTeachers: { i: "classTeachers", d: "Teachers" }, // 担当教職員
  commutingBy: { i: "commutingBy", d: "Commuting By" }, // 通学方法
  country: { i: "country", d: "Nationality" }, // 国籍
  curriculum: { i: "curriculum", d: "Curriculum" }, // 教科
  curriculumEvalType: { i: "curriculumEvalType", d: "Evaluation Type" }, // 教科評価タイプ
  customItems: { i: "customItems", d: "Custom Items" }, // カスタム項目
  custom01: { i: "custom01", d: "Custom Item 01" }, // カスタム項目01
  custom02: { i: "custom02", d: "Custom Item 02" }, // カスタム項目02
  custom03: { i: "custom03", d: "Custom Item 03" }, // カスタム項目03
  custom04: { i: "custom04", d: "Custom Item 04" }, // カスタム項目04
  custom05: { i: "custom05", d: "Custom Item 05" }, // カスタム項目05
  custom06: { i: "custom06", d: "Custom Item 06" }, // カスタム項目06
  custom07: { i: "custom07", d: "Custom Item 07" }, // カスタム項目07
  custom08: { i: "custom08", d: "Custom Item 08" }, // カスタム項目08
  custom09: { i: "custom09", d: "Custom Item 09" }, // カスタム項目09
  custom10: { i: "custom10", d: "Custom Item 10" }, // カスタム項目10
  custom11: { i: "custom11", d: "Custom Item 11" }, // カスタム項目11
  custom12: { i: "custom12", d: "Custom Item 12" }, // カスタム項目12
  custom13: { i: "custom13", d: "Custom Item 13" }, // カスタム項目13
  custom14: { i: "custom14", d: "Custom Item 14" }, // カスタム項目14
  custom15: { i: "custom15", d: "Custom Item 15" }, // カスタム項目15
  custom16: { i: "custom16", d: "Custom Item 16" }, // カスタム項目16
  custom17: { i: "custom17", d: "Custom Item 17" }, // カスタム項目17
  custom18: { i: "custom18", d: "Custom Item 18" }, // カスタム項目18
  custom19: { i: "custom19", d: "Custom Item 19" }, // カスタム項目19
  custom20: { i: "custom20", d: "Custom Item 20" }, // カスタム項目20
  custom21: { i: "custom21", d: "Custom Item 21" }, // カスタム項目21
  custom22: { i: "custom22", d: "Custom Item 22" }, // カスタム項目22
  custom23: { i: "custom23", d: "Custom Item 23" }, // カスタム項目23
  custom24: { i: "custom24", d: "Custom Item 24" }, // カスタム項目24
  custom25: { i: "custom25", d: "Custom Item 25" }, // カスタム項目25
  custom26: { i: "custom26", d: "Custom Item 26" }, // カスタム項目26
  custom27: { i: "custom27", d: "Custom Item 27" }, // カスタム項目27
  custom28: { i: "custom28", d: "Custom Item 28" }, // カスタム項目28
  custom29: { i: "custom29", d: "Custom Item 29" }, // カスタム項目29
  custom30: { i: "custom30", d: "Custom Item 30" }, // カスタム項目30
  custom31: { i: "custom31", d: "Custom Item 31" }, // カスタム項目31
  custom32: { i: "custom32", d: "Custom Item 32" }, // カスタム項目32
  custom33: { i: "custom33", d: "Custom Item 33" }, // カスタム項目33
  custom34: { i: "custom34", d: "Custom Item 34" }, // カスタム項目34
  custom35: { i: "custom35", d: "Custom Item 35" }, // カスタム項目35
  custom36: { i: "custom36", d: "Custom Item 36" }, // カスタム項目36
  custom37: { i: "custom37", d: "Custom Item 37" }, // カスタム項目37
  custom38: { i: "custom38", d: "Custom Item 38" }, // カスタム項目38
  custom39: { i: "custom39", d: "Custom Item 39" }, // カスタム項目39
  custom40: { i: "custom40", d: "Custom Item 40" }, // カスタム項目40

  dateValue: { i: "dateValue", d: "Date" }, // 日付

  elementarySchoolEntranceDate: {
    i: "elementarySchoolEntranceDate",
    d: "Elementary School Entrance Date",
  }, // 小学校入学日
  email: { i: "email", d: "Email" }, // メールアドレス
  email1: { i: "email1", d: "Email 1" }, // メールアドレス1
  email2: { i: "email2", d: "Email 2" }, // メールアドレス2
  emergencyContactPhone1: {
    i: "emergencyContactPhone1",
    d: "Emergency Contact Phone 1",
  }, // 緊急連絡先-電話番号1
  emergencyContactPhone2: {
    i: "emergencyContactPhone2",
    d: "Emergency Contact Phone 2",
  }, // 緊急連絡先-電話番号2
  emergencyContactPhone3: {
    i: "emergencyContactPhone3",
    d: "Emergency Contact Phone 3",
  }, // 緊急連絡先-電話番号3
  emergencyContactRelationship1: {
    i: "emergencyContactRelationship1",
    d: "Emergency Contact Relationship 1",
  }, // 緊急連絡先-続柄1
  emergencyContactRelationship2: {
    i: "emergencyContactRelationship2",
    d: "Emergency Contact Relationship 2",
  }, // 緊急連絡先-続柄2
  emergencyContactRelationship3: {
    i: "emergencyContactRelationship3",
    d: "Emergency Contact Relationship 3",
  }, // 緊急連絡先-続柄3
  endDate: { i: "endDate", d: "End Date" }, // 終了日付

  familyName: { i: "familyName", d: "Family Name" }, // 姓
  familyName1: { i: "familyName1", d: "Family Name 1" }, // 姓1
  familyName2: { i: "familyName2", d: "Family Name 2" }, // 姓2
  familyNameKana: { i: "familyNameKana", d: "Family Name (Kana)" }, // 姓かな
  female: { i: "female", d: "Female" }, // 女性
  foodAllergy: { i: "foodAllergy", d: "Food Allergy" }, // 食物アレルギー
  foreignType: { i: "foreignType", d: "Foreign Type" }, // 外国人タイプ

  givenName: { i: "givenName", d: "Given Name" }, // 名
  givenName1: { i: "givenName1", d: "Given Name 1" }, // 名1
  givenName2: { i: "givenName2", d: "Given Name 2" }, // 名2
  givenNameKana: { i: "givenNameKana", d: "Given Name (Kana)" }, // 名かな
  grade: { i: "grade", d: "Grade" }, // 学年
  gradeNumber: { i: "gradeNumber", d: "Grade" }, // 学年
  graduationDate: { i: "graduationDate", d: "Graduation Date" }, // 卒業日
  guardian: { i: "guardian", d: "Guardian" }, // 保護者
  guardianCSV: { i: "guardianCSV", d: "Guardian CSV" }, // 保護者CSV
  googleMail: { i: "googleMail", d: "Google Mail" }, // Google Mail

  hasLunch: { i: "hasLunch", d: "Orders Lunch (Y/N)" }, // ランチ有無
  health: { i: "health", d: "Health" }, // 健康状態
  homeDoctor: { i: "homeDoctor", d: "Home Doctor" }, // かかりつけ医

  inChargeType: { i: "inChargeType", d: "In Charge Type" }, // 担任タイプ
  inoculation: { i: "inoculation", d: "Inoculation" }, // 予防接種

  job1: { i: "job1", d: "Job 1" }, // 職業1
  job2: { i: "job2", d: "Job 2" }, // 職業2
  juniorHighSchoolEntranceDate: {
    i: "juniorHighSchoolEntranceDate",
    d: "Junior High School Entrance Date",
  }, // 中学校入学日

  kindergarten: { i: "kindergarten", d: "Kindergarten" }, // 出身園
  kindergartenEntranceDate: {
    i: "kindergartenEntranceDate",
    d: "Kindergarten Entrance Date",
  }, // 幼稚園入園日

  landlinePhone: { i: "landlinePhone", d: "Phone (Landline)" }, // 固定電話番号
  landlinePhone1: { i: "landlinePhone1", d: "Phone 1 (Landline)" }, // 固定電話番号1
  landlinePhone2: { i: "landlinePhone2", d: "Phone 2 (Landline)" }, // 固定電話番号2

  male: { i: "male", d: "Male" }, // 男性
  medicalHistory: { i: "medicalHistory", d: "Medical History" }, // 既往歴
  mobilePhone: { i: "mobilePhone", d: "Mobile Phone" }, // 携帯電話番号
  mobilePhone1: { i: "mobilePhone1", d: "Mobile Phone 1" }, // 携帯電話番号1
  mobilePhone2: { i: "mobilePhone2", d: "Mobile Phone 2" }, // 携帯電話番号2

  name: { i: "name", d: "Name" }, // 表示名
  name1: { i: "name1", d: "Name 1" }, // 表示名1
  name2: { i: "name2", d: "Name 2" }, // 表示名2
  nameKana: { i: "nameKana", d: "Name (Kana)" }, // 表示名かな
  nameKana1: { i: "nameKana1", d: "Name 1 (Kana)" }, // 表示名かな1
  nameKana2: { i: "nameKana2", d: "Name 2 (Kana)" }, // 表示名かな2
  nameRome: { i: "nameRome", d: "Name (Romaji)" }, // 表示名ローマ字
  nearestStation: { i: "nearestStation", d: "Nearest Station" }, // 最寄り駅
  nickname: { i: "nickname", d: "Nickname" }, // ニックネーム
  nicknameKana: { i: "nicknameKana", d: "Nickname (Kana)" }, // ニックネームかな
  no: { i: "no", d: "No" }, // いいえ
  normalBodyTemperature: {
    i: "normalBodyTemperature",
    d: "Normal Body Temperature",
  }, // 平熱
  notes: { i: "notes", d: "Notes" }, // 備考
  note01: { i: "note01", d: "Note 01" }, // 備考1
  note02: { i: "note02", d: "Note 02" }, // 備考2
  note03: { i: "note03", d: "Note 03" }, // 備考3
  note04: { i: "note04", d: "Note 04" }, // 備考4
  note05: { i: "note05", d: "Note 05" }, // 備考5
  note06: { i: "note06", d: "Note 06" }, // 備考6
  note07: { i: "note07", d: "Note 07" }, // 備考7
  note08: { i: "note08", d: "Note 08" }, // 備考8
  note09: { i: "note09", d: "Note 09" }, // 備考9
  note10: { i: "note10", d: "Note 10" }, // 備考10

  other: { i: "other", d: "Other" }, // その他
  otherAllergy: { i: "otherAllergy", d: "Other Allergy" }, // その他アレルギー

  pictureGcsUrl: { i: "pictureGcsUrl", d: "Picture" }, // 写真
  pictureGcsUrl1: { i: "pictureGcsUrl1", d: "Picture 1" }, // 写真1
  pictureGcsUrl2: { i: "pictureGcsUrl2", d: "Picture 2" }, // 写真2
  photoUrl: { i: "photoUrl", d: "Icon" }, // アイコンURL
  previousSchool: { i: "previousSchool", d: "Previous School" }, // ひとつ前の学校
  profile: { i: "profile", d: "Profile" }, // プロフィール

  religion: { i: "religion", d: "Religion" }, // 宗教・宗派

  sex: { i: "sex", d: "Sex" }, // 性別
  schoolLogo: { i: "schoolLogo", d: "School Logo" }, // 学校ロゴ
  schoolName: { i: "schoolName", d: "School Name" }, // 学校名
  schoolType: { i: "schoolType", d: "School Type" }, // 学校タイプ
  schoolYear: { i: "schoolYear", d: "School Year" }, // 年度
  staff: { i: "staff", d: "Staff" }, // スタッフ
  startDate: { i: "startDate", d: "Start Date" }, // 開始日付
  student: { i: "student", d: "Student" }, // 児童生徒
  studentClass: { i: "studentClass", d: "Class" }, // クラス
  studentCSV: { i: "studentCSV", d: "Student CSV" }, // 児童生徒CSV
  studentCustomItems: { i: "studentCustomItems", d: "Student Custom Items" }, // 児童生徒カスタム項目
  studentNumber: { i: "studentNumber", d: "Student Number" }, // 出席番号

  teacher: { i: "teacher", d: "Staff" }, // 教職員
  teacherClass: { i: "teacherClass", d: "Class" }, // クラス
  teacherCSV: { i: "teacherCSV", d: "Staff CSV" }, // 教職員CSV
  teacherType: { i: "teacherType", d: "Teacher Type" }, // 教職員タイプ
  term: { i: "term", d: "Term" }, // 学期
  termNumber: { i: "termNumber", d: "Term Number" }, // 学期番号
  transferDate: { i: "transferDate", d: "Transfer Date" }, // 転学日

  userId: { i: "userId", d: "User ID" }, // ユーザーID
  userType: { i: "userType", d: "User Type" }, // ユーザータイプ

  workPlace1: { i: "workPlace1", d: "Work Place 1" }, // 勤務地1
  workPlace2: { i: "workPlace2", d: "Work Place 2" }, // 勤務地2

  yes: { i: "yes", d: "Yes" }, // はい

  zipcode: { i: "zipcode", d: "Postal Code" }, // 郵便番号
} as const;

export type Names = typeof names;
export type ObjectInternalName = Names[keyof Names]["i"];
export type ObjectDisplayName = Names[keyof Names]["d"];

export const namesArray = Object.values(names);
