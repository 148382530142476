import {
  TeacherType,
  teacherTypeFromStringOrError,
} from "@/ts/objects/value/teacher-type";
import { combineResults, Result } from "@/ts/app/result";
import {
  dropAllUndefinedFields,
  isNullish,
  isStringBlank,
  transformIfNotNullish,
  typedObjectKeys,
} from "@/ts/utils/common-util";
import {
  errors,
  handleThrownError,
  ThrowableAppError,
} from "@/ts/app/error/app-error";
import { Teacher, UpdatingTeacher } from "@/ts/objects/entity/teacher";
import { names, ObjectInternalName } from "@/ts/app/object-name";
import {
  ExportingRawCSVRow,
  ImportedRawCSVRow,
} from "@/ts/app/columns/csv/raw-csv";
import { validateAndRenameColsOfRow } from "@/ts/app/columns/csv/csv";
import pick from "lodash/pick";
import {
  TeacherListColumnId,
  teacherListColumnIds,
} from "@/ts/app/columns/user-list/teacher-list-columns";
import { schoolTypes } from "@/ts/objects/value/school-type";

export type ExportingRawTeacherCSVRow = Pick<
  ExportingRawCSVRow,
  TeacherListColumnId
>;

export type TeacherCSVFile = {
  filename: string;
  rows: TeacherCSVRow[];
};

/**
 * 教職員のCSV行。
 * 各列の値について、 undefined = 列自体が存在しない, null = 列は存在するが値がnull
 */
export type TeacherCSVRow = {
  rowNumber: number;

  userId: string;
  googleMail?: string;
  name?: string;

  nameKana?: string;
  familyName?: string;
  givenName?: string;

  teacherType?: TeacherType;
  zipcode?: string;
  address?: string;
  email?: string;
  mobilePhone?: string;
  landlinePhone?: string;
  foreignType?: string;
};

export function importedToTeacherCSVRow(
  filename: string,
  rowNumber: number,
  rawRow: ImportedRawCSVRow,
  nameToId: Map<string, ObjectInternalName>,
): Result<TeacherCSVRow> {
  const result = validateAndRenameColsOfRow(
    names.teacherCSV,
    rawRow,
    nameToId,
    teacherListColumnIds,
  );
  if (!result.ok) {
    return {
      ok: false,
      error: result.error,
    };
  }

  const row = result.data;

  try {
    const userId = row["userId"];
    if (isStringBlank(userId))
      throw new ThrowableAppError(
        errors.validationFailed.nullValue(names.userId),
      );

    const parsed: TeacherCSVRow = {
      rowNumber,

      userId: userId ?? "",
      googleMail: row["googleMail"],
      name: row["name"],

      nameKana: row["nameKana"],
      familyName: row["familyName"],
      givenName: row["givenName"],
      teacherType:
        transformIfNotNullish(
          row["teacherType"],
          teacherTypeFromStringOrError,
        ) ?? undefined,
      zipcode: row["zipcode"],
      address: row["address"],
      email: row["email"],
      mobilePhone: row["mobilePhone"],
      landlinePhone: row["landlinePhone"],
      foreignType: row["foreignType"],
    };
    return { ok: true, data: parsed };
  } catch (e) {
    const appError = handleThrownError("toTeacherCSVRow", e);
    return {
      ok: false,
      error: errors.readCSVFailed(
        `${filename}: line ${rowNumber}: ${appError.name}: ${appError.internalMessage}`,
        `${filename}: ${rowNumber}行目: ${appError.displayMessage}`,
      ),
    };
  }
}

export function updateTeacherWithCSVRow(
  filename: string,
  t: Teacher,
  row: TeacherCSVRow,
): Result<UpdatingTeacher> {
  if (row.userId !== t.userId)
    throw new Error(
      `updateTeacherWithCSVRow: row userId ${row.userId} and ${names.teacher.i} userId ${t.userId} must be same`,
    );

  try {
    const partialTeacher: Partial<Teacher> = dropAllUndefinedFields({
      name: row.name,

      nameKana: row.nameKana,
      familyName: row.familyName,
      givenName: row.givenName,

      teacherType: row.teacherType,
      zipcode: row.zipcode,
      address: row.address,
      email: row.email,
      mobilePhone: row.mobilePhone,
      landlinePhone: row.landlinePhone,
      foreignType: row.foreignType,
    });
    return {
      ok: true,
      data: {
        teacher: { ...t, ...partialTeacher },
        updatingFields: typedObjectKeys(partialTeacher),
      },
    };
  } catch (e) {
    const appError = handleThrownError("updateTeacherWithCSVRow", e);
    return {
      ok: false,
      error: errors.readCSVFailed(
        `${filename}: line ${row.rowNumber}: ${appError.name}: ${appError.internalMessage}`,
        `${filename}: ${row.rowNumber}行目: ${appError.displayMessage}`,
      ),
    };
  }
}

export function updateTeachersWithCSVRows(
  filename: string,
  teachers: Teacher[],
  rows: TeacherCSVRow[],
): Result<UpdatingTeacher[]> {
  try {
    const updatingTeachers = rows.map((row) => {
      const teacher = teachers.find((t) => t.userId === row.userId);
      if (isNullish(teacher))
        throw new ThrowableAppError(
          errors.readCSVFailed(
            `${names.teacher.i} ${row.userId} not found`,
            `指定した${names.teacher.d}が見つかりません。(${names.userId.d}=${row.userId})`,
          ),
        );
      return updateTeacherWithCSVRow(filename, teacher, row);
    });
    return combineResults(updatingTeachers);
  } catch (e) {
    const appError = handleThrownError("updateTeachersWithCSVRows", e);
    return { ok: false, error: appError };
  }
}

/**
 * teacherをCSVエクスポート用のオブジェクトに詰め替える。
 */
export function teacherToExportingCSVRow(
  t: Teacher,
  schoolYear: number,
  columnIds: readonly TeacherListColumnId[],
): ExportingRawTeacherCSVRow {
  const row: Required<ExportingRawTeacherCSVRow> = {
    userId: t.userId,
    googleMail: t.googleMail,
    name: t.name,

    classesOfTheYear: t.classes
      .filter((c) => c.schoolYear === schoolYear)
      .map(
        (c) =>
          `${schoolTypes[c.grade.schoolType]}${c.grade.gradeNumber}年${
            c.name
          }組(${c.inChargeType})`,
      )
      .join(","),

    nameKana: t.nameKana,
    familyName: t.familyName,
    givenName: t.givenName,

    teacherType: t.teacherType,
    zipcode: t.zipcode,
    address: t.address,
    email: t.email,
    mobilePhone: t.mobilePhone,
    landlinePhone: t.landlinePhone,
    foreignType: t.foreignType,
  };

  return pick(row, columnIds);
}
