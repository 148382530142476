
import { defineComponent, PropType } from "vue";
import BaseSmallButton from "@/components/buttons/BaseSmallButton/BaseSmallButton.vue";
import { ColorLabel } from "@/ts/objects/value/color-label";

export default defineComponent({
  name: "RightButton",
  components: { BaseSmallButton },
  props: {
    color: {
      type: String as PropType<ColorLabel>,
      default: "gray",
      required: false,
    },
    colorOnHover: {
      type: String as PropType<ColorLabel>,
      default: "dark-gray",
      required: false,
    },
    backgroundColorOnHover: {
      type: String as PropType<ColorLabel>,
      default: "gray-transparent",
      required: false,
    },
    iconSize: { type: Number, default: 12, required: false },
    size: { type: Number, default: 20, required: false },
    onClick: { type: Function as PropType<() => void>, required: true },
  },
});
