
import { computed, defineComponent, ref, watch, watchEffect } from "vue";
import StudentImport from "@/views/student/StudentImport/StudentImport.vue";
import { useAppStore } from "@/store/app-store";
import { hasValue, isNullish } from "@/ts/utils/common-util";
import {
  importedToStudentCSVRow,
  StudentCSVFile,
  StudentCSVRow,
  updateStudentsWithCSVRows,
} from "@/ts/app/columns/csv/student-csv";
import { useStudentStore } from "@/store/student-store";
import { useRouter } from "vue-router";
import { useUserService } from "@/composables/provide-user-service";
import { useDebouncedDataLoading } from "@/composables/use-debounced-data-loading";
import { Student, updatingStudentToPartial } from "@/ts/objects/entity/student";
import { Class } from "@/ts/objects/entity/class";
import { useClassStore } from "@/store/class-store";
import {
  LoadableData,
  loadableDataNull,
  mapData,
} from "@/ts/app/loadable-data";
import log from "loglevel";
import { names } from "@/ts/app/object-name";
import { parseCSV } from "@/ts/utils/app-util";
import { AppError } from "@/ts/app/error/app-error";
import { useAppToast } from "@/composables/use-app-toast";
import { getStudentColumnDefs } from "@/ts/app/columns/def/student/student-column";

// TODO 特に、(まだ)存在しない児童生徒をインポートしようとしたとき、分かりやすいエラーメッセージが出るようにする。
export default defineComponent({
  name: "StudentImportContainer",
  components: { StudentImport },
  setup() {
    const appStore = useAppStore();
    const studentStore = useStudentStore();
    const classStore = useClassStore();
    const userService = useUserService();
    const router = useRouter();
    const { showError, showSuccess } = useAppToast();

    const showErrorImportFailed = (error: AppError) => {
      showError(
        `${names.studentCSV.d}のインポートに失敗しました: ${error.displayMessage}`,
      );
    };

    const csvFile = ref<StudentCSVFile | null>(null);

    const fileLoaded = computed(() => hasValue(csvFile.value));
    const saving = ref(false); // セーブ中(confirm後)はtrue。trueの間、操作できない。

    const currentSchoolYear = appStore.currentSchoolYear;

    const schoolYear = ref(currentSchoolYear);
    const onInputSchoolYear = (_schoolYear: number) => {
      if (fileLoaded.value || saving.value) return;

      schoolYear.value = _schoolYear;
    };
    watch(
      schoolYear,
      () => classStore.fetchClasses(userService, schoolYear.value),
      { immediate: true },
    );

    const classesOfSchoolYear = computed<LoadableData<Class[]>>(
      () =>
        classStore.schoolYearToClasses[schoolYear.value] ?? loadableDataNull(),
    );

    const [studentsBefore, debouncedLoadStudentsBefore, clearStudentsBefore] =
      useDebouncedDataLoading(
        null,
        (args: { userIds: string[] }) => {
          return userService.listStudentsByPost(args.userIds);
        },
        200,
        true,
        true,
        showErrorImportFailed,
      );

    const [studentsAfter, debouncedLoadStudentsAfter, clearStudentsAfter] =
      useDebouncedDataLoading(
        null,
        async (args: {
          filename: string;
          students: Student[];
          rows: StudentCSVRow[];
          schoolYear: number;
          classesOfSchoolYear: Class[];
        }) => {
          return updateStudentsWithCSVRows(
            args.filename,
            args.students,
            args.rows,
            args.schoolYear,
            args.classesOfSchoolYear,
          );
        },
        200,
        true,
        true,
        showErrorImportFailed,
      );
    watchEffect(() => {
      log.debug(`StudentImportContainer: watchEffect: Triggered.`);

      const _csvFile = csvFile.value;
      const _studentsBefore = studentsBefore.value;
      const _schoolYear = schoolYear.value;
      const _classesOfSchoolYear = classesOfSchoolYear.value;
      if (
        isNullish(_csvFile) ||
        !_studentsBefore.hasFreshData ||
        !_classesOfSchoolYear.hasFreshData
      ) {
        log.debug(`StudentImportContainer: watchEffect: clearStudentsAfter`);
        clearStudentsAfter();
        return;
      }

      log.debug(
        `StudentImportContainer: watchEffect: debouncedLoadStudentsAfter`,
      );
      debouncedLoadStudentsAfter({
        filename: _csvFile.filename,
        students: _studentsBefore.data,
        rows: _csvFile.rows,
        schoolYear: _schoolYear,
        classesOfSchoolYear: _classesOfSchoolYear.data,
      });
    });

    const allColumnDefs = computed(() =>
      getStudentColumnDefs(
        schoolYear.value,
        studentStore.customColumnNames.data,
      ),
    );
    const onSelectFile = async (file: File) => {
      if (saving.value) return;

      const _customColumnNames = studentStore.customColumnNames;
      if (!_customColumnNames.loaded) {
        log.debug(
          `StudentImportContainer: onSelectFile: ${names.studentCustomItems.i} not yet loaded. Aborting.`,
        );
        return;
      }

      const result = await parseCSV(
        file,
        importedToStudentCSVRow,
        allColumnDefs.value,
        true,
      );
      if (!result.ok) {
        showErrorImportFailed(result.error);
        return;
      }

      const _csvFile: StudentCSVFile = {
        filename: file.name,
        rows: result.data,
      };
      csvFile.value = _csvFile;

      debouncedLoadStudentsBefore({
        userIds: _csvFile.rows.map((r) => r.userId),
      });
    };
    const onUnselectFile = () => {
      if (saving.value) return;

      csvFile.value = null;
      clearStudentsBefore();
      clearStudentsAfter();
    };

    const columnVisibility = computed(() => studentStore.columnVisibility);
    const isColumnSelectOpen = ref(true);
    const toggleColumnSelectVisibility = () =>
      (isColumnSelectOpen.value = !isColumnSelectOpen.value);
    const onChangeColumnVisibility = studentStore.updateColumnVisibility;

    const onConfirmImport = async () => {
      if (saving.value) return;

      const _studentsAfter = studentsAfter.value;
      if (!_studentsAfter.hasFreshData) {
        return;
      }

      saving.value = true;

      const partialStudents = _studentsAfter.data.map((s) =>
        updatingStudentToPartial(s),
      );
      const result = await userService.batchPatchStudent(partialStudents);
      if (!result.ok) {
        // TODO エラーハンドリング
        showErrorImportFailed(result.error);
        saving.value = false;
        return;
      }

      showSuccess(
        `${partialStudents.length}件の${names.student.d}データをインポートしました。`,
      );

      csvFile.value = null;
      clearStudentsBefore();
      clearStudentsAfter();

      saving.value = false;
    };

    const onClickBack = () => router.back();

    return {
      schoolYear,
      onInputSchoolYear,
      saving,

      fileLoaded: computed(() => hasValue(csvFile.value)),

      studentsAfter: computed(() =>
        mapData(studentsAfter.value, (d) => d.map((s) => s.student)),
      ),

      onSelectFile,
      onUnselectFile,

      columnVisibility,
      isColumnSelectOpen,
      toggleColumnSelectVisibility,
      onChangeColumnVisibility,

      onConfirmImport,
      onClickBack,
    };
  },
});
