import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StudentCustomColumnEdit = _resolveComponent("StudentCustomColumnEdit")!

  return (_openBlock(), _createBlock(_component_StudentCustomColumnEdit, {
    "is-admin": _ctx.isAdmin,
    "custom-column-names": _ctx.customColumnNames,
    "unsaved-data": _ctx.unsavedData,
    "on-input": _ctx.onInput,
    "on-click-back": _ctx.onClickBack,
    "start-editing": _ctx.startEditing,
    "cancel-editing": _ctx.cancelEditing,
    save: _ctx.save
  }, null, 8, ["is-admin", "custom-column-names", "unsaved-data", "on-input", "on-click-back", "start-editing", "cancel-editing", "save"]))
}