import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseLargeButton = _resolveComponent("BaseLargeButton")!

  return (_openBlock(), _createBlock(_component_BaseLargeButton, {
    label: "Confirm Import",
    color: "white",
    "background-color": "dark-red",
    "background-color-on-hover": undefined,
    "border-color": "ruby",
    width: 180,
    enabled: _ctx.enabled,
    "on-click": _ctx.onClick
  }, null, 8, ["enabled", "on-click"]))
}