import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9321b1a6"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "base-custom-dropdown-options-container",
    style: _normalizeStyle(_ctx.styles)
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
      return (_openBlock(), _createElementBlock("button", {
        key: item.value,
        type: "button",
        class: "item",
        onClick: () => _ctx.onSelect(item.value)
      }, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(item.label), 1)
      ], 8, _hoisted_1))
    }), 128))
  ], 4))
}