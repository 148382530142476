export const exportDropdownOptionValues = [
  "export-selected-columns",
  "export-all-columns",
] as const;

export type ExportDropdownOptionValue =
  typeof exportDropdownOptionValues[number];

export function isExportDropdownOptionValue(
  value: unknown,
): value is ExportDropdownOptionValue {
  return (
    typeof value === "string" &&
    exportDropdownOptionValues.some((v) => v === value)
  );
}
