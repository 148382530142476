import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createBlock(_component_BaseButton, {
    class: "class-details-table-add-button-container",
    width: 48,
    height: 15,
    "padding-right": 7,
    "padding-left": 7,
    "icon-padding-bottom": 0,
    "label-padding-bottom": 0,
    icon: "plus",
    "icon-size": 9,
    label: "Add",
    "font-size": 10,
    color: "light-black",
    bold: false,
    "border-width": 0,
    "border-radius": 9,
    "background-color": "dark-white",
    enabled: _ctx.enabled,
    "on-click": _ctx.onClick
  }, null, 8, ["enabled", "on-click"]))
}