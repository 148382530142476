
import { computed, defineComponent, PropType } from "vue";
import UserListTableHeaderCell from "@/components/table/list/UserListTableHeaderCell/UserListTableHeaderCell.vue";
import { UserColumnDef } from "@/ts/app/columns/def/user-column-def";
import { ObjectInternalName } from "@/ts/app/object-name";

export default defineComponent({
  name: "UserListTableHeader",
  components: { UserListTableHeaderCell },
  props: {
    horizontalMargin: { type: Number, required: true },
    iconCellWidth: { type: Number, required: true },
    nameCellWidth: { type: Number, required: true },
    height: { type: Number, required: true },

    allColumns: {
      type: Array as PropType<UserColumnDef<ObjectInternalName, any, any>[]>,
      required: true,
    },
    columnVisibility: {
      type: Object as PropType<Record<string, boolean>>,
      required: true,
    },
  },
  setup(props) {
    return {
      shownColumns: computed(() =>
        props.allColumns.filter((col) => props.columnVisibility[col.id]),
      ),
      styles: {
        "--horizontal-margin": `${props.horizontalMargin}px`,
        "--icon-cell-width": `${props.iconCellWidth}px`,
        "--name-cell-width": `${props.nameCellWidth}px`,
        "--height": `${props.height}px`,
      },
    };
  },
});
