
import { computed, defineComponent, PropType } from "vue";
import { ColorLabel, labelToColor } from "@/ts/objects/value/color-label";
import { hasValue } from "@/ts/utils/common-util";

export default defineComponent({
  name: "BaseSmallButton",
  props: {
    color: { type: String as PropType<ColorLabel>, default: "black" },
    /**
     * ホバー中の色。
     * 未指定の場合、colorと同じになる。
     */
    colorOnHover: { type: String as PropType<ColorLabel>, required: false },
    backgroundColor: {
      type: String as PropType<ColorLabel>,
      default: "transparent",
    },
    backgroundColorOnHover: {
      type: String as PropType<ColorLabel>,
      default: "gray-transparent",
    },

    iconType: { type: String, default: "fas" }, // fas or far
    icon: { type: String, default: null },
    iconSize: { type: Number, default: 18 },

    borderColor: { type: String as PropType<ColorLabel>, default: "gray" },
    borderRadius: { type: Number, default: 16 },
    borderWidth: { type: Number, default: 0 },

    width: { type: Number, default: 24 },
    height: { type: Number, default: 24 },
    paddingHorizontal: { type: Number, default: 8 },

    enabled: { type: Boolean, default: true },
    onClick: { type: Function as PropType<() => void>, required: true },
  },
  setup(props) {
    const color = computed<string>(() => labelToColor(props.color));
    const colorOnHover = computed<string>(() =>
      hasValue(props.colorOnHover)
        ? labelToColor(props.colorOnHover)
        : color.value,
    );

    const bgColor = computed<string>(() => labelToColor(props.backgroundColor));
    const bgColorOnHover = computed<string>(() =>
      hasValue(props.backgroundColorOnHover)
        ? labelToColor(props.backgroundColorOnHover)
        : bgColor.value,
    );

    return {
      styles: computed<Record<string, string>>(() => ({
        "--color": color.value,
        "--color-on-hover": colorOnHover.value,
        "--background-color": bgColor.value,
        "--background-color-on-hover": bgColorOnHover.value,

        "--icon-size": `${props.iconSize}px`,

        "--border-width": `${props.borderWidth}px`,
        "--border-radius": `${props.borderRadius}px`,
        "--border-color": labelToColor(props.borderColor),

        "--width": `${props.width}px`,
        "--height": `${props.height}px`,
      })),
      onClickInternal: () => {
        if (!props.enabled) return;
        props.onClick();
      },
    };
  },
});
