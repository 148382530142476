import { SchoolType } from "@/ts/objects/value/school-type";
import { hasValue, isNullish } from "@/ts/utils/common-util";
import { Grade as GradeResp } from "@/ts/api/user-service";
import { Result } from "@/ts/app/result";
import { names } from "@/ts/app/object-name";
import { errors } from "@/ts/app/error/app-error";
import {
  ElementaryGradeNumber,
  isElementaryGradeNumber,
  isJuniorhighGradeNumber,
  JuniorhighGradeNumber,
} from "@/ts/objects/value/grade-number";

export type Grade = ElementaryGrade | JuniorhighGrade;

export type ElementaryGrade = {
  readonly schoolType: "elementary";
  readonly gradeNumber: ElementaryGradeNumber;
};

export type JuniorhighGrade = {
  readonly schoolType: "juniorhigh";
  readonly gradeNumber: JuniorhighGradeNumber;
};

export const gradeValues = [
  "e1",
  "e2",
  "e3",
  "e4",
  "e5",
  "e6",
  "j1",
  "j2",
  "j3",
] as const;
export type GradeValue = typeof gradeValues[number];

export function isGradeValue(str: string): str is GradeValue {
  return gradeValues.some((v) => v === str);
}

export function gradeToGradeValue(grade: Grade): GradeValue {
  const schoolTypePart = grade.schoolType === "elementary" ? "e" : "j";
  const value = `${schoolTypePart}${grade.gradeNumber}`;
  if (!isGradeValue(value))
    throw new Error(
      `gradeToGradeValue: invalid grade: ${JSON.stringify(grade)}`,
    );
  return value;
}

export function gradeValueToGrade(value: string): Grade {
  if (!isGradeValue(value))
    throw new Error(`gradeValueToGrade: invalid grade value: ${value}`);

  const schoolTypePart = value.charAt(0);
  const schoolType = schoolTypePart === "e" ? "elementary" : "juniorhigh";

  const gradeNumberPart = value.charAt(1);
  const gradeNumber = parseInt(gradeNumberPart, 10);
  if (isNullish(gradeNumber) || isNaN(gradeNumber))
    throw new Error(`gradeValueToGrade: invalid grade value: ${value}`);

  if (schoolType === "elementary" && isElementaryGradeNumber(gradeNumber)) {
    return { schoolType, gradeNumber };
  }
  if (schoolType === "juniorhigh" && isJuniorhighGradeNumber(gradeNumber)) {
    return { schoolType, gradeNumber };
  }

  throw new Error(`gradeValueToGrade: invalid grade value: ${value}`);
}

/**
 * schoolTypeとgradeNumberから、gradeのリストを得る。
 * gradeNumberがおかしな値かどうかのバリデーションまでは行わない。
 */
export function schoolTypeAndGradeNumberToGrades(
  schoolType: SchoolType | null,
  gradeNumber: number | null,
): Grade[] {
  const schoolTypes: SchoolType[] = isNullish(schoolType)
    ? ["elementary", "juniorhigh"]
    : [schoolType];
  const elementarySchoolGradeNumbers = isNullish(gradeNumber)
    ? [1, 2, 3, 4, 5, 6]
    : [gradeNumber];
  const juniorhighSchoolGradeNumbers = isNullish(gradeNumber)
    ? [1, 2, 3]
    : [gradeNumber];

  const elementarySchoolGrades = schoolTypes.includes("elementary")
    ? elementarySchoolGradeNumbers
        .map((n) => {
          if (!isElementaryGradeNumber(n)) return null;
          return {
            schoolType: "elementary" as const,
            gradeNumber: n,
          };
        })
        .filter((g): g is ElementaryGrade => hasValue(g))
    : [];
  const juniorhighSchoolGrades = schoolTypes.includes("juniorhigh")
    ? juniorhighSchoolGradeNumbers
        .map((n) => {
          if (!isJuniorhighGradeNumber(n)) return null;
          return {
            schoolType: "juniorhigh" as const,
            gradeNumber: n,
          };
        })
        .filter((g): g is JuniorhighGrade => hasValue(g))
    : [];

  return [...elementarySchoolGrades, ...juniorhighSchoolGrades];
}

/**
 * 数値で値を得る。
 * e1 -> 1, e2 -> 2, ..., j3 -> 9
 */
export function gradeValueToIntValue(value: GradeValue): number {
  return gradeValues.findIndex((v) => v === value) + 1;
}

/**
 * 数値部分の値を得る。
 * つまり、例えば e2 も j2 も 2 を返す。
 */
export function intPartValue(value: GradeValue): number {
  switch (value) {
    case "e1":
      return 1;
    case "e2":
      return 2;
    case "e3":
      return 3;
    case "e4":
      return 4;
    case "e5":
      return 5;
    case "e6":
      return 6;
    case "j1":
      return 1;
    case "j2":
      return 2;
    case "j3":
      return 3;
  }
}

export function createGrade(
  schoolType: string | null,
  gradeNumber: number | null,
): Result<Grade> {
  const errorResult = {
    ok: false as const,
    error: errors.validationFailed.invalidValue(
      { schoolType, gradeNumber },
      names.grade,
    ),
  };

  if (isNullish(schoolType) || isNullish(gradeNumber)) return errorResult;

  if (schoolType === "elementary" && isElementaryGradeNumber(gradeNumber)) {
    return { ok: true, data: { schoolType, gradeNumber } };
  } else if (
    schoolType === "juniorhigh" &&
    isJuniorhighGradeNumber(gradeNumber)
  ) {
    return { ok: true, data: { schoolType, gradeNumber } };
  } else {
    return errorResult;
  }
}

export function createGradeOrError(
  schoolType: string | null,
  gradeNumber: number | null,
): Grade {
  const result = createGrade(schoolType, gradeNumber);
  if (!result.ok) throw new Error(result.error.internalMessage);
  return result.data;
}

export function gradeFromRespOrError(r: GradeResp): Grade {
  return createGradeOrError(r.schoolType, r.gradeNumber);
}
