
import BaseLargeButton from "@/components/buttons/BaseLargeButton/BaseLargeButton.vue";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "ConfirmImportButton",
  components: { BaseLargeButton },
  props: {
    enabled: { type: Boolean, default: true },
    onClick: { type: Function as PropType<() => void>, required: true },
  },
});
