import {
  inject,
  InjectionKey,
  onMounted,
  onUnmounted,
  provide,
  readonly,
  ref,
  Ref,
} from "vue";
import { hasValue, isNullish } from "@/ts/utils/common-util";
import { App } from "@vue/runtime-core";
import { DeepReadonly } from "@vue/reactivity";

export const cursorXKey: InjectionKey<DeepReadonly<Ref<number>>> =
  Symbol("cursorX");
export const cursorYKey: InjectionKey<DeepReadonly<Ref<number>>> =
  Symbol("cursorY");

export function provideCursorPosition(app?: App): void {
  const cursorX = ref(0);
  const cursorY = ref(0);

  const update = (e: MouseEvent) => {
    cursorX.value = e.pageX;
    cursorY.value = e.pageY;
  };
  onMounted(() => {
    window.addEventListener("mousemove", update);
  });
  onUnmounted(() => {
    window.removeEventListener("mousemove", update);
  });

  const _provide = hasValue(app) ? app.provide : provide;
  _provide(cursorXKey, readonly(cursorX));
  _provide(cursorYKey, readonly(cursorY));
}

export function useCursorPosition(): {
  cursorX: DeepReadonly<Ref<number>>;
  cursorY: DeepReadonly<Ref<number>>;
} {
  const cursorX = inject(cursorXKey);
  const cursorY = inject(cursorYKey);

  if (isNullish(cursorX) || isNullish(cursorY))
    throw new Error("injectCursorPosition: cursorX or cursorY not provided");

  return { cursorX, cursorY };
}
