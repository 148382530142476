import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c6cc58b2"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["aria-label"]
const _hoisted_2 = { class: "name-cell" }
const _hoisted_3 = { class: "value-cell" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EditableItemImage = _resolveComponent("EditableItemImage")!

  return (_openBlock(), _createElementBlock("li", {
    class: "school-edit-table-row-image-container",
    "aria-label": _ctx.schoolLogoDisplayName,
    style: _normalizeStyle(_ctx.styles)
  }, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.name), 1),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_EditableItemImage, {
        class: "editable-item",
        "gcs-url": _ctx.gcsUrl,
        editable: _ctx.editing,
        "accepted-image-types": ['jpeg', 'png'],
        "on-input": _ctx.onInput,
        "padding-top": 20,
        "padding-right": 20,
        "padding-bottom": 20,
        "padding-left": 20
      }, null, 8, ["gcs-url", "editable", "on-input"])
    ])
  ], 12, _hoisted_1))
}