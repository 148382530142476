import { Result } from "@/ts/app/result";
import { errors, ThrowableAppError } from "@/ts/app/error/app-error";
import { names, ObjectName } from "@/ts/app/object-name";
import { isNullish } from "@/ts/utils/common-util";

// TODO has-lunch-valueのテスト。booleanじゃなくしたから。特にCSVまわり。

export const hasLunchValues = {
  yes: "有",
  no: "無",
} as const;

/**
 * ランチ有無。
 */
export type HasLunchValue = keyof typeof hasLunchValues;
export type HasLunchDisplayValue = typeof hasLunchValues[HasLunchValue];

export function isHasLunchValue(value: unknown): value is HasLunchValue {
  return (
    typeof value === "string" &&
    Object.keys(hasLunchValues).some((v) => v === value)
  );
}

export function hasLunchValueToDisplayValue(
  value: HasLunchValue,
): HasLunchDisplayValue {
  return hasLunchValues[value];
}

export function displayValueToHasLunchValue(
  displayValue: string,
  objectName: ObjectName = names.hasLunch,
): Result<HasLunchValue> {
  const error = {
    ok: false,
    error: errors.validationFailed.invalidValue(displayValue, objectName),
  } as const;

  const kv = Object.entries(hasLunchValues).find(
    ([_k, v]) => v === displayValue,
  );
  if (isNullish(kv)) return error;
  const k = kv[0];
  if (!isHasLunchValue(k)) return error;
  return { ok: true, data: k };
}

export function displayValueToHasLunchValueOrNull(
  displayValue: string,
): HasLunchValue | null {
  const result = displayValueToHasLunchValue(displayValue);
  if (!result.ok) return null;
  return result.data;
}

export function displayValueToHasLunchValueOrError(
  displayValue: string,
  objectName: ObjectName = names.hasLunch,
): HasLunchValue {
  const result = displayValueToHasLunchValue(displayValue, objectName);
  if (!result.ok) throw new ThrowableAppError(result.error);
  return result.data;
}

export function hasLunchValueFromString(value: string): Result<HasLunchValue> {
  if (isHasLunchValue(value)) {
    return { ok: true, data: value };
  }
  return {
    ok: false,
    error: errors.validationFailed.invalidValue(value, names.hasLunch),
  };
}

export function hasLunchValueFromStringOrError(value: string): HasLunchValue {
  const result = hasLunchValueFromString(value);
  if (result.ok) {
    return result.data;
  } else {
    throw new ThrowableAppError(result.error);
  }
}
