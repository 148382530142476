import { names } from "@/ts/app/object-name";
import { StudentColumnVisibilityAll } from "@/ts/app/columns/visibility/student-column-visibility";
import { typedObjectEntries } from "@/ts/utils/common-util";

export const studentListProfileColumns = [
  names.userId,
  names.googleMail,

  names.pictureGcsUrl,
  names.name,
  names.nameKana,
  names.nameRome,
  names.familyName,
  names.familyNameKana,
  names.givenName,
  names.givenNameKana,

  names.schoolType,
  names.gradeNumber,
  names.className,
  names.studentNumber,

  names.sex,
  names.nickname,
  names.nicknameKana,
  names.address,
  names.mobilePhone,
  names.birthday,
  names.zipcode,
  names.country,
  names.religion,
  names.hasLunch,
  names.email,
  names.commutingBy,
  names.bus,
  names.nearestStation,
  names.guardian,
  names.applicationDate,
  names.elementarySchoolEntranceDate,
  names.transferDate,
  names.graduationDate,
  names.kindergartenEntranceDate,
  names.juniorHighSchoolEntranceDate,
  names.previousSchool,
  names.kindergarten,

  names.activityMonday,
  names.activityTuesday,
  names.activityWednesday,
  names.activityThursday,
  names.activityFriday,
] as const;

export const studentListProfileColumnIds = studentListProfileColumns.map(
  (c) => c.i,
);

export type StudentListProfileColumnId =
  typeof studentListProfileColumnIds[number];

export const studentListHealthColumns = [
  names.bloodType,
  names.normalBodyTemperature,
  names.inoculation,
  names.medicalHistory,
  names.homeDoctor,
  names.foodAllergy,
  names.anaphylaxis,
  names.otherAllergy,
  names.asthma,
] as const;

export const studentListHealthColumnIds = studentListHealthColumns.map(
  (c) => c.i,
);

export type StudentListHealthColumnId =
  typeof studentListHealthColumnIds[number];

export const studentListCustomColumns = [
  names.custom01,
  names.custom02,
  names.custom03,
  names.custom04,
  names.custom05,
  names.custom06,
  names.custom07,
  names.custom08,
  names.custom09,
  names.custom10,
  names.custom11,
  names.custom12,
  names.custom13,
  names.custom14,
  names.custom15,
  names.custom16,
  names.custom17,
  names.custom18,
  names.custom19,
  names.custom20,
  names.custom21,
  names.custom22,
  names.custom23,
  names.custom24,
  names.custom25,
  names.custom26,
  names.custom27,
  names.custom28,
  names.custom29,
  names.custom30,
  names.custom31,
  names.custom32,
  names.custom33,
  names.custom34,
  names.custom35,
  names.custom36,
  names.custom37,
  names.custom38,
  names.custom39,
  names.custom40,
];

export const studentListCustomColumnIds = studentListCustomColumns.map(
  (c) => c.i,
);

export type StudentListCustomColumnId =
  typeof studentListCustomColumnIds[number];

export const studentListColumns = [
  ...studentListProfileColumns,
  ...studentListHealthColumns,
  ...studentListCustomColumns,
] as const;

export const studentListColumnIds = studentListColumns.map((c) => c.i);

export type StudentListColumnId = typeof studentListColumnIds[number];

export function studentColumnVisibilityToListColumnIds(
  v: StudentColumnVisibilityAll,
): StudentListColumnId[] {
  const profileColumns = typedObjectEntries(v.profileColumnVisibility.state);
  const healthColumns = typedObjectEntries(v.healthColumnVisibility.state);
  const customColumns = typedObjectEntries(v.customColumnVisibility.state);

  const checkedColIds = [...profileColumns, ...healthColumns, ...customColumns]
    .filter(([_, checked]) => checked)
    .map(([colId, _]) => colId);

  // 並び順を正しくするため、filterし直す。
  return [
    ...studentListProfileColumnIds,
    ...studentListHealthColumnIds,
    ...studentListCustomColumnIds,
  ].filter((colId) => checkedColIds.includes(colId));
}
