import { withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-429d52a9"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["name", "checked", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", {
    class: _normalizeClass(["base-checkbox-container", { enabled: _ctx.enabled }]),
    style: _normalizeStyle(_ctx.styles)
  }, [
    (_openBlock(), _createElementBlock("input", {
      key: _ctx.checked,
      type: "checkbox",
      class: "checkbox",
      name: _ctx.label,
      checked: _ctx.checked,
      disabled: !_ctx.enabled,
      onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onInputInternal && _ctx.onInputInternal(...args)), ["prevent"]))
    }, null, 8, _hoisted_1)),
    _createElementVNode("span", {
      class: _normalizeClass(["checkbox-body", { checked: _ctx.checked, strikethrough: !_ctx.checked && _ctx.strikethroughOnUnchecked }])
    }, _toDisplayString(_ctx.label), 3)
  ], 6))
}