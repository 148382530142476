import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.onCursor)
    ? (_openBlock(), _createBlock(_Teleport, {
        key: 0,
        to: "body"
      }, [
        _createElementVNode("div", {
          class: "text-balloon-container fixed",
          style: _normalizeStyle(_ctx.styles)
        }, _toDisplayString(_ctx.text), 5)
      ]))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: "text-balloon-container",
        style: _normalizeStyle(_ctx.styles)
      }, _toDisplayString(_ctx.text), 5))
}