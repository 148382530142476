import { UserColumnVisibility } from "@/ts/app/columns/visibility/user-column-visibility";
import {
  teacherProfileColumnGroupName,
  TeacherProfileColumnGroupName,
} from "@/ts/app/columns/def/teacher/teacher-profile-column";
import { PartialDeep } from "type-fest";
import { TeacherListProfileColumnId } from "@/ts/app/columns/user-list/teacher-list-columns";

export type TeacherColumnVisibilityAll = {
  profileColumnVisibility: TeacherProfileColumnVisibility;
};

export type TeacherColumnVisibility = TeacherProfileColumnVisibility;

export type TeacherProfileColumnVisibility = UserColumnVisibility<
  TeacherProfileColumnGroupName,
  TeacherListProfileColumnId
>;

/**
 * デフォルトの教職員プロフィール列表示状態を取得する。
 * @param checked trueならすべてチェック済、falseならすべて未チェックの状態を返す。
 */
export function defaultTeacherProfileColumnVisibility(
  checked: boolean,
): TeacherProfileColumnVisibility {
  return {
    groupName: teacherProfileColumnGroupName,
    state: {
      userId: checked,
      googleMail: checked,
      name: checked,

      classesOfTheYear: checked,

      nameKana: checked,
      familyName: checked,
      givenName: checked,

      teacherType: checked,
      zipcode: checked,
      address: checked,
      email: checked,
      mobilePhone: checked,
      landlinePhone: checked,
      foreignType: checked,
    },
  };
}

/**
 * デフォルトの教職員列表示状態を取得する。
 * @param checked trueならすべてチェック済、falseならすべて未チェックの状態を返す。
 */
export function defaultTeacherColumnVisibility(
  checked: boolean,
): TeacherColumnVisibilityAll {
  return {
    profileColumnVisibility: defaultTeacherProfileColumnVisibility(checked),
  };
}

export function getTeacherColumnVisibility(
  partial: PartialDeep<TeacherColumnVisibilityAll> = {},
): TeacherColumnVisibilityAll {
  return {
    profileColumnVisibility: {
      groupName: teacherProfileColumnGroupName,
      state: {
        ...defaultTeacherProfileColumnVisibility(false).state,
        ...partial?.profileColumnVisibility?.state,
      },
    },
  };
}
