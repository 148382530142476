
import { computed, defineComponent, PropType } from "vue";
import { ColorLabel, labelToColor } from "@/ts/objects/value/color-label";
import UserListTableCell from "@/components/table/list/UserListTableCell/UserListTableCell.vue";
import { UserColumn } from "@/ts/app/columns/def/user-column";
import { AnyUser } from "@/ts/objects/entity/user";
import { toUserDisplayName } from "@/ts/objects/value/user-display-name";
import { isNullish } from "@/ts/utils/common-util";
import { ObjectInternalName } from "@/ts/app/object-name";

export default defineComponent({
  name: "UserListTableRow",
  components: { UserListTableCell },
  props: {
    horizontalMargin: { type: Number, required: true },
    iconCellWidth: { type: Number, required: true },
    nameCellWidth: { type: Number, required: true },
    minHeight: { type: Number, required: true },
    maxHeight: { type: Number, required: true },

    styleType: { type: Number as PropType<0 | 1>, required: true },

    name: { type: String, required: true },
    photoUrl: { type: String, required: true },
    userColumns: {
      type: Array as PropType<UserColumn<ObjectInternalName, AnyUser, any>[]>,
      required: true,
    },
    columnVisibility: {
      type: Object as PropType<Record<string, boolean>>,
      required: true,
    },
    /**
     * 編集画面のパス。
     * 未指定の場合、編集画面へのリンクが現れない。
     */
    editPath: { type: String, required: false },

    /**
     * trueなら、ユーザー項目などをリンク化する。
     */
    linkEnabled: { type: Boolean, required: true },
  },
  setup(props) {
    const displayName = computed<{ color: ColorLabel; value: string }>(() => {
      if (isNullish(props.editPath)) {
        return toUserDisplayName(props.name, "black");
      }
      return toUserDisplayName(props.name, "link-text");
    });

    const shownColumns = computed(() =>
      props.userColumns.filter((col) => props.columnVisibility[col.id]),
    );

    return {
      displayName,
      shownColumns,

      styles: {
        "--display-name-color": labelToColor(displayName.value.color),
        "--horizontal-margin": `${props.horizontalMargin}px`,
        "--icon-cell-width": `${props.iconCellWidth}px`,
        "--name-cell-width": `${props.nameCellWidth}px`,
        "--min-height": `${props.minHeight}px`,
        "--max-height": `${props.maxHeight}px`,
      },
    };
  },
});
