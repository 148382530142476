import { CurriculumService } from "@/ts/services/curriculum-service";
import { App } from "@vue/runtime-core";
import { inject, InjectionKey, provide } from "vue";
import { hasValue, isNullish } from "@/ts/utils/common-util";

export const curriculumServiceKey: InjectionKey<CurriculumService> =
  Symbol("curriculumService");

export function provideCurriculumService(
  curriculumService: CurriculumService,
  app?: App,
): void {
  const _provide = hasValue(app) ? app.provide : provide;
  _provide(curriculumServiceKey, curriculumService);
}

export function useCurriculumService(): CurriculumService {
  const curriculumService = inject(curriculumServiceKey);

  if (isNullish(curriculumService))
    throw new Error("useCurriculumService: curriculumService not provided");

  return curriculumService;
}
