
import { computed, defineComponent, PropType, ref, watchEffect } from "vue";
import { hasValue } from "@/ts/utils/common-util";
import { ColorLabel, labelToColor } from "@/ts/objects/value/color-label";

export default defineComponent({
  name: "BaseSearchBox",
  props: {
    initialText: { type: String, default: "" }, // テキストの初期値。セットアップ時のみ使う。

    placeholder: { type: String, default: "" },
    width: { type: Number, default: 300 },
    height: { type: Number, default: 30 },
    fontSize: { type: Number, default: 14 },
    placeholderFontSize: { type: Number, default: 12 },
    borderColor: {
      type: String as PropType<ColorLabel>,
      default: "light-gray",
    },
    borderColorOnFocus: {
      type: String as PropType<ColorLabel>,
      default: "dark-gray",
    },

    enabled: { type: Boolean, default: true },

    onFocus: { type: Function as PropType<() => void> },
    onBlur: { type: Function as PropType<() => void> },
    onInput: {
      type: Function as PropType<(text: string) => void>,
      required: true,
    },
    onConfirm: { type: Function as PropType<() => void> },
    /**
     * リセット(右端のx)を押したときに呼ばれる。
     * 同時に onInput("") も呼ばれるので注意。
     */
    onReset: { type: Function as PropType<() => void> },
  },
  setup(props) {
    const focused = ref(false);
    const text = ref(props.initialText);
    watchEffect(() => {
      if (!props.enabled) return;
      props.onInput(text.value);
    });

    return {
      focused,
      text,
      hasAnyText: computed(() => text.value.length > 0),
      resetText: () => {
        text.value = "";
        if (hasValue(props.onReset)) props.onReset();
      },
      onFocusInternal: () => {
        focused.value = true;
        if (hasValue(props.onFocus)) props.onFocus();
      },
      onBlurInternal: () => {
        focused.value = false;
        if (hasValue(props.onBlur)) props.onBlur();
      },
      onConfirmInternal: () => {
        if (hasValue(props.onConfirm)) props.onConfirm();
      },
      styles: computed(() => ({
        "--width": `${props.width}px`,
        "--height": `${props.height}px`,
        "--font-size": `${props.fontSize}px`,
        "--placeholder-font-size": `${props.placeholderFontSize}px`,
        "--border-color": labelToColor(props.borderColor),
        "--border-color-on-focus": labelToColor(props.borderColorOnFocus),
      })),
    };
  },
});
