import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2b37690a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["placeholder", "value", "maxlength", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("textarea", {
    ref: "el",
    class: "auto-resize-textarea-container",
    style: _normalizeStyle({ height: `${_ctx.height}px` }),
    placeholder: _ctx.placeholder,
    value: _ctx.value,
    maxlength: _ctx.maxChars,
    onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onInputInternal && _ctx.onInputInternal(...args))),
    onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onBlur && _ctx.onBlur(...args))),
    disabled: !_ctx.enabled
  }, null, 44, _hoisted_1))
}