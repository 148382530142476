
import { computed, defineComponent, PropType, ref } from "vue";
import { isNullish } from "@/ts/utils/common-util";
import { useHovering } from "@/composables/use-hovering";

export default defineComponent({
  name: "UploadArea",
  props: {
    mainText: {
      type: String,
      default: "ここにファイルをドロップ",
      required: false,
    },
    buttonText: { type: String, default: "ファイルを選択", required: false },
    subText: { type: String, default: null, required: false },
    accept: { type: String, default: null, required: false },

    onSelectFile: {
      type: Function as PropType<(file: File) => void>,
      required: true,
    },
    onUnselectFile: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
  setup(props) {
    const { hovering, onMouseEnter, onMouseLeave } = useHovering();
    const selectedFile = ref<File | null>(null);
    return {
      hovering,
      onMouseEnter,
      onMouseLeave,

      selectedFile,
      dropFile: (e: DragEvent) => {
        const files = e.dataTransfer?.files;
        if (isNullish(files) || files.length <= 0) return;
        const file = files[0];
        if (!(file instanceof File)) return;

        selectedFile.value = file;
        props.onSelectFile(file);
      },
      selectFile: (e: Event) => {
        const files = (e.target as HTMLInputElement).files;
        if (isNullish(files) || files.length === 0) return;
        const file = files[0];
        if (!(file instanceof File)) return;

        selectedFile.value = file;
        props.onSelectFile(file);
      },
      unselectFile: () => {
        selectedFile.value = null;
        props.onUnselectFile();
      },
      fileName: computed(() => selectedFile.value?.name ?? null),
    };
  },
});
