import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-37ee7f81"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "user-list-table-cell-teacher-classes-container" }
const _hoisted_2 = { class: "wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TeacherClassText = _resolveComponent("TeacherClassText")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.teacherClasses, (teacherClass) => {
        return (_openBlock(), _createBlock(_component_TeacherClassText, {
          key: teacherClass.classId,
          class: "item",
          "teacher-class": teacherClass,
          "show-school-year": false,
          "is-link": _ctx.isLink,
          wrap: false
        }, null, 8, ["teacher-class", "is-link"]))
      }), 128))
    ])
  ]))
}