import { inject, InjectionKey, provide } from "vue";
import { App } from "@vue/runtime-core";
import { hasValue, isNullish } from "@/ts/utils/common-util";
import { AuthService } from "@/ts/services/auth-service";

export const authServiceKey: InjectionKey<AuthService> = Symbol("authService");

export function provideAuthService(authService: AuthService, app?: App): void {
  const _provide = hasValue(app) ? app.provide : provide;
  _provide(authServiceKey, authService);
}

export function useAuthService(): AuthService {
  const authService = inject(authServiceKey);

  if (isNullish(authService))
    throw new Error("useAuthService: authService not provided");

  return authService;
}
