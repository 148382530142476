import {
  ComputedRef,
  inject,
  InjectionKey,
  provide,
  ref,
  Ref,
  watchEffect,
} from "vue";
import { FirebaseApp } from "firebase/app";
import {
  FirebaseStorage,
  ref as storageRef,
  getDownloadURL,
} from "firebase/storage";
import { App } from "@vue/runtime-core";
import { hasValue, isNullish } from "@/ts/utils/common-util";
import { Result } from "@/ts/app/result";
import { handleThrownError } from "@/ts/app/error/app-error";
import { DeepReadonly } from "@vue/reactivity";

export const firebaseAppKey: InjectionKey<FirebaseApp | null> =
  Symbol("firebaseApp");

export const firebaseStorageKey: InjectionKey<FirebaseStorage | null> =
  Symbol("firebaseStorage");

export function provideFirebaseApp(
  firebaseApp: FirebaseApp | null | undefined,
  firebaseStorage: FirebaseStorage | null | undefined,
  app?: App,
): void {
  const _provide = hasValue(app) ? app.provide : provide;
  _provide(firebaseAppKey, firebaseApp ?? null);
  _provide(firebaseStorageKey, firebaseStorage ?? null);
}

export function useFileUrlGetter(): (
  gcsUrl: string,
) => Promise<Result<string>> {
  const storage = inject(firebaseStorageKey);

  if (isNullish(storage)) {
    return async () => ({
      ok: true,
      data: "https://picsum.photos/600/300",
    });
  }

  return async (gcsUrl: string) => {
    try {
      const url = await getDownloadURL(storageRef(storage, gcsUrl));
      return { ok: true, data: url };
    } catch (e) {
      const appError = handleThrownError("fileUrlGetter", e);
      return {
        ok: false,
        error: appError,
      };
    }
  };
}

export function useFileUrl(
  gcsUrl:
    | Ref<string | null | undefined>
    | ComputedRef<string | null | undefined>,
): DeepReadonly<Ref<string | null>> {
  const fileUrlGetter = useFileUrlGetter();

  const fileUrl = ref<string | null>(null);
  watchEffect(async () => {
    const _gcsUrl = gcsUrl.value;
    if (isNullish(_gcsUrl) || _gcsUrl === "") {
      fileUrl.value = null;
      return;
    }

    const result = await fileUrlGetter(_gcsUrl);
    if (!result.ok) {
      fileUrl.value = null;
      return;
    }

    fileUrl.value = result.data;
  });

  return fileUrl;
}
