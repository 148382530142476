import { SortOrderValue } from "@/ts/objects/value/sort-order";
import { LocationQuery, LocationQueryValue } from "vue-router";
import { isNullish } from "@/ts/utils/common-util";

const _subTypes = {
  nameKanaAsc: "nameKanaAsc",
  nameKanaDesc: "nameKanaDesc",
  createdAtDesc: "createdAtDesc",
  createdAtAsc: "createdAtAsc",
  updatedAtDesc: "updatedAtDesc",
  updatedAtAsc: "updatedAtAsc",
} as const;

export const userSortConditionSubTypes = Object.values(_subTypes);

export type UserSortConditionSubType = typeof userSortConditionSubTypes[number];

export function isUserSortConditionSubType(
  value: unknown,
): value is UserSortConditionSubType {
  return (
    typeof value === "string" &&
    userSortConditionSubTypes.some((v) => v === value)
  );
}

export function asUserSortConditionSubTypeOrNull(
  value: unknown,
): UserSortConditionSubType | null {
  if (isUserSortConditionSubType(value)) return value;
  return null;
}

export type UserSortCondition = {
  searchConditionType: "sort-user";
} & (
  | {
      subType: typeof _subTypes["nameKanaAsc"];
    }
  | {
      subType: typeof _subTypes["nameKanaDesc"];
    }
  | {
      subType: typeof _subTypes["createdAtDesc"];
    }
  | {
      subType: typeof _subTypes["createdAtAsc"];
    }
  | {
      subType: typeof _subTypes["updatedAtDesc"];
    }
  | {
      subType: typeof _subTypes["updatedAtAsc"];
    }
);

export function userSortConditionDefault(): UserSortCondition {
  return {
    searchConditionType: "sort-user",
    subType: "nameKanaAsc",
  };
}

export function userSortConditionToDisplayValue(
  cond: UserSortCondition,
): string {
  switch (cond.subType) {
    case "nameKanaAsc":
      return "表示名かな昇順";
    case "nameKanaDesc":
      return "表示名かな降順";
    case "createdAtDesc":
      return "作成が新しい順";
    case "createdAtAsc":
      return "作成が古い順";
    case "updatedAtDesc":
      return "更新が新しい順";
    case "updatedAtAsc":
      return "更新が古い順";
  }
}

type QueryParams = {
  sortSubType: string | undefined;
};

export function userSortConditionFromQueryParams(
  query: LocationQuery,
): UserSortCondition {
  const _query = query as Record<
    keyof QueryParams,
    LocationQueryValue | LocationQueryValue[] | undefined
  >;
  const subType = asUserSortConditionSubTypeOrNull(_query.sortSubType);

  if (isNullish(subType)) return userSortConditionDefault();
  return {
    searchConditionType: "sort-user",
    subType,
  };
}

export function userSortConditionToQueryParams(
  cond: UserSortCondition,
): QueryParams {
  return {
    sortSubType: cond.subType,
  };
}

export type UserSortConditionRaw = {
  readonly sortBy: "nameKana" | "createdAt" | "updatedAt";
  readonly sortOrder: SortOrderValue;
};

export function userSortConditionToRaw(
  cond: UserSortCondition,
): UserSortConditionRaw {
  switch (cond.subType) {
    case "nameKanaAsc":
      return { sortBy: "nameKana", sortOrder: "asc" };
    case "nameKanaDesc":
      return { sortBy: "nameKana", sortOrder: "desc" };
    case "createdAtDesc":
      return { sortBy: "createdAt", sortOrder: "desc" };
    case "createdAtAsc":
      return { sortBy: "createdAt", sortOrder: "asc" };
    case "updatedAtDesc":
      return { sortBy: "updatedAt", sortOrder: "desc" };
    case "updatedAtAsc":
      return { sortBy: "updatedAt", sortOrder: "asc" };
  }
}
