import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageHeading = _resolveComponent("PageHeading")!

  return (_openBlock(), _createBlock(_component_PageHeading, {
    class: "class-page-heading-container",
    icon: "chalkboard-teacher",
    label: "Classes"
  }))
}