
import { computed, defineComponent, PropType } from "vue";
import UserEditTableTabs from "@/components/table/edit/UserEditTableTabs/UserEditTableTabs.vue";
import UserEditTableBody from "@/components/table/edit/UserEditTableBody/UserEditTableBody.vue";
import {
  TeacherProfileColumnGroup,
  teacherProfileColumnGroupName,
} from "@/ts/app/columns/def/teacher/teacher-profile-column";
import { TeacherEditTableTabValue } from "@/views/teacher/TeacherEdit/teacher-edit-table-tab-value";
import { TeacherColumnId } from "@/ts/app/columns/def/teacher/teacher-column";

export default defineComponent({
  name: "TeacherEditTable",
  components: { UserEditTableBody, UserEditTableTabs },
  props: {
    columnGroups: {
      type: Object as PropType<{
        profileColumnGroup: TeacherProfileColumnGroup;
      }>,
      required: true,
    },
    selectedTab: {
      type: String as PropType<TeacherEditTableTabValue>,
      required: true,
    },
    editing: { type: Boolean, required: true },

    onInput: {
      type: Function as PropType<
        (columnId: TeacherColumnId, value: any) => void
      >,
      required: true,
    },
    onSelectTab: {
      type: Function as PropType<(tab: TeacherEditTableTabValue) => void>,
      required: true,
    },
  },
  setup(props) {
    const selectedGroupColumns = computed(
      () =>
        Object.values(props.columnGroups).find(
          (g) => g.groupName === props.selectedTab,
        )?.columns ?? null,
    );

    return {
      columnGroupNames: [teacherProfileColumnGroupName],
      selectedGroupColumns,
    };
  },
});
