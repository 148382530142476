
import { defineComponent } from "vue";

export default defineComponent({
  name: "PageHeading",
  props: {
    iconType: { type: String, default: "fas" }, // fas or far
    icon: { type: String, required: true },
    label: { type: String, required: true },
  },
});
