
import { defineComponent, PropType } from "vue";
import BaseButton from "@/components/buttons/BaseButton/BaseButton.vue";

export default defineComponent({
  name: "ClassListTableRowAddButton",
  components: { BaseButton },
  props: {
    onClick: { type: Function as PropType<() => void>, required: true },
  },
});
