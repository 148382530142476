
import CurriculumEdit from "@/views/curriculum/CurriculumEdit/CurriculumEdit.vue";
import { computed, defineComponent, ref, watch } from "vue";
import { useCurriculumService } from "@/composables/provide-curriculum-service";
import { useAppStore } from "@/store/app-store";
import { useCurriculumRoute } from "@/router/use-curriculum-route";
import { useAppToast } from "@/composables/use-app-toast";
import { SchoolType } from "@/ts/objects/value/school-type";
import { useRouter } from "vue-router";
import { LoadableData, loadableDataNull } from "@/ts/app/loadable-data";
import { Curriculum } from "@/ts/objects/entity/curriculum";
import { useCurriculumStore } from "@/store/curriculum-store";
import {
  allItemsOfCurriculumEditTableData,
  CurriculumEditState,
  CurriculumEditTableData,
  curriculumEditTableDataFromCurriculums,
} from "@/views/curriculum/CurriculumEdit/curriculum-edit-data";
import cloneDeep from "lodash/cloneDeep";
import { hasValue, isNullish } from "@/ts/utils/common-util";
import { createGradeOrError } from "@/ts/objects/value/grade";
import { ResultErr } from "@/ts/app/result";

export default defineComponent({
  name: "CurriculumEditContainer",
  components: { CurriculumEdit },
  setup() {
    const appStore = useAppStore();
    const curriculumStore = useCurriculumStore();
    const curriculumService = useCurriculumService();
    const router = useRouter();
    const { schoolType, schoolYear } = useCurriculumRoute(
      appStore.currentSchoolYear,
    );
    const { showInfo, showError, showSuccess } = useAppToast();

    const isAdmin = computed(() => appStore.isAdmin);

    const freeze = ref(false);

    const changeSchoolType = (tab: SchoolType) => {
      if (editState.value.editing || freeze.value) return;

      router.push({
        path: "/curriculum/edit",
        query: {
          schoolType: tab,
          schoolYear: schoolYear.value,
        },
      });
    };

    const changeSchoolYear = (value: number) => {
      if (editState.value.editing || freeze.value) return;

      router.push({
        path: "/curriculum/edit",
        query: {
          schoolType: schoolType.value,
          schoolYear: value,
        },
      });
    };
    watch(schoolYear, () =>
      curriculumStore.fetchCurriculums(curriculumService, schoolYear.value),
    );

    const curriculums = computed<LoadableData<Curriculum[]>>(
      () =>
        curriculumStore.schoolYearToCurriculums[schoolYear.value] ??
        loadableDataNull(),
    );
    curriculumStore.fetchCurriculums(curriculumService, schoolYear.value);

    const editState = ref<CurriculumEditState>({ editing: false });
    const onUpdate = (data: CurriculumEditTableData) => {
      if (!editState.value.editing) return;

      editState.value = { editing: true, data };
    };

    const copyFromPreviousYear = async () => {
      const _schoolYear = schoolYear.value;
      const _schoolType = schoolType.value;
      const _editState = editState.value;
      if (!_editState.editing || freeze.value) return;

      freeze.value = true;

      if (
        allItemsOfCurriculumEditTableData(_schoolYear, _editState.data)
          .length !== 0
      ) {
        showInfo(
          "完全に未設定の年度でのみ、前年度からコピーできます。\n使用するには、先にこの年度の教科をすべて削除してください。",
          16000,
        );
        freeze.value = false;
        return;
      }

      const prevSchoolYear = _schoolYear - 1;
      if (prevSchoolYear < 1) {
        freeze.value = false;
        return;
      }

      await curriculumStore.fetchCurriculums(curriculumService, prevSchoolYear);
      const prevYearCurriculums: LoadableData<Curriculum[]> =
        curriculumStore.schoolYearToCurriculums[prevSchoolYear];
      if (isNullish(prevYearCurriculums) || !prevYearCurriculums.hasFreshData) {
        showError("前年度のデータの取得に失敗しました。");
        freeze.value = false;
        return;
      }

      const copiedCurriculums = prevYearCurriculums.data
        .filter((c) => c.grade.schoolType === _schoolType)
        .map((c) => ({
          ...c,
          schoolYear: _schoolYear,
        }));
      editState.value = {
        editing: true,
        data: curriculumEditTableDataFromCurriculums(
          _schoolYear,
          _schoolType,
          copiedCurriculums,
          true,
        ),
      };
      showSuccess("前年度からデータをコピーしました。");
      freeze.value = false;
    };

    const startEditing = () => {
      const _schoolYear = schoolYear.value;
      const _schoolType = schoolType.value;
      const _curriculums = curriculums.value;
      if (!_curriculums.hasFreshData || freeze.value) return;

      editState.value = {
        editing: true,
        data: curriculumEditTableDataFromCurriculums(
          _schoolYear,
          _schoolType,
          cloneDeep(_curriculums.data),
        ),
      };
    };
    const cancelEditing = () => {
      if (freeze.value) return;

      editState.value = { editing: false };
    };
    const save = async () => {
      const _schoolYear = schoolYear.value;
      const _curriculums = curriculums.value;
      const _editState = editState.value;
      if (!_curriculums.hasData || !_editState.editing || freeze.value) return;

      freeze.value = true;

      const allItems = allItemsOfCurriculumEditTableData(
        _schoolYear,
        _editState.data,
      );
      const postPromises = allItems
        .filter((i) => isNullish(i.id)) // 新規作成したアイテムに絞る。
        .map((i) =>
          curriculumService.postCurriculum(
            i.evalType,
            i.schoolYear,
            createGradeOrError(i.schoolType, i.gradeNumber),
            i.name,
            i.orderNum,
          ),
        );
      const patchPromises = allItems
        .filter((i) => hasValue(i.id) && !i.deleted) // 更新したアイテムに絞る。
        .filter((i) => {
          // 変化したものだけに絞る。
          // name, orderNum以外も編集できるようになったら、そのチェックも追加すること。
          const existing = _curriculums.data.find(
            (c) => c.evalType === i.evalType && c.id === i.id,
          );
          if (isNullish(existing)) return true; // なぜか見つからなかったのは念のため残す。

          // 1カ所でも変化していれば、残す。
          return (
            i.name.value !== existing.name.value ||
            i.orderNum !== existing.orderNum
          );
        })
        .map((i) =>
          curriculumService.patchCurriculum(
            i.evalType,
            i.id ?? "",
            i.name,
            i.orderNum,
          ),
        );
      const deletePromises = allItems
        .filter((i) => hasValue(i.id) && i.deleted) // 削除したアイテムに絞る。
        .map((i) => curriculumService.deleteCurriculum(i.evalType, i.id ?? ""));

      const results = await Promise.all([
        ...postPromises,
        ...patchPromises,
        ...deletePromises,
      ]);
      const errors = results
        .filter((r): r is ResultErr => !r.ok)
        .map((r) => r.error);
      if (errors.length === 0) {
        showSuccess("変更を保存しました。");
      } else {
        showError(`変更の保存に失敗しました: ${errors[0].displayMessage}`);
      }

      editState.value = { editing: false };
      curriculumStore.clearCurriculums(_schoolYear);
      curriculumStore.fetchCurriculums(curriculumService, _schoolYear);

      freeze.value = false;
    };

    return {
      isAdmin,

      schoolType,
      changeSchoolType,

      schoolYear,
      changeSchoolYear,

      curriculums,
      editState,

      onUpdate,
      copyFromPreviousYear,

      startEditing,
      cancelEditing,
      save,
    };
  },
});
