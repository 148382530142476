import { useRouter } from "vue-router";
import { UserService } from "@/ts/services/user-service";

/**
 * 以下のような関数を返す。
 *
 * OAuth認可済かどうかを確認してから、指定したパスに遷移する。
 * 未認可だった場合、認可用URLにリダイレクトする。
 *
 * 何らかのエラーにより確認できなかった場合も、とにかく指定したパスには遷移する。
 * 当然ながら、遷移先の画面自体を保護する機能は全く無い。
 */
export function usePushRouteAfterAuth(
  userService: UserService,
): (path: string) => Promise<void> {
  const router = useRouter();
  return async (path: string) => {
    const result = await userService.authorize(
      `https://${window.location.host}${path}`,
    );

    if (!result.ok || !result.data.needAuth) {
      await router.push(path);
      return;
    }

    window.location.replace(result.data.authUrl);
  };
}
