
import GuardianEdit from "@/views/guardian/GuardianEdit/GuardianEdit.vue";
import { computed, defineComponent, Ref, ref } from "vue";
import { useAppStore } from "@/store/app-store";
import { useRouter } from "vue-router";
import {
  delay,
  getChangesByTopLevelKey,
  hasValue,
  isNullish,
} from "@/ts/utils/common-util";
import log from "loglevel";
import { useGuardianRoute } from "@/router/use-guardian-route";
import {
  getGuardianColumnDefsAsDict,
  GuardianColumnDef,
  GuardianColumnId,
} from "@/ts/app/columns/def/guardian/guardian-column";
import { GuardianEditTableTabValue } from "@/views/guardian/GuardianEdit/guardian-edit-table-tab-value";
import { useUserService } from "@/composables/provide-user-service";
import cloneDeep from "lodash/cloneDeep";
import { UserService } from "@/ts/services/user-service";
import { useAppToast } from "@/composables/use-app-toast";
import { CheckFailed } from "@/ts/app/error/check-error";
import {
  applyResult,
  LoadableData,
  loadableDataNull,
} from "@/ts/app/loadable-data";
import {
  GuardianEditData,
  GuardianEditState,
} from "@/views/guardian/GuardianEdit/guardian-edit-state";
import { mapResult } from "@/ts/app/result";
import { getToday } from "@/ts/utils/app-util";
import { names } from "@/ts/app/object-name";

export default defineComponent({
  name: "GuardianEditContainer",
  components: { GuardianEdit },
  setup() {
    const appStore = useAppStore();
    const { userId } = useGuardianRoute(getToday(), appStore.currentSchoolYear);
    const router = useRouter();
    const userService = useUserService();
    const { showError, showSuccess } = useAppToast();

    const isAdmin = computed(() => appStore.isAdmin);

    const freeze = ref(false);

    const data = ref<LoadableData<GuardianEditData>>(loadableDataNull());

    const fetchGuardian = async () => {
      const _userId = userId.value;
      if (isNullish(_userId)) return;

      const result = await userService.getGuardian(_userId);

      data.value = applyResult(
        data.value,
        mapResult(result, (r) => ({ guardian: r })),
      );
    };

    const editState = ref<GuardianEditState>({ editing: false });

    const guardianColumnDefs = getGuardianColumnDefsAsDict();

    const onInput = (columnId: GuardianColumnId, value: any) => {
      const _editState = editState.value;
      if (!_editState.editing) return;

      log.debug(
        `GuardianEditContainer: onInput: columnId=${columnId}, value=${JSON.stringify(
          value,
        )}`,
      );

      const colDef = guardianColumnDefs[columnId];

      // 画像だけ特別扱い。すぐに更新する。
      if (colDef.type.category === "image") {
        let guardianNumber: 1 | 2 | undefined = undefined;
        switch (columnId) {
          case "pictureGcsUrl1":
            guardianNumber = 1;
            break;
          case "pictureGcsUrl2":
            guardianNumber = 2;
            break;
          default:
            throw new CheckFailed();
        }
        if (isNullish(guardianNumber)) return;

        if (hasValue(value)) {
          uploadPicture(
            userService,
            _editState.data.guardian.userId,
            guardianNumber,
            value,
            showError,
            showSuccess,
            colDef,
            editState,
          );
        } else {
          deletePicture(
            userService,
            _editState.data.guardian.userId,
            guardianNumber,
            showError,
            showSuccess,
            colDef,
            editState,
          );
        }

        return;
      }

      const setValue = colDef.setValue;
      if (isNullish(setValue)) return;

      _editState.data.guardian = setValue(_editState.data.guardian, value);
    };

    const startEditing = () => {
      const _data = data.value;
      if (editState.value.editing || !_data.hasFreshData || freeze.value)
        return;

      editState.value = {
        editing: true,
        data: cloneDeep(_data.data),
      };
    };
    const cancelEditing = () => {
      if (freeze.value) return;

      editState.value = { editing: false };
      fetchGuardian();
    };
    const save = async () => {
      const _data = data.value;
      const _editState = editState.value;
      if (!_data.hasData || !_editState.editing || freeze.value) return;

      freeze.value = true;

      const changes = getChangesByTopLevelKey(
        _data.data.guardian,
        _editState.data.guardian,
      );
      const result = await userService.patchGuardian({
        userId: _editState.data.guardian.userId,
        ...changes,
      });

      if (result.ok) {
        showSuccess("変更を保存しました。");
      } else {
        showError(`変更の保存に失敗しました: ${result.error.displayMessage}`);
      }

      editState.value = { editing: false };
      data.value = applyResult(
        data.value,
        mapResult(result, (r) => ({ guardian: r })),
      );

      freeze.value = false;

      await fetchGuardian();
    };

    const selectedTab = ref<GuardianEditTableTabValue>("profile");
    const onSelectTab = (tab: GuardianEditTableTabValue) => {
      selectedTab.value = tab;
    };

    const onClickBack = () => router.back();

    const deleteUser = async () => {
      const _editState = editState.value;
      if (!_editState.editing || freeze.value) return;

      if (
        !window.confirm(
          `この${names.guardian.d}を削除してよろしいですか？\nこの操作はすぐに反映され、元に戻せません。`,
        )
      )
        return;

      freeze.value = true;

      editState.value = { editing: false };
      await userService.deleteGuardian(_editState.data.guardian.userId);

      freeze.value = false;

      router.back();
    };

    fetchGuardian();

    return {
      isAdmin,

      data,
      editState,

      selectedTab,

      onClickBack,
      startEditing,
      cancelEditing,
      save,
      deleteUser,

      onInput,

      onSelectTab,
    };
  },
});

async function uploadPicture(
  userService: UserService,
  userId: string,
  guardianNumber: 1 | 2,
  file: unknown,
  showError: (message: string) => void,
  showSuccess: (message: string) => void,
  colDef: GuardianColumnDef<GuardianColumnId, any>,
  editState: Ref<GuardianEditState>,
) {
  if (!(file instanceof File)) return;

  const result = await userService.uploadGuardianPicture(
    userId,
    guardianNumber,
    file,
  );

  if (!result.ok) {
    showError(
      `写真のアップロードに失敗しました: ${result.error.displayMessage}`,
    );
    return;
  }
  showSuccess(
    "写真をアップロードしました。(反映には時間が掛かる場合があります。)",
  );

  await reloadViewAfterPictureUpdate(
    colDef,
    editState,
    result.data[`pictureGcsUrl${guardianNumber}`],
  );
}

async function deletePicture(
  userService: UserService,
  userId: string,
  guardianNumber: 1 | 2,
  showError: (message: string) => void,
  showSuccess: (message: string) => void,
  colDef: GuardianColumnDef<GuardianColumnId, any>,
  editState: Ref<GuardianEditState>,
) {
  if (
    !window.confirm(
      `${
        names[`pictureGcsUrl${guardianNumber}`].d
      }を削除してよろしいですか？\nこの操作はすぐに反映され、元に戻せません。`,
    )
  )
    return;

  const result = await userService.deleteGuardianPicture(
    userId,
    guardianNumber,
  );

  if (!result.ok) {
    showError(`写真の削除に失敗しました: ${result.error.displayMessage}`);
    return;
  }
  showSuccess("写真を削除しました。");

  await reloadViewAfterPictureUpdate(colDef, editState, null);
}

async function reloadViewAfterPictureUpdate(
  colDef: GuardianColumnDef<GuardianColumnId, any>,
  editState: Ref<GuardianEditState>,
  newPictureUrl: string | null,
) {
  // 以下、ビューをリロードするためだけにやっている。
  const setValue = colDef.setValue;
  if (!editState.value.editing || isNullish(setValue)) return;

  editState.value.data.guardian = setValue(editState.value.data.guardian, null);
  await delay(0);
  editState.value.data.guardian = setValue(
    editState.value.data.guardian,
    newPictureUrl,
  );
}
