import {
  UserColumnDefGroup,
  UserColumnGroup,
} from "@/ts/app/columns/def/user-column-group";
import { Guardian, GuardianProfile } from "@/ts/objects/entity/guardian";
import { GuardianColumnDef } from "@/ts/app/columns/def/guardian/guardian-column";
import { UserColumn } from "@/ts/app/columns/def/user-column";
import { genUserColumnDefs } from "@/ts/app/columns/def/user-column-def";
import {
  ChildrenType,
  ImageType,
  StringType,
} from "@/ts/app/columns/def/column-types";
import {
  userColumnDefGoogleMail,
  userColumnDefName1,
  userColumnDefPhotoUrl,
  userColumnDefUserId,
} from "@/ts/app/columns/def/common/user-common-column";
import { names } from "@/ts/app/object-name";

export const guardianProfileColumnGroupName = names.profile.i;
export type GuardianProfileColumnGroupName =
  typeof guardianProfileColumnGroupName;

export type GuardianProfileColumnId =
  | "userId"
  | "googleMail"
  | "photoUrl"
  | "name1"
  | keyof GuardianProfile;

export type GuardianProfileColumnDef<T> = GuardianColumnDef<
  GuardianProfileColumnId,
  T
>;

export type GuardianProfileColumn<T> = UserColumn<
  GuardianProfileColumnId,
  Guardian,
  T
>;

export type GuardianProfileColumnDefGroup = UserColumnDefGroup<
  GuardianProfileColumnGroupName,
  GuardianProfileColumnId,
  Guardian
>;

export type GuardianProfileColumnGroup = UserColumnGroup<
  GuardianProfileColumnGroupName,
  GuardianProfileColumnId,
  Guardian
>;

export const guardianProfileColumnDefs: GuardianProfileColumnDef<any>[] =
  genUserColumnDefs<GuardianProfileColumnId, Guardian, any>([
    userColumnDefUserId(),
    userColumnDefGoogleMail(),
    userColumnDefPhotoUrl(),

    {
      id: "pictureGcsUrl1",
      width: 120,
      type: ImageType,
      getValue: (g) => g.pictureGcsUrl1,
      setValue: (g, v) => ({ ...g, pictureGcsUrl1: v }),
    },
    userColumnDefName1(), // 保護者のみ、nameではなくname1という項目を持つ。
    {
      id: "nameKana1",
      width: 120,
      type: StringType,
      getValue: (g) => g.nameKana1,
      setValue: (g, v) => ({ ...g, nameKana1: v }),
    },
    {
      id: "familyName1",
      width: 120,
      type: StringType,
      getValue: (g) => g.familyName1,
      setValue: (g, v) => ({ ...g, familyName1: v }),
    },
    {
      id: "givenName1",
      width: 120,
      type: StringType,
      getValue: (g) => g.givenName1,
      setValue: (g, v) => ({ ...g, givenName1: v }),
    },
    {
      id: "email1",
      width: 120,
      type: StringType,
      getValue: (g) => g.email1,
      setValue: (g, v) => ({ ...g, email1: v }),
    },
    {
      id: "mobilePhone1",
      width: 120,
      type: StringType,
      getValue: (g) => g.mobilePhone1,
      setValue: (g, v) => ({ ...g, mobilePhone1: v }),
    },
    {
      id: "landlinePhone1",
      width: 120,
      type: StringType,
      getValue: (g) => g.landlinePhone1,
      setValue: (g, v) => ({ ...g, landlinePhone1: v }),
    },
    {
      id: "job1",
      width: 120,
      type: StringType,
      getValue: (g) => g.job1,
      setValue: (g, v) => ({ ...g, job1: v }),
    },
    {
      id: "workPlace1",
      width: 120,
      type: StringType,
      getValue: (g) => g.workPlace1,
      setValue: (g, v) => ({ ...g, workPlace1: v }),
    },

    {
      id: "pictureGcsUrl2",
      width: 120,
      type: ImageType,
      getValue: (g) => g.pictureGcsUrl2,
      setValue: (g, v) => ({ ...g, pictureGcsUrl2: v }),
    },
    {
      id: "name2",
      width: 120,
      type: StringType,
      getValue: (g) => g.name2,
      setValue: (g, v) => ({ ...g, name2: v }),
    },
    {
      id: "nameKana2",
      width: 120,
      type: StringType,
      getValue: (g) => g.nameKana2,
      setValue: (g, v) => ({ ...g, nameKana2: v }),
    },
    {
      id: "familyName2",
      width: 120,
      type: StringType,
      getValue: (g) => g.familyName2,
      setValue: (g, v) => ({ ...g, familyName2: v }),
    },
    {
      id: "givenName2",
      width: 120,
      type: StringType,
      getValue: (g) => g.givenName2,
      setValue: (g, v) => ({ ...g, givenName2: v }),
    },
    {
      id: "email2",
      width: 120,
      type: StringType,
      getValue: (g) => g.email2,
      setValue: (g, v) => ({ ...g, email2: v }),
    },
    {
      id: "mobilePhone2",
      width: 120,
      type: StringType,
      getValue: (g) => g.mobilePhone2,
      setValue: (g, v) => ({ ...g, mobilePhone2: v }),
    },
    {
      id: "landlinePhone2",
      width: 120,
      type: StringType,
      getValue: (g) => g.landlinePhone2,
      setValue: (g, v) => ({ ...g, landlinePhone2: v }),
    },
    {
      id: "job2",
      width: 120,
      type: StringType,
      getValue: (g) => g.job2,
      setValue: (g, v) => ({ ...g, job2: v }),
    },
    {
      id: "workPlace2",
      width: 120,
      type: StringType,
      getValue: (g) => g.workPlace2,
      setValue: (g, v) => ({ ...g, workPlace2: v }),
    },

    {
      id: "children",
      width: 160,
      type: ChildrenType,
      getValue: (g) => g.children,
      setValue: (g, v) => ({ ...g, children: v }),
    },

    {
      id: "emergencyContactRelationship1",
      width: 120,
      type: StringType,
      getValue: (g) => g.emergencyContactRelationship1,
      setValue: (g, v) => ({ ...g, emergencyContactRelationship1: v }),
    },
    {
      id: "emergencyContactPhone1",
      width: 140,
      type: StringType,
      getValue: (g) => g.emergencyContactPhone1,
      setValue: (g, v) => ({ ...g, emergencyContactPhone1: v }),
    },
    {
      id: "emergencyContactRelationship2",
      width: 120,
      type: StringType,
      getValue: (g) => g.emergencyContactRelationship2,
      setValue: (g, v) => ({ ...g, emergencyContactRelationship2: v }),
    },
    {
      id: "emergencyContactPhone2",
      width: 140,
      type: StringType,
      getValue: (g) => g.emergencyContactPhone2,
      setValue: (g, v) => ({ ...g, emergencyContactPhone2: v }),
    },
    {
      id: "emergencyContactRelationship3",
      width: 120,
      type: StringType,
      getValue: (g) => g.emergencyContactRelationship3,
      setValue: (g, v) => ({ ...g, emergencyContactRelationship3: v }),
    },
    {
      id: "emergencyContactPhone3",
      width: 140,
      type: StringType,
      getValue: (g) => g.emergencyContactPhone3,
      setValue: (g, v) => ({ ...g, emergencyContactPhone3: v }),
    },
  ]);
