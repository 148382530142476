import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fde3b43e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "edit-button-set-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EditSaveButton = _resolveComponent("EditSaveButton")!
  const _component_EditCancelButton = _resolveComponent("EditCancelButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_EditSaveButton, {
      class: "edit-button",
      editing: _ctx.editing,
      enabled: _ctx.enabled,
      "on-click-edit": _ctx.onClickEdit,
      "on-click-save": _ctx.onClickSave
    }, null, 8, ["editing", "enabled", "on-click-edit", "on-click-save"]),
    _createVNode(_component_EditCancelButton, {
      class: "cancel-button",
      enabled: _ctx.enabled && _ctx.editing,
      "on-click": _ctx.onClickCancel
    }, null, 8, ["enabled", "on-click"])
  ]))
}