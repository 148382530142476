import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5da714f6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "user-list-table-cell-user-container" }
const _hoisted_2 = {
  key: 1,
  class: "item"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isLink && _ctx.linkPath != null)
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 0,
          class: "item link",
          to: _ctx.linkPath
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.displayValue), 1)
          ]),
          _: 1
        }, 8, ["to"]))
      : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.displayValue), 1))
  ]))
}