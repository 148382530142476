
import { computed, defineComponent, ref } from "vue";
import TeacherEdit from "@/views/teacher/TeacherEdit/TeacherEdit.vue";
import { useAppStore } from "@/store/app-store";
import { useTeacherRoute } from "@/router/use-teacher-route";
import { useRouter } from "vue-router";
import { useUserService } from "@/composables/provide-user-service";
import { getChangesByTopLevelKey, isNullish } from "@/ts/utils/common-util";
import {
  getTeacherColumnDefsAsDict,
  TeacherColumnId,
} from "@/ts/app/columns/def/teacher/teacher-column";
import { TeacherEditTableTabValue } from "@/views/teacher/TeacherEdit/teacher-edit-table-tab-value";
import log from "loglevel";
import cloneDeep from "lodash/cloneDeep";
import {
  applyResult,
  LoadableData,
  loadableDataNull,
} from "@/ts/app/loadable-data";
import {
  TeacherEditData,
  TeacherEditState,
} from "@/views/teacher/TeacherEdit/teacher-edit-state";
import { useAppToast } from "@/composables/use-app-toast";
import { composeResults, Result } from "@/ts/app/result";
import { names } from "@/ts/app/object-name";

export default defineComponent({
  name: "TeacherEditContainer",
  components: { TeacherEdit },
  setup() {
    const appStore = useAppStore();
    const { userId } = useTeacherRoute(appStore.currentSchoolYear);
    const router = useRouter();
    const userService = useUserService();
    const { showError, showSuccess } = useAppToast();

    const isAdmin = computed(() => appStore.isAdmin);

    const schoolYear = appStore.currentSchoolYear;

    const freeze = ref(false);

    const data = ref<LoadableData<TeacherEditData>>(loadableDataNull());
    const fetchTeacher = async () => {
      const _userId = userId.value;
      if (isNullish(_userId)) return;

      const [getTeacherResult, isAdminResult] = await Promise.all([
        userService.getTeacher(_userId),
        userService.isAdmin(_userId),
      ]);
      const result: Result<TeacherEditData> = composeResults(
        getTeacherResult,
        isAdminResult,
        (r0, r1) => ({ teacher: r0, isAdmin: r1.isAdmin }),
      );

      data.value = applyResult(data.value, result);
    };

    const editState = ref<TeacherEditState>({ editing: false });

    const teacherColumnDefs = getTeacherColumnDefsAsDict(schoolYear);
    const onInput = (columnId: TeacherColumnId, value: any) => {
      const _editState = editState.value;
      if (!_editState.editing) return;

      log.debug(
        `TeacherEditContainer: onInput: columnId=${columnId}, value=${JSON.stringify(
          value,
        )}`,
      );

      const setValue = teacherColumnDefs[columnId].setValue;
      if (isNullish(setValue)) return;
      _editState.data.teacher = setValue(_editState.data.teacher, value);
    };
    const onToggleAdmin = (isAdmin: boolean) => {
      const _editState = editState.value;
      if (!_editState.editing) return;

      _editState.data.isAdmin = isAdmin;
    };

    const startEditing = () => {
      const _data = data.value;
      if (editState.value.editing || !_data.hasFreshData || freeze.value)
        return;

      editState.value = {
        editing: true,
        data: cloneDeep(_data.data),
      };
    };
    const cancelEditing = () => {
      if (freeze.value) return;

      editState.value = { editing: false };
    };
    const save = async () => {
      const _data = data.value;
      const _editState = editState.value;
      if (!_data.hasData || !_editState.editing || freeze.value) return;

      freeze.value = true;

      const changes = getChangesByTopLevelKey(
        _data.data.teacher,
        _editState.data.teacher,
      );
      const [patchTeacherResult, toggleAdminRoleResult] = await Promise.all([
        userService.patchTeacher({
          userId: _editState.data.teacher.userId,
          ...changes,
        }),
        _data.data.isAdmin === _editState.data.isAdmin
          ? Promise.resolve<Result<{ isAdmin: boolean }>>({
              ok: true,
              data: { isAdmin: _data.data.isAdmin },
            })
          : userService.toggleAdminRole(
              _editState.data.teacher.userId,
              _editState.data.isAdmin,
            ),
      ]);
      const result: Result<TeacherEditData> = composeResults(
        patchTeacherResult,
        toggleAdminRoleResult,
        (r0, r1) => ({ teacher: r0, isAdmin: r1.isAdmin }),
      );

      if (result.ok) {
        showSuccess("変更を保存しました。");
      } else {
        showError(`変更の保存に失敗しました: ${result.error.displayMessage}`);
      }

      editState.value = { editing: false };
      data.value = applyResult(data.value, result);

      freeze.value = false;

      await fetchTeacher();
    };

    const selectedTab = ref<TeacherEditTableTabValue>("profile");
    const onSelectTab = (tab: TeacherEditTableTabValue) => {
      selectedTab.value = tab;
    };

    const onClickBack = () => router.back();

    const deleteUser = async () => {
      const _editState = editState.value;
      if (!_editState.editing || freeze.value) return;

      if (
        !window.confirm(
          `この${names.teacher.d}を削除してよろしいですか？\nこの操作はすぐに反映され、元に戻せません。`,
        )
      )
        return;

      freeze.value = true;

      editState.value = { editing: false };
      await userService.deleteTeacher(_editState.data.teacher.userId);

      freeze.value = false;

      router.back();
    };

    fetchTeacher();

    return {
      isAdmin,

      schoolYear,

      data,
      editState,

      selectedTab,

      onClickBack,
      startEditing,
      cancelEditing,
      save,
      deleteUser,

      onInput,
      onToggleAdmin,

      onSelectTab,
    };
  },
});
