import { isNullish } from "@/ts/utils/common-util";
import { Result } from "@/ts/app/result";
import { errors, ThrowableAppError } from "@/ts/app/error/app-error";
import { names } from "@/ts/app/object-name";

export const teacherTypes = {
  staff: "スタッフ",
  teacher: "教師",
} as const;

/**
 * 教職員タイプ。
 */
export type TeacherType = keyof typeof teacherTypes;
export type TeacherTypeDisplayValue = typeof teacherTypes[TeacherType];

export function isTeacherType(value: unknown): value is TeacherType {
  return (
    typeof value === "string" &&
    Object.keys(teacherTypes).some((v) => v === value)
  );
}

export function teacherTypeToDisplayValue(
  value: TeacherType,
): TeacherTypeDisplayValue {
  return teacherTypes[value];
}

export function displayValueToTeacherType(
  displayValue: string,
): Result<TeacherType> {
  const error = {
    ok: false,
    error: errors.validationFailed.invalidValue(
      displayValue,
      names.teacherType,
    ),
  } as const;

  const kv = Object.entries(teacherTypes).find(([_k, v]) => v === displayValue);
  if (isNullish(kv)) return error;
  const k = kv[0];
  if (!isTeacherType(k)) return error;
  return { ok: true, data: k };
}

export function displayValueToTeacherTypeOrNull(
  displayValue: string,
): TeacherType | null {
  const result = displayValueToTeacherType(displayValue);
  if (!result.ok) return null;
  return result.data;
}

export function displayValueToTeacherTypeOrError(
  displayValue: string,
): TeacherType {
  const result = displayValueToTeacherType(displayValue);
  if (!result.ok) throw new ThrowableAppError(result.error);
  return result.data;
}

export function teacherTypeFromString(value: string): Result<TeacherType> {
  if (isTeacherType(value)) {
    return { ok: true, data: value };
  }
  return {
    ok: false,
    error: errors.validationFailed.invalidValue(value, names.teacherType),
  };
}

export function teacherTypeFromStringOrError(value: string): TeacherType {
  const result = teacherTypeFromString(value);
  if (result.ok) {
    return result.data;
  } else {
    throw new Error(result.error.internalMessage);
  }
}
