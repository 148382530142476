import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-059adb52"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "student-edit-body-head-lower-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SimpleTextItem1 = _resolveComponent("SimpleTextItem1")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SimpleTextItem1, {
      class: "item",
      name: _ctx.gradeDisplayName,
      value: `${_ctx.schoolType}${_ctx.grade}`
    }, null, 8, ["name", "value"]),
    _createVNode(_component_SimpleTextItem1, {
      class: "item",
      name: _ctx.classDisplayName,
      value: _ctx.cls
    }, null, 8, ["name", "value"]),
    _createVNode(_component_SimpleTextItem1, {
      class: "item",
      name: _ctx.studentNumberDisplayName,
      value: _ctx.studentNumber
    }, null, 8, ["name", "value"]),
    _createVNode(_component_SimpleTextItem1, {
      class: "item",
      name: _ctx.guardianDisplayName,
      value: _ctx.guardian,
      "link-enabled": _ctx.linkEnabled && _ctx.guardianLinkPath != null,
      "link-path": _ctx.guardianLinkPath
    }, null, 8, ["name", "value", "link-enabled", "link-path"])
  ]))
}