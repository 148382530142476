
import { computed, defineComponent, PropType } from "vue";
import StudentCustomColumnEditTableItem from "@/views/student/StudentCustomColumnEdit/StudentCustomColumnEditTable/StudentCustomColumnEditTableItem/StudentCustomColumnEditTableItem.vue";
import {
  StudentCustomColumnId,
  StudentCustomColumnNames,
} from "@/ts/objects/value/student-custom-column-names";
import { typedObjectEntries } from "@/ts/utils/common-util";

export default defineComponent({
  name: "StudentCustomColumnEditTable",
  components: { StudentCustomColumnEditTableItem },
  props: {
    customColumnNames: {
      type: Object as PropType<StudentCustomColumnNames>,
      required: true,
    },
    editing: { type: Boolean, required: true },

    onInput: {
      type: Function as PropType<
        (id: StudentCustomColumnId, value: string) => void
      >,
      required: true,
    },
  },
  setup(props) {
    const customColumnNamesInternal = computed<
      [StudentCustomColumnId, string][]
    >(() => typedObjectEntries(props.customColumnNames));

    const onInputInternal = (id: StudentCustomColumnId, value: string) => {
      if (!props.editing) return;

      props.onInput(id, value);
    };

    return {
      customColumnNamesInternal,

      onInputInternal,
    };
  },
});
