import { ColorLabel } from "@/ts/objects/value/color-label";

export type UserDisplayName = {
  readonly color: ColorLabel;
  readonly value: string;
};

export function toUserDisplayName(
  name: string,
  defaultColor: ColorLabel,
): UserDisplayName {
  if (name === "")
    return {
      color: "ruby",
      value: "名称未設定",
    };
  return {
    color: defaultColor,
    value: name,
  };
}
