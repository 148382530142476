
import { defineComponent, PropType } from "vue";
import EditableItemText from "@/components/editable-items/EditableItemText/EditableItemText.vue";
import { names } from "@/ts/app/object-name";

export default defineComponent({
  name: "SchoolEditTableRowText",
  components: { EditableItemText },
  props: {
    name: { type: String, required: true },
    value: { type: String, required: true },

    editing: { type: Boolean, required: true },

    onInput: {
      type: Function as PropType<(value: string) => void>,
      required: true,
    },

    nameCellWidth: { type: Number, required: true },
    valueCellWidth: { type: Number, required: true },
  },
  setup(props) {
    const schoolNameDisplayName = names.schoolName.d;

    return {
      schoolNameDisplayName,

      styles: {
        "--name-cell-width": `${props.nameCellWidth}px`,
        "--value-cell-width": `${props.valueCellWidth}px`,
      },
    };
  },
});
