import { User } from "@/ts/objects/entity/user";
import { BasicUserInfoPartial } from "@/ts/objects/value/basic-user-info";
import { Guardian as GuardianResp, GuardianWrite } from "@/ts/api/user-service";
import { pick } from "lodash";
import { dropAllUndefinedFields } from "@/ts/utils/common-util";

export type Guardian = { readonly userType: "guardian" } & User &
  GuardianProfile &
  GuardianNotes;

export type GuardianProfile = {
  // Userのnameが、name1。
  readonly nameKana1: string;
  readonly familyName1: string;
  readonly givenName1: string;
  readonly name2: string;
  readonly nameKana2: string;
  readonly familyName2: string;
  readonly givenName2: string;

  readonly children: readonly BasicUserInfoPartial[];

  readonly email1: string;
  readonly email2: string;
  readonly mobilePhone1: string;
  readonly mobilePhone2: string;
  readonly landlinePhone1: string;
  readonly landlinePhone2: string;
  readonly job1: string;
  readonly job2: string;
  readonly workPlace1: string;
  readonly workPlace2: string;
  readonly pictureGcsUrl1: string | null;
  readonly pictureGcsUrl2: string | null;
  readonly emergencyContactRelationship1: string;
  readonly emergencyContactPhone1: string;
  readonly emergencyContactRelationship2: string;
  readonly emergencyContactPhone2: string;
  readonly emergencyContactRelationship3: string;
  readonly emergencyContactPhone3: string;
};

export type GuardianNotes = {
  readonly note01: string;
  readonly note02: string;
  readonly note03: string;
  readonly note04: string;
  readonly note05: string;
  readonly note06: string;
  readonly note07: string;
  readonly note08: string;
  readonly note09: string;
  readonly note10: string;
};

export function guardianFromRespOrError(r: GuardianResp): Guardian {
  return {
    userType: "guardian",

    userId: r.userId,
    googleMail: r.googleMail,
    photoUrl: r.photoUrl,
    name: r.name1,

    children: r.children.map((c) => ({
      userId: c.userId,
      googleMail: c.googleMail,
      userType: "student",
      name: c.name,
      photoUrl: c.photoUrl,
    })),

    nameKana1: r.nameKana1,
    familyName1: r.familyName1,
    givenName1: r.givenName1,
    name2: r.name2,
    nameKana2: r.nameKana2,
    familyName2: r.familyName2,
    givenName2: r.givenName2,
    email1: r.email1,
    email2: r.email2,
    mobilePhone1: r.mobilePhone1,
    mobilePhone2: r.mobilePhone2,
    landlinePhone1: r.landlinePhone1,
    landlinePhone2: r.landlinePhone2,
    job1: r.job1,
    job2: r.job2,
    workPlace1: r.workPlace1,
    workPlace2: r.workPlace2,
    pictureGcsUrl1: r.pictureGcsUrl1 ?? null,
    pictureGcsUrl2: r.pictureGcsUrl2 ?? null,
    emergencyContactRelationship1: r.emergencyContactRelationship1,
    emergencyContactPhone1: r.emergencyContactPhone1,
    emergencyContactRelationship2: r.emergencyContactRelationship2,
    emergencyContactPhone2: r.emergencyContactPhone2,
    emergencyContactRelationship3: r.emergencyContactRelationship3,
    emergencyContactPhone3: r.emergencyContactPhone3,

    note01: r.note01,
    note02: r.note02,
    note03: r.note03,
    note04: r.note04,
    note05: r.note05,
    note06: r.note06,
    note07: r.note07,
    note08: r.note08,
    note09: r.note09,
    note10: r.note10,
  };
}

export function guardianToReq(g: Partial<Guardian>): GuardianWrite {
  const req = {
    name1: g.name,
    nameKana1: g.nameKana1,
    familyName1: g.familyName1,
    givenName1: g.givenName1,
    name2: g.name2,
    nameKana2: g.nameKana2,
    familyName2: g.familyName2,
    givenName2: g.givenName2,
    email1: g.email1,
    email2: g.email2,
    mobilePhone1: g.mobilePhone1,
    mobilePhone2: g.mobilePhone2,
    landlinePhone1: g.landlinePhone1,
    landlinePhone2: g.landlinePhone2,
    job1: g.job1,
    job2: g.job2,
    workPlace1: g.workPlace1,
    workPlace2: g.workPlace2,
    // pictureGcsUrl1は、直接書き込み不可。
    // pictureGcsUrl2は、直接書き込み不可。
    emergencyContactRelationship1: g.emergencyContactRelationship1,
    emergencyContactPhone1: g.emergencyContactPhone1,
    emergencyContactRelationship2: g.emergencyContactRelationship2,
    emergencyContactPhone2: g.emergencyContactPhone2,
    emergencyContactRelationship3: g.emergencyContactRelationship3,
    emergencyContactPhone3: g.emergencyContactPhone3,

    note01: g.note01,
    note02: g.note02,
    note03: g.note03,
    note04: g.note04,
    note05: g.note05,
    note06: g.note06,
    note07: g.note07,
    note08: g.note08,
    note09: g.note09,
    note10: g.note10,
  };
  return dropAllUndefinedFields(req);
}

export type UpdatingGuardian = {
  readonly guardian: Guardian;
  readonly updatingFields: (keyof Guardian)[];
};

export function updatingGuardianToPartial(
  g: UpdatingGuardian,
): Partial<Guardian> & { readonly userId: string } {
  const partialGuardian: Partial<Guardian> = pick(g.guardian, g.updatingFields);
  return { ...partialGuardian, userId: g.guardian.userId };
}

export function guardianToBasicUserInfo(g: Guardian): User {
  return {
    userId: g.userId,
    googleMail: g.googleMail,
    userType: "guardian",
    name: g.name,
    photoUrl: g.photoUrl,
  };
}
