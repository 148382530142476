import { readonly, Ref, ref } from "vue";
import { delay } from "@/ts/utils/common-util";
import { DeepReadonly } from "@vue/reactivity";

export function useHovering(delayMillis: number = 0): {
  hovering: DeepReadonly<Ref<boolean>>;
  onMouseEnter: () => Promise<void>;
  onMouseLeave: () => void;
} {
  const active = ref(false);
  const hovering = ref(false);

  const onMouseEnter = async () => {
    hovering.value = true;
    await delay(delayMillis);
    if (hovering.value) active.value = true;
  };
  const onMouseLeave = () => {
    hovering.value = false;
    active.value = false;
  };

  return {
    hovering: readonly(active),
    onMouseEnter,
    onMouseLeave,
  };
}
