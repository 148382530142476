
import { defineComponent, PropType } from "vue";
import SearchConditionRadio from "@/components/radios/SearchConditionRadio/SearchConditionRadio.vue";

/**
 * SearchConditionRadioの、ただテキストラベルがあるだけのやつ。
 */
export default defineComponent({
  name: "SearchConditionRadioSimple",
  components: { SearchConditionRadio },
  props: {
    /**
     * ラジオボタングループの名前。
     */
    radioGroupName: { type: String, required: true },
    /**
     * ラジオボタングループの中で選択中の値。
     */
    selectedRadioValue: { type: String, required: true },
    /**
     * ラジオボタンを選択した時に送信される値。
     */
    radioValue: { type: String, required: true },
    /**
     * ラジオボタンのラベルテキスト。
     */
    text: { type: String, required: true },

    onSelect: {
      type: Function as PropType<(radioValue: string) => void>,
      required: true,
    },
  },
});
