
import { computed, defineComponent, PropType, ref } from "vue";
import SearchConditionPopupFrame from "@/components/search-condition/SearchConditionPopupFrame/SearchConditionPopupFrame.vue";
import { SearchConditionClass } from "@/ts/objects/search-condition/search-condition-class";
import SearchConditionRadioSimple from "@/components/radios/SearchConditionRadioSimple/SearchConditionRadioSimple.vue";
import SearchConditionPopupClassSelectRadio from "@/components/search-condition/SearchConditionPopupClass/SearchConditionPopupClassSelectRadio/SearchConditionPopupClassSelectRadio.vue";
import { SchoolType } from "@/ts/objects/value/school-type";
import { Class } from "@/ts/objects/entity/class";
import { hasValue, isNullish } from "@/ts/utils/common-util";
import { createGrade } from "@/ts/objects/value/grade";

const radioValues = ["all", "select-class", "no-class"] as const;
type RadioValue = typeof radioValues[number];

function isRadioValue(value: unknown): value is RadioValue {
  return typeof value === "string" && radioValues.some((v) => v === value);
}

// TODO 年度を変えたあと、開き直してバグってないことの確認。

export default defineComponent({
  name: "SearchConditionPopupClass",
  components: {
    SearchConditionRadioSimple,
    SearchConditionPopupClassSelectRadio,
    SearchConditionPopupFrame,
  },
  props: {
    schoolYear: { type: Number, required: true },
    /**
     * 選択肢に現れる可能性のあるすべてのクラス。
     *
     * （選択中の年度の）すべての学校タイプ・学年のクラスを含んでいること。
     * 適切な並び順になっていること。
     */
    allClasses: { type: Array as PropType<Class[]>, required: true },

    /**
     * 現在の検索条件。
     * ポップアップの初期値に使う。
     */
    searchCondition: {
      type: Object as PropType<SearchConditionClass>,
      required: true,
    },

    /**
     * キャンセルして閉じる。
     */
    cancel: { type: Function as PropType<() => void>, required: true },
    /**
     * 確定して閉じる。
     */
    confirm: {
      type: Function as PropType<(cond: SearchConditionClass) => void>,
      required: true,
    },
  },
  setup(props) {
    const initialCond = fromSearchCondition(
      props.schoolYear,
      props.searchCondition,
      props.allClasses,
    );

    const selectedRadioValue = ref<RadioValue>(initialCond.radioValue);

    const selectedSchoolType = ref<SchoolType>(initialCond.schoolType);
    const selectedGradeNumber = ref<number | null>(initialCond.gradeNumber);
    const selectedClass = ref<Class | null>(initialCond.class);

    const selectedClassId = computed(
      () => selectedClass.value?.classId ?? null,
    );

    return {
      radioGroupName: "class",
      selectedRadioValue,

      selectedSchoolType,
      selectedGradeNumber,
      selectedClassId,

      onSelect: (value: string) => {
        if (!isRadioValue(value)) return;
        selectedRadioValue.value = value;
      },
      onChangeClassSelect: (
        schoolType: SchoolType, // 選択中の学校タイプ。
        gradeNumber: number | null, // 選択中の学年。
        cls: Class | null, // 選択中のクラス。
      ) => {
        selectedSchoolType.value = schoolType;
        selectedGradeNumber.value = gradeNumber;
        selectedClass.value = cls;
      },
      onClickConfirm: () => {
        const searchCondition = toSearchCondition(
          props.schoolYear,
          selectedRadioValue.value,
          selectedSchoolType.value,
          selectedGradeNumber.value,
          selectedClass.value,
        );
        props.confirm(searchCondition);
      },
    };
  },
});

/**
 * 検索条件から本ポップアップの初期値を作成する。
 */
function fromSearchCondition(
  schoolYear: number,
  cond: SearchConditionClass,
  allClasses: Class[],
): {
  radioValue: RadioValue;
  schoolType: SchoolType;
  gradeNumber: number | null;
  class: Class | null;
} {
  const defaultValues = {
    radioValue: "all" as const,
    schoolType: "elementary" as const,
    gradeNumber: null,
    class: null,
  };

  if (cond.schoolYear !== schoolYear) return defaultValues;

  switch (cond.subType) {
    case "all":
      return defaultValues;
    case "school-type":
      return {
        radioValue: "select-class",
        schoolType: cond.schoolType,
        gradeNumber: null,
        class: null,
      };
    case "grade":
      return {
        radioValue: "select-class",
        schoolType: cond.grade.schoolType,
        gradeNumber: cond.grade.gradeNumber,
        class: null,
      };
    case "class":
      return {
        radioValue: "select-class",
        schoolType: cond.grade.schoolType,
        gradeNumber: cond.grade.gradeNumber,
        class: allClasses.find((c) => c.classId === cond.classId) ?? null,
      };
    case "no-class":
      return {
        radioValue: "no-class",
        schoolType: "elementary",
        gradeNumber: null,
        class: null,
      };
  }
}

/**
 * 本ポップアップの選択値から検索条件を作成する。
 */
function toSearchCondition(
  schoolYear: number,
  radioValue: RadioValue,
  schoolType: SchoolType,
  gradeNumber: number | null,
  cls: Class | null,
): SearchConditionClass {
  const commonAttr = {
    searchConditionType: "class" as const,
    schoolYear,
  };
  const defaultCond = {
    ...commonAttr,
    subType: "all" as const,
  };

  switch (radioValue) {
    case "all":
      return defaultCond;
    case "select-class": {
      // const displayValue = displayValueFor(schoolType, gradeNumber, cls);
      if (isNullish(gradeNumber) && isNullish(cls))
        return {
          ...commonAttr,
          subType: "school-type",
          schoolType,
        };
      if (hasValue(gradeNumber) && isNullish(cls)) {
        const gradeResult = createGrade(schoolType, gradeNumber);
        if (!gradeResult.ok) return defaultCond;
        return {
          ...commonAttr,
          subType: "grade",
          grade: gradeResult.data,
        };
      }
      if (hasValue(gradeNumber) && hasValue(cls)) {
        const gradeResult = createGrade(schoolType, gradeNumber);
        if (!gradeResult.ok) return defaultCond;
        return {
          ...commonAttr,
          subType: "class",
          grade: gradeResult.data,
          className: cls.name,
          classId: cls.classId,
        };
      }
      return defaultCond;
    }
    case "no-class":
      return {
        ...commonAttr,
        subType: "no-class",
      };
  }
}
