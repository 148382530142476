import { Result } from "@/ts/app/result";
import { Ref, shallowRef } from "vue";
import debounce from "lodash/debounce";
import {
  applyResult,
  LoadableData,
  loadableDataNull,
  loadableDataStale,
  toLoading,
} from "@/ts/app/loadable-data";
import { hasValue } from "@/ts/utils/common-util";
import { AppError } from "@/ts/app/error/app-error";

export function useDebouncedDataLoading<T, ARG>(
  initValue: T | null,
  load: (args: ARG) => Promise<Result<T>>,
  delayMillis: number,
  clearOnStartLoading: boolean = false,
  clearDataOnError: boolean = false,
  onError?: (err: AppError) => void,
): [Ref<LoadableData<T>>, (args: ARG) => void, () => void] {
  // 参考: https://github.com/vuejs/vue-next/issues/1324
  const value = shallowRef<LoadableData<T>>(
    hasValue(initValue) ? loadableDataStale(initValue) : loadableDataNull(),
  );

  const f = debounce(async (args: ARG) => {
    const result = await load(args);
    value.value = applyResult(value.value, result, clearDataOnError);

    if (!result.ok && hasValue(onError)) onError(result.error);
  }, delayMillis);

  const debouncedLoad = (args: ARG) => {
    if (clearOnStartLoading) {
      value.value = loadableDataNull();
    } else {
      value.value = toLoading(value.value);
    }
    f(args);
  };

  const clearValue = () => (value.value = loadableDataNull());

  return [value, debouncedLoad, clearValue];
}
