import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-20efd153"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "school-edit-table-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SchoolEditTableRowText = _resolveComponent("SchoolEditTableRowText")!
  const _component_SchoolEditTableRowImage = _resolveComponent("SchoolEditTableRowImage")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    _createVNode(_component_SchoolEditTableRowText, {
      class: "row",
      name: _ctx.schoolNameDisplayName,
      value: _ctx.model.schoolName,
      editing: _ctx.editing,
      "on-input": _ctx.onInputSchoolNameInternal,
      "name-cell-width": _ctx.nameCellWidth,
      "value-cell-width": _ctx.valueCellWidth
    }, null, 8, ["name", "value", "editing", "on-input", "name-cell-width", "value-cell-width"]),
    (_openBlock(), _createBlock(_component_SchoolEditTableRowImage, {
      key: _ctx.logoUpdatedAt,
      class: "row logo-row",
      name: _ctx.schoolLogoDisplayName,
      "gcs-url": _ctx.model.logoGcsUrl,
      editing: _ctx.editing,
      "on-input": _ctx.onInputLogoInternal,
      "name-cell-width": _ctx.nameCellWidth,
      "value-cell-width": _ctx.valueCellWidth
    }, null, 8, ["name", "gcs-url", "editing", "on-input", "name-cell-width", "value-cell-width"]))
  ]))
}