
import { computed, defineComponent, PropType } from "vue";
import { ClassListItemData } from "@/views/class/ClassList/class-list-data";
import { useHovering } from "@/composables/use-hovering";
import BaseSmallButton from "@/components/buttons/BaseSmallButton/BaseSmallButton.vue";
import EditableItemText from "@/components/editable-items/EditableItemText/EditableItemText.vue";
import RemoveButton from "@/components/buttons/RemoveButton/RemoveButton.vue";
import { isNullish } from "@/ts/utils/common-util";

export default defineComponent({
  name: "ClassListTableRowItem",
  components: { RemoveButton, EditableItemText, BaseSmallButton },
  props: {
    height: { type: Number, required: true },

    data: { type: Object as PropType<ClassListItemData>, required: true },
    editing: { type: Boolean, required: true },

    onInputName: {
      type: Function as PropType<(name: string) => void>,
      required: true,
    },
    onClickUp: { type: Function as PropType<() => void>, required: true },
    onClickDown: { type: Function as PropType<() => void>, required: true },
    onClickRemove: { type: Function as PropType<() => void>, required: true },
  },
  setup(props) {
    const { hovering, onMouseEnter, onMouseLeave } = useHovering();

    // 新規作成データならtrue。このときだけ削除できる。
    const isDataNew = computed(() => isNullish(props.data.classId));

    return {
      hovering,
      onMouseEnter,
      onMouseLeave,

      isDataNew,

      onInputNameInternal: (name: string) => {
        if (!props.editing) return;
        props.onInputName(name);
      },
      onClickUpInternal: () => {
        if (!props.editing) return;
        props.onClickUp();
      },
      onClickDownInternal: () => {
        if (!props.editing) return;
        props.onClickDown();
      },
      onClickRemoveInternal: () => {
        // 新規作成データのみ削除できる。
        if (!props.editing || !isDataNew.value) return;
        props.onClickRemove();
      },

      styles: {
        "--height": `${props.height}px`,
      },
    };
  },
});
