import log, { LogLevelDesc } from "loglevel";
import { AuthService, createAuthService } from "@/ts/services/auth-service";
import { getLogLevel } from "@/ts/utils/logging";
import { createPinia, Pinia } from "pinia";
import { useAppStore } from "@/store/app-store";
import {
  UserService,
  UserServiceImpl,
  UserServiceMock,
} from "@/ts/services/user-service";
import axios, { AxiosInstance } from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { hasValue } from "@/ts/utils/common-util";
import { FirebaseApp } from "firebase/app";
import { FirebaseStorage } from "firebase/storage";
import { initializeFirebase } from "@/ts/init/initialize-firebase";
import {
  CurriculumService,
  CurriculumServiceImpl,
  CurriculumServiceMock,
} from "@/ts/services/curriculum-service";

// TODO クライアントID等のべた書き方式は、他の学校でも使うならやめたほうがいいな・・・。
const HOST_TO_CONFIG = new Map<string, AppConfig>([
  [
    "develop0.admin.seto-solan.ed.jp",
    {
      clientId:
        "336753560362-3ugsjam3c4l5eior42gvfphdrci1onmt.apps.googleusercontent.com",
      serviceHostName: "develop0.manaport.seto-solan.ed.jp",
      storageBucket: "gs://eportfolio-dev0-storage",
      defaultLogLevel: log.levels.DEBUG,
    },
  ],
  [
    "develop1.admin.seto-solan.ed.jp",
    {
      clientId:
        "143483732184-sv0mrcaldf13r2oon9amqtrkum7evbsg.apps.googleusercontent.com",
      serviceHostName: "develop1.manaport.seto-solan.ed.jp",
      storageBucket: "gs://eportfolio-dev1-storage",
      defaultLogLevel: log.levels.DEBUG,
    },
  ],
  [
    "develop2.admin.seto-solan.ed.jp",
    {
      clientId:
        "513789769022-iukf8o7mhic6oofh9ugoia4jbe0eh9bf.apps.googleusercontent.com",
      serviceHostName: "develop2.manaport.seto-solan.ed.jp",
      storageBucket: "gs://eportfolio-dev2-storage",
      defaultLogLevel: log.levels.DEBUG,
    },
  ],
  [
    "develop3.admin.seto-solan.ed.jp",
    {
      clientId:
        "277774883463-2mh5erqf8uamsib5aveqcl0r53ceo2l7.apps.googleusercontent.com",
      serviceHostName: "develop3.manaport.seto-solan.ed.jp",
      storageBucket: "gs://eportfolio-dev3-storage",
      defaultLogLevel: log.levels.DEBUG,
    },
  ],
  [
    "develop4.admin.seto-solan.ed.jp",
    {
      clientId:
        "549131332481-v34df8jsrc19olbebiert8ok0ug7617g.apps.googleusercontent.com",
      serviceHostName: "develop4.manaport.seto-solan.ed.jp",
      storageBucket: "gs://eportfolio-dev4-storage",
      defaultLogLevel: log.levels.DEBUG,
    },
  ],
  [
    "testing.admin.seto-solan.ed.jp",
    {
      clientId:
        "335621474908-oco8kmcni4pqs7eq6mpoe98j9rbsb1sq.apps.googleusercontent.com",
      serviceHostName: "testing.manaport.seto-solan.ed.jp",
      storageBucket: "gs://eportfolio-testing-storage",
      defaultLogLevel: log.levels.DEBUG,
    },
  ],
  [
    "staging.admin.seto-solan.ed.jp",
    {
      clientId:
        "486707120532-rumlbb970u96kmu1litm6udnus04ud5d.apps.googleusercontent.com",
      serviceHostName: "staging.manaport.seto-solan.ed.jp",
      storageBucket: "gs://eportfolio-staging-storage",
      defaultLogLevel: log.levels.INFO,
    },
  ],
  [
    "www.admin.seto-solan.ed.jp",
    {
      clientId:
        "1095247098471-eq0j0la5njv1220lepsv8910868lqpa8.apps.googleusercontent.com",
      serviceHostName: "www.manaport.seto-solan.ed.jp",
      storageBucket: "gs://eportfolio-production-storage",
      defaultLogLevel: log.levels.INFO,
    },
  ],
]);

export type AppConfig = {
  readonly clientId: string | null;
  readonly serviceHostName: string;
  readonly storageBucket: string;
  readonly defaultLogLevel: LogLevelDesc;
};

export type DebugConfig =
  | {
      readonly debugging: false;
    }
  | {
      readonly debugging: true;
      readonly fakeUserInfo: {
        readonly userId: string;
        readonly googleMail: string;
        readonly imageUrl: string;
      };
    };

export type InitializationResult = {
  readonly authService: AuthService;
  readonly userService: UserService;
  readonly curriculumService: CurriculumService;
  readonly firebaseApp: FirebaseApp | null;
  readonly firebaseStorage: FirebaseStorage | null;
  readonly pinia: Pinia;
};

export async function initializeApp(
  localDebugging: boolean,
): Promise<InitializationResult> {
  const conf: AppConfig = HOST_TO_CONFIG.get(window.location.host) ?? {
    clientId: "dummy-client-id",
    serviceHostName: "dummy-service-host-name",
    storageBucket: "dummy-storage-bucket",
    defaultLogLevel: log.levels.DEBUG,
  };

  log.setDefaultLevel(conf.defaultLogLevel);
  console.log(
    `Log level is "${getLogLevel()}". Run "setLogLevel('debug')" to change.`,
  );
  log.debug(`conf=${JSON.stringify(conf)}`);

  const debugConfig: DebugConfig = localDebugging
    ? {
        debugging: true,
        fakeUserInfo: {
          userId: "admin000",
          googleMail: "admin000@seto-solan.ed.jp",
          imageUrl: "https://avatars.dicebear.com/api/bottts/anbtrewqhb.svg",
        },
      }
    : {
        debugging: false,
      };

  const pinia = createPinia();
  const appStore = useAppStore(pinia);

  const authService = await createAuthService(conf.clientId, debugConfig);
  const userService = debugConfig.debugging
    ? new UserServiceMock()
    : new UserServiceImpl(
        `https://${conf.serviceHostName}/api/user`,
        undefined,
        getAxiosInstance(pinia, authService),
      );
  const curriculumService = debugConfig.debugging
    ? new CurriculumServiceMock()
    : new CurriculumServiceImpl(
        `https://${conf.serviceHostName}/api/curriculum`,
        undefined,
        getAxiosInstance(pinia, authService),
      );

  const loginInfo = await authService.getLoginInfo(false);

  appStore.loginInfo = loginInfo;

  const [{ firebaseApp, firebaseStorage }] = await Promise.all([
    initializeFirebase(conf, debugConfig, loginInfo, userService),
    appStore.setIsAdmin(userService),
    appStore.setSchool(userService),
    appStore.setCurrentSchoolYear(userService),
  ]);

  log.debug("Initialized app.");

  return {
    authService,
    userService,
    curriculumService,
    firebaseApp,
    firebaseStorage,
    pinia,
  };
}

function getAxiosInstance(
  pinia: Pinia,
  authService: AuthService,
): AxiosInstance {
  const instance = axios.create();
  instance.defaults.timeout = 15000;
  instance.defaults.withCredentials = false;
  instance.interceptors.request.use(
    function (config) {
      const appStore = useAppStore(pinia);
      const idToken = appStore.loginInfo.googleIdToken;
      if (hasValue(idToken)) {
        // @ts-ignore
        config.headers["Authorization"] = `Bearer ${idToken}`; // TODO とりあえずts-ignoreしているが、もっと綺麗にできる？なんでheadersはundefinedでありうるのか？
      }
      return config;
    },
    function (error) {
      return Promise.reject(error);
    },
  );
  createAuthRefreshInterceptor(instance, async (): Promise<any> => {
    log.debug("createAuthRefreshInterceptor:refreshAuthCall");
    await authService.goLogin();
    return Promise.resolve();
  });
  return instance;
}
