import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-65dd0910"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "inner-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchConditionRadioSimple = _resolveComponent("SearchConditionRadioSimple")!
  const _component_SearchConditionPopupFrame = _resolveComponent("SearchConditionPopupFrame")!

  return (_openBlock(), _createBlock(_component_SearchConditionPopupFrame, {
    class: "search-condition-popup-num-results-container",
    label: "表示件数",
    "confirm-button-label": "表示件数を適用",
    width: 200,
    "on-click-cancel": _ctx.cancel,
    "on-click-confirm": _ctx.onClickConfirm
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_SearchConditionRadioSimple, {
          class: "radio-item",
          "radio-group-name": _ctx.radioGroupName,
          "radio-value": "10",
          "selected-radio-value": _ctx.selectedRadioValue,
          text: "10件",
          "on-select": _ctx.onSelect
        }, null, 8, ["radio-group-name", "selected-radio-value", "on-select"]),
        _createVNode(_component_SearchConditionRadioSimple, {
          class: "radio-item",
          "radio-group-name": _ctx.radioGroupName,
          "radio-value": "20",
          "selected-radio-value": _ctx.selectedRadioValue,
          text: "20件",
          "on-select": _ctx.onSelect
        }, null, 8, ["radio-group-name", "selected-radio-value", "on-select"]),
        _createVNode(_component_SearchConditionRadioSimple, {
          class: "radio-item",
          "radio-group-name": _ctx.radioGroupName,
          "radio-value": "50",
          "selected-radio-value": _ctx.selectedRadioValue,
          text: "50件",
          "on-select": _ctx.onSelect
        }, null, 8, ["radio-group-name", "selected-radio-value", "on-select"]),
        _createVNode(_component_SearchConditionRadioSimple, {
          class: "radio-item",
          "radio-group-name": _ctx.radioGroupName,
          "radio-value": "100",
          "selected-radio-value": _ctx.selectedRadioValue,
          text: "100件",
          "on-select": _ctx.onSelect
        }, null, 8, ["radio-group-name", "selected-radio-value", "on-select"]),
        _createVNode(_component_SearchConditionRadioSimple, {
          class: "radio-item",
          "radio-group-name": _ctx.radioGroupName,
          "radio-value": "500",
          "selected-radio-value": _ctx.selectedRadioValue,
          text: "500件",
          "on-select": _ctx.onSelect
        }, null, 8, ["radio-group-name", "selected-radio-value", "on-select"]),
        _createVNode(_component_SearchConditionRadioSimple, {
          class: "radio-item",
          "radio-group-name": _ctx.radioGroupName,
          "radio-value": "1000",
          "selected-radio-value": _ctx.selectedRadioValue,
          text: "1000件",
          "on-select": _ctx.onSelect
        }, null, 8, ["radio-group-name", "selected-radio-value", "on-select"])
      ])
    ]),
    _: 1
  }, 8, ["on-click-cancel", "on-click-confirm"]))
}