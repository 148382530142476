import { Student } from "@/ts/objects/entity/student";
import {
  StudentProfileColumnDefGroup,
  StudentProfileColumnId,
  studentProfileColumnDefs,
  studentProfileColumnGroupName,
  StudentProfileColumnGroupName,
  StudentProfileColumnGroup,
} from "@/ts/app/columns/def/student/student-profile-column";
import {
  StudentHealthColumnDefGroup,
  StudentHealthColumnId,
  studentHealthColumnDefs,
  studentHealthColumnGroupName,
  StudentHealthColumnGroupName,
  StudentHealthColumnGroup,
} from "@/ts/app/columns/def/student/student-health-column";
import {
  StudentCustomColumnDefGroup,
  StudentCustomColumnId,
  studentCustomColumnDefs,
  studentCustomColumnGroupName,
  StudentCustomColumnGroupName,
  StudentCustomColumnGroup,
} from "@/ts/app/columns/def/student/student-custom-column";
import { UserColumnDef } from "@/ts/app/columns/def/user-column-def";
import {
  attachValueToCols,
  UserColumn,
} from "@/ts/app/columns/def/user-column";
import {
  StudentCustomColumnNames,
  studentCustomColumnNamesFromStudent,
} from "@/ts/objects/value/student-custom-column-names";
import { createTypedObjectFromEntries } from "@/ts/utils/common-util";
import { ObjectInternalName } from "@/ts/app/object-name";

export type StudentColumnGroupName =
  | StudentProfileColumnGroupName
  | StudentHealthColumnGroupName
  | StudentCustomColumnGroupName;

export type StudentColumnId =
  | StudentProfileColumnId
  | StudentHealthColumnId
  | StudentCustomColumnId;

export type StudentColumnDef<
  ColId extends ObjectInternalName,
  T,
> = UserColumnDef<ColId, Student, T>;

export type StudentColumn<ColId extends ObjectInternalName, T> = UserColumn<
  ColId,
  Student,
  T
>;

export type StudentColumnDefGroup =
  | StudentProfileColumnDefGroup
  | StudentHealthColumnDefGroup
  | StudentCustomColumnDefGroup;

export function getStudentColumnDefs(
  schoolYear: number,
  colNames: StudentCustomColumnNames,
): StudentColumnDef<StudentColumnId, any>[] {
  return [
    ...studentProfileColumnDefs(schoolYear),
    ...studentHealthColumnDefs,
    ...studentCustomColumnDefs(colNames),
  ];
}

export function getStudentColumnDefsGrouped(
  schoolYear: number,
  colNames: StudentCustomColumnNames,
): {
  profileColumnDefGroup: StudentProfileColumnDefGroup;
  healthColumnDefGroup: StudentHealthColumnDefGroup;
  customColumnDefGroup: StudentCustomColumnDefGroup;
} {
  return {
    profileColumnDefGroup: {
      groupName: studentProfileColumnGroupName,
      columns: studentProfileColumnDefs(schoolYear),
    },
    healthColumnDefGroup: {
      groupName: studentHealthColumnGroupName,
      columns: studentHealthColumnDefs,
    },
    customColumnDefGroup: {
      groupName: studentCustomColumnGroupName,
      columns: studentCustomColumnDefs(colNames),
    },
  };
}

export function getStudentColumnDefsAsDict(
  schoolYear: number,
  colNames: StudentCustomColumnNames,
): Record<StudentColumnId, StudentColumnDef<StudentColumnId, any>> {
  return createTypedObjectFromEntries(
    getStudentColumnDefs(schoolYear, colNames).map(
      (col): [StudentColumnId, StudentColumnDef<StudentColumnId, any>] => [
        col.id,
        col,
      ],
    ),
  );
}

export function getStudentColumns(
  schoolYear: number,
  student: Student,
): StudentColumn<StudentColumnId, any>[] {
  const columnDefs = getStudentColumnDefs(
    schoolYear,
    studentCustomColumnNamesFromStudent(student),
  );
  return attachValueToCols(columnDefs, student);
}

export function getStudentColumnsGrouped(
  schoolYear: number,
  student: Student,
): {
  profileColumnGroup: StudentProfileColumnGroup;
  healthColumnGroup: StudentHealthColumnGroup;
  customColumnGroup: StudentCustomColumnGroup;
} {
  const { profileColumnDefGroup, healthColumnDefGroup, customColumnDefGroup } =
    getStudentColumnDefsGrouped(
      schoolYear,
      studentCustomColumnNamesFromStudent(student),
    );
  return {
    profileColumnGroup: {
      groupName: studentProfileColumnGroupName,
      columns: attachValueToCols(profileColumnDefGroup.columns, student),
    },
    healthColumnGroup: {
      groupName: studentHealthColumnGroupName,
      columns: attachValueToCols(healthColumnDefGroup.columns, student),
    },
    customColumnGroup: {
      groupName: studentCustomColumnGroupName,
      columns: attachValueToCols(customColumnDefGroup.columns, student),
    },
  };
}
