
import { defineComponent, PropType, ref } from "vue";
import SearchConditionPopupFrame from "@/components/search-condition/SearchConditionPopupFrame/SearchConditionPopupFrame.vue";
import SearchConditionRadioSimple from "@/components/radios/SearchConditionRadioSimple/SearchConditionRadioSimple.vue";
import {
  isStudentSortConditionSubType,
  StudentSortCondition,
  StudentSortConditionSubType,
} from "@/ts/objects/search-condition/student-sort-condition";

export default defineComponent({
  name: "SearchConditionPopupStudentSort",
  components: { SearchConditionRadioSimple, SearchConditionPopupFrame },
  props: {
    /**
     * 年度。
     */
    schoolYear: { type: Number, required: true },
    /**
     * 現在の並び替え条件。
     * ポップアップの初期値に使う。
     */
    searchCondition: {
      type: Object as PropType<StudentSortCondition>,
      required: true,
    },

    /**
     * キャンセルして閉じる。
     */
    cancel: { type: Function as PropType<() => void>, required: true },
    /**
     * 確定して閉じる。
     */
    confirm: {
      type: Function as PropType<(cond: StudentSortCondition) => void>,
      required: true,
    },
  },
  setup(props) {
    const selectedRadioValue = ref(props.searchCondition.subType);
    return {
      radioGroupName: "sort",
      selectedRadioValue,

      onSelect: (value: string) => {
        if (!isStudentSortConditionSubType(value)) return;
        selectedRadioValue.value = value;
      },
      onClickConfirm: () => {
        props.confirm(
          toSearchCondition(props.schoolYear, selectedRadioValue.value),
        );
      },
    };
  },
});

/**
 * 本ポップアップの選択値から検索条件を作成する。
 */
function toSearchCondition(
  schoolYear: number,
  radioValue: StudentSortConditionSubType,
): StudentSortCondition {
  const commonAttr = {
    searchConditionType: "sort-student" as const,
  };

  switch (radioValue) {
    case "gradeClassStudentNumber":
      return {
        ...commonAttr,
        subType: "gradeClassStudentNumber",
        schoolYear,
      };
    case "nameKanaAsc":
      return {
        ...commonAttr,
        subType: "nameKanaAsc",
      };
    case "nameKanaDesc":
      return {
        ...commonAttr,
        subType: "nameKanaDesc",
      };
    case "createdAtDesc":
      return {
        ...commonAttr,
        subType: "createdAtDesc",
      };
    case "createdAtAsc":
      return {
        ...commonAttr,
        subType: "createdAtAsc",
      };
    case "updatedAtDesc":
      return {
        ...commonAttr,
        subType: "updatedAtDesc",
      };
    case "updatedAtAsc":
      return {
        ...commonAttr,
        subType: "updatedAtAsc",
      };
  }
}
