
import { computed, defineComponent, PropType } from "vue";
import TermEditTable from "@/views/term/TermEdit/TermEditTable/TermEditTable.vue";
import SchoolTypeTabs from "@/components/other/SchoolTypeTabs/SchoolTypeTabs.vue";
import TermEditHeader from "@/views/term/TermEdit/TermEditHeader/TermEditHeader.vue";
import AddTermButton from "@/components/buttons/AddButton/AddTermButton.vue";
import AddYearButton from "@/components/buttons/AddButton/AddYearButton.vue";
import { SchoolType } from "@/ts/objects/value/school-type";
import {
  TermEditTableShape,
  TermTableModel,
  termTableModelFromTerms,
  UnsavedTermEditData,
} from "@/views/term/TermEdit/term-edit-data";
import { LoadableData } from "@/ts/app/loadable-data";
import { Term } from "@/ts/objects/entity/term";
import { hasValue } from "@/ts/utils/common-util";
import { DateValue } from "@/ts/objects/value/date-value";
import LoadingBlock from "@/components/loading/LoadingBlock/LoadingBlock.vue";

export default defineComponent({
  name: "TermEdit",
  components: {
    LoadingBlock,
    TermEditHeader,
    SchoolTypeTabs,
    TermEditTable,
    AddTermButton,
    AddYearButton,
  },
  props: {
    isAdmin: { type: Boolean, required: true },
    /**
     * 現在選択中のタブ。
     */
    currentTab: { type: String as PropType<SchoolType>, required: true },
    /**
     * タブを変更する。
     */
    changeTab: {
      type: Function as PropType<(tab: SchoolType) => void>,
      required: true,
    },

    /**
     * 保存済の学期データ。
     */
    terms: { type: Object as PropType<LoadableData<Term[]>>, required: true },

    tableShape: {
      type: Object as PropType<TermEditTableShape>,
      required: true,
    },
    tableShapeOnEdit: {
      type: Object as PropType<TermEditTableShape>,
      required: true,
    },

    /**
     * 編集中の未保存の学期データ。
     */
    unsavedData: {
      type: Object as PropType<UnsavedTermEditData>,
      required: false,
    },

    /**
     * 学期を変更する。
     */
    onChangeTerm: {
      type: Function as PropType<
        (
          schoolType: SchoolType,
          schoolYear: number,
          termNumber: number,

          changedStartDate: boolean,
          newDate: DateValue | null,
        ) => void
      >,
      required: true,
    },
    /**
     * テーブルの学期番号(= 列)を末尾に追加する。
     */
    addTermNumber: {
      type: Function as PropType<() => void>,
      required: true,
    },
    /**
     * テーブルの学期番号(= 列)を末尾から削除する。
     */
    deleteTermNumber: {
      type: Function as PropType<() => void>,
      required: true,
    },
    /**
     * テーブルの年度(= 行)を末尾に追加する。
     */
    addSchoolYear: {
      type: Function as PropType<() => void>,
      required: true,
    },
    /**
     * テーブルの年度(= 行)を末尾から削除する。
     */
    deleteSchoolYear: {
      type: Function as PropType<() => void>,
      required: true,
    },

    /**
     * 編集を開始する。
     */
    startEditing: { type: Function as PropType<() => void>, required: true },
    /**
     * 編集をキャンセルする。
     */
    cancelEditing: { type: Function as PropType<() => void>, required: true },
    /**
     * セーブし、編集を終了する。
     */
    save: { type: Function as PropType<() => Promise<void>>, required: true },
  },
  setup(props) {
    const elementarySelected = computed(
      () => props.currentTab === "elementary",
    );

    const tableModel = computed<TermTableModel | null>(() => {
      const _unsavedData = props.unsavedData;
      const _schoolType = props.currentTab;
      const _terms = props.terms;

      if (hasValue(_unsavedData)) {
        return _unsavedData[_schoolType];
      } else if (_terms.hasData) {
        return termTableModelFromTerms(
          _terms.data.filter((t) => t.schoolType === _schoolType),
        );
      } else {
        return null;
      }
    });
    const tableShape = computed<TermEditTableShape>(() => {
      const _unsavedData = props.unsavedData;

      return hasValue(_unsavedData) ? props.tableShapeOnEdit : props.tableShape;
    });

    const onChangeTermInternal = (
      schoolYear: number,
      termNumber: number,

      changedStartDate: boolean,
      newDate: DateValue | null,
    ) =>
      props.onChangeTerm(
        props.currentTab,
        schoolYear,
        termNumber,
        changedStartDate,
        newDate,
      );

    return {
      editing: computed(() => hasValue(props.unsavedData)),
      tableModel,

      maxTermNumber: computed(() =>
        elementarySelected.value
          ? tableShape.value.elementaryMaxTermNumber
          : tableShape.value.juniorhighMaxTermNumber,
      ),
      minSchoolYear: computed(() =>
        elementarySelected.value
          ? tableShape.value.elementaryMinSchoolYear
          : tableShape.value.juniorhighMinSchoolYear,
      ),
      maxSchoolYear: computed(() =>
        elementarySelected.value
          ? tableShape.value.elementaryMaxSchoolYear
          : tableShape.value.juniorhighMaxSchoolYear,
      ),
      onChangeTermInternal,
    };
  },
});
