import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9fa320c2"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "user-edit-header-user-name",
    style: _normalizeStyle(_ctx.styles)
  }, [
    (_ctx.photoUrl !== '')
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          class: "icon",
          src: _ctx.photoUrl,
          alt: "user icon"
        }, null, 8, _hoisted_1))
      : _createCommentVNode("", true),
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.name), 1)
  ], 4))
}