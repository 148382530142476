// src/css/_color-variables.scss と対応している。変更する場合、両方とも変更すること。

const labelToColorDict = {
  transparent: "rgba(0, 0, 0, 0)",

  white: "#ffffff",
  "slightly-dark-white": "#f4f4f4",
  "dark-white": "#EEEEEE",
  "light-gray": "#DEDEDE",
  gray: "#B8B8B8",
  "dark-gray": "#7A7A7A",
  "light-black": "#505050",
  black: "#191919",
  "gray-transparent": "rgba(184, 184, 184, 0.2)",

  "light-green-transparent": "rgba(160, 232, 180, 0.4)",
  "light-green": "#DDF6F3",
  green: "#136060",
  "blue-green": "#269393",
  "dark-blue-green": "#0D6363",
  "light-blue": "#6674F2",
  cobalt: "#153fce",
  blue: "#3223DE",
  red: "#EB4040",
  ruby: "#C52F6E",
  "light-red": "#FF9292",
  "dark-red": "#C61A1A",
  "light-yellow": "#FAEFCA",

  "link-text": "#2F5FC5",
  "table-cell-0": "#E1F9F2",
  "table-cell-1": "#F5FFFB",
  "table-cell-2": "#f8fffc",
} as const;
export const colorLabels = Object.keys(labelToColorDict);
export type ColorLabel = keyof typeof labelToColorDict;

export function isColorLabel(value: unknown): value is ColorLabel {
  return typeof value === "string" && colorLabels.some((v) => v === value);
}

export function labelToColor(colorLabel: ColorLabel): string {
  return labelToColorDict[colorLabel];
}
