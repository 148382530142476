
import { defineComponent, PropType } from "vue";
import { AuthService } from "@/ts/services/auth-service";
import Main from "@/views/Main.vue";
import { UserService } from "@/ts/services/user-service";
import { provideCursorPosition } from "@/composables/provide-cursor-position";
import { provideUserService } from "@/composables/provide-user-service";
import { provideAuthService } from "@/composables/provide-auth-service";
import { provideFirebaseApp } from "@/composables/provide-firebase-app";
import { FirebaseApp } from "firebase/app";
import { FirebaseStorage } from "firebase/storage";
import { CurriculumService } from "@/ts/services/curriculum-service";
import { provideCurriculumService } from "@/composables/provide-curriculum-service";

export default defineComponent({
  name: "App",
  components: { Main },
  props: {
    authService: { type: Object as PropType<AuthService>, required: true },
    userService: { type: Object as PropType<UserService>, required: true },
    curriculumService: {
      type: Object as PropType<CurriculumService>,
      required: true,
    },
    firebaseApp: { type: Object as PropType<FirebaseApp>, required: false },
    firebaseStorage: {
      type: Object as PropType<FirebaseStorage>,
      required: false,
    },
  },
  setup(props) {
    provideAuthService(props.authService);
    provideCursorPosition();
    provideFirebaseApp(props.firebaseApp, props.firebaseStorage);
    provideUserService(props.userService);
    provideCurriculumService(props.curriculumService);
  },
});
