import { isNullish } from "@/ts/utils/common-util";
import { Result } from "@/ts/app/result";
import { errors, ThrowableAppError } from "@/ts/app/error/app-error";
import { names, ObjectName } from "@/ts/app/object-name";

export const schoolTypes = {
  elementary: "小学校",
  juniorhigh: "中学校",
} as const;

/**
 * 学校タイプ。
 */
export type SchoolType = keyof typeof schoolTypes;
export type SchoolTypeDisplayValue = typeof schoolTypes[SchoolType];

export function isSchoolType(value: unknown): value is SchoolType {
  return (
    typeof value === "string" &&
    Object.keys(schoolTypes).some((v) => v === value)
  );
}

export function asSchoolType(value: unknown): Result<SchoolType> {
  if (isSchoolType(value)) return { ok: true, data: value };
  return {
    ok: false,
    error: errors.validationFailed.invalidValue(value, names.schoolType),
  };
}

export function asSchoolTypeOrNull(value: unknown): SchoolType | null {
  const result = asSchoolType(value);
  if (!result.ok) return null;
  return result.data;
}

export function asSchoolTypeOrError(value: unknown): SchoolType {
  const result = asSchoolType(value);
  if (!result.ok) throw new ThrowableAppError(result.error);
  return result.data;
}

export function schoolTypeToDisplayValue(
  value: SchoolType,
): SchoolTypeDisplayValue {
  return schoolTypes[value];
}

export function displayValueToSchoolType(
  displayValue: string,
  objectName: ObjectName = names.schoolType,
): Result<SchoolType> {
  const error = {
    ok: false,
    error: errors.validationFailed.invalidValue(displayValue, objectName),
  } as const;

  const kv = Object.entries(schoolTypes).find(([_k, v]) => v === displayValue);
  if (isNullish(kv)) return error;
  const k = kv[0];
  if (!isSchoolType(k)) return error;
  return { ok: true, data: k };
}

export function displayValueToSchoolTypeOrNull(
  displayValue: string,
): SchoolType | null {
  const result = displayValueToSchoolType(displayValue);
  if (!result.ok) return null;
  return result.data;
}

export function displayValueToSchoolTypeOrError(
  displayValue: string,
  objectName: ObjectName = names.schoolType,
): SchoolType {
  const result = displayValueToSchoolType(displayValue, objectName);
  if (!result.ok) throw new ThrowableAppError(result.error);
  return result.data;
}

export function schoolTypeFromStringOrError(value: string): SchoolType {
  if (isSchoolType(value)) {
    return value;
  }
  throw new Error(
    `schoolTypeFromStringOrError: invalid school type value: ${value}`,
  );
}
