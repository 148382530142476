import { combineResults, Result } from "@/ts/app/result";
import {
  errors,
  handleThrownError,
  ThrowableAppError,
} from "@/ts/app/error/app-error";
import { Guardian, UpdatingGuardian } from "@/ts/objects/entity/guardian";
import { names, ObjectInternalName } from "@/ts/app/object-name";
import {
  dropAllUndefinedFields,
  isNullish,
  isStringBlank,
  typedObjectKeys,
} from "@/ts/utils/common-util";
import {
  ExportingRawCSVRow,
  ImportedRawCSVRow,
} from "@/ts/app/columns/csv/raw-csv";
import { validateAndRenameColsOfRow } from "@/ts/app/columns/csv/csv";
import pick from "lodash/pick";
import {
  GuardianListColumnId,
  guardianListColumnIds,
} from "@/ts/app/columns/user-list/guardian-list-columns";

export type ExportingRawGuardianCSVRow = Pick<
  ExportingRawCSVRow,
  GuardianListColumnId
>;

export type GuardianCSVFile = {
  filename: string;
  rows: GuardianCSVRow[];
};

/**
 * 保護者のCSV行。
 * 各列の値について、 undefined = 列自体が存在しない, null = 列は存在するが値がnull
 */
export type GuardianCSVRow = {
  rowNumber: number;

  userId: string;
  googleMail?: string;
  name1?: string;

  // Userのnameが、name1。
  nameKana1?: string;
  familyName1?: string;
  givenName1?: string;
  name2?: string;
  nameKana2?: string;
  familyName2?: string;
  givenName2?: string;
  email1?: string;
  email2?: string;
  mobilePhone1?: string;
  mobilePhone2?: string;
  landlinePhone1?: string;
  landlinePhone2?: string;
  job1?: string;
  job2?: string;
  workPlace1?: string;
  workPlace2?: string;
  pictureGcsUrl1?: string;
  pictureGcsUrl2?: string;
  emergencyContactRelationship1?: string;
  emergencyContactPhone1?: string;
  emergencyContactRelationship2?: string;
  emergencyContactPhone2?: string;
  emergencyContactRelationship3?: string;
  emergencyContactPhone3?: string;

  note01?: string;
  note02?: string;
  note03?: string;
  note04?: string;
  note05?: string;
  note06?: string;
  note07?: string;
  note08?: string;
  note09?: string;
  note10?: string;
};

export function importedToGuardianCSVRow(
  filename: string,
  rowNumber: number,
  rawRow: ImportedRawCSVRow,
  nameToId: Map<string, ObjectInternalName>,
): Result<GuardianCSVRow> {
  const result = validateAndRenameColsOfRow(
    names.guardianCSV,
    rawRow,
    nameToId,
    guardianListColumnIds,
  );
  if (!result.ok) {
    return {
      ok: false,
      error: result.error,
    };
  }

  const row = result.data;

  try {
    const userId = row["userId"];
    if (isStringBlank(userId))
      throw new ThrowableAppError(
        errors.validationFailed.nullValue(names.userId),
      );

    const parsed: GuardianCSVRow = {
      rowNumber,

      userId: userId ?? "",
      googleMail: row["googleMail"],
      name1: row["name1"],

      nameKana1: row["nameKana1"],
      familyName1: row["familyName1"],
      givenName1: row["givenName1"],
      name2: row["name2"],
      nameKana2: row["nameKana2"],
      familyName2: row["familyName2"],
      givenName2: row["givenName2"],
      email1: row["email1"],
      email2: row["email2"],
      mobilePhone1: row["mobilePhone1"],
      mobilePhone2: row["mobilePhone2"],
      landlinePhone1: row["landlinePhone1"],
      landlinePhone2: row["landlinePhone2"],
      job1: row["job1"],
      job2: row["job2"],
      workPlace1: row["workPlace1"],
      workPlace2: row["workPlace2"],
      pictureGcsUrl1: row["pictureGcsUrl1"],
      pictureGcsUrl2: row["pictureGcsUrl2"],
      emergencyContactRelationship1: row["emergencyContactRelationship1"],
      emergencyContactPhone1: row["emergencyContactPhone1"],
      emergencyContactRelationship2: row["emergencyContactRelationship2"],
      emergencyContactPhone2: row["emergencyContactPhone2"],
      emergencyContactRelationship3: row["emergencyContactRelationship3"],
      emergencyContactPhone3: row["emergencyContactPhone3"],

      note01: row["note01"],
      note02: row["note02"],
      note03: row["note03"],
      note04: row["note04"],
      note05: row["note05"],
      note06: row["note06"],
      note07: row["note07"],
      note08: row["note08"],
      note09: row["note09"],
      note10: row["note10"],
    };
    return { ok: true, data: parsed };
  } catch (e) {
    const appError = handleThrownError("toGuardianCSVRow", e);
    return {
      ok: false,
      error: errors.readCSVFailed(
        `${filename}: line ${rowNumber}: ${appError.name}: ${appError.internalMessage}`,
        `${filename}: ${rowNumber}行目: ${appError.displayMessage}`,
      ),
    };
  }
}

export function updateGuardianWithCSVRow(
  filename: string,
  g: Guardian,
  row: GuardianCSVRow,
): Result<UpdatingGuardian> {
  if (row.userId !== g.userId)
    throw new Error(
      `updateGuardianWithCSVRow: row userId ${row.userId} and ${names.guardian.i} userId ${g.userId} must be same`,
    );

  try {
    const partialGuardian: Partial<Guardian> = dropAllUndefinedFields({
      name: row.name1,

      nameKana1: row.nameKana1,
      familyName1: row.familyName1,
      givenName1: row.givenName1,
      name2: row.name2,
      nameKana2: row.nameKana2,
      familyName2: row.familyName2,
      givenName2: row.givenName2,
      email1: row.email1,
      email2: row.email2,
      mobilePhone1: row.mobilePhone1,
      mobilePhone2: row.mobilePhone2,
      landlinePhone1: row.landlinePhone1,
      landlinePhone2: row.landlinePhone2,
      job1: row.job1,
      job2: row.job2,
      workPlace1: row.workPlace1,
      workPlace2: row.workPlace2,
      // pictureGcsUrl1は、CSVによる読み込み不可。
      // pictureGcsUrl2は、CSVによる読み込み不可。
      emergencyContactRelationship1: row.emergencyContactRelationship1,
      emergencyContactPhone1: row.emergencyContactPhone1,
      emergencyContactRelationship2: row.emergencyContactRelationship2,
      emergencyContactPhone2: row.emergencyContactPhone2,
      emergencyContactRelationship3: row.emergencyContactRelationship3,
      emergencyContactPhone3: row.emergencyContactPhone3,

      note01: row.note01,
      note02: row.note02,
      note03: row.note03,
      note04: row.note04,
      note05: row.note05,
      note06: row.note06,
      note07: row.note07,
      note08: row.note08,
      note09: row.note09,
      note10: row.note10,
    });
    return {
      ok: true,
      data: {
        guardian: { ...g, ...partialGuardian },
        updatingFields: typedObjectKeys(partialGuardian),
      },
    };
  } catch (e) {
    const appError = handleThrownError("updateGuardianWithCSVRow", e);
    return {
      ok: false,
      error: errors.readCSVFailed(
        `${filename}: line ${row.rowNumber}: ${appError.name}: ${appError.internalMessage}`,
        `${filename}: ${row.rowNumber}行目: ${appError.displayMessage}`,
      ),
    };
  }
}

export function updateGuardiansWithCSVRows(
  filename: string,
  guardians: Guardian[],
  rows: GuardianCSVRow[],
): Result<UpdatingGuardian[]> {
  try {
    const updatingGuardians = rows.map((row) => {
      const guardian = guardians.find((g) => g.userId === row.userId);
      if (isNullish(guardian))
        throw new ThrowableAppError(
          errors.readCSVFailed(
            `${names.guardian.i} ${row.userId} not found`,
            `指定した${names.guardian.d}が見つかりません。(${names.userId.d}=${row.userId})`,
          ),
        );
      return updateGuardianWithCSVRow(filename, guardian, row);
    });
    return combineResults(updatingGuardians);
  } catch (e) {
    const appError = handleThrownError("updateGuardiansWithCSVRows", e);
    return { ok: false, error: appError };
  }
}

/**
 * guardianをCSVエクスポート用のオブジェクトに詰め替える。
 *
 * @param g 保護者
 * @param columnIds ここで指定した列のみをエクスポートする。
 */
export function guardianToExportingCSVRow(
  g: Guardian,
  columnIds: readonly GuardianListColumnId[],
): ExportingRawGuardianCSVRow {
  const row: Required<ExportingRawGuardianCSVRow> = {
    userId: g.userId,
    googleMail: g.googleMail,
    name1: g.name,

    nameKana1: g.nameKana1,
    familyName1: g.familyName1,
    givenName1: g.givenName1,
    name2: g.name2,
    nameKana2: g.nameKana2,
    familyName2: g.familyName2,
    givenName2: g.givenName2,

    children: g.children.map((c) => c.userId).join(","),

    email1: g.email1,
    email2: g.email2,
    mobilePhone1: g.mobilePhone1,
    mobilePhone2: g.mobilePhone2,
    landlinePhone1: g.landlinePhone1,
    landlinePhone2: g.landlinePhone2,
    job1: g.job1,
    job2: g.job2,
    workPlace1: g.workPlace1,
    workPlace2: g.workPlace2,
    pictureGcsUrl1: g.pictureGcsUrl1,
    pictureGcsUrl2: g.pictureGcsUrl2,
    emergencyContactRelationship1: g.emergencyContactRelationship1,
    emergencyContactPhone1: g.emergencyContactPhone1,
    emergencyContactRelationship2: g.emergencyContactRelationship2,
    emergencyContactPhone2: g.emergencyContactPhone2,
    emergencyContactRelationship3: g.emergencyContactRelationship3,
    emergencyContactPhone3: g.emergencyContactPhone3,

    note01: g.note01,
    note02: g.note02,
    note03: g.note03,
    note04: g.note04,
    note05: g.note05,
    note06: g.note06,
    note07: g.note07,
    note08: g.note08,
    note09: g.note09,
    note10: g.note10,
  };

  return pick(row, columnIds);
}
