
import { defineComponent, PropType } from "vue";
import { ColorLabel, labelToColor } from "@/ts/objects/value/color-label";

export default defineComponent({
  name: "UserEditHeaderUserName",
  props: {
    photoUrl: { type: String, required: true },
    name: { type: String, required: true },
    nameColor: { type: String as PropType<ColorLabel>, required: true },
  },
  setup(props) {
    return {
      styles: {
        "--display-name-color": labelToColor(props.nameColor),
      },
    };
  },
});
