
import { computed, defineComponent, PropType } from "vue";
import { StudentColumnVisibilityAll } from "@/ts/app/columns/visibility/student-column-visibility";
import UserColumnsSelectSection from "@/components/other/UserColumnsSelectSection/UserColumnsSelectSection.vue";
import CollapseButtonLR from "@/components/buttons/CollapseButtonLR/CollapseButtonLR.vue";
import { UserColumnVisibility } from "@/ts/app/columns/visibility/user-column-visibility";
import { useStudentStore } from "@/store/student-store";
import { useUserService } from "@/composables/provide-user-service";
import { names, ObjectInternalName } from "@/ts/app/object-name";
import {
  studentListCustomColumnIds,
  studentListHealthColumnIds,
  studentListProfileColumnIds,
} from "@/ts/app/columns/user-list/student-list-columns";
import { getStudentColumnDefsGrouped } from "@/ts/app/columns/def/student/student-column";

export default defineComponent({
  name: "StudentColumnsSelect",
  components: { CollapseButtonLR, UserColumnsSelectSection },
  props: {
    schoolYear: { type: Number, required: true },
    columnVisibility: {
      type: Object as PropType<StudentColumnVisibilityAll>,
      required: true,
    },
    open: { type: Boolean, required: true },

    onClickCollapseButton: {
      type: Function as PropType<() => void>,
      required: true,
    },
    onChangeColumnVisibility: {
      type: Function as PropType<
        (state: UserColumnVisibility<ObjectInternalName, string>) => void
      >,
      required: true,
    },
  },
  setup(props) {
    const studentStore = useStudentStore();
    const userService = useUserService();

    studentStore.loadCustomColumnNames(userService);

    const customColumnNames = computed(
      () => studentStore.customColumnNames.data,
    );

    const columnDefsGrouped = computed(() =>
      getStudentColumnDefsGrouped(props.schoolYear, customColumnNames.value),
    );

    const profileSectionTitle =
      names[props.columnVisibility.profileColumnVisibility.groupName].d;
    const profileColumnIds: string[] = studentListProfileColumnIds;
    const profileColumnDefs = computed(() =>
      columnDefsGrouped.value.profileColumnDefGroup.columns.filter((c) =>
        profileColumnIds.includes(c.id),
      ),
    );

    const healthSectionTitle =
      names[props.columnVisibility.healthColumnVisibility.groupName].d;
    const healthColumnIds: string[] = studentListHealthColumnIds;
    const healthColumnDefs = computed(() =>
      columnDefsGrouped.value.healthColumnDefGroup.columns.filter((c) =>
        healthColumnIds.includes(c.id),
      ),
    );

    const customSectionTitle =
      names[props.columnVisibility.customColumnVisibility.groupName].d;
    const customColumnIds: string[] = studentListCustomColumnIds;
    const customColumnDefs = computed(() =>
      columnDefsGrouped.value.customColumnDefGroup.columns.filter((c) =>
        customColumnIds.includes(c.id),
      ),
    );

    return {
      profileSectionTitle,
      profileColumnDefs,

      healthSectionTitle,
      healthColumnDefs,

      customSectionTitle,
      customColumnDefs,
    };
  },
});
