
import { defineComponent } from "vue";

export default defineComponent({
  name: "MainMenuItem",
  props: {
    iconType: { type: String, default: "fas" }, // fas or far
    icon: { type: String, required: true },
    label: { type: String, required: true },

    active: { type: Boolean, required: true },

    linkPath: { type: String, required: true },
  },
});
