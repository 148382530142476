import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-621a1676"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]
const _hoisted_2 = { class: "label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("button", {
    type: "button",
    class: _normalizeClass(["base-button-container", { disabled: !_ctx.enabled }]),
    style: _normalizeStyle(_ctx.styles),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClickInternal && _ctx.onClickInternal(...args))),
    disabled: !_ctx.enabled
  }, [
    (_ctx.icon != null)
      ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
          key: 0,
          class: "icon",
          icon: [_ctx.iconType, _ctx.icon]
        }, null, 8, ["icon"]))
      : _createCommentVNode("", true),
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.label), 1)
  ], 14, _hoisted_1))
}