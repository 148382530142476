
import { computed, defineComponent, PropType } from "vue";
import UserEditTableTab from "@/components/table/edit/UserEditTableTabs/UserEditTableTab/UserEditTableTab.vue";
import { names, ObjectInternalName } from "@/ts/app/object-name";

export default defineComponent({
  name: "UserEditTableTabs",
  components: { UserEditTableTab },
  props: {
    columnGroupNames: {
      type: Array as PropType<ObjectInternalName[]>,
      required: true,
    },
    selectedTab: {
      type: String,
      required: true,
    },

    onSelectTab: {
      type: Function as PropType<(tab: string) => void>,
      required: true,
    },
  },
  setup(props) {
    const namesInternal = computed(() =>
      props.columnGroupNames.map((internalName) => names[internalName]),
    );
    return {
      namesInternal,
    };
  },
});
