import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StudentList = _resolveComponent("StudentList")!

  return (_openBlock(), _createBlock(_component_StudentList, {
    "is-admin": _ctx.isAdmin,
    "column-visibility": _ctx.columnVisibility,
    "school-year": _ctx.schoolYear,
    "all-classes": _ctx.allClasses,
    "search-result": _ctx.searchResult,
    "num-results": _ctx.numResults,
    "page-number": _ctx.pageNumber,
    "search-condition": _ctx.searchCondition,
    "on-input-query-text": _ctx.onInputQueryText,
    "on-confirm-query-text": _ctx.onConfirmQueryText,
    "is-school-year-popup-active": _ctx.isSchoolYearPopupActive,
    "change-school-year-popup-activeness": _ctx.changeSchoolYearPopupActiveness,
    "on-confirm-condition-school-year": _ctx.onConfirmConditionSchoolYear,
    "is-class-popup-active": _ctx.isClassPopupActive,
    "change-class-popup-activeness": _ctx.changeClassPopupActiveness,
    "on-confirm-condition-class": _ctx.onConfirmConditionClass,
    "is-in-school-state-popup-active": _ctx.isInSchoolStatePopupActive,
    "change-in-school-state-popup-activeness": 
      _ctx.changeInSchoolStatePopupActiveness
    ,
    "on-confirm-condition-in-school-state": _ctx.onConfirmConditionInSchoolState,
    "is-sort-popup-active": _ctx.isSortPopupActive,
    "change-sort-popup-activeness": _ctx.changeSortPopupActiveness,
    "on-confirm-condition-sort": _ctx.onConfirmConditionSort,
    "is-num-results-popup-active": _ctx.isNumResultsPopupActive,
    "change-num-results-popup-activeness": _ctx.changeNumResultsPopupActiveness,
    "on-confirm-condition-num-results": _ctx.onConfirmConditionNumResults,
    "is-column-select-open": _ctx.isColumnSelectOpen,
    "toggle-column-select-visibility": _ctx.toggleColumnSelectVisibility,
    "sync-with-g-w-s": _ctx.syncWithGWS,
    "go-edit-custom-columns": _ctx.goEditCustomColumns,
    "on-click-export-button": _ctx.onClickExportButton,
    "user-id-to-edit-path": _ctx.userIdToEditPath,
    "move-page-to": _ctx.movePageTo,
    "on-change-column-visibility": _ctx.onChangeColumnVisibility
  }, null, 8, ["is-admin", "column-visibility", "school-year", "all-classes", "search-result", "num-results", "page-number", "search-condition", "on-input-query-text", "on-confirm-query-text", "is-school-year-popup-active", "change-school-year-popup-activeness", "on-confirm-condition-school-year", "is-class-popup-active", "change-class-popup-activeness", "on-confirm-condition-class", "is-in-school-state-popup-active", "change-in-school-state-popup-activeness", "on-confirm-condition-in-school-state", "is-sort-popup-active", "change-sort-popup-activeness", "on-confirm-condition-sort", "is-num-results-popup-active", "change-num-results-popup-activeness", "on-confirm-condition-num-results", "is-column-select-open", "toggle-column-select-visibility", "sync-with-g-w-s", "go-edit-custom-columns", "on-click-export-button", "user-id-to-edit-path", "move-page-to", "on-change-column-visibility"]))
}