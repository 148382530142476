import { defineStore } from "pinia";
import { Class } from "@/ts/objects/entity/class";
import { UserService } from "@/ts/services/user-service";
import {
  LoadableData,
  toLoading,
  applyResult,
  loadableDataNull,
} from "@/ts/app/loadable-data";
import { Teacher } from "@/ts/objects/entity/teacher";
import { Student } from "@/ts/objects/entity/student";
import { isNullish } from "@/ts/utils/common-util";
import { mapResult } from "@/ts/app/result";
import sortBy from "lodash/sortBy";

export const useClassStore = defineStore("class", {
  state: (): {
    schoolYearToClasses: { [schoolYear: number]: LoadableData<Class[]> };
    classIdToTeachers: { [classId: string]: LoadableData<Teacher[]> };
    classIdToStudents: { [classId: string]: LoadableData<Student[]> };
  } => ({
    schoolYearToClasses: {},
    classIdToTeachers: {},
    classIdToStudents: {},
  }),
  actions: {
    clearClasses(schoolYear?: number) {
      if (isNullish(schoolYear)) {
        this.schoolYearToClasses = {};
      } else {
        this.schoolYearToClasses[schoolYear] = loadableDataNull();
      }
    },
    async fetchClasses(userService: UserService, schoolYear: number) {
      this.schoolYearToClasses[schoolYear] = toLoading(
        this.schoolYearToClasses[schoolYear],
      );

      const result = await userService.listClasses(schoolYear);

      this.schoolYearToClasses[schoolYear] = applyResult(
        this.schoolYearToClasses[schoolYear],
        result,
      );
    },
    clearTeachers() {
      this.classIdToTeachers = {};
    },
    async fetchTeachers(userService: UserService, classId: string) {
      this.classIdToTeachers[classId] = toLoading(
        this.classIdToTeachers[classId],
      );

      const result = await userService.listTeachers(undefined, classId);

      this.classIdToTeachers[classId] = applyResult(
        this.classIdToTeachers[classId],
        mapResult(result, (teachers) =>
          sortBy(
            teachers,
            (t) => t.classes.find((c) => c.classId === classId)?.inChargeType,
          ),
        ),
      );
    },
    clearStudents() {
      this.classIdToStudents = {};
    },
    async fetchStudents(userService: UserService, classId: string) {
      this.classIdToStudents[classId] = toLoading(
        this.classIdToStudents[classId],
      );

      const result = await userService.listStudents(undefined, classId);

      this.classIdToStudents[classId] = applyResult(
        this.classIdToStudents[classId],
        mapResult(result, (students) =>
          sortBy(
            students,
            (s) => s.classes.find((c) => c.classId === classId)?.studentNumber,
          ),
        ),
      );
    },
  },
});
