import { combineResults, Result } from "@/ts/app/result";
import {
  errors,
  handleThrownError,
  ThrowableAppError,
} from "@/ts/app/error/app-error";
import {
  dropAllUndefinedFields,
  hasValue,
  isNullish,
  isStringBlank,
  isStringEmpty,
  isStringNotBlank,
  isStringNotEmpty,
  parseIntOrError,
  put,
  transformIfNotNullish,
  typedObjectKeys,
} from "@/ts/utils/common-util";
import {
  DateValue,
  dateValueToDisplayValue,
  displayValueToDateValueOrError,
} from "@/ts/objects/value/date-value";
import { Student, UpdatingStudent } from "@/ts/objects/entity/student";
import { Sex, sexFromStringOrError } from "@/ts/objects/value/sex";
import { CustomColumn } from "@/ts/objects/value/custom-column";
import { BasicUserInfoPartial } from "@/ts/objects/value/basic-user-info";
import {
  SchoolType,
  schoolTypeFromStringOrError,
} from "@/ts/objects/value/school-type";
import { StudentClass } from "@/ts/objects/value/student-class";
import { Class } from "@/ts/objects/entity/class";
import { names, ObjectInternalName, ObjectName } from "@/ts/app/object-name";
import {
  ExportingRawCSVRow,
  ImportedRawCSVRow,
} from "@/ts/app/columns/csv/raw-csv";
import { hasLunchValueFromStringOrError } from "@/ts/objects/value/has-lunch-value";
import { validateAndRenameColsOfRow } from "@/ts/app/columns/csv/csv";
import orderBy from "lodash/orderBy";
import { CheckFailed } from "@/ts/app/error/check-error";
import pick from "lodash/pick";
import {
  StudentListColumnId,
  studentListColumnIds,
} from "@/ts/app/columns/user-list/student-list-columns";

export type ImportedRawStudentCSVRow = Pick<
  ImportedRawCSVRow,
  StudentListColumnId
>;

export type ExportingRawStudentCSVRow = Pick<
  ExportingRawCSVRow,
  StudentListColumnId
>;

export type StudentCSVFile = {
  filename: string;
  rows: StudentCSVRow[];
};

/**
 * 児童生徒のCSV行。
 * 各列の値について、 undefined = 列自体が存在しない, null = 列は存在するが値がnull
 */
export type StudentCSVRow = {
  rowNumber: number;

  userId: string;
  googleMail?: string;
  name?: string;

  nameKana?: string;
  nameRome?: string;
  familyName?: string;
  familyNameKana?: string;
  givenName?: string;
  givenNameKana?: string;
  class?: {
    // TODO schoolType, gradeNumber, classNameから、classIdを特定する。万一複数あったら1個目ので良いだろう。classStoreとかに持たせとく？
    schoolType: SchoolType;
    gradeNumber: number;
    className: string;
    studentNumber: number | null;
  } | null;
  guardian?: string | null; // TODO 空欄の場合バグらないかテスト。
  sex?: Sex;
  nickname?: string;
  nicknameKana?: string;
  birthday?: DateValue | null;
  applicationDate?: DateValue | null;
  kindergartenEntranceDate?: DateValue | null;
  elementarySchoolEntranceDate?: DateValue | null;
  juniorHighSchoolEntranceDate?: DateValue | null;
  transferDate?: DateValue | null;
  graduationDate?: DateValue | null;
  kindergarten?: string;
  previousSchool?: string;
  zipcode?: string;
  address?: string;
  email?: string;
  mobilePhone?: string;
  pictureGcsUrl?: string;
  country?: string;
  religion?: string;
  commutingBy?: string;
  bus?: string;
  nearestStation?: string;
  hasLunch?: string;

  activityMonday?: string;
  activityTuesday?: string;
  activityWednesday?: string;
  activityThursday?: string;
  activityFriday?: string;

  bloodType?: string;
  normalBodyTemperature?: string;
  inoculation?: string;
  medicalHistory?: string;
  homeDoctor?: string;
  foodAllergy?: string;
  anaphylaxis?: string;
  otherAllergy?: string;
  asthma?: string;

  custom01?: string;
  custom02?: string;
  custom03?: string;
  custom04?: string;
  custom05?: string;
  custom06?: string;
  custom07?: string;
  custom08?: string;
  custom09?: string;
  custom10?: string;
  custom11?: string;
  custom12?: string;
  custom13?: string;
  custom14?: string;
  custom15?: string;
  custom16?: string;
  custom17?: string;
  custom18?: string;
  custom19?: string;
  custom20?: string;
  custom21?: string;
  custom22?: string;
  custom23?: string;
  custom24?: string;
  custom25?: string;
  custom26?: string;
  custom27?: string;
  custom28?: string;
  custom29?: string;
  custom30?: string;
  custom31?: string;
  custom32?: string;
  custom33?: string;
  custom34?: string;
  custom35?: string;
  custom36?: string;
  custom37?: string;
  custom38?: string;
  custom39?: string;
  custom40?: string;
};

export function importedToStudentCSVRow(
  filename: string,
  rowNumber: number,
  rawRow: ImportedRawStudentCSVRow,
  nameToId: Map<string, ObjectInternalName>,
): Result<StudentCSVRow> {
  const result = validateAndRenameColsOfRow(
    names.studentCSV,
    rawRow,
    nameToId,
    studentListColumnIds,
  );
  if (!result.ok) {
    return {
      ok: false,
      error: result.error,
    };
  }

  const row = result.data;

  try {
    const parseClass = (
      row: ImportedRawStudentCSVRow,
    ):
      | {
          schoolType: SchoolType;
          gradeNumber: number;
          className: string;
          studentNumber: number | null;
        }
      | null
      | undefined => {
      const _schoolType = row["schoolType"];
      const _gradeNumber = row["gradeNumber"];
      const _className = row["className"];
      const _studentNumber = row["studentNumber"];

      if (
        isStringNotEmpty(_studentNumber) &&
        (isStringEmpty(_schoolType) ||
          isStringEmpty(_gradeNumber) ||
          isStringEmpty(_className))
      )
        throw new ThrowableAppError(
          errors.validationFailed.other(
            {
              schoolType: _schoolType,
              gradeNumber: _gradeNumber,
              className: _className,
            },
            names.studentNumber,
            `all of ${names.schoolType.i}, ${names.gradeNumber.i}, and ${names.className.i} must be specified to specify ${names.studentNumber.i}`,
            `${names.studentNumber.d}を指定するには、${names.schoolType.d}・${names.gradeNumber.d}・${names.className.d}のすべてを指定してください。`,
          ),
        );

      if (
        isNullish(_schoolType) &&
        isNullish(_gradeNumber) &&
        isNullish(_className)
      )
        return undefined; // 列自体が存在しない。

      if (
        isStringEmpty(_schoolType) &&
        isStringEmpty(_gradeNumber) &&
        isStringEmpty(_className)
      )
        return null; // 空に更新する。

      if (
        !(
          isStringNotEmpty(_schoolType) &&
          isStringNotEmpty(_gradeNumber) &&
          isStringNotEmpty(_className)
        )
      )
        throw new ThrowableAppError(
          errors.validationFailed.other(
            {
              schoolType: _schoolType,
              gradeNumber: _gradeNumber,
              className: _className,
            },
            names.studentClass,
            `all of ${names.schoolType.i}, ${names.gradeNumber.i}, and ${names.className.i} must be specified to specify ${names.class.i}`,
            `${names.class.d}を指定するには、${names.schoolType.d}・${names.gradeNumber.d}・${names.className.d}のすべてを指定してください。`,
          ),
        );

      if (
        isNullish(_schoolType) ||
        isNullish(_gradeNumber) ||
        isNullish(_className)
      )
        throw new CheckFailed();

      return {
        schoolType: schoolTypeFromStringOrError(_schoolType),
        gradeNumber: parseIntOrError(_gradeNumber, names.grade),
        className: _className,
        studentNumber:
          hasValue(_studentNumber) && isStringNotBlank(_studentNumber)
            ? parseIntOrError(_studentNumber, names.studentNumber)
            : null,
      };
    };

    // undefined = 列が存在しない, null = 列は存在するが値がnull
    const parseGuardianValue = (
      rowValue: string | undefined,
    ): string | null | undefined => {
      if (rowValue === undefined) return undefined;
      if (rowValue === "") return null;
      return rowValue;
    };

    const parseDateValue = (
      rowValue: string | undefined,
      objectName: ObjectName,
    ): DateValue | null | undefined => {
      if (rowValue === undefined) return undefined;
      if (rowValue === "") return null;
      return displayValueToDateValueOrError(rowValue, objectName);
    };

    const userId = row["userId"];
    if (isStringBlank(userId))
      throw new ThrowableAppError(
        errors.validationFailed.nullValue(names.userId),
      );

    const parsed: StudentCSVRow = {
      rowNumber,

      userId: userId ?? "",
      googleMail: row["googleMail"],
      name: row["name"],

      nameKana: row["nameKana"],
      nameRome: row["nameRome"],
      familyName: row["familyName"],
      familyNameKana: row["familyNameKana"],
      givenName: row["givenName"],
      givenNameKana: row["givenNameKana"],
      class: parseClass(row),
      guardian: parseGuardianValue(row["guardian"]),
      sex: transformIfNotNullish(row["sex"], sexFromStringOrError) ?? undefined,
      nickname: row["nickname"],
      nicknameKana: row["nicknameKana"],
      birthday: parseDateValue(row["birthday"], names.birthday),
      applicationDate: parseDateValue(
        row["applicationDate"],
        names.applicationDate,
      ),
      kindergartenEntranceDate: parseDateValue(
        row["kindergartenEntranceDate"],
        names.kindergartenEntranceDate,
      ),
      elementarySchoolEntranceDate: parseDateValue(
        row["elementarySchoolEntranceDate"],
        names.elementarySchoolEntranceDate,
      ),
      juniorHighSchoolEntranceDate: parseDateValue(
        row["juniorHighSchoolEntranceDate"],
        names.juniorHighSchoolEntranceDate,
      ),
      transferDate: parseDateValue(row["transferDate"], names.transferDate),
      graduationDate: parseDateValue(
        row["graduationDate"],
        names.graduationDate,
      ),
      kindergarten: row["kindergarten"],
      previousSchool: row["previousSchool"],
      zipcode: row["zipcode"],
      address: row["address"],
      email: row["email"],
      mobilePhone: row["mobilePhone"],
      pictureGcsUrl: row["pictureGcsUrl"],
      country: row["country"],
      religion: row["religion"],
      commutingBy: row["commutingBy"],
      bus: row["bus"],
      nearestStation: row["nearestStation"],
      hasLunch:
        transformIfNotNullish(
          row["hasLunch"],
          hasLunchValueFromStringOrError,
        ) ?? undefined,
      activityMonday: row["activityMonday"],
      activityTuesday: row["activityTuesday"],
      activityWednesday: row["activityWednesday"],
      activityThursday: row["activityThursday"],
      activityFriday: row["activityFriday"],

      bloodType: row["bloodType"],
      normalBodyTemperature: row["normalBodyTemperature"],
      inoculation: row["inoculation"],
      medicalHistory: row["medicalHistory"],
      homeDoctor: row["homeDoctor"],
      foodAllergy: row["foodAllergy"],
      anaphylaxis: row["anaphylaxis"],
      otherAllergy: row["otherAllergy"],
      asthma: row["asthma"],

      custom01: row["custom01"],
      custom02: row["custom02"],
      custom03: row["custom03"],
      custom04: row["custom04"],
      custom05: row["custom05"],
      custom06: row["custom06"],
      custom07: row["custom07"],
      custom08: row["custom08"],
      custom09: row["custom09"],
      custom10: row["custom10"],
      custom11: row["custom11"],
      custom12: row["custom12"],
      custom13: row["custom13"],
      custom14: row["custom14"],
      custom15: row["custom15"],
      custom16: row["custom16"],
      custom17: row["custom17"],
      custom18: row["custom18"],
      custom19: row["custom19"],
      custom20: row["custom20"],
      custom21: row["custom21"],
      custom22: row["custom22"],
      custom23: row["custom23"],
      custom24: row["custom24"],
      custom25: row["custom25"],
      custom26: row["custom26"],
      custom27: row["custom27"],
      custom28: row["custom28"],
      custom29: row["custom29"],
      custom30: row["custom30"],
      custom31: row["custom31"],
      custom32: row["custom32"],
      custom33: row["custom33"],
      custom34: row["custom34"],
      custom35: row["custom35"],
      custom36: row["custom36"],
      custom37: row["custom37"],
      custom38: row["custom38"],
      custom39: row["custom39"],
      custom40: row["custom40"],
    };
    return { ok: true, data: parsed };
  } catch (e) {
    const appError = handleThrownError("importedToStudentCSVRow", e);
    return {
      ok: false,
      error: errors.readCSVFailed(
        `${filename}: line ${rowNumber}: ${appError.name}: ${appError.internalMessage}`,
        `${filename}: ${rowNumber}行目: ${appError.displayMessage}`,
      ),
    };
  }
}

export function updateStudentWithCSVRow(
  filename: string,
  s: Student,
  row: StudentCSVRow,
  schoolYear: number,
  classesOfSchoolYear: Class[], // その年度のクラスすべてが含まれる必要あり。
): Result<UpdatingStudent> {
  if (row.userId !== s.userId)
    throw new Error(
      `updateStudentWithCSVRow: row userId ${row.userId} and student userId ${s.userId} must be same`,
    );

  const toClassesField = (
    newVal:
      | {
          schoolType: SchoolType;
          gradeNumber: number;
          className: string;
          studentNumber: number | null;
        }
      | null
      | undefined,
    oldClasses: readonly StudentClass[],
  ): StudentClass[] | undefined => {
    // 未指定なら未指定とする。
    if (newVal === undefined) return undefined;
    // nullに更新したい -> この年度のクラスを削除する。
    if (newVal === null)
      return oldClasses.filter((c) => c.schoolYear !== schoolYear);

    // 普通に値があるなら、その値が表すクラスを見つけ、それでこの年度のクラスを更新する。
    const newClass = classesOfSchoolYear.find(
      (c) =>
        c.schoolYear === schoolYear &&
        c.grade.schoolType === newVal.schoolType &&
        c.grade.gradeNumber === newVal.gradeNumber &&
        c.name === newVal.className,
    );
    if (isNullish(newClass))
      throw new ThrowableAppError(
        errors.readCSVFailed(
          `updateStudentWithCSVRow: overwriteClasses: class not found: class=${JSON.stringify(
            newVal,
          )}, allClasses=${JSON.stringify(classesOfSchoolYear)}`,
          `指定した${names.class.d}が見つかりません。年度に誤りが無いことを確認してください。` +
            `(` +
            `${names.schoolYear.d}=${schoolYear}, ` +
            `${names.schoolType.d}=${newVal.schoolType}, ` +
            `${names.gradeNumber.d}=${newVal.gradeNumber}, ` +
            `${names.className.d}=${newVal.className}` +
            `)`,
        ),
      );

    const updatedClasses = put(oldClasses, (c) => c.schoolYear === schoolYear, {
      ...newClass,
      studentNumber: newVal.studentNumber,
    });
    return orderBy(updatedClasses, ["schoolYear"]);
  };

  const toGuardianField = (
    newUserId: string | null | undefined,
  ): BasicUserInfoPartial | null | undefined => {
    switch (newUserId) {
      case undefined:
        return undefined;
      case null:
        return null;
      default:
        return {
          userId: newUserId,
        };
    }
  };

  const toCustomColField = (
    newVal: string | undefined,
    oldVal: CustomColumn,
  ): CustomColumn | undefined =>
    newVal === undefined ? undefined : { name: oldVal.name, value: newVal };

  try {
    const partialStudent: Partial<Student> = dropAllUndefinedFields({
      name: row.name,

      classes: toClassesField(row.class, s.classes),
      guardian: toGuardianField(row.guardian),

      nameKana: row.nameKana,
      nameRome: row.nameRome,
      familyName: row.familyName,
      familyNameKana: row.familyNameKana,
      givenName: row.givenName,
      givenNameKana: row.givenNameKana,
      sex: row.sex,
      nickname: row.nickname,
      nicknameKana: row.nicknameKana,
      birthday: row.birthday,
      applicationDate: row.applicationDate,
      kindergartenEntranceDate: row.kindergartenEntranceDate,
      elementarySchoolEntranceDate: row.elementarySchoolEntranceDate,
      juniorHighSchoolEntranceDate: row.juniorHighSchoolEntranceDate,
      transferDate: row.transferDate,
      graduationDate: row.graduationDate,
      kindergarten: row.kindergarten,
      previousSchool: row.previousSchool,
      zipcode: row.zipcode,
      address: row.address,
      email: row.email,
      mobilePhone: row.mobilePhone,
      // pictureGcsUrlは、CSVによる読み込み不可。
      country: row.country,
      religion: row.religion,
      commutingBy: row.commutingBy,
      bus: row.bus,
      nearestStation: row.nearestStation,
      hasLunch: hasValue(row.hasLunch)
        ? hasLunchValueFromStringOrError(row.hasLunch)
        : undefined,
      activityMonday: row.activityMonday,
      activityTuesday: row.activityTuesday,
      activityWednesday: row.activityWednesday,
      activityThursday: row.activityThursday,
      activityFriday: row.activityFriday,

      bloodType: row.bloodType,
      normalBodyTemperature: row.normalBodyTemperature,
      inoculation: row.inoculation,
      medicalHistory: row.medicalHistory,
      homeDoctor: row.homeDoctor,
      foodAllergy: row.foodAllergy,
      anaphylaxis: row.anaphylaxis,
      otherAllergy: row.otherAllergy,
      asthma: row.asthma,

      custom01: toCustomColField(row.custom01, s.custom01),
      custom02: toCustomColField(row.custom02, s.custom02),
      custom03: toCustomColField(row.custom03, s.custom03),
      custom04: toCustomColField(row.custom04, s.custom04),
      custom05: toCustomColField(row.custom05, s.custom05),
      custom06: toCustomColField(row.custom06, s.custom06),
      custom07: toCustomColField(row.custom07, s.custom07),
      custom08: toCustomColField(row.custom08, s.custom08),
      custom09: toCustomColField(row.custom09, s.custom09),
      custom10: toCustomColField(row.custom10, s.custom10),
      custom11: toCustomColField(row.custom11, s.custom11),
      custom12: toCustomColField(row.custom12, s.custom12),
      custom13: toCustomColField(row.custom13, s.custom13),
      custom14: toCustomColField(row.custom14, s.custom14),
      custom15: toCustomColField(row.custom15, s.custom15),
      custom16: toCustomColField(row.custom16, s.custom16),
      custom17: toCustomColField(row.custom17, s.custom17),
      custom18: toCustomColField(row.custom18, s.custom18),
      custom19: toCustomColField(row.custom19, s.custom19),
      custom20: toCustomColField(row.custom20, s.custom20),
      custom21: toCustomColField(row.custom21, s.custom21),
      custom22: toCustomColField(row.custom22, s.custom22),
      custom23: toCustomColField(row.custom23, s.custom23),
      custom24: toCustomColField(row.custom24, s.custom24),
      custom25: toCustomColField(row.custom25, s.custom25),
      custom26: toCustomColField(row.custom26, s.custom26),
      custom27: toCustomColField(row.custom27, s.custom27),
      custom28: toCustomColField(row.custom28, s.custom28),
      custom29: toCustomColField(row.custom29, s.custom29),
      custom30: toCustomColField(row.custom30, s.custom30),
      custom31: toCustomColField(row.custom31, s.custom31),
      custom32: toCustomColField(row.custom32, s.custom32),
      custom33: toCustomColField(row.custom33, s.custom33),
      custom34: toCustomColField(row.custom34, s.custom34),
      custom35: toCustomColField(row.custom35, s.custom35),
      custom36: toCustomColField(row.custom36, s.custom36),
      custom37: toCustomColField(row.custom37, s.custom37),
      custom38: toCustomColField(row.custom38, s.custom38),
      custom39: toCustomColField(row.custom39, s.custom39),
      custom40: toCustomColField(row.custom40, s.custom40),
    });
    return {
      ok: true,
      data: {
        student: { ...s, ...partialStudent },
        updatingFields: typedObjectKeys(partialStudent),
      },
    };
  } catch (e) {
    const appError = handleThrownError("updateStudentWithCSVRow", e);
    return {
      ok: false,
      error: errors.readCSVFailed(
        `${filename}: line ${row.rowNumber}: ${appError.name}: ${appError.internalMessage}`,
        `${filename}: ${row.rowNumber}行目: ${appError.displayMessage}`,
      ),
    };
  }
}

export function updateStudentsWithCSVRows(
  filename: string,
  students: Student[],
  rows: StudentCSVRow[],
  schoolYear: number,
  classesOfSchoolYear: Class[], // その年度のクラスすべてが含まれる必要あり。
): Result<UpdatingStudent[]> {
  try {
    const updatingStudents = rows.map((row) => {
      const student = students.find((s) => s.userId === row.userId);
      if (isNullish(student))
        throw new ThrowableAppError(
          errors.readCSVFailed(
            `${names.student.i} ${row.userId} not found`,
            `指定した${names.student.d}が見つかりません。(${names.userId.d}=${row.userId})`,
          ),
        );
      return updateStudentWithCSVRow(
        filename,
        student,
        row,
        schoolYear,
        classesOfSchoolYear,
      );
    });
    return combineResults(updatingStudents);
  } catch (e) {
    const appError = handleThrownError("updateStudentsWithCSVRows", e);
    return { ok: false, error: appError };
  }
}

/**
 * studentをCSVエクスポート用のオブジェクトに詰め替える。
 *
 * @param s 児童生徒
 * @param schoolYear 年度
 * @param columnIds ここで指定した列のみをエクスポートする。
 */
export function studentToExportingCSVRow(
  s: Student,
  schoolYear: number,
  columnIds: readonly StudentListColumnId[],
): ExportingRawStudentCSVRow {
  // 指定した年度のクラス。
  const cls = s.classes.find((c) => c.schoolYear === schoolYear);

  const unparseDateValue = (v: DateValue | null): string | null =>
    hasValue(v) ? dateValueToDisplayValue(v) : null;

  const row: Required<ExportingRawStudentCSVRow> = {
    userId: s.userId,
    googleMail: s.googleMail,
    name: s.name,

    nameKana: s.nameKana,
    nameRome: s.nameRome,
    familyName: s.familyName,
    familyNameKana: s.familyNameKana,
    givenName: s.givenName,
    givenNameKana: s.givenNameKana,

    schoolType: hasValue(cls) ? cls.grade.schoolType : null,
    gradeNumber: cls?.grade.gradeNumber ?? null,
    className: cls?.name ?? null,
    studentNumber: cls?.studentNumber ?? null,

    guardian: s.guardian?.userId ?? null,

    sex: s.sex,
    nickname: s.nickname,
    nicknameKana: s.nicknameKana,
    birthday: unparseDateValue(s.birthday),
    applicationDate: unparseDateValue(s.applicationDate),
    kindergartenEntranceDate: unparseDateValue(s.kindergartenEntranceDate),
    elementarySchoolEntranceDate: unparseDateValue(
      s.elementarySchoolEntranceDate,
    ),
    juniorHighSchoolEntranceDate: unparseDateValue(
      s.juniorHighSchoolEntranceDate,
    ),
    transferDate: unparseDateValue(s.transferDate),
    graduationDate: unparseDateValue(s.graduationDate),
    kindergarten: s.kindergarten,
    previousSchool: s.previousSchool,
    zipcode: s.zipcode,
    address: s.address,
    email: s.email,
    mobilePhone: s.mobilePhone,
    pictureGcsUrl: s.pictureGcsUrl,
    country: s.country,
    religion: s.religion,
    commutingBy: s.commutingBy,
    bus: s.bus,
    nearestStation: s.nearestStation,
    hasLunch: s.hasLunch,
    activityMonday: s.activityMonday,
    activityTuesday: s.activityTuesday,
    activityWednesday: s.activityWednesday,
    activityThursday: s.activityThursday,
    activityFriday: s.activityFriday,

    bloodType: s.bloodType,
    normalBodyTemperature: s.normalBodyTemperature,
    inoculation: s.inoculation,
    medicalHistory: s.medicalHistory,
    homeDoctor: s.homeDoctor,
    foodAllergy: s.foodAllergy,
    anaphylaxis: s.anaphylaxis,
    otherAllergy: s.otherAllergy,
    asthma: s.asthma,

    custom01: s.custom01.value,
    custom02: s.custom02.value,
    custom03: s.custom03.value,
    custom04: s.custom04.value,
    custom05: s.custom05.value,
    custom06: s.custom06.value,
    custom07: s.custom07.value,
    custom08: s.custom08.value,
    custom09: s.custom09.value,
    custom10: s.custom10.value,
    custom11: s.custom11.value,
    custom12: s.custom12.value,
    custom13: s.custom13.value,
    custom14: s.custom14.value,
    custom15: s.custom15.value,
    custom16: s.custom16.value,
    custom17: s.custom17.value,
    custom18: s.custom18.value,
    custom19: s.custom19.value,
    custom20: s.custom20.value,
    custom21: s.custom21.value,
    custom22: s.custom22.value,
    custom23: s.custom23.value,
    custom24: s.custom24.value,
    custom25: s.custom25.value,
    custom26: s.custom26.value,
    custom27: s.custom27.value,
    custom28: s.custom28.value,
    custom29: s.custom29.value,
    custom30: s.custom30.value,
    custom31: s.custom31.value,
    custom32: s.custom32.value,
    custom33: s.custom33.value,
    custom34: s.custom34.value,
    custom35: s.custom35.value,
    custom36: s.custom36.value,
    custom37: s.custom37.value,
    custom38: s.custom38.value,
    custom39: s.custom39.value,
    custom40: s.custom40.value,
  };

  return pick(row, columnIds);
}
