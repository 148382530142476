
import { defineComponent, PropType } from "vue";
import { Page, PageName } from "@/ts/app/pages/pages";
import MainMenuItem from "@/views/_components/MainMenu/MainMenuItem/MainMenuItem.vue";

export default defineComponent({
  name: "MainMenu",
  components: { MainMenuItem },
  props: {
    pages: { type: Array as PropType<Page[]>, required: true },
    activePageName: { type: String as PropType<PageName>, default: null },
  },
});
