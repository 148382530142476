import { Student, StudentProfile } from "@/ts/objects/entity/student";
import {
  DateOrNullType,
  GuardianOrNullType,
  HasLunchType,
  ImageType,
  IntegerOrNullType,
  SchoolTypeOrNullType,
  SexType,
  StringOrNullType,
  StringType,
  StudentClassesType,
} from "@/ts/app/columns/def/column-types";
import { StudentColumnDef } from "@/ts/app/columns/def/student/student-column";
import { genUserColumnDefs } from "@/ts/app/columns/def/user-column-def";
import {
  UserColumnDefGroup,
  UserColumnGroup,
} from "@/ts/app/columns/def/user-column-group";
import { UserColumn } from "@/ts/app/columns/def/user-column";
import { getClassOfSchoolYear } from "@/ts/objects/value/student-class";
import {
  userColumnDefGoogleMail,
  userColumnDefName,
  userColumnDefPhotoUrl,
  userColumnDefUserId,
} from "@/ts/app/columns/def/common/user-common-column";
import { names } from "@/ts/app/object-name";

export const studentProfileColumnGroupName = names.profile.i;
export type StudentProfileColumnGroupName =
  typeof studentProfileColumnGroupName;

export type StudentProfileColumnId =
  | "userId"
  | "googleMail"
  | "photoUrl"
  | "name"
  | "schoolType"
  | "gradeNumber"
  | "className"
  | "studentNumber"
  | keyof StudentProfile;

export type StudentProfileColumnDef<T> = StudentColumnDef<
  StudentProfileColumnId,
  T
>;

export type StudentProfileColumn<T> = UserColumn<
  StudentProfileColumnId,
  Student,
  T
>;

export type StudentProfileColumnDefGroup = UserColumnDefGroup<
  StudentProfileColumnGroupName,
  StudentProfileColumnId,
  Student
>;

export type StudentProfileColumnGroup = UserColumnGroup<
  StudentProfileColumnGroupName,
  StudentProfileColumnId,
  Student
>;

export function studentProfileColumnDefs(
  schoolYear: number,
): StudentProfileColumnDef<any>[] {
  const getClass = (s: Student) => getClassOfSchoolYear(s.classes, schoolYear);
  return genUserColumnDefs<StudentProfileColumnId, Student, any>([
    userColumnDefUserId(),
    userColumnDefGoogleMail(),
    userColumnDefPhotoUrl(),

    {
      id: "pictureGcsUrl",
      width: 120,
      type: ImageType,
      getValue: (s) => s.pictureGcsUrl,
      setValue: (s, v) => ({ ...s, pictureGcsUrl: v }),
    },
    userColumnDefName(),
    {
      id: "nameKana",
      width: 120,
      type: StringType,
      getValue: (s) => s.nameKana,
      setValue: (s, v) => ({ ...s, nameKana: v }),
    },
    {
      id: "nameRome",
      width: 120,
      type: StringType,
      getValue: (s) => s.nameRome,
      setValue: (s, v) => ({ ...s, nameRome: v }),
    },
    {
      id: "familyName",
      width: 80,
      type: StringType,
      getValue: (s) => s.familyName,
      setValue: (s, v) => ({ ...s, familyName: v }),
    },
    {
      id: "familyNameKana",
      width: 100,
      type: StringType,
      getValue: (s) => s.familyNameKana,
      setValue: (s, v) => ({ ...s, familyNameKana: v }),
    },
    {
      id: "givenName",
      width: 80,
      type: StringType,
      getValue: (s) => s.givenName,
      setValue: (s, v) => ({ ...s, givenName: v }),
    },
    {
      id: "givenNameKana",
      width: 100,
      type: StringType,
      getValue: (s) => s.givenNameKana,
      setValue: (s, v) => ({ ...s, givenNameKana: v }),
    },

    {
      id: "schoolType",
      width: 90,
      type: SchoolTypeOrNullType,
      getValue: (s) => getClass(s)?.grade?.schoolType ?? null,
    },
    {
      id: "gradeNumber",
      width: 40,
      type: IntegerOrNullType,
      getValue: (s) => getClass(s)?.grade?.gradeNumber ?? null,
    },
    {
      id: "className",
      width: 80,
      type: StringOrNullType,
      getValue: (s) => getClass(s)?.name ?? null,
    },
    {
      id: "classes",
      width: 140,
      type: StudentClassesType,
      getValue: (s) => s.classes,
      setValue: (s, v): Student => ({ ...s, classes: v }),
    },
    {
      id: "studentNumber",
      width: 60,
      type: IntegerOrNullType,
      getValue: (s) => getClass(s)?.studentNumber ?? null,
    },

    {
      id: "sex",
      width: 50,
      type: SexType,
      getValue: (s) => s.sex,
      setValue: (s, v) => ({ ...s, sex: v }),
    },
    {
      id: "nickname",
      width: 120,
      type: StringType,
      getValue: (s) => s.nickname,
      setValue: (s, v) => ({ ...s, nickname: v }),
    },
    {
      id: "nicknameKana",
      width: 120,
      type: StringType,
      getValue: (s) => s.nicknameKana,
      setValue: (s, v) => ({ ...s, nicknameKana: v }),
    },
    {
      id: "address",
      width: 120,
      type: StringType,
      getValue: (s) => s.address,
      setValue: (s, v) => ({ ...s, address: v }),
    },
    {
      id: "mobilePhone",
      width: 120,
      type: StringType,
      getValue: (s) => s.mobilePhone,
      setValue: (s, v) => ({ ...s, mobilePhone: v }),
    },
    {
      id: "birthday",
      width: 120,
      type: DateOrNullType,
      getValue: (s) => s.birthday,
      setValue: (s, v) => ({ ...s, birthday: v }),
    },
    {
      id: "zipcode",
      width: 120,
      type: StringType,
      getValue: (s) => s.zipcode,
      setValue: (s, v) => ({ ...s, zipcode: v }),
    },
    {
      id: "country",
      width: 90,
      type: StringType,
      getValue: (s) => s.country,
      setValue: (s, v) => ({ ...s, country: v }),
    },
    {
      id: "religion",
      width: 90,
      type: StringType,
      getValue: (s) => s.religion,
      setValue: (s, v) => ({ ...s, religion: v }),
    },
    {
      id: "hasLunch",
      width: 70,
      type: HasLunchType,
      getValue: (s) => s.hasLunch,
      setValue: (s, v) => ({ ...s, hasLunch: v }),
    },
    {
      id: "email",
      width: 180,
      type: StringType,
      getValue: (s) => s.email,
      setValue: (s, v) => ({ ...s, email: v }),
    },
    {
      id: "commutingBy",
      width: 120,
      type: StringType,
      getValue: (s) => s.commutingBy,
      setValue: (s, v) => ({ ...s, commutingBy: v }),
    },
    {
      id: "bus",
      width: 120,
      type: StringType,
      getValue: (s) => s.bus,
      setValue: (s, v) => ({ ...s, bus: v }),
    },
    {
      id: "nearestStation",
      width: 120,
      type: StringType,
      getValue: (s) => s.nearestStation,
      setValue: (s, v) => ({ ...s, nearestStation: v }),
    },
    {
      id: "guardian",
      width: 120,
      type: GuardianOrNullType,
      getValue: (s) => s.guardian,
      setValue: (s, v): Student => ({ ...s, guardian: v }),
    },
    {
      id: "applicationDate",
      width: 120,
      type: DateOrNullType,
      getValue: (s) => s.applicationDate,
      setValue: (s, v) => ({ ...s, applicationDate: v }),
    },
    {
      id: "elementarySchoolEntranceDate",
      width: 120,
      type: DateOrNullType,
      getValue: (s) => s.elementarySchoolEntranceDate,
      setValue: (s, v) => ({ ...s, elementarySchoolEntranceDate: v }),
    },
    {
      id: "transferDate",
      width: 120,
      type: DateOrNullType,
      getValue: (s) => s.transferDate,
      setValue: (s, v) => ({ ...s, transferDate: v }),
    },
    {
      id: "graduationDate",
      width: 120,
      type: DateOrNullType,
      getValue: (s) => s.graduationDate,
      setValue: (s, v) => ({ ...s, graduationDate: v }),
    },
    {
      id: "kindergartenEntranceDate",
      width: 120,
      type: DateOrNullType,
      getValue: (s) => s.kindergartenEntranceDate,
      setValue: (s, v) => ({ ...s, kindergartenEntranceDate: v }),
    },
    {
      id: "juniorHighSchoolEntranceDate",
      width: 120,
      type: DateOrNullType,
      getValue: (s) => s.juniorHighSchoolEntranceDate,
      setValue: (s, v) => ({ ...s, juniorHighSchoolEntranceDate: v }),
    },
    {
      id: "previousSchool",
      width: 120,
      type: StringType,
      getValue: (s) => s.previousSchool,
      setValue: (s, v) => ({ ...s, previousSchool: v }),
    },
    {
      id: "kindergarten",
      width: 120,
      type: StringType,
      getValue: (s) => s.kindergarten,
      setValue: (s, v) => ({ ...s, kindergarten: v }),
    },
    {
      id: "activityMonday",
      width: 120,
      type: StringType,
      getValue: (s) => s.activityMonday,
      setValue: (s, v) => ({ ...s, activityMonday: v }),
    },
    {
      id: "activityTuesday",
      width: 120,
      type: StringType,
      getValue: (s) => s.activityTuesday,
      setValue: (s, v) => ({ ...s, activityTuesday: v }),
    },
    {
      id: "activityWednesday",
      width: 120,
      type: StringType,
      getValue: (s) => s.activityWednesday,
      setValue: (s, v) => ({ ...s, activityWednesday: v }),
    },
    {
      id: "activityThursday",
      width: 120,
      type: StringType,
      getValue: (s) => s.activityThursday,
      setValue: (s, v) => ({ ...s, activityThursday: v }),
    },
    {
      id: "activityFriday",
      width: 120,
      type: StringType,
      getValue: (s) => s.activityFriday,
      setValue: (s, v) => ({ ...s, activityFriday: v }),
    },
  ]);
}
