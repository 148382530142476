import { isNullish } from "@/ts/utils/common-util";

export const pageNames = [
  "teacher",
  "student",
  "guardian",
  "class",
  "term",
  "curriculum",
  "school",
] as const;
export type PageName = typeof pageNames[number];

export function isPageName(value: unknown): value is PageName {
  return typeof value === "string" && pageNames.some((v) => v === value);
}

export function asPageNameOrNull(value: unknown): PageName | null {
  if (isPageName(value)) return value;
  return null;
}

export type Page = {
  readonly name: PageName;
  readonly label: string;
  readonly icon: string;
};

export const teacherPage: Page = {
  name: "teacher",
  label: "Staff",
  icon: "user-graduate",
};

export const studentPage: Page = {
  name: "student",
  label: "Student",
  icon: "child",
};

export const guardianPage: Page = {
  name: "guardian",
  label: "Guardian",
  icon: "user-tie",
};

export const classPage: Page = {
  name: "class",
  label: "Class",
  icon: "chalkboard-teacher",
};

export const termPage: Page = {
  name: "term",
  label: "Year and Term",
  icon: "calendar-alt",
};

export const curriculumPage: Page = {
  name: "curriculum",
  label: "Curriculum",
  icon: "book",
};

export const schoolPage: Page = {
  name: "school",
  label: "School",
  icon: "school",
};

export const allPages = [
  teacherPage,
  studentPage,
  guardianPage,
  classPage,
  termPage,
  curriculumPage,
  schoolPage,
];

export function getPageFromName(name: PageName): Page {
  const page = allPages.find((p) => p.name === name);
  if (isNullish(page)) throw new Error("getPageFromName: unknown page name");

  return page;
}
