import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3c06d44b"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["value"]
const _hoisted_2 = ["value"]
const _hoisted_3 = {
  key: 1,
  class: "value-area"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["editable-item-enum-container", { boxed: _ctx.editable || !_ctx.hideBoxOnDisable }]),
    style: _normalizeStyle(_ctx.styles)
  }, [
    (_ctx.editable)
      ? (_openBlock(), _createElementBlock("select", {
          key: 0,
          class: "value-area select",
          value: _ctx.value,
          onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args)))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
            return (_openBlock(), _createElementBlock("option", {
              key: option.value,
              class: "option",
              value: option.value
            }, _toDisplayString(option.name), 9, _hoisted_2))
          }), 128))
        ], 40, _hoisted_1))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.selectedName), 1))
  ], 6))
}