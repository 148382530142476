
import { defineComponent, PropType } from "vue";
import { TeacherClass } from "@/ts/objects/value/teacher-class";
import TeacherClassText from "@/components/texts/TeacherClassText/TeacherClassText.vue";

export default defineComponent({
  name: "UserListTableCellTeacherClasses",
  components: { TeacherClassText },
  props: {
    teacherClasses: { type: Array as PropType<TeacherClass[]>, required: true },

    isLink: { type: Boolean, required: true },
  },
});
