
import { defineComponent, PropType } from "vue";
import CurriculumEditTableRowSection from "@/views/curriculum/CurriculumEdit/CurriculumEditTable/CurriculumEditTableRow/CurriculumEditTableRowSection/CurriculumEditTableRowSection.vue";
import {
  CurriculumEditRowData,
  CurriculumEditRowSectionData,
} from "@/views/curriculum/CurriculumEdit/curriculum-edit-data";
import { CurriculumEvalType } from "@/ts/objects/value/curriculum-eval-type";

export default defineComponent({
  name: "CurriculumEditTableRow",
  components: { CurriculumEditTableRowSection },
  props: {
    /**
     * データ。
     * 選択中の年度、学校タイプ、学年の教科のみが含まれる前提。ここでチェックはしない。
     */
    data: {
      type: Object as PropType<CurriculumEditRowData<1 | 2 | 3 | 4 | 5 | 6>>,
      required: true,
    },

    editing: { type: Boolean, required: true },

    onUpdate: {
      type: Function as PropType<
        (data: CurriculumEditRowData<1 | 2 | 3 | 4 | 5 | 6>) => void
      >,
      required: true,
    },
  },
  setup(props) {
    const onUpdateSection = (
      sectionData: CurriculumEditRowSectionData<CurriculumEvalType>,
    ) => {
      if (!props.editing) return;

      props.onUpdate({
        ...props.data,
        [sectionData.evalType]: sectionData,
      });
    };

    return {
      onUpdateSection,
    };
  },
});
