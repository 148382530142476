
import { defineComponent, PropType, ref } from "vue";
import SearchConditionPopupFrame from "@/components/search-condition/SearchConditionPopupFrame/SearchConditionPopupFrame.vue";
import {
  isSearchConditionInSchoolStateSubType,
  SearchConditionInSchoolState,
  SearchConditionInSchoolStateSubType,
} from "@/ts/objects/search-condition/search-condition-in-school-state";
import SearchConditionRadioSimple from "@/components/radios/SearchConditionRadioSimple/SearchConditionRadioSimple.vue";
import { DateValue } from "@/ts/objects/value/date-value";

export default defineComponent({
  name: "SearchConditionPopupInSchoolState",
  components: {
    SearchConditionRadioSimple,
    SearchConditionPopupFrame,
  },
  props: {
    /**
     * 現在の検索条件。
     * ポップアップの初期値に使う。
     */
    searchCondition: {
      type: Object as PropType<SearchConditionInSchoolState>,
      required: true,
    },

    /**
     * キャンセルして閉じる。
     */
    cancel: { type: Function as PropType<() => void>, required: true },
    /**
     * 確定して閉じる。
     */
    confirm: {
      type: Function as PropType<(cond: SearchConditionInSchoolState) => void>,
      required: true,
    },
  },
  setup(props) {
    const selectedRadioValue = ref<SearchConditionInSchoolStateSubType>(
      props.searchCondition.subType,
    );
    const date = ref(props.searchCondition.on);

    return {
      radioGroupName: "in-school-state",
      selectedRadioValue,

      onSelect: (value: string) => {
        if (!isSearchConditionInSchoolStateSubType(value)) return;
        selectedRadioValue.value = value;
      },
      onClickConfirm: () => {
        props.confirm(toSearchCondition(selectedRadioValue.value, date.value));
      },
    };
  },
});

/**
 * 本ポップアップの選択値から検索条件を作成する。
 */
function toSearchCondition(
  radioValue: SearchConditionInSchoolStateSubType,
  date: DateValue,
): SearchConditionInSchoolState {
  const commonAttr = {
    searchConditionType: "in-school-state" as const,
    on: date,
  };

  switch (radioValue) {
    case "all":
      return {
        ...commonAttr,
        subType: "all",
      };
    case "inSchool":
      return {
        ...commonAttr,
        subType: "inSchool",
      };
    case "graduated":
      return {
        ...commonAttr,
        subType: "graduated",
      };
    case "transferred":
      return {
        ...commonAttr,
        subType: "transferred",
      };
    case "graduatedOrTransferred":
      return {
        ...commonAttr,
        subType: "graduatedOrTransferred",
      };
  }
}
