import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1f43c390"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "class-list-table-container" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = {
  class: "body",
  "aria-label": "Grade Rows"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ClassListTableRow = _resolveComponent("ClassListTableRow")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1),
    _createElementVNode("ul", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rows, (row) => {
        return (_openBlock(), _createBlock(_component_ClassListTableRow, {
          key: row.data.gradeNumber,
          class: "row",
          "aria-label": `Grade ${row.data.gradeNumber} Row`,
          theme: row.theme,
          "header-height": 32,
          "item-height": 32,
          data: row.data,
          editing: _ctx.editing,
          "on-update": 
          (classes) => _ctx.onUpdateRowInternal(row.data.gradeNumber, classes)
        
        }, null, 8, ["aria-label", "theme", "data", "editing", "on-update"]))
      }), 128))
    ])
  ]))
}