
import { defineComponent, PropType } from "vue";
import BaseRadio from "@/components/radios/BaseRadio/BaseRadio.vue";

export default defineComponent({
  name: "SearchConditionRadio",
  components: { BaseRadio },
  props: {
    /**
     * ラジオボタングループの名前。
     */
    radioGroupName: { type: String, required: true },
    /**
     * ラジオボタンを選択した時に送信される値。
     */
    radioValue: { type: String, required: true },
    /**
     * ラジオボタングループの中で選択中の値。
     */
    selectedRadioValue: { type: String, required: true },

    onSelect: {
      type: Function as PropType<(radioValue: string) => void>,
      required: true,
    },
  },
});
