
import { computed, defineComponent, PropType } from "vue";
import TermEditTableCell from "@/views/term/TermEdit/TermEditTable/TermEditTableRow/TermEditTableCell/TermEditTableCell.vue";
import { DateValue } from "@/ts/objects/value/date-value";
import {
  TermTableCellModel,
  TermTableRowModel,
} from "@/views/term/TermEdit/term-edit-data";
import { formatOrdinals, hasValue } from "@/ts/utils/common-util";
import RemoveButton from "@/components/buttons/RemoveButton/RemoveButton.vue";
import log from "loglevel";

export default defineComponent({
  name: "TermEditTableRow",
  components: {
    RemoveButton,
    TermEditTableCell,
  },
  props: {
    schoolYear: { type: Number, required: true },
    model: {
      type: Object as PropType<TermTableRowModel>,
      required: false,
    },
    onChangeTerm: {
      type: Function as PropType<
        (
          termNumber: number,

          changedStartDate: boolean,
          newDate: DateValue | null,
        ) => void
      >,
      required: true,
    },
    maxTermNumber: {
      type: Number,
      required: true,
    },
    editing: { type: Boolean, required: true },
    /**
     * 行（年度）を削除する。
     * 値がないとき、削除ボタンを表示しない。
     * 最新の年度にのみ表示すれば良い。
     */
    onClickDeleteRow: {
      type: Function as PropType<() => void>,
      required: false,
    },

    yearCellWidth: { type: Number, required: true },
    cellWidth: { type: Number, required: true },
  },
  setup(props) {
    log.debug(`TermEditTableRow: model=${JSON.stringify(props.model)}`);

    const hasDeleteButton = computed(() => hasValue(props.onClickDeleteRow));

    const cells = computed<{ [termNumber: number]: TermTableCellModel }>(() => {
      if (hasValue(props.model)) {
        return props.model.cells;
      } else {
        return {};
      }
    });

    const deleteSchoolYearInternal = () => {
      const _onClickDeleteRow = props.onClickDeleteRow;
      if (props.editing && hasValue(_onClickDeleteRow)) _onClickDeleteRow();
    };

    return {
      hasDeleteButton,

      cells,

      deleteSchoolYearInternal,

      formatOrdinalsInternal: formatOrdinals,

      styles: {
        "--year-cell-width": `${props.yearCellWidth}px`,
        "--cell-width": `${props.cellWidth}px`,
      },
    };
  },
});
