import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f6a8213a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-header-container" }
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 1,
  class: "school-name"
}
const _hoisted_4 = { class: "user-area" }
const _hoisted_5 = {
  key: 0,
  class: "google-mail"
}
const _hoisted_6 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_BaseCustomDropdown = _resolveComponent("BaseCustomDropdown")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createVNode(_component_router_link, {
      class: "school-brand",
      to: "/home"
    }, {
      default: _withCtx(() => [
        (_ctx.schoolLogoUrl != null)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              class: "school-logo",
              src: _ctx.schoolLogoUrl,
              alt: "school logo"
            }, null, 8, _hoisted_2))
          : _createCommentVNode("", true),
        (_ctx.schoolNameInternal != null)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.schoolNameInternal), 1))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.googleMail != null)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.googleMail), 1))
        : _createCommentVNode("", true),
      _createVNode(_component_BaseCustomDropdown, {
        class: "user-icon-wrap",
        items: _ctx.userMenuOptions,
        enabled: true,
        "options-align": "right",
        "on-select": _ctx.onSelectUserMenuOption
      }, {
        default: _withCtx(() => [
          _createElementVNode("img", {
            class: "user-icon",
            src: _ctx.userIconUrl,
            alt: "user icon"
          }, null, 8, _hoisted_6)
        ]),
        _: 1
      }, 8, ["items", "on-select"])
    ])
  ]))
}