
import SearchConditionPopupFrame from "@/components/search-condition/SearchConditionPopupFrame/SearchConditionPopupFrame.vue";
import SearchConditionRadioSimple from "@/components/radios/SearchConditionRadioSimple/SearchConditionRadioSimple.vue";
import { defineComponent, PropType, ref } from "vue";
import {
  isSearchConditionTeacherTypeSubType,
  SearchConditionTeacherType,
  SearchConditionTeacherTypeSubType,
} from "@/ts/objects/search-condition/search-condition-teacher-type";

export default defineComponent({
  name: "SearchConditionPopupTeacherType",
  components: { SearchConditionRadioSimple, SearchConditionPopupFrame },
  props: {
    /**
     * 現在の検索条件。
     * ポップアップの初期値に使う。
     */
    searchCondition: {
      type: Object as PropType<SearchConditionTeacherType>,
      required: true,
    },

    /**
     * キャンセルして閉じる。
     */
    cancel: { type: Function as PropType<() => void>, required: true },
    /**
     * 確定して閉じる。
     */
    confirm: {
      type: Function as PropType<(cond: SearchConditionTeacherType) => void>,
      required: true,
    },
  },
  setup(props) {
    const selectedRadioValue = ref(props.searchCondition.subType);
    return {
      radioGroupName: "teacher-type",
      selectedRadioValue,

      onSelect: (value: string) => {
        if (!isSearchConditionTeacherTypeSubType(value)) return;
        selectedRadioValue.value = value;
      },
      onClickConfirm: () => {
        props.confirm(toSearchCondition(selectedRadioValue.value));
      },
    };
  },
});

/**
 * 本ポップアップの選択値から検索条件を作成する。
 */
function toSearchCondition(
  radioValue: SearchConditionTeacherTypeSubType,
): SearchConditionTeacherType {
  return {
    searchConditionType: "teacher-type",
    subType: radioValue,
  };
}
