
import { computed, defineComponent, PropType } from "vue";
import UserEditTableTabs from "@/components/table/edit/UserEditTableTabs/UserEditTableTabs.vue";
import UserEditTableBody from "@/components/table/edit/UserEditTableBody/UserEditTableBody.vue";
import { StudentColumnId } from "@/ts/app/columns/def/student/student-column";
import {
  studentProfileColumnGroupName,
  StudentProfileColumnGroup,
} from "@/ts/app/columns/def/student/student-profile-column";
import {
  studentHealthColumnGroupName,
  StudentHealthColumnGroup,
} from "@/ts/app/columns/def/student/student-health-column";
import {
  studentCustomColumnGroupName,
  StudentCustomColumnGroup,
} from "@/ts/app/columns/def/student/student-custom-column";
import { StudentEditTableTabValue } from "@/views/student/StudentEdit/student-edit-table-tab-value";

export default defineComponent({
  name: "StudentEditTable",
  components: { UserEditTableBody, UserEditTableTabs },
  props: {
    columnGroups: {
      type: Object as PropType<{
        profileColumnGroup: StudentProfileColumnGroup;
        healthColumnGroup: StudentHealthColumnGroup;
        customColumnGroup: StudentCustomColumnGroup;
      }>,
      required: true,
    },
    selectedTab: {
      type: String as PropType<StudentEditTableTabValue>,
      required: true,
    },
    editing: { type: Boolean, required: true },

    onInput: {
      type: Function as PropType<
        (columnId: StudentColumnId, value: any) => void
      >,
      required: true,
    },
    onSelectTab: {
      type: Function as PropType<(tab: StudentEditTableTabValue) => void>,
      required: true,
    },
  },
  setup(props) {
    const selectedGroupColumns = computed(
      () =>
        Object.values(props.columnGroups).find(
          (g) => g.groupName === props.selectedTab,
        )?.columns ?? null,
    );

    return {
      columnGroupNames: [
        studentProfileColumnGroupName,
        studentHealthColumnGroupName,
        studentCustomColumnGroupName,
      ],
      selectedGroupColumns,
    };
  },
});
