
import { defineComponent, PropType } from "vue";
import SearchConditionDropdownButton from "@/components/search-condition/SearchConditionDropdownButton/SearchConditionDropdownButton.vue";
import log from "loglevel";

export default defineComponent({
  name: "SearchConditionDropdown",
  components: { SearchConditionDropdownButton },
  props: {
    buttonLabel: { type: String, required: true },
    buttonMinWidth: { type: Number, default: 125 },

    active: { type: Boolean, required: true },

    changeActiveness: {
      type: Function as PropType<(active: boolean) => void>,
      required: true,
    },
  },
  setup(props) {
    return {
      onClickButton: () => {
        log.debug(`SearchConditionDropdown: clicked!`);
        props.changeActiveness(!props.active);
      },
      deactivate: () => {
        if (!props.active) return;
        props.changeActiveness(false);
      },
    };
  },
});
