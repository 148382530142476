import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2e4d73a4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "inner-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchConditionRadioSimple = _resolveComponent("SearchConditionRadioSimple")!
  const _component_SearchConditionPopupFrame = _resolveComponent("SearchConditionPopupFrame")!

  return (_openBlock(), _createBlock(_component_SearchConditionPopupFrame, {
    class: "search-condition-popup-teacher-type-container",
    label: "教職員タイプ",
    "confirm-button-label": "検索条件を適用",
    width: 160,
    "on-click-cancel": _ctx.cancel,
    "on-click-confirm": _ctx.onClickConfirm
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_SearchConditionRadioSimple, {
          class: "radio-item",
          "radio-group-name": _ctx.radioGroupName,
          "radio-value": "all",
          "selected-radio-value": _ctx.selectedRadioValue,
          text: "すべて",
          "on-select": _ctx.onSelect
        }, null, 8, ["radio-group-name", "selected-radio-value", "on-select"]),
        _createVNode(_component_SearchConditionRadioSimple, {
          class: "radio-item",
          "radio-group-name": _ctx.radioGroupName,
          "radio-value": "staff",
          "selected-radio-value": _ctx.selectedRadioValue,
          text: "スタッフ",
          "on-select": _ctx.onSelect
        }, null, 8, ["radio-group-name", "selected-radio-value", "on-select"]),
        _createVNode(_component_SearchConditionRadioSimple, {
          class: "radio-item",
          "radio-group-name": _ctx.radioGroupName,
          "radio-value": "teacher",
          "selected-radio-value": _ctx.selectedRadioValue,
          text: "教師",
          "on-select": _ctx.onSelect
        }, null, 8, ["radio-group-name", "selected-radio-value", "on-select"])
      ])
    ]),
    _: 1
  }, 8, ["on-click-cancel", "on-click-confirm"]))
}