import { defineStore } from "pinia";
import {
  defaultStudentColumnVisibility,
  defaultStudentCustomColumnVisibility,
  defaultStudentHealthColumnVisibility,
  defaultStudentProfileColumnVisibility,
  getStudentColumnVisibility,
  StudentColumnVisibility,
  StudentColumnVisibilityAll,
} from "@/ts/app/columns/visibility/student-column-visibility";
import { UserService } from "@/ts/services/user-service";
import { Student } from "@/ts/objects/entity/student";
import { StudentSearchCondition } from "@/ts/objects/search-condition/student-search-condition";
import { AppError } from "@/ts/app/error/app-error";
import { SearchResult } from "@/ts/app/search-result";
import log from "loglevel";
import { Class } from "@/ts/objects/entity/class";
import {
  StudentCustomColumnNames,
  studentCustomColumnNamesDefault,
} from "@/ts/objects/value/student-custom-column-names";
import { RemovableRef, useLocalStorage } from "@vueuse/core";
import { localStorageKeys } from "@/ts/app/constant";
import pick from "lodash/pick";
import {
  studentListCustomColumnIds,
  studentListHealthColumnIds,
  studentListProfileColumnIds,
} from "@/ts/app/columns/user-list/student-list-columns";

export const useStudentStore = defineStore("student", {
  state: (): {
    columnVisibilityInternal: RemovableRef<Partial<StudentColumnVisibilityAll>>;
    studentSearchResult: SearchResult<Student> | null;
    allClassesOnSchoolYear: Class[] | null;
    customColumnNames: { loaded: boolean; data: StudentCustomColumnNames };
  } => ({
    columnVisibilityInternal: useLocalStorage<
      Partial<StudentColumnVisibilityAll>
    >(
      localStorageKeys.studentColumnVisibility,
      getStudentColumnVisibility({
        profileColumnVisibility: {
          state: {
            googleMail: true,
            schoolType: true,
            gradeNumber: true,
            className: true,
            studentNumber: true,
            guardian: true,
          },
        },
      }),
    ),
    studentSearchResult: null,
    allClassesOnSchoolYear: null,
    customColumnNames: {
      loaded: false,
      data: studentCustomColumnNamesDefault(),
    },
  }),
  getters: {
    columnVisibility: (state): StudentColumnVisibilityAll => {
      return {
        ...defaultStudentColumnVisibility(false),
        ...state.columnVisibilityInternal,
      };
    },
  },
  actions: {
    updateColumnVisibility(v: StudentColumnVisibility) {
      // わざわざpickする理由は、仕様変更によりlocalStorage内のcolumnVisibilityが古くなっている可能性があるから。
      // 読み出すときに修正したいがややこしそうなので、せめてここで修正していく。
      switch (v.groupName) {
        case "profile": {
          this.columnVisibilityInternal = {
            ...this.columnVisibility,
            profileColumnVisibility: {
              groupName: "profile",
              state: {
                ...defaultStudentProfileColumnVisibility(false).state,
                ...pick(v.state, studentListProfileColumnIds),
              },
            },
          };
          break;
        }
        case "health": {
          this.columnVisibilityInternal = {
            ...this.columnVisibility,
            healthColumnVisibility: {
              groupName: "health",
              state: {
                ...defaultStudentHealthColumnVisibility(false).state,
                ...pick(v.state, studentListHealthColumnIds),
              },
            },
          };
          break;
        }
        case "customItems": {
          this.columnVisibilityInternal = {
            ...this.columnVisibility,
            customColumnVisibility: {
              groupName: "customItems",
              state: {
                ...defaultStudentCustomColumnVisibility(false).state,
                ...pick(v.state, studentListCustomColumnIds),
              },
            },
          };
          break;
        }
      }
    },
    clearStudents() {
      this.studentSearchResult = null;
    },
    async fetchStudents(
      userService: UserService,
      searchCondition: StudentSearchCondition,
      page: number,
      onSuccess: () => void = () => {},
      onFail: (e: AppError) => void = () => {},
    ) {
      log.debug(
        `studentStore: fetchStudents: searchCondition=${JSON.stringify(
          searchCondition,
        )}, page=${page}`,
      );
      const resp = await userService.listStudentsPaged(searchCondition, page);
      if (!resp.ok) {
        onFail(resp.error);
        return;
      }

      this.studentSearchResult = resp.data;
      onSuccess();
    },
    clearClasses() {
      this.allClassesOnSchoolYear = null;
    },
    async fetchClasses(
      userService: UserService,
      schoolYear: number,
      onSuccess: () => void = () => {},
      onFail: (e: AppError) => void = () => {},
    ) {
      const resp = await userService.listClasses(schoolYear);
      if (!resp.ok) {
        onFail(resp.error);
        return;
      }

      this.allClassesOnSchoolYear = resp.data;
      onSuccess();
    },
    async loadCustomColumnNames(userService: UserService) {
      if (this.customColumnNames.loaded) return;

      const resp = await userService.getStudentCustomColumnNames();
      if (!resp.ok) {
        return;
      }

      this.customColumnNames = { loaded: true, data: resp.data };
    },
    async reloadCustomColumnNames(userService: UserService) {
      const resp = await userService.getStudentCustomColumnNames();
      if (!resp.ok) {
        return;
      }

      this.customColumnNames = { loaded: true, data: resp.data };
    },
  },
});
