
import { computed, defineComponent, PropType } from "vue";
import UserListTableHeader from "@/components/table/list/UserListTableHeader/UserListTableHeader.vue";
import { Teacher } from "@/ts/objects/entity/teacher";
import { TeacherColumnVisibilityAll } from "@/ts/app/columns/visibility/teacher-column-visibility";
import { getTeacherColumnDefs } from "@/ts/app/columns/def/teacher/teacher-column";
import TeacherListTableRow from "@/views/teacher/_components/TeacherListTable/TeacherListTableRow/TeacherListTableRow.vue";

export default defineComponent({
  name: "TeacherListTable",
  components: {
    TeacherListTableRow,
    UserListTableHeader,
  },
  props: {
    horizontalMargin: { type: Number, default: 24 },

    schoolYear: { type: Number, required: true },
    teachers: { type: Array as PropType<Teacher[]>, required: true },
    columnVisibility: {
      type: Object as PropType<TeacherColumnVisibilityAll>,
      required: true,
    },

    /**
     * ユーザーIDから、編集画面のパスを取得する。
     * 未指定の場合、編集画面へのリンクが現れない。
     */
    userIdToEditPath: {
      type: Function as PropType<(userId: string) => string>,
      required: false,
    },

    /**
     * trueなら、ユーザー項目などをリンク化する。
     */
    linkEnabled: { type: Boolean, required: true },
  },
  setup(props) {
    const allColumns = computed(() => getTeacherColumnDefs(props.schoolYear));

    return {
      allColumns,
      flattenedColumnVisibility: computed(() => ({
        ...props.columnVisibility.profileColumnVisibility.state,
      })),

      iconCellWidth: 28,
      nameCellWidth: 120,
      styles: { "--horizontal-margin": `${props.horizontalMargin}px` },
    };
  },
});
