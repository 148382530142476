import { Ref, ref } from "vue";
import throttle from "lodash/throttle";

export function useSearchConditionPopup<T>(
  valueRef: Ref<T>,
  afterConfirm: () => void,
): [Ref<boolean>, (active: boolean) => void, (value: T) => void] {
  const isPopupActive = ref(false);

  const changePopupActiveness = throttle(
    (active: boolean) => {
      isPopupActive.value = active;
    },
    5,
    { trailing: false },
  );

  // // shallowRefの意味と、なぜrefではうまく行かないのかが良く分かっていない。
  // // 参考: https://github.com/vuejs/vue-next/issues/1324
  // const valueRef = shallowRef(initialValue);

  const onConfirm = (value: T) => {
    isPopupActive.value = false;
    valueRef.value = value;
    afterConfirm();
  };

  return [isPopupActive, changePopupActiveness, onConfirm];
}
