
import { computed, defineComponent, PropType } from "vue";
import TextBalloon from "@/components/balloons/TextBalloon/TextBalloon.vue";
import { useHovering } from "@/composables/use-hovering";
import { useCopyToClipboard } from "@/composables/use-copy-to-clipboard";
import {
  DateValue,
  dateValueToDisplayValue,
} from "@/ts/objects/value/date-value";
import { isNullish } from "@/ts/utils/common-util";

export default defineComponent({
  name: "UserListTableCellDate",
  components: { TextBalloon },
  props: {
    date: { type: Object as PropType<DateValue>, required: false },
  },
  setup(props) {
    const { hovering, onMouseEnter, onMouseLeave } = useHovering(200);
    const { copyToClipboard } = useCopyToClipboard();

    const displayValue = computed(() => {
      const _date = props.date;
      if (isNullish(_date)) return "";
      return dateValueToDisplayValue(_date);
    });
    const isDisplayValueEmpty = computed(() => displayValue.value === "");

    return {
      hovering,
      onMouseEnter,
      onMouseLeave,

      copyToClipboard: () => copyToClipboard(displayValue.value),

      displayValue,
      isDisplayValueEmpty,
    };
  },
});
