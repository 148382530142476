import { ref, readonly, watchEffect, Ref, DeepReadonly } from "vue";

/**
 * Handle overlapping async evaluations
 *
 * @param cancelCallback 現在のfetchが終了する前にcomputedが再評価された場合、そのfetchをキャンセルするために呼び出される関数。
 */
export type AsyncComputedOnCancel = (cancelCallback: () => void) => void;

export type AsyncComputedResult<T> = [
  value: DeepReadonly<Ref<T>>,
  evaluating: DeepReadonly<Ref<boolean>>,
];

/**
 * async-computedな値を作成する。
 * 参考: https://gist.github.com/loilo/fbe3124108a46ff50ab1b867bb5b4bf9
 *
 * @param fetchValue   値を取得する関数。
 * @param defaultValue デフォルト値。最初に取得が成功するまで、この値が用いられる。
 */
export function useAsyncComputed<T>(
  fetchValue: (onCancel: AsyncComputedOnCancel) => T | Promise<T>,
  defaultValue: T,
): AsyncComputedResult<T> {
  let counter = 0;
  const current = ref(defaultValue) as Ref<T>;
  const evaluating = ref<boolean>(false);

  watchEffect(async (onInvalidate) => {
    counter++;
    const counterAtBeginning = counter;
    let hasFinished = false;

    try {
      // Defer initial setting of `evaluating` ref
      // to avoid having it as a dependency
      Promise.resolve().then(() => {
        evaluating.value = true;
      });

      const result = await fetchValue((cancelCallback) => {
        onInvalidate(() => {
          evaluating.value = false;
          if (!hasFinished) {
            cancelCallback();
          }
        });
      });

      if (counterAtBeginning === counter) {
        current.value = result;
      }
    } finally {
      evaluating.value = false;
      hasFinished = true;
    }
  });

  return [readonly(current), readonly(evaluating)];
}
