import { Teacher, TeacherProfile } from "@/ts/objects/entity/teacher";

export function createFakeTeacher(partial: Partial<Teacher> = {}): Teacher {
  const userId = partial?.userId ?? "teacher000";

  const defaultUserInfo = {
    userType: "teacher",
    googleMail: `${userId}@example.com`,
    photoUrl: "https://avatars.dicebear.com/api/adventurer/xdrwq.svg",
    name: "先生 瀬太郎",
  } as const;

  const defaultTeacherProfile: TeacherProfile = {
    classes: [
      {
        classId: "class-2000-e1-1",
        schoolYear: 2000,
        grade: { schoolType: "elementary", gradeNumber: 1 },
        classNo: 0,
        name: "2000-e1-0",
        inChargeType: "a",
      },
      {
        classId: "class-2000-e1-2",
        schoolYear: 2000,
        grade: { schoolType: "elementary", gradeNumber: 1 },
        classNo: 1,
        name: "2000-e1-1_verylonglonglonglonglonglongname",
        inChargeType: "a",
      },
      {
        classId: "class-2002-j1-1",
        schoolYear: 2002,
        grade: { schoolType: "juniorhigh", gradeNumber: 1 },
        classNo: 0,
        name: "2002-j1-0",
        inChargeType: "b",
      },
    ],

    nameKana: "せんせい せたろう",
    familyName: "先生",
    givenName: "瀬太郎",
    teacherType: "teacher",
    zipcode: "1234567",
    address: "京都府ペンシルバニア州2-3-4瀬戸パレス606号室",
    email: `${userId}@gmail.com`,
    mobilePhone: "08012345678",
    landlinePhone: "0612345678",
    foreignType: "0",
  };

  return {
    userId,
    ...defaultUserInfo,
    ...defaultTeacherProfile,
    ...partial,
  };
}

export const fakeTeacherBasicInfos = {
  teacher000: {
    userType: "teacher",
    userId: "teacher000",
    googleMail: "teacher000@example.com",
    photoUrl: "https://avatars.dicebear.com/api/adventurer/xdrwq.svg",
    name: "先生 瀬太郎",
  },
  teacher001: {
    userType: "teacher",
    userId: "teacher001",
    googleMail: "teacher001@example.com",
    photoUrl: "https://avatars.dicebear.com/api/adventurer/9%EF%BD%91.svg",
    name: "先生 瀬戸子",
  },
  teacher002: {
    userType: "teacher",
    userId: "teacher002",
    googleMail: "teacher002@example.com",
    photoUrl: "https://avatars.dicebear.com/api/adventurer/pazzntaed.svg",
    name: "先生 瀬戸助",
  },
  teacher003: {
    userType: "teacher",
    userId: "teacher003",
    googleMail: "teacher003@example.com",
    photoUrl: "https://avatars.dicebear.com/api/adventurer/tANq.svg",
    name: "先生 瀬戸美",
  },
} as const;

export function fakeTeachers(): {
  [userId in keyof typeof fakeTeacherBasicInfos]: Teacher;
} {
  return {
    teacher000: createFakeTeacher(fakeTeacherBasicInfos.teacher000),
    teacher001: createFakeTeacher(fakeTeacherBasicInfos.teacher001),
    teacher002: {
      ...createFakeTeacher(fakeTeacherBasicInfos.teacher002),
      classes: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((n) => ({
        classId: `class-2000-e3-${n}`,
        schoolYear: 2000,
        grade: { schoolType: "elementary", gradeNumber: 3 },
        classNo: n,
        name: `2000-e3-${n}`,
        inChargeType: "a",
      })),
    },
    teacher003: {
      ...createFakeTeacher(fakeTeacherBasicInfos.teacher003),
      classes: [],
    },
  };
}
