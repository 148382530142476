
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "PagerPageNumber",
  props: {
    number: { type: Number, required: true },
    active: { type: Boolean, required: true },

    onClick: { type: Function as PropType<() => void>, required: true },
  },
  setup(props) {
    return {
      onClickInternal: () => {
        if (props.active) return;
        props.onClick();
      },
    };
  },
});
