
import { defineComponent, PropType } from "vue";
import { ColorLabel, labelToColor } from "@/ts/objects/value/color-label";

export default defineComponent({
  name: "LoadingBlock",
  props: {
    color: { type: String as PropType<ColorLabel>, default: "gray" },
  },
  setup(props) {
    return {
      styles: {
        "--color": labelToColor(props.color),
      },
    };
  },
});
