
import { computed, defineComponent, PropType, ref, watchEffect } from "vue";
import { useAuthService } from "@/composables/provide-auth-service";
import { LoadableData } from "@/ts/app/loadable-data";
import { useFileUrlGetter } from "@/composables/provide-firebase-app";
import log from "loglevel";
import { useAppStore } from "@/store/app-store";
import BaseCustomDropdown from "@/components/dropdowns/BaseCustomDropdown/BaseCustomDropdown.vue";
import { DropdownItem } from "@/ts/app/dropdown-item";

export default defineComponent({
  name: "AppHeader",
  components: { BaseCustomDropdown },
  props: {
    schoolLogoGcsUrl: {
      type: Object as PropType<LoadableData<string>>,
      required: true,
    },
    schoolName: {
      type: Object as PropType<LoadableData<string>>,
      required: true,
    },
  },
  setup(props) {
    const appStore = useAppStore();
    const authService = useAuthService();
    const fileUrlGetter = useFileUrlGetter();

    const schoolLogoUrl = ref<string | null>(null);
    watchEffect(async () => {
      const _schoolLogoGcsUrl = props.schoolLogoGcsUrl;
      if (!_schoolLogoGcsUrl.hasData) {
        schoolLogoUrl.value = null;
        return;
      }

      log.debug(`_schoolLogoGcsUrl=${JSON.stringify(_schoolLogoGcsUrl)}`);
      const result = await fileUrlGetter(_schoolLogoGcsUrl.data);
      if (!result.ok) {
        schoolLogoUrl.value = null;
        return;
      }

      schoolLogoUrl.value = result.data;
    });

    const schoolNameInternal = computed<string | null>(
      () => props.schoolName.data,
    );

    const googleMail = computed(() => appStore.loginInfo.googleMail);

    const userIconUrl = computed(() => appStore.loginInfo.imageUrl);
    const userMenuOptions: DropdownItem[] = [
      { value: "changeUser", label: "Change user" },
    ];
    const onSelectUserMenuOption = (value: string | null) => {
      switch (value) {
        case "changeUser": {
          authService.changeGoogleAccount();
        }
      }
    };

    return {
      schoolLogoUrl,
      schoolNameInternal,

      googleMail,

      userIconUrl,
      userMenuOptions,
      onSelectUserMenuOption,
    };
  },
});
