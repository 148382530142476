import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseSmallButton = _resolveComponent("BaseSmallButton")!

  return (_openBlock(), _createBlock(_component_BaseSmallButton, {
    class: "right-button-container",
    color: _ctx.color,
    "color-on-hover": _ctx.colorOnHover,
    "background-color": "transparent",
    "background-color-on-hover": _ctx.backgroundColorOnHover,
    icon: "chevron-right",
    "icon-size": _ctx.iconSize,
    width: _ctx.size,
    height: _ctx.size,
    enabled: true,
    "on-click": _ctx.onClick
  }, null, 8, ["color", "color-on-hover", "background-color-on-hover", "icon-size", "width", "height", "on-click"]))
}