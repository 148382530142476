
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "SearchConditionDropdownButton",
  props: {
    label: { type: String, required: true },
    minWidth: { type: Number, default: 125 },

    onClick: { type: Function as PropType<() => void>, required: true },
  },
  setup(props) {
    return {
      styles: {
        "--min-width": `${props.minWidth}px`,
      },
    };
  },
});
