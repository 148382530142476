
import { computed, defineComponent, PropType } from "vue";
import EditableItemText from "@/components/editable-items/EditableItemText/EditableItemText.vue";
import MenuDropdown from "@/components/dropdowns/MenuDropdown/MenuDropdown.vue";
import { DropdownItem } from "@/ts/app/dropdown-item";
import { useHovering } from "@/composables/use-hovering";
import { ColorLabel } from "@/ts/objects/value/color-label";
import LeftButton from "@/components/buttons/arrow-buttons/LeftButton/LeftButton.vue";
import RightButton from "@/components/buttons/arrow-buttons/RightButton/RightButton.vue";

export default defineComponent({
  name: "CurriculumEditTableCell",
  components: { RightButton, LeftButton, MenuDropdown, EditableItemText },
  props: {
    name: { type: String, required: true },
    editing: { type: Boolean, required: true },
    /**
     * 削除済ならtrue。
     * trueの場合、削除済っぽく表示し、また操作できない。
     */
    deleted: { type: Boolean, required: true },

    onInputName: {
      type: Function as PropType<(value: string) => void>,
      required: true,
    },
    move: {
      type: Function as PropType<(up: boolean) => void>,
      required: true,
    },
    delete: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
  setup(props) {
    const { hovering, onMouseEnter, onMouseLeave } = useHovering();

    const editable = computed(() => props.editing && !props.deleted);

    const onInputNameInternal = (value: string) => {
      if (!editable.value) return;
      props.onInputName(value);
    };
    const moveInternal = (up: boolean) => {
      if (!editable.value) return;
      props.move(up);
    };
    const deleteInternal = () => {
      if (!editable.value) return;
      props.delete();
    };

    const buttonsColor = computed<ColorLabel>(() =>
      hovering.value ? "dark-gray" : "transparent",
    );
    const menuItems: DropdownItem[] = [{ value: "delete", label: "Delete" }];
    const onSelectMenuItem = (value: string | null) => {
      switch (value) {
        case "delete":
          deleteInternal();
          break;
      }
    };

    return {
      hovering,
      onMouseEnter,
      onMouseLeave,

      editable,

      buttonsColor,
      menuItems,
      onSelectMenuItem,

      onInputNameInternal,
      moveInternal,
    };
  },
});
