import {
  SearchConditionClass,
  searchConditionClassDefault,
  SearchConditionClassRaw,
  searchConditionClassToQueryParams,
  searchConditionClassToRaw,
} from "@/ts/objects/search-condition/search-condition-class";
import {
  UserSortCondition,
  userSortConditionDefault,
  UserSortConditionRaw,
  userSortConditionToQueryParams,
  userSortConditionToRaw,
} from "@/ts/objects/search-condition/user-sort-condition";
import {
  NumResultsCondition,
  numResultsConditionDefault,
  numResultsConditionToQueryParams,
} from "@/ts/objects/search-condition/num-results-condition";
import {
  SearchConditionTeacherType,
  searchConditionTeacherTypeDefault,
  SearchConditionTeacherTypeRaw,
  searchConditionTeacherTypeToQueryParams,
  searchConditionTeacherTypeToRaw,
} from "@/ts/objects/search-condition/search-condition-teacher-type";

export type TeacherSearchCondition = {
  readonly schoolYear: number;
  readonly queryText: string;
  readonly class: SearchConditionClass;
  readonly teacherType: SearchConditionTeacherType;
  readonly sort: UserSortCondition;
  readonly numResults: NumResultsCondition;
};

export function teacherSearchConditionDefault(
  schoolYear: number,
): TeacherSearchCondition {
  return {
    schoolYear,
    queryText: "",
    class: searchConditionClassDefault(schoolYear),
    teacherType: searchConditionTeacherTypeDefault(),
    sort: userSortConditionDefault(),
    numResults: numResultsConditionDefault(),
  };
}

export function teacherSearchConditionToQueryParams(
  cond: TeacherSearchCondition,
): Record<string, string | number | undefined> {
  return {
    schoolYear: cond.schoolYear,
    q: cond.queryText,
    ...searchConditionClassToQueryParams(cond.class),
    ...searchConditionTeacherTypeToQueryParams(cond.teacherType),
    ...userSortConditionToQueryParams(cond.sort),
    ...numResultsConditionToQueryParams(cond.numResults),
  };
}

export type TeacherSearchConditionRaw = {
  readonly schoolYear: number;
  readonly queryText: string;
  readonly class: SearchConditionClassRaw;
  readonly teacherType: SearchConditionTeacherTypeRaw;
  readonly sort: UserSortConditionRaw;
  readonly numResults: NumResultsCondition;
};

export function teacherSearchConditionToRaw(
  cond: TeacherSearchCondition,
): TeacherSearchConditionRaw {
  return {
    schoolYear: cond.schoolYear,
    queryText: cond.queryText,
    class: searchConditionClassToRaw(cond.class),
    teacherType: searchConditionTeacherTypeToRaw(cond.teacherType),
    sort: userSortConditionToRaw(cond.sort),
    numResults: cond.numResults,
  };
}
