
import { computed, defineComponent, PropType } from "vue";
import CurriculumPageHeading from "@/views/curriculum/_components/CurriculumPageHeading/CurriculumPageHeading.vue";
import EditButtonSet from "@/components/other/EditButtonSet/EditButtonSet.vue";
import SchoolTypeTabs from "@/components/other/SchoolTypeTabs/SchoolTypeTabs.vue";
import { SchoolType } from "@/ts/objects/value/school-type";
import { LoadableData } from "@/ts/app/loadable-data";
import { Curriculum } from "@/ts/objects/entity/curriculum";
import {
  CurriculumEditState,
  CurriculumEditTableData,
  curriculumEditTableDataFromCurriculums,
} from "@/views/curriculum/CurriculumEdit/curriculum-edit-data";
import SchoolYearSwitch from "@/components/other/SchoolYearSwitch/SchoolYearSwitch.vue";
import CurriculumEditTable from "@/views/curriculum/CurriculumEdit/CurriculumEditTable/CurriculumEditTable.vue";
import LoadingBlock from "@/components/loading/LoadingBlock/LoadingBlock.vue";
import BaseButton from "@/components/buttons/BaseButton/BaseButton.vue";

export default defineComponent({
  name: "CurriculumEdit",
  components: {
    BaseButton,
    LoadingBlock,
    CurriculumEditTable,
    SchoolYearSwitch,
    SchoolTypeTabs,
    EditButtonSet,
    CurriculumPageHeading,
  },
  props: {
    isAdmin: { type: Boolean, required: true },

    schoolType: { type: String as PropType<SchoolType>, required: true },
    changeSchoolType: {
      type: Function as PropType<(schoolType: SchoolType) => void>,
      required: true,
    },

    schoolYear: { type: Number, required: true },
    changeSchoolYear: {
      type: Function as PropType<(schoolYear: number) => void>,
      required: true,
    },

    /**
     * 閲覧モード時に用いるデータ。
     *
     * 編集中には表示に使わないし、変更もされない。
     * 編集を開始したとき、このデータが編集用データとして複製される。
     * 編集をキャンセルしたとき、このデータに戻る。
     * 編集をセーブしたとき、このデータが上書きされる。
     */
    data: {
      type: Object as PropType<LoadableData<Curriculum[]>>,
      required: true,
    },

    /**
     * 編集状態。
     * editingフラグと、編集モード時に用いるデータを持つ。
     */
    editState: {
      type: Object as PropType<CurriculumEditState>,
      required: true,
    },

    onUpdate: {
      type: Function as PropType<(data: CurriculumEditTableData) => void>,
      required: true,
    },
    /**
     * 前年度からコピーする。
     */
    copyFromPreviousYear: {
      type: Function as PropType<() => void>,
      required: true,
    },

    /**
     * 編集を開始する。
     */
    startEditing: { type: Function as PropType<() => void>, required: true },
    /**
     * 編集をキャンセルする。
     */
    cancelEditing: { type: Function as PropType<() => void>, required: true },
    /**
     * セーブし、編集を終了する。
     */
    save: { type: Function as PropType<() => void>, required: true },
  },
  setup(props) {
    const viewData = computed<CurriculumEditTableData>(() =>
      curriculumEditTableDataFromCurriculums(
        props.schoolYear,
        props.schoolType,
        props.data.data ?? [],
      ),
    );

    return {
      viewData,
    };
  },
});
