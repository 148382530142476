
import { defineComponent, PropType } from "vue";
import { isNullish } from "@/ts/utils/common-util";

export default defineComponent({
  name: "BaseRadio",
  props: {
    /**
     * ラジオボタングループの名前。
     */
    radioGroupName: { type: String, required: true },
    /**
     * ラジオボタンを選択した時に送信される値。
     */
    radioValue: { type: String, required: true },
    /**
     * ラジオボタングループの中で選択中の値。
     */
    selectedRadioValue: { type: String, required: true },

    /**
     * フォントサイズ。
     * slot部分に適用するだけなので、本プロパティは用いず、本コンポーネント外で直接CSSから指定しても良い。
     */
    fontSize: { type: Number, default: 14 },
    radioButtonScale: { type: Number, default: 1 },
    labelMarginLeft: { type: Number, default: 4 },

    onSelect: {
      type: Function as PropType<(radioValue: string) => void>,
      required: true,
    },
  },
  setup(props) {
    return {
      onSelectInternal: (e: Event) => {
        const value = (e.target as HTMLInputElement).value;
        if (isNullish(value)) return;
        props.onSelect(value);
      },

      styles: {
        "--font-size": `${props.fontSize}px`,
        "--radio-button-scale": props.radioButtonScale,
        "--label-margin-left": `${props.labelMarginLeft}px`,
      },
    };
  },
});
