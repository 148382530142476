
import { defineComponent, PropType } from "vue";
import PageHeading from "@/components/other/PageHeading/PageHeading.vue";

export default defineComponent({
  name: "SubPageHeading",
  components: { PageHeading },
  props: {
    mainIconType: { type: String, default: "fas" }, // fas or far
    mainIcon: { type: String, required: true },
    mainLabel: { type: String, required: true },

    subLabel: { type: String, required: false },

    mainPageHeadingLinkPath: { type: String, required: true },
    onClickBack: { type: Function as PropType<() => void>, required: true },
  },
});
