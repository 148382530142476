
import { defineComponent, PropType, ref, watch } from "vue";
import {
  dateToDateValueNullable,
  DateValue,
  dateValueToDateNullable,
} from "@/ts/objects/value/date-value";
import { Locale } from "date-fns";
import Datepicker from "vue3-datepicker";
import { ColorLabel, labelToColor } from "@/ts/objects/value/color-label";
import cloneDeep from "lodash/cloneDeep";
import { hasValue, isNullish } from "@/ts/utils/common-util";

const TIME_RESOLUTIONS = ["day", "month", "year"];

export default defineComponent({
  name: "BaseDatePicker",
  components: {
    Datepicker,
  },
  props: {
    value: { type: Object as PropType<DateValue>, required: false },
    onChange: {
      type: Function as PropType<(value: DateValue | null) => void>,
      required: true,
    },

    fontSize: { type: Number, default: 14 },
    fontColor: { type: String as PropType<ColorLabel>, default: "black" },

    placeholder: {
      type: String,
      default: "",
    },
    /**
     * Dates not available for picking
     */
    disabledDates: {
      type: Object as PropType<{ dates?: Date[] }>,
      required: false,
    },
    /**
     * Upper limit for available dates for picking
     */
    upperLimit: {
      type: Date as PropType<Date>,
      required: false,
    },
    /**
     * Lower limit for available dates for picking
     */
    lowerLimit: {
      type: Date as PropType<Date>,
      required: false,
    },
    /**
     * View on which the date picker should open. Can be either `year`, `month`, or `day`
     */
    startingView: {
      type: String as PropType<"year" | "month" | "day">,
      required: false,
      default: "day",
      validator: (v: unknown) =>
        typeof v === "string" && TIME_RESOLUTIONS.includes(v),
    },
    /**
     * `date-fns`-type formatting for a month view heading
     */
    monthHeadingFormat: {
      type: String,
      required: false,
      default: "LLLL yyyy",
    },
    /**
     * `date-fns`-type formatting for the month picker view
     */
    monthListFormat: {
      type: String,
      required: false,
      default: "LLL",
    },
    /**
     * `date-fns`-type formatting for a line of weekdays on day view
     */
    weekdayFormat: {
      type: String,
      required: false,
      default: "EE",
    },
    /**
     * `date-fns`-type format in which the string in the input should be both
     * parsed and displayed
     */
    inputFormat: {
      type: String,
      required: false,
      default: "yyyy-MM-dd",
    },
    /**
     * [`date-fns` locale object](https://date-fns.org/v2.16.1/docs/I18n#usage).
     * Used in string formatting (see default `monthHeadingFormat`)
     */
    locale: {
      type: Object as PropType<Locale>,
      required: false,
    },
    /**
     * Day on which the week should start.
     *
     * Number from 0 to 6, where 0 is Sunday and 6 is Saturday.
     * Week starts with a Monday (1) by default
     */
    weekStartsOn: {
      type: Number,
      required: false,
      default: 1,
      validator: (value: any) => [0, 1, 2, 3, 4, 5, 6].includes(value),
    },
    /**
     * Disables datepicker and prevents it's opening
     */
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * Clears selected date
     */
    clearable: {
      type: Boolean,
      required: false,
      default: false,
    },
    /*
     * Allows user to input date manually
     */
    typeable: {
      type: Boolean,
      required: false,
      default: true,
    },
    /**
     * If set, lower-level views won't show
     */
    minimumView: {
      type: String as PropType<"year" | "month" | "day">,
      required: false,
      default: "day",
      validator: (v: unknown) =>
        typeof v === "string" && TIME_RESOLUTIONS.includes(v),
    },
  },
  setup(props) {
    const modelValue = ref(dateValueToDateNullable(props.value));
    watch(modelValue, (newVal, oldVal) => {
      if (isNullish(newVal) && isNullish(oldVal)) {
        return;
      }
      if (hasValue(newVal) && hasValue(oldVal) && isSameDate(newVal, oldVal)) {
        return;
      }
      props.onChange(dateToDateValueNullable(newVal));
    });

    return {
      modelValue,

      clear: () => {
        modelValue.value = null;
      },
      onFocus: (e: Event) => {
        (e.target as HTMLInputElement).select();
      },
      onBlur: () => {
        // 自由入力によって変な値で完了したとき、見た目をまともな値に戻す。
        modelValue.value = cloneDeep(modelValue.value);
      },

      styles: {
        "--text-color": labelToColor("black"),
        "--vdp-text-color": labelToColor("black"),
        "--vdp-elem-color": labelToColor("black"),
        "font-size": `${props.fontSize}px`,
        color: labelToColor(props.fontColor),
        width: "100%",
      },
    };
  },
});

function isSameDate(date0: Date, date1: Date) {
  return (
    date0.getFullYear() === date1.getFullYear() &&
    date0.getMonth() === date1.getMonth() &&
    date0.getDate() === date1.getDate()
  );
}
