
import { defineComponent, PropType } from "vue";
import CollapseButtonLR from "@/components/buttons/CollapseButtonLR/CollapseButtonLR.vue";
import UserColumnsSelectSection from "@/components/other/UserColumnsSelectSection/UserColumnsSelectSection.vue";
import { UserColumnVisibility } from "@/ts/app/columns/visibility/user-column-visibility";
import { GuardianColumnVisibilityAll } from "@/ts/app/columns/visibility/guardian-column-visibility";
import { names, ObjectInternalName } from "@/ts/app/object-name";
import {
  guardianListNotesColumnIds,
  guardianListProfileColumnIds,
} from "@/ts/app/columns/user-list/guardian-list-columns";
import { getGuardianColumnDefsGrouped } from "@/ts/app/columns/def/guardian/guardian-column";

export default defineComponent({
  name: "GuardianColumnsSelect",
  components: { CollapseButtonLR, UserColumnsSelectSection },
  props: {
    columnVisibility: {
      type: Object as PropType<GuardianColumnVisibilityAll>,
      required: true,
    },
    open: { type: Boolean, required: true },

    onClickCollapseButton: {
      type: Function as PropType<() => void>,
      required: true,
    },
    onChangeColumnVisibility: {
      type: Function as PropType<
        (state: UserColumnVisibility<ObjectInternalName, string>) => void
      >,
      required: true,
    },
  },
  setup(props) {
    const columnDefsGrouped = getGuardianColumnDefsGrouped();

    const profileSectionTitle =
      names[props.columnVisibility.profileColumnVisibility.groupName].d;
    const profileColumnIds: string[] = guardianListProfileColumnIds;
    const profileColumnDefs =
      columnDefsGrouped.profileColumnDefGroup.columns.filter((c) =>
        profileColumnIds.includes(c.id),
      );

    const notesSectionTitle =
      names[props.columnVisibility.notesColumnVisibility.groupName].d;
    const notesColumnIds: string[] = guardianListNotesColumnIds;
    const notesColumnDefs =
      columnDefsGrouped.notesColumnDefGroup.columns.filter((c) =>
        notesColumnIds.includes(c.id),
      );

    return {
      profileSectionTitle,
      profileColumnDefs,

      notesSectionTitle,
      notesColumnDefs,
    };
  },
});
