import { DateValue } from "@/ts/objects/value/date-value";
import { Term } from "@/ts/objects/entity/term";
import groupBy from "lodash/groupBy";
import { parseIntOrError } from "@/ts/utils/common-util";
import { names } from "@/ts/app/object-name";
import log from "loglevel";

export type UnsavedTermEditData = {
  elementary: TermTableModel;
  juniorhigh: TermTableModel;
};

export type TermTableModel = {
  [schoolYear: number]: TermTableRowModel;
};

export type TermTableRowModel = {
  cells: { [termNumber: number]: TermTableCellModel };
};

export type TermTableCellModel = {
  startDate: DateValue | null;
  endDate: DateValue | null;

  /**
   * 状態。
   * new: 今回の編集中に、新規作成したterm。
   * new-deleted: 新規作成したtermで、削除済。（deleteリクエストが不要なので、ただのdeletedと区別する。）
   * unchanged: 既存のtermで、変更なし。
   * changed: 既存のtermで、変更あり。不正な状態(どちらかのdateがnull, startDate > endDate 等)も含む。
   * deleted: 既存のtermで、削除済。
   */
  state: "new" | "new-deleted" | "unchanged" | "changed" | "deleted";
};

/**
 * 学期のリストから、unsavedTermEditDataを作成する。
 */
export function unsavedTermEditDataFromTerms(
  terms: Term[],
): UnsavedTermEditData {
  const elementaryTerms = terms.filter((t) => t.schoolType === "elementary");
  const juniorhighTerms = terms.filter((t) => t.schoolType === "juniorhigh");

  return {
    elementary: termTableModelFromTerms(elementaryTerms),
    juniorhigh: termTableModelFromTerms(juniorhighTerms),
  };
}

/**
 * 同じ学校タイプに属す学期のリストから、unsavedSchoolYearsを作成する。
 * 同じ学校タイプに属すことの確認はせず、呼び出し側の責務とする。
 */
export function termTableModelFromTerms(terms: Term[]): TermTableModel {
  const schoolYearGroups = groupBy(terms, (t) => t.schoolYear);
  log.debug(
    `termTableModelFromTerms: schoolYearGroups=${JSON.stringify(
      schoolYearGroups,
    )}`,
  );
  const schoolYearAndRowModelPairs = Object.entries(schoolYearGroups).map(
    ([stringSchoolYear, _terms]): [number, TermTableRowModel] => {
      const schoolYear = parseIntOrError(stringSchoolYear, names.schoolYear);
      const cells = _terms.map((t) => [
        t.termNumber,
        {
          startDate: t.startDate,
          endDate: t.endDate,
          state: "unchanged" as const,
        },
      ]);
      return [schoolYear, { cells: Object.fromEntries(cells) }];
    },
  );
  return Object.fromEntries(schoolYearAndRowModelPairs);
}

export type TermEditTableShape = {
  /**
   * 小学校選択時のテーブルに表示する最大termNumber = 列数。
   * 表示に使うだけなので、実際の最大値とずれていても良い。
   */
  elementaryMaxTermNumber: number;
  /**
   * 小学校選択時のテーブルに表示する最小年度。
   * 表示に使うだけなので、実際の最小値とずれていても良い。
   */
  elementaryMinSchoolYear: number;
  /**
   * 小学校選択時のテーブルに表示する最大年度。
   * 表示に使うだけなので、実際の最大値とずれていても良い。
   */
  elementaryMaxSchoolYear: number;

  /**
   * 中学校選択時のテーブルに表示する最大termNumber = 列数。
   * 表示に使うだけなので、実際の最大値とずれていても良い。
   */
  juniorhighMaxTermNumber: number;
  /**
   * 中学校選択時のテーブルに表示する最小年度。
   * 表示に使うだけなので、実際の最小値とずれていても良い。
   */
  juniorhighMinSchoolYear: number;
  /**
   * 中学校選択時のテーブルに表示する最大年度。
   * 表示に使うだけなので、実際の最大値とずれていても良い。
   */
  juniorhighMaxSchoolYear: number;
};
