import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    type: "button",
    class: _normalizeClass(["main-menu-item-container", { active: _ctx.active }]),
    to: _ctx.linkPath
  }, {
    default: _withCtx(() => [
      _createVNode(_component_font_awesome_icon, {
        class: _normalizeClass(["icon", { active: _ctx.active }]),
        icon: [_ctx.iconType, _ctx.icon]
      }, null, 8, ["class", "icon"]),
      _createElementVNode("span", {
        class: _normalizeClass(["label", { active: _ctx.active }])
      }, _toDisplayString(_ctx.label), 3)
    ]),
    _: 1
  }, 8, ["class", "to"]))
}