import { UserColumnDef } from "@/ts/app/columns/def/user-column-def";
import { User } from "@/ts/objects/entity/user";
import { ObjectInternalName } from "@/ts/app/object-name";

export type UserColumn<
  ColId extends ObjectInternalName,
  U extends User,
  T,
> = UserColumnDef<ColId, U, T> & {
  readonly value: T;
};

export function attachValue<
  ColId extends ObjectInternalName,
  U extends User,
  T,
>(col: UserColumnDef<ColId, U, T>, user: U): UserColumn<ColId, U, T> {
  const value = col.getValue(user);
  return {
    ...col,
    value,
  };
}

export function attachValueToCols<
  ColId extends ObjectInternalName,
  U extends User,
>(cols: UserColumnDef<ColId, U, any>[], user: U): UserColumn<ColId, U, any>[] {
  return cols.map((col) => attachValue(col, user));
}
