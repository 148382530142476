import { LocationQuery } from "vue-router";
import { asIntOrNull, isNullish } from "@/ts/utils/common-util";

export type NumResultsCondition = {
  n: number;
};

export function numResultsConditionDefault(): NumResultsCondition {
  return { n: 50 };
}

export function numResultsConditionToDisplayValue(
  cond: NumResultsCondition,
): string {
  return `${cond.n}件ずつ表示`;
}

type QueryParams = {
  n: number | undefined;
};

export function numResultsConditionFromQueryParams(
  query: LocationQuery,
): NumResultsCondition {
  const n = asIntOrNull(query["n"]);
  if (isNullish(n)) return numResultsConditionDefault();
  return { n };
}

export function numResultsConditionToQueryParams(
  cond: NumResultsCondition,
): QueryParams {
  return { n: cond.n };
}
