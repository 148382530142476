import { CurriculumEvalType } from "@/ts/objects/value/curriculum-eval-type";
import { Grade, gradeToGradeValue } from "@/ts/objects/value/grade";
import { Result } from "@/ts/app/result";
import {
  Curriculum,
  curriculumFromEECRespOrError,
  curriculumFromNECRespOrError,
} from "@/ts/objects/entity/curriculum";
import { DefaultApi as CurriculumDefaultApi } from "@/ts/api/curriculum-service";
import { Configuration } from "@/ts/api/user-service";
import { AxiosInstance } from "axios";
import { doReq } from "@/ts/utils/app-util";
import { CheckFailed } from "@/ts/app/error/check-error";
import { HashedString } from "@/ts/objects/value/hashed-string";
import { fakeCurriculums } from "@/ts/objects/entity/curriculum-fake";
import { delay, hasValue, isNullish } from "@/ts/utils/common-util";
import { v4 as uuidv4 } from "uuid";
import { errors } from "@/ts/app/error/app-error";
import { names } from "@/ts/app/object-name";
import sortBy from "lodash/sortBy";
import log from "loglevel";

export abstract class CurriculumService {
  abstract listCurriculums(
    evalType: CurriculumEvalType,
    schoolYear: number,
    grade: Grade | null,
  ): Promise<Result<Curriculum[]>>;

  abstract postCurriculum(
    evalType: CurriculumEvalType,
    schoolYear: number,
    grade: Grade,
    name: HashedString,
    orderNum: number | null,
  ): Promise<Result<Curriculum>>;

  abstract patchCurriculum(
    evalType: CurriculumEvalType,
    id: string,
    name: HashedString,
    orderNum: number | null,
  ): Promise<Result<Curriculum>>;

  abstract deleteCurriculum(
    evalType: CurriculumEvalType,
    id: string,
  ): Promise<Result<true>>;
}

export type CurriculumServiceMockInitialData = {
  readonly curriculums?: Curriculum[];
};

export class CurriculumServiceMock extends CurriculumService {
  private curriculums: Curriculum[];

  constructor(
    data?: CurriculumServiceMockInitialData,
    private fakeLatencyMillis: number = 500,
  ) {
    super();

    const defaults = { curriculums: fakeCurriculums() };
    const _data = isNullish(data) ? defaults : { ...defaults, ...data };

    this.curriculums = _data.curriculums;
  }

  async listCurriculums(
    this: this,
    evalType: CurriculumEvalType,
    schoolYear: number,
    grade: Grade | null,
  ): Promise<Result<Curriculum[]>> {
    await delay(this.fakeLatencyMillis);

    const curriculums = this.curriculums.filter(
      (c) =>
        c.evalType === evalType &&
        c.schoolYear === schoolYear &&
        (isNullish(grade) ||
          (c.grade.schoolType === grade.schoolType &&
            c.grade.gradeNumber === grade.gradeNumber)),
    );

    return {
      ok: true,
      data: sortBy(curriculums, ["orderNum"]),
    };
  }

  async postCurriculum(
    this: this,
    evalType: CurriculumEvalType,
    schoolYear: number,
    grade: Grade,
    name: HashedString,
    orderNum: number | null,
  ): Promise<Result<Curriculum>> {
    await delay(this.fakeLatencyMillis);

    log.debug(
      `CurriculumServiceMock.postCurriculum: evalType=${evalType}, schoolYear=${schoolYear}, schoolType=${grade.schoolType}, gradeNumber=${grade.gradeNumber}, name=${name.value}, orderNum=${orderNum}`,
    );

    const _orderNum: number = hasValue(orderNum)
      ? orderNum
      : (() => {
          const currentMax = Math.max(
            ...this.curriculums.map((c) => c.orderNum),
          );
          return isFinite(currentMax) ? currentMax + 1 : 0;
        })();

    const id = uuidv4();
    const newCurriculum: Curriculum = {
      evalType,
      id,
      schoolYear,
      grade,
      name: { value: name.value, hash: "dummy-hash" },
      orderNum: _orderNum,
      syllabusFileGcsUrl: null,
    };

    this.curriculums = [...this.curriculums, newCurriculum];

    return { ok: true, data: newCurriculum };
  }

  async patchCurriculum(
    this: this,
    evalType: CurriculumEvalType,
    id: string,
    name: HashedString,
    orderNum: number | null,
  ): Promise<Result<Curriculum>> {
    await delay(this.fakeLatencyMillis);

    log.debug(
      `CurriculumServiceMock.patchCurriculum: evalType=${evalType}, id=${id}, name=${name.value}, orderNum=${orderNum}`,
    );

    const _curriculum = this.curriculums.find(
      (c) => c.evalType === evalType && c.id === id,
    );
    if (isNullish(_curriculum))
      return { ok: false, error: errors.updateFailed(names.curriculum) };

    const updatedCurriculum: Curriculum = {
      ..._curriculum,
      name: { value: name.value, hash: "dummy-hash" },
      orderNum: hasValue(orderNum) ? orderNum : _curriculum.orderNum,
    };
    this.curriculums = [
      ...this.curriculums.filter(
        (c) => !(c.evalType === evalType && c.id === id),
      ),
      updatedCurriculum,
    ];

    return { ok: true, data: updatedCurriculum };
  }

  async deleteCurriculum(
    this: this,
    evalType: CurriculumEvalType,
    id: string,
  ): Promise<Result<true>> {
    await delay(this.fakeLatencyMillis);

    log.debug(
      `CurriculumServiceMock.deleteCurriculum: evalType=${evalType}, id=${id}`,
    );

    const _curriculum = this.curriculums.find(
      (c) => c.evalType === evalType && c.id === id,
    );
    if (isNullish(_curriculum))
      return { ok: false, error: errors.deleteFailed(names.curriculum) };

    this.curriculums = this.curriculums.filter(
      (c) => !(c.evalType === evalType && c.id === id),
    );

    return { ok: true, data: true };
  }
}

export class CurriculumServiceImpl extends CurriculumService {
  private readonly curriculumService: CurriculumDefaultApi;

  constructor(
    serviceBasePath: string,
    axiosConf: Configuration | undefined,
    axiosInstance: AxiosInstance,
  ) {
    super();
    this.curriculumService = new CurriculumDefaultApi(
      axiosConf,
      serviceBasePath,
      axiosInstance,
    );
  }

  async listCurriculums(
    this: this,
    evalType: CurriculumEvalType,
    schoolYear: number,
    grade: Grade | null,
  ): Promise<Result<Curriculum[]>> {
    const gradeValue = hasValue(grade) ? gradeToGradeValue(grade) : undefined;

    switch (evalType) {
      case "numeric": {
        const resp = await doReq(() =>
          this.curriculumService.listNECurriculum(schoolYear, gradeValue),
        );
        if (!resp.ok) return resp;
        return { ok: true, data: resp.data.map(curriculumFromNECRespOrError) };
      }
      case "essay": {
        const resp = await doReq(() =>
          this.curriculumService.listEECurriculum(schoolYear, gradeValue),
        );
        if (!resp.ok) return resp;
        return { ok: true, data: resp.data.map(curriculumFromEECRespOrError) };
      }
      default:
        throw new CheckFailed();
    }
  }

  async postCurriculum(
    this: this,
    evalType: CurriculumEvalType,
    schoolYear: number,
    grade: Grade,
    name: HashedString,
    orderNum: number | null,
  ): Promise<Result<Curriculum>> {
    switch (evalType) {
      case "numeric": {
        const resp = await doReq(() =>
          this.curriculumService.postNECurriculum({
            schoolYear,
            grade: gradeToGradeValue(grade),
            name,
            orderNum: orderNum ?? undefined,
          }),
        );
        if (!resp.ok) return resp;

        const viewPointResps = await Promise.all([
          doReq(() =>
            this.curriculumService.postViewPoint(resp.data.necId, {
              name: { value: "知識・技能", hash: "" },
              color: { red: 218, green: 59, blue: 116 },
              orderNum: 0,
            }),
          ),
          doReq(() =>
            this.curriculumService.postViewPoint(resp.data.necId, {
              name: { value: "思考・判断・表現", hash: "" },
              color: { red: 79, green: 101, blue: 219 },
              orderNum: 1,
            }),
          ),
          doReq(() =>
            this.curriculumService.postViewPoint(resp.data.necId, {
              name: { value: "学びに向かう力・人間性等", hash: "" },
              color: { red: 54, green: 131, blue: 47 },
              orderNum: 2,
            }),
          ),
        ]);
        for (const r of viewPointResps) {
          if (!r.ok) return r;
        }

        return { ok: true, data: curriculumFromNECRespOrError(resp.data) };
      }
      case "essay": {
        const resp = await doReq(() =>
          this.curriculumService.postEECurriculum({
            schoolYear,
            grade: gradeToGradeValue(grade),
            name,
            orderNum: orderNum ?? undefined,
          }),
        );
        if (!resp.ok) return resp;
        return { ok: true, data: curriculumFromEECRespOrError(resp.data) };
      }
      default:
        throw new CheckFailed();
    }
  }

  async patchCurriculum(
    this: this,
    evalType: CurriculumEvalType,
    id: string,
    name: HashedString,
    orderNum: number | null,
  ): Promise<Result<Curriculum>> {
    switch (evalType) {
      case "numeric": {
        const resp = await doReq(() =>
          this.curriculumService.patchNECurriculum(id, {
            name,
            orderNum: orderNum ?? undefined,
          }),
        );
        if (!resp.ok) return resp;
        return { ok: true, data: curriculumFromNECRespOrError(resp.data) };
      }
      case "essay": {
        const resp = await doReq(() =>
          this.curriculumService.patchEECurriculum(id, {
            name,
            orderNum: orderNum ?? undefined,
          }),
        );
        if (!resp.ok) return resp;
        return { ok: true, data: curriculumFromEECRespOrError(resp.data) };
      }
      default:
        throw new CheckFailed();
    }
  }

  async deleteCurriculum(
    this: this,
    evalType: CurriculumEvalType,
    id: string,
  ): Promise<Result<true>> {
    switch (evalType) {
      case "numeric": {
        const resp = await doReq(() =>
          this.curriculumService.deleteNECurriculum(id),
        );
        if (!resp.ok) return resp;
        return { ok: true, data: true };
      }
      case "essay": {
        const resp = await doReq(() =>
          this.curriculumService.deleteEECurriculum(id),
        );
        if (!resp.ok) return resp;
        return { ok: true, data: true };
      }
      default:
        throw new CheckFailed();
    }
  }
}
