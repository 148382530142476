
import { labelToColor } from "@/ts/objects/value/color-label";
import { defineComponent, PropType } from "vue";
import { SchoolType } from "@/ts/objects/value/school-type";

export default defineComponent({
  name: "SchoolTypeTabs",
  components: {},
  props: {
    currentTab: {
      type: String as PropType<SchoolType>,
      required: true,
    },
    changeTab: {
      type: Function as PropType<(tab: SchoolType) => void>,
      required: true,
    },

    enabled: { type: Boolean, required: true },
  },
  setup(props) {
    const changeTabInternal = (tab: SchoolType) => {
      if (!props.enabled) return;
      props.changeTab(tab);
    };

    return {
      changeTabInternal,

      styles: {
        "--color": labelToColor("green"),
      },
    };
  },
});
