import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-aeed4a5a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "user-list-table-cell-users-container"
}
const _hoisted_2 = {
  key: 1,
  class: "user-list-table-cell-users-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.isLink)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
          return (_openBlock(), _createBlock(_component_router_link, {
            key: item.userId,
            class: "item link",
            to: item.linkPath
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(item.displayValue), 1)
            ]),
            _: 2
          }, 1032, ["to"]))
        }), 128))
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
          return (_openBlock(), _createElementBlock("div", {
            key: item.userId,
            class: "item"
          }, _toDisplayString(item.displayValue), 1))
        }), 128))
      ]))
}