import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-11e20842"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sub-page-heading-container" }
const _hoisted_2 = { class: "sub-heading" }
const _hoisted_3 = {
  key: 0,
  class: "sub-label"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageHeading = _resolveComponent("PageHeading")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_link, {
      class: "main-page-heading",
      to: _ctx.mainPageHeadingLinkPath
    }, {
      default: _withCtx(() => [
        _createVNode(_component_PageHeading, {
          class: "main-heading",
          "icon-type": _ctx.mainIconType,
          icon: _ctx.mainIcon,
          label: _ctx.mainLabel
        }, null, 8, ["icon-type", "icon", "label"])
      ]),
      _: 1
    }, 8, ["to"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        type: "button",
        class: "back-button",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClickBack && _ctx.onClickBack(...args)))
      }, [
        _createVNode(_component_font_awesome_icon, {
          class: "back-icon",
          icon: ['fas', 'arrow-left']
        })
      ]),
      (_ctx.subLabel != null)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.subLabel), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}