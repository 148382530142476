
import { computed, defineComponent, PropType } from "vue";
import { ColorLabel, labelToColor } from "@/ts/objects/value/color-label";
import { hasValue, isNullish } from "@/ts/utils/common-util";

export default defineComponent({
  name: "BaseButton",
  props: {
    color: { type: String as PropType<ColorLabel>, default: "black" },
    backgroundColor: { type: String as PropType<ColorLabel>, default: "white" },
    backgroundColorOnHover: { type: String as PropType<ColorLabel> },

    iconType: { type: String, default: "fas" }, // fas or far
    icon: { type: String, default: null },
    iconSize: { type: Number, default: 18 },
    iconPaddingBottom: { type: Number, default: 0 },
    iconMarginRight: { type: Number, default: 4 },

    label: { type: String, required: true },
    labelPaddingBottom: { type: Number, default: 1 },

    borderColor: { type: String as PropType<ColorLabel>, default: "gray" },
    borderRadius: { type: Number, default: 6 },
    borderWidth: { type: Number, default: 1 },

    width: { type: Number, default: null },
    height: { type: Number, default: null },
    paddingHorizontal: { type: Number, default: 8 },
    paddingRight: { type: Number, default: null }, // 設定した場合、paddingHorizontalより優先。
    paddingLeft: { type: Number, default: null }, // 設定した場合、paddingHorizontalより優先。
    fontSize: { type: Number, default: 16 },
    bold: { type: Boolean, default: true },

    enabled: { type: Boolean, default: true },
    onClick: { type: Function as PropType<() => void>, required: true },
  },
  setup(props) {
    const bgColor = labelToColor(props.backgroundColor);
    const bgColorOnHover = hasValue(props.backgroundColorOnHover)
      ? labelToColor(props.backgroundColorOnHover)
      : null;

    const paddingRight = hasValue(props.paddingRight)
      ? props.paddingRight
      : props.paddingHorizontal;
    const paddingLeft = hasValue(props.paddingLeft)
      ? props.paddingLeft
      : props.paddingHorizontal;

    return {
      styles: computed(() => ({
        "--color": labelToColor(props.color),
        "--background-color": bgColor,
        "--background-color-on-hover": hasValue(bgColorOnHover)
          ? bgColorOnHover
          : bgColor,

        "--icon-size": `${props.iconSize}px`,
        "--icon-padding-bottom": `${props.iconPaddingBottom}px`,
        "--icon-margin-right": `${props.iconMarginRight}px`,

        "--label-padding-bottom": `${props.labelPaddingBottom}px`,

        "--border-width": `${props.borderWidth}px`,
        "--border-radius": `${props.borderRadius}px`,
        "--border-color": labelToColor(props.borderColor),

        "--width": isNullish(props.width) ? "auto" : `${props.width}px`,
        "--height": getHeight(props.height, props.fontSize),
        "--padding-right": `${paddingRight}px`,
        "--padding-left": `${paddingLeft}px`,
        "--font-size": `${props.fontSize}px`,
        "--font-weight": props.bold ? "bold" : "normal",
      })),
      onClickInternal: () => {
        if (!props.enabled) return;
        props.onClick();
      },
    };
  },
});

function getHeight(heightProp: number | null, fontSizeProp: number): string {
  if (hasValue(heightProp)) return `${heightProp}px`;
  if (fontSizeProp <= 24) return "32px";
  return `${fontSizeProp + 8}px`;
}
