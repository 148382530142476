import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-43e4e4b1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "student-edit-container" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StudentSubPageHeading = _resolveComponent("StudentSubPageHeading")!
  const _component_EditButtonSet = _resolveComponent("EditButtonSet")!
  const _component_UserEditHeaderUpper = _resolveComponent("UserEditHeaderUpper")!
  const _component_StudentEditHeaderLower = _resolveComponent("StudentEditHeaderLower")!
  const _component_StudentEditTable = _resolveComponent("StudentEditTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_StudentSubPageHeading, {
        class: "page-heading",
        "sub-label": "Student Details",
        "main-page-heading-link-path": "/student",
        "on-click-back": _ctx.onClickBack
      }, null, 8, ["on-click-back"]),
      (_ctx.isAdmin)
        ? (_openBlock(), _createBlock(_component_EditButtonSet, {
            key: 0,
            class: "edit-button-set",
            editing: _ctx.editState.editing,
            "on-click-edit": _ctx.startEditing,
            "on-click-save": _ctx.save,
            "on-click-cancel": _ctx.cancelEditing
          }, null, 8, ["editing", "on-click-edit", "on-click-save", "on-click-cancel"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.student != null)
        ? (_openBlock(), _createBlock(_component_UserEditHeaderUpper, {
            key: 0,
            class: "body-head-upper",
            "user-id": _ctx.student.userId,
            "google-mail": _ctx.student.googleMail,
            "photo-url": _ctx.student.photoUrl,
            name: _ctx.student.name,
            editing: _ctx.editState.editing,
            "delete-user": _ctx.deleteUser
          }, null, 8, ["user-id", "google-mail", "photo-url", "name", "editing", "delete-user"]))
        : _createCommentVNode("", true),
      (_ctx.profileColumnGroup != null)
        ? (_openBlock(), _createBlock(_component_StudentEditHeaderLower, {
            key: 1,
            class: "body-head-lower",
            "profile-column-group": _ctx.profileColumnGroup,
            "link-enabled": !_ctx.editState.editing
          }, null, 8, ["profile-column-group", "link-enabled"]))
        : _createCommentVNode("", true),
      (
          _ctx.profileColumnGroup != null &&
          _ctx.healthColumnGroup != null &&
          _ctx.customColumnGroup != null
        )
        ? (_openBlock(), _createBlock(_component_StudentEditTable, {
            key: 2,
            class: "table",
            "column-groups": {
          profileColumnGroup: _ctx.profileColumnGroup,
          healthColumnGroup: _ctx.healthColumnGroup,
          customColumnGroup: _ctx.customColumnGroup,
        },
            "selected-tab": _ctx.selectedTab,
            editing: _ctx.editState.editing,
            "on-input": _ctx.onInput,
            "on-select-tab": _ctx.onSelectTab
          }, null, 8, ["column-groups", "selected-tab", "editing", "on-input", "on-select-tab"]))
        : _createCommentVNode("", true)
    ])
  ]))
}