import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b13e8176"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "user-list-table-cell-image-container" }
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.url != null)
      ? (_openBlock(), _createElementBlock("a", {
          key: 0,
          class: "link",
          href: _ctx.url,
          target: "_blank"
        }, "View", 8, _hoisted_2))
      : _createCommentVNode("", true)
  ]))
}