export const termSubPageNames = ["edit"] as const;
export type TermSubPageName = typeof termSubPageNames[number];

export function isTermSubPageName(value: unknown): value is TermSubPageName {
  return typeof value === "string" && termSubPageNames.some((v) => v === value);
}

export function asTermSubPageNameOrNull(
  value: unknown,
): TermSubPageName | null {
  if (isTermSubPageName(value)) return value;
  return null;
}
