
import { computed, defineComponent, PropType } from "vue";
import StudentCustomColumnEditTable from "@/views/student/StudentCustomColumnEdit/StudentCustomColumnEditTable/StudentCustomColumnEditTable.vue";
import { LoadableData } from "@/ts/app/loadable-data";
import { hasValue } from "@/ts/utils/common-util";
import {
  StudentCustomColumnId,
  StudentCustomColumnNames,
} from "@/ts/objects/value/student-custom-column-names";
import StudentCustomColumnEditHeader from "@/views/student/StudentCustomColumnEdit/StudentCustomColumnEditHeader/StudentCustomColumnEditHeader.vue";
import log from "loglevel";

export default defineComponent({
  name: "StudentCustomColumnEdit",
  components: { StudentCustomColumnEditHeader, StudentCustomColumnEditTable },
  props: {
    isAdmin: { type: Boolean, required: true },

    customColumnNames: {
      type: Object as PropType<LoadableData<StudentCustomColumnNames>>,
      required: true,
    },
    unsavedData: {
      type: Object as PropType<StudentCustomColumnNames>,
      required: false,
    },

    onInput: {
      type: Function as PropType<
        (id: StudentCustomColumnId, value: string) => void
      >,
      required: true,
    },

    onClickBack: { type: Function as PropType<() => void>, required: true },
    /**
     * 編集を開始する。
     */
    startEditing: {
      type: Function as PropType<() => void>,
      required: true,
    },
    /**
     * 編集をキャンセルする。
     */
    cancelEditing: {
      type: Function as PropType<() => void>,
      required: true,
    },
    /**
     * セーブし、編集を終了する。
     */
    save: { type: Function as PropType<() => Promise<void>>, required: true },
  },
  setup(props) {
    const editing = computed(() => hasValue(props.unsavedData));

    const tableModel = computed<StudentCustomColumnNames | null>(() => {
      const _unsavedData = props.unsavedData;
      const _customColumnNames = props.customColumnNames;

      if (hasValue(_unsavedData)) {
        log.debug(`tableModel: _unsavedData=${JSON.stringify(_unsavedData)}`);
        return _unsavedData;
      }

      if (_customColumnNames.hasData) {
        log.debug(
          `tableModel: _customColumnNames.data=${JSON.stringify(
            _customColumnNames.data,
          )}`,
        );
        return _customColumnNames.data;
      }

      log.debug(`tableModel: null`);
      return null;
    });

    return {
      editing,
      tableModel,
    };
  },
});
