import { Student, StudentHealth } from "@/ts/objects/entity/student";
import { StringType } from "@/ts/app/columns/def/column-types";
import { StudentColumnDef } from "@/ts/app/columns/def/student/student-column";
import { genUserColumnDefs } from "@/ts/app/columns/def/user-column-def";
import {
  UserColumnDefGroup,
  UserColumnGroup,
} from "@/ts/app/columns/def/user-column-group";
import { UserColumn } from "@/ts/app/columns/def/user-column";
import { names } from "@/ts/app/object-name";

export const studentHealthColumnGroupName = names.health.i;
export type StudentHealthColumnGroupName = typeof studentHealthColumnGroupName;

export type StudentHealthColumnId = keyof StudentHealth;

export type StudentHealthColumnDef<T> = StudentColumnDef<
  StudentHealthColumnId,
  T
>;

export type StudentHealthColumn<T> = UserColumn<
  StudentHealthColumnId,
  Student,
  T
>;

export type StudentHealthColumnDefGroup = UserColumnDefGroup<
  StudentHealthColumnGroupName,
  StudentHealthColumnId,
  Student
>;

export type StudentHealthColumnGroup = UserColumnGroup<
  StudentHealthColumnGroupName,
  StudentHealthColumnId,
  Student
>;

export const studentHealthColumnDefs: StudentHealthColumnDef<any>[] =
  genUserColumnDefs<StudentHealthColumnId, Student, any>([
    {
      id: "bloodType",
      width: 50,
      type: StringType,
      getValue: (s) => s.bloodType,
      setValue: (s, v) => ({ ...s, bloodType: v }),
    },
    {
      id: "normalBodyTemperature",
      width: 86,
      type: StringType,
      getValue: (s) => s.normalBodyTemperature,
      setValue: (s, v) => ({ ...s, normalBodyTemperature: v }),
    },
    {
      id: "inoculation",
      width: 120,
      type: StringType,
      getValue: (s) => s.inoculation,
      setValue: (s, v) => ({ ...s, inoculation: v }),
    },
    {
      id: "medicalHistory",
      width: 120,
      type: StringType,
      getValue: (s) => s.medicalHistory,
      setValue: (s, v) => ({ ...s, medicalHistory: v }),
    },
    {
      id: "homeDoctor",
      width: 120,
      type: StringType,
      getValue: (s) => s.homeDoctor,
      setValue: (s, v) => ({ ...s, homeDoctor: v }),
    },
    {
      id: "foodAllergy",
      width: 120,
      type: StringType,
      getValue: (s) => s.foodAllergy,
      setValue: (s, v) => ({ ...s, foodAllergy: v }),
    },
    {
      id: "anaphylaxis",
      width: 120,
      type: StringType,
      getValue: (s) => s.anaphylaxis,
      setValue: (s, v) => ({ ...s, anaphylaxis: v }),
    },
    {
      id: "otherAllergy",
      width: 120,
      type: StringType,
      getValue: (s) => s.otherAllergy,
      setValue: (s, v) => ({ ...s, otherAllergy: v }),
    },
    {
      id: "asthma",
      width: 120,
      type: StringType,
      getValue: (s) => s.asthma,
      setValue: (s, v) => ({ ...s, asthma: v }),
    },
  ]);
