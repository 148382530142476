
import { defineComponent, PropType } from "vue";
import { ColorLabel, labelToColor } from "@/ts/objects/value/color-label";
import { isNullish } from "@/ts/utils/common-util";
import { useSelectOnFocus } from "@/composables/use-select-on-focus";

export default defineComponent({
  name: "EditableItemNumber",
  props: {
    value: { type: Number, required: false },
    /**
     * 無効な値を入力したときなどに代わりに入るデフォルト値。
     */
    defaultValue: { type: Number, default: null, required: false },
    /**
     * 編集可能状態であればtrue。
     */
    editable: { type: Boolean, required: true },

    paddingTop: { type: Number, default: 4, required: false },
    paddingRight: { type: Number, default: 4, required: false },
    paddingBottom: { type: Number, default: 4, required: false },
    paddingLeft: { type: Number, default: 4, required: false },
    fontSize: { type: Number, default: 14, required: false },
    fontColor: {
      type: String as PropType<ColorLabel>,
      default: "black",
      required: false,
    },

    /**
     * trueにした場合、
     * editableがfalseの間、入力ボックスを消して、中身の値だけを普通のテキストのように表示する。
     */
    hideBoxOnDisable: { type: Boolean, default: true },

    onInput: {
      type: Function as PropType<(value: number | null) => void>,
      required: true,
    },
    onBlur: { type: Function as PropType<() => void>, required: false },
    /**
     * input内でエンターキーを押したときに呼び出される。
     */
    onEnter: { type: Function as PropType<() => void>, required: false },

    selectOnFocus: { type: Boolean, default: false, required: false },
  },
  setup(props) {
    const onInputInternal = (e: Event) => {
      if (!props.editable) return;

      const value = (e.target as HTMLInputElement).value;
      if (isNullish(value) || value === "") {
        props.onInput(props.defaultValue);
        return;
      }

      const numberValue = parseInt(value, 10);
      if (isNaN(numberValue)) {
        props.onInput(props.defaultValue);
        return;
      }
      props.onInput(numberValue);
    };

    const onFocus = props.selectOnFocus ? useSelectOnFocus() : undefined;

    return {
      onInputInternal,
      onFocus,

      styles: {
        "--padding-top": `${props.paddingTop}px`,
        "--padding-right": `${props.paddingRight}px`,
        "--padding-bottom": `${props.paddingBottom}px`,
        "--padding-left": `${props.paddingLeft}px`,
        "--font-size": `${props.fontSize}px`,
        "--color": labelToColor(props.fontColor),
      },
    };
  },
});
