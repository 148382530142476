import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Teleport as _Teleport, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3cf4ca5e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["aria-label"]
const _hoisted_2 = { class: "text" }
const _hoisted_3 = { class: "wrap" }
const _hoisted_4 = {
  class: "items",
  "aria-label": "Classes"
}
const _hoisted_5 = { class: "buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TeacherClassesSelectPopupItem = _resolveComponent("TeacherClassesSelectPopupItem")!
  const _component_AddButton = _resolveComponent("AddButton")!
  const _component_PopupConfirmButton = _resolveComponent("PopupConfirmButton")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _withDirectives((_openBlock(), _createElementBlock("div", {
      class: "teacher-classes-select-popup-container",
      role: "dialog",
      "aria-label": _ctx.text,
      style: _normalizeStyle(_ctx.styles)
    }, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.text), 1),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("ul", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, idx) => {
            return (_openBlock(), _createBlock(_component_TeacherClassesSelectPopupItem, {
              class: "item",
              key: idx,
              width: _ctx.itemWidth,
              height: 44,
              "school-year": item.schoolYear,
              "school-type": item.schoolType,
              "grade-number": item.gradeNumber,
              "class-id": _ctx.toClassId(item.cls),
              "in-charge-type": item.inChargeType,
              "get-classes": _ctx.getClasses,
              "on-change": 
              (schoolYear, schoolType, gradeNumber, cls, inChargeType) =>
                _ctx.onChangeItem(
                  idx,
                  schoolYear,
                  schoolType,
                  gradeNumber,
                  cls,
                  inChargeType,
                )
            ,
              "on-remove": () => _ctx.onClickRemoveItem(idx)
            }, null, 8, ["width", "school-year", "school-type", "grade-number", "class-id", "in-charge-type", "get-classes", "on-change", "on-remove"]))
          }), 128))
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_AddButton, {
          class: "add-button",
          "on-click": _ctx.onClickAddItem
        }, null, 8, ["on-click"]),
        _createVNode(_component_PopupConfirmButton, {
          class: "confirm-button",
          width: 90,
          height: 26,
          label: "OK",
          enabled: _ctx.canConfirm,
          "on-click": _ctx.onConfirmInternal
        }, null, 8, ["enabled", "on-click"])
      ])
    ], 12, _hoisted_1)), [
      [_directive_click_outside, _ctx.onClickOutside]
    ])
  ]))
}