import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createBlock(_component_BaseButton, {
    label: "Add Term",
    color: "black",
    "font-size": 13,
    "background-color": "white",
    icon: "plus",
    "icon-size": 12,
    "icon-padding-bottom": 1,
    "border-color": "light-gray",
    width: 103,
    height: 26,
    "padding-horizontal": 12,
    enabled: _ctx.enabled,
    "on-click": _ctx.onClick
  }, null, 8, ["enabled", "on-click"]))
}