import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-72d3353a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["aria-label"]
const _hoisted_2 = { class: "name-cell" }
const _hoisted_3 = { class: "value-cell" }
const _hoisted_4 = {
  key: 10,
  class: "editable-item"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EditableItemText = _resolveComponent("EditableItemText")!
  const _component_EditableItemNumber = _resolveComponent("EditableItemNumber")!
  const _component_EditableItemDate = _resolveComponent("EditableItemDate")!
  const _component_EditableItemImage = _resolveComponent("EditableItemImage")!
  const _component_EditableItemStudentClasses = _resolveComponent("EditableItemStudentClasses")!
  const _component_EditableItemTeacherClasses = _resolveComponent("EditableItemTeacherClasses")!
  const _component_EditableItemEnum = _resolveComponent("EditableItemEnum")!
  const _component_EditableItemUser = _resolveComponent("EditableItemUser")!
  const _component_UserEditTableItemReadonlyUsers = _resolveComponent("UserEditTableItemReadonlyUsers")!

  return (_openBlock(), _createElementBlock("div", {
    class: "user-edit-table-item-container",
    style: _normalizeStyle(_ctx.styles),
    role: "cell",
    "aria-label": _ctx.userColumn.name
  }, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.userColumn.name), 1),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.userColumn.type.category === 'string')
        ? (_openBlock(), _createBlock(_component_EditableItemText, {
            key: 0,
            class: "editable-item",
            value: _ctx.userColumn.value,
            editable: _ctx.editing,
            type: "input",
            "font-size": _ctx.valueFontSize,
            "font-color": "black",
            "max-length": _ctx.userColumn.maxLength,
            "on-input": _ctx.onInput
          }, null, 8, ["value", "editable", "font-size", "max-length", "on-input"]))
        : (_ctx.userColumn.type.category === 'multi line string')
          ? (_openBlock(), _createBlock(_component_EditableItemText, {
              key: 1,
              class: "editable-item",
              value: _ctx.userColumn.value,
              editable: _ctx.editing,
              type: "textarea",
              "font-size": _ctx.valueFontSize,
              "font-color": "black",
              "max-length": _ctx.userColumn.maxLength,
              "on-input": _ctx.onInput
            }, null, 8, ["value", "editable", "font-size", "max-length", "on-input"]))
          : (_ctx.userColumn.type.category === 'number')
            ? (_openBlock(), _createBlock(_component_EditableItemNumber, {
                key: 2,
                class: "editable-item",
                value: _ctx.userColumn.value,
                editable: _ctx.editing,
                "font-size": _ctx.valueFontSize,
                "font-color": "black",
                "on-input": _ctx.onInput
              }, null, 8, ["value", "editable", "font-size", "on-input"]))
            : (_ctx.userColumn.type.category === 'date')
              ? (_openBlock(), _createBlock(_component_EditableItemDate, {
                  key: 3,
                  class: "editable-item",
                  value: _ctx.userColumn.value,
                  editable: _ctx.editing,
                  clearable: true,
                  "font-size": _ctx.valueFontSize,
                  "font-color": "black",
                  "align-on-readonly": "flex-start",
                  "on-input": _ctx.onInput
                }, null, 8, ["value", "editable", "font-size", "on-input"]))
              : (_ctx.userColumn.type.category === 'image')
                ? (_openBlock(), _createBlock(_component_EditableItemImage, {
                    key: 4,
                    class: "editable-item editable-item-image",
                    "gcs-url": _ctx.userColumn.value,
                    editable: _ctx.editing,
                    "open-on-click": true,
                    "accepted-image-types": ['jpeg', 'png'],
                    "on-input": _ctx.onInput
                  }, null, 8, ["gcs-url", "editable", "on-input"]))
                : (_ctx.userColumn.type.category === 'student-classes')
                  ? (_openBlock(), _createBlock(_component_EditableItemStudentClasses, {
                      key: 5,
                      class: "editable-item",
                      value: _ctx.userColumn.value,
                      editable: _ctx.editing,
                      "font-size": _ctx.valueFontSize,
                      "font-color": "black",
                      "on-input": _ctx.onInput,
                      "is-link-on-disable": true
                    }, null, 8, ["value", "editable", "font-size", "on-input"]))
                  : (_ctx.userColumn.type.category === 'teacher-classes')
                    ? (_openBlock(), _createBlock(_component_EditableItemTeacherClasses, {
                        key: 6,
                        class: "editable-item",
                        value: _ctx.userColumn.value,
                        editable: _ctx.editing,
                        "font-size": _ctx.valueFontSize,
                        "font-color": "black",
                        "on-input": _ctx.onInput,
                        "is-link-on-disable": true
                      }, null, 8, ["value", "editable", "font-size", "on-input"]))
                    : (_ctx.userColumn.type.category === 'enum')
                      ? (_openBlock(), _createBlock(_component_EditableItemEnum, {
                          key: 7,
                          class: "editable-item",
                          value: _ctx.userColumn.value,
                          options: _ctx.enumOptions,
                          editable: _ctx.editing,
                          "font-size": _ctx.valueFontSize,
                          "font-color": "black",
                          "on-input": _ctx.onInput
                        }, null, 8, ["value", "options", "editable", "font-size", "on-input"]))
                      : (_ctx.userColumn.type.category === 'user')
                        ? (_openBlock(), _createBlock(_component_EditableItemUser, {
                            key: 8,
                            class: "editable-item",
                            value: _ctx.userColumn.value,
                            editable: _ctx.editing,
                            "font-size": _ctx.valueFontSize,
                            "font-color": "black",
                            "font-color-light": "gray",
                            "prompt-text": `${_ctx.userColumn.name}を選択してください。`,
                            "user-type": _ctx.userColumn.type.userType,
                            "is-link-on-disable": true,
                            "on-input": _ctx.onInput
                          }, null, 8, ["value", "editable", "font-size", "prompt-text", "user-type", "on-input"]))
                        : (_ctx.userColumn.type.category === 'users')
                          ? (_openBlock(), _createBlock(_component_UserEditTableItemReadonlyUsers, {
                              key: 9,
                              class: "editable-item",
                              value: _ctx.userColumn.value,
                              editing: _ctx.editing
                            }, null, 8, ["value", "editing"]))
                          : (_openBlock(), _createElementBlock("div", _hoisted_4, "Unsupported type."))
    ])
  ], 12, _hoisted_1))
}