export const studentSubPageNames = ["list", "edit"] as const;
export type StudentSubPageName = typeof studentSubPageNames[number];

export function isStudentSubPageName(
  value: unknown,
): value is StudentSubPageName {
  return (
    typeof value === "string" && studentSubPageNames.some((v) => v === value)
  );
}

export function asStudentSubPageNameOrNull(
  value: unknown,
): StudentSubPageName | null {
  if (isStudentSubPageName(value)) return value;
  return null;
}
