
import { defineComponent } from "vue";
import { useCopyToClipboard } from "@/composables/use-copy-to-clipboard";

export default defineComponent({
  name: "SimpleTextItem0",
  props: {
    name: { type: String, required: true },
    value: { type: String, required: true },
    copyable: { type: Boolean, required: true },
  },
  setup(props) {
    const { copyToClipboard } = useCopyToClipboard();

    return {
      copyToClipboard: () => {
        if (!props.copyable) return;
        copyToClipboard(props.value);
      },
    };
  },
});
