import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-30d3c15b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "guardian-list-container" }
const _hoisted_2 = { class: "main" }
const _hoisted_3 = { class: "main-header" }
const _hoisted_4 = { class: "main-header-upper" }
const _hoisted_5 = { class: "buttons" }
const _hoisted_6 = { class: "main-header-lower" }
const _hoisted_7 = { class: "main-body" }
const _hoisted_8 = { class: "search-condition-area" }
const _hoisted_9 = {
  key: 1,
  class: "table-wrap"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GuardianPageHeading = _resolveComponent("GuardianPageHeading")!
  const _component_ImportButton = _resolveComponent("ImportButton")!
  const _component_ExportDropdown = _resolveComponent("ExportDropdown")!
  const _component_MenuDropdown = _resolveComponent("MenuDropdown")!
  const _component_BaseSearchBox = _resolveComponent("BaseSearchBox")!
  const _component_SearchConditionPopupSchoolYear = _resolveComponent("SearchConditionPopupSchoolYear")!
  const _component_SearchConditionDropdown = _resolveComponent("SearchConditionDropdown")!
  const _component_SearchConditionPopupClass = _resolveComponent("SearchConditionPopupClass")!
  const _component_SearchConditionPopupInSchoolState = _resolveComponent("SearchConditionPopupInSchoolState")!
  const _component_SearchConditionPopupUserSort = _resolveComponent("SearchConditionPopupUserSort")!
  const _component_SearchConditionPopupNumResults = _resolveComponent("SearchConditionPopupNumResults")!
  const _component_SearchResultNumbers = _resolveComponent("SearchResultNumbers")!
  const _component_GuardianListTable = _resolveComponent("GuardianListTable")!
  const _component_Pager = _resolveComponent("Pager")!
  const _component_GuardianColumnsSelect = _resolveComponent("GuardianColumnsSelect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_GuardianPageHeading, { class: "page-heading" }),
          _createElementVNode("div", _hoisted_5, [
            (_ctx.isAdmin)
              ? (_openBlock(), _createBlock(_component_ImportButton, {
                  key: 0,
                  class: "import-button",
                  to: "/guardian/import"
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_ExportDropdown, {
              class: "export-button",
              "on-select": _ctx.onClickExportButton
            }, null, 8, ["on-select"]),
            _createVNode(_component_MenuDropdown, {
              class: "extra-menu-button",
              items: _ctx.extraMenuItems,
              enabled: true,
              "options-width": 200,
              "options-align": "right",
              "on-select": _ctx.onSelectExtraMenuItem
            }, null, 8, ["items", "on-select"])
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_BaseSearchBox, {
            class: "search-box",
            "initial-text": _ctx.searchCondition.queryText,
            placeholder: "名前・Google Mail・ユーザーIDで前方一致検索",
            width: 410,
            height: 30,
            "on-input": _ctx.onInputQueryText,
            "on-confirm": _ctx.onConfirmQueryText
          }, null, 8, ["initial-text", "on-input", "on-confirm"])
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_SearchConditionDropdown, {
            class: "search-condition-dropdown",
            "data-testid": "search-condition-dropdown-school-year",
            "button-label": `${_ctx.schoolYear}年度`,
            "button-min-width": 80,
            active: _ctx.isSchoolYearPopupActive,
            "change-activeness": _ctx.changeSchoolYearPopupActiveness
          }, {
            default: _withCtx(() => [
              _createVNode(_component_SearchConditionPopupSchoolYear, {
                "school-year": _ctx.schoolYear,
                cancel: () => _ctx.changeSchoolYearPopupActiveness(false),
                confirm: _ctx.onConfirmConditionSchoolYear
              }, null, 8, ["school-year", "cancel", "confirm"])
            ]),
            _: 1
          }, 8, ["button-label", "active", "change-activeness"]),
          _createVNode(_component_SearchConditionDropdown, {
            class: "search-condition-dropdown",
            "data-testid": "search-condition-dropdown-class",
            "button-label": _ctx.classDisplayValue,
            "button-min-width": 80,
            active: _ctx.isClassPopupActive,
            "change-activeness": _ctx.changeClassPopupActiveness
          }, {
            default: _withCtx(() => [
              _createVNode(_component_SearchConditionPopupClass, {
                "school-year": _ctx.schoolYear,
                "all-classes": _ctx.allClasses,
                "search-condition": _ctx.searchCondition.class,
                cancel: () => _ctx.changeClassPopupActiveness(false),
                confirm: _ctx.onConfirmConditionClass
              }, null, 8, ["school-year", "all-classes", "search-condition", "cancel", "confirm"])
            ]),
            _: 1
          }, 8, ["button-label", "active", "change-activeness"]),
          _createVNode(_component_SearchConditionDropdown, {
            class: "search-condition-dropdown",
            "data-testid": "search-condition-dropdown-in-school-state",
            "button-label": _ctx.inSchoolStateDisplayValue,
            "button-min-width": 100,
            active: _ctx.isInSchoolStatePopupActive,
            "change-activeness": _ctx.changeInSchoolStatePopupActiveness
          }, {
            default: _withCtx(() => [
              _createVNode(_component_SearchConditionPopupInSchoolState, {
                "search-condition": _ctx.searchCondition.inSchoolState,
                cancel: () => _ctx.changeInSchoolStatePopupActiveness(false),
                confirm: _ctx.onConfirmConditionInSchoolState
              }, null, 8, ["search-condition", "cancel", "confirm"])
            ]),
            _: 1
          }, 8, ["button-label", "active", "change-activeness"]),
          _createVNode(_component_SearchConditionDropdown, {
            class: "search-condition-dropdown",
            "data-testid": "search-condition-dropdown-user-sort",
            "button-label": _ctx.sortDisplayValue,
            "button-min-width": 125,
            active: _ctx.isSortPopupActive,
            "change-activeness": _ctx.changeSortPopupActiveness
          }, {
            default: _withCtx(() => [
              _createVNode(_component_SearchConditionPopupUserSort, {
                "search-condition": _ctx.searchCondition.sort,
                cancel: () => _ctx.changeSortPopupActiveness(false),
                confirm: _ctx.onConfirmConditionSort
              }, null, 8, ["search-condition", "cancel", "confirm"])
            ]),
            _: 1
          }, 8, ["button-label", "active", "change-activeness"]),
          _createVNode(_component_SearchConditionDropdown, {
            class: "search-condition-dropdown",
            "data-testid": "search-condition-dropdown-num-results",
            "button-label": _ctx.numResultsDisplayValue,
            "button-min-width": 115,
            active: _ctx.isNumResultsPopupActive,
            "change-activeness": _ctx.changeNumResultsPopupActiveness
          }, {
            default: _withCtx(() => [
              _createVNode(_component_SearchConditionPopupNumResults, {
                "search-condition": _ctx.searchCondition.numResults,
                cancel: () => _ctx.changeNumResultsPopupActiveness(false),
                confirm: _ctx.onConfirmConditionNumResults
              }, null, 8, ["search-condition", "cancel", "confirm"])
            ]),
            _: 1
          }, 8, ["button-label", "active", "change-activeness"])
        ]),
        (_ctx.numResults != null)
          ? (_openBlock(), _createBlock(_component_SearchResultNumbers, {
              key: 0,
              class: "search-result-numbers",
              "page-number": _ctx.pageNumber,
              "num-results": _ctx.numResults
            }, null, 8, ["page-number", "num-results"]))
          : _createCommentVNode("", true),
        (_ctx.searchResult != null)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createVNode(_component_GuardianListTable, {
                class: "table",
                guardians: _ctx.searchResult.data,
                "column-visibility": _ctx.columnVisibility,
                "user-id-to-edit-path": _ctx.userIdToEditPath,
                "link-enabled": true
              }, null, 8, ["guardians", "column-visibility", "user-id-to-edit-path"]),
              _createVNode(_component_Pager, {
                class: "pager",
                "active-page": _ctx.pageNumber,
                "num-all-pages": _ctx.numAllPages,
                "move-page-to": _ctx.movePageTo
              }, null, 8, ["active-page", "num-all-pages", "move-page-to"])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createVNode(_component_GuardianColumnsSelect, {
      class: "columns-select",
      "column-visibility": _ctx.columnVisibility,
      open: _ctx.isColumnSelectOpen,
      "on-click-collapse-button": _ctx.toggleColumnSelectVisibility,
      "on-change-column-visibility": _ctx.onChangeColumnVisibility
    }, null, 8, ["column-visibility", "open", "on-click-collapse-button", "on-change-column-visibility"])
  ]))
}