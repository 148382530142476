import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0b23c69a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "class-details-container" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "body" }
const _hoisted_4 = { class: "body-header-upper" }
const _hoisted_5 = { class: "body-header-lower" }
const _hoisted_6 = {
  key: 1,
  class: "class-id"
}
const _hoisted_7 = {
  key: 0,
  class: "tables"
}
const _hoisted_8 = {
  key: 1,
  class: "tables"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ClassSubPageHeading = _resolveComponent("ClassSubPageHeading")!
  const _component_EditButtonSet = _resolveComponent("EditButtonSet")!
  const _component_SchoolYearSwitch = _resolveComponent("SchoolYearSwitch")!
  const _component_DeleteButton = _resolveComponent("DeleteButton")!
  const _component_ClassDetailsTitle = _resolveComponent("ClassDetailsTitle")!
  const _component_SimpleTextItem0 = _resolveComponent("SimpleTextItem0")!
  const _component_ClassDetailsTable = _resolveComponent("ClassDetailsTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ClassSubPageHeading, {
        class: "page-heading",
        "sub-label": "Class Details",
        "main-page-heading-link-path": "/class",
        "on-click-back": _ctx.onClickBack
      }, null, 8, ["on-click-back"]),
      (_ctx.isAdmin)
        ? (_openBlock(), _createBlock(_component_EditButtonSet, {
            key: 0,
            class: "edit-button-set",
            editing: _ctx.editState.editing,
            "on-click-edit": _ctx.startEditing,
            "on-click-save": _ctx.save,
            "on-click-cancel": _ctx.cancelEditing
          }, null, 8, ["editing", "on-click-edit", "on-click-save", "on-click-cancel"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        (_ctx.cls.hasData)
          ? (_openBlock(), _createBlock(_component_SchoolYearSwitch, {
              key: 0,
              class: "school-year-switch",
              "school-year": _ctx.cls.data.schoolYear,
              enabled: false,
              "on-change": () => {}
            }, null, 8, ["school-year", "on-change"]))
          : _createCommentVNode("", true),
        (_ctx.cls.hasData && _ctx.editState.editing)
          ? (_openBlock(), _createBlock(_component_DeleteButton, {
              key: 1,
              class: "delete-class-button",
              enabled: _ctx.cls.hasData && _ctx.editState.editing,
              "on-click": _ctx.onClickDelete
            }, null, 8, ["enabled", "on-click"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_5, [
        (_ctx.cls.hasData)
          ? (_openBlock(), _createBlock(_component_ClassDetailsTitle, {
              key: 0,
              class: "class-title",
              "school-type": _ctx.cls.data.grade.schoolType,
              "grade-number": _ctx.cls.data.grade.gradeNumber,
              name: _ctx.cls.data.name
            }, null, 8, ["school-type", "grade-number", "name"]))
          : _createCommentVNode("", true),
        (_ctx.cls.hasData)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createVNode(_component_SimpleTextItem0, {
                class: "item",
                name: _ctx.classIdDisplayName,
                value: _ctx.cls.data.classId,
                copyable: true
              }, null, 8, ["name", "value"])
            ]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.editState.editing)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createVNode(_component_ClassDetailsTable, {
              class: "table teacher-table",
              "aria-label": "Teachers",
              "title-icon": "user-graduate",
              title: _ctx.teacherDisplayName,
              data: _ctx.editState.data.teacher,
              editing: true,
              "on-update": _ctx.onUpdateInternal
            }, null, 8, ["title", "data", "on-update"]),
            _createVNode(_component_ClassDetailsTable, {
              class: "table student-table",
              "aria-label": "Students",
              "title-icon": "child",
              title: _ctx.studentDisplayName,
              data: _ctx.editState.data.student,
              editing: true,
              "on-update": _ctx.onUpdateInternal
            }, null, 8, ["title", "data", "on-update"])
          ]))
        : (_ctx.data.hasData)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createVNode(_component_ClassDetailsTable, {
                class: "table teacher-table",
                "title-icon": "user-graduate",
                title: _ctx.teacherDisplayName,
                data: _ctx.data.data.teacher,
                editing: false,
                "on-update": _ctx.onUpdateInternal
              }, null, 8, ["title", "data", "on-update"]),
              _createVNode(_component_ClassDetailsTable, {
                class: "table student-table",
                "title-icon": "child",
                title: _ctx.studentDisplayName,
                data: _ctx.data.data.student,
                editing: false,
                "on-update": _ctx.onUpdateInternal
              }, null, 8, ["title", "data", "on-update"])
            ]))
          : _createCommentVNode("", true)
    ])
  ]))
}