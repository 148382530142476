import { isNullish } from "@/ts/utils/common-util";
import { Result } from "@/ts/app/result";
import { errors, ThrowableAppError } from "@/ts/app/error/app-error";
import { names, ObjectName } from "@/ts/app/object-name";

export const sexes = {
  male: "男",
  female: "女",
  other: "その他",
} as const;

/**
 * 性別。
 */
export type Sex = keyof typeof sexes;
export type SexDisplayValue = typeof sexes[Sex];

export function isSex(value: unknown): value is Sex {
  return (
    typeof value === "string" && Object.keys(sexes).some((v) => v === value)
  );
}

export function sexToDisplayValue(value: Sex): SexDisplayValue {
  return sexes[value];
}

export function displayValueToSex(
  displayValue: string,
  objectName: ObjectName = names.sex,
): Result<Sex> {
  const error = {
    ok: false,
    error: errors.validationFailed.invalidValue(displayValue, objectName),
  } as const;

  const kv = Object.entries(sexes).find(([_k, v]) => v === displayValue);
  if (isNullish(kv)) return error;
  const k = kv[0];
  if (!isSex(k)) return error;
  return { ok: true, data: k };
}

export function displayValueToSexOrNull(displayValue: string): Sex | null {
  const result = displayValueToSex(displayValue);
  if (!result.ok) return null;
  return result.data;
}

export function displayValueToSexOrError(
  displayValue: string,
  objectName: ObjectName = names.sex,
): Sex {
  const result = displayValueToSex(displayValue, objectName);
  if (!result.ok) throw new ThrowableAppError(result.error);
  return result.data;
}

export function sexFromString(value: string): Result<Sex> {
  if (isSex(value)) {
    return { ok: true, data: value };
  }
  return {
    ok: false,
    error: errors.validationFailed.invalidValue(value, names.sex),
  };
}

export function sexFromStringOrError(value: string): Sex {
  const result = sexFromString(value);
  if (result.ok) {
    return result.data;
  } else {
    throw new ThrowableAppError(result.error);
  }
}
