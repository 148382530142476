
import { computed, defineComponent, PropType } from "vue";
import { Student } from "@/ts/objects/entity/student";
import UserListTableRow from "@/components/table/list/UserListTableRow/UserListTableRow.vue";
import { getStudentColumns } from "@/ts/app/columns/def/student/student-column";
import { isNullish } from "@/ts/utils/common-util";

export default defineComponent({
  name: "StudentListTableRow",
  components: { UserListTableRow },
  props: {
    horizontalMargin: { type: Number, required: true },
    iconCellWidth: { type: Number, required: true },
    nameCellWidth: { type: Number, required: true },
    minHeight: { type: Number, required: true },
    maxHeight: { type: Number, required: true },

    styleType: { type: Number as PropType<0 | 1>, required: true },

    schoolYear: { type: Number, required: true },
    student: { type: Object as PropType<Student>, required: true },
    columnVisibility: {
      type: Object as PropType<Record<string, boolean>>,
      required: true,
    },

    /**
     * ユーザーIDから、編集画面のパスを取得する。
     * 未指定の場合、編集画面へのリンクが現れない。
     */
    userIdToEditPath: {
      type: Function as PropType<(userId: string) => string>,
      required: false,
    },

    /**
     * trueなら、ユーザー項目などをリンク化する。
     */
    linkEnabled: { type: Boolean, required: true },
  },
  setup(props) {
    const columns = getStudentColumns(props.schoolYear, props.student);
    const editPath = computed<string | null>(() => {
      const userIdToEditPath = props.userIdToEditPath;
      if (isNullish(userIdToEditPath)) {
        return null;
      }
      return userIdToEditPath(props.student.userId);
    });

    return {
      columns,
      editPath,
    };
  },
});
