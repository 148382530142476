
import { computed, defineComponent, PropType } from "vue";
import { ColorLabel, labelToColor } from "@/ts/objects/value/color-label";

/**
 * 開くのが左（<）で閉じるのが右（>）であるような開閉ボタン。
 */
export default defineComponent({
  name: "CollapseButtonLR",
  props: {
    color: { type: String as PropType<ColorLabel>, default: "black" },
    size: { type: Number, default: 18 },

    open: { type: Boolean, required: true },
    onClick: { type: Function as PropType<() => void>, required: true },
  },
  setup(props) {
    return {
      styles: computed(() => ({
        "--color": labelToColor(props.color),
        "--size": `${props.size}px`,
      })),
    };
  },
});
