import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-70b34184"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main-container" }
const _hoisted_2 = { class: "body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppHeader = _resolveComponent("AppHeader")!
  const _component_MainMenu = _resolveComponent("MainMenu")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AppHeader, {
      class: "header",
      "school-logo-gcs-url": _ctx.schoolLogoGcsUrl,
      "school-name": _ctx.schoolName,
      "user-photo-url": _ctx.userPhotoUrl
    }, null, 8, ["school-logo-gcs-url", "school-name", "user-photo-url"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_MainMenu, {
        class: "main-menu",
        pages: _ctx.pages,
        "active-page-name": _ctx.pageName
      }, null, 8, ["pages", "active-page-name"]),
      _createVNode(_component_router_view, { class: "main-view" })
    ])
  ]))
}