
import { defineComponent, PropType } from "vue";
import SubPageHeading from "@/components/other/SubPageHeading/SubPageHeading.vue";

export default defineComponent({
  name: "GuardianSubPageHeading",
  components: { SubPageHeading },
  props: {
    subLabel: { type: String, required: true },

    mainPageHeadingLinkPath: { type: String, required: true },
    onClickBack: { type: Function as PropType<() => void>, required: true },
  },
});
