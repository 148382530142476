import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-155f3ac2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "student-edit-table-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserEditTableTabs = _resolveComponent("UserEditTableTabs")!
  const _component_UserEditTableBody = _resolveComponent("UserEditTableBody")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_UserEditTableTabs, {
      class: "tabs",
      "column-group-names": _ctx.columnGroupNames,
      "selected-tab": _ctx.selectedTab,
      "on-select-tab": _ctx.onSelectTab
    }, null, 8, ["column-group-names", "selected-tab", "on-select-tab"]),
    (_ctx.selectedGroupColumns != null)
      ? (_openBlock(), _createBlock(_component_UserEditTableBody, {
          key: 0,
          class: "table-body",
          "user-columns": _ctx.selectedGroupColumns,
          editing: _ctx.editing,
          "on-input": _ctx.onInput
        }, null, 8, ["user-columns", "editing", "on-input"]))
      : _createCommentVNode("", true)
  ]))
}