import { Teacher } from "@/ts/objects/entity/teacher";
import { Student } from "@/ts/objects/entity/student";
import { isNullish } from "@/ts/utils/common-util";
import { User } from "@/ts/objects/entity/user";

export type ClassDetailsEditState =
  | {
      readonly editing: false;
    }
  | {
      readonly editing: true;
      readonly data: ClassDetailsData;
    };

export type ClassDetailsData = {
  teacher: ClassDetailsTableDataTeacher;
  student: ClassDetailsTableDataStudent;
};

export function classDetailsDataFromUsers(
  teachers: Teacher[],
  students: Student[],
  classId: string,
): ClassDetailsData {
  return {
    teacher: {
      userType: "teacher",
      users: teachers.map((t) => classDetailsRowDataFromTeacher(t, classId)),
    },
    student: {
      userType: "student",
      users: students.map((s) => classDetailsRowDataFromStudent(s, classId)),
    },
  };
}

export type ClassDetailsTableData =
  | ClassDetailsTableDataTeacher
  | ClassDetailsTableDataStudent;

export type ClassDetailsTableDataTeacher = {
  readonly userType: "teacher";
  readonly users: ClassDetailsRowDataTeacher[];
};

export type ClassDetailsTableDataStudent = {
  readonly userType: "student";
  readonly users: ClassDetailsRowDataStudent[];
};

export type ClassDetailsRowData =
  | ClassDetailsRowDataTeacher
  | ClassDetailsRowDataStudent;

export type ClassDetailsRowDataTeacher = {
  readonly userType: "teacher";
  readonly userId: string;
  readonly googleMail: string;
  readonly name: string;
  readonly photoUrl: string;
  readonly inChargeType: string;
};

export type ClassDetailsRowDataStudent = {
  readonly userType: "student";
  readonly userId: string;
  readonly googleMail: string;
  readonly name: string;
  readonly photoUrl: string;
  readonly studentNumber: number | null;
};

export function classDetailsRowDataFromTeacher(
  user: Teacher,
  classId: string,
): ClassDetailsRowDataTeacher {
  const teacherClass = user.classes.find((c) => c.classId === classId);
  if (isNullish(teacherClass))
    throw new Error(
      `classDetailsRowDataFromTeacher: teacherClass for classId ${classId} not found`,
    );
  return {
    userType: "teacher",
    userId: user.userId,
    googleMail: user.googleMail,
    name: user.name,
    photoUrl: user.photoUrl,
    inChargeType: teacherClass.inChargeType,
  };
}

export function classDetailsRowDataFromStudent(
  user: Student,
  classId: string,
): ClassDetailsRowDataStudent {
  const studentClass = user.classes.find((c) => c.classId === classId);
  if (isNullish(studentClass))
    throw new Error(
      `classDetailsRowDataFromStudent: studentClass for classId ${classId} not found`,
    );
  return {
    userType: "student",
    userId: user.userId,
    googleMail: user.googleMail,
    name: user.name,
    photoUrl: user.photoUrl,
    studentNumber: studentClass.studentNumber,
  };
}

export function classDetailsRowDataFromTeacherBasicUserInfo(
  user: User,
): ClassDetailsRowDataTeacher {
  if (user.userType !== "teacher")
    throw new Error(
      `classDetailsRowDataFromTeacherBasicUserInfo: userType must be "teacher", but was "${user.userType}"`,
    );

  return {
    userType: "teacher",
    userId: user.userId,
    googleMail: user.googleMail,
    name: user.name,
    photoUrl: user.photoUrl,
    inChargeType: "",
  };
}

export function classDetailsRowDataFromStudentBasicUserInfo(
  user: User,
): ClassDetailsRowDataStudent {
  if (user.userType !== "student")
    throw new Error(
      `classDetailsRowDataFromTeacherBasicUserInfo: userType must be "student", but was "${user.userType}"`,
    );

  return {
    userType: "student",
    userId: user.userId,
    googleMail: user.googleMail,
    name: user.name,
    photoUrl: user.photoUrl,
    studentNumber: null,
  };
}
