import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5014602b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "inner-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SchoolYearSwitch = _resolveComponent("SchoolYearSwitch")!
  const _component_SearchConditionPopupFrame = _resolveComponent("SearchConditionPopupFrame")!

  return (_openBlock(), _createBlock(_component_SearchConditionPopupFrame, {
    class: "search-condition-popup-school-year-container",
    label: "年度",
    "confirm-button-label": "年度を適用",
    width: 140,
    "on-click-cancel": _ctx.cancel,
    "on-click-confirm": _ctx.confirmInternal
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_SchoolYearSwitch, {
          class: "school-year-switch",
          "school-year": _ctx.schoolYearInternal,
          enabled: true,
          "on-change": _ctx.onChange,
          "on-enter": _ctx.confirmInternal
        }, null, 8, ["school-year", "on-change", "on-enter"])
      ])
    ]),
    _: 1
  }, 8, ["on-click-cancel", "on-click-confirm"]))
}