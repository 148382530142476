
import { computed, defineComponent, PropType } from "vue";
import { BasicUserInfoPartial } from "@/ts/objects/value/basic-user-info";
import { isStringBlank } from "@/ts/utils/common-util";
import { userEditPagePathOrHome } from "@/router/path-constructors";

export default defineComponent({
  name: "UserListTableCellUsers",
  props: {
    users: { type: Array as PropType<BasicUserInfoPartial[]>, required: true },

    isLink: { type: Boolean, required: true },
  },
  setup(props) {
    const items = computed<Item[]>(() =>
      props.users.map((u) => ({
        userId: u.userId,
        displayValue: (isStringBlank(u.name) ? u.googleMail : u.name) ?? "",
        linkPath: userEditPagePathOrHome(u.userType, u.userId),
      })),
    );

    return {
      items,
    };
  },
});

type Item = {
  readonly userId: string;
  readonly displayValue: string;
  readonly linkPath: string;
};
