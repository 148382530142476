import { DateValue } from "@/ts/objects/value/date-value";
import { computed, ComputedRef, ref, Ref } from "vue";
import { asPageNameOrNull, PageName } from "@/ts/app/pages/pages";
import {
  asGuardianSubPageNameOrNull,
  GuardianSubPageName,
} from "@/ts/app/pages/guardian-sub-pages";
import {
  SearchConditionClass,
  searchConditionClassFromQueryParams,
} from "@/ts/objects/search-condition/search-condition-class";
import {
  SearchConditionInSchoolState,
  searchConditionInSchoolStateFromQueryParams,
} from "@/ts/objects/search-condition/search-condition-in-school-state";
import {
  NumResultsCondition,
  numResultsConditionFromQueryParams,
} from "@/ts/objects/search-condition/num-results-condition";
import {
  UserSortCondition,
  userSortConditionFromQueryParams,
} from "@/ts/objects/search-condition/user-sort-condition";
import { useRoute } from "vue-router";
import { asIntOrNull, asTextOrNull } from "@/ts/utils/common-util";

export function useGuardianRoute(
  today: DateValue,
  currentSchoolYear: number,
): {
  pageName: ComputedRef<PageName | null>;
  subPageName: ComputedRef<GuardianSubPageName | null>;
  userId: ComputedRef<string | null>;
  searchPageNum: Ref<number>;
  queryText: Ref<string>;
  schoolYear: Ref<number>;
  searchConditionClass: Ref<SearchConditionClass>;
  searchConditionInSchoolState: Ref<SearchConditionInSchoolState>;
  searchConditionSort: Ref<UserSortCondition>;
  searchConditionNumResults: Ref<NumResultsCondition>;
} {
  const route = useRoute();
  const splitPath = computed(() => route.path.split("/"));
  const query = computed(() => route.query);
  const params = computed(() => route.params);

  const pageName = computed(() => {
    return asPageNameOrNull(splitPath.value[1]);
  });
  const subPageName = computed(() => {
    return asGuardianSubPageNameOrNull(splitPath.value[2]);
  });

  const userId = computed(() => asTextOrNull(params.value["userId"]));
  const searchPageNum = ref(asIntOrNull(query.value["page"], 1, 99999) ?? 1);

  const queryText = ref(asTextOrNull(query.value["q"]) ?? "");

  const schoolYear = ref(
    asIntOrNull(query.value["schoolYear"], 1, 99999) ?? currentSchoolYear,
  );

  const searchConditionClass = ref<SearchConditionClass>(
    searchConditionClassFromQueryParams(schoolYear.value, query.value),
  );
  const searchConditionInSchoolState = ref<SearchConditionInSchoolState>(
    searchConditionInSchoolStateFromQueryParams(today, query.value),
  );
  const searchConditionSort = ref<UserSortCondition>(
    userSortConditionFromQueryParams(query.value),
  );
  const searchConditionNumResults = ref<NumResultsCondition>(
    numResultsConditionFromQueryParams(query.value),
  );

  return {
    pageName,
    subPageName,
    userId,
    searchPageNum,
    queryText,
    schoolYear,
    searchConditionClass,
    searchConditionInSchoolState,
    searchConditionSort,
    searchConditionNumResults,
  };
}
