
import { defineComponent, PropType } from "vue";
import SchoolEditTableRowText from "@/views/school/SchoolEdit/SchoolEditTable/SchoolEditTableRowText/SchoolEditTableRowText.vue";
import SchoolEditTableRowImage from "@/views/school/SchoolEdit/SchoolEditTable/SchoolEditTableRowImage/SchoolEditTableRowImage.vue";
import { School } from "@/ts/api/user-service";
import { names } from "@/ts/app/object-name";

export default defineComponent({
  name: "SchoolEditTable",
  components: { SchoolEditTableRowImage, SchoolEditTableRowText },
  props: {
    model: { type: Object as PropType<School>, required: true },

    /**
     * この画面に来てからの、ロゴのアップデート時刻。
     * 画面に来た時の初期値はnull。
     * ただロゴの変更をすぐに反映するためだけに用いる。
     */
    logoUpdatedAt: { type: Object as PropType<Date>, required: false },

    onInputSchoolName: {
      type: Function as PropType<(value: string) => void>,
      required: true,
    },
    onInputLogo: {
      type: Function as PropType<(file: File | null) => void>,
      required: true,
    },

    editing: { type: Boolean, required: true },
  },
  setup(props) {
    const onInputSchoolNameInternal = (value: string) => {
      if (!props.editing) return;
      props.onInputSchoolName(value);
    };
    const onInputLogoInternal = (file: File | null) => {
      if (!props.editing) return;
      props.onInputLogo(file);
    };

    const nameCellWidth = 100;
    const valueCellWidth = 240;

    const schoolNameDisplayName = names.schoolName.d;
    const schoolLogoDisplayName = names.schoolLogo.d;

    return {
      onInputSchoolNameInternal,
      onInputLogoInternal,

      nameCellWidth,
      valueCellWidth,

      schoolNameDisplayName,
      schoolLogoDisplayName,
    };
  },
});
