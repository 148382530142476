import { User } from "@/ts/objects/entity/user";
import { StringType } from "@/ts/app/columns/def/column-types";
import { UserColumnDefPartial } from "@/ts/app/columns/def/user-column-def";

export function userColumnDefUserId<U extends User>(): UserColumnDefPartial<
  "userId",
  U,
  string
> {
  return {
    id: "userId",
    width: 156,
    type: StringType,
    getValue: (u: U) => u.userId,
  };
}

export function userColumnDefGoogleMail<U extends User>(): UserColumnDefPartial<
  "googleMail",
  U,
  string
> {
  return {
    id: "googleMail",
    width: 190,
    type: StringType,
    getValue: (u: U) => u.googleMail,
  };
}

export function userColumnDefPhotoUrl<U extends User>(): UserColumnDefPartial<
  "photoUrl",
  U,
  string
> {
  return {
    id: "photoUrl",
    width: 120,
    type: StringType,
    getValue: (u: U) => u.photoUrl,
  };
}

export function userColumnDefName<U extends User>(): UserColumnDefPartial<
  "name",
  U,
  string
> {
  return {
    id: "name",
    width: 120,
    type: StringType,
    getValue: (u: U) => u.name,
    setValue: (u: U, v: any): U => ({ ...u, name: v }),
  };
}

// Guardian用。
export function userColumnDefName1<U extends User>(): UserColumnDefPartial<
  "name1",
  U,
  string
> {
  return {
    id: "name1",
    width: 120,
    type: StringType,
    getValue: (u: U) => u.name,
    setValue: (u: U, v: any): U => ({ ...u, name: v }),
  };
}
