import { PartialDeep } from "type-fest";
import {
  studentProfileColumnGroupName,
  StudentProfileColumnGroupName,
} from "@/ts/app/columns/def/student/student-profile-column";
import {
  studentHealthColumnGroupName,
  StudentHealthColumnGroupName,
} from "@/ts/app/columns/def/student/student-health-column";
import {
  studentCustomColumnGroupName,
  StudentCustomColumnGroupName,
} from "@/ts/app/columns/def/student/student-custom-column";
import { UserColumnVisibility } from "@/ts/app/columns/visibility/user-column-visibility";
import {
  StudentListCustomColumnId,
  StudentListHealthColumnId,
  StudentListProfileColumnId,
} from "@/ts/app/columns/user-list/student-list-columns";

export type StudentColumnVisibilityAll = {
  profileColumnVisibility: StudentProfileColumnVisibility;
  healthColumnVisibility: StudentHealthColumnVisibility;
  customColumnVisibility: StudentCustomColumnVisibility;
};

export type StudentColumnVisibility =
  | StudentProfileColumnVisibility
  | StudentHealthColumnVisibility
  | StudentCustomColumnVisibility;

export type StudentProfileColumnVisibility = UserColumnVisibility<
  StudentProfileColumnGroupName,
  StudentListProfileColumnId
>;

export type StudentHealthColumnVisibility = UserColumnVisibility<
  StudentHealthColumnGroupName,
  StudentListHealthColumnId
>;

export type StudentCustomColumnVisibility = UserColumnVisibility<
  StudentCustomColumnGroupName,
  StudentListCustomColumnId
>;

/**
 * デフォルトの児童生徒プロフィール列表示状態を取得する。
 * @param checked trueならすべてチェック済、falseならすべて未チェックの状態を返す。
 */
export function defaultStudentProfileColumnVisibility(
  checked: boolean,
): StudentProfileColumnVisibility {
  return {
    groupName: studentProfileColumnGroupName,
    state: {
      userId: checked,
      googleMail: checked,
      name: checked,

      nameKana: checked,
      nameRome: checked,
      familyName: checked,
      familyNameKana: checked,
      givenName: checked,
      givenNameKana: checked,

      schoolType: checked,
      gradeNumber: checked,
      className: checked,
      studentNumber: checked,

      guardian: checked,

      sex: checked,
      nickname: checked,
      nicknameKana: checked,
      birthday: checked,
      applicationDate: checked,
      kindergartenEntranceDate: checked,
      elementarySchoolEntranceDate: checked,
      juniorHighSchoolEntranceDate: checked,
      transferDate: checked,
      graduationDate: checked,
      kindergarten: checked,
      previousSchool: checked,
      zipcode: checked,
      address: checked,
      email: checked,
      mobilePhone: checked,
      pictureGcsUrl: checked,
      country: checked,
      religion: checked,
      commutingBy: checked,
      bus: checked,
      nearestStation: checked,
      hasLunch: checked,
      activityMonday: checked,
      activityTuesday: checked,
      activityWednesday: checked,
      activityThursday: checked,
      activityFriday: checked,
    },
  };
}

/**
 * デフォルトの児童生徒健康状態列表示状態を取得する。
 * @param checked trueならすべてチェック済、falseならすべて未チェックの状態を返す。
 */
export function defaultStudentHealthColumnVisibility(
  checked: boolean,
): StudentHealthColumnVisibility {
  return {
    groupName: studentHealthColumnGroupName,
    state: {
      bloodType: checked,
      normalBodyTemperature: checked,
      inoculation: checked,
      medicalHistory: checked,
      homeDoctor: checked,
      foodAllergy: checked,
      anaphylaxis: checked,
      otherAllergy: checked,
      asthma: checked,
    },
  };
}

/**
 * デフォルトの児童生徒カスタム列表示状態を取得する。
 * @param checked trueならすべてチェック済、falseならすべて未チェックの状態を返す。
 */
export function defaultStudentCustomColumnVisibility(
  checked: boolean,
): StudentCustomColumnVisibility {
  return {
    groupName: studentCustomColumnGroupName,
    state: {
      custom01: checked,
      custom02: checked,
      custom03: checked,
      custom04: checked,
      custom05: checked,
      custom06: checked,
      custom07: checked,
      custom08: checked,
      custom09: checked,
      custom10: checked,
      custom11: checked,
      custom12: checked,
      custom13: checked,
      custom14: checked,
      custom15: checked,
      custom16: checked,
      custom17: checked,
      custom18: checked,
      custom19: checked,
      custom20: checked,
      custom21: checked,
      custom22: checked,
      custom23: checked,
      custom24: checked,
      custom25: checked,
      custom26: checked,
      custom27: checked,
      custom28: checked,
      custom29: checked,
      custom30: checked,
      custom31: checked,
      custom32: checked,
      custom33: checked,
      custom34: checked,
      custom35: checked,
      custom36: checked,
      custom37: checked,
      custom38: checked,
      custom39: checked,
      custom40: checked,
    },
  };
}

/**
 * デフォルトの児童生徒列表示状態を取得する。
 * @param checked trueならすべてチェック済、falseならすべて未チェックの状態を返す。
 */
export function defaultStudentColumnVisibility(
  checked: boolean,
): StudentColumnVisibilityAll {
  return {
    profileColumnVisibility: defaultStudentProfileColumnVisibility(checked),
    healthColumnVisibility: defaultStudentHealthColumnVisibility(checked),
    customColumnVisibility: defaultStudentCustomColumnVisibility(checked),
  };
}

export function getStudentColumnVisibility(
  partial: PartialDeep<StudentColumnVisibilityAll> = {},
): StudentColumnVisibilityAll {
  return {
    profileColumnVisibility: {
      groupName: studentProfileColumnGroupName,
      state: {
        ...defaultStudentProfileColumnVisibility(false).state,
        ...partial?.profileColumnVisibility?.state,
      },
    },
    healthColumnVisibility: {
      groupName: studentHealthColumnGroupName,
      state: {
        ...defaultStudentHealthColumnVisibility(false).state,
        ...partial?.healthColumnVisibility?.state,
      },
    },
    customColumnVisibility: {
      groupName: studentCustomColumnGroupName,
      state: {
        ...defaultStudentCustomColumnVisibility(false).state,
        ...partial?.customColumnVisibility?.state,
      },
    },
  };
}
