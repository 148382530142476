
import { defineComponent, PropType } from "vue";
import EditButtonSet from "@/components/other/EditButtonSet/EditButtonSet.vue";
import StudentSubPageHeading from "@/views/student/_components/StudentSubPageHeading/StudentSubPageHeading.vue";
import { names } from "@/ts/app/object-name";

export default defineComponent({
  name: "StudentCustomColumnEditHeader",
  components: {
    StudentSubPageHeading,
    EditButtonSet,
  },
  props: {
    isAdmin: { type: Boolean, required: true },

    editing: { type: Boolean, required: true },

    onClickBack: { type: Function as PropType<() => void>, required: true },
    onClickEdit: { type: Function as PropType<() => void>, required: true },
    onClickCancel: { type: Function as PropType<() => void>, required: true },
    onClickSave: { type: Function as PropType<() => void>, required: true },
  },
  setup() {
    const studentCustomItemsDisplayName = names.studentCustomItems.d;

    return {
      studentCustomItemsDisplayName,
    };
  },
});
