import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TermEdit = _resolveComponent("TermEdit")!

  return (_openBlock(), _createBlock(_component_TermEdit, {
    "is-admin": _ctx.isAdmin,
    "current-tab": _ctx.currentTab,
    "change-tab": _ctx.changeTab,
    terms: _ctx.terms,
    "table-shape": _ctx.tableShape,
    "table-shape-on-edit": _ctx.tableShapeOnEdit,
    "unsaved-data": _ctx.unsavedData,
    "on-change-term": _ctx.onChangeTerm,
    "add-term-number": _ctx.addTermNumber,
    "delete-term-number": _ctx.deleteTermNumber,
    "add-school-year": _ctx.addSchoolYear,
    "delete-school-year": _ctx.deleteSchoolYear,
    "start-editing": _ctx.startEditing,
    "cancel-editing": _ctx.cancelEditing,
    save: _ctx.save
  }, null, 8, ["is-admin", "current-tab", "change-tab", "terms", "table-shape", "table-shape-on-edit", "unsaved-data", "on-change-term", "add-term-number", "delete-term-number", "add-school-year", "delete-school-year", "start-editing", "cancel-editing", "save"]))
}