
import { computed, defineComponent } from "vue";
import TextBalloon from "@/components/balloons/TextBalloon/TextBalloon.vue";
import { useHovering } from "@/composables/use-hovering";
import { useCopyToClipboard } from "@/composables/use-copy-to-clipboard";
import { isNullish } from "@/ts/utils/common-util";

export default defineComponent({
  name: "UserListTableCellNormal",
  components: { TextBalloon },
  props: {
    value: { type: [String, Number], required: false },
  },
  setup(props) {
    const { hovering, onMouseEnter, onMouseLeave } = useHovering(200);
    const { copyToClipboard } = useCopyToClipboard();

    const displayValue = computed<string>(() => {
      const _value = props.value;
      if (isNullish(_value)) return "";
      if (typeof _value === "string") return _value;
      return _value.toString(10);
    });
    const isDisplayValueEmpty = computed(() => displayValue.value === "");

    return {
      hovering,
      onMouseEnter,
      onMouseLeave,

      copyToClipboard: () => copyToClipboard(displayValue.value),

      displayValue,
      isDisplayValueEmpty,
    };
  },
});
