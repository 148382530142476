
import { computed, defineComponent, PropType } from "vue";
import EditableItemText from "@/components/editable-items/EditableItemText/EditableItemText.vue";
import EditableItemDate from "@/components/editable-items/EditableItemDate/EditableItemDate.vue";
import EditableItemEnum from "@/components/editable-items/EditableItemEnum/EditableItemEnum.vue";
import { SelectOption } from "@/ts/app/select-option";
import { UserColumn } from "@/ts/app/columns/def/user-column";
import { AnyUser } from "@/ts/objects/entity/user";
import EditableItemNumber from "@/components/editable-items/EditableItemNumber/EditableItemNumber.vue";
import { hasValue } from "@/ts/utils/common-util";
import EditableItemUser from "@/components/editable-items/EditableItemUser/EditableItemUser.vue";
import EditableItemStudentClasses from "@/components/editable-items/EditableItemStudentClasses/EditableItemStudentClasses.vue";
import EditableItemTeacherClasses from "@/components/editable-items/EditableItemTeacherClasses/EditableItemTeacherClasses.vue";
import { ObjectInternalName } from "@/ts/app/object-name";
import EditableItemImage from "@/components/editable-items/EditableItemImage/EditableItemImage.vue";
import UserEditTableItemReadonlyUsers from "@/components/table/edit/UserEditTableItem/UserEditTableItemReadonlyUsers/UserEditTableItemReadonlyUsers.vue";

export default defineComponent({
  name: "UserEditTableItem",
  components: {
    UserEditTableItemReadonlyUsers,
    EditableItemImage,
    EditableItemTeacherClasses,
    EditableItemStudentClasses,
    EditableItemUser,
    EditableItemNumber,
    EditableItemEnum,
    EditableItemDate,
    EditableItemText,
  },
  props: {
    userColumn: {
      type: Object as PropType<UserColumn<ObjectInternalName, AnyUser, any>>,
      required: true,
      validator: (v: UserColumn<ObjectInternalName, AnyUser, any>) =>
        hasValue(v.setValue),
    },
    editing: { type: Boolean, required: true },

    onInput: {
      type: Function as PropType<(value: any) => void>,
      required: true,
    },
  },
  setup(props) {
    const enumOptions = computed<SelectOption[] | null>(() => {
      const type = props.userColumn.type;
      if (type.category !== "enum") return null;
      return type.possibleValues ?? null;
    });

    const valueFontSize = 13;
    return {
      enumOptions,

      valueFontSize,
      styles: {
        "--value-font-size": `${valueFontSize}px`,
      },
    };
  },
});
