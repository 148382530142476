
import { computed, defineComponent, PropType } from "vue";
import { Student } from "@/ts/objects/entity/student";
import { StudentColumnVisibilityAll } from "@/ts/app/columns/visibility/student-column-visibility";
import StudentListTableRow from "@/views/student/_components/StudentListTable/StudentListTableRow/StudentListTableRow.vue";
import UserListTableHeader from "@/components/table/list/UserListTableHeader/UserListTableHeader.vue";
import { getStudentColumnDefs } from "@/ts/app/columns/def/student/student-column";
import { useStudentStore } from "@/store/student-store";
import { useUserService } from "@/composables/provide-user-service";

export default defineComponent({
  name: "StudentListTable",
  components: { UserListTableHeader, StudentListTableRow },
  props: {
    horizontalMargin: { type: Number, default: 24 },

    schoolYear: { type: Number, required: true },
    students: { type: Array as PropType<Student[]>, required: true },
    columnVisibility: {
      type: Object as PropType<StudentColumnVisibilityAll>,
      required: true,
    },

    /**
     * ユーザーIDから、編集画面のパスを取得する。
     * 未指定の場合、編集画面へのリンクが現れない。
     */
    userIdToEditPath: {
      type: Function as PropType<(userId: string) => string>,
      required: false,
    },

    /**
     * trueなら、ユーザー項目などをリンク化する。
     */
    linkEnabled: { type: Boolean, required: true },
  },
  setup(props) {
    const userService = useUserService();
    const studentStore = useStudentStore();
    studentStore.loadCustomColumnNames(userService);

    return {
      allColumns: computed(() =>
        getStudentColumnDefs(
          props.schoolYear,
          studentStore.customColumnNames.data,
        ),
      ),
      flattenedColumnVisibility: computed(() => ({
        ...props.columnVisibility.profileColumnVisibility.state,
        ...props.columnVisibility.healthColumnVisibility.state,
        ...props.columnVisibility.customColumnVisibility.state,
      })),

      iconCellWidth: 28,
      nameCellWidth: 120,
      styles: { "--horizontal-margin": `${props.horizontalMargin}px` },
    };
  },
});
