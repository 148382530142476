import { AppError } from "@/ts/app/error/app-error";
import { isNullish } from "@/ts/utils/common-util";
import { Result } from "@/ts/app/result";

export type LoadableData<T> =
  | LoadableDataNull
  | LoadableDataError
  | LoadableDataFresh<T>
  | LoadableDataStale<T>
  | LoadableDataStaleError<T>;

type LoadableDataNull = {
  readonly state: "null";
  readonly data: null;
  readonly error: null;

  readonly hasData: false;
  readonly hasFreshData: false;
  readonly hasError: false;
};
export function loadableDataNull(): LoadableDataNull {
  return {
    state: "null",
    data: null,
    error: null,
    hasData: false,
    hasFreshData: false,
    hasError: false,
  };
}

type LoadableDataError = {
  readonly state: "error";
  readonly data: null;
  readonly error: AppError;

  readonly hasData: false;
  readonly hasFreshData: false;
  readonly hasError: true;
};
export function loadableDataError(error: AppError): LoadableDataError {
  return {
    state: "error",
    data: null,
    error,
    hasData: false,
    hasFreshData: false,
    hasError: true,
  };
}

type LoadableDataFresh<T> = {
  readonly state: "fresh";
  readonly data: T;
  readonly error: null;

  readonly hasData: true;
  readonly hasFreshData: true;
  readonly hasError: false;
};
export function loadableDataFresh<T>(data: T): LoadableDataFresh<T> {
  return {
    state: "fresh",
    data,
    error: null,

    hasData: true,
    hasFreshData: true,
    hasError: false,
  };
}

type LoadableDataStale<T> = {
  readonly state: "stale";
  readonly data: T;
  readonly error: null;

  readonly hasData: true;
  readonly hasFreshData: false;
  readonly hasError: false;
};
export function loadableDataStale<T>(data: T): LoadableDataStale<T> {
  return {
    state: "stale",
    data,
    error: null,

    hasData: true,
    hasFreshData: false,
    hasError: false,
  };
}

type LoadableDataStaleError<T> = {
  readonly state: "stale-error";
  readonly data: T;
  readonly error: AppError;

  readonly hasData: true;
  readonly hasFreshData: false;
  readonly hasError: true;
};
export function loadableDataStaleError<T>(
  data: T,
  error: AppError,
): LoadableDataStaleError<T> {
  return {
    state: "stale-error",
    data,
    error,

    hasData: true,
    hasFreshData: false,
    hasError: true,
  };
}

export function toLoading<T>(
  d: LoadableData<T> | null | undefined,
):
  | LoadableDataNull
  | LoadableDataError
  | LoadableDataStale<T>
  | LoadableDataStaleError<T> {
  if (isNullish(d)) return loadableDataNull();

  if (d.state === "fresh") return loadableDataStale(d.data);
  return d;
}

export function applyError<T>(
  d: LoadableData<T> | null | undefined,
  error: AppError,
): LoadableDataError | LoadableDataStaleError<T> {
  if (isNullish(d)) return loadableDataError(error);

  switch (d.state) {
    case "null":
      return loadableDataError(error);
    case "error":
      return loadableDataError(error);
    case "fresh":
      return loadableDataStaleError(d.data, error);
    case "stale":
      return loadableDataStaleError(d.data, error);
    case "stale-error":
      return loadableDataStaleError(d.data, error);
  }
}

export function applyResult<T>(
  d: LoadableData<T> | null | undefined,
  result: Result<T>,
  clearDataOnError: boolean = false,
): LoadableDataError | LoadableDataFresh<T> | LoadableDataStaleError<T> {
  if (result.ok) {
    return loadableDataFresh(result.data);
  } else {
    if (clearDataOnError) {
      return loadableDataError(result.error);
    } else {
      return applyError(d, result.error);
    }
  }
}

export function composeLoadableData<T0, T1, U>(
  d0: LoadableData<T0>,
  d1: LoadableData<T1>,
  compose: (t0: T0, t1: T1) => U,
): LoadableData<U> {
  // とりあえず、エラーは一個だけ使うことにする。

  if (d0.hasFreshData && d1.hasFreshData) {
    return loadableDataFresh(compose(d0.data, d1.data));
  }

  const error = d0.hasError ? d0.error : d1.hasError ? d1.error : null;

  if (d0.hasData && d1.hasData) {
    const composed = compose(d0.data, d1.data);
    return isNullish(error)
      ? loadableDataStale(composed)
      : loadableDataStaleError(composed, error);
  }

  return isNullish(error) ? loadableDataNull() : loadableDataError(error);
}

export function mapData<T, U>(
  d: LoadableData<T>,
  fn: (v: T) => U,
): LoadableData<U> {
  switch (d.state) {
    case "null":
      return loadableDataNull();
    case "error":
      return loadableDataError(d.error);
    case "fresh":
      return loadableDataFresh(fn(d.data));
    case "stale":
      return loadableDataStale(fn(d.data));
    case "stale-error":
      return loadableDataStaleError(fn(d.data), d.error);
  }
}
