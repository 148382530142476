import { ObjectName } from "@/ts/app/object-name";
import log from "loglevel";

export type AppError = {
  readonly name: ErrorName;
  readonly internalMessage: string;
  readonly displayMessage: string;
};

export class ThrowableAppError extends Error {
  constructor(public appError: AppError) {
    super();

    // 参考: https://developer.mozilla.org/ja/docs/Web/JavaScript/Reference/Global_Objects/Error
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ThrowableAppError);
    }

    this.name = `ThrowableAppError(${appError.name})`;
    this.message = appError.internalMessage;
  }
}

export function handleThrownError(logPrefix: string, e: unknown): AppError {
  if (e instanceof ThrowableAppError) {
    log.error(
      `${logPrefix}: error thrown: name=${e.name}, message=${e.message}`,
    );
    log.trace(e);
    return e.appError;
  }
  if (e instanceof Error) {
    log.error(
      `${logPrefix}: error thrown: name=${e.name}, message=${e.message}`,
    );
    log.trace(e);
    return errors.internal(`${e.name}: ${e.message}`);
  }
  log.error(`${logPrefix}: error thrown: unknown error`);
  log.trace(e);
  return errors.internal("unknown error occurred");
}

export type ErrorName =
  | "internal"
  | "requestFailed"
  | "fetchFailed"
  | "updateFailed"
  | "deleteFailed"
  | "validationFailed.invalidValue"
  | "validationFailed.invalidDateValue"
  | "validationFailed.nullValue"
  | "validationFailed.other"
  | "readCSVFailed";

export const errors = {
  internal: (internalMessage: string): AppError => ({
    name: "internal",
    internalMessage,
    displayMessage: "エラーが発生しました。",
  }),
  requestFailed: (
    internalMessage: string,
    displayMessage: string,
  ): AppError => ({
    name: "requestFailed",
    internalMessage,
    displayMessage,
  }),
  fetchFailed: (objectName: ObjectName): AppError => ({
    name: "fetchFailed",
    internalMessage: `failed to fetch ${objectName.i}`,
    displayMessage: `${objectName.d}の取得に失敗しました。`,
  }),
  updateFailed: (objectName: ObjectName): AppError => ({
    name: "updateFailed",
    internalMessage: `failed to update ${objectName.i}`,
    displayMessage: `${objectName.d}の更新に失敗しました。`,
  }),
  deleteFailed: (objectName: ObjectName): AppError => ({
    name: "deleteFailed",
    internalMessage: `failed to update ${objectName.i}`,
    displayMessage: `${objectName.d}の削除に失敗しました。`,
  }),
  validationFailed: {
    invalidValue: (value: any, objectName: ObjectName): AppError => ({
      name: "validationFailed.invalidValue",
      internalMessage: `${objectName.i}: invalid value: value=${JSON.stringify(
        value,
      )}`,
      displayMessage: `${objectName.d}: 使用できない値です。`,
    }),
    invalidDateValue: (value: any, objectName: ObjectName): AppError => ({
      name: "validationFailed.invalidDateValue",
      internalMessage: `${
        objectName.i
      }: invalid date value: value=${JSON.stringify(value)}`,
      displayMessage: `${objectName.d}: 日付はyyyy-mm-ddの形式(例: 2001-01-01)で指定してください。`,
    }),
    nullValue: (objectName: ObjectName): AppError => ({
      name: "validationFailed.nullValue",
      internalMessage: `${objectName.i}: null value`,
      displayMessage: `${objectName.d}: 値がありません。`,
    }),
    other: (
      value: any,
      objectName: ObjectName,
      internalMessage: string,
      displayMessage: string,
    ): AppError => {
      const _internalMessage =
        value === undefined
          ? `${objectName.i}: ${internalMessage}`
          : `${objectName.i}: ${internalMessage}: value=${JSON.stringify(
              value,
            )}`;
      return {
        name: "validationFailed.other",
        internalMessage: _internalMessage,
        displayMessage,
      };
    },
  },
  readCSVFailed: (
    internalMessage: string,
    displayMessage: string,
  ): AppError => ({
    name: "readCSVFailed",
    internalMessage,
    displayMessage,
  }),
} as const;
