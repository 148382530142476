import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GuardianImport = _resolveComponent("GuardianImport")!

  return (_openBlock(), _createBlock(_component_GuardianImport, {
    saving: _ctx.saving,
    guardians: _ctx.guardiansAfter,
    "on-select-file": _ctx.onSelectFile,
    "on-unselect-file": _ctx.onUnselectFile,
    "column-visibility": _ctx.columnVisibility,
    "is-column-select-open": _ctx.isColumnSelectOpen,
    "toggle-column-select-visibility": _ctx.toggleColumnSelectVisibility,
    "on-change-column-visibility": _ctx.onChangeColumnVisibility,
    "on-confirm-import": _ctx.onConfirmImport,
    "on-click-back": _ctx.onClickBack
  }, null, 8, ["saving", "guardians", "on-select-file", "on-unselect-file", "column-visibility", "is-column-select-open", "toggle-column-select-visibility", "on-change-column-visibility", "on-confirm-import", "on-click-back"]))
}