
import { computed, defineComponent, PropType } from "vue";
import UserEditTableItem from "@/components/table/edit/UserEditTableItem/UserEditTableItem.vue";
import { UserColumn } from "@/ts/app/columns/def/user-column";
import { AnyUser } from "@/ts/objects/entity/user";
import { hasValue } from "@/ts/utils/common-util";
import { ObjectInternalName } from "@/ts/app/object-name";

export default defineComponent({
  name: "UserEditTableBody",
  components: { UserEditTableItem },
  props: {
    userColumns: {
      type: Array as PropType<UserColumn<ObjectInternalName, AnyUser, any>[]>,
      required: true,
    },
    editing: { type: Boolean, required: true },

    onInput: {
      type: Function as PropType<(columnId: string, value: any) => void>,
      required: true,
    },
  },
  setup(props) {
    return {
      writableUserColumns: computed(() =>
        props.userColumns.filter((col) => hasValue(col.setValue)),
      ),
    };
  },
});
