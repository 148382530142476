
import { computed, defineComponent, PropType } from "vue";
import EditableItemEnum from "@/components/editable-items/EditableItemEnum/EditableItemEnum.vue";
import EditableItemNumber from "@/components/editable-items/EditableItemNumber/EditableItemNumber.vue";
import { SchoolType, schoolTypes } from "@/ts/objects/value/school-type";
import { Class } from "@/ts/objects/entity/class";
import { isNullish, parseIntOrNull } from "@/ts/utils/common-util";
import { useAsyncComputed } from "@/composables/use-async-computed";
import {
  gradeNumbersOfSchoolType,
  isElementaryGradeNumber,
  isJuniorhighGradeNumber,
} from "@/ts/objects/value/grade-number";
import RemoveButton from "@/components/buttons/RemoveButton/RemoveButton.vue";
import { names } from "@/ts/app/object-name";

const CLASS_UNSELECTED = "__CLASS_UNSELECTED__";

export default defineComponent({
  name: "StudentClassesSelectPopupItem",
  components: { RemoveButton, EditableItemNumber, EditableItemEnum },
  props: {
    width: { type: Number, default: 260 },
    height: { type: Number, default: 42 },

    /**
     * 選択中の年度。
     */
    schoolYear: { type: Number, default: null },
    /**
     * 選択中の学校タイプ。
     */
    schoolType: { type: String as PropType<SchoolType>, required: true },
    /**
     * 選択中の学年。
     */
    gradeNumber: { type: Number, default: null },
    /**
     * 選択中のクラスID。
     */
    classId: { type: String, default: null },
    /**
     * 選択中の出席番号。
     */
    studentNumber: { type: Number, default: null },

    /**
     * ある年度のすべてのクラスを、正しい並び順で取得する関数。
     */
    getClasses: {
      type: Function as PropType<(schoolYear: number) => Promise<Class[]>>,
      required: true,
    },

    /**
     * 学校タイプ・学年・クラスが変更されたときに呼び出される。
     */
    onChange: {
      type: Function as PropType<
        (
          schoolYear: number | null,
          schoolType: SchoolType,
          gradeNumber: number | null,
          cls: Class | null,
          studentNumber: number | null,
        ) => void
      >,
      required: true,
    },
    /**
     * 削除ボタンを押したときに呼び出される。
     */
    onRemove: { type: Function as PropType<() => void>, required: true },
  },
  setup(props) {
    const update = async (
      schoolYearInput: number | null,
      schoolTypeInput: SchoolType,
      gradeNumberInput: number,
      classIdInput: string | null,
      studentNumberInput: number | null,
    ) => {
      const [schoolType, gradeNumber] = sanitize(
        schoolTypeInput,
        gradeNumberInput,
      );

      if (
        isNullish(schoolYearInput) ||
        isNullish(gradeNumber) ||
        isNullish(classIdInput)
      ) {
        props.onChange(
          schoolYearInput,
          schoolType,
          gradeNumber,
          null,
          studentNumberInput,
        );
        return;
      }

      const classes = await props.getClasses(schoolYearInput);
      const cls = classes.find((c) => c.classId === classIdInput);
      if (
        isNullish(cls) ||
        cls.schoolYear !== schoolYearInput ||
        cls.grade.schoolType !== schoolType ||
        cls.grade.gradeNumber !== gradeNumber
      ) {
        props.onChange(
          schoolYearInput,
          schoolType,
          gradeNumber,
          null,
          studentNumberInput,
        );
        return;
      }

      props.onChange(
        schoolYearInput,
        schoolType,
        gradeNumber,
        cls,
        studentNumberInput,
      );
    };

    const [selectableClasses] = useAsyncComputed(async () => {
      const schoolYear = props.schoolYear;
      const schoolType = props.schoolType;
      const gradeNumber = props.gradeNumber;
      if (
        isNullish(schoolYear) ||
        isNullish(schoolType) ||
        isNullish(gradeNumber)
      )
        return [];

      return (await props.getClasses(schoolYear)).filter(
        (c) =>
          c.grade.gradeNumber === gradeNumber &&
          c.grade.schoolType === schoolType,
      );
    }, []);
    const classOptions = computed(() => [
      { value: CLASS_UNSELECTED, name: "－" },
      ...selectableClasses.value.map((c) => ({
        value: c.classId,
        name: c.name,
      })),
    ]);
    const classInputEnabled = computed(
      () => selectableClasses.value.length > 0,
    );
    const classIdSelectValue = computed(() => {
      if (isNullish(props.classId)) return CLASS_UNSELECTED;
      return props.classId;
    });

    const schoolYearLabel = names.schoolYear.d;
    const schoolTypeLabel = names.schoolType.d;
    const gradeNumberLabel = names.gradeNumber.d;
    const classNameLabel = names.className.d;
    const studentNumberLabel = names.studentNumber.d;

    return {
      schoolTypeOptions: Object.entries(schoolTypes).map(([k, v]) => ({
        name: v,
        value: k,
      })),

      gradeNumberSelectValue: computed(() => props.gradeNumber.toString(10)),
      gradeNumberOptions: computed(() =>
        gradeNumbersOfSchoolType[props.schoolType].map((gradeNumber) => ({
          name: gradeNumber.toString(10),
          value: gradeNumber.toString(10),
        })),
      ),

      message: computed(() => {
        if (
          isNullish(props.schoolYear) ||
          isNullish(props.schoolType) ||
          isNullish(props.gradeNumber) ||
          isNullish(props.classId)
        )
          return "年度とクラスを入力してください。";
        return null;
      }),

      classOptions,
      classInputEnabled,
      classIdSelectValue,

      onInputSchoolYearInternal: (value: number | null) => {
        update(
          value,
          props.schoolType,
          props.gradeNumber,
          props.classId,
          props.studentNumber,
        );
      },
      onInputSchoolTypeInternal: (value: SchoolType) => {
        update(
          props.schoolYear,
          value,
          props.gradeNumber,
          props.classId,
          props.studentNumber,
        );
      },
      onInputGradeNumberInternal: (value: string) => {
        const intValue = parseIntOrNull(value) ?? 1;
        update(
          props.schoolYear,
          props.schoolType,
          intValue,
          props.classId,
          props.studentNumber,
        );
      },
      onInputClassInternal: (value: string) => {
        update(
          props.schoolYear,
          props.schoolType,
          props.gradeNumber,
          value === CLASS_UNSELECTED ? null : value,
          props.studentNumber,
        );
      },
      onInputStudentNumberInternal: (value: number | null) => {
        update(
          props.schoolYear,
          props.schoolType,
          props.gradeNumber,
          props.classId,
          value,
        );
      },

      schoolYearLabel,
      schoolTypeLabel,
      gradeNumberLabel,
      classNameLabel,
      studentNumberLabel,

      styles: {
        "--width": `${props.width}px`,
        "--height": `${props.height}px`,
      },
    };
  },
});

function sanitize(
  schoolType: SchoolType,
  gradeNumber: number,
): [SchoolType, number] {
  if (schoolType === "elementary") {
    if (isElementaryGradeNumber(gradeNumber))
      return ["elementary", gradeNumber];
    return ["elementary", 1];
  } else {
    if (isJuniorhighGradeNumber(gradeNumber))
      return ["juniorhigh", gradeNumber];
    return ["juniorhigh", 1];
  }
}
