import { Curriculum } from "@/ts/objects/entity/curriculum";
import { Grade, gradeToGradeValue } from "@/ts/objects/value/grade";

export function fakeCurriculums(
  schoolYears: number[] = [1999, 2000, 2001, 2002],
  grades: Grade[] = [
    { schoolType: "elementary", gradeNumber: 1 },
    { schoolType: "elementary", gradeNumber: 2 },
    { schoolType: "elementary", gradeNumber: 3 },
    { schoolType: "elementary", gradeNumber: 4 },
    { schoolType: "juniorhigh", gradeNumber: 1 },
    { schoolType: "juniorhigh", gradeNumber: 2 },
  ],
): Curriculum[] {
  return schoolYears.flatMap((schoolYear) =>
    grades.flatMap((grade) => {
      const isElementary = grade.schoolType === "elementary";
      const gradeValue = gradeToGradeValue(grade);

      return [
        // 数値評価教科
        {
          evalType: "numeric",
          id: `nec-${schoolYear}-${gradeValue}-japanese`,
          schoolYear,
          grade,
          name: {
            value: "国語",
            hash: "dummy-hash",
          },
          orderNum: 0,
          syllabusFileGcsUrl: null,
        },
        {
          evalType: "numeric",
          id: `nec-${schoolYear}-${gradeValue}-math`,
          schoolYear,
          grade,
          name: {
            value: isElementary ? "算数" : "数学",
            hash: "dummy-hash",
          },
          orderNum: 1,
          syllabusFileGcsUrl: null,
        },
        {
          evalType: "numeric",
          id: `nec-${schoolYear}-${gradeValue}-science`,
          schoolYear,
          grade,
          name: {
            value: "理科",
            hash: "dummy-hash",
          },
          orderNum: 2,
          syllabusFileGcsUrl: null,
        },
        {
          evalType: "numeric",
          id: `nec-${schoolYear}-${gradeValue}-society`,
          schoolYear,
          grade,
          name: {
            value: "社会",
            hash: "dummy-hash",
          },
          orderNum: 3,
          syllabusFileGcsUrl: null,
        },
        {
          evalType: "numeric",
          id: `nec-${schoolYear}-${gradeValue}-english`,
          schoolYear,
          grade,
          name: {
            value: "英語",
            hash: "dummy-hash",
          },
          orderNum: 4,
          syllabusFileGcsUrl: null,
        },
        {
          evalType: "numeric",
          id: `nec-${schoolYear}-${gradeValue}-music`,
          schoolYear,
          grade,
          name: {
            value: "音楽",
            hash: "dummy-hash",
          },
          orderNum: 5,
          syllabusFileGcsUrl: null,
        },
        {
          evalType: "numeric",
          id: `nec-${schoolYear}-${gradeValue}-art`,
          schoolYear,
          grade,
          name: {
            value: "図画工作",
            hash: "dummy-hash",
          },
          orderNum: 6,
          syllabusFileGcsUrl: null,
        },
        {
          evalType: "numeric",
          id: `nec-${schoolYear}-${gradeValue}-pe`,
          schoolYear,
          grade,
          name: {
            value: "体育",
            hash: "dummy-hash",
          },
          orderNum: 7,
          syllabusFileGcsUrl: null,
        },
        {
          evalType: "numeric",
          id: `nec-${schoolYear}-${gradeValue}-psychohistory`,
          schoolYear,
          grade,
          name: {
            value: "Psychohistory",
            hash: "dummy-hash",
          },
          orderNum: 8,
          syllabusFileGcsUrl: null,
        },

        // 文書評価教科
        {
          evalType: "essay",
          id: `eec-${schoolYear}-${gradeValue}-ethics`,
          schoolYear,
          grade,
          name: {
            value: "道徳",
            hash: "dummy-hash",
          },
          orderNum: 3,
          syllabusFileGcsUrl: null,
        },
      ];
    }),
  );
}
