import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0cee06c8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "rows",
  role: "rowgroup"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TermEditTableHeader = _resolveComponent("TermEditTableHeader")!
  const _component_TermEditTableRow = _resolveComponent("TermEditTableRow")!

  return (_openBlock(), _createElementBlock("div", {
    class: "term-edit-table-container",
    role: "table",
    "aria-label": "Term Table",
    style: _normalizeStyle(_ctx.styles)
  }, [
    _createVNode(_component_TermEditTableHeader, {
      "max-term-number": _ctx.maxTermNumber,
      "delete-term-number": _ctx.deleteTermNumber,
      editing: _ctx.editing,
      "year-cell-width": _ctx.yearCellWidth,
      "cell-width": _ctx.cellWidth
    }, null, 8, ["max-term-number", "delete-term-number", "editing", "year-cell-width", "cell-width"]),
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.schoolYears, (schoolYear) => {
        return (_openBlock(), _createBlock(_component_TermEditTableRow, {
          key: schoolYear,
          class: "row",
          "school-year": schoolYear,
          model: _ctx.model[schoolYear],
          "on-change-term": 
          (termNumber, changedStartDate, newDate) =>
            _ctx.onChangeTerm(schoolYear, termNumber, changedStartDate, newDate)
        ,
          "max-term-number": _ctx.maxTermNumber,
          editing: _ctx.editing,
          "on-click-delete-row": 
          schoolYear === _ctx.maxSchoolYear ? _ctx.deleteSchoolYear : undefined
        ,
          "year-cell-width": _ctx.yearCellWidth,
          "cell-width": _ctx.cellWidth
        }, null, 8, ["school-year", "model", "on-change-term", "max-term-number", "editing", "on-click-delete-row", "year-cell-width", "cell-width"]))
      }), 128))
    ])
  ], 4))
}