
import { defineComponent, PropType, ref } from "vue";
import SearchConditionRadioSimple from "@/components/radios/SearchConditionRadioSimple/SearchConditionRadioSimple.vue";
import SearchConditionPopupFrame from "@/components/search-condition/SearchConditionPopupFrame/SearchConditionPopupFrame.vue";
import {
  isUserSortConditionSubType,
  UserSortCondition,
  UserSortConditionSubType,
} from "@/ts/objects/search-condition/user-sort-condition";

export default defineComponent({
  name: "SearchConditionPopupUserSort",
  components: { SearchConditionRadioSimple, SearchConditionPopupFrame },
  props: {
    /**
     * 現在の並び替え条件。
     * ポップアップの初期値に使う。
     */
    searchCondition: {
      type: Object as PropType<UserSortCondition>,
      required: true,
    },

    /**
     * キャンセルして閉じる。
     */
    cancel: { type: Function as PropType<() => void>, required: true },
    /**
     * 確定して閉じる。
     */
    confirm: {
      type: Function as PropType<(cond: UserSortCondition) => void>,
      required: true,
    },
  },
  setup(props) {
    const selectedRadioValue = ref(props.searchCondition.subType);
    return {
      radioGroupName: "sort",
      selectedRadioValue,

      onSelect: (value: string) => {
        if (!isUserSortConditionSubType(value)) return;
        selectedRadioValue.value = value;
      },
      onClickConfirm: () => {
        props.confirm(toSearchCondition(selectedRadioValue.value));
      },
    };
  },
});

/**
 * 本ポップアップの選択値から検索条件を作成する。
 */
function toSearchCondition(
  radioValue: UserSortConditionSubType,
): UserSortCondition {
  const commonAttr = {
    searchConditionType: "sort-user" as const,
  };

  switch (radioValue) {
    case "nameKanaAsc":
      return {
        ...commonAttr,
        subType: "nameKanaAsc",
      };
    case "nameKanaDesc":
      return {
        ...commonAttr,
        subType: "nameKanaDesc",
      };
    case "createdAtDesc":
      return {
        ...commonAttr,
        subType: "createdAtDesc",
      };
    case "createdAtAsc":
      return {
        ...commonAttr,
        subType: "createdAtAsc",
      };
    case "updatedAtDesc":
      return {
        ...commonAttr,
        subType: "updatedAtDesc",
      };
    case "updatedAtAsc":
      return {
        ...commonAttr,
        subType: "updatedAtAsc",
      };
  }
}
