import { User } from "@/ts/objects/entity/user";
import { ColumnType } from "@/ts/app/columns/def/column-types";
import { names, ObjectInternalName } from "@/ts/app/object-name";
import { hasValue } from "@/ts/utils/common-util";

export type UserColumnDef<
  ColId extends ObjectInternalName,
  U extends User,
  T,
> = {
  readonly id: ColId;
  readonly name: string;
  readonly width: number;
  readonly type: ColumnType<T>;
  /**
   * 設定した場合、入力時に最大文字数を制限する。(文字列項目でのみ有効。)
   */
  readonly maxLength: number | null;
  /**
   * 値を取得する。
   */
  readonly getValue: (user: U) => T;
  /**
   * 値を設定する。
   * 未設定の場合は、読み取り専用となり、編集画面のテーブルに現れない。
   */
  readonly setValue: ((user: U, value: T) => U) | null;
  /**
   * CSV出力するとき、ダブルクォートを強制するならtrue。
   * ※ダブルクォートが値に含まれているなどの場合、これがfalseであってもダブルクォートで括られる。
   */
  readonly forceQuotes: boolean;
};

export type UserColumnDefPartial<
  ColId extends ObjectInternalName,
  U extends User,
  T,
> = {
  id: ColId;
  name?: string;
  width: number;
  type: ColumnType<T>;
  maxLength?: number;
  getValue: (user: U) => T;
  setValue?: (user: U, value: T) => U;
  forceQuotes?: boolean;
};

export function genUserColumnDefs<
  ColId extends ObjectInternalName,
  U extends User,
  T,
>(
  definitions: UserColumnDefPartial<ColId, U, T>[],
): UserColumnDef<ColId, U, T>[] {
  return definitions.map((d) => genUserColumnDef(d));
}

export function genUserColumnDef<
  ColId extends ObjectInternalName,
  U extends User,
  T,
>(
  definition: UserColumnDefPartial<ColId, U, T> | UserColumnDef<ColId, U, T>,
): UserColumnDef<ColId, U, T> {
  return {
    id: definition.id,
    name: hasValue(definition.name) ? definition.name : names[definition.id].d,
    width: definition.width,
    type: definition.type,
    maxLength: definition.maxLength ?? null,
    getValue: definition.getValue,
    setValue: definition.setValue ?? null,
    forceQuotes: definition.forceQuotes ?? false,
  };
}
