import { Term } from "@/ts/objects/entity/term";
import { SchoolType } from "@/ts/objects/value/school-type";

export function fakeTerms(
  schoolYears: number[] = [1999, 2000, 2001, 2002],
  schoolTypes: SchoolType[] = ["elementary", "juniorhigh"],
): Term[] {
  return schoolYears.flatMap((schoolYear) =>
    schoolTypes.flatMap((schoolType) => [
      {
        schoolYear,
        schoolType,
        termNumber: 1,
        startDate: { year: schoolYear, month: 4, day: 1 },
        endDate: { year: schoolYear, month: 6, day: 30 },
      },
      {
        schoolYear,
        schoolType,
        termNumber: 2,
        startDate: { year: schoolYear, month: 7, day: 1 },
        endDate: { year: schoolYear, month: 9, day: 30 },
      },
      {
        schoolYear,
        schoolType,
        termNumber: 3,
        startDate: { year: schoolYear, month: 10, day: 1 },
        endDate: { year: schoolYear, month: 12, day: 31 },
      },
      {
        schoolYear,
        schoolType,
        termNumber: 4,
        startDate: { year: schoolYear + 1, month: 1, day: 1 },
        endDate: { year: schoolYear + 1, month: 3, day: 31 },
      },
    ]),
  );
}
