import { isUserType, UserType } from "@/ts/objects/value/user-type";
import {
  BasicUserInfoPartial as BasicUserInfoPartialResp,
  BasicUserInfo as BasicUserInfoResp,
} from "@/ts/api/user-service";
import { hasValue } from "@/ts/utils/common-util";

export type BasicUserInfo = {
  readonly userId: string;
  readonly googleMail: string;
  readonly userType: UserType;
  readonly name: string;
  readonly photoUrl?: string;
};

export function basicUserInfoFromRespOrError(
  r: BasicUserInfoResp,
): BasicUserInfo {
  const userType = r.userType;
  if (hasValue(userType) && !isUserType(userType))
    throw new Error(
      `basicUserInfoFromRespOrError: invalid userType: ${userType}`,
    );

  return {
    userId: r.userId,
    googleMail: r.googleMail,
    userType,
    name: r.name,
    photoUrl: r.photoUrl,
  };
}

export type BasicUserInfoPartial = {
  readonly userId: string;
  readonly googleMail?: string;
  readonly userType?: UserType;
  readonly name?: string;
  readonly photoUrl?: string;
};

export function basicUserInfoPartialFromRespOrError(
  r: BasicUserInfoPartialResp,
): BasicUserInfoPartial {
  const userType = r.userType;
  if (hasValue(userType) && !isUserType(userType))
    throw new Error(
      `basicUserInfoFromRespOrError: invalid userType: ${userType}`,
    );

  return {
    userId: r.userId,
    googleMail: r.googleMail,
    userType,
    name: r.name,
    photoUrl: r.photoUrl,
  };
}
