import {
  StudentCustomColumnNames,
  studentCustomColumnNamesDefault,
} from "@/ts/objects/value/student-custom-column-names";

export function fakeStudentCustomColumnNames(): StudentCustomColumnNames {
  return {
    ...studentCustomColumnNamesDefault(),
    custom02: "大きさ",
    custom40: "丸さ",
  };
}
