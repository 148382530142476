import { Class } from "@/ts/objects/entity/class";
import { createTypedObjectFromEntries } from "@/ts/utils/common-util";

export function createFakeClass(partial: Partial<Class> = {}): Class {
  const defaultClass: Class = {
    classId: "class-2000-e1-1",
    schoolYear: 2000,
    grade: { schoolType: "elementary", gradeNumber: 1 },
    name: "いち",
    classNo: 1,
  };

  return {
    ...defaultClass,
    ...partial,
  };
}

const fakeClassIds = [
  "class-2000-e1-1",
  "class-2000-e1-2",
  "class-2000-e1-3",
  "class-2000-e1-4",
  "class-2000-e6-1",
  "class-2000-e6-2",
  "class-2000-j1-1",
  "class-2000-j1-2",
  "class-2000-j3-1",
  "class-2000-j3-2",

  "class-2001-e1-1",
  "class-2001-e2-1",

  "class-2002-j1-1",
] as const;

export type FakeClassId = typeof fakeClassIds[number];

type FakeClass = Class & {
  readonly classId: FakeClassId;
};

export const fakeClasses: { [classId in FakeClassId]: FakeClass } = {
  "class-2000-e1-1": {
    classId: "class-2000-e1-1",
    schoolYear: 2000,
    grade: { schoolType: "elementary", gradeNumber: 1 },
    name: "いち",
    classNo: 1,
  },
  "class-2000-e1-2": {
    classId: "class-2000-e1-2",
    schoolYear: 2000,
    grade: { schoolType: "elementary", gradeNumber: 1 },
    name: "に",
    classNo: 2,
  },
  "class-2000-e1-3": {
    classId: "class-2000-e1-3",
    schoolYear: 2000,
    grade: { schoolType: "elementary", gradeNumber: 1 },
    name: "さん - veryveryverylonglonglongclassname!!!!!!!!!!!!",
    classNo: 3,
  },
  "class-2000-e1-4": {
    classId: "class-2000-e1-4",
    schoolYear: 2000,
    grade: { schoolType: "elementary", gradeNumber: 1 },
    name: "よん",
    classNo: 4,
  },
  "class-2000-e6-1": {
    classId: "class-2000-e6-1",
    schoolYear: 2000,
    grade: { schoolType: "elementary", gradeNumber: 6 },
    name: "イチ",
    classNo: 1,
  },
  "class-2000-e6-2": {
    classId: "class-2000-e6-2",
    schoolYear: 2000,
    grade: { schoolType: "elementary", gradeNumber: 6 },
    name: "ニ",
    classNo: 2,
  },
  "class-2000-j1-1": {
    classId: "class-2000-j1-1",
    schoolYear: 2000,
    grade: { schoolType: "juniorhigh", gradeNumber: 1 },
    name: "one",
    classNo: 1,
  },
  "class-2000-j1-2": {
    classId: "class-2000-j1-2",
    schoolYear: 2000,
    grade: { schoolType: "juniorhigh", gradeNumber: 1 },
    name: "two",
    classNo: 2,
  },
  "class-2000-j3-1": {
    classId: "class-2000-j3-1",
    schoolYear: 2000,
    grade: { schoolType: "juniorhigh", gradeNumber: 3 },
    name: "ONE",
    classNo: 1,
  },
  "class-2000-j3-2": {
    classId: "class-2000-j3-2",
    schoolYear: 2000,
    grade: { schoolType: "juniorhigh", gradeNumber: 3 },
    name: "TWO",
    classNo: 2,
  },

  "class-2001-e1-1": {
    classId: "class-2001-e1-1",
    schoolYear: 2001,
    grade: { schoolType: "elementary", gradeNumber: 1 },
    name: "2001-e1-いち",
    classNo: 1,
  },

  "class-2001-e2-1": {
    classId: "class-2001-e2-1",
    schoolYear: 2001,
    grade: { schoolType: "elementary", gradeNumber: 2 },
    name: "2001-e2-いち",
    classNo: 1,
  },
  "class-2002-j1-1": {
    classId: "class-2002-j1-1",
    schoolYear: 2002,
    grade: { schoolType: "juniorhigh", gradeNumber: 1 },
    name: "2002-j1-one",
    classNo: 1,
  },
} as const;

export function fakeClassesAsArray(): FakeClass[] {
  return Object.values(fakeClasses);
}

export function fakeClassesOnSchoolYearAsArray(
  schoolYear: number,
): FakeClass[] {
  return fakeClassesAsArray().filter((c) => c.schoolYear === schoolYear);
}

export function fakeClassesOnSchoolYear(schoolYear: number): {
  [classId in FakeClassId]: FakeClass;
} {
  return createTypedObjectFromEntries(
    fakeClassesOnSchoolYearAsArray(schoolYear).map(
      (c): [FakeClassId, FakeClass] => [c.classId, c],
    ),
  );
}
