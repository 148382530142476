
import { computed, defineComponent, PropType } from "vue";
import { ColorLabel, labelToColor } from "@/ts/objects/value/color-label";
import {
  DateValue,
  dateValueToDisplayValue,
} from "@/ts/objects/value/date-value";
import BaseDatePicker from "@/components/other/BaseDatePicker/BaseDatePicker.vue";
import { hasValue } from "@/ts/utils/common-util";

export default defineComponent({
  name: "EditableItemDate",
  components: { BaseDatePicker },
  props: {
    value: { type: Object as PropType<DateValue>, required: false },
    /**
     * 編集可能状態であればtrue。
     */
    editable: { type: Boolean, required: true },
    /**
     * クリア可能かどうか。（クリア用の×ボタンを表示するかどうか。）
     */
    clearable: { type: Boolean, required: false, default: false },

    placeholder: { type: String, required: false, default: "" },

    paddingTop: { type: Number, required: false, default: 4 },
    paddingRight: { type: Number, required: false, default: 4 },
    paddingBottom: { type: Number, required: false, default: 4 },
    paddingLeft: { type: Number, required: false, default: 4 },
    fontSize: { type: Number, required: false, default: 14 },
    fontColor: {
      type: String as PropType<ColorLabel>,
      required: false,
      default: "black",
    },

    /**
     * trueにした場合、
     * editableがfalseの間、入力ボックスを消して、中身の値だけを普通のテキストのように表示する。
     */
    hideBoxOnDisable: { type: Boolean, required: false, default: true },
    alignOnReadonly: {
      type: String as PropType<"center" | "flex-start" | "flex-end">,
      required: false,
      default: "center",
    },

    onInput: {
      type: Function as PropType<(value: DateValue | null) => void>,
      required: true,
    },
  },
  setup(props) {
    return {
      displayValue: computed(() =>
        hasValue(props.value) ? dateValueToDisplayValue(props.value) : "",
      ),
      onInputInternal: (value: DateValue) => {
        if (!props.editable) return;
        props.onInput(value);
      },

      styles: {
        "--padding-top": `${props.paddingTop}px`,
        "--padding-right": `${props.paddingRight}px`,
        "--padding-bottom": `${props.paddingBottom}px`,
        "--padding-left": `${props.paddingLeft}px`,
        "--font-size": `${props.fontSize}px`,
        "--color": labelToColor(props.fontColor),
        "--align-on-readonly": props.alignOnReadonly,
      },
    };
  },
});
