
import SearchConditionPopupFrame from "@/components/search-condition/SearchConditionPopupFrame/SearchConditionPopupFrame.vue";
import { defineComponent, PropType, ref } from "vue";
import SchoolYearSwitch from "@/components/other/SchoolYearSwitch/SchoolYearSwitch.vue";

export default defineComponent({
  name: "SearchConditionPopupSchoolYear",
  components: { SchoolYearSwitch, SearchConditionPopupFrame },
  props: {
    schoolYear: { type: Number, required: true },

    /**
     * キャンセルして閉じる。
     * 不正な値で確定しようとしたときも、こちらが呼ばれる。
     */
    cancel: { type: Function as PropType<() => void>, required: true },
    /**
     * 確定して閉じる。
     */
    confirm: {
      type: Function as PropType<(schoolYear: number) => void>,
      required: true,
    },
  },
  setup(props) {
    const schoolYearInternal = ref<number>(props.schoolYear);

    const confirmInternal = () => {
      const schoolYear = schoolYearInternal.value;
      if (isNaN(schoolYear) || schoolYear < 1 || schoolYear > 9999) {
        props.cancel();
        return;
      }
      props.confirm(schoolYear);
    };

    return {
      schoolYearInternal,
      confirmInternal,
      onChange: (value: number) => {
        schoolYearInternal.value = value;
      },
    };
  },
});
