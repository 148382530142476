import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createBlock(_component_BaseButton, {
    label: _ctx.label,
    color: "black",
    "background-color": "light-gray",
    "icon-padding-bottom": 1,
    "border-width": 0,
    width: _ctx.width,
    height: _ctx.height,
    "padding-horizontal": 10,
    "border-radius": 4,
    "font-size": 13,
    bold: false,
    enabled: _ctx.enabled,
    "on-click": _ctx.onClick
  }, null, 8, ["label", "width", "height", "enabled", "on-click"]))
}