import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fa3947b4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "label-area" }
const _hoisted_2 = { class: "label" }
const _hoisted_3 = { class: "slot-area" }
const _hoisted_4 = { class: "button-area" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_PopupConfirmButton = _resolveComponent("PopupConfirmButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: "search-condition-popup-frame-container",
    style: _normalizeStyle(_ctx.styles)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.label), 1),
      _createVNode(_component_font_awesome_icon, {
        class: "icon",
        icon: ['fas', 'chevron-up'],
        onClick: _ctx.onClickCancel
      }, null, 8, ["onClick"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_PopupConfirmButton, {
        class: "confirm-button",
        label: _ctx.confirmButtonLabel,
        width: _ctx.buttonWidth,
        height: 30,
        "on-click": _ctx.onClickConfirm
      }, null, 8, ["label", "width", "on-click"])
    ])
  ], 4))
}