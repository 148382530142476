import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_ctx.editing)
    ? (_openBlock(), _createBlock(_component_BaseButton, {
        key: 0,
        label: "Save",
        color: "white",
        "background-color": "light-blue",
        icon: "save",
        "icon-padding-bottom": 1,
        "border-color": "blue",
        "border-width": 2,
        width: 90,
        height: 28,
        "padding-horizontal": 12,
        enabled: _ctx.enabled,
        "on-click": _ctx.onClickSave
      }, null, 8, ["enabled", "on-click"]))
    : (_openBlock(), _createBlock(_component_BaseButton, {
        key: 1,
        label: "Edit",
        color: "white",
        "background-color": "blue-green",
        icon: "edit",
        "icon-padding-bottom": 1,
        "border-color": "green",
        "border-width": 2,
        width: 90,
        height: 28,
        "padding-horizontal": 12,
        enabled: _ctx.enabled,
        "on-click": _ctx.onClickEdit
      }, null, 8, ["enabled", "on-click"]))
}