import { defineStore } from "pinia";
import {
  applyResult,
  LoadableData,
  loadableDataNull,
  toLoading,
} from "@/ts/app/loadable-data";
import { Curriculum } from "@/ts/objects/entity/curriculum";
import { isNullish } from "@/ts/utils/common-util";
import { CurriculumService } from "@/ts/services/curriculum-service";
import { composeResults, Result } from "@/ts/app/result";

export const useCurriculumStore = defineStore("curriculum", {
  state: (): {
    schoolYearToCurriculums: {
      [schoolYear: number]: LoadableData<Curriculum[]>;
    };
  } => ({
    schoolYearToCurriculums: {},
  }),
  actions: {
    clearCurriculums(schoolYear?: number) {
      if (isNullish(schoolYear)) {
        this.schoolYearToCurriculums = {};
      } else {
        this.schoolYearToCurriculums[schoolYear] = loadableDataNull();
      }
    },
    async fetchCurriculums(
      curriculumService: CurriculumService,
      schoolYear: number,
    ) {
      this.schoolYearToCurriculums[schoolYear] = toLoading(
        this.schoolYearToCurriculums[schoolYear],
      );

      const [necResult, eecResult] = await Promise.all([
        curriculumService.listCurriculums("numeric", schoolYear, null),
        curriculumService.listCurriculums("essay", schoolYear, null),
      ]);
      const result: Result<Curriculum[]> = composeResults(
        necResult,
        eecResult,
        (necs, eecs) => [...necs, ...eecs],
      );

      this.schoolYearToCurriculums[schoolYear] = applyResult(
        this.schoolYearToCurriculums[schoolYear],
        result,
      );
    },
  },
});
