
import { computed, defineComponent } from "vue";

export default defineComponent({
  name: "SearchResultNumbers",
  props: {
    /**
     * 現在のページ番号。
     */
    pageNumber: { type: Number, required: true },
    /**
     * 結果の全件数。
     */
    numResults: { type: Number, required: true },
  },
  setup(props) {
    return {
      s: computed(() => (props.numResults === 1 ? "" : "s")),
    };
  },
});
