import {
  Guardian,
  GuardianNotes,
  GuardianProfile,
} from "@/ts/objects/entity/guardian";
import { fakeStudentBasicInfos } from "@/ts/objects/entity/student-fake";

export function createFakeGuardian(partial: Partial<Guardian> = {}): Guardian {
  const userId = partial?.userId ?? "guardian000";

  const defaultUserInfo = {
    userType: "guardian",
    googleMail: `${userId}@example.com`,
    photoUrl: "https://avatars.dicebear.com/api/avataaars/j6.svg",
    name: "瀬戸 空太郎",
  } as const;

  const defaultGuardianProfile: GuardianProfile = {
    children: [],

    // Userのnameが、name1。
    nameKana1: "せと そらたろう",
    familyName1: "瀬戸",
    givenName1: "空太郎",
    name2: "瀬戸 空子",
    nameKana2: "せと そらこ",
    familyName2: "瀬戸",
    givenName2: "空子",
    email1: `${userId}-1@gmail.com`,
    email2: `${userId}-2@gmail.com`,
    mobilePhone1: "09000000001",
    mobilePhone2: "09000000002",
    landlinePhone1: "0600000001",
    landlinePhone2: "0600000002",
    job1: "漁師",
    job2: "大学教授",
    workPlace1: "海",
    workPlace2: "東京大学",
    pictureGcsUrl1: `https://example.com/pictures/${userId}-1`,
    pictureGcsUrl2: `https://example.com/pictures/${userId}-2`,
    emergencyContactRelationship1: "祖父",
    emergencyContactPhone1: "09000000011",
    emergencyContactRelationship2: "祖母",
    emergencyContactPhone2: "09000000012",
    emergencyContactRelationship3: "叔母",
    emergencyContactPhone3: "09000000013",
  };

  const defaultGuardianNotes: GuardianNotes = {
    note01: "備考01",
    note02: "備考02",
    note03: "備考03",
    note04: "備考04",
    note05: "備考05",
    note06: "備考06",
    note07: "備考07",
    note08: "備考08",
    note09: "備考09",
    note10: "備考10",
  };

  return {
    userId,
    ...defaultUserInfo,
    ...defaultGuardianProfile,
    ...defaultGuardianNotes,
    ...partial,
  };
}

export const fakeGuardianBasicInfos = {
  guardian000: {
    userId: "guardian000",
    googleMail: "guardian000@example.com",
    userType: "guardian",
    name: "瀬戸 空太郎",
    photoUrl: "https://avatars.dicebear.com/api/avataaars/j6.svg",
  },
  guardian001: {
    userId: "guardian001",
    googleMail: "guardian001@example.com",
    userType: "guardian",
    name: "山口 花子",
    photoUrl: "https://avatars.dicebear.com/api/avataaars/j6xz.svg",
  },
  guardian002: {
    userId: "guardian002",
    googleMail: "guardian002@example.com",
    userType: "guardian",
    name: "",
    photoUrl: "https://avatars.dicebear.com/api/avataaars/t.svg",
  },
  guardian003: {
    userId: "guardian003",
    googleMail: "guardian003@example.com",
    userType: "guardian",
    name: "Big John Doe",
    photoUrl: "https://avatars.dicebear.com/api/avataaars/9xeaq.svg",
  },
  guardian004: {
    userId: "guardian004",
    googleMail: "guardian004@example.com",
    userType: "guardian",
    name: "Lonely Guardian",
    photoUrl: "https://avatars.dicebear.com/api/avataaars/q.svg",
  },
} as const;

export function fakeGuardians(): {
  [userId in keyof typeof fakeGuardianBasicInfos]: Guardian;
} {
  return {
    guardian000: createFakeGuardian({
      ...fakeGuardianBasicInfos.guardian000,
      children: [fakeStudentBasicInfos.student000],
    }),
    guardian001: createFakeGuardian({
      ...fakeGuardianBasicInfos.guardian001,
      children: [fakeStudentBasicInfos.student001],
    }),
    guardian002: createFakeGuardian({
      ...fakeGuardianBasicInfos.guardian002,
      children: [fakeStudentBasicInfos.student002],
    }),
    guardian003: createFakeGuardian({
      ...fakeGuardianBasicInfos.guardian003,
      children: [
        fakeStudentBasicInfos.student003,
        fakeStudentBasicInfos.student004,
        fakeStudentBasicInfos.student005,
        fakeStudentBasicInfos.student006,
        fakeStudentBasicInfos.student007,
        fakeStudentBasicInfos.student008,
        fakeStudentBasicInfos.student009,
      ],
    }),
    guardian004: createFakeGuardian({
      ...fakeGuardianBasicInfos.guardian004,
      children: [],
    }),
  };
}
