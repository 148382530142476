
import { defineComponent, PropType } from "vue";
import UserListTableCellNormal from "@/components/table/list/UserListTableCell/UserListTableCellNormal/UserListTableCellNormal.vue";
import { UserColumn } from "@/ts/app/columns/def/user-column";
import { ObjectInternalName } from "@/ts/app/object-name";
import { AnyUser } from "@/ts/objects/entity/user";
import UserListTableCellDate from "@/components/table/list/UserListTableCell/UserListTableCellDate/UserListTableCellDate.vue";
import UserListTableCellImage from "@/components/table/list/UserListTableCell/UserListTableCellImage/UserListTableCellImage.vue";
import UserListTableCellUser from "@/components/table/list/UserListTableCell/UserListTableCellUser/UserListTableCellUser.vue";
import UserListTableCellUsers from "@/components/table/list/UserListTableCell/UserListTableCellUsers/UserListTableCellUsers.vue";
import UserListTableCellTeacherClasses from "@/components/table/list/UserListTableCell/UserListTableCellTeacherClasses/UserListTableCellTeacherClasses.vue";

export default defineComponent({
  name: "UserListTableCell",
  components: {
    UserListTableCellTeacherClasses,
    UserListTableCellUsers,
    UserListTableCellUser,
    UserListTableCellImage,
    UserListTableCellDate,
    UserListTableCellNormal,
  },
  props: {
    userColumn: {
      type: Object as PropType<UserColumn<ObjectInternalName, AnyUser, any>>,
      required: true,
    },

    width: { type: Number, required: true },
    minHeight: { type: Number, required: true },
    maxHeight: { type: Number, required: true },

    /**
     * trueなら、ユーザー項目などをリンク化する。
     */
    linkEnabled: { type: Boolean, required: true },
  },
  setup(props) {
    return {
      styles: {
        "--width": `${props.width}px`,
        "--min-height": `${props.minHeight}px`,
        "--max-height": `${props.maxHeight}px`,
      },
    };
  },
});
