import { Grade, gradeFromRespOrError } from "@/ts/objects/value/grade";
import { StudentClass as StudentClassResp } from "@/ts/api/user-service";
import { Class } from "@/ts/objects/entity/class";

/**
 * 児童生徒クラス。
 */
export type StudentClass = {
  readonly classId: string;

  readonly schoolYear: number;
  readonly grade: Grade;
  readonly classNo: number; // (年度, 学年)内での、並び順。ゼロ始まり。
  readonly name: string;

  readonly studentNumber: number | null;
};

export function studentClassFromRespOrError(r: StudentClassResp): StudentClass {
  return {
    classId: r.classId,

    schoolYear: r.schoolYear,
    grade: gradeFromRespOrError(r.grade),
    classNo: r.orderNum,
    name: r.name,

    studentNumber: r.studentNumber ?? null,
  };
}

export function studentClassToClass(sc: StudentClass): Class {
  return {
    classId: sc.classId,

    schoolYear: sc.schoolYear,
    grade: sc.grade,
    classNo: sc.classNo,
    name: sc.name,
  };
}

export function getClassOfSchoolYear(
  classes: readonly StudentClass[],
  schoolYear: number,
): StudentClass | null {
  return classes.find((c) => c.schoolYear === schoolYear) ?? null;
}
