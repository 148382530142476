import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1a38b7ac"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["placeholder"]
const _hoisted_2 = { class: "selected-value" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["selectable-search-box-container", { focused: _ctx.focused }]),
    style: _normalizeStyle(_ctx.styles)
  }, [
    (_ctx.selectedValue == null)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _withDirectives(_createElementVNode("input", {
            type: "text",
            class: "input",
            placeholder: _ctx.placeholder,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.text) = $event)),
            onFocus: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onFocusInternal && _ctx.onFocusInternal(...args))),
            onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onBlurInternal && _ctx.onBlurInternal(...args)))
          }, null, 40, _hoisted_1), [
            [_vModelText, _ctx.text]
          ]),
          (_ctx.hasAnyText)
            ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                key: 0,
                class: "icon icon--reset",
                icon: ['fas', 'times'],
                onClick: _ctx.resetText
              }, null, 8, ["onClick"]))
            : (_openBlock(), _createBlock(_component_font_awesome_icon, {
                key: 1,
                class: "icon icon--search",
                icon: ['fas', 'search']
              }))
        ], 64))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.selectedValue), 1),
          _createVNode(_component_font_awesome_icon, {
            class: "icon icon--reset",
            icon: ['fas', 'times'],
            onClick: _ctx.resetText
          }, null, 8, ["onClick"])
        ], 64))
  ], 6))
}