import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0c1840b8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchConditionRadio = _resolveComponent("SearchConditionRadio")!

  return (_openBlock(), _createBlock(_component_SearchConditionRadio, {
    class: "search-condition-radio-simple-container",
    "radio-group-name": _ctx.radioGroupName,
    "radio-value": _ctx.radioValue,
    "selected-radio-value": _ctx.selectedRadioValue,
    "on-select": _ctx.onSelect
  }, {
    default: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.text), 1)
    ]),
    _: 1
  }, 8, ["radio-group-name", "radio-value", "selected-radio-value", "on-select"]))
}