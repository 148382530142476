import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EditableItemDate = _resolveComponent("EditableItemDate")!

  return (_openBlock(), _createBlock(_component_EditableItemDate, {
    class: "term-edit-table-cell-content-container",
    value: _ctx.date,
    editable: _ctx.editing,
    "on-input": _ctx.onChangeTerm,
    clearable: true,
    "padding-left": 4,
    "padding-right": 0,
    "font-size": 13,
    placeholder: _ctx.placeholder
  }, null, 8, ["value", "editable", "on-input", "placeholder"]))
}