import { isUserType, UserType } from "@/ts/objects/value/user-type";

export function userEditPagePath(userType: UserType, userId: string): string {
  return `/${userType}/edit/${userId}`;
}

export function userEditPagePathOrHome(
  userType: UserType | null | undefined,
  userId: string,
): string {
  if (isUserType(userType)) return userEditPagePath(userType, userId);
  return "/home";
}

export function classDetailsPagePath(classId: string): string {
  return `/class/details/${classId}`;
}
