
import { computed, defineComponent, PropType, ref } from "vue";
import TeacherClassesSelectPopup from "@/components/popups/TeacherClassesSelectPopup/TeacherClassesSelectPopup.vue";
import { ColorLabel, labelToColor } from "@/ts/objects/value/color-label";
import { TeacherClass } from "@/ts/objects/value/teacher-class";
import TeacherClassText from "@/components/texts/TeacherClassText/TeacherClassText.vue";

export default defineComponent({
  name: "EditableItemTeacherClasses",
  components: { TeacherClassText, TeacherClassesSelectPopup },
  props: {
    value: { type: Array as PropType<TeacherClass[]>, required: true },
    /**
     * 編集可能状態であればtrue。
     */
    editable: { type: Boolean, required: true },

    paddingTop: { type: Number, required: false, default: 4 },
    paddingRight: { type: Number, required: false, default: 4 },
    paddingBottom: { type: Number, required: false, default: 4 },
    paddingLeft: { type: Number, required: false, default: 4 },
    fontSize: { type: Number, required: false, default: 14 },
    fontColor: {
      type: String as PropType<ColorLabel>,
      required: false,
      default: "black",
    },

    /**
     * trueにした場合、
     * editableがfalseの間、入力ボックスを消して、中身の値だけを普通のテキストのように表示する。
     */
    hideBoxOnDisable: { type: Boolean, required: false, default: true },
    /**
     * trueにした場合、
     * editableがfalseの間、クラス詳細ページへのリンクになる。
     */
    isLinkOnDisable: { type: Boolean, required: false, default: false },

    promptText: {
      type: String,
      required: false,
      default: "ユーザーを選択します。",
    },
    onInput: {
      type: Function as PropType<(classes: TeacherClass[]) => void>,
      required: true,
    },
  },
  setup(props) {
    const active = ref(false);

    const openPopup = () => {
      if (!props.editable) return;
      active.value = true;
    };
    const closePopup = () => {
      active.value = false;
    };
    const onConfirm = (classes: TeacherClass[]) => {
      active.value = false;
      props.onInput(classes);
    };

    return {
      active,

      openPopup,
      closePopup,
      onConfirm,

      styles: computed(() => ({
        "--padding-top": `${props.paddingTop}px`,
        "--padding-right": `${props.paddingRight}px`,
        "--padding-bottom": `${props.paddingBottom}px`,
        "--padding-left": `${props.paddingLeft}px`,
        "--font-size": `${props.fontSize}px`,
        "--color": labelToColor(props.fontColor),
      })),
    };
  },
});
