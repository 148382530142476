import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8d81d3b8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "icon-cell" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "name-cell" }
const _hoisted_4 = {
  key: 1,
  class: "name-button"
}
const _hoisted_5 = { class: "value-cells-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_UserListTableCell = _resolveComponent("UserListTableCell")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["user-list-table-row-container", `style-type-${_ctx.styleType}`]),
    style: _normalizeStyle(_ctx.styles)
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["row-header", `style-type-${_ctx.styleType}`])
    }, [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.photoUrl !== '')
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              class: "icon",
              src: _ctx.photoUrl,
              alt: "user icon"
            }, null, 8, _hoisted_2))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.linkEnabled && _ctx.editPath != null)
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 0,
              class: "name-button",
              to: _ctx.editPath
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.displayName.value), 1)
              ]),
              _: 1
            }, 8, ["to"]))
          : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.displayName.value), 1))
      ])
    ], 2),
    _createElementVNode("div", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.shownColumns, (col) => {
        return (_openBlock(), _createBlock(_component_UserListTableCell, {
          key: col.id,
          class: "cell",
          "user-column": col,
          width: col.width,
          "min-height": _ctx.minHeight,
          "max-height": _ctx.maxHeight,
          "link-enabled": _ctx.linkEnabled
        }, null, 8, ["user-column", "width", "min-height", "max-height", "link-enabled"]))
      }), 128))
    ])
  ], 6))
}