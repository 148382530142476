import log, { LogLevelDesc } from "loglevel";

export function getLogLevel(): string {
  switch (log.getLevel()) {
    case 0:
      return "trace";
    case 1:
      return "debug";
    case 2:
      return "info";
    case 3:
      return "warn";
    case 4:
      return "error";
    case 5:
      return "silent";
    default:
      return "unknown-level";
  }
}

/**
 * ブラウザの開発者ツールから、 setLogLevel("debug") などでログレベルを変更するための関数。
 * @param level
 */
function setLogLevel(level: LogLevelDesc) {
  log.setLevel(level);
  console.log(`log level was set to "${getLogLevel()}"`);
}

(window as any).getLogLevel = getLogLevel;
(window as any).setLogLevel = setLogLevel;
