import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0096e7aa"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "search-results-popup"
}
const _hoisted_2 = {
  key: 0,
  class: "search-results-notfound"
}
const _hoisted_3 = {
  key: 1,
  class: "search-result-rows"
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 1,
  class: "user-icon"
}
const _hoisted_7 = { class: "user-name" }
const _hoisted_8 = { class: "google-mail" }
const _hoisted_9 = { class: "user-id" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SelectableSearchBox = _resolveComponent("SelectableSearchBox")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: "user-search-box-container",
    style: _normalizeStyle(_ctx.styles)
  }, [
    _createVNode(_component_SelectableSearchBox, {
      "selected-value": _ctx.selectedUserName,
      width: _ctx.width,
      height: _ctx.height,
      "font-size": 14,
      "placeholder-font-size": _ctx.placeholderFontSize,
      placeholder: _ctx.placeholder,
      "border-color": _ctx.borderColor,
      "border-color-on-focus": _ctx.borderColorOnFocus,
      "on-focus": _ctx.onFocus,
      "on-input": _ctx.onInput,
      "on-reset": _ctx.onClickReset
    }, null, 8, ["selected-value", "width", "height", "placeholder-font-size", "placeholder", "border-color", "border-color-on-focus", "on-focus", "on-input", "on-reset"]),
    (_ctx.focused && _ctx.selectedUser == null && _ctx.searchResults != null)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.searchResults.length === 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, " 該当ユーザーが見つかりません。 "))
            : (_openBlock(), _createElementBlock("ul", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.searchResults, (user) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: user.userId,
                    class: "search-result-row",
                    onClick: () => _ctx.onSelectUserInternal(user)
                  }, [
                    (user.photoUrl != null && user.photoUrl !== '')
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 0,
                          class: "user-icon",
                          src: user.photoUrl,
                          alt: "user icon"
                        }, null, 8, _hoisted_5))
                      : (_openBlock(), _createElementBlock("div", _hoisted_6)),
                    _createElementVNode("span", _hoisted_7, _toDisplayString(user.name), 1),
                    _createElementVNode("span", _hoisted_8, _toDisplayString(user.googleMail), 1),
                    _createElementVNode("span", _hoisted_9, _toDisplayString(user.userId), 1)
                  ], 8, _hoisted_4))
                }), 128))
              ]))
        ]))
      : _createCommentVNode("", true)
  ], 4)), [
    [_directive_click_outside, _ctx.onClickOutside]
  ])
}