import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-46f5a672"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main-menu-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainMenuItem = _resolveComponent("MainMenuItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pages, (page) => {
      return (_openBlock(), _createBlock(_component_MainMenuItem, {
        key: page.name,
        icon: page.icon,
        label: page.label,
        active: _ctx.activePageName != null && page.name === _ctx.activePageName,
        "link-path": `/${page.name}`
      }, null, 8, ["icon", "label", "active", "link-path"]))
    }), 128))
  ]))
}