
import { defineComponent, PropType } from "vue";
import TeacherSubPageHeading from "@/views/teacher/_components/TeacherSubPageHeading/TeacherSubPageHeading.vue";
import UploadArea from "@/components/other/UploadArea/UploadArea.vue";
import ConfirmImportButton from "@/components/buttons/ConfirmImportButton/ConfirmImportButton.vue";
import TeacherListTable from "@/views/teacher/_components/TeacherListTable/TeacherListTable.vue";
import TeacherColumnsSelect from "@/views/teacher/_components/TeacherColumnsSelect/TeacherColumnsSelect.vue";
import { LoadableData } from "@/ts/app/loadable-data";
import { Teacher } from "@/ts/objects/entity/teacher";
import {
  TeacherColumnVisibility,
  TeacherColumnVisibilityAll,
} from "@/ts/app/columns/visibility/teacher-column-visibility";
import { names } from "@/ts/app/object-name";
import LoadingBlock from "@/components/loading/LoadingBlock/LoadingBlock.vue";

export default defineComponent({
  name: "TeacherImport",
  components: {
    LoadingBlock,
    TeacherColumnsSelect,
    TeacherListTable,
    ConfirmImportButton,
    UploadArea,
    TeacherSubPageHeading,
  },
  props: {
    schoolYear: { type: Number, default: null, required: false },
    saving: { type: Boolean, required: true },
    teachers: {
      type: Object as PropType<LoadableData<Teacher[]>>,
      required: true,
    },

    onSelectFile: {
      type: Function as PropType<(file: File) => void>,
      required: true,
    },
    onUnselectFile: {
      type: Function as PropType<() => void>,
      required: true,
    },

    columnVisibility: {
      type: Object as PropType<TeacherColumnVisibilityAll>,
      required: true,
    },
    isColumnSelectOpen: { type: Boolean, required: true },
    toggleColumnSelectVisibility: {
      type: Function as PropType<() => void>,
      required: true,
    },
    onChangeColumnVisibility: {
      type: Function as PropType<(v: TeacherColumnVisibility) => void>,
      required: true,
    },

    onConfirmImport: { type: Function as PropType<() => void>, required: true },

    onClickBack: { type: Function as PropType<() => void>, required: true },
  },
  setup(props) {
    return {
      onUnselectFileInternal: () => {
        props.onUnselectFile();
      },

      googleMailDisplayName: names.googleMail.d,
      classDisplayName: names.class.d,
      classesDisplayName: names.classes.d,
    };
  },
});
