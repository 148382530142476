import { User } from "@/ts/objects/entity/user";
import {
  TeacherClass,
  teacherClassFromRespOrError,
} from "@/ts/objects/value/teacher-class";
import {
  TeacherType,
  teacherTypeFromStringOrError,
} from "@/ts/objects/value/teacher-type";
import { Teacher as TeacherResp, TeacherWrite } from "@/ts/api/user-service";
import { pick } from "lodash";
import { dropAllUndefinedFields } from "@/ts/utils/common-util";

export type Teacher = { readonly userType: "teacher" } & User & TeacherProfile;

export type TeacherProfile = {
  readonly classes: readonly TeacherClass[];

  readonly nameKana: string;
  readonly familyName: string;
  readonly givenName: string;

  readonly teacherType: TeacherType;
  readonly zipcode: string;
  readonly address: string;
  readonly email: string;
  readonly mobilePhone: string;
  readonly landlinePhone: string;
  readonly foreignType: string;
};

export function teacherFromRespOrError(r: TeacherResp): Teacher {
  return {
    userType: "teacher",

    userId: r.userId,
    googleMail: r.googleMail,
    photoUrl: r.photoUrl,
    name: r.name,

    classes: r.classes.map(teacherClassFromRespOrError),

    nameKana: r.nameKana,
    familyName: r.familyName,
    givenName: r.givenName,

    teacherType: teacherTypeFromStringOrError(r.teacherType),
    zipcode: r.zipcode,
    address: r.address,
    email: r.email,
    mobilePhone: r.mobilePhone,
    landlinePhone: r.landlinePhone,
    foreignType: r.foreignType,
  };
}

export function teacherToReq(t: Partial<Teacher>): TeacherWrite {
  const req = {
    name: t.name,

    classes: t.classes?.map((c) => ({
      classId: c.classId,
      inChargeType: c.inChargeType,
    })),

    nameKana: t.nameKana,
    familyName: t.familyName,
    givenName: t.givenName,

    teacherType: t.teacherType,
    zipcode: t.zipcode,
    address: t.address,
    email: t.email,
    mobilePhone: t.mobilePhone,
    landlinePhone: t.landlinePhone,
    foreignType: t.foreignType,
  };
  return dropAllUndefinedFields(req);
}

export type UpdatingTeacher = {
  readonly teacher: Teacher;
  readonly updatingFields: (keyof Teacher)[];
};

export function updatingTeacherToPartial(
  t: UpdatingTeacher,
): Partial<Teacher> & { readonly userId: string } {
  const partialTeacher: Partial<Teacher> = pick(t.teacher, t.updatingFields);
  return { ...partialTeacher, userId: t.teacher.userId };
}

export function teacherToBasicUserInfo(t: Teacher): User {
  return {
    userId: t.userId,
    googleMail: t.googleMail,
    userType: "teacher",
    name: t.name,
    photoUrl: t.photoUrl,
  };
}
