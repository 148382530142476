
import { defineComponent, PropType } from "vue";
import { DropdownItem } from "@/ts/app/dropdown-item";

export default defineComponent({
  name: "BaseCustomDropdownOptions",
  props: {
    items: { type: Array as PropType<DropdownItem[]>, required: true },

    zIndex: { type: Number, default: 10, required: false },
    width: { type: Number, default: 120, required: false },

    onSelect: {
      type: Function as PropType<(value: string | null) => void>,
      required: true,
    },
  },
  setup(props) {
    return {
      styles: {
        "--z-index": props.zIndex,
        "--width": `${props.width}px`,
      },
    };
  },
});
