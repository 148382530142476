import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-08e25486"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "student-custom-column-edit-table-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StudentCustomColumnEditTableItem = _resolveComponent("StudentCustomColumnEditTableItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customColumnNamesInternal, ([id, name]) => {
      return (_openBlock(), _createBlock(_component_StudentCustomColumnEditTableItem, {
        key: id,
        class: "item",
        "custom-column-id": id,
        "custom-column-name": name,
        editing: _ctx.editing,
        "on-input": (value) => _ctx.onInputInternal(id, value)
      }, null, 8, ["custom-column-id", "custom-column-name", "editing", "on-input"]))
    }), 128))
  ]))
}