
import { defineComponent, PropType } from "vue";
import BaseButton from "@/components/buttons/BaseButton/BaseButton.vue";

export default defineComponent({
  name: "PopupConfirmButton",
  components: { BaseButton },
  props: {
    label: { type: String, default: "選択する" },

    width: { type: Number, default: 300 },
    height: { type: Number, default: 36 },

    enabled: { type: Boolean, default: true },
    onClick: { type: Function as PropType<() => void>, required: true },
  },
});
