
import { computed, defineComponent, PropType } from "vue";
import { SchoolType, schoolTypes } from "@/ts/objects/value/school-type";

export default defineComponent({
  name: "ClassDetailsTitle",
  props: {
    schoolType: { type: String as PropType<SchoolType>, required: true },
    gradeNumber: { type: Number, required: true },
    name: { type: String, required: true },
  },
  setup(props) {
    return {
      schoolTypeText: computed(() => {
        return schoolTypes[props.schoolType];
      }),
    };
  },
});
