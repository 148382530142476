import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3cfe89e0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "term-edit-header-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageHeading = _resolveComponent("PageHeading")!
  const _component_EditButtonSet = _resolveComponent("EditButtonSet")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PageHeading, {
      class: "page-heading",
      icon: "calendar-alt",
      label: "Year and Term"
    }),
    (_ctx.isAdmin)
      ? (_openBlock(), _createBlock(_component_EditButtonSet, {
          key: 0,
          class: "edit-button-set",
          editing: _ctx.editing,
          "on-click-edit": _ctx.onClickEdit,
          "on-click-save": _ctx.onClickSave,
          "on-click-cancel": _ctx.onClickCancel
        }, null, 8, ["editing", "on-click-edit", "on-click-save", "on-click-cancel"]))
      : _createCommentVNode("", true)
  ]))
}