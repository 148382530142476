
import { defineComponent, PropType } from "vue";
import {
  ClassListItemData,
  ClassListRowData,
  createNewClassListItemData,
} from "@/views/class/ClassList/class-list-data";
import ClassListTableRowAddButton from "@/views/class/ClassList/ClassListTable/ClassListTableRow/ClassListTableRowAddButton/ClassListTableRowAddButton.vue";
import ClassListTableRowItem from "@/views/class/ClassList/ClassListTable/ClassListTableRow/ClassListTableRowItem/ClassListTableRowItem.vue";
import { arrayMoveOne, hasValue, updateFirst } from "@/ts/utils/common-util";
import log from "loglevel";

export default defineComponent({
  name: "ClassListTableRow",
  components: { ClassListTableRowItem, ClassListTableRowAddButton },
  props: {
    theme: { type: Number as PropType<0 | 1>, required: true },
    headerHeight: { type: Number, default: 50 },
    itemHeight: { type: Number, default: 50 },

    data: { type: Object as PropType<ClassListRowData>, required: true },

    editing: { type: Boolean, required: true },

    onUpdate: {
      type: Function as PropType<(classes: ClassListItemData[]) => void>,
      required: true,
    },
  },
  setup(props) {
    const updateName = (clientSideId: string, name: string) => {
      if (!props.editing) return;

      const [updatedClasses] = updateFirst(
        props.data.classes,
        (c) => c.clientSideId === clientSideId,
        (c) => ({ ...c, name }),
      );
      props.onUpdate(updatedClasses);
    };
    const moveClass = (clientSideId: string, up: boolean) => {
      if (!props.editing) return;

      const updatedClasses = arrayMoveOne(
        props.data.classes,
        (c) => c.clientSideId === clientSideId,
        up,
      );
      props.onUpdate(updatedClasses);
    };
    const addClass = () => {
      if (!props.editing) return;

      const newClass = createNewClassListItemData("新しい");
      const updatedClasses = [...props.data.classes, newClass];
      props.onUpdate(updatedClasses);
    };
    const removeClass = (clientSideId: string) => {
      if (!props.editing) return;

      const removingClassIdx = props.data.classes.findIndex(
        (c) => c.clientSideId === clientSideId,
      );
      log.debug(
        `removingClassIdx=${removingClassIdx}, removingClass=${JSON.stringify(
          props.data.classes[removingClassIdx],
        )}`,
      );
      // 新規作成したばかりのクラスのみ削除可能。(classIdに値が入っているものは削除できない。)
      if (
        removingClassIdx < 0 ||
        hasValue(props.data.classes[removingClassIdx].classId)
      )
        return;
      const updatedClasses = props.data.classes.slice();
      updatedClasses.splice(removingClassIdx, 1);
      props.onUpdate(updatedClasses);
    };

    return {
      updateName,
      moveClass,
      addClass,
      removeClass,

      styles: {
        "--header-height": `${props.headerHeight}px`,
      },
    };
  },
});
