import { UserColumnDef } from "@/ts/app/columns/def/user-column-def";
import { Teacher } from "@/ts/objects/entity/teacher";
import {
  attachValueToCols,
  UserColumn,
} from "@/ts/app/columns/def/user-column";
import {
  TeacherProfileColumnDefGroup,
  teacherProfileColumnDefs,
  TeacherProfileColumnGroup,
  teacherProfileColumnGroupName,
  TeacherProfileColumnGroupName,
  TeacherProfileColumnId,
} from "@/ts/app/columns/def/teacher/teacher-profile-column";
import { createTypedObjectFromEntries } from "@/ts/utils/common-util";
import { ObjectInternalName } from "@/ts/app/object-name";

export type TeacherColumnGroupName = TeacherProfileColumnGroupName;

export type TeacherColumnId = TeacherProfileColumnId;

export type TeacherColumnDef<
  ColId extends ObjectInternalName,
  T,
> = UserColumnDef<ColId, Teacher, T>;

export type TeacherColumn<ColId extends ObjectInternalName, T> = UserColumn<
  ColId,
  Teacher,
  T
>;

export type TeacherColumnDefGroup = TeacherProfileColumnDefGroup;

export function getTeacherColumnDefs(
  schoolYear: number,
): TeacherColumnDef<TeacherColumnId, any>[] {
  return [...teacherProfileColumnDefs(schoolYear)];
}

export function getTeacherColumnDefsGrouped(schoolYear: number): {
  profileColumnDefGroup: TeacherProfileColumnDefGroup;
} {
  return {
    profileColumnDefGroup: {
      groupName: teacherProfileColumnGroupName,
      columns: teacherProfileColumnDefs(schoolYear),
    },
  };
}

export function getTeacherColumnDefsAsDict(
  schoolYear: number,
): Record<TeacherColumnId, TeacherColumnDef<TeacherColumnId, any>> {
  return createTypedObjectFromEntries(
    getTeacherColumnDefs(schoolYear).map(
      (col): [TeacherColumnId, TeacherColumnDef<TeacherColumnId, any>] => [
        col.id,
        col,
      ],
    ),
  );
}

export function getTeacherColumns(
  schoolYear: number,
  teacher: Teacher,
): TeacherColumn<TeacherColumnId, any>[] {
  const columnDefs = getTeacherColumnDefs(schoolYear);
  return attachValueToCols(columnDefs, teacher);
}

export function getTeacherColumnsGrouped(
  schoolYear: number,
  teacher: Teacher,
): {
  profileColumnGroup: TeacherProfileColumnGroup;
} {
  const { profileColumnDefGroup } = getTeacherColumnDefsGrouped(schoolYear);
  return {
    profileColumnGroup: {
      groupName: teacherProfileColumnGroupName,
      columns: attachValueToCols(profileColumnDefGroup.columns, teacher),
    },
  };
}
