import { Result } from "@/ts/app/result";
import { errors, ThrowableAppError } from "@/ts/app/error/app-error";
import { names, ObjectName } from "@/ts/app/object-name";
import { isNullish } from "@/ts/utils/common-util";

export const curriculumEvalTypes = {
  numeric: "数値評価",
  essay: "文書評価",
} as const;

/**
 * 教科評価タイプ。
 */
export type CurriculumEvalType = keyof typeof curriculumEvalTypes;
export type CurriculumEvalTypeDisplayValue =
  typeof curriculumEvalTypes[CurriculumEvalType];

export function isCurriculumEvalType(
  value: unknown,
): value is CurriculumEvalType {
  return (
    typeof value === "string" &&
    Object.keys(curriculumEvalTypes).some((v) => v === value)
  );
}

export function asCurriculumEvalType(
  value: unknown,
): Result<CurriculumEvalType> {
  if (isCurriculumEvalType(value)) return { ok: true, data: value };
  return {
    ok: false,
    error: errors.validationFailed.invalidValue(
      value,
      names.curriculumEvalType,
    ),
  };
}

export function asCurriculumEvalTypeOrNull(
  value: unknown,
): CurriculumEvalType | null {
  const result = asCurriculumEvalType(value);
  if (!result.ok) return null;
  return result.data;
}

export function asCurriculumEvalTypeOrError(
  value: unknown,
): CurriculumEvalType {
  const result = asCurriculumEvalType(value);
  if (!result.ok) throw new ThrowableAppError(result.error);
  return result.data;
}

export function curriculumEvalTypeToDisplayValue(
  value: CurriculumEvalType,
): CurriculumEvalTypeDisplayValue {
  return curriculumEvalTypes[value];
}

export function displayValueToCurriculumEvalType(
  displayValue: string,
  objectName: ObjectName = names.curriculumEvalType,
): Result<CurriculumEvalType> {
  const error = {
    ok: false,
    error: errors.validationFailed.invalidValue(displayValue, objectName),
  } as const;

  const kv = Object.entries(curriculumEvalTypes).find(
    ([_k, v]) => v === displayValue,
  );
  if (isNullish(kv)) return error;
  const k = kv[0];
  if (!isCurriculumEvalType(k)) return error;
  return { ok: true, data: k };
}

export function displayValueToCurriculumEvalTypeOrNull(
  displayValue: string,
): CurriculumEvalType | null {
  const result = displayValueToCurriculumEvalType(displayValue);
  if (!result.ok) return null;
  return result.data;
}

export function displayValueToCurriculumEvalTypeOrError(
  displayValue: string,
  objectName: ObjectName = names.curriculumEvalType,
): CurriculumEvalType {
  const result = displayValueToCurriculumEvalType(displayValue, objectName);
  if (!result.ok) throw new ThrowableAppError(result.error);
  return result.data;
}

export function curriculumEvalTypeFromStringOrError(
  value: string,
): CurriculumEvalType {
  if (isCurriculumEvalType(value)) {
    return value;
  }
  throw new Error(
    `curriculumEvalTypeFromStringOrError: invalid curriculum eval type value: ${value}`,
  );
}
