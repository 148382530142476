import { names } from "@/ts/app/object-name";
import { GuardianColumnVisibilityAll } from "@/ts/app/columns/visibility/guardian-column-visibility";
import { typedObjectEntries } from "@/ts/utils/common-util";

export const guardianListProfileColumns = [
  names.userId,
  names.googleMail,

  names.pictureGcsUrl1,
  names.name1,
  names.nameKana1,
  names.familyName1,
  names.givenName1,
  names.email1,
  names.mobilePhone1,
  names.landlinePhone1,
  names.job1,
  names.workPlace1,

  names.pictureGcsUrl2,
  names.name2,
  names.nameKana2,
  names.familyName2,
  names.givenName2,
  names.email2,
  names.mobilePhone2,
  names.landlinePhone2,
  names.job2,
  names.workPlace2,

  names.children,

  names.emergencyContactRelationship1,
  names.emergencyContactPhone1,
  names.emergencyContactRelationship2,
  names.emergencyContactPhone2,
  names.emergencyContactRelationship3,
  names.emergencyContactPhone3,
] as const;

export const guardianListProfileColumnIds = guardianListProfileColumns.map(
  (c) => c.i,
);

export type GuardianListProfileColumnId =
  typeof guardianListProfileColumnIds[number];

export const guardianListNotesColumns = [
  names.note01,
  names.note02,
  names.note03,
  names.note04,
  names.note05,
  names.note06,
  names.note07,
  names.note08,
  names.note09,
  names.note10,
] as const;

export const guardianListNotesColumnIds = guardianListNotesColumns.map(
  (c) => c.i,
);

export type GuardianListNotesColumnId =
  typeof guardianListNotesColumnIds[number];

export const guardianListColumns = [
  ...guardianListProfileColumns,
  ...guardianListNotesColumns,
] as const;

export const guardianListColumnIds = guardianListColumns.map((c) => c.i);

export type GuardianListColumnId = typeof guardianListColumnIds[number];

export function guardianColumnVisibilityToListColumnIds(
  v: GuardianColumnVisibilityAll,
): GuardianListColumnId[] {
  const profileColumns = typedObjectEntries(v.profileColumnVisibility.state);
  const notesColumns = typedObjectEntries(v.notesColumnVisibility.state);

  const checkedColIds = [...profileColumns, ...notesColumns]
    .filter(([_, checked]) => checked)
    .map(([colId, _]) => colId);

  // 並び順を正しくするため、filterし直す。
  return [
    ...guardianListProfileColumnIds,
    ...guardianListNotesColumnIds,
  ].filter((colId) => checkedColIds.includes(colId));
}
