
import { computed, defineComponent, PropType } from "vue";
import { ColorLabel, labelToColor } from "@/ts/objects/value/color-label";
import { SelectOption } from "@/ts/app/select-option";
import { isNullish } from "@/ts/utils/common-util";

export default defineComponent({
  name: "EditableItemEnum",
  props: {
    value: { type: String, required: true },
    options: { type: Array as PropType<SelectOption[]>, required: true },
    /**
     * 編集可能状態であればtrue。
     */
    editable: { type: Boolean, required: true },

    paddingTop: { type: Number, default: 4, required: false },
    paddingRight: { type: Number, default: 4, required: false },
    paddingBottom: { type: Number, default: 4, required: false },
    paddingLeft: { type: Number, default: 4, required: false },
    fontSize: { type: Number, default: 14 },
    fontColor: { type: String as PropType<ColorLabel>, default: "black" },
    optionFontSize: { type: Number, default: 13 },
    optionFontColor: { type: String as PropType<ColorLabel>, default: "black" },

    /**
     * trueにした場合、
     * editableがfalseの間、入力ボックスを消して、中身の値だけを普通のテキストのように表示する。
     */
    hideBoxOnDisable: { type: Boolean, default: true },

    onInput: {
      type: Function as PropType<(value: string) => void>,
      required: true,
    },
  },
  setup(props) {
    const selectedName = computed(
      () => props.options.find((opt) => opt.value === props.value)?.name ?? "",
    );
    const possibleValues = computed(() =>
      props.options.map((opt) => opt.value),
    );

    return {
      selectedName,

      onChange: (e: Event) => {
        if (!props.editable) return;

        const value = (e.target as HTMLInputElement).value;
        if (isNullish(value) || !possibleValues.value.includes(value)) return;

        props.onInput(value);
      },

      styles: {
        "--padding-top": `${props.paddingTop}px`,
        "--padding-right": `${props.paddingRight}px`,
        "--padding-bottom": `${props.paddingBottom}px`,
        "--padding-left": `${props.paddingLeft}px`,
        "--font-size": `${props.fontSize}px`,
        "--color": labelToColor(props.fontColor),
        "--option-font-size": `${props.optionFontSize}px`,
        "--option-color": labelToColor(props.optionFontColor),
      },
    };
  },
});
