import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-452ed5b8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "value-area readonly"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseDatePicker = _resolveComponent("BaseDatePicker")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["editable-item-date-container", { boxed: _ctx.editable || !_ctx.hideBoxOnDisable }]),
    style: _normalizeStyle(_ctx.styles)
  }, [
    (_ctx.editable)
      ? (_openBlock(), _createBlock(_component_BaseDatePicker, {
          key: 0,
          class: "value-area",
          value: _ctx.value,
          "on-change": _ctx.onInputInternal,
          "font-size": _ctx.fontSize,
          "font-color": _ctx.fontColor,
          placeholder: _ctx.placeholder,
          clearable: _ctx.clearable
        }, null, 8, ["value", "on-change", "font-size", "font-color", "placeholder", "clearable"]))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.displayValue), 1))
  ], 6))
}