import { Grade, gradeFromRespOrError } from "@/ts/objects/value/grade";
import { TeacherClass as TeacherClassResp } from "@/ts/api/user-service";
import { Class } from "@/ts/objects/entity/class";

/**
 * 教職員クラス。
 */
export type TeacherClass = {
  readonly classId: string;

  readonly schoolYear: number;
  readonly grade: Grade;
  readonly classNo: number; // (年度, 学年)内での、並び順。ゼロ始まり。
  readonly name: string;

  readonly inChargeType: string;
};

export function teacherClassFromRespOrError(r: TeacherClassResp): TeacherClass {
  return {
    classId: r.classId,

    schoolYear: r.schoolYear,
    grade: gradeFromRespOrError(r.grade),
    classNo: r.orderNum,
    name: r.name,

    inChargeType: r.inChargeType,
  };
}

export function teacherClassToClass(tc: TeacherClass): Class {
  return {
    classId: tc.classId,

    schoolYear: tc.schoolYear,
    grade: tc.grade,
    classNo: tc.classNo,
    name: tc.name,
  };
}

export function getClassOfSchoolYear(
  classes: TeacherClass[],
  schoolYear: number,
): TeacherClass | null {
  return classes.find((c) => c.schoolYear === schoolYear) ?? null;
}
