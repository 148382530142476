
import { defineComponent, PropType } from "vue";
import EditableItemText from "@/components/editable-items/EditableItemText/EditableItemText.vue";
import { names } from "@/ts/app/object-name";
import { StudentCustomColumnId } from "@/ts/objects/value/student-custom-column-names";

export default defineComponent({
  name: "StudentCustomColumnEditTableItem",
  components: { EditableItemText },
  props: {
    customColumnId: {
      type: String as PropType<StudentCustomColumnId>,
      required: true,
    },
    customColumnName: { type: String, required: true },
    editing: { type: Boolean, required: true },

    onInput: {
      type: Function as PropType<(value: string) => void>,
      required: true,
    },
  },
  setup(props) {
    const cellName = names[props.customColumnId].d;

    return {
      cellName,
    };
  },
});
