import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("button", {
    type: "button",
    class: "collapse-button-lr-container",
    style: _normalizeStyle(_ctx.styles),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
  }, [
    (_ctx.open)
      ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
          key: 0,
          class: "icon",
          icon: ['fas', 'chevron-right']
        }))
      : (_openBlock(), _createBlock(_component_font_awesome_icon, {
          key: 1,
          class: "icon",
          icon: ['fas', 'chevron-left']
        }))
  ], 4))
}