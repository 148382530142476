
import { computed, defineComponent, PropType } from "vue";
import SimpleTextItem0 from "@/components/texts/SimpleTextItem0/SimpleTextItem0.vue";
import { ColorLabel } from "@/ts/objects/value/color-label";
import UserEditHeaderUserName from "@/components/table/edit/UserEditHeaderUserName/UserEditHeaderUserName.vue";
import { toUserDisplayName } from "@/ts/objects/value/user-display-name";
import { names } from "@/ts/app/object-name";
import DeleteButton from "@/components/buttons/DeleteButton/DeleteButton.vue";

export default defineComponent({
  name: "UserEditHeaderUpper",
  components: { DeleteButton, UserEditHeaderUserName, SimpleTextItem0 },
  props: {
    userId: { type: String, required: true },
    googleMail: { type: String, required: true },
    photoUrl: { type: String, required: true },
    name: { type: String, required: true },

    editing: { type: Boolean, required: true },
    deleteUser: { type: Function as PropType<() => void>, required: true },
  },
  setup(props) {
    const displayName = computed<{ color: ColorLabel; value: string }>(() =>
      toUserDisplayName(props.name, "black"),
    );

    const googleMailDisplayName = names.googleMail.d;
    const userIdDisplayName = names.userId.d;

    return {
      displayName,

      googleMailDisplayName,
      userIdDisplayName,
    };
  },
});
