
import { computed, defineComponent, PropType, ref } from "vue";
import { useAppStore } from "@/store/app-store";
import { useUserService } from "@/composables/provide-user-service";
import { Class } from "@/ts/objects/entity/class";
import { hasValue } from "@/ts/utils/common-util";
import { SchoolType } from "@/ts/objects/value/school-type";
import log from "loglevel";
import { createGradeOrError } from "@/ts/objects/value/grade";
import PopupConfirmButton from "@/components/buttons/PopupConfirmButton/PopupConfirmButton.vue";
import AddButton from "@/components/buttons/AddButton/AddButton.vue";
import TeacherClassesSelectPopupItem from "@/components/popups/TeacherClassesSelectPopup/TeacherClassesSelectPopupItem/TeacherClassesSelectPopupItem.vue";
import {
  TeacherClass,
  teacherClassToClass,
} from "@/ts/objects/value/teacher-class";

export default defineComponent({
  name: "TeacherClassesSelectPopup",
  components: { PopupConfirmButton, AddButton, TeacherClassesSelectPopupItem },
  props: {
    initialValue: { type: Array as PropType<TeacherClass[]>, required: true },
    text: { type: String, default: "クラスを設定してください。" },

    onConfirm: {
      type: Function as PropType<(classes: TeacherClass[]) => void>,
      required: true,
    },
    onClickOutside: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
  setup(props) {
    const appStore = useAppStore();
    const userService = useUserService();

    const items = ref<Item[]>(
      props.initialValue.map((tc) => ({
        schoolYear: tc.schoolYear,
        schoolType: tc.grade.schoolType,
        gradeNumber: tc.grade.gradeNumber,
        cls: teacherClassToClass(tc),
        inChargeType: tc.inChargeType,
      })),
    );

    const cachedClasses: Record<number, Class[]> = [];
    const getClasses = async (schoolYear: number): Promise<Class[]> => {
      const classes: Class[] | undefined = cachedClasses[schoolYear];
      if (hasValue(classes)) return classes;

      const result = await userService.listClasses(schoolYear);
      if (!result.ok) return []; // もっとちゃんとエラーハンドリングする？

      cachedClasses[schoolYear] = result.data;
      return result.data;
    };

    const width = 460;
    const itemWidth = 370;

    const canConfirm = computed(() => {
      return items.value.every((i) => isItemComplete(i));
    });

    return {
      items,

      getClasses,
      canConfirm,

      itemWidth,

      onClickAddItem: () => {
        items.value.push({
          schoolYear: appStore.currentSchoolYear,
          schoolType: "elementary",
          gradeNumber: 1,
          cls: null,
          inChargeType: "",
        });
      },
      onChangeItem: (
        idx: number,
        schoolYear: number | null,
        schoolType: SchoolType,
        gradeNumber: number | null,
        cls: Class | null,
        inChargeType: string,
      ) => {
        items.value.splice(idx, 1, {
          schoolYear,
          schoolType,
          gradeNumber,
          cls,
          inChargeType,
        });
      },
      onClickRemoveItem: (idx: number) => {
        items.value.splice(idx, 1);
      },
      onConfirmInternal: () => {
        log.debug(`onConfirmInternal: items=${JSON.stringify(items)}`);
        const teacherClasses: TeacherClass[] = items.value
          .filter((i): i is CompleteItem => isItemComplete(i))
          .map((i) => ({
            classId: i.cls.classId,
            schoolYear: i.schoolYear,
            grade: createGradeOrError(i.schoolType, i.gradeNumber),
            classNo: i.cls.classNo,
            name: i.cls.name,
            inChargeType: i.inChargeType,
          }));
        log.debug(
          `onConfirmInternal: teacherClasses=${JSON.stringify(teacherClasses)}`,
        );
        props.onConfirm(teacherClasses);
      },

      styles: {
        "--width": `${width}px`,
      },

      // テンプレート中で?を使うとStorybookがエラー吐くので・・・。
      toClassId: (cls: Class | null | undefined) => cls?.classId ?? null,
    };
  },
});

type Item = {
  readonly schoolYear: number | null;
  readonly schoolType: SchoolType;
  readonly gradeNumber: number | null;
  readonly cls: Class | null;
  readonly inChargeType: string;
};

type CompleteItem = {
  readonly schoolYear: number;
  readonly schoolType: SchoolType;
  readonly gradeNumber: number;
  readonly cls: Class;
  readonly inChargeType: string;
};

function isItemComplete(item: Item): item is CompleteItem {
  return (
    hasValue(item.schoolYear) &&
    hasValue(item.schoolType) &&
    hasValue(item.gradeNumber) &&
    hasValue(item.cls) &&
    hasValue(item.inChargeType)
  );
}
