import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f0343106"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "user-columns-select-section-container" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = ["name", "checked", ".indeterminate"]
const _hoisted_4 = { class: "title-text" }
const _hoisted_5 = { class: "main" }
const _hoisted_6 = ["name", "checked", "onChange"]
const _hoisted_7 = { class: "item-name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, [
      _createElementVNode("input", {
        type: "checkbox",
        class: "title-checkbox",
        name: _ctx.sectionTitle,
        checked: _ctx.overallSelectionState === 'all',
        ".indeterminate": _ctx.overallSelectionState === 'some',
        onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onToggleOverallSelection && _ctx.onToggleOverallSelection(...args)), ["prevent"]))
      }, null, 8, _hoisted_3),
      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.sectionTitle), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columnDefs, (col) => {
        return (_openBlock(), _createElementBlock("label", {
          key: col.id,
          class: "item"
        }, [
          _createElementVNode("input", {
            type: "checkbox",
            class: "item-checkbox",
            name: col.d,
            checked: _ctx.columnVisibility.state[col.id],
            onChange: (e) => _ctx.onToggleInternal(col.id, e)
          }, null, 40, _hoisted_6),
          _createElementVNode("span", _hoisted_7, _toDisplayString(col.name), 1)
        ]))
      }), 128))
    ])
  ]))
}