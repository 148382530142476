import { withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-146838c8"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["value"]
const _hoisted_2 = {
  key: 1,
  class: "value-area"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["editable-item-number-container", { boxed: _ctx.editable || !_ctx.hideBoxOnDisable }]),
    style: _normalizeStyle(_ctx.styles)
  }, [
    (_ctx.editable)
      ? (_openBlock(), _createElementBlock("input", {
          key: 0,
          type: "number",
          role: "textbox",
          class: "value-area",
          value: _ctx.value,
          onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onInputInternal && _ctx.onInputInternal(...args))),
          onFocus: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onFocus && _ctx.onFocus(...args))),
          onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onBlur && _ctx.onBlur(...args))),
          onKeyup: _cache[3] || (_cache[3] = _withKeys(
//@ts-ignore
(...args) => (_ctx.onEnter && _ctx.onEnter(...args)), ["enter"]))
        }, null, 40, _hoisted_1))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.value), 1))
  ], 6))
}