import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3b8c2636"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "class-details-table-container" }
const _hoisted_2 = { class: "table-header" }
const _hoisted_3 = { class: "table-title" }
const _hoisted_4 = { class: "title-text" }
const _hoisted_5 = { class: "num-users" }
const _hoisted_6 = {
  key: 0,
  class: "add-button-area"
}
const _hoisted_7 = { class: "table-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_ClassDetailsTableAddButton = _resolveComponent("ClassDetailsTableAddButton")!
  const _component_UserSelectPopup = _resolveComponent("UserSelectPopup")!
  const _component_ClassDetailsTableRow = _resolveComponent("ClassDetailsTableRow")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_font_awesome_icon, {
          class: "title-icon",
          icon: ['fas', _ctx.titleIcon]
        }, null, 8, ["icon"]),
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.title), 1)
      ]),
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.data.users.length) + "名", 1),
      (_ctx.editing)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_ClassDetailsTableAddButton, {
              enabled: !_ctx.isUserSelectPopupActive,
              "on-click": _ctx.openUserSelectPopup
            }, null, 8, ["enabled", "on-click"]),
            (_ctx.isUserSelectPopupActive)
              ? _withDirectives((_openBlock(), _createBlock(_component_UserSelectPopup, {
                  key: 0,
                  class: "user-select-popup",
                  text: _ctx.userSelectPopupMessage,
                  width: 380,
                  "user-type": _ctx.data.userType,
                  "on-confirm": _ctx.onConfirmUserSelectPopup
                }, null, 8, ["text", "user-type", "on-confirm"])), [
                  [_directive_click_outside, _ctx.closeUserSelectPopup]
                ])
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("ul", _hoisted_7, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rows, (row) => {
        return (_openBlock(), _createBlock(_component_ClassDetailsTableRow, {
          key: row.data.userId,
          "aria-label": row.data.googleMail,
          class: "row",
          theme: row.theme,
          data: row.data,
          editing: _ctx.editing,
          "on-change-row-title": 
          (value) => _ctx.onChangeRowTitle(row.data.userId, value)
        ,
          "on-click-remove": () => _ctx.onClickRemove(row.data.userId)
        }, null, 8, ["aria-label", "theme", "data", "editing", "on-change-row-title", "on-click-remove"]))
      }), 128))
    ])
  ]))
}