
import { computed, defineComponent, PropType } from "vue";
import { schoolTypes } from "@/ts/objects/value/school-type";
import ClassListTableRow from "@/views/class/ClassList/ClassListTable/ClassListTableRow/ClassListTableRow.vue";
import {
  ClassListItemData,
  ClassListRowData,
  ClassListTableData,
} from "@/views/class/ClassList/class-list-data";
import { isNullish } from "@/ts/utils/common-util";

type Row = {
  readonly theme: 0 | 1;
  readonly data: ClassListRowData;
};

export default defineComponent({
  name: "ClassListTable",
  components: { ClassListTableRow },
  props: {
    data: { type: Object as PropType<ClassListTableData>, required: true },

    editing: { type: Boolean, required: true },

    onUpdateRow: {
      type: Function as PropType<
        (gradeNumber: number, classes: ClassListItemData[]) => void
      >,
      required: false,
    },
  },
  setup(props) {
    const rows = computed<Row[]>(() => {
      const _data = props.data;
      const _rows =
        _data.schoolType === "elementary"
          ? [
              _data.row1,
              _data.row2,
              _data.row3,
              _data.row4,
              _data.row5,
              _data.row6,
            ]
          : [_data.row1, _data.row2, _data.row3];
      return _rows.map((r) => ({
        theme: (r.gradeNumber % 2) as 0 | 1,
        data: r,
      }));
    });

    return {
      title: computed(() => schoolTypes[props.data.schoolType]),
      rows,
      onUpdateRowInternal: (
        gradeNumber: number,
        classes: ClassListItemData[],
      ) => {
        if (!props.editing || isNullish(props.onUpdateRow)) return;
        props.onUpdateRow(gradeNumber, classes);
      },
    };
  },
});
