import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserListTableRow = _resolveComponent("UserListTableRow")!

  return (_openBlock(), _createBlock(_component_UserListTableRow, {
    "horizontal-margin": _ctx.horizontalMargin,
    "icon-cell-width": _ctx.iconCellWidth,
    "name-cell-width": _ctx.nameCellWidth,
    "min-height": _ctx.minHeight,
    "max-height": _ctx.maxHeight,
    "style-type": _ctx.styleType,
    name: _ctx.teacher.name,
    "photo-url": _ctx.teacher.photoUrl,
    "user-columns": _ctx.columns,
    "column-visibility": _ctx.columnVisibility,
    "edit-path": _ctx.editPath,
    "link-enabled": _ctx.linkEnabled
  }, null, 8, ["horizontal-margin", "icon-cell-width", "name-cell-width", "min-height", "max-height", "style-type", "name", "photo-url", "user-columns", "column-visibility", "edit-path", "link-enabled"]))
}