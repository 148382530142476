import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3c495f2c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["value", "maxlength"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AutoResizeTextarea = _resolveComponent("AutoResizeTextarea")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["editable-item-text-container", {
      boxed: _ctx.boxed,
      'adjust-content': _ctx.adjustContent,
    }]),
    style: _normalizeStyle(_ctx.styles)
  }, [
    (_ctx.editable && _ctx.type === 'textarea')
      ? (_openBlock(), _createBlock(_component_AutoResizeTextarea, {
          key: 0,
          class: "value-area textbox",
          value: _ctx.value,
          "trigger-on-window-resize": false,
          "max-chars": _ctx.maxLength,
          "on-input": _ctx.onInputInternal
        }, null, 8, ["value", "max-chars", "on-input"]))
      : (_ctx.editable && _ctx.type === 'input')
        ? (_openBlock(), _createElementBlock("input", {
            key: 1,
            type: "text",
            class: "value-area textbox",
            value: _ctx.value,
            maxlength: _ctx.maxLength,
            onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onInputInternalForInput && _ctx.onInputInternalForInput(...args)))
          }, null, 40, _hoisted_1))
        : (_openBlock(), _createElementBlock("div", {
            key: 2,
            class: _normalizeClass(["value-area", {
        'adjust-content': _ctx.adjustContent,
      }])
          }, _toDisplayString(_ctx.value), 3))
  ], 6))
}