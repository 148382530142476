import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-55c6e263"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseCustomDropdownOptions = _resolveComponent("BaseCustomDropdownOptions")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", {
    class: "base-custom-dropdown-container",
    style: _normalizeStyle(_ctx.styles)
  }, [
    _createElementVNode("button", {
      type: "button",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.activate && _ctx.activate(...args))),
      disabled: !_ctx.buttonEnabled
    }, [
      _renderSlot(_ctx.$slots, "default", { class: "slot" }, undefined, true)
    ], 8, _hoisted_1),
    (_ctx.active)
      ? _withDirectives((_openBlock(), _createBlock(_component_BaseCustomDropdownOptions, {
          key: 0,
          class: _normalizeClass(["options", `align-${_ctx.optionsAlign}`]),
          items: _ctx.items,
          "z-index": _ctx.optionsZIndex,
          width: _ctx.optionsWidth,
          "on-select": _ctx.onSelectInternal
        }, null, 8, ["class", "items", "z-index", "width", "on-select"])), [
          [_directive_click_outside, _ctx.deactivate]
        ])
      : _createCommentVNode("", true)
  ], 4))
}