
import { computed, defineComponent, ref, watch } from "vue";
import ClassList from "@/views/class/ClassList/ClassList.vue";
import { useAppStore } from "@/store/app-store";
import { useUserService } from "@/composables/provide-user-service";
import { useClassStore } from "@/store/class-store";
import { Class } from "@/ts/objects/entity/class";
import { LoadableData, loadableDataNull } from "@/ts/app/loadable-data";
import {
  classListDataFromClasses,
  ClassListEditState,
  ClassListItemData,
  ClassListRowData,
} from "@/views/class/ClassList/class-list-data";
import { SchoolType } from "@/ts/objects/value/school-type";
import {
  isElementaryGradeNumber,
  isJuniorhighGradeNumber,
} from "@/ts/objects/value/grade-number";
import { hasValue, isNullish } from "@/ts/utils/common-util";
import { createGradeOrError } from "@/ts/objects/value/grade";
import differenceWith from "lodash/differenceWith";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import { useClassRoute } from "@/router/use-class-route";
import { useRouter } from "vue-router";
import { ResultErr } from "@/ts/app/result";
import { useAppToast } from "@/composables/use-app-toast";
import log from "loglevel";

// 編集中は学校タイプ切り替え不可にした。が、現状、切り替え可能前提の作り。まあ普通に動くはずだが、最適化しても良い。

type ClassListRowDataWithClassNo = {
  readonly gradeNumber: 1 | 2 | 3 | 4 | 5 | 6;
  readonly classes: (ClassListItemData & { readonly classNo: number })[];
};

export default defineComponent({
  name: "ClassListContainer",
  components: { ClassList },
  setup() {
    const appStore = useAppStore();
    const classStore = useClassStore();
    const userService = useUserService();
    const router = useRouter();
    const { schoolType, schoolYear } = useClassRoute(
      appStore.currentSchoolYear,
    );
    const { showError, showSuccess } = useAppToast();

    const isAdmin = computed(() => appStore.isAdmin);

    const freeze = ref(false);

    const changeSchoolType = (tab: SchoolType) => {
      router.push({
        path: "/class/list",
        query: {
          schoolType: tab,
          schoolYear: schoolYear.value,
        },
      });
    };

    const changeSchoolYear = (value: number) => {
      router.push({
        path: "/class/list",
        query: {
          schoolType: schoolType.value,
          schoolYear: value,
        },
      });
    };
    watch(schoolYear, () =>
      classStore.fetchClasses(userService, schoolYear.value),
    ); // Stale While Revalidate的な挙動になる。

    const classes = computed<LoadableData<Class[]>>(
      () =>
        classStore.schoolYearToClasses[schoolYear.value] ?? loadableDataNull(),
    );
    classStore.fetchClasses(userService, schoolYear.value);

    const editState = ref<ClassListEditState>({ editing: false });
    const onUpdateRow = (
      schoolType: SchoolType,
      gradeNumber: number,
      classes: ClassListItemData[],
    ) => {
      const _editState = editState.value;
      if (!_editState.editing) return;

      log.debug(
        `ClassListContainer: onUpdateRow: schoolType=${schoolType}, gradeNumber=${gradeNumber}, classes=${JSON.stringify(
          classes,
        )}`,
      );

      if (schoolType === "elementary" && isElementaryGradeNumber(gradeNumber)) {
        _editState.data.elementary[`row${gradeNumber}`].classes = classes;
      } else if (
        schoolType === "juniorhigh" &&
        isJuniorhighGradeNumber(gradeNumber)
      ) {
        _editState.data.juniorhigh[`row${gradeNumber}`].classes = classes;
      }
    };
    const startEditing = () => {
      const _classes = classes.value;
      if (!_classes.hasFreshData || freeze.value) return;
      editState.value = {
        editing: true,
        data: classListDataFromClasses(cloneDeep(_classes.data)),
      };
    };
    const save = async () => {
      const _schoolYear = schoolYear.value;
      const _classes = classes.value;
      const _editState = editState.value;
      if (!_classes.hasData || !_editState.editing || freeze.value) return;

      freeze.value = true;

      const attachClassNo = (
        row: ClassListRowData,
      ): ClassListRowDataWithClassNo => ({
        gradeNumber: row.gradeNumber,
        classes: row.classes.map((c, itemIdx) => ({
          ...c,
          classNo: itemIdx + 1, // classNoは1始まり。
        })),
      });
      const e1 = attachClassNo(_editState.data.elementary.row1);
      const e2 = attachClassNo(_editState.data.elementary.row2);
      const e3 = attachClassNo(_editState.data.elementary.row3);
      const e4 = attachClassNo(_editState.data.elementary.row4);
      const e5 = attachClassNo(_editState.data.elementary.row5);
      const e6 = attachClassNo(_editState.data.elementary.row6);
      const j1 = attachClassNo(_editState.data.juniorhigh.row1);
      const j2 = attachClassNo(_editState.data.juniorhigh.row2);
      const j3 = attachClassNo(_editState.data.juniorhigh.row3);

      const rowToNewClasses = (
        schoolType: SchoolType,
        row: ClassListRowDataWithClassNo,
      ): Omit<Class, "classId">[] => {
        return row.classes
          .filter((c) => isNullish(c.classId))
          .map((c) => ({
            schoolYear: _schoolYear,
            grade: createGradeOrError(schoolType, row.gradeNumber),
            classNo: c.classNo,
            name: c.name.trim(), // 空白文字をtrim。
          }));
      };
      const addedClasses = [
        ...rowToNewClasses("elementary", e1),
        ...rowToNewClasses("elementary", e2),
        ...rowToNewClasses("elementary", e3),
        ...rowToNewClasses("elementary", e4),
        ...rowToNewClasses("elementary", e5),
        ...rowToNewClasses("elementary", e6),
        ...rowToNewClasses("juniorhigh", j1),
        ...rowToNewClasses("juniorhigh", j2),
        ...rowToNewClasses("juniorhigh", j3),
      ];

      const rowToUpdatedClasses = (
        schoolType: SchoolType,
        row: ClassListRowDataWithClassNo,
      ): Class[] => {
        const notNewClasses: Class[] = row.classes
          .filter((c) => hasValue(c.classId))
          .map((c) => ({
            classId: c.classId ?? "",
            schoolYear: _schoolYear,
            grade: createGradeOrError(schoolType, row.gradeNumber),
            classNo: c.classNo,
            name: c.name.trim(), // 空白文字をtrim。
          }));

        // 変更があったものだけに絞る。
        log.debug(`schoolType=${schoolType}, gradeNumber=${row.gradeNumber}`);
        log.debug(`notNewClasses=${JSON.stringify(notNewClasses, null, 2)}`);
        log.debug(`_classes.data=${JSON.stringify(_classes.data, null, 2)}`);
        const updated = differenceWith(notNewClasses, _classes.data, isEqual);
        log.debug(`diff=${JSON.stringify(updated, null, 2)}`);

        return updated;
      };
      const updatedClasses = [
        ...rowToUpdatedClasses("elementary", e1),
        ...rowToUpdatedClasses("elementary", e2),
        ...rowToUpdatedClasses("elementary", e3),
        ...rowToUpdatedClasses("elementary", e4),
        ...rowToUpdatedClasses("elementary", e5),
        ...rowToUpdatedClasses("elementary", e6),
        ...rowToUpdatedClasses("juniorhigh", j1),
        ...rowToUpdatedClasses("juniorhigh", j2),
        ...rowToUpdatedClasses("juniorhigh", j3),
      ];

      const results = await Promise.all([
        ...addedClasses.map((c) => userService.postClass(c)),
        ...updatedClasses.map((c) => userService.patchClass(c)),
      ]);
      const errors = results
        .filter((r): r is ResultErr => !r.ok)
        .map((r) => r.error);
      if (errors.length === 0) {
        showSuccess("変更を保存しました。");
      } else {
        showError(`変更の保存に失敗しました: ${errors[0].displayMessage}`);
      }

      editState.value = { editing: false };
      classStore.clearClasses(schoolYear.value);
      classStore.fetchClasses(userService, schoolYear.value);

      freeze.value = false;
    };
    const cancelEditing = () => {
      if (freeze.value) return;

      editState.value = { editing: false };
    };

    return {
      isAdmin,

      schoolType,
      changeSchoolType,

      schoolYear,
      changeSchoolYear,

      classes,

      editState,

      onUpdateRow,
      startEditing,
      cancelEditing,
      save,
    };
  },
});
