
import { computed, defineComponent, PropType } from "vue";
import SchoolEditTable from "@/views/school/SchoolEdit/SchoolEditTable/SchoolEditTable.vue";
import { UnsavedSchoolEditData } from "@/views/school/SchoolEdit/school-edit-data";
import { School } from "@/ts/objects/entity/school";
import { hasValue } from "@/ts/utils/common-util";
import { LoadableData } from "@/ts/app/loadable-data";
import LoadingBlock from "@/components/loading/LoadingBlock/LoadingBlock.vue";
import SchoolEditHeader from "@/views/school/SchoolEdit/SchoolEditHeader/SchoolEditHeader.vue";

export default defineComponent({
  name: "SchoolEdit",
  components: { SchoolEditHeader, LoadingBlock, SchoolEditTable },
  props: {
    isAdmin: { type: Boolean, required: true },

    school: { type: Object as PropType<LoadableData<School>>, required: true },
    unsavedData: {
      type: Object as PropType<UnsavedSchoolEditData>,
      required: false,
    },

    /**
     * この画面に来てからの、ロゴのアップデート時刻。
     * 画面に来た時の初期値はnull。
     * ただロゴの変更をすぐに反映するためだけに用いる。
     */
    logoUpdatedAt: { type: Object as PropType<Date>, required: false },

    onInputSchoolName: {
      type: Function as PropType<(value: string) => void>,
      required: true,
    },
    onInputLogo: {
      type: Function as PropType<(file: File | null) => void>,
      required: true,
    },

    /**
     * 編集を開始する。
     */
    startEditing: {
      type: Function as PropType<() => void>,
      required: true,
    },
    /**
     * 編集をキャンセルする。
     */
    cancelEditing: {
      type: Function as PropType<() => void>,
      required: true,
    },
    /**
     * セーブし、編集を終了する。
     */
    save: { type: Function as PropType<() => Promise<void>>, required: true },
  },
  setup(props) {
    const editing = computed(() => hasValue(props.unsavedData));

    const tableModel = computed<School | null>(() => {
      const _unsavedData = props.unsavedData;
      const _school = props.school;

      if (hasValue(_unsavedData) && _school.hasData) {
        // logoGcsUrlは、ロゴアップロード時にすぐに書き換えられ、キャンセルできないため、セーブ済の値しか存在しない。
        return {
          schoolName: _unsavedData.schoolName,
          logoGcsUrl: _school.data.logoGcsUrl,
        };
      } else if (_school.hasData) {
        return _school.data;
      } else {
        return null;
      }
    });

    return {
      editing,
      tableModel,
    };
  },
});
