export const guardianSubPageNames = ["list", "edit"] as const;
export type GuardianSubPageName = typeof guardianSubPageNames[number];

export function isGuardianSubPageName(
  value: unknown,
): value is GuardianSubPageName {
  return (
    typeof value === "string" && guardianSubPageNames.some((v) => v === value)
  );
}

export function asGuardianSubPageNameOrNull(
  value: unknown,
): GuardianSubPageName | null {
  if (isGuardianSubPageName(value)) return value;
  return null;
}
