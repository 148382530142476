
import { computed, defineComponent, ref } from "vue";
import MainMenu from "@/views/_components/MainMenu/MainMenu.vue";
import { allPages } from "@/ts/app/pages/pages";
import AppHeader from "@/views/_components/AppHeader/AppHeader.vue";
import { useAppRoute } from "@/router/use-app-route";
import { useAppStore } from "@/store/app-store";

export default defineComponent({
  name: "Main",
  components: { AppHeader, MainMenu },
  setup() {
    const appStore = useAppStore();
    const { pageName } = useAppRoute();

    const schoolLogoGcsUrl = computed(() => appStore.school.logoGcsUrl);
    const schoolName = computed(() => appStore.school.schoolName);
    const userPhotoUrl = ref("https://avatars.dicebear.com/api/bottts/417.svg");

    return {
      schoolLogoGcsUrl,
      schoolName,
      userPhotoUrl,

      pages: allPages,
      pageName,
    };
  },
});
