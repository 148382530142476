import { UserColumnDef } from "@/ts/app/columns/def/user-column-def";
import { Guardian } from "@/ts/objects/entity/guardian";
import {
  GuardianProfileColumnDefGroup,
  guardianProfileColumnDefs,
  GuardianProfileColumnGroup,
  guardianProfileColumnGroupName,
  GuardianProfileColumnGroupName,
  GuardianProfileColumnId,
} from "@/ts/app/columns/def/guardian/guardian-profile-column";
import {
  GuardianNotesColumnDefGroup,
  guardianNotesColumnDefs,
  GuardianNotesColumnGroup,
  guardianNotesColumnGroupName,
  GuardianNotesColumnGroupName,
  GuardianNotesColumnId,
} from "@/ts/app/columns/def/guardian/guardian-notes-column";
import {
  attachValueToCols,
  UserColumn,
} from "@/ts/app/columns/def/user-column";
import { createTypedObjectFromEntries } from "@/ts/utils/common-util";
import { ObjectInternalName } from "@/ts/app/object-name";

export type GuardianColumnGroupName =
  | GuardianProfileColumnGroupName
  | GuardianNotesColumnGroupName;

export type GuardianColumnId = GuardianProfileColumnId | GuardianNotesColumnId;

export type GuardianColumnDef<
  ColId extends ObjectInternalName,
  T,
> = UserColumnDef<ColId, Guardian, T>;

export type GuardianColumn<ColId extends ObjectInternalName, T> = UserColumn<
  ColId,
  Guardian,
  T
>;

export type GuardianColumnDefGroup =
  | GuardianProfileColumnDefGroup
  | GuardianNotesColumnDefGroup;

export function getGuardianColumnDefs(): GuardianColumnDef<
  GuardianColumnId,
  any
>[] {
  return [...guardianProfileColumnDefs, ...guardianNotesColumnDefs];
}

export function getGuardianColumnDefsGrouped(): {
  profileColumnDefGroup: GuardianProfileColumnDefGroup;
  notesColumnDefGroup: GuardianNotesColumnDefGroup;
} {
  return {
    profileColumnDefGroup: {
      groupName: guardianProfileColumnGroupName,
      columns: guardianProfileColumnDefs,
    },
    notesColumnDefGroup: {
      groupName: guardianNotesColumnGroupName,
      columns: guardianNotesColumnDefs,
    },
  };
}

export function getGuardianColumnDefsAsDict(): Record<
  GuardianColumnId,
  GuardianColumnDef<GuardianColumnId, any>
> {
  return createTypedObjectFromEntries(
    getGuardianColumnDefs().map(
      (col): [GuardianColumnId, GuardianColumnDef<GuardianColumnId, any>] => [
        col.id,
        col,
      ],
    ),
  );
}

export function getGuardianColumns(
  guardian: Guardian,
): GuardianColumn<GuardianColumnId, any>[] {
  const columnDefs = getGuardianColumnDefs();
  return attachValueToCols(columnDefs, guardian);
}

export function getGuardianColumnsGrouped(guardian: Guardian): {
  profileColumnGroup: GuardianProfileColumnGroup;
  notesColumnGroup: GuardianNotesColumnGroup;
} {
  const { profileColumnDefGroup, notesColumnDefGroup } =
    getGuardianColumnDefsGrouped();
  return {
    profileColumnGroup: {
      groupName: guardianProfileColumnGroupName,
      columns: attachValueToCols(profileColumnDefGroup.columns, guardian),
    },
    notesColumnGroup: {
      groupName: guardianNotesColumnGroupName,
      columns: attachValueToCols(notesColumnDefGroup.columns, guardian),
    },
  };
}
