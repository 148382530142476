import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9f7e94bc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "user-edit-table-body-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserEditTableItem = _resolveComponent("UserEditTableItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.writableUserColumns, (userColumn) => {
      return (_openBlock(), _createBlock(_component_UserEditTableItem, {
        class: "item",
        key: userColumn.id,
        "user-column": userColumn,
        editing: _ctx.editing,
        "on-input": (value) => _ctx.onInput(userColumn.id, value)
      }, null, 8, ["user-column", "editing", "on-input"]))
    }), 128))
  ]))
}