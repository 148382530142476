import {
  UserColumnDefGroup,
  UserColumnGroup,
} from "@/ts/app/columns/def/user-column-group";
import { Teacher, TeacherProfile } from "@/ts/objects/entity/teacher";
import { TeacherColumnDef } from "@/ts/app/columns/def/teacher/teacher-column";
import { UserColumn } from "@/ts/app/columns/def/user-column";
import { genUserColumnDefs } from "@/ts/app/columns/def/user-column-def";
import {
  StringType,
  TeacherClassesType,
  TeacherTypeType,
} from "@/ts/app/columns/def/column-types";
import {
  userColumnDefGoogleMail,
  userColumnDefName,
  userColumnDefPhotoUrl,
  userColumnDefUserId,
} from "@/ts/app/columns/def/common/user-common-column";
import { names } from "@/ts/app/object-name";

export const teacherProfileColumnGroupName = names.profile.i;
export type TeacherProfileColumnGroupName =
  typeof teacherProfileColumnGroupName;

export type TeacherProfileColumnId =
  | "userId"
  | "googleMail"
  | "photoUrl"
  | "name"
  | "classesOfTheYear"
  | keyof TeacherProfile;

export type TeacherProfileColumnDef<T> = TeacherColumnDef<
  TeacherProfileColumnId,
  T
>;

export type TeacherProfileColumn<T> = UserColumn<
  TeacherProfileColumnId,
  Teacher,
  T
>;

export type TeacherProfileColumnDefGroup = UserColumnDefGroup<
  TeacherProfileColumnGroupName,
  TeacherProfileColumnId,
  Teacher
>;

export type TeacherProfileColumnGroup = UserColumnGroup<
  TeacherProfileColumnGroupName,
  TeacherProfileColumnId,
  Teacher
>;

export function teacherProfileColumnDefs(
  schoolYear: number,
): TeacherProfileColumnDef<any>[] {
  return genUserColumnDefs<TeacherProfileColumnId, Teacher, any>([
    userColumnDefUserId(),
    userColumnDefGoogleMail(),
    userColumnDefPhotoUrl(),
    userColumnDefName(),
    {
      id: "nameKana",
      width: 120,
      type: StringType,
      getValue: (t) => t.nameKana,
      setValue: (t, v) => ({ ...t, nameKana: v }),
    },

    {
      id: "classes",
      width: 230,
      type: TeacherClassesType,
      getValue: (t) => t.classes,
      setValue: (s, v): Teacher => ({ ...s, classes: v }),
    },
    {
      id: "classesOfTheYear",
      width: 230,
      type: TeacherClassesType,
      getValue: (t) => t.classes.filter((c) => c.schoolYear === schoolYear),
    },
    {
      id: "familyName",
      width: 120,
      type: StringType,
      getValue: (t) => t.familyName,
      setValue: (t, v) => ({ ...t, familyName: v }),
    },
    {
      id: "givenName",
      width: 120,
      type: StringType,
      getValue: (t) => t.givenName,
      setValue: (t, v) => ({ ...t, givenName: v }),
    },
    {
      id: "teacherType",
      width: 120,
      type: TeacherTypeType,
      getValue: (t) => t.teacherType,
      setValue: (t, v) => ({ ...t, teacherType: v }),
    },
    {
      id: "zipcode",
      width: 120,
      type: StringType,
      getValue: (t) => t.zipcode,
      setValue: (t, v) => ({ ...t, zipcode: v }),
    },
    {
      id: "address",
      width: 120,
      type: StringType,
      getValue: (t) => t.address,
      setValue: (t, v) => ({ ...t, address: v }),
    },
    {
      id: "email",
      width: 120,
      type: StringType,
      getValue: (t) => t.email,
      setValue: (t, v) => ({ ...t, email: v }),
    },
    {
      id: "mobilePhone",
      width: 120,
      type: StringType,
      getValue: (t) => t.mobilePhone,
      setValue: (t, v) => ({ ...t, mobilePhone: v }),
    },
    {
      id: "landlinePhone",
      width: 120,
      type: StringType,
      getValue: (t) => t.landlinePhone,
      setValue: (t, v) => ({ ...t, landlinePhone: v }),
    },
    {
      id: "foreignType",
      width: 120,
      type: StringType,
      maxLength: 1,
      getValue: (t) => t.foreignType,
      setValue: (t, v) => ({ ...t, foreignType: v }),
    },
  ]);
}
