
import { computed, defineComponent, ref } from "vue";
import ClassDetails from "@/views/class/ClassDetails/ClassDetails.vue";
import { useClassStore } from "@/store/class-store";
import { useUserService } from "@/composables/provide-user-service";
import { useClassRoute } from "@/router/use-class-route";
import {
  applyResult,
  composeLoadableData,
  LoadableData,
  loadableDataNull,
} from "@/ts/app/loadable-data";
import {
  ClassDetailsData,
  classDetailsDataFromUsers,
  ClassDetailsEditState,
  ClassDetailsRowDataStudent,
  ClassDetailsRowDataTeacher,
  ClassDetailsTableData,
} from "@/views/class/ClassDetails/class-details-data";
import {
  arrayDiff,
  filterNotNullish,
  isNullish,
  isStringBlank,
  isStringNotBlank,
} from "@/ts/utils/common-util";
import { Class } from "@/ts/objects/entity/class";
import { useRouter } from "vue-router";
import { Teacher } from "@/ts/objects/entity/teacher";
import { Student } from "@/ts/objects/entity/student";
import log from "loglevel";
import cloneDeep from "lodash/cloneDeep";
import { useAppStore } from "@/store/app-store";
import { useAppToast } from "@/composables/use-app-toast";
import { ResultErr } from "@/ts/app/result";
import { names } from "@/ts/app/object-name";
import { TeacherClass } from "@/ts/objects/value/teacher-class";
import isEqual from "lodash/isEqual";
import { StudentClass } from "@/ts/objects/value/student-class";

export default defineComponent({
  name: "ClassDetailsContainer",
  components: { ClassDetails },
  setup() {
    const appStore = useAppStore();
    const classStore = useClassStore();
    const userService = useUserService();
    const router = useRouter();
    const { classId } = useClassRoute(appStore.currentSchoolYear);
    const { showError, showSuccess } = useAppToast();

    const isAdmin = computed(() => appStore.isAdmin);

    const freeze = ref(false);

    const cls = ref<LoadableData<Class>>(loadableDataNull());
    if (isStringNotBlank(classId.value)) {
      userService.getClass(classId.value ?? "").then((result) => {
        cls.value = applyResult(cls.value, result);
      });
    }

    const teachers = computed<LoadableData<Teacher[]>>(() => {
      const _classId = classId.value;
      if (isNullish(_classId) || isStringBlank(_classId))
        return loadableDataNull();
      return classStore.classIdToTeachers[_classId] ?? loadableDataNull();
    });
    const students = computed<LoadableData<Student[]>>(() => {
      const _classId = classId.value;
      if (isNullish(_classId) || isStringBlank(_classId))
        return loadableDataNull();
      return classStore.classIdToStudents[_classId] ?? loadableDataNull();
    });
    const data = computed<LoadableData<ClassDetailsData>>(() => {
      const _classId = classId.value;
      if (isNullish(_classId) || isStringBlank(_classId))
        return loadableDataNull();

      return composeLoadableData(teachers.value, students.value, (t, s) =>
        classDetailsDataFromUsers(t, s, _classId),
      );
    });
    if (isStringNotBlank(classId.value)) {
      classStore.fetchTeachers(userService, classId.value ?? "");
      classStore.fetchStudents(userService, classId.value ?? "");
    }

    const deletingThisClass = ref(false);

    const editState = ref<ClassDetailsEditState>({ editing: false });

    const onUpdate = (updated: ClassDetailsTableData) => {
      const _editState = editState.value;
      if (!_editState.editing) return;

      log.debug(`onUpdate: updated=${JSON.stringify(updated, null, 2)}`);

      switch (updated.userType) {
        case "teacher":
          _editState.data.teacher = updated;
          return;
        case "student":
          _editState.data.student = updated;
          return;
      }
    };

    const onClickBack = () => {
      router.back();
    };
    const cancelEditing = () => {
      if (freeze.value) return;

      editState.value = { editing: false };
    };
    const startEditing = () => {
      const _data = data.value;
      if (!_data.hasFreshData || deletingThisClass.value || freeze.value)
        return;
      editState.value = {
        editing: true,
        data: cloneDeep(_data.data),
      };
    };
    const save = async () => {
      const _cls = cls.value;
      const _teachers = teachers.value;
      const _students = students.value;
      const _data = data.value;
      const _editState = editState.value;
      if (
        !_cls.hasData ||
        !_teachers.hasData ||
        !_students.hasData ||
        !_data.hasData ||
        !_editState.editing ||
        deletingThisClass.value ||
        freeze.value
      )
        return;

      freeze.value = true;

      const thisClass = _cls.data;

      const { removed: removedTeacherIds } = arrayDiff(
        _data.data.teacher.users.map((t) => t.userId),
        _editState.data.teacher.users.map((t) => t.userId),
      );
      const teacherRows: ClassDetailsRowDataTeacher[] =
        _editState.data.teacher.users;

      const { removed: removedStudentIds } = arrayDiff(
        _data.data.student.users.map((s) => s.userId),
        _editState.data.student.users.map((s) => s.userId),
      );
      const studentRows: ClassDetailsRowDataStudent[] =
        _editState.data.student.users;

      const [listTeachersResult, listStudentsResult] = await Promise.all([
        (async () => {
          if (teacherRows.length === 0) return { ok: true, data: [] };
          return await userService.listTeachers(
            teacherRows.map((t) => t.userId),
          );
        })(),
        (async () => {
          if (studentRows.length === 0) return { ok: true, data: [] };
          return await userService.listStudents(
            studentRows.map((s) => s.userId),
          );
        })(),
      ]);
      if (!listTeachersResult.ok || !listStudentsResult.ok) {
        showError("変更の保存に失敗しました。");
        freeze.value = false;
        return;
      }
      const teachersOfRows = listTeachersResult.data;
      const studentsOfRows = listStudentsResult.data;

      const results = await Promise.all([
        ...filterNotNullish(
          teacherRows.map((row) => {
            const _teacher = teachersOfRows.find(
              (t) => t.userId === row.userId,
            );
            if (isNullish(_teacher)) return null;

            const teacherClassBefore: TeacherClass | undefined =
              _teacher.classes.find((c) => c.classId === thisClass.classId);
            const teacherClassAfter: TeacherClass = {
              ...thisClass,
              inChargeType: row.inChargeType,
            };
            // 変化なしなら、わざわざpatchしに行かない。
            if (isEqual(teacherClassBefore, teacherClassAfter)) return null;

            return userService.patchTeacher({
              userId: _teacher.userId,
              classes: [
                ..._teacher.classes.filter(
                  (c) => c.classId !== thisClass.classId,
                ),
                teacherClassAfter,
              ],
            });
          }),
        ),
        ...filterNotNullish(
          removedTeacherIds.map((userId) => {
            const _teacher = _teachers.data.find((t) => t.userId === userId);
            if (isNullish(_teacher)) return null;
            return userService.patchTeacher({
              userId,
              classes: _teacher.classes.filter(
                (c) => c.classId !== thisClass.classId,
              ),
            });
          }),
        ),
        ...filterNotNullish(
          studentRows.map((row) => {
            const _student = studentsOfRows.find(
              (t) => t.userId === row.userId,
            );
            if (isNullish(_student)) return null;

            const studentClassBefore: StudentClass | undefined =
              _student.classes.find((c) => c.classId === thisClass.classId);
            const studentClassAfter: StudentClass = {
              ...thisClass,
              studentNumber: row.studentNumber,
            };
            // 変化なしなら、わざわざpatchしに行かない。
            if (isEqual(studentClassBefore, studentClassAfter)) return null;

            return userService.patchStudent({
              userId: _student.userId,
              classes: [
                ..._student.classes.filter(
                  (c) => c.classId !== thisClass.classId,
                ),
                {
                  ...thisClass,
                  studentNumber: row.studentNumber,
                },
              ],
            });
          }),
        ),
        ...filterNotNullish(
          removedStudentIds.map((userId) => {
            const _student = _students.data.find((t) => t.userId === userId);
            if (isNullish(_student)) return null;
            return userService.patchStudent({
              userId,
              classes: _student.classes.filter(
                (c) => c.classId !== thisClass.classId,
              ),
            });
          }),
        ),
      ]);
      const errors = results
        .filter((r): r is ResultErr => !r.ok)
        .map((r) => r.error);
      if (errors.length === 0) {
        showSuccess("変更を保存しました。");
      } else {
        showError(`変更の保存に失敗しました: ${errors[0].displayMessage}`);
      }

      editState.value = { editing: false };
      classStore.fetchTeachers(userService, _cls.data.classId);
      classStore.fetchStudents(userService, _cls.data.classId);

      freeze.value = false;
    };
    const onClickDelete = async () => {
      const _cls = cls.value;
      const _editState = editState.value;
      if (
        !_cls.hasData ||
        !_editState.editing ||
        deletingThisClass.value ||
        freeze.value
      )
        return;

      if (
        !window.confirm(
          `この${names.class.d}を削除してよろしいですか？\nこの操作はすぐに反映され、元に戻せません。`,
        )
      ) {
        return;
      }

      freeze.value = true;

      editState.value = { editing: false };
      deletingThisClass.value = true;
      await userService.deleteClass(_cls.data.classId);

      freeze.value = false;

      router.back();
    };

    return {
      isAdmin,

      cls,
      data,

      editState,

      onUpdate,

      onClickBack,
      startEditing,
      save,
      cancelEditing,
      onClickDelete,
    };
  },
});
