
import { computed, defineComponent, PropType } from "vue";
import GuardianPageHeading from "@/views/guardian/_components/GuardianPageHeading/GuardianPageHeading.vue";
import BaseSearchBox from "@/components/textboxes/BaseSearchBox/BaseSearchBox.vue";
import ImportButton from "@/components/buttons/ImportButton/ImportButton.vue";
import GuardianColumnsSelect from "@/views/guardian/_components/GuardianColumnsSelect/GuardianColumnsSelect.vue";
import SearchConditionDropdown from "@/components/search-condition/SearchConditionDropdown/SearchConditionDropdown.vue";
import SearchConditionPopupSchoolYear from "@/components/search-condition/SearchConditionPopupSchoolYear/SearchConditionPopupSchoolYear.vue";
import SearchConditionPopupClass from "@/components/search-condition/SearchConditionPopupClass/SearchConditionPopupClass.vue";
import SearchConditionPopupInSchoolState from "@/components/search-condition/SearchConditionPopupInSchoolState/SearchConditionPopupInSchoolState.vue";
import SearchConditionPopupNumResults from "@/components/search-condition/SearchConditionPopupNumResults/SearchConditionPopupNumResults.vue";
import SearchResultNumbers from "@/components/other/SearchResultNumbers/SearchResultNumbers.vue";
import GuardianListTable from "@/views/guardian/_components/GuardianListTable/GuardianListTable.vue";
import Pager from "@/components/other/Pager/Pager.vue";
import {
  GuardianColumnVisibility,
  GuardianColumnVisibilityAll,
} from "@/ts/app/columns/visibility/guardian-column-visibility";
import { Class } from "@/ts/objects/entity/class";
import { SearchResult } from "@/ts/app/search-result";
import { Guardian } from "@/ts/objects/entity/guardian";
import { GuardianSearchCondition } from "@/ts/objects/search-condition/guardian-search-condition";
import {
  SearchConditionClass,
  searchConditionClassToDisplayValue,
} from "@/ts/objects/search-condition/search-condition-class";
import {
  SearchConditionInSchoolState,
  searchConditionInSchoolStateToDisplayValue,
} from "@/ts/objects/search-condition/search-condition-in-school-state";
import {
  NumResultsCondition,
  numResultsConditionToDisplayValue,
} from "@/ts/objects/search-condition/num-results-condition";
import {
  UserSortCondition,
  userSortConditionToDisplayValue,
} from "@/ts/objects/search-condition/user-sort-condition";
import { hasValue } from "@/ts/utils/common-util";
import SearchConditionPopupUserSort from "@/components/search-condition/SearchConditionPopupUserSort/SearchConditionPopupUserSort.vue";
import { ExportDropdownOptionValue } from "@/components/dropdowns/ExportDropdown/export-dropdown";
import ExportDropdown from "@/components/dropdowns/ExportDropdown/ExportDropdown.vue";
import MenuDropdown from "@/components/dropdowns/MenuDropdown/MenuDropdown.vue";
import { DropdownItem } from "@/ts/app/dropdown-item";

export default defineComponent({
  name: "GuardianList",
  components: {
    MenuDropdown,
    ExportDropdown,
    SearchConditionPopupUserSort,
    Pager,
    GuardianListTable,
    SearchResultNumbers,
    SearchConditionPopupNumResults,
    SearchConditionPopupInSchoolState,
    SearchConditionPopupClass,
    SearchConditionPopupSchoolYear,
    SearchConditionDropdown,
    GuardianColumnsSelect,
    ImportButton,
    BaseSearchBox,
    GuardianPageHeading,
  },
  props: {
    isAdmin: { type: Boolean, required: true },

    columnVisibility: {
      type: Object as PropType<GuardianColumnVisibilityAll>,
      required: true,
    },
    schoolYear: { type: Number, required: true },

    /**
     * ある年度のすべてのクラス。
     *
     * （選択中の年度の）すべての学校タイプ・学年のクラスを含んでいること。
     * 適切な並び順になっていること。
     */
    allClasses: { type: Array as PropType<Class[]>, required: true },

    searchResult: { type: Object as PropType<SearchResult<Guardian>> },
    numResults: { type: Number },
    pageNumber: { type: Number, required: true },

    searchCondition: {
      type: Object as PropType<GuardianSearchCondition>,
      required: true,
    },

    onInputQueryText: {
      type: Function as PropType<(text: string) => void>,
      required: true,
    },
    onConfirmQueryText: {
      type: Function as PropType<(value: string) => void>,
      required: true,
    },

    // 年度ポップアップ
    isSchoolYearPopupActive: { type: Boolean, required: true },
    changeSchoolYearPopupActiveness: {
      type: Function as PropType<(active: boolean) => void>,
      required: true,
    },
    onConfirmConditionSchoolYear: {
      type: Function as PropType<(schoolYear: number) => void>,
      required: true,
    },

    // クラスポップアップ
    isClassPopupActive: { type: Boolean, required: true },
    changeClassPopupActiveness: {
      type: Function as PropType<(active: boolean) => void>,
      required: true,
    },
    onConfirmConditionClass: {
      type: Function as PropType<(cond: SearchConditionClass) => void>,
      required: true,
    },

    // 在学状態ポップアップ
    isInSchoolStatePopupActive: { type: Boolean, required: true },
    changeInSchoolStatePopupActiveness: {
      type: Function as PropType<(active: boolean) => void>,
      required: true,
    },
    onConfirmConditionInSchoolState: {
      type: Function as PropType<(cond: SearchConditionInSchoolState) => void>,
      required: true,
    },

    // 並び替え条件ポップアップ
    isSortPopupActive: { type: Boolean, required: true },
    changeSortPopupActiveness: {
      type: Function as PropType<(active: boolean) => void>,
      required: true,
    },
    onConfirmConditionSort: {
      type: Function as PropType<(cond: UserSortCondition) => void>,
      required: true,
    },

    // 件数ポップアップ
    isNumResultsPopupActive: { type: Boolean, required: true },
    changeNumResultsPopupActiveness: {
      type: Function as PropType<(active: boolean) => void>,
      required: true,
    },
    onConfirmConditionNumResults: {
      type: Function as PropType<(cond: NumResultsCondition) => void>,
      required: true,
    },

    isColumnSelectOpen: { type: Boolean, required: true },
    toggleColumnSelectVisibility: {
      type: Function as PropType<() => void>,
      required: true,
    },

    syncWithGWS: {
      type: Function as PropType<() => void>,
      required: true,
    },
    onClickExportButton: {
      type: Function as PropType<(value: ExportDropdownOptionValue) => void>,
      required: true,
    },

    userIdToEditPath: {
      type: Function as PropType<(userId: string) => string>,
      required: true,
    },

    movePageTo: {
      type: Function as PropType<(n: number) => void>,
      required: true,
    },

    onChangeColumnVisibility: {
      type: Function as PropType<(v: GuardianColumnVisibility) => void>,
      required: true,
    },
  },
  setup(props) {
    const extraMenuItems: DropdownItem[] = [
      {
        value: "sync-with-gws",
        label: "Sync with Google Workspace",
      },
    ];
    const onSelectExtraMenuItem = (value: string) => {
      switch (value) {
        case "sync-with-gws": {
          props.syncWithGWS();
          break;
        }
      }
    };

    return {
      extraMenuItems,
      onSelectExtraMenuItem,

      numAllPages: computed(() =>
        hasValue(props.searchResult)
          ? Math.ceil(
              props.searchResult.numSearchResults /
                props.searchResult.numResultsPerPage,
            )
          : null,
      ),

      classDisplayValue: computed(() =>
        searchConditionClassToDisplayValue(props.searchCondition.class),
      ),
      inSchoolStateDisplayValue: computed(() =>
        searchConditionInSchoolStateToDisplayValue(
          props.searchCondition.inSchoolState,
        ),
      ),
      sortDisplayValue: computed(() =>
        userSortConditionToDisplayValue(props.searchCondition.sort),
      ),
      numResultsDisplayValue: computed(() =>
        numResultsConditionToDisplayValue(props.searchCondition.numResults),
      ),
    };
  },
});
