
import { computed, defineComponent, PropType } from "vue";
import StudentSubPageHeading from "@/views/student/_components/StudentSubPageHeading/StudentSubPageHeading.vue";
import EditButtonSet from "@/components/other/EditButtonSet/EditButtonSet.vue";
import { Student } from "@/ts/objects/entity/student";
import StudentEditTable from "@/views/student/StudentEdit/StudentEditTable/StudentEditTable.vue";
import { StudentEditTableTabValue } from "@/views/student/StudentEdit/student-edit-table-tab-value";
import {
  getStudentColumnsGrouped,
  StudentColumnId,
} from "@/ts/app/columns/def/student/student-column";
import { isNullish } from "@/ts/utils/common-util";
import UserEditHeaderUpper from "@/components/table/edit/UserEditHeaderUpper/UserEditHeaderUpper.vue";
import StudentEditHeaderLower from "@/views/student/StudentEdit/StudentEditHeaderLower/StudentEditHeaderLower.vue";
import { LoadableData } from "@/ts/app/loadable-data";
import {
  StudentEditData,
  StudentEditState,
} from "@/views/student/StudentEdit/student-edit-state";

export default defineComponent({
  name: "StudentEdit",
  components: {
    StudentEditHeaderLower,
    UserEditHeaderUpper,
    StudentEditTable,
    EditButtonSet,
    StudentSubPageHeading,
  },
  props: {
    isAdmin: { type: Boolean, required: true },

    schoolYear: { type: Number, required: true },

    /**
     * 閲覧モード時に用いるデータ。
     *
     * 編集中には表示に使わないし、変更もされない。
     * 編集を開始したとき、このデータが編集用データとして複製される。
     * 編集をキャンセルしたとき、このデータに戻る。
     * 編集をセーブしたとき、このデータが上書きされる。
     */
    data: {
      type: Object as PropType<LoadableData<StudentEditData>>,
      required: true,
    },

    /**
     * 編集状態。
     * editingフラグと、編集モード時に用いるデータを持つ。
     */
    editState: {
      type: Object as PropType<StudentEditState>,
      required: true,
    },

    selectedTab: {
      type: String as PropType<StudentEditTableTabValue>,
      required: true,
    },

    onClickBack: { type: Function as PropType<() => void>, required: true },
    startEditing: { type: Function as PropType<() => void>, required: true },
    cancelEditing: { type: Function as PropType<() => void>, required: true },
    save: { type: Function as PropType<() => void>, required: true },
    deleteUser: { type: Function as PropType<() => void>, required: true },

    onInput: {
      type: Function as PropType<
        (columnId: StudentColumnId, value: any) => void
      >,
      required: true,
    },

    onSelectTab: {
      type: Function as PropType<(tab: StudentEditTableTabValue) => void>,
      required: true,
    },
  },
  setup(props) {
    const student = computed<Student | null>(() => {
      if (props.editState.editing) return props.editState.data.student;
      if (props.data.hasData) return props.data.data.student;
      return null;
    });

    const studentColumns = computed(() => {
      const _student = student.value;
      if (isNullish(_student)) return null;
      return getStudentColumnsGrouped(props.schoolYear, _student);
    });

    const profileColumnGroup = computed(
      () => studentColumns.value?.profileColumnGroup ?? null,
    );
    const healthColumnGroup = computed(
      () => studentColumns.value?.healthColumnGroup ?? null,
    );
    const customColumnGroup = computed(
      () => studentColumns.value?.customColumnGroup ?? null,
    );

    return {
      student,

      profileColumnGroup,
      healthColumnGroup,
      customColumnGroup,
    };
  },
});
