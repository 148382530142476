
import { computed, defineComponent, PropType } from "vue";
import { UserColumnVisibility } from "@/ts/app/columns/visibility/user-column-visibility";
import { ObjectInternalName } from "@/ts/app/object-name";

export default defineComponent({
  name: "UserColumnsSelectSection",
  props: {
    sectionTitle: { type: String, required: true },
    columnDefs: {
      type: Array as PropType<{ id: string; name: string }[]>,
      required: true,
    },

    columnVisibility: {
      type: Object as PropType<
        UserColumnVisibility<ObjectInternalName, string>
      >,
      required: true,
    },

    onToggle: {
      type: Function as PropType<
        (updated: UserColumnVisibility<ObjectInternalName, string>) => void
      >,
      required: true,
    },
  },
  setup(props) {
    const overallSelectionState = computed((): "none" | "some" | "all" => {
      let foundChecked = false;
      let foundUnchecked = false;
      for (const isChecked of Object.values(props.columnVisibility.state)) {
        if (isChecked) foundChecked = true;
        else foundUnchecked = true;
      }

      if (foundChecked && foundUnchecked) return "some";
      else if (foundChecked && !foundUnchecked) return "all";
      else if (!foundChecked && foundUnchecked) return "none";
      else return "none";
    });

    return {
      overallSelectionState,

      onToggleInternal: (id: string, e: InputEvent) => {
        const updated = {
          ...props.columnVisibility.state,
          [id]: (e.target as HTMLInputElement).checked,
        };
        props.onToggle({
          groupName: props.columnVisibility.groupName,
          state: updated,
        });
      },
      onToggleOverallSelection: () => {
        // 参考: https://github.com/vuejs/vue/issues/9535#issuecomment-466217819
        // TODO WindowsのChrome以外でもちゃんと動くか確認。
        setTimeout(() => {
          const changeTo = overallSelectionState.value === "none";
          const updated = Object.fromEntries(
            Object.keys(props.columnVisibility.state).map((key) => [
              key,
              changeTo,
            ]),
          );
          props.onToggle({
            groupName: props.columnVisibility.groupName,
            state: updated,
          });
        });
      },
    };
  },
});
