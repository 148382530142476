import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SchoolEdit = _resolveComponent("SchoolEdit")!

  return (_openBlock(), _createBlock(_component_SchoolEdit, {
    "is-admin": _ctx.isAdmin,
    school: _ctx.school,
    "unsaved-data": _ctx.unsavedData,
    "logo-updated-at": _ctx.logoUpdatedAt,
    "on-input-school-name": _ctx.onInputSchoolName,
    "on-input-logo": _ctx.onInputLogo,
    "start-editing": _ctx.startEditing,
    "cancel-editing": _ctx.cancelEditing,
    save: _ctx.save
  }, null, 8, ["is-admin", "school", "unsaved-data", "logo-updated-at", "on-input-school-name", "on-input-logo", "start-editing", "cancel-editing", "save"]))
}