
import { computed, defineComponent, ref, watch, watchEffect } from "vue";
import { useStudentStore } from "@/store/student-store";
import { useAppStore } from "@/store/app-store";
import log from "loglevel";
import {
  StudentSearchCondition,
  studentSearchConditionToQueryParams,
} from "@/ts/objects/search-condition/student-search-condition";
import { isNullish } from "@/ts/utils/common-util";
import { useRouter } from "vue-router";
import { useStudentRoute } from "@/router/use-student-route";
import debounce from "lodash/debounce";
import StudentList from "@/views/student/StudentList/StudentList.vue";
import { useSearchConditionPopup } from "@/ts/composables/use-search-condition-popup";
import { searchConditionClassDefault } from "@/ts/objects/search-condition/search-condition-class";
import { useUserService } from "@/composables/provide-user-service";
import { ExportDropdownOptionValue } from "@/components/dropdowns/ExportDropdown/export-dropdown";
import { studentToExportingCSVRow } from "@/ts/app/columns/csv/student-csv";
import { downloadAsCSV, getToday, unparseCSV } from "@/ts/utils/app-util";
import { format } from "date-fns";
import { names } from "@/ts/app/object-name";
import { usePushRouteAfterAuth } from "@/composables/use-push-route-after-auth";
import { SearchResult } from "@/ts/app/search-result";
import { Student } from "@/ts/objects/entity/student";
import { StudentColumnVisibilityAll } from "@/ts/app/columns/visibility/student-column-visibility";
import {
  studentColumnVisibilityToListColumnIds,
  StudentListColumnId,
  studentListColumnIds,
} from "@/ts/app/columns/user-list/student-list-columns";
import { getStudentColumnDefs } from "@/ts/app/columns/def/student/student-column";

export default defineComponent({
  name: "StudentListContainer",
  components: { StudentList },
  setup() {
    const appStore = useAppStore();
    const studentStore = useStudentStore();
    const router = useRouter();
    const userService = useUserService();

    const isAdmin = computed(() => appStore.isAdmin);

    const {
      searchPageNum,
      queryText,
      schoolYear,
      searchConditionClass,
      searchConditionInSchoolState,
      searchConditionSort,
      searchConditionNumResults,
    } = useStudentRoute(getToday(), appStore.currentSchoolYear);

    // 検索条件
    const searchCondition = computed(
      (): StudentSearchCondition => ({
        schoolYear: schoolYear.value,
        queryText: queryText.value,
        inSchoolState: searchConditionInSchoolState.value,
        class: searchConditionClass.value,
        sort: searchConditionSort.value,
        numResults: searchConditionNumResults.value,
      }),
    );

    // fetchStudents
    const debouncedFetchStudentsInternal = debounce(() => {
      studentStore.fetchStudents(
        userService,
        searchCondition.value,
        searchPageNum.value,
      );
    }, 1); // これでちゃんと検索条件がリアクティブになるようだが、気を付けておくこと。
    const fetchStudents = () => {
      studentStore.clearStudents();
      debouncedFetchStudentsInternal();
    };

    // reload
    const reload = async (
      page: number = 1,
      schoolYearChanged: boolean = false,
    ) => {
      if (schoolYearChanged) {
        // 年度を変更したら、クラス条件のみリセットする。
        searchConditionClass.value = searchConditionClassDefault(
          schoolYear.value,
        );
      }

      searchPageNum.value = page;
      await router.push({
        path: "/student/list",
        query: {
          page,
          ...studentSearchConditionToQueryParams(searchCondition.value),
        },
      });
      fetchStudents();
    };

    // 年度ポップアップ
    const [
      isSchoolYearPopupActive,
      changeSchoolYearPopupActiveness,
      onConfirmConditionSchoolYear,
    ] = useSearchConditionPopup(schoolYear, () => reload(1, true));

    // クラスポップアップ
    const [
      isClassPopupActive,
      changeClassPopupActiveness,
      onConfirmConditionClass,
    ] = useSearchConditionPopup(searchConditionClass, reload);

    // 在学状態ポップアップ
    const [
      isInSchoolStatePopupActive,
      changeInSchoolStatePopupActiveness,
      onConfirmConditionInSchoolState,
    ] = useSearchConditionPopup(searchConditionInSchoolState, reload);

    // 並び替え条件ポップアップ
    const [
      isSortPopupActive,
      changeSortPopupActiveness,
      onConfirmConditionSort,
    ] = useSearchConditionPopup(searchConditionSort, reload);

    // 件数ポップアップ
    const [
      isNumResultsPopupActive,
      changeNumResultsPopupActiveness,
      onConfirmConditionNumResults,
    ] = useSearchConditionPopup(searchConditionNumResults, reload);

    const searchResult = computed<SearchResult<Student> | null>(
      () => studentStore.studentSearchResult,
    );

    const numResults = ref<number | null>(null); // 検索結果数だが、表示に使うために古い値も保持する。よって正確な値ではないので注意。
    watchEffect(() => {
      const _searchResult = searchResult.value;
      if (isNullish(_searchResult)) return;
      numResults.value = _searchResult.numSearchResults;
    });

    fetchStudents();

    const debouncedFetchClassesInternal = debounce(
      () => studentStore.fetchClasses(userService, schoolYear.value),
      1,
    );
    const fetchClasses = () => {
      studentStore.clearClasses();
      debouncedFetchClassesInternal();
    };
    watch(schoolYear, fetchClasses);
    fetchClasses();

    const allClasses = computed(
      () => studentStore.allClassesOnSchoolYear ?? [],
    );

    const isColumnSelectOpen = ref(true);
    const columnVisibility = computed<StudentColumnVisibilityAll>(
      () => studentStore.columnVisibility,
    );

    const pushRouteAfterAuth = usePushRouteAfterAuth(userService);
    const syncWithGWS = () => pushRouteAfterAuth("/student/sync");

    const allColumnDefs = computed(() =>
      getStudentColumnDefs(
        schoolYear.value,
        studentStore.customColumnNames.data,
      ),
    );
    const onClickExportButton = (value: ExportDropdownOptionValue) => {
      log.debug("StudentListContainer: onClickExportButton");

      const _searchResult = searchResult.value;
      const _schoolYear = schoolYear.value;
      const _customColumnNames = studentStore.customColumnNames;
      const _columnVisibility = columnVisibility.value;
      if (isNullish(_searchResult) || !_customColumnNames.loaded) return;

      const _students = _searchResult.data;
      const columnIds: StudentListColumnId[] =
        value === "export-all-columns"
          ? studentListColumnIds
          : studentColumnVisibilityToListColumnIds(_columnVisibility);

      const csvText = unparseCSV(
        _students,
        (s) => studentToExportingCSVRow(s, _schoolYear, columnIds),
        allColumnDefs.value,
      );
      downloadAsCSV(
        csvText,
        `${names.student.d}_${format(new Date(), "yyyyMMdd'T'HHmmss")}.csv`,
      );
    };

    const goEditCustomColumns = () => {
      router.push("/student/customcol");
    };

    return {
      isAdmin,

      columnVisibility,
      schoolYear,

      searchResult,
      numResults,
      pageNumber: searchPageNum,

      searchCondition,

      allClasses,

      onInputQueryText: (text: string) => {
        queryText.value = text;
      },
      onConfirmQueryText: () => {
        reload();
      },

      isSchoolYearPopupActive,
      changeSchoolYearPopupActiveness,
      onConfirmConditionSchoolYear,

      isClassPopupActive,
      changeClassPopupActiveness,
      onConfirmConditionClass,

      isInSchoolStatePopupActive,
      changeInSchoolStatePopupActiveness,
      onConfirmConditionInSchoolState,

      isSortPopupActive,
      changeSortPopupActiveness,
      onConfirmConditionSort,

      isNumResultsPopupActive,
      changeNumResultsPopupActiveness,
      onConfirmConditionNumResults,

      isColumnSelectOpen,
      toggleColumnSelectVisibility: () => {
        isColumnSelectOpen.value = !isColumnSelectOpen.value;
      },

      syncWithGWS,
      goEditCustomColumns,
      onClickExportButton,

      userIdToEditPath: (userId: string) => {
        return `/student/edit/${userId}`;
      },

      movePageTo: (n: number) => {
        searchPageNum.value = n;
        reload(n);
      },

      onChangeColumnVisibility: studentStore.updateColumnVisibility,
    };
  },
});
