
import { computed, defineComponent, PropType, ref } from "vue";
import BaseCustomDropdownOptions from "@/components/dropdowns/BaseCustomDropdown/BaseCustomDropdownOptions/BaseCustomDropdownOptions.vue";
import { DropdownItem } from "@/ts/app/dropdown-item";

export default defineComponent({
  name: "BaseCustomDropdown",
  components: { BaseCustomDropdownOptions },
  props: {
    items: { type: Array as PropType<DropdownItem[]>, required: true },
    enabled: { type: Boolean, required: true },

    optionsZIndex: { type: Number, default: 10, required: false },
    optionsWidth: { type: Number, default: 120, required: false },
    optionsAlign: {
      type: String as PropType<"left" | "center" | "right">,
      default: "left",
      required: false,
    },
    /**
     * optionsAlignを適用した後、さらに左右にずらしたければ、指定する。
     * 正の値だと右にずれる。
     */
    optionsHorizontalPosition: { type: Number, default: 0, required: false },

    onSelect: {
      type: Function as PropType<(value: string | null) => void>,
      required: true,
    },
  },
  setup(props) {
    const active = ref(false);
    const activate = () => (active.value = true);
    const deactivate = () => (active.value = false);

    const buttonEnabled = computed(() => props.enabled && !active.value);

    const onSelectInternal = (value: string | null) => {
      deactivate();
      if (!props.enabled) return;
      props.onSelect(value);
    };

    return {
      active,
      activate,
      deactivate,

      buttonEnabled,

      onSelectInternal,

      styles: {
        "--options-horizontal-position": `${props.optionsHorizontalPosition}px`,
      },
    };
  },
});
