import { names } from "@/ts/app/object-name";
import { TeacherColumnVisibilityAll } from "@/ts/app/columns/visibility/teacher-column-visibility";
import { typedObjectEntries } from "@/ts/utils/common-util";

export const teacherListProfileColumns = [
  names.userId,
  names.googleMail,
  names.name,

  names.nameKana,
  names.familyName,
  names.givenName,

  names.classesOfTheYear,

  names.teacherType,
  names.zipcode,
  names.address,
  names.email,
  names.mobilePhone,
  names.landlinePhone,
  names.foreignType,
] as const;

export const teacherListProfileColumnIds = teacherListProfileColumns.map(
  (c) => c.i,
);

export type TeacherListProfileColumnId =
  typeof teacherListProfileColumnIds[number];

export const teacherListColumns = [...teacherListProfileColumns] as const;

export const teacherListColumnIds = teacherListColumns.map((c) => c.i);

export type TeacherListColumnId = typeof teacherListColumnIds[number];

export function teacherColumnVisibilityToListColumnIds(
  v: TeacherColumnVisibilityAll,
): TeacherListColumnId[] {
  const profileColumns = typedObjectEntries(v.profileColumnVisibility.state);

  const checkedColIds = profileColumns
    .filter(([_, checked]) => checked)
    .map(([colId, _]) => colId);

  // 並び順を正しくするため、filterし直す。
  return [...teacherListProfileColumnIds].filter((colId) =>
    checkedColIds.includes(colId),
  );
}
