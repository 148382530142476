
import { defineComponent } from "vue";

export default defineComponent({
  name: "SimpleTextItem1",
  props: {
    name: { type: String, required: true },
    value: { type: String, required: true },
    linkEnabled: { type: Boolean, required: false, default: false },
    linkPath: { type: String, required: false },
  },
});
