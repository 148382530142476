
import { computed, defineComponent, ref, watchEffect } from "vue";
import TeacherImport from "@/views/teacher/TeacherImport/TeacherImport.vue";
import { useTeacherStore } from "@/store/teacher-store";
import { useUserService } from "@/composables/provide-user-service";
import { useRouter } from "vue-router";
import { isNullish } from "@/ts/utils/common-util";
import { useDebouncedDataLoading } from "@/composables/use-debounced-data-loading";
import {
  importedToTeacherCSVRow,
  TeacherCSVFile,
  TeacherCSVRow,
  updateTeachersWithCSVRows,
} from "@/ts/app/columns/csv/teacher-csv";
import { Teacher, updatingTeacherToPartial } from "@/ts/objects/entity/teacher";
import log from "loglevel";
import { names } from "@/ts/app/object-name";
import { mapData } from "@/ts/app/loadable-data";
import { parseCSV } from "@/ts/utils/app-util";
import { AppError } from "@/ts/app/error/app-error";
import { useAppToast } from "@/composables/use-app-toast";
import { getTeacherColumnDefs } from "@/ts/app/columns/def/teacher/teacher-column";
import { useAppStore } from "@/store/app-store";

export default defineComponent({
  name: "TeacherImportContainer",
  components: { TeacherImport },
  setup() {
    const appStore = useAppStore();
    const teacherStore = useTeacherStore();
    const userService = useUserService();
    const router = useRouter();
    const { showError, showSuccess } = useAppToast();

    const showErrorImportFailed = (error: AppError) => {
      showError(
        `${names.teacherCSV.d}のインポートに失敗しました: ${error.displayMessage}`,
      );
    };

    const schoolYear = appStore.currentSchoolYear;

    const saving = ref(false); // セーブ中(confirm後)はtrue。trueの間、操作できない。

    const [teachersBefore, debouncedLoadTeachersBefore, clearTeachersBefore] =
      useDebouncedDataLoading(
        null,
        (args: { userIds: string[] }) => {
          return userService.listTeachersByPost(args.userIds);
        },
        200,
        true,
        true,
        showErrorImportFailed,
      );

    const csvFile = ref<TeacherCSVFile | null>(null);

    const [teachersAfter, debouncedLoadTeachersAfter, clearTeachersAfter] =
      useDebouncedDataLoading(
        null,
        async (args: {
          filename: string;
          teachers: Teacher[];
          rows: TeacherCSVRow[];
        }) => {
          return updateTeachersWithCSVRows(
            args.filename,
            args.teachers,
            args.rows,
          );
        },
        200,
        true,
        true,
        showErrorImportFailed,
      );
    watchEffect(() => {
      log.debug(`TeacherImportContainer: watchEffect: Triggered.`);

      const _csvFile = csvFile.value;
      const _teachersBefore = teachersBefore.value;
      if (isNullish(_csvFile) || !_teachersBefore.hasFreshData) {
        log.debug(`TeacherImportContainer: watchEffect: clearTeachersAfter`);
        clearTeachersAfter();
        return;
      }

      log.debug(
        `TeacherImportContainer: watchEffect: debouncedLoadTeachersAfter`,
      );
      debouncedLoadTeachersAfter({
        filename: _csvFile.filename,
        teachers: _teachersBefore.data,
        rows: _csvFile.rows,
      });
    });

    const allColumnDefs = getTeacherColumnDefs(schoolYear);
    const onSelectFile = async (file: File) => {
      if (saving.value) return;

      const result = await parseCSV(
        file,
        importedToTeacherCSVRow,
        allColumnDefs,
        true,
      );
      if (!result.ok) {
        showErrorImportFailed(result.error);
        return;
      }

      const _csvFile: TeacherCSVFile = {
        filename: file.name,
        rows: result.data,
      };
      csvFile.value = _csvFile;

      debouncedLoadTeachersBefore({
        userIds: _csvFile.rows.map((r) => r.userId),
      });
    };
    const onUnselectFile = () => {
      if (saving.value) return;

      csvFile.value = null;
      clearTeachersBefore();
      clearTeachersAfter();
    };

    const columnVisibility = computed(() => teacherStore.columnVisibility);
    const isColumnSelectOpen = ref(true);
    const toggleColumnSelectVisibility = () =>
      (isColumnSelectOpen.value = !isColumnSelectOpen.value);
    const onChangeColumnVisibility = teacherStore.updateColumnVisibility;

    const onConfirmImport = async () => {
      if (saving.value) return;

      const _teachersAfter = teachersAfter.value;
      if (!_teachersAfter.hasFreshData) {
        return;
      }

      saving.value = true;

      const partialTeachers = _teachersAfter.data.map((t) =>
        updatingTeacherToPartial(t),
      );
      const result = await userService.batchPatchTeacher(partialTeachers);
      if (!result.ok) {
        showErrorImportFailed(result.error);
        saving.value = false;
        return;
      }

      showSuccess(
        `${partialTeachers.length}件の${names.teacher.d}データをインポートしました。`,
      );

      csvFile.value = null;
      clearTeachersBefore();
      clearTeachersAfter();

      saving.value = false;
    };

    const onClickBack = () => router.back();

    return {
      schoolYear,
      saving,

      teachersAfter: computed(() =>
        mapData(teachersAfter.value, (d) => d.map((t) => t.teacher)),
      ),

      onSelectFile,
      onUnselectFile,

      columnVisibility,
      isColumnSelectOpen,
      toggleColumnSelectVisibility,
      onChangeColumnVisibility,

      onConfirmImport,
      onClickBack,
    };
  },
});
