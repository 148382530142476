export const classSubPageNames = ["list", "details"] as const;
export type ClassSubPageName = typeof classSubPageNames[number];

export function isClassSubPageName(value: unknown): value is ClassSubPageName {
  return (
    typeof value === "string" && classSubPageNames.some((v) => v === value)
  );
}

export function asClassSubPageNameOrNull(
  value: unknown,
): ClassSubPageName | null {
  if (isClassSubPageName(value)) return value;
  return null;
}
