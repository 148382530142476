
import { computed, defineComponent, PropType } from "vue";
import GuardianSubPageHeading from "@/views/guardian/_components/GuardianSubPageHeading/GuardianSubPageHeading.vue";
import EditButtonSet from "@/components/other/EditButtonSet/EditButtonSet.vue";
import UserEditHeaderUpper from "@/components/table/edit/UserEditHeaderUpper/UserEditHeaderUpper.vue";
import {
  getGuardianColumnsGrouped,
  GuardianColumnId,
} from "@/ts/app/columns/def/guardian/guardian-column";
import { GuardianEditTableTabValue } from "@/views/guardian/GuardianEdit/guardian-edit-table-tab-value";
import { isNullish } from "@/ts/utils/common-util";
import GuardianEditTable from "@/views/guardian/GuardianEdit/GuardianEditTable/GuardianEditTable.vue";
import { LoadableData } from "@/ts/app/loadable-data";
import {
  GuardianEditData,
  GuardianEditState,
} from "@/views/guardian/GuardianEdit/guardian-edit-state";
import { Guardian } from "@/ts/objects/entity/guardian";

export default defineComponent({
  name: "GuardianEdit",
  components: {
    GuardianEditTable,
    UserEditHeaderUpper,
    EditButtonSet,
    GuardianSubPageHeading,
  },
  props: {
    isAdmin: { type: Boolean, required: true },

    /**
     * 閲覧モード時に用いるデータ。
     *
     * 編集中には表示に使わないし、変更もされない。
     * 編集を開始したとき、このデータが編集用データとして複製される。
     * 編集をキャンセルしたとき、このデータに戻る。
     * 編集をセーブしたとき、このデータが上書きされる。
     */
    data: {
      type: Object as PropType<LoadableData<GuardianEditData>>,
      required: true,
    },

    /**
     * 編集状態。
     * editingフラグと、編集モード時に用いるデータを持つ。
     */
    editState: {
      type: Object as PropType<GuardianEditState>,
      required: true,
    },

    selectedTab: {
      type: String as PropType<GuardianEditTableTabValue>,
      required: true,
    },

    onClickBack: { type: Function as PropType<() => void>, required: true },
    startEditing: { type: Function as PropType<() => void>, required: true },
    cancelEditing: { type: Function as PropType<() => void>, required: true },
    save: { type: Function as PropType<() => void>, required: true },
    deleteUser: { type: Function as PropType<() => void>, required: true },

    onInput: {
      type: Function as PropType<
        (columnId: GuardianColumnId, value: any) => void
      >,
      required: true,
    },

    onSelectTab: {
      type: Function as PropType<(tab: GuardianEditTableTabValue) => void>,
      required: true,
    },
  },
  setup(props) {
    const guardian = computed<Guardian | null>(() => {
      if (props.editState.editing) return props.editState.data.guardian;
      if (props.data.hasData) return props.data.data.guardian;
      return null;
    });

    const guardianColumns = computed(() => {
      const _guardian = guardian.value;
      if (isNullish(_guardian)) return null;
      return getGuardianColumnsGrouped(_guardian);
    });

    const profileColumnGroup = computed(
      () => guardianColumns.value?.profileColumnGroup ?? null,
    );
    const notesColumnGroup = computed(
      () => guardianColumns.value?.notesColumnGroup ?? null,
    );

    return {
      guardian,

      profileColumnGroup,
      notesColumnGroup,
    };
  },
});
