
import { defineComponent, PropType } from "vue";
import StudentImportSchoolYearArea from "@/views/student/StudentImport/StudentImportSchoolYearArea/StudentImportSchoolYearArea.vue";
import UploadArea from "@/components/other/UploadArea/UploadArea.vue";
import StudentColumnsSelect from "@/views/student/_components/StudentColumnsSelect/StudentColumnsSelect.vue";
import {
  StudentColumnVisibility,
  StudentColumnVisibilityAll,
} from "@/ts/app/columns/visibility/student-column-visibility";
import { Student } from "@/ts/objects/entity/student";
import StudentListTable from "@/views/student/_components/StudentListTable/StudentListTable.vue";
import ConfirmImportButton from "@/components/buttons/ConfirmImportButton/ConfirmImportButton.vue";
import StudentSubPageHeading from "@/views/student/_components/StudentSubPageHeading/StudentSubPageHeading.vue";
import { LoadableData } from "@/ts/app/loadable-data";
import LoadingBlock from "@/components/loading/LoadingBlock/LoadingBlock.vue";

export default defineComponent({
  name: "StudentImport",
  components: {
    LoadingBlock,
    StudentSubPageHeading,
    ConfirmImportButton,
    StudentListTable,
    StudentColumnsSelect,
    UploadArea,
    StudentImportSchoolYearArea,
  },
  props: {
    schoolYear: { type: Number, default: null, required: false },
    onInputSchoolYear: {
      type: Function as PropType<(schoolYear: number) => void>,
      required: true,
    },
    saving: { type: Boolean, required: true },

    fileLoaded: { type: Boolean, required: true },

    students: {
      type: Object as PropType<LoadableData<Student[]>>,
      required: true,
    },

    onSelectFile: {
      type: Function as PropType<(file: File) => void>,
      required: true,
    },
    onUnselectFile: {
      type: Function as PropType<() => void>,
      required: true,
    },

    columnVisibility: {
      type: Object as PropType<StudentColumnVisibilityAll>,
      required: true,
    },
    isColumnSelectOpen: { type: Boolean, required: true },
    toggleColumnSelectVisibility: {
      type: Function as PropType<() => void>,
      required: true,
    },
    onChangeColumnVisibility: {
      type: Function as PropType<(v: StudentColumnVisibility) => void>,
      required: true,
    },

    onConfirmImport: { type: Function as PropType<() => void>, required: true },

    onClickBack: { type: Function as PropType<() => void>, required: true },
  },
  setup(props) {
    return {
      onUnselectFileInternal: () => {
        props.onUnselectFile();
      },
    };
  },
});
