import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createBlock(_component_BaseButton, {
    label: _ctx.label,
    color: _ctx.color,
    "background-color": _ctx.backgroundColor,
    "background-color-on-hover": _ctx.backgroundColorOnHover,
    "icon-type": _ctx.iconType,
    icon: _ctx.icon,
    "icon-padding-bottom": 1,
    "border-color": _ctx.borderColor,
    width: _ctx.width,
    "padding-horizontal": 12,
    enabled: _ctx.enabled,
    "on-click": _ctx.onClick
  }, null, 8, ["label", "color", "background-color", "background-color-on-hover", "icon-type", "icon", "border-color", "width", "enabled", "on-click"]))
}