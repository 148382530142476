import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8fa6eb7e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "user-list-table-cell-date-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextBalloon = _resolveComponent("TextBalloon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", {
      class: "value",
      onMouseenter: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onMouseEnter && _ctx.onMouseEnter(...args))),
      onMouseleave: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onMouseLeave && _ctx.onMouseLeave(...args))),
      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.copyToClipboard && _ctx.copyToClipboard(...args)))
    }, _toDisplayString(_ctx.displayValue), 33),
    (_ctx.hovering && !_ctx.isDisplayValueEmpty)
      ? (_openBlock(), _createBlock(_component_TextBalloon, {
          key: 0,
          text: _ctx.displayValue,
          "on-cursor": true
        }, null, 8, ["text"]))
      : _createCommentVNode("", true)
  ]))
}