import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-757cd22b"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["aria-label"]
const _hoisted_2 = { class: "text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserSearchBox = _resolveComponent("UserSearchBox")!
  const _component_PopupConfirmButton = _resolveComponent("PopupConfirmButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: "user-select-popup-container",
    role: "dialog",
    "aria-label": _ctx.text,
    style: _normalizeStyle(_ctx.styles)
  }, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.text), 1),
    _createVNode(_component_UserSearchBox, {
      class: "search-box",
      width: _ctx.searchBoxWidth,
      height: 36,
      placeholder: _ctx.searchBoxPlaceholder,
      "placeholder-font-size": _ctx.searchBoxPlaceholderFontSize,
      "border-color": "light-gray",
      "border-color-on-focus": "dark-gray",
      "num-search-results": _ctx.maxSearchResults,
      "search-users": _ctx.searchUsers,
      "on-select-user": _ctx.onSelectUser,
      "on-unselect-user": _ctx.onUnselectUser
    }, null, 8, ["width", "placeholder", "placeholder-font-size", "num-search-results", "search-users", "on-select-user", "on-unselect-user"]),
    _createVNode(_component_PopupConfirmButton, {
      class: "confirm-button",
      width: 90,
      height: 26,
      label: "OK",
      enabled: _ctx.selectedUser != null,
      "on-click": _ctx.onConfirmInternal
    }, null, 8, ["enabled", "on-click"])
  ], 12, _hoisted_1))
}