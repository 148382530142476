import { Student, StudentCustom } from "@/ts/objects/entity/student";
import { StringType } from "@/ts/app/columns/def/column-types";
import { StudentColumnDef } from "@/ts/app/columns/def/student/student-column";
import { genUserColumnDefs } from "@/ts/app/columns/def/user-column-def";
import {
  UserColumnDefGroup,
  UserColumnGroup,
} from "@/ts/app/columns/def/user-column-group";
import { UserColumn } from "@/ts/app/columns/def/user-column";
import { StudentCustomColumnNames } from "@/ts/objects/value/student-custom-column-names";
import { names } from "@/ts/app/object-name";

export const studentCustomColumnGroupName = names.customItems.i;
export type StudentCustomColumnGroupName = typeof studentCustomColumnGroupName;

export type StudentCustomColumnId = keyof StudentCustom;

export type StudentCustomColumnDef<T> = StudentColumnDef<
  StudentCustomColumnId,
  T
>;

export type StudentCustomColumn<T> = UserColumn<
  StudentCustomColumnId,
  Student,
  T
>;

export type StudentCustomColumnDefGroup = UserColumnDefGroup<
  StudentCustomColumnGroupName,
  StudentCustomColumnId,
  Student
>;

export type StudentCustomColumnGroup = UserColumnGroup<
  StudentCustomColumnGroupName,
  StudentCustomColumnId,
  Student
>;

export function studentCustomColumnDefs(
  colNames: StudentCustomColumnNames,
): StudentCustomColumnDef<any>[] {
  return genUserColumnDefs<StudentCustomColumnId, Student, any>([
    {
      id: "custom01",
      name: colNames.custom01,
      width: 120,
      type: StringType,
      getValue: (s) => s.custom01.value,
      setValue: (s, v) => ({
        ...s,
        custom01: { name: s.custom01.name, value: v },
      }),
    },
    {
      id: "custom02",
      name: colNames.custom02,
      width: 120,
      type: StringType,
      getValue: (s) => s.custom02.value,
      setValue: (s, v) => ({
        ...s,
        custom02: { name: s.custom02.name, value: v },
      }),
    },
    {
      id: "custom03",
      name: colNames.custom03,
      width: 120,
      type: StringType,
      getValue: (s) => s.custom03.value,
      setValue: (s, v) => ({
        ...s,
        custom03: { name: s.custom03.name, value: v },
      }),
    },
    {
      id: "custom04",
      name: colNames.custom04,
      width: 120,
      type: StringType,
      getValue: (s) => s.custom04.value,
      setValue: (s, v) => ({
        ...s,
        custom04: { name: s.custom04.name, value: v },
      }),
    },
    {
      id: "custom05",
      name: colNames.custom05,
      width: 120,
      type: StringType,
      getValue: (s) => s.custom05.value,
      setValue: (s, v) => ({
        ...s,
        custom05: { name: s.custom05.name, value: v },
      }),
    },
    {
      id: "custom06",
      name: colNames.custom06,
      width: 120,
      type: StringType,
      getValue: (s) => s.custom06.value,
      setValue: (s, v) => ({
        ...s,
        custom06: { name: s.custom06.name, value: v },
      }),
    },
    {
      id: "custom07",
      name: colNames.custom07,
      width: 120,
      type: StringType,
      getValue: (s) => s.custom07.value,
      setValue: (s, v) => ({
        ...s,
        custom07: { name: s.custom07.name, value: v },
      }),
    },
    {
      id: "custom08",
      name: colNames.custom08,
      width: 120,
      type: StringType,
      getValue: (s) => s.custom08.value,
      setValue: (s, v) => ({
        ...s,
        custom08: { name: s.custom08.name, value: v },
      }),
    },
    {
      id: "custom09",
      name: colNames.custom09,
      width: 120,
      type: StringType,
      getValue: (s) => s.custom09.value,
      setValue: (s, v) => ({
        ...s,
        custom09: { name: s.custom09.name, value: v },
      }),
    },
    {
      id: "custom10",
      name: colNames.custom10,
      width: 120,
      type: StringType,
      getValue: (s) => s.custom10.value,
      setValue: (s, v) => ({
        ...s,
        custom10: { name: s.custom10.name, value: v },
      }),
    },
    {
      id: "custom11",
      name: colNames.custom11,
      width: 120,
      type: StringType,
      getValue: (s) => s.custom11.value,
      setValue: (s, v) => ({
        ...s,
        custom11: { name: s.custom11.name, value: v },
      }),
    },
    {
      id: "custom12",
      name: colNames.custom12,
      width: 120,
      type: StringType,
      getValue: (s) => s.custom12.value,
      setValue: (s, v) => ({
        ...s,
        custom12: { name: s.custom12.name, value: v },
      }),
    },
    {
      id: "custom13",
      name: colNames.custom13,
      width: 120,
      type: StringType,
      getValue: (s) => s.custom13.value,
      setValue: (s, v) => ({
        ...s,
        custom13: { name: s.custom13.name, value: v },
      }),
    },
    {
      id: "custom14",
      name: colNames.custom14,
      width: 120,
      type: StringType,
      getValue: (s) => s.custom14.value,
      setValue: (s, v) => ({
        ...s,
        custom14: { name: s.custom14.name, value: v },
      }),
    },
    {
      id: "custom15",
      name: colNames.custom15,
      width: 120,
      type: StringType,
      getValue: (s) => s.custom15.value,
      setValue: (s, v) => ({
        ...s,
        custom15: { name: s.custom15.name, value: v },
      }),
    },
    {
      id: "custom16",
      name: colNames.custom16,
      width: 120,
      type: StringType,
      getValue: (s) => s.custom16.value,
      setValue: (s, v) => ({
        ...s,
        custom16: { name: s.custom16.name, value: v },
      }),
    },
    {
      id: "custom17",
      name: colNames.custom17,
      width: 120,
      type: StringType,
      getValue: (s) => s.custom17.value,
      setValue: (s, v) => ({
        ...s,
        custom17: { name: s.custom17.name, value: v },
      }),
    },
    {
      id: "custom18",
      name: colNames.custom18,
      width: 120,
      type: StringType,
      getValue: (s) => s.custom18.value,
      setValue: (s, v) => ({
        ...s,
        custom18: { name: s.custom18.name, value: v },
      }),
    },
    {
      id: "custom19",
      name: colNames.custom19,
      width: 120,
      type: StringType,
      getValue: (s) => s.custom19.value,
      setValue: (s, v) => ({
        ...s,
        custom19: { name: s.custom19.name, value: v },
      }),
    },
    {
      id: "custom20",
      name: colNames.custom20,
      width: 120,
      type: StringType,
      getValue: (s) => s.custom20.value,
      setValue: (s, v) => ({
        ...s,
        custom20: { name: s.custom20.name, value: v },
      }),
    },
    {
      id: "custom21",
      name: colNames.custom21,
      width: 120,
      type: StringType,
      getValue: (s) => s.custom21.value,
      setValue: (s, v) => ({
        ...s,
        custom21: { name: s.custom21.name, value: v },
      }),
    },
    {
      id: "custom22",
      name: colNames.custom22,
      width: 120,
      type: StringType,
      getValue: (s) => s.custom22.value,
      setValue: (s, v) => ({
        ...s,
        custom22: { name: s.custom22.name, value: v },
      }),
    },
    {
      id: "custom23",
      name: colNames.custom23,
      width: 120,
      type: StringType,
      getValue: (s) => s.custom23.value,
      setValue: (s, v) => ({
        ...s,
        custom23: { name: s.custom23.name, value: v },
      }),
    },
    {
      id: "custom24",
      name: colNames.custom24,
      width: 120,
      type: StringType,
      getValue: (s) => s.custom24.value,
      setValue: (s, v) => ({
        ...s,
        custom24: { name: s.custom24.name, value: v },
      }),
    },
    {
      id: "custom25",
      name: colNames.custom25,
      width: 120,
      type: StringType,
      getValue: (s) => s.custom25.value,
      setValue: (s, v) => ({
        ...s,
        custom25: { name: s.custom25.name, value: v },
      }),
    },
    {
      id: "custom26",
      name: colNames.custom26,
      width: 120,
      type: StringType,
      getValue: (s) => s.custom26.value,
      setValue: (s, v) => ({
        ...s,
        custom26: { name: s.custom26.name, value: v },
      }),
    },
    {
      id: "custom27",
      name: colNames.custom27,
      width: 120,
      type: StringType,
      getValue: (s) => s.custom27.value,
      setValue: (s, v) => ({
        ...s,
        custom27: { name: s.custom27.name, value: v },
      }),
    },
    {
      id: "custom28",
      name: colNames.custom28,
      width: 120,
      type: StringType,
      getValue: (s) => s.custom28.value,
      setValue: (s, v) => ({
        ...s,
        custom28: { name: s.custom28.name, value: v },
      }),
    },
    {
      id: "custom29",
      name: colNames.custom29,
      width: 120,
      type: StringType,
      getValue: (s) => s.custom29.value,
      setValue: (s, v) => ({
        ...s,
        custom29: { name: s.custom29.name, value: v },
      }),
    },
    {
      id: "custom30",
      name: colNames.custom30,
      width: 120,
      type: StringType,
      getValue: (s) => s.custom30.value,
      setValue: (s, v) => ({
        ...s,
        custom30: { name: s.custom30.name, value: v },
      }),
    },
    {
      id: "custom31",
      name: colNames.custom31,
      width: 120,
      type: StringType,
      getValue: (s) => s.custom31.value,
      setValue: (s, v) => ({
        ...s,
        custom31: { name: s.custom31.name, value: v },
      }),
    },
    {
      id: "custom32",
      name: colNames.custom32,
      width: 120,
      type: StringType,
      getValue: (s) => s.custom32.value,
      setValue: (s, v) => ({
        ...s,
        custom32: { name: s.custom32.name, value: v },
      }),
    },
    {
      id: "custom33",
      name: colNames.custom33,
      width: 120,
      type: StringType,
      getValue: (s) => s.custom33.value,
      setValue: (s, v) => ({
        ...s,
        custom33: { name: s.custom33.name, value: v },
      }),
    },
    {
      id: "custom34",
      name: colNames.custom34,
      width: 120,
      type: StringType,
      getValue: (s) => s.custom34.value,
      setValue: (s, v) => ({
        ...s,
        custom34: { name: s.custom34.name, value: v },
      }),
    },
    {
      id: "custom35",
      name: colNames.custom35,
      width: 120,
      type: StringType,
      getValue: (s) => s.custom35.value,
      setValue: (s, v) => ({
        ...s,
        custom35: { name: s.custom35.name, value: v },
      }),
    },
    {
      id: "custom36",
      name: colNames.custom36,
      width: 120,
      type: StringType,
      getValue: (s) => s.custom36.value,
      setValue: (s, v) => ({
        ...s,
        custom36: { name: s.custom36.name, value: v },
      }),
    },
    {
      id: "custom37",
      name: colNames.custom37,
      width: 120,
      type: StringType,
      getValue: (s) => s.custom37.value,
      setValue: (s, v) => ({
        ...s,
        custom37: { name: s.custom37.name, value: v },
      }),
    },
    {
      id: "custom38",
      name: colNames.custom38,
      width: 120,
      type: StringType,
      getValue: (s) => s.custom38.value,
      setValue: (s, v) => ({
        ...s,
        custom38: { name: s.custom38.name, value: v },
      }),
    },
    {
      id: "custom39",
      name: colNames.custom39,
      width: 120,
      type: StringType,
      getValue: (s) => s.custom39.value,
      setValue: (s, v) => ({
        ...s,
        custom39: { name: s.custom39.name, value: v },
      }),
    },
    {
      id: "custom40",
      name: colNames.custom40,
      width: 120,
      type: StringType,
      getValue: (s) => s.custom40.value,
      setValue: (s, v) => ({
        ...s,
        custom40: { name: s.custom40.name, value: v },
      }),
    },
  ]);
}
