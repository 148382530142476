import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3c267400"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "base-date-picker-container" }
const _hoisted_2 = { class: "wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_datepicker = _resolveComponent("datepicker")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_datepicker, {
        class: "datepicker",
        style: _normalizeStyle(_ctx.styles),
        modelValue: _ctx.modelValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modelValue) = $event)),
        placeholder: _ctx.placeholder,
        "disabled-dates": _ctx.disabledDates,
        "upper-limit": _ctx.upperLimit,
        "lower-limit": _ctx.lowerLimit,
        "starting-view": _ctx.startingView,
        "month-heading-format": _ctx.monthHeadingFormat,
        "month-list-format": _ctx.monthListFormat,
        "weekday-format": _ctx.weekdayFormat,
        "input-format": _ctx.inputFormat,
        locale: _ctx.locale,
        "week-starts-on": _ctx.weekStartsOn,
        disabled: _ctx.disabled,
        typeable: _ctx.typeable,
        "minimum-view": _ctx.minimumView,
        onFocus: _ctx.onFocus,
        onBlur: _ctx.onBlur
      }, null, 8, ["style", "modelValue", "placeholder", "disabled-dates", "upper-limit", "lower-limit", "starting-view", "month-heading-format", "month-list-format", "weekday-format", "input-format", "locale", "week-starts-on", "disabled", "typeable", "minimum-view", "onFocus", "onBlur"])
    ]),
    (_ctx.clearable)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          type: "button",
          class: "clear-button",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.clear && _ctx.clear(...args))),
          "aria-label": "Clear"
        }, [
          _createVNode(_component_font_awesome_icon, {
            class: "icon",
            icon: ['fas', 'times']
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}