import {
  DateValue,
  dateValueToDisplayValue,
} from "@/ts/objects/value/date-value";
import { LocationQuery, LocationQueryValue } from "vue-router";
import { asDateValueOrNull, isNullish } from "@/ts/utils/common-util";

const _subTypes = {
  all: "all",
  inSchool: "inSchool",
  graduated: "graduated",
  transferred: "transferred",
  graduatedOrTransferred: "graduatedOrTransferred",
} as const;

export const searchConditionInSchoolStateSubTypes = Object.values(_subTypes);

export type SearchConditionInSchoolStateSubType =
  typeof searchConditionInSchoolStateSubTypes[number];

export function isSearchConditionInSchoolStateSubType(
  value: unknown,
): value is SearchConditionInSchoolStateSubType {
  return (
    typeof value === "string" &&
    searchConditionInSchoolStateSubTypes.some((v) => v === value)
  );
}

export function asSearchConditionInSchoolStateSubTypeOrNull(
  value: unknown,
): SearchConditionInSchoolStateSubType | null {
  if (isSearchConditionInSchoolStateSubType(value)) return value;
  return null;
}

export type SearchConditionInSchoolState = {
  searchConditionType: "in-school-state";
  on: DateValue;
} & (
  | {
      subType: typeof _subTypes["all"];
    } // すべての生徒。
  | {
      subType: typeof _subTypes["inSchool"];
    } // 卒業済・転学済でない。
  | {
      subType: typeof _subTypes["graduated"];
    } // 卒業済である。(卒業済 = 卒業年月日に値があり、それが指定日付かそれ以前の日付)
  | {
      subType: typeof _subTypes["transferred"];
    } // 転学済である。(転学済 = 転学年月日に値があり、それが指定日付かそれ以前の日付)
  | {
      subType: typeof _subTypes["graduatedOrTransferred"];
    }
); // 卒業または転学済である。(卒業年月日か転学年月日に値があり、少なくとも一方が今日かそれ以前の日付である。)

export function searchConditionInSchoolStateDefault(
  date: DateValue,
): SearchConditionInSchoolState {
  return {
    searchConditionType: "in-school-state",
    on: date,
    subType: "all",
  };
}

export function searchConditionInSchoolStateToDisplayValue(
  cond: SearchConditionInSchoolState,
): string {
  switch (cond.subType) {
    case "all":
      return "すべて";
    case "inSchool":
      return "在学中";
    case "graduated":
      return "卒業済";
    case "transferred":
      return "転学済";
    case "graduatedOrTransferred":
      return "卒業・転学済";
  }
}

/**
 * Vue側のクエリパラメタ。
 * （API側ではなく。）
 */
type QueryParams = {
  readonly inSchoolStateSubType: string | undefined;
  readonly inSchoolStateOn: string | undefined;
};

export function searchConditionInSchoolStateFromQueryParams(
  today: DateValue,
  query: LocationQuery,
): SearchConditionInSchoolState {
  const _query = query as Record<
    keyof QueryParams,
    LocationQueryValue | LocationQueryValue[] | undefined
  >;
  const subType = asSearchConditionInSchoolStateSubTypeOrNull(
    _query.inSchoolStateSubType,
  );
  const on = asDateValueOrNull(_query.inSchoolStateOn);

  if (isNullish(subType) || isNullish(on))
    return searchConditionInSchoolStateDefault(today);

  return {
    searchConditionType: "in-school-state",
    subType,
    on,
  };
}

export function searchConditionInSchoolStateToQueryParams(
  cond: SearchConditionInSchoolState,
): QueryParams {
  switch (cond.subType) {
    case "all":
      return {
        inSchoolStateSubType: undefined,
        inSchoolStateOn: undefined,
      };
    default:
      return {
        inSchoolStateSubType: cond.subType,
        inSchoolStateOn: dateValueToDisplayValue(cond.on),
      };
  }
}

// すべてand。
export type SearchConditionInSchoolStateRaw = {
  inSchoolState?: string;
  on?: string;
};

export function searchConditionInSchoolStateToRaw(
  cond: SearchConditionInSchoolState,
): SearchConditionInSchoolStateRaw {
  switch (cond.subType) {
    case "all":
      return {};
    case "inSchool":
      return {
        inSchoolState: "inSchool",
        on: dateValueToDisplayValue(cond.on),
      };
    case "graduated":
      return {
        inSchoolState: "graduated",
        on: dateValueToDisplayValue(cond.on),
      };
    case "transferred":
      return {
        inSchoolState: "transferred",
        on: dateValueToDisplayValue(cond.on),
      };
    case "graduatedOrTransferred":
      return {
        inSchoolState: "graduatedOrTransferred",
        on: dateValueToDisplayValue(cond.on),
      };
  }
}
