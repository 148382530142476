
import StudentEdit from "@/views/student/StudentEdit/StudentEdit.vue";
import { computed, defineComponent, Ref, ref } from "vue";
import { StudentEditTableTabValue } from "@/views/student/StudentEdit/student-edit-table-tab-value";
import log from "loglevel";
import {
  getStudentColumnDefsAsDict,
  StudentColumnDef,
  StudentColumnId,
} from "@/ts/app/columns/def/student/student-column";
import { useStudentRoute } from "@/router/use-student-route";
import { useAppStore } from "@/store/app-store";
import {
  delay,
  isNullish,
  getChangesByTopLevelKey,
  hasValue,
} from "@/ts/utils/common-util";
import { useRouter } from "vue-router";
import { useStudentStore } from "@/store/student-store";
import { useUserService } from "@/composables/provide-user-service";
import cloneDeep from "lodash/cloneDeep";
import { UserService } from "@/ts/services/user-service";
import { useAppToast } from "@/composables/use-app-toast";
import {
  applyResult,
  LoadableData,
  loadableDataNull,
} from "@/ts/app/loadable-data";
import {
  StudentEditData,
  StudentEditState,
} from "@/views/student/StudentEdit/student-edit-state";
import { mapResult } from "@/ts/app/result";
import { getToday } from "@/ts/utils/app-util";
import { names } from "@/ts/app/object-name";

export default defineComponent({
  name: "StudentEditContainer",
  components: { StudentEdit },
  setup() {
    const appStore = useAppStore();
    const studentStore = useStudentStore();
    const userService = useUserService();
    const router = useRouter();
    const { userId, schoolYear } = useStudentRoute(
      getToday(),
      appStore.currentSchoolYear,
    );
    const { showError, showSuccess } = useAppToast();

    const isAdmin = computed(() => appStore.isAdmin);

    const freeze = ref(false);

    studentStore.loadCustomColumnNames(userService);

    const data = ref<LoadableData<StudentEditData>>(loadableDataNull());

    const fetchStudent = async () => {
      const _userId = userId.value;
      if (isNullish(_userId)) return;

      const result = await userService.getStudent(_userId);

      data.value = applyResult(
        data.value,
        mapResult(result, (r) => ({ student: r })),
      );
    };

    const editState = ref<StudentEditState>({ editing: false });

    const studentColumnDefs = computed(() =>
      getStudentColumnDefsAsDict(
        schoolYear.value,
        studentStore.customColumnNames.data,
      ),
    );

    const onInput = (columnId: StudentColumnId, value: any) => {
      const _editState = editState.value;
      if (!_editState.editing) return;

      log.debug(
        `StudentEditContainer: onInput: columnId=${columnId}, value=${JSON.stringify(
          value,
        )}`,
      );

      const colDef = studentColumnDefs.value[columnId];

      // 画像だけ特別扱い。すぐに更新する。
      if (colDef.type.category === "image") {
        if (hasValue(value)) {
          uploadPicture(
            userService,
            _editState.data.student.userId,
            value,
            showError,
            showSuccess,
            colDef,
            editState,
          );
        } else {
          deletePicture(
            userService,
            _editState.data.student.userId,
            showError,
            showSuccess,
            colDef,
            editState,
          );
        }
        return;
      }

      const setValue = colDef.setValue;
      if (isNullish(setValue)) return;

      _editState.data.student = setValue(_editState.data.student, value);
    };

    const startEditing = () => {
      const _data = data.value;
      if (editState.value.editing || !_data.hasFreshData || freeze.value)
        return;

      editState.value = {
        editing: true,
        data: cloneDeep(_data.data),
      };
    };
    const cancelEditing = () => {
      if (freeze.value) return;

      editState.value = { editing: false };
      fetchStudent();
    };
    const save = async () => {
      const _data = data.value;
      const _editState = editState.value;
      if (!_data.hasData || !_editState.editing || freeze.value) return;

      freeze.value = true;

      const changes = getChangesByTopLevelKey(
        _data.data.student,
        _editState.data.student,
      );
      const result = await userService.patchStudent({
        userId: _editState.data.student.userId,
        ...changes,
      });

      if (result.ok) {
        showSuccess("変更を保存しました。");
      } else {
        showError(`変更の保存に失敗しました: ${result.error.displayMessage}`);
      }

      editState.value = { editing: false };
      data.value = applyResult(
        data.value,
        mapResult(result, (r) => ({ student: r })),
      );

      freeze.value = false;

      await fetchStudent();
    };

    const selectedTab = ref<StudentEditTableTabValue>("profile");
    const onSelectTab = (tab: StudentEditTableTabValue) => {
      selectedTab.value = tab;
    };

    const onClickBack = () => router.back();

    const deleteUser = async () => {
      const _editState = editState.value;
      if (!_editState.editing || freeze.value) return;

      if (
        !window.confirm(
          `この${names.student.d}を削除してよろしいですか？\nこの操作はすぐに反映され、元に戻せません。`,
        )
      )
        return;

      freeze.value = true;

      editState.value = { editing: false };
      await userService.deleteStudent(_editState.data.student.userId);

      freeze.value = false;

      router.back();
    };

    fetchStudent();

    return {
      isAdmin,

      schoolYear,

      data,
      editState,

      selectedTab,

      onClickBack,
      startEditing,
      cancelEditing,
      save,
      deleteUser,

      onInput,

      onSelectTab,
    };
  },
});

async function uploadPicture(
  userService: UserService,
  userId: string,
  file: unknown,
  showError: (message: string) => void,
  showSuccess: (message: string) => void,
  colDef: StudentColumnDef<StudentColumnId, any>,
  editState: Ref<StudentEditState>,
) {
  if (!(file instanceof File)) return;

  const result = await userService.uploadStudentPicture(userId, file);

  if (!result.ok) {
    showError(
      `写真のアップロードに失敗しました: ${result.error.displayMessage}`,
    );
    return;
  }
  showSuccess(
    "写真をアップロードしました。(反映には時間が掛かる場合があります。)",
  );

  await reloadViewAfterPictureUpdate(
    colDef,
    editState,
    result.data.pictureGcsUrl,
  );
}

async function deletePicture(
  userService: UserService,
  userId: string,
  showError: (message: string) => void,
  showSuccess: (message: string) => void,
  colDef: StudentColumnDef<StudentColumnId, any>,
  editState: Ref<StudentEditState>,
) {
  if (
    !window.confirm(
      `${names.pictureGcsUrl.d}を削除してよろしいですか？\nこの操作はすぐに反映され、元に戻せません。`,
    )
  )
    return;

  const result = await userService.deleteStudentPicture(userId);

  if (!result.ok) {
    showError(`写真の削除に失敗しました: ${result.error.displayMessage}`);
    return;
  }
  showSuccess("写真を削除しました。");

  await reloadViewAfterPictureUpdate(colDef, editState, null);
}

async function reloadViewAfterPictureUpdate(
  colDef: StudentColumnDef<StudentColumnId, any>,
  editState: Ref<StudentEditState>,
  newPictureUrl: string | null,
) {
  // 以下、ビューをリロードするためだけにやっている。
  const setValue = colDef.setValue;
  if (!editState.value.editing || isNullish(setValue)) return;

  editState.value.data.student = setValue(editState.value.data.student, null);
  await delay(0);
  editState.value.data.student = setValue(
    editState.value.data.student,
    newPictureUrl,
  );
}
