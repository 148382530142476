
import { computed, defineComponent, ref, watch, watchEffect } from "vue";
import TeacherList from "@/views/teacher/TeacherList/TeacherList.vue";
import { useAppStore } from "@/store/app-store";
import { useTeacherStore } from "@/store/teacher-store";
import { useRouter } from "vue-router";
import { useUserService } from "@/composables/provide-user-service";
import {
  TeacherSearchCondition,
  teacherSearchConditionToQueryParams,
} from "@/ts/objects/search-condition/teacher-search-condition";
import debounce from "lodash/debounce";
import { searchConditionClassDefault } from "@/ts/objects/search-condition/search-condition-class";
import { useSearchConditionPopup } from "@/ts/composables/use-search-condition-popup";
import { isNullish } from "@/ts/utils/common-util";
import log from "loglevel";
import { useTeacherRoute } from "@/router/use-teacher-route";
import { downloadAsCSV, unparseCSV } from "@/ts/utils/app-util";
import { format } from "date-fns";
import { teacherToExportingCSVRow } from "@/ts/app/columns/csv/teacher-csv";
import { names } from "@/ts/app/object-name";
import { usePushRouteAfterAuth } from "@/composables/use-push-route-after-auth";
import { ExportDropdownOptionValue } from "@/components/dropdowns/ExportDropdown/export-dropdown";
import {
  teacherColumnVisibilityToListColumnIds,
  TeacherListColumnId,
  teacherListColumnIds,
} from "@/ts/app/columns/user-list/teacher-list-columns";
import { getTeacherColumnDefs } from "@/ts/app/columns/def/teacher/teacher-column";

export default defineComponent({
  name: "TeacherListContainer",
  components: { TeacherList },
  setup() {
    const appStore = useAppStore();
    const teachersStore = useTeacherStore();
    const router = useRouter();
    const userService = useUserService();

    const isAdmin = computed(() => appStore.isAdmin);

    const {
      searchPageNum,
      queryText,
      schoolYear,
      searchConditionClass,
      searchConditionTeacherType,
      searchConditionSort,
      searchConditionNumResults,
    } = useTeacherRoute(appStore.currentSchoolYear);

    const searchCondition = computed(
      (): TeacherSearchCondition => ({
        schoolYear: schoolYear.value,
        queryText: queryText.value,
        teacherType: searchConditionTeacherType.value,
        class: searchConditionClass.value,
        sort: searchConditionSort.value,
        numResults: searchConditionNumResults.value,
      }),
    );

    // fetchTeachers
    const debouncedFetchTeachersInternal = debounce(() => {
      teachersStore.fetchTeachers(
        userService,
        searchCondition.value,
        searchPageNum.value,
      );
    }, 1); // これでちゃんと検索条件がリアクティブになるようだが、気を付けておくこと。
    const fetchTeachers = () => {
      teachersStore.clearTeachers();
      debouncedFetchTeachersInternal();
    };

    // reload
    const reload = async (
      page: number = 1,
      schoolYearChanged: boolean = false,
    ) => {
      if (schoolYearChanged) {
        // 年度を変更したら、クラス条件のみリセットする。
        searchConditionClass.value = searchConditionClassDefault(
          schoolYear.value,
        );
      }

      searchPageNum.value = page;
      await router.push({
        path: "/teacher/list",
        query: {
          page,
          ...teacherSearchConditionToQueryParams(searchCondition.value),
        },
      });
      fetchTeachers();
    };

    // 年度ポップアップ
    const [
      isSchoolYearPopupActive,
      changeSchoolYearPopupActiveness,
      onConfirmConditionSchoolYear,
    ] = useSearchConditionPopup(schoolYear, () => reload(1, true));

    // クラスポップアップ
    const [
      isClassPopupActive,
      changeClassPopupActiveness,
      onConfirmConditionClass,
    ] = useSearchConditionPopup(searchConditionClass, reload);

    // 在学状態ポップアップ
    const [
      isTeacherTypePopupActive,
      changeTeacherTypePopupActiveness,
      onConfirmConditionTeacherType,
    ] = useSearchConditionPopup(searchConditionTeacherType, reload);

    // 並び替え条件ポップアップ
    const [
      isSortPopupActive,
      changeSortPopupActiveness,
      onConfirmConditionSort,
    ] = useSearchConditionPopup(searchConditionSort, reload);

    // 件数ポップアップ
    const [
      isNumResultsPopupActive,
      changeNumResultsPopupActiveness,
      onConfirmConditionNumResults,
    ] = useSearchConditionPopup(searchConditionNumResults, reload);

    const searchResult = computed(() => teachersStore.teacherSearchResult);

    const numResults = ref<number | null>(null); // 検索結果数だが、表示に使うために古い値も保持する。よって正確な値ではないので注意。
    watchEffect(() => {
      const _searchResult = searchResult.value;
      if (isNullish(_searchResult)) return;
      numResults.value = _searchResult.numSearchResults;
    });

    fetchTeachers();

    const debouncedFetchClassesInternal = debounce(
      () => teachersStore.fetchClasses(userService, schoolYear.value),
      1,
    );
    const fetchClasses = () => {
      teachersStore.clearClasses();
      debouncedFetchClassesInternal();
    };
    watch(schoolYear, fetchClasses);
    fetchClasses();

    const allClasses = computed(
      () => teachersStore.allClassesOnSchoolYear ?? [],
    );

    const isColumnSelectOpen = ref(true);
    const columnVisibility = computed(() => teachersStore.columnVisibility);

    const pushRouteAfterAuth = usePushRouteAfterAuth(userService);
    const syncWithGWS = () => pushRouteAfterAuth("/teacher/sync");

    const allColumnDefs = computed(() =>
      getTeacherColumnDefs(schoolYear.value),
    );
    const onClickExportButton = (value: ExportDropdownOptionValue) => {
      log.debug("TeacherListContainer: onClickExportButton");

      const _searchResult = searchResult.value;
      const _columnVisibility = columnVisibility.value;
      if (isNullish(_searchResult)) return;

      const _teachers = _searchResult.data;
      const columnIds: TeacherListColumnId[] =
        value === "export-all-columns"
          ? teacherListColumnIds
          : teacherColumnVisibilityToListColumnIds(_columnVisibility);

      const csvText = unparseCSV(
        _teachers,
        (t) => teacherToExportingCSVRow(t, schoolYear.value, columnIds),
        allColumnDefs.value,
      );
      downloadAsCSV(
        csvText,
        `${names.teacher.d}_${format(new Date(), "yyyyMMdd'T'HHmmss")}.csv`,
      );
    };

    return {
      isAdmin,

      columnVisibility,
      schoolYear,

      searchResult,
      numResults,
      pageNumber: searchPageNum,

      searchCondition,

      allClasses,

      onInputQueryText: (text: string) => {
        queryText.value = text;
      },
      onConfirmQueryText: () => {
        reload();
      },

      isSchoolYearPopupActive,
      changeSchoolYearPopupActiveness,
      onConfirmConditionSchoolYear,

      isClassPopupActive,
      changeClassPopupActiveness,
      onConfirmConditionClass,

      isTeacherTypePopupActive,
      changeTeacherTypePopupActiveness,
      onConfirmConditionTeacherType,

      isSortPopupActive,
      changeSortPopupActiveness,
      onConfirmConditionSort,

      isNumResultsPopupActive,
      changeNumResultsPopupActiveness,
      onConfirmConditionNumResults,

      isColumnSelectOpen,
      toggleColumnSelectVisibility: () => {
        isColumnSelectOpen.value = !isColumnSelectOpen.value;
      },

      syncWithGWS,
      onClickExportButton,

      userIdToEditPath: (userId: string) => {
        return `/teacher/edit/${userId}`;
      },

      movePageTo: (n: number) => {
        searchPageNum.value = n;
        reload(n);
      },

      onChangeColumnVisibility: teachersStore.updateColumnVisibility,
    };
  },
});
