import { AppError, errors } from "@/ts/app/error/app-error";
import { isNullish } from "@/ts/utils/common-util";

export type Result<T> = ResultOk<T> | ResultErr;

export type ResultOk<T> = { readonly ok: true; readonly data: T };
export type ResultErr = { readonly ok: false; readonly error: AppError };

export function combineResults<T>(results: Result<T>[]): Result<T[]> {
  const failedResult = results.find((r) => !r.ok) as ResultErr | undefined;
  if (isNullish(failedResult)) {
    return { ok: true, data: (results as ResultOk<T>[]).map((r) => r.data) };
  }
  return { ok: false, error: failedResult.error };
}

export function composeResults<T0, T1, U>(
  r0: Result<T0>,
  r1: Result<T1>,
  compose: (t0: T0, t1: T1) => U,
): Result<U> {
  if (r0.ok && r1.ok) {
    return { ok: true, data: compose(r0.data, r1.data) };
  }

  const error = !r0.ok
    ? r0.error
    : !r1.ok
    ? r1.error
    : errors.internal("unreachable");
  return { ok: false, error };
}

export function mapResult<T, U>(r: Result<T>, fn: (v: T) => U): Result<U> {
  if (!r.ok) return r;
  return {
    ok: true,
    data: fn(r.data),
  };
}
