import { createApp } from "vue";
import App from "./App.vue";
import { InitializationResult, initializeApp } from "@/ts/init/initialize-app";
import { getRouter } from "@/router/router";
import { setupIcons } from "@/ts/init/setup-icons";
import { setupVueApp } from "@/ts/init/setup-vue-app";
import { devtools } from "@/devtools";
import { useAppStore } from "@/store/app-store";

const localDebugging = /^localhost:\d+$/.test(window.location.host);

initializeApp(localDebugging).then(
  ({
    authService,
    userService,
    curriculumService,
    firebaseApp,
    firebaseStorage,
    pinia,
  }: InitializationResult) => {
    const appStore = useAppStore(pinia);
    const router = getRouter(() => appStore.isAdmin);

    const app = createApp(App, {
      authService,
      userService,
      curriculumService,
      firebaseApp,
      firebaseStorage,
    })
      .use(devtools)
      .use(pinia)
      .use(router);

    setupIcons(app);
    setupVueApp(app);
    app.mount("#app");
  },
);
