
import { defineComponent, PropType } from "vue";
import EditableItemImage from "@/components/editable-items/EditableItemImage/EditableItemImage.vue";
import { names } from "@/ts/app/object-name";

export default defineComponent({
  name: "SchoolEditTableRowImage",
  components: { EditableItemImage },
  props: {
    name: { type: String, required: true },
    gcsUrl: { type: String, required: false },

    editing: { type: Boolean, required: true },

    onInput: {
      type: Function as PropType<(file: File | null) => void>,
      required: true,
    },

    nameCellWidth: { type: Number, required: true },
    valueCellWidth: { type: Number, required: true },
  },
  setup(props) {
    const schoolLogoDisplayName = names.schoolLogo.d;

    return {
      schoolLogoDisplayName,

      styles: {
        "--name-cell-width": `${props.nameCellWidth}px`,
        "--value-cell-width": `${props.valueCellWidth}px`,
      },
    };
  },
});
