
import TermEdit from "@/views/term/TermEdit/TermEdit.vue";
import { defineComponent } from "vue-demi";
import { useUserService } from "@/composables/provide-user-service";
import { computed, Ref, ref } from "vue";
import { Term } from "@/ts/objects/entity/term";
import {
  applyResult,
  LoadableData,
  loadableDataNull,
  toLoading,
} from "@/ts/app/loadable-data";
import {
  TermEditTableShape,
  TermTableCellModel,
  UnsavedTermEditData,
  unsavedTermEditDataFromTerms,
} from "@/views/term/TermEdit/term-edit-data";
import {
  hasValue,
  isNullish,
  typedIntegerKeyedObjectEntries,
} from "@/ts/utils/common-util";
import { SchoolType } from "@/ts/objects/value/school-type";
import { compareDateValue, DateValue } from "@/ts/objects/value/date-value";
import { ResultErr } from "@/ts/app/result";
import { UserService } from "@/ts/services/user-service";
import { useAppStore } from "@/store/app-store";
import { CheckFailed } from "@/ts/app/error/check-error";
import clamp from "lodash/clamp";
import log from "loglevel";
import cloneDeep from "lodash/cloneDeep";
import { useAppToast } from "@/composables/use-app-toast";
import { useTermRoute } from "@/router/use-term-route";
import { useRouter } from "vue-router";

// TODO termが存在しないときの挙動。add / deleteTermは正しいか？saveできるか？など。
// TODO できれば、重複がある場合などのエラーをこっちでチェックしてあげたい。
// 編集中は学校タイプ切り替え不可にした。が、現状、切り替え可能前提の作り。まあ普通に動くはずだが、最適化しても良い。

export default defineComponent({
  name: "TermEditContainer",
  components: { TermEdit },
  setup() {
    const appStore = useAppStore();
    const userService = useUserService();
    const router = useRouter();
    const { schoolType: currentTab } = useTermRoute();
    const { showInfo, showError, showSuccess } = useAppToast();

    const isAdmin = computed(() => appStore.isAdmin);

    const freeze = ref(false);

    const defaultMaxTermNumber = 3;
    const currentSchoolYear = appStore.currentSchoolYear;

    const changeTab = (tab: SchoolType) => {
      router.push({
        path: "/term/edit",
        query: {
          schoolType: tab,
        },
      });
    };

    const terms = ref<LoadableData<Term[]>>(loadableDataNull());

    const tableShape = ref<TermEditTableShape>({
      elementaryMaxTermNumber: defaultMaxTermNumber,
      elementaryMinSchoolYear: currentSchoolYear,
      elementaryMaxSchoolYear: currentSchoolYear,
      juniorhighMaxTermNumber: defaultMaxTermNumber,
      juniorhighMinSchoolYear: currentSchoolYear,
      juniorhighMaxSchoolYear: currentSchoolYear,
    });
    const tableShapeOnEdit = ref<TermEditTableShape>({
      elementaryMaxTermNumber: defaultMaxTermNumber,
      elementaryMinSchoolYear: currentSchoolYear,
      elementaryMaxSchoolYear: currentSchoolYear,
      juniorhighMaxTermNumber: defaultMaxTermNumber,
      juniorhighMinSchoolYear: currentSchoolYear,
      juniorhighMaxSchoolYear: currentSchoolYear,
    });

    const fetchTerms = async () => {
      terms.value = toLoading(terms.value);
      const result = await userService.listTerms(
        undefined,
        undefined,
        undefined,
        true,
      );
      const _terms = applyResult(terms.value, result);

      if (_terms.hasData) {
        const _elementaryTerms = _terms.data?.filter(
          (t) => t.schoolType === "elementary",
        );
        const _juniorhighTerms = _terms.data?.filter(
          (t) => t.schoolType === "juniorhigh",
        );

        tableShape.value = {
          elementaryMaxTermNumber:
            _elementaryTerms.length > 0
              ? Math.max(..._elementaryTerms.map((t) => t.termNumber))
              : defaultMaxTermNumber,
          elementaryMinSchoolYear:
            _elementaryTerms.length > 0
              ? Math.min(..._elementaryTerms.map((t) => t.schoolYear))
              : currentSchoolYear,
          elementaryMaxSchoolYear:
            _elementaryTerms.length > 0
              ? Math.max(..._elementaryTerms.map((t) => t.schoolYear))
              : currentSchoolYear,
          juniorhighMaxTermNumber:
            _juniorhighTerms.length > 0
              ? Math.max(..._juniorhighTerms.map((t) => t.termNumber))
              : defaultMaxTermNumber,
          juniorhighMinSchoolYear:
            _juniorhighTerms.length > 0
              ? Math.min(..._juniorhighTerms.map((t) => t.schoolYear))
              : currentSchoolYear,
          juniorhighMaxSchoolYear:
            _juniorhighTerms.length > 0
              ? Math.max(..._juniorhighTerms.map((t) => t.schoolYear))
              : currentSchoolYear,
        };
      }
      terms.value = _terms;
    };
    fetchTerms();

    // unsavedDataがnullでないときが、編集状態(editing)。
    const unsavedData = ref<UnsavedTermEditData | null>(null);
    const onChangeTerm = (
      schoolType: SchoolType,
      schoolYear: number,
      termNumber: number,

      changedStartDate: boolean,
      newDate: DateValue | null,
    ) => {
      _onChangeTerm(
        schoolType,
        schoolYear,
        termNumber,
        changedStartDate,
        newDate,
        unsavedData,
      );
    };
    const addTermNumber = () => {
      const _schoolType = currentTab.value;
      const _unsavedData = unsavedData.value;
      const _tableShapeOnEdit = tableShapeOnEdit.value;

      if (isNullish(_unsavedData)) return; // 編集中でなければ何もしない。

      switch (_schoolType) {
        case "elementary":
          _tableShapeOnEdit.elementaryMaxTermNumber =
            _tableShapeOnEdit.elementaryMaxTermNumber + 1;
          break;
        case "juniorhigh":
          _tableShapeOnEdit.juniorhighMaxTermNumber =
            _tableShapeOnEdit.juniorhighMaxTermNumber + 1;
          break;
      }
    };
    const deleteTermNumber = () => {
      const _schoolType = currentTab.value;
      const _unsavedData = unsavedData.value;
      const _tableShapeOnEdit = tableShapeOnEdit.value;

      if (isNullish(_unsavedData)) return; // 編集中でなければ何もしない。

      const deletingTermNumber =
        _schoolType === "elementary"
          ? _tableShapeOnEdit.elementaryMaxTermNumber
          : _tableShapeOnEdit.juniorhighMaxTermNumber;
      if (deletingTermNumber <= 1) {
        showInfo("学期をこれ以上削除できません。");
        return;
      }

      const deletable = Object.values(_unsavedData[_schoolType])
        .flatMap((unsavedSchoolYear) => Object.entries(unsavedSchoolYear.cells))
        .filter(
          ([termNumber, _cell]) =>
            termNumber === deletingTermNumber.toString(10),
        )
        .every(
          ([_termNumber, cell]) =>
            cell.state === "deleted" ||
            (isNullish(cell.startDate) && isNullish(cell.endDate)),
        );
      if (!deletable) {
        showInfo("学期を削除するには、先にすべての日付を空にしてください。");
        log.debug(`_unsavedData=${JSON.stringify(_unsavedData)}`);
        return;
      }

      switch (_schoolType) {
        case "elementary":
          _tableShapeOnEdit.elementaryMaxTermNumber = deletingTermNumber - 1;
          break;
        case "juniorhigh":
          _tableShapeOnEdit.juniorhighMaxTermNumber = deletingTermNumber - 1;
          break;
      }
    };
    const addSchoolYear = () => {
      const _schoolType = currentTab.value;
      const _unsavedData = unsavedData.value;
      const _tableShapeOnEdit = tableShapeOnEdit.value;

      if (isNullish(_unsavedData)) return; // 編集中でなければ何もしない。

      switch (_schoolType) {
        case "elementary":
          _tableShapeOnEdit.elementaryMaxSchoolYear = clamp(
            _tableShapeOnEdit.elementaryMaxSchoolYear + 1,
            1,
            9999,
          );
          break;
        case "juniorhigh":
          _tableShapeOnEdit.juniorhighMaxSchoolYear = clamp(
            _tableShapeOnEdit.juniorhighMaxSchoolYear + 1,
            1,
            9999,
          );
          break;
      }
    };
    const deleteSchoolYear = () => {
      const _schoolType = currentTab.value;
      const _unsavedData = unsavedData.value;
      const _tableShapeOnEdit = tableShapeOnEdit.value;

      if (isNullish(_unsavedData)) return; // 編集中でなければ何もしない。

      const deletingSchoolYear =
        _schoolType === "elementary"
          ? _tableShapeOnEdit.elementaryMaxSchoolYear
          : _tableShapeOnEdit.juniorhighMaxSchoolYear;
      const minSchoolYear =
        _schoolType === "elementary"
          ? _tableShapeOnEdit.elementaryMinSchoolYear
          : _tableShapeOnEdit.juniorhighMinSchoolYear;

      if (deletingSchoolYear <= minSchoolYear) {
        showInfo("年度をこれ以上削除できません。");
        return;
      }

      const deletingTerms: { [termNumber: number]: TermTableCellModel } =
        _unsavedData[_schoolType][deletingSchoolYear]?.cells ?? {};
      const deletable = Object.values(deletingTerms).every(
        (cell) =>
          cell.state === "deleted" ||
          (isNullish(cell.startDate) && isNullish(cell.endDate)),
      );
      if (!deletable) {
        showInfo("年度を削除するには、先にすべての日付を空にしてください。");
        return;
      }

      switch (_schoolType) {
        case "elementary":
          _tableShapeOnEdit.elementaryMaxSchoolYear = deletingSchoolYear - 1;
          break;
        case "juniorhigh":
          _tableShapeOnEdit.juniorhighMaxSchoolYear = deletingSchoolYear - 1;
          break;
      }
    };

    const startEditing = () => {
      const _terms = terms.value;

      // すでに編集中か、termsが新鮮なデータでないなら、編集開始しない。
      if (hasValue(unsavedData.value) || !_terms.hasFreshData || freeze.value)
        return;

      tableShapeOnEdit.value = cloneDeep(tableShape.value);
      unsavedData.value = unsavedTermEditDataFromTerms(_terms.data);
    };
    const cancelEditing = () => {
      if (freeze.value) return;

      unsavedData.value = null;
    };
    const save = async () => {
      await _save(
        userService,
        showError,
        showSuccess,
        terms,
        freeze,
        unsavedData,
      );
      await fetchTerms();
    };

    return {
      isAdmin,

      currentTab,

      terms,

      changeTab,

      tableShape,
      tableShapeOnEdit,

      unsavedData,

      onChangeTerm,
      addTermNumber,
      deleteTermNumber,
      addSchoolYear,
      deleteSchoolYear,

      startEditing,
      cancelEditing,
      save,
    };
  },
});

function _onChangeTerm(
  schoolType: SchoolType,
  schoolYear: number,
  termNumber: number,

  changedStartDate: boolean,
  newDate: DateValue | null,

  unsavedData: Ref<UnsavedTermEditData | null>,
) {
  const _unsavedData = unsavedData.value;
  if (isNullish(_unsavedData)) return;

  log.debug(`_unsavedData(before)=${JSON.stringify(_unsavedData)}`);

  // 変更対象のtermを検索する。見つからなければ新規作成する。
  const oldTerm: TermTableCellModel | undefined =
    _unsavedData[schoolType][schoolYear]?.cells?.[termNumber];

  const newStartDate = changedStartDate ? newDate : oldTerm?.startDate ?? null;
  const newEndDate = changedStartDate ? oldTerm?.endDate ?? null : newDate;

  const oldState = oldTerm?.state;
  const hasAnyValue = hasValue(newStartDate) || hasValue(newEndDate);
  let newState: "new" | "new-deleted" | "unchanged" | "changed" | "deleted";
  if (isNullish(oldState) || oldState === "new" || oldState === "new-deleted") {
    // 今回の編集で新規作成したterm。
    newState = hasAnyValue ? "new" : "new-deleted";
  } else if (
    oldState === "unchanged" ||
    oldState === "changed" ||
    oldState === "deleted"
  ) {
    // 既存のterm。
    newState = hasAnyValue ? "changed" : "deleted";
  } else {
    throw new CheckFailed();
  }

  if (_unsavedData[schoolType][schoolYear] === undefined) {
    _unsavedData[schoolType][schoolYear] = { cells: {} };
  }
  _unsavedData[schoolType][schoolYear].cells[termNumber] = {
    startDate: newStartDate,
    endDate: newEndDate,
    state: newState,
  };

  unsavedData.value = _unsavedData;

  log.debug(`_unsavedData(after)=${JSON.stringify(_unsavedData)}`);
}

async function _save(
  userService: UserService,
  showError: (message: string) => void,
  showSuccess: (message: string) => void,
  terms: Ref<LoadableData<Term[]>>,
  freeze: Ref<boolean>,
  unsavedData: Ref<UnsavedTermEditData | null>,
) {
  const _unsavedData = unsavedData.value;
  if (isNullish(_unsavedData) || freeze.value) return;

  freeze.value = true;

  const unsavedTerms: {
    readonly schoolYear: number;
    readonly schoolType: SchoolType;
    readonly termNumber: number;

    readonly startDate: DateValue | null;
    readonly endDate: DateValue | null;

    readonly state: "new" | "new-deleted" | "unchanged" | "changed" | "deleted";
  }[] = [
    {
      schoolType: "elementary" as const,
      unsavedSchoolYears: _unsavedData.elementary,
    },
    {
      schoolType: "juniorhigh" as const,
      unsavedSchoolYears: _unsavedData.juniorhigh,
    },
  ]
    .flatMap(({ schoolType, unsavedSchoolYears }) =>
      typedIntegerKeyedObjectEntries(unsavedSchoolYears).map(
        ([schoolYear, unsavedSchoolYear]) => ({
          schoolType,
          schoolYear,
          unsavedSchoolYear,
        }),
      ),
    )
    .flatMap(({ schoolType, schoolYear, unsavedSchoolYear }) =>
      typedIntegerKeyedObjectEntries(unsavedSchoolYear.cells).map(
        ([termNumber, cell]) => ({
          schoolType,
          schoolYear,
          termNumber,

          startDate: cell.startDate,
          endDate: cell.endDate,

          state: cell.state,
        }),
      ),
    );

  const newOrChangedTerms = unsavedTerms.filter(
    (t) => t.state === "new" || t.state === "changed",
  );
  if (
    newOrChangedTerms.some(
      (t) => isNullish(t.startDate) || isNullish(t.endDate),
    )
  ) {
    showError("すべての開始・終了日付を入力してください。");
    freeze.value = false;
    return;
  }
  if (
    newOrChangedTerms.some((t) => {
      const _startDate = t.startDate;
      const _endDate = t.endDate;
      if (isNullish(_startDate) || isNullish(_endDate)) {
        throw new CheckFailed();
      }
      return compareDateValue(_startDate, _endDate) > 0;
    })
  ) {
    showError("終了日付は開始日付以降に設定してください。");
    freeze.value = false;
    return;
  }
  const deletedTerms = unsavedTerms.filter((t) => t.state === "deleted");

  terms.value = toLoading(terms.value);
  unsavedData.value = null;

  const results = await Promise.all([
    ...newOrChangedTerms
      .map(
        (t): Term => ({
          schoolYear: t.schoolYear,
          schoolType: t.schoolType,
          termNumber: t.termNumber,

          startDate: t.startDate as DateValue,
          endDate: t.endDate as DateValue,
        }),
      )
      .map((t) => userService.createOrUpdateTerm(t)),
    ...deletedTerms.map((t) =>
      userService.deleteTerm(t.schoolYear, t.schoolType, t.termNumber),
    ),
  ]);
  const errors = results
    .filter((r): r is ResultErr => !r.ok)
    .map((r) => r.error);
  if (errors.length === 0) {
    showSuccess("変更を保存しました。");
  } else {
    showError(`変更の保存に失敗しました: ${errors[0].displayMessage}`);
  }

  freeze.value = false;
}
