import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6f60eee6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "student-sync-container" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StudentSubPageHeading = _resolveComponent("StudentSubPageHeading")!
  const _component_SyncWithGWSView = _resolveComponent("SyncWithGWSView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_StudentSubPageHeading, {
        class: "page-heading",
        "sub-label": "Sync Students with Google Workspace",
        "main-page-heading-link-path": "/student",
        "on-click-back": _ctx.onClickBack
      }, null, 8, ["on-click-back"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_SyncWithGWSView, { "user-type": "student" })
    ])
  ]))
}