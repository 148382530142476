import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-313cf792"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "search-condition-dropdown-container" }
const _hoisted_2 = { class: "popup-slot-area" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchConditionDropdownButton = _resolveComponent("SearchConditionDropdownButton")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SearchConditionDropdownButton, {
      label: _ctx.buttonLabel,
      "min-width": _ctx.buttonMinWidth,
      "on-click": _ctx.onClickButton
    }, null, 8, ["label", "min-width", "on-click"]),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.active)
        ? _renderSlot(_ctx.$slots, "default", {
            key: 0,
            class: "popup-slot"
          }, undefined, true)
        : _createCommentVNode("", true)
    ])
  ])), [
    [_directive_click_outside, _ctx.deactivate]
  ])
}