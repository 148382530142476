
import { computed, defineComponent, PropType } from "vue";
import { ColorLabel, labelToColor } from "@/ts/objects/value/color-label";
import AutoResizeTextarea from "@/components/textboxes/AutoResizeTextarea/AutoResizeTextarea.vue";
import { isNullish } from "@/ts/utils/common-util";

// TODO テキストエリアがリサイズされると、どんどんはみ出ていく。外からwidthとか指定している場合はfixedにしないと・・・。そうじゃなくても綺麗にいくか知らないが。

export default defineComponent({
  name: "EditableItemText",
  components: { AutoResizeTextarea },
  props: {
    value: { type: String, required: true },
    /**
     * 編集可能状態であればtrue。
     */
    editable: { type: Boolean, required: true },
    type: {
      type: String as PropType<"textarea" | "input">,
      default: "textarea",
      required: false,
    },

    paddingTop: { type: Number, default: 4, required: false },
    paddingRight: { type: Number, default: 4, required: false },
    paddingBottom: { type: Number, default: 4, required: false },
    paddingLeft: { type: Number, default: 4, required: false },
    fontSize: { type: Number, default: 14 },
    fontColor: { type: String as PropType<ColorLabel>, default: "black" },

    maxLength: { type: Number, default: null, required: false },

    /**
     * trueにした場合、editableがfalseの間、
     * 入力ボックスを消して、ただのテキストのような見た目にする。
     */
    hideBoxOnDisable: { type: Boolean, default: true },
    /**
     * trueにした場合、editableがtrueの間、
     * 入力ボックスを消して、ただのテキストのような見た目にする。
     */
    hideBoxOnEditing: { type: Boolean, default: false },
    /**
     * trueにした場合、editableがfalseの間、
     * width: max-content を付与したり、中央揃えにしたりする。
     */
    adjustContentOnDisable: { type: Boolean, default: false },

    onInput: {
      type: Function as PropType<(value: string) => void>,
      required: true,
    },
  },
  setup(props) {
    const boxed = computed(
      () =>
        (props.editable && !props.hideBoxOnEditing) ||
        (!props.editable && !props.hideBoxOnDisable),
    );

    const adjustContent = computed(
      () => !props.editable && props.adjustContentOnDisable,
    );

    return {
      boxed,
      adjustContent,

      onInputInternal: (value: string) => {
        if (!props.editable) return;
        props.onInput(value);
      },
      onInputInternalForInput: (e: Event) => {
        if (!props.editable) return;
        const value = (e.target as HTMLInputElement).value;
        if (isNullish(value)) return;
        props.onInput(value);
      },

      styles: {
        "--padding-top": `${props.paddingTop}px`,
        "--padding-right": `${props.paddingRight}px`,
        "--padding-bottom": `${props.paddingBottom}px`,
        "--padding-left": `${props.paddingLeft}px`,
        "--font-size": `${props.fontSize}px`,
        "--color": labelToColor(props.fontColor),
      },
    };
  },
});
