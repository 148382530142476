
import { computed, defineComponent, PropType } from "vue";
import TermEditTableCellContent from "@/views/term/TermEdit/TermEditTable/TermEditTableRow/TermEditTableCell/TermEditTableCellContent/TermEditTableCellContent.vue";
import { TermTableCellModel } from "../../../term-edit-data";
import { DateValue } from "@/ts/objects/value/date-value";

export default defineComponent({
  name: "TermEditTableCell",
  components: {
    TermEditTableCellContent,
  },
  props: {
    model: {
      type: Object as PropType<TermTableCellModel>,
      required: false, // データとして存在しないtermは、undefinedとして設定されるので。
    },
    onChangeTerm: {
      type: Function as PropType<
        (changedStartDate: boolean, newDate: DateValue | null) => void
      >,
      required: true,
    },
    editing: { type: Boolean, required: true },
  },
  setup(props) {
    // modelがない場合、ダミーのstart, endを作る。
    const startDate = computed<DateValue | null>(
      () => props.model?.startDate ?? null,
    );
    const endDate = computed<DateValue | null>(
      () => props.model?.endDate ?? null,
    );

    return {
      startDate,
      endDate,
    };
  },
});
