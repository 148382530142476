
import { computed, defineComponent, PropType } from "vue";
import StudentColumnsSelect from "@/views/student/_components/StudentColumnsSelect/StudentColumnsSelect.vue";
import {
  StudentColumnVisibilityAll,
  StudentColumnVisibility,
} from "@/ts/app/columns/visibility/student-column-visibility";
import BaseSearchBox from "@/components/textboxes/BaseSearchBox/BaseSearchBox.vue";
import ImportButton from "@/components/buttons/ImportButton/ImportButton.vue";
import SearchResultNumbers from "@/components/other/SearchResultNumbers/SearchResultNumbers.vue";
import StudentListTable from "@/views/student/_components/StudentListTable/StudentListTable.vue";
import Pager from "@/components/other/Pager/Pager.vue";
import { SearchResult } from "@/ts/app/search-result";
import { Student } from "@/ts/objects/entity/student";
import { hasValue } from "@/ts/utils/common-util";
import StudentPageHeading from "@/views/student/_components/StudentPageHeading/StudentPageHeading.vue";
import SearchConditionDropdown from "@/components/search-condition/SearchConditionDropdown/SearchConditionDropdown.vue";
import SearchConditionPopupClass from "@/components/search-condition/SearchConditionPopupClass/SearchConditionPopupClass.vue";
import { Class } from "@/ts/objects/entity/class";
import {
  SearchConditionClass,
  searchConditionClassToDisplayValue,
} from "@/ts/objects/search-condition/search-condition-class";
import { StudentSearchCondition } from "@/ts/objects/search-condition/student-search-condition";
import SearchConditionPopupSchoolYear from "@/components/search-condition/SearchConditionPopupSchoolYear/SearchConditionPopupSchoolYear.vue";
import {
  SearchConditionInSchoolState,
  searchConditionInSchoolStateToDisplayValue,
} from "@/ts/objects/search-condition/search-condition-in-school-state";
import SearchConditionPopupInSchoolState from "@/components/search-condition/SearchConditionPopupInSchoolState/SearchConditionPopupInSchoolState.vue";
import {
  NumResultsCondition,
  numResultsConditionToDisplayValue,
} from "@/ts/objects/search-condition/num-results-condition";
import SearchConditionPopupNumResults from "@/components/search-condition/SearchConditionPopupNumResults/SearchConditionPopupNumResults.vue";
import {
  StudentSortCondition,
  studentSortConditionToDisplayValue,
} from "@/ts/objects/search-condition/student-sort-condition";
import SearchConditionPopupStudentSort from "@/components/search-condition/SearchConditionPopupStudentSort/SearchConditionPopupStudentSort.vue";
import { ExportDropdownOptionValue } from "@/components/dropdowns/ExportDropdown/export-dropdown";
import MenuDropdown from "@/components/dropdowns/MenuDropdown/MenuDropdown.vue";
import ExportDropdown from "@/components/dropdowns/ExportDropdown/ExportDropdown.vue";
import { DropdownItem } from "@/ts/app/dropdown-item";
import { names } from "@/ts/app/object-name";

export default defineComponent({
  name: "StudentList",
  components: {
    ExportDropdown,
    MenuDropdown,
    SearchConditionPopupStudentSort,
    SearchConditionPopupNumResults,
    SearchConditionPopupInSchoolState,
    SearchConditionPopupSchoolYear,
    SearchConditionPopupClass,
    SearchConditionDropdown,
    StudentPageHeading,
    Pager,
    StudentListTable,
    SearchResultNumbers,
    ImportButton,
    BaseSearchBox,
    StudentColumnsSelect,
  },
  props: {
    isAdmin: { type: Boolean, required: true },

    columnVisibility: {
      type: Object as PropType<StudentColumnVisibilityAll>,
      required: true,
    },
    schoolYear: { type: Number, required: true },

    /**
     * ある年度のすべてのクラス。
     *
     * （選択中の年度の）すべての学校タイプ・学年のクラスを含んでいること。
     * 適切な並び順になっていること。
     */
    allClasses: { type: Array as PropType<Class[]>, required: true },

    searchResult: { type: Object as PropType<SearchResult<Student>> },
    numResults: { type: Number },
    pageNumber: { type: Number, required: true },

    searchCondition: {
      type: Object as PropType<StudentSearchCondition>,
      required: true,
    },

    onInputQueryText: {
      type: Function as PropType<(text: string) => void>,
      required: true,
    },
    onConfirmQueryText: {
      type: Function as PropType<(value: string) => void>,
      required: true,
    },

    // 年度ポップアップ
    isSchoolYearPopupActive: { type: Boolean, required: true },
    changeSchoolYearPopupActiveness: {
      type: Function as PropType<(active: boolean) => void>,
      required: true,
    },
    onConfirmConditionSchoolYear: {
      type: Function as PropType<(schoolYear: number) => void>,
      required: true,
    },

    // クラスポップアップ
    isClassPopupActive: { type: Boolean, required: true },
    changeClassPopupActiveness: {
      type: Function as PropType<(active: boolean) => void>,
      required: true,
    },
    onConfirmConditionClass: {
      type: Function as PropType<(cond: SearchConditionClass) => void>,
      required: true,
    },

    // 在学状態ポップアップ
    isInSchoolStatePopupActive: { type: Boolean, required: true },
    changeInSchoolStatePopupActiveness: {
      type: Function as PropType<(active: boolean) => void>,
      required: true,
    },
    onConfirmConditionInSchoolState: {
      type: Function as PropType<(cond: SearchConditionInSchoolState) => void>,
      required: true,
    },

    // 並び替え条件ポップアップ
    isSortPopupActive: { type: Boolean, required: true },
    changeSortPopupActiveness: {
      type: Function as PropType<(active: boolean) => void>,
      required: true,
    },
    onConfirmConditionSort: {
      type: Function as PropType<(cond: StudentSortCondition) => void>,
      required: true,
    },

    // 件数ポップアップ
    isNumResultsPopupActive: { type: Boolean, required: true },
    changeNumResultsPopupActiveness: {
      type: Function as PropType<(active: boolean) => void>,
      required: true,
    },
    onConfirmConditionNumResults: {
      type: Function as PropType<(cond: NumResultsCondition) => void>,
      required: true,
    },

    isColumnSelectOpen: { type: Boolean, required: true },
    toggleColumnSelectVisibility: {
      type: Function as PropType<() => void>,
      required: true,
    },
    onChangeColumnVisibility: {
      type: Function as PropType<(v: StudentColumnVisibility) => void>,
      required: true,
    },

    syncWithGWS: {
      type: Function as PropType<() => void>,
      required: true,
    },
    goEditCustomColumns: {
      type: Function as PropType<() => void>,
      required: true,
    },
    onClickExportButton: {
      type: Function as PropType<(value: ExportDropdownOptionValue) => void>,
      required: true,
    },

    userIdToEditPath: {
      type: Function as PropType<(userId: string) => string>,
      required: true,
    },

    movePageTo: {
      type: Function as PropType<(n: number) => void>,
      required: true,
    },
  },
  setup(props) {
    const extraMenuItems: DropdownItem[] = [
      {
        value: "sync-with-gws",
        label: "Sync with Google Workspace",
      },
      {
        value: "edit-custom-columns",
        label: names.studentCustomItems.d,
      },
    ];
    const onSelectExtraMenuItem = (value: string) => {
      switch (value) {
        case "sync-with-gws": {
          props.syncWithGWS();
          break;
        }
        case "edit-custom-columns": {
          props.goEditCustomColumns();
          break;
        }
      }
    };

    return {
      extraMenuItems,
      onSelectExtraMenuItem,

      numAllPages: computed(() =>
        hasValue(props.searchResult)
          ? Math.ceil(
              props.searchResult.numSearchResults /
                props.searchResult.numResultsPerPage,
            )
          : null,
      ),

      classDisplayValue: computed(() =>
        searchConditionClassToDisplayValue(props.searchCondition.class),
      ),
      inSchoolStateDisplayValue: computed(() =>
        searchConditionInSchoolStateToDisplayValue(
          props.searchCondition.inSchoolState,
        ),
      ),
      sortDisplayValue: computed(() =>
        studentSortConditionToDisplayValue(props.searchCondition.sort),
      ),
      numResultsDisplayValue: computed(() =>
        numResultsConditionToDisplayValue(props.searchCondition.numResults),
      ),
    };
  },
});
