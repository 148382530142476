
import { computed, defineComponent, PropType, ref } from "vue";
import { useUserService } from "@/composables/provide-user-service";
import SyncWithGWSButton from "@/components/buttons/SyncWithGWSButton/SyncWithGWSButton.vue";
import { useAppToast } from "@/composables/use-app-toast";
import { UserType } from "@/ts/objects/value/user-type";
import { CheckFailed } from "@/ts/app/error/check-error";

export default defineComponent({
  name: "SyncWithGWSView",
  components: { SyncWithGWSButton },
  props: {
    userType: { type: String as PropType<UserType>, required: true },
  },
  setup(props) {
    const userService = useUserService();
    const { showError, showSuccess } = useAppToast();

    const userTypeJapanese = computed(() => {
      switch (props.userType) {
        case "teacher":
          return "教職員";
        case "student":
          return "児童生徒";
        case "guardian":
          return "保護者";
        default:
          throw new CheckFailed();
      }
    });

    const syncing = ref(false);

    const syncWithGWS = async () => {
      syncing.value = true;
      const result = await userService.syncWithGWS(props.userType);
      syncing.value = false;
      if (!result.ok) {
        showError(
          `Google Workspaceとの同期に失敗しました: ${result.error.displayMessage}`,
        );
        return;
      }

      switch (props.userType) {
        case "teacher":
          showSuccess(
            `${result.data.createdTeachers}名の教職員を作成しました。\n${result.data.updatedTeachers}名の教職員を更新しました。`,
          );
          break;
        case "student":
          showSuccess(
            `${result.data.createdStudents}名の児童生徒を作成しました。\n${result.data.updatedStudents}名の児童生徒を更新しました。`,
          );
          break;
        case "guardian":
          showSuccess(
            `${result.data.createdGuardians}名の保護者を作成しました。\n${result.data.updatedGuardians}名の保護者を更新しました。`,
          );
          break;
        default:
          throw new CheckFailed();
      }
    };

    return {
      userTypeJapanese,

      syncing,
      syncWithGWS,
    };
  },
});
