
import { defineComponent, PropType } from "vue";
import BaseCheckbox from "@/components/checkboxes/BaseCheckbox/BaseCheckbox.vue";

export default defineComponent({
  name: "UserEditHeaderRole",
  components: { BaseCheckbox },
  props: {
    isAdmin: { type: Boolean, required: false },
    editable: { type: Boolean, required: true },
    onToggleAdmin: {
      type: Function as PropType<(isAdmin: boolean) => void>,
      required: true,
    },
  },
});
