import { arrayMoveImmutable } from "array-move";
import { format } from "date-fns";
import {
  DateValue,
  displayValueToDateValueOrNull,
} from "@/ts/objects/value/date-value";
import { UnionToIntersection } from "type-fest";
import { DeepWritable } from "@/ts/utils/common-types";
import isNaN from "lodash/isNaN";
import clamp from "lodash/clamp";
import intersectionWith from "lodash/intersectionWith";
import differenceWith from "lodash/differenceWith";
import cloneDeep from "lodash/cloneDeep";
import pickBy from "lodash/pickBy";
import log from "loglevel";
import { Result } from "@/ts/app/result";
import { ObjectName } from "@/ts/app/object-name";
import { errors, ThrowableAppError } from "@/ts/app/error/app-error";
import omitBy from "lodash/omitBy";
import isEqual from "lodash/isEqual";

/**
 * 引数がnullまたはundefinedであればtrueを返す。
 *
 * `value == null` でも全く同じことができる: https://stackoverflow.com/a/5515385/12579447
 * が、イコールの数を数えるのが面倒だし、きっとそのうち間違えるので、テンプレート外ではこの関数を推奨する。
 */
export function isNullish<T>(
  value: T | null | undefined,
): value is null | undefined {
  return value === null || value === undefined;
}

/**
 * 引数がnullでもundefinedでもなければtrueを返す。
 */
export function hasValue<T>(value: T | null | undefined): value is T {
  return !isNullish(value);
}

export function filterNotNullish<T>(arr: (T | null | undefined)[]): T[] {
  return arr.filter((v): v is T => hasValue(v));
}

export function isStringEmpty(value: string | null | undefined): boolean {
  return isNullish(value) || value === "";
}

export function isStringNotEmpty(value: string | null | undefined): boolean {
  return !isStringEmpty(value);
}

export function isStringBlank(value: string | null | undefined): boolean {
  return isNullish(value) || value.trim() === "";
}

export function isStringNotBlank(value: string | null | undefined): boolean {
  return !isStringBlank(value);
}

/**
 * 文字列を整数に変換し、変換できなければnullを返す。
 */
export function parseIntOrNull(value: string): number | null {
  const parsed = parseInt(value, 10);
  if (isNullish(parsed) || isNaN(parsed)) return null;
  return parsed;
}

/**
 * 文字列を整数に変換し、変換できなければエラーを投げる。
 */
export function parseIntOrError(value: string, objectName: ObjectName): number {
  const result = parseIntOrAppError(value, objectName);
  if (!result.ok) throw new ThrowableAppError(result.error);
  return result.data;
}

/**
 * 文字列を整数に変換し、変換できなければAppErrorを返す。
 */
export function parseIntOrAppError(
  value: string,
  objectName: ObjectName,
): Result<number> {
  const parsed = parseIntOrNull(value);
  if (isNullish(parsed))
    return {
      ok: false,
      error: errors.validationFailed.invalidValue(value, objectName),
    };
  return { ok: true, data: parsed };
}

/**
 * 文字列をbooleanに変換し、変換できなければnullを返す。
 */
export function parseBooleanOrNull(value: string): boolean | null {
  if (value === "true" || value === "True") return true;
  if (value === "false" || value === "False") return false;
  return null;
}

/**
 * 文字列をbooleanに変換し、変換できなければエラーを投げる。
 */
export function parseBooleanOrError(value: string): boolean {
  const parsed = parseBooleanOrNull(value);
  if (isNullish(parsed))
    throw new Error(`parseBooleanOrError: failed to parse value: ${value}`);
  return parsed;
}

export function transformIfNotNullish<T, U>(
  value: T | null | undefined,
  transform: (value: T) => U,
): U | null | undefined {
  if (isNullish(value)) return value;
  return transform(value);
}

export function transformIfNotUndefined<T, U>(
  value: T | undefined,
  transform: (value: T) => U,
): U | undefined {
  if (value === undefined) return undefined;
  return transform(value);
}

/**
 * 指定したミリ秒だけ待つ。
 */
export async function delay(millis: number): Promise<void> {
  await new Promise<void>((resolve) => setTimeout(() => resolve(), millis));
}

/**
 * 配列の末尾に値を追加するが、既に同じものが存在すれば何もしない。
 * ※ setでやればいいようなものだが、setはリアクティブにできないので・・・。
 */
export function pushUniq<T>(arr: T[], value: T): T[] {
  if (arr.find((v) => v === value) !== undefined) return arr;
  return [...arr, value];
}

export function hasDuplicates<T extends string | number>(arr: T[]): boolean {
  return new Set(arr).size !== arr.length; // もっと速い方法はありそうだが・・・。
}

/**
 * 配列の中で、1要素を上(indexの小さい方)か下(indexの大きい方)へ1個動かす。
 * 配列をmutateせず、変更があった場合はコピーを返す。
 *
 * findElemで最初にヒットするものを探し、それを動かす。
 * 何も見つからなければ何もしない。
 * upを指定したのにすでに一番上にいたりと、動けない場合も何もしない。
 */
export function arrayMoveOne<T>(
  arr: readonly T[],
  findElem: (elem: T) => boolean,
  up: boolean,
): T[] {
  const currentIdx = arr.findIndex(findElem);
  if (currentIdx < 0) return arr.slice();

  const toIdx = clamp(up ? currentIdx - 1 : currentIdx + 1, 0, arr.length - 1);

  return arrayMoveImmutable(arr, currentIdx, toIdx);
}

export function arrayDiff<T>(
  arr0: readonly T[],
  arr1: readonly T[],
  comparator: (v0: T, v1: T) => boolean = (v0: T, v1: T) => v0 === v1,
): { added: T[]; removed: T[] } {
  const intersection = intersectionWith(arr0, arr1, comparator);
  log.debug(
    `arrayDiff: arr0=${JSON.stringify(arr0)}, arr1=${JSON.stringify(
      arr1,
    )}, intersection=${JSON.stringify(intersection)}`,
  );
  return {
    added: differenceWith(arr1, intersection, comparator),
    removed: differenceWith(arr0, intersection, comparator),
  };
}

/**
 * 最初に見つかった、条件を満たす要素を更新する。
 * 元の配列は更新せず、コピーを返す。
 *
 * @return [更新済配列, 更新対象が見つかったかどうか]
 */
export function updateFirst<T>(
  arr: readonly T[],
  findElem: (v: T) => boolean,
  update: (v: T) => T,
): [T[], boolean] {
  const idx = arr.findIndex(findElem);
  if (idx < 0) return [arr.slice(), false];

  const _arr = arr.slice();
  _arr.splice(idx, 1, update(cloneDeep(_arr[idx])));
  return [_arr, true];
}

/**
 * 最初に見つかった、条件を満たす要素を置き換える。
 * 見つからなければ、末尾に追加する。
 * 元の配列は更新せず、コピーを返す。
 */
export function put<T>(
  arr: readonly T[],
  findElem: (v: T) => boolean,
  newVal: T,
): T[] {
  const idx = arr.findIndex(findElem);
  if (idx < 0) return [...arr, newVal];

  const _arr = arr.slice();
  _arr.splice(idx, 1, newVal);
  return _arr;
}

export function getStringValueWithKey(key: string, obj: any): string {
  const value = obj[key];
  if (isNullish(value) || typeof value !== "string")
    throw new Error(`getStringValueWithKey: key ${key} is not found in object`);
  return value;
}

export function getNumberValueWithKey(key: string, obj: any): number {
  const value = obj[key];
  if (isNullish(value) || typeof value !== "number")
    throw new Error(`getNumberValueWithKey: key ${key} is not found in object`);
  return value;
}

export function formatISO8601(date: Date): string {
  return format(date, "yyyy-MM-dd'T'HH:mm:ssXXX");
}

export function asTextOrNull(value: unknown): string | null {
  if (typeof value !== "string") return null;
  return value;
}

export function asIntOrNull(
  value: unknown,
  /**
   * 最小値。
   * これより小さいと異常値と判定し、nullを返す。
   */
  min?: number,
  /**
   * 最大値。
   * これより大きいと異常値と判定し、nullを返す。
   */
  max?: number,
): number | null {
  if (typeof value === "number") return Math.floor(value);

  if (typeof value !== "string") return null;

  const numValue = parseInt(value, 10);
  if (isNullish(numValue) || isNaN(numValue)) return null;
  if (hasValue(min) && numValue < min) return null;
  if (hasValue(max) && max < numValue) return null;
  return numValue;
}

export function asDateValueOrNull(value: unknown): DateValue | null {
  if (typeof value !== "string") return null;
  return displayValueToDateValueOrNull(value);
}

export function typedObjectKeys<T extends Record<string, any>>(
  obj: T,
): (keyof T)[] {
  return Object.keys(obj) as (keyof T)[];
}

export function typedIntegerKeyedObjectKeys<T extends Record<number, any>>(
  obj: T,
): (keyof T)[] {
  return Object.keys(obj).map((k) => parseInt(k, 10)) as (keyof T)[];
}

export function typedObjectEntries<Key extends string, Value>(
  obj: Record<Key, Value>,
): [Key, Value][] {
  return Object.entries(obj) as [Key, Value][];
}

export function typedIntegerKeyedObjectEntries<Key extends number, Value>(
  obj: Record<Key, Value>,
): [Key, Value][] {
  return Object.entries(obj).map(([k, v]) => [parseInt(k, 10), v]) as [
    Key,
    Value,
  ][];
}

// 参考: https://stackoverflow.com/a/69019874/12579447
type EntriesType =
  | [PropertyKey, unknown][]
  | ReadonlyArray<readonly [PropertyKey, unknown]>;
type UnionObjectFromArrayOfPairs<ARR_T extends EntriesType> =
  DeepWritable<ARR_T> extends (infer R)[]
    ? R extends [infer key, infer val]
      ? { [prop in key & PropertyKey]: val }
      : never
    : never;
type MergeIntersectingObjects<ObjT> = { [key in keyof ObjT]: ObjT[key] };
type EntriesToObject<ARR_T extends EntriesType> = MergeIntersectingObjects<
  UnionToIntersection<UnionObjectFromArrayOfPairs<ARR_T>>
>;
export function createTypedObjectFromEntries<ARR_T extends EntriesType>(
  arr: ARR_T,
): EntriesToObject<ARR_T> {
  return Object.fromEntries(arr) as EntriesToObject<ARR_T>;
}

export function dropAllUndefinedFields<T extends Record<string, any>>(
  obj: T,
): Partial<T> {
  return pickBy(obj, (v) => v !== undefined) as Partial<T>;
}

/**
 * オブジェクトの変化を取得する。
 * 変化の取得にあたっては（deep diffではなく）、トップレベルキー単位のみ比較を行う。
 * （値同士の比較にはisEqualを使う。）
 * 参考: https://tacamy.hatenablog.com/entry/2018/03/04/005938
 *
 * 型制約を入れたので大丈夫だとは思うが、例えば
 * before = { a: 1, b: 2 }, after = { a: 1 }
 * みたいな、キーごと削除のdiffはうまく取れない。注意。
 */
export function getChangesByTopLevelKey<T extends Record<string, any>>(
  before: T,
  after: T,
): Partial<T> {
  return omitBy(after, (v, k) => isEqual(before[k], v)) as Partial<T>;
}

export function capitalizeFirstLetter<T extends string | null | undefined>(
  text: T,
): T | string {
  if (typeof text !== "string") return text;
  if (text.length <= 0) return text;
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export function decapitalizeFirstLetter<T extends string | null | undefined>(
  text: T,
): T | string {
  if (typeof text !== "string") return text;
  if (text.length <= 0) return text;
  return text.charAt(0).toLowerCase() + text.slice(1);
}

const pluralRules = new Intl.PluralRules("en-US", { type: "ordinal" });
const ordinalsSuffixes = {
  zero: "", // English localeでは使わないみたい。
  one: "st",
  two: "nd",
  few: "rd",
  many: "", // English localeでは使わないみたい。
  other: "th",
};

/**
 * 0 -> th, 1 -> st のように序数の接尾語を得る。
 * 参考: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/PluralRules/PluralRules
 */
export function getOrdinalsSuffix(n: number): string {
  const rule = pluralRules.select(n);
  const suffix = ordinalsSuffixes[rule];
  if (isNullish(suffix)) return "";
  return suffix;
}

/**
 * 0 -> 0th, 1 -> 1st のように序数表現にする。
 * 参考: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/PluralRules/PluralRules
 */
export function formatOrdinals(n: number): string {
  return `${n}${getOrdinalsSuffix(n)}`;
}
