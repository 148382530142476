import { useRoute } from "vue-router";
import { computed, ComputedRef } from "vue";
import { asPageNameOrNull, PageName } from "@/ts/app/pages/pages";
import {
  asClassSubPageNameOrNull,
  ClassSubPageName,
} from "@/ts/app/pages/class-sub-pages";
import { asIntOrNull, asTextOrNull } from "@/ts/utils/common-util";
import { asSchoolTypeOrNull, SchoolType } from "@/ts/objects/value/school-type";

export function useClassRoute(currentSchoolYear: number): {
  pageName: ComputedRef<PageName | null>;
  subPageName: ComputedRef<ClassSubPageName | null>;
  classId: ComputedRef<string | null>;
  schoolType: ComputedRef<SchoolType>;
  schoolYear: ComputedRef<number>;
} {
  const route = useRoute();
  const splitPath = computed(() => route.path.split("/"));
  const query = computed(() => route.query);
  const params = computed(() => route.params);

  const pageName = computed(() => {
    return asPageNameOrNull(splitPath.value[1]);
  });
  const subPageName = computed(() => {
    return asClassSubPageNameOrNull(splitPath.value[2]);
  });

  const classId = computed(() => asTextOrNull(params.value["classId"]));

  const schoolType = computed(
    () => asSchoolTypeOrNull(query.value["schoolType"]) ?? "elementary",
  );
  const schoolYear = computed(
    () => asIntOrNull(query.value["schoolYear"], 1, 99999) ?? currentSchoolYear,
  );

  return {
    pageName,
    subPageName,
    classId,
    schoolType,
    schoolYear,
  };
}
