import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-71235996"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["aria-label"]
const _hoisted_2 = { class: "name-cell" }
const _hoisted_3 = { class: "value-cell" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EditableItemText = _resolveComponent("EditableItemText")!

  return (_openBlock(), _createElementBlock("div", {
    class: "student-custom-column-edit-table-item-container",
    role: "cell",
    "aria-label": _ctx.cellName
  }, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.cellName), 1),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_EditableItemText, {
        class: "editable-item",
        value: _ctx.customColumnName,
        editable: _ctx.editing,
        type: "input",
        "font-size": 13,
        "font-color": "black",
        "on-input": _ctx.onInput
      }, null, 8, ["value", "editable", "on-input"])
    ])
  ], 8, _hoisted_1))
}