import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2828f101"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page-heading-container" }
const _hoisted_2 = { class: "label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_font_awesome_icon, {
      class: "icon",
      icon: [_ctx.iconType, _ctx.icon]
    }, null, 8, ["icon"]),
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.label), 1)
  ]))
}