export const constants = {
  // adminRoleIdのべた書きは、よりきちんとしたロール管理機能ができるまでの繋ぎ。
  adminRoleId: "42603ff6-94d6-43be-a5c1-b07891846ecd",
} as const;

export const localStorageKeys = {
  teacherColumnVisibility: "teacherColumnVisibility",
  studentColumnVisibility: "studentColumnVisibility",
  guardianColumnVisibility: "guardianColumnVisibility",
} as const;
