import {
  createRouter,
  createWebHistory,
  NavigationGuardWithThis,
  Router,
  RouteRecordRaw,
} from "vue-router";
import StudentMain from "@/views/student/StudentMain.vue";
import StudentListContainer from "@/views/student/StudentList/StudentListContainer.vue";
import StudentEditContainer from "@/views/student/StudentEdit/StudentEditContainer.vue";
import TermMain from "@/views/term/TermMain.vue";
import GuardianListContainer from "@/views/guardian/GuardianList/GuardianListContainer.vue";
import GuardianMain from "@/views/guardian/GuardianMain.vue";
import GuardianEditContainer from "@/views/guardian/GuardianEdit/GuardianEditContainer.vue";
import TeacherListContainer from "@/views/teacher/TeacherList/TeacherListContainer.vue";
import TeacherMain from "@/views/teacher/TeacherMain.vue";
import TeacherEditContainer from "@/views/teacher/TeacherEdit/TeacherEditContainer.vue";
import ClassMain from "@/views/class/ClassMain.vue";
import ClassListContainer from "@/views/class/ClassList/ClassListContainer.vue";
import ClassDetailsContainer from "@/views/class/ClassDetails/ClassDetailsContainer.vue";
import StudentImportContainer from "@/views/student/StudentImport/StudentImportContainer.vue";
import TeacherImportContainer from "@/views/teacher/TeacherImport/TeacherImportContainer.vue";
import GuardianImportContainer from "@/views/guardian/GuardianImport/GuardianImportContainer.vue";
import TermEditContainer from "@/views/term/TermEdit/TermEditContainer.vue";
import SchoolMain from "@/views/school/SchoolMain.vue";
import SchoolEditContainer from "@/views/school/SchoolEdit/SchoolEditContainer.vue";
import Home from "@/views/home/Home.vue";
import StudentCustomColumnEditContainer from "@/views/student/StudentCustomColumnEdit/StudentCustomColumnEditContainer.vue";
import StudentSync from "@/views/student/StudentSync/StudentSync.vue";
import TeacherSync from "@/views/teacher/TeacherSync/TeacherSync.vue";
import GuardianSync from "@/views/guardian/GuardianSync/GuardianSync.vue";
import CurriculumMain from "@/views/curriculum/CurriculumMain.vue";
import CurriculumEditContainer from "@/views/curriculum/CurriculumEdit/CurriculumEditContainer.vue";
import Forbidden from "@/views/error/Forbidden.vue";

export function getRouter(getIsAdmin: () => boolean): Router {
  const routes = getRoutes();
  const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
  });
  router.beforeEach(beforeEach(getIsAdmin));

  return router;
}

function getRoutes(): RouteRecordRaw[] {
  return [
    {
      path: "/home",
      component: Home,
    },
    {
      path: "/teacher",
      redirect: "/teacher/list",
      component: TeacherMain,
      children: [
        {
          path: "list",
          component: TeacherListContainer,
        },
        {
          path: "edit/:userId",
          component: TeacherEditContainer,
        },
        {
          path: "import",
          component: TeacherImportContainer,
        },
        {
          path: "sync",
          component: TeacherSync,
        },
      ],
    },
    {
      path: "/student",
      redirect: "/student/list",
      component: StudentMain,
      children: [
        {
          path: "list",
          component: StudentListContainer,
        },
        {
          path: "edit/:userId",
          component: StudentEditContainer,
        },
        {
          path: "import",
          component: StudentImportContainer,
        },
        {
          path: "sync",
          component: StudentSync,
        },
        {
          path: "customcol",
          component: StudentCustomColumnEditContainer,
        },
      ],
    },
    {
      path: "/guardian",
      redirect: "/guardian/list",
      component: GuardianMain,
      children: [
        {
          path: "list",
          component: GuardianListContainer,
        },
        {
          path: "edit/:userId",
          component: GuardianEditContainer,
        },
        {
          path: "import",
          component: GuardianImportContainer,
        },
        {
          path: "sync",
          component: GuardianSync,
        },
      ],
    },
    {
      path: "/class",
      redirect: "/class/list",
      component: ClassMain,
      children: [
        {
          path: "list",
          component: ClassListContainer,
        },
        {
          path: "details/:classId",
          component: ClassDetailsContainer,
        },
      ],
    },
    {
      path: "/term",
      redirect: "/term/edit",
      component: TermMain,
      children: [
        {
          path: "edit",
          component: TermEditContainer,
        },
      ],
    },
    {
      path: "/curriculum",
      redirect: "/curriculum/edit",
      component: CurriculumMain,
      children: [
        {
          path: "edit",
          component: CurriculumEditContainer,
        },
      ],
    },
    {
      path: "/school",
      redirect: "/school/edit",
      component: SchoolMain,
      children: [
        {
          path: "edit",
          component: SchoolEditContainer,
        },
      ],
    },
    {
      path: "/error/forbidden",
      meta: { allowNonAdmin: true },
      component: Forbidden,
    },
    {
      path: "/:pathMatch(.*)*",
      redirect: "/home",
    },
  ];
}

function beforeEach(
  getIsAdmin: () => boolean,
): NavigationGuardWithThis<undefined> {
  return (to, from, next) => {
    if (to.meta["allowNonAdmin"] === true) {
      next();
      return;
    }

    if (!getIsAdmin()) {
      next("/error/forbidden");
      return;
    }

    next();
  };
}
