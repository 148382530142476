
import { defineComponent, PropType } from "vue";
import BaseCustomDropdown from "@/components/dropdowns/BaseCustomDropdown/BaseCustomDropdown.vue";
import {
  ExportDropdownOptionValue,
  isExportDropdownOptionValue,
} from "@/components/dropdowns/ExportDropdown/export-dropdown";

export default defineComponent({
  name: "ExportDropdown",
  components: { BaseCustomDropdown },
  props: {
    enabled: { type: Boolean, required: false, default: true },
    onSelect: {
      type: Function as PropType<(value: ExportDropdownOptionValue) => void>,
      required: true,
    },
  },
  setup(props) {
    const items: {
      readonly value: ExportDropdownOptionValue;
      readonly label: string;
    }[] = [
      { value: "export-all-columns", label: "All columns" },
      { value: "export-selected-columns", label: "Only selected columns" },
    ];

    const onSelectInternal = (value: string | null) => {
      if (!isExportDropdownOptionValue(value)) return;
      props.onSelect(value);
    };

    return {
      items,

      onSelectInternal,
    };
  },
});
