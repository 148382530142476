
import { computed, defineComponent, PropType, ref, watchEffect } from "vue";
import { BasicUserInfoPartial } from "@/ts/objects/value/basic-user-info";
import { isNullish, isStringBlank } from "@/ts/utils/common-util";
import { userEditPagePathOrHome } from "@/router/path-constructors";
import { useUserService } from "@/composables/provide-user-service";

export default defineComponent({
  name: "UserListTableCellUser",
  props: {
    user: { type: Object as PropType<BasicUserInfoPartial>, required: false },

    isLink: { type: Boolean, required: true },
  },
  setup(props) {
    const userService = useUserService();

    const displayValue = ref("");
    watchEffect(async () => {
      const _user = props.user;
      if (isNullish(_user)) {
        displayValue.value = "";
        return;
      }
      if (!isStringBlank(_user.name)) {
        displayValue.value = _user.name ?? "";
        return;
      }

      displayValue.value = _user.googleMail ?? "";
      const result = await userService.getBasicUserInfo(_user.userId);
      if (result.ok && !isStringBlank(result.data.name)) {
        displayValue.value = result.data.name;
        return;
      }
    });

    const linkPath = computed<string | null>(() => {
      const _user = props.user;
      if (isNullish(_user)) return null;

      return userEditPagePathOrHome(_user.userType, _user.userId);
    });

    return {
      displayValue,
      linkPath,
    };
  },
});
