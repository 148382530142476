
import {
  defineComponent,
  nextTick,
  onMounted,
  onUnmounted,
  PropType,
  ref,
  watch,
} from "vue";
import clamp from "lodash/clamp";
import debounce from "lodash/debounce";
import { isNullish } from "@/ts/utils/common-util";

export default defineComponent({
  name: "AutoResizeTextarea",
  props: {
    placeholder: { type: String, default: "" },
    value: { type: String, required: true },
    maxChars: { type: Number, default: 9999 },
    minHeight: { type: Number, default: 1 },
    maxHeight: { type: Number, default: 500 },
    triggerOnWindowResize: { type: Boolean, required: true },
    /**
     * trueなら、onInputで吐く値から改行を取り除く。
     * (それ以外のこと、例えばprops.valueに改行が入っていないことの保証などはしない。)
     */
    forceSingleLine: { type: Boolean, default: false },

    enabled: { type: Boolean, default: true },

    onInput: {
      type: Function as PropType<(value: string) => void>,
      required: true,
    },
    onBlur: {
      type: Function as PropType<() => void>,
      default: () => {
        return;
      },
    },
  },
  setup(props) {
    const el = ref<Element | null>(null);
    const height = ref(1);

    const resize = async () => {
      const _el = el.value;
      if (isNullish(_el)) return;

      height.value = 1;
      await nextTick();
      height.value = clamp(_el.scrollHeight, props.minHeight, props.maxHeight);
    };
    const debouncedResize = debounce(resize, 100);

    onMounted(() => {
      resize();

      // たまに最初にリサイズできないことがあるので、試しに仕込んでみる。無意味なら消す。
      // debouncedResize();

      // 画面リサイズイベントによるトリガーはdebounceする。（そうしないと非常に重くなる）
      if (props.triggerOnWindowResize)
        window.addEventListener("resize", debouncedResize);
    });
    onUnmounted(() => {
      window.removeEventListener("resize", debouncedResize);
    });

    watch(() => props.value, resize);

    return {
      el,
      height,

      onInputInternal: (e: Event) => {
        if (!props.enabled) return;

        const value = (e.target as HTMLInputElement).value;
        if (isNullish(value)) return;

        if (props.forceSingleLine) {
          props.onInput(value.replace(/\n/g, ""));
        } else {
          props.onInput(value);
        }
      },
    };
  },
});
