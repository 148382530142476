import {
  SearchConditionClass,
  searchConditionClassDefault,
  SearchConditionClassRaw,
  searchConditionClassToQueryParams,
  searchConditionClassToRaw,
} from "@/ts/objects/search-condition/search-condition-class";
import {
  SearchConditionInSchoolState,
  searchConditionInSchoolStateDefault,
  SearchConditionInSchoolStateRaw,
  searchConditionInSchoolStateToQueryParams,
  searchConditionInSchoolStateToRaw,
} from "@/ts/objects/search-condition/search-condition-in-school-state";
import {
  NumResultsCondition,
  numResultsConditionDefault,
  numResultsConditionToQueryParams,
} from "@/ts/objects/search-condition/num-results-condition";
import {
  StudentSortCondition,
  studentSortConditionDefault,
  StudentSortConditionRaw,
  studentSortConditionToQueryParams,
  studentSortConditionToRaw,
} from "@/ts/objects/search-condition/student-sort-condition";
import { DateValue } from "@/ts/objects/value/date-value";

export type StudentSearchCondition = {
  readonly schoolYear: number;
  readonly queryText: string;
  readonly class: SearchConditionClass;
  readonly inSchoolState: SearchConditionInSchoolState;
  readonly sort: StudentSortCondition;
  readonly numResults: NumResultsCondition;
};

export function studentSearchConditionDefault(
  schoolYear: number,
  date: DateValue,
): StudentSearchCondition {
  return {
    schoolYear,
    queryText: "",
    class: searchConditionClassDefault(schoolYear),
    inSchoolState: searchConditionInSchoolStateDefault(date),
    sort: studentSortConditionDefault(schoolYear),
    numResults: numResultsConditionDefault(),
  };
}

export function studentSearchConditionToQueryParams(
  cond: StudentSearchCondition,
): Record<string, string | number | undefined> {
  return {
    schoolYear: cond.schoolYear,
    q: cond.queryText,
    ...searchConditionClassToQueryParams(cond.class),
    ...searchConditionInSchoolStateToQueryParams(cond.inSchoolState),
    ...studentSortConditionToQueryParams(cond.sort),
    ...numResultsConditionToQueryParams(cond.numResults),
  };
}

export type StudentSearchConditionRaw = {
  readonly schoolYear: number;
  readonly queryText: string;
  readonly class: SearchConditionClassRaw;
  readonly inSchoolState: SearchConditionInSchoolStateRaw;
  readonly sort: StudentSortConditionRaw;
  readonly numResults: NumResultsCondition;
};

export function studentSearchConditionToRaw(
  cond: StudentSearchCondition,
): StudentSearchConditionRaw {
  return {
    schoolYear: cond.schoolYear,
    queryText: cond.queryText,
    class: searchConditionClassToRaw(cond.class),
    inSchoolState: searchConditionInSchoolStateToRaw(cond.inSchoolState),
    sort: studentSortConditionToRaw(cond.sort),
    numResults: cond.numResults,
  };
}
