
import { computed, defineComponent, ref, watchEffect } from "vue";
import GuardianImport from "@/views/guardian/GuardianImport/GuardianImport.vue";
import { useGuardianStore } from "@/store/guardian-store";
import { useUserService } from "@/composables/provide-user-service";
import { useRouter } from "vue-router";
import { isNullish } from "@/ts/utils/common-util";
import { useDebouncedDataLoading } from "@/composables/use-debounced-data-loading";
import {
  GuardianCSVFile,
  GuardianCSVRow,
  importedToGuardianCSVRow,
  updateGuardiansWithCSVRows,
} from "@/ts/app/columns/csv/guardian-csv";
import {
  Guardian,
  updatingGuardianToPartial,
} from "@/ts/objects/entity/guardian";
import log from "loglevel";
import { names } from "@/ts/app/object-name";
import { mapData } from "@/ts/app/loadable-data";
import { parseCSV } from "@/ts/utils/app-util";
import { AppError } from "@/ts/app/error/app-error";
import { useAppToast } from "@/composables/use-app-toast";
import { getGuardianColumnDefs } from "@/ts/app/columns/def/guardian/guardian-column";

export default defineComponent({
  name: "GuardianImportContainer",
  components: { GuardianImport },
  setup() {
    const guardianStore = useGuardianStore();
    const userService = useUserService();
    const router = useRouter();
    const { showError, showSuccess } = useAppToast();

    const showErrorImportFailed = (error: AppError) => {
      showError(
        `${names.guardianCSV.d}のインポートに失敗しました: ${error.displayMessage}`,
      );
    };

    const saving = ref(false); // セーブ中(confirm後)はtrue。trueの間、操作できない。

    const [
      guardiansBefore,
      debouncedLoadGuardiansBefore,
      clearGuardiansBefore,
    ] = useDebouncedDataLoading(
      null,
      (args: { userIds: string[] }) => {
        return userService.listGuardiansByPost(args.userIds);
      },
      200,
      true,
      true,
      showErrorImportFailed,
    );

    const csvFile = ref<GuardianCSVFile | null>(null);

    const [guardiansAfter, debouncedLoadGuardiansAfter, clearGuardiansAfter] =
      useDebouncedDataLoading(
        null,
        async (args: {
          filename: string;
          guardians: Guardian[];
          rows: GuardianCSVRow[];
        }) => {
          return updateGuardiansWithCSVRows(
            args.filename,
            args.guardians,
            args.rows,
          );
        },
        200,
        true,
        true,
        showErrorImportFailed,
      );
    watchEffect(() => {
      log.debug(`GuardianImportContainer: watchEffect: Triggered.`);

      const _csvFile = csvFile.value;
      const _guardiansBefore = guardiansBefore.value;
      if (isNullish(_csvFile) || !_guardiansBefore.hasFreshData) {
        log.debug(`GuardianImportContainer: watchEffect: clearGuardiansAfter`);
        clearGuardiansAfter();
        return;
      }

      log.debug(
        `GuardianImportContainer: watchEffect: debouncedLoadGuardiansAfter`,
      );
      debouncedLoadGuardiansAfter({
        filename: _csvFile.filename,
        guardians: _guardiansBefore.data,
        rows: _csvFile.rows,
      });
    });

    const allColumnDefs = getGuardianColumnDefs();
    const onSelectFile = async (file: File) => {
      if (saving.value) return;

      const result = await parseCSV(
        file,
        importedToGuardianCSVRow,
        allColumnDefs,
        true,
      );
      if (!result.ok) {
        showErrorImportFailed(result.error);
        return;
      }

      const _csvFile: GuardianCSVFile = {
        filename: file.name,
        rows: result.data,
      };
      csvFile.value = _csvFile;

      debouncedLoadGuardiansBefore({
        userIds: _csvFile.rows.map((r) => r.userId),
      });
    };
    const onUnselectFile = () => {
      if (saving.value) return;

      csvFile.value = null;
      clearGuardiansBefore();
      clearGuardiansAfter();
    };

    const columnVisibility = computed(() => guardianStore.columnVisibility);
    const isColumnSelectOpen = ref(true);
    const toggleColumnSelectVisibility = () =>
      (isColumnSelectOpen.value = !isColumnSelectOpen.value);
    const onChangeColumnVisibility = guardianStore.updateColumnVisibility;

    const onConfirmImport = async () => {
      if (saving.value) return;

      const _guardiansAfter = guardiansAfter.value;
      if (!_guardiansAfter.hasFreshData) {
        return;
      }

      saving.value = true;

      const partialGuardians = _guardiansAfter.data.map((g) =>
        updatingGuardianToPartial(g),
      );
      const result = await userService.batchPatchGuardian(partialGuardians);
      if (!result.ok) {
        showErrorImportFailed(result.error);
        saving.value = false;
        return;
      }

      showSuccess(
        `${partialGuardians.length}件の${names.guardian.d}データをインポートしました。`,
      );

      csvFile.value = null;
      clearGuardiansBefore();
      clearGuardiansAfter();

      saving.value = false;
    };

    const onClickBack = () => router.back();

    return {
      saving,

      guardiansAfter: computed(() =>
        mapData(guardiansAfter.value, (d) => d.map((g) => g.guardian)),
      ),

      onSelectFile,
      onUnselectFile,

      columnVisibility,
      isColumnSelectOpen,
      toggleColumnSelectVisibility,
      onChangeColumnVisibility,

      onConfirmImport,
      onClickBack,
    };
  },
});
