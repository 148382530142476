
import { computed, defineComponent, PropType, ref } from "vue";
import StudentClassesSelectPopup from "@/components/popups/StudentClassesSelectPopup/StudentClassesSelectPopup.vue";
import { ColorLabel, labelToColor } from "@/ts/objects/value/color-label";
import { StudentClass } from "@/ts/objects/value/student-class";
import StudentClassText from "@/components/texts/StudentClassText/StudentClassText.vue";

export default defineComponent({
  name: "EditableItemStudentClasses",
  components: { StudentClassText, StudentClassesSelectPopup },
  props: {
    value: { type: Array as PropType<StudentClass[]>, required: true },
    /**
     * 編集可能状態であればtrue。
     */
    editable: { type: Boolean, required: true },

    paddingTop: { type: Number, default: 4, required: false },
    paddingRight: { type: Number, default: 4, required: false },
    paddingBottom: { type: Number, default: 4, required: false },
    paddingLeft: { type: Number, default: 4, required: false },
    fontSize: { type: Number, default: 14 },
    fontColor: { type: String as PropType<ColorLabel>, default: "black" },
    /**
     * trueにした場合、
     * editableがfalseの間、入力ボックスを消して、中身の値だけを普通のテキストのように表示する。
     */
    hideBoxOnDisable: { type: Boolean, default: true },
    /**
     * trueにした場合、
     * editableがfalseの間、クラス詳細ページへのリンクになる。
     */
    isLinkOnDisable: { type: Boolean, required: false, default: false },

    promptText: { type: String, default: "ユーザーを選択します。" },
    onInput: {
      type: Function as PropType<(classes: StudentClass[]) => void>,
      required: true,
    },
  },
  setup(props) {
    const active = ref(false);

    const openPopup = () => {
      if (!props.editable) return;
      active.value = true;
    };
    const closePopup = () => {
      active.value = false;
    };
    const onConfirm = (classes: StudentClass[]) => {
      active.value = false;
      if (props.editable) props.onInput(classes);
    };

    return {
      active,

      openPopup,
      closePopup,
      onConfirm,

      styles: computed(() => ({
        "--padding-top": `${props.paddingTop}px`,
        "--padding-right": `${props.paddingRight}px`,
        "--padding-bottom": `${props.paddingBottom}px`,
        "--padding-left": `${props.paddingLeft}px`,
        "--font-size": `${props.fontSize}px`,
        "--color": labelToColor(props.fontColor),
      })),
    };
  },
});
