import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GuardianEdit = _resolveComponent("GuardianEdit")!

  return (_openBlock(), _createBlock(_component_GuardianEdit, {
    "is-admin": _ctx.isAdmin,
    data: _ctx.data,
    "edit-state": _ctx.editState,
    "selected-tab": _ctx.selectedTab,
    "on-click-back": _ctx.onClickBack,
    "start-editing": _ctx.startEditing,
    "cancel-editing": _ctx.cancelEditing,
    save: _ctx.save,
    "delete-user": _ctx.deleteUser,
    "on-input": _ctx.onInput,
    "on-select-tab": _ctx.onSelectTab
  }, null, 8, ["is-admin", "data", "edit-state", "selected-tab", "on-click-back", "start-editing", "cancel-editing", "save", "delete-user", "on-input", "on-select-tab"]))
}