
import { computed, defineComponent, PropType } from "vue";
import { TeacherClass } from "@/ts/objects/value/teacher-class";
import { schoolTypeToDisplayValue } from "@/ts/objects/value/school-type";
import { isStringNotBlank } from "@/ts/utils/common-util";
import TextBalloon from "@/components/balloons/TextBalloon/TextBalloon.vue";
import { useHovering } from "@/composables/use-hovering";
import { names } from "@/ts/app/object-name";
import { classDetailsPagePath } from "@/router/path-constructors";

export default defineComponent({
  name: "TeacherClassText",
  components: { TextBalloon },
  props: {
    teacherClass: { type: Object as PropType<TeacherClass>, required: true },

    /**
     * trueなら、年度を表示する。
     */
    showSchoolYear: { type: Boolean, required: true },
    /**
     * trueなら、クラス詳細画面へのリンクとなる。
     */
    isLink: { type: Boolean, required: true },

    fontSizeSmaller: { type: Number, required: false, default: 10 },
    fontSizeSmall: { type: Number, required: false, default: 11 },
    fontSize: { type: Number, required: false, default: 12 },
    fontSizeBig: { type: Number, required: false, default: 13 },
    wrap: { type: Boolean, required: false, default: true },
  },
  setup(props) {
    const { hovering, onMouseEnter, onMouseLeave } = useHovering(200);

    const schoolTypeDisplayValue = computed(() =>
      schoolTypeToDisplayValue(props.teacherClass.grade.schoolType),
    );
    const className = computed(() =>
      isStringNotBlank(props.teacherClass.name)
        ? props.teacherClass.name
        : "－",
    );

    const inChargeTypeBalloonContent = computed(
      () => `${names.inChargeType.d} = ${props.teacherClass.inChargeType}`,
    );

    const linkPath = computed(() =>
      classDetailsPagePath(props.teacherClass.classId),
    );

    return {
      hovering,
      onMouseEnter,
      onMouseLeave,

      schoolTypeDisplayValue,
      className,

      inChargeTypeBalloonContent,

      linkPath,

      styles: {
        "--font-size-smaller": `${props.fontSizeSmaller}px`,
        "--font-size-small": `${props.fontSizeSmall}px`,
        "--font-size": `${props.fontSize}px`,
        "--font-size-big": `${props.fontSizeBig}px`,
        "--wrap": props.wrap ? "wrap" : "norwap",
        "--name-white-space": props.wrap ? "normal" : "norwap",
      },
    };
  },
});
