import { LoginInfo } from "@/ts/objects/value/login-info";
import { defineStore } from "pinia";
import log from "loglevel";
import { UserService } from "@/ts/services/user-service";
import {
  applyResult,
  LoadableData,
  loadableDataNull,
} from "@/ts/app/loadable-data";
import { mapResult } from "@/ts/app/result";
import { getToday } from "@/ts/utils/app-util";
import { isNullish } from "@/ts/utils/common-util";

export const useAppStore = defineStore("app", {
  state: (): {
    loginInfo: LoginInfo;
    isAdmin: boolean;
    school: {
      schoolName: LoadableData<string>;
      logoGcsUrl: LoadableData<string | null>;
    };
    currentSchoolYear: number;
  } => {
    return {
      loginInfo: {
        isLoggedIn: false,
        googleIdToken: null,
        userId: null,
        googleMail: null,
        imageUrl: null,
      },
      isAdmin: false,
      school: {
        schoolName: loadableDataNull(),
        logoGcsUrl: loadableDataNull(),
      },
      currentSchoolYear: 2000,
    };
  },
  actions: {
    async setIsAdmin(userService: UserService) {
      log.debug(`appStore: Setting isAdmin...`);

      const userId = this.loginInfo.userId;
      if (isNullish(userId)) return;

      const result = await userService.isAdmin(userId);
      if (!result.ok) return;
      this.isAdmin = result.data.isAdmin;
    },
    async setSchool(userService: UserService) {
      log.debug(`appStore: Setting school information...`);

      const result = await userService.getSchool(false);
      this.school.schoolName = applyResult(
        this.school.schoolName,
        mapResult(result, (s) => s.schoolName),
      );
      this.school.logoGcsUrl = applyResult(
        this.school.logoGcsUrl,
        mapResult(result, (s) => s.logoGcsUrl),
      );

      log.debug(
        `appStore: School information set: schoolName=${this.school.schoolName.data}, logoGcsUrl=${this.school.logoGcsUrl.data}`,
      );
    },
    async setCurrentSchoolYear(userService: UserService) {
      log.debug(`appStore: Setting current schoolYear...`);

      const today = getToday();

      // とりあえず、予想値を設定。
      if (today.month < 4) {
        this.currentSchoolYear = today.year - 1;
      } else {
        this.currentSchoolYear = today.year;
      }

      // ちゃんと取得しに行く。
      // まずは、小学校のschoolYearを取りに行く。
      const resp0 = await userService.getCurrentSchoolYear("elementary");
      if (resp0.ok) {
        this.currentSchoolYear = resp0.data;
      } else {
        // 小学校のschoolYearがなければ、中学校のschoolYearを取りに行く。
        const resp1 = await userService.getCurrentSchoolYear("juniorhigh");
        if (resp1.ok) {
          this.currentSchoolYear = resp1.data;
        }
      }

      log.debug(
        `appStore: Current schoolYear set: value=${this.currentSchoolYear}`,
      );
    },
  },
});
