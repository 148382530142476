
import { defineComponent, PropType } from "vue";
import PopupConfirmButton from "@/components/buttons/PopupConfirmButton/PopupConfirmButton.vue";

export default defineComponent({
  name: "SearchConditionPopupFrame",
  components: { PopupConfirmButton },
  props: {
    label: { type: String, required: true },
    confirmButtonLabel: { type: String, required: true },

    width: { type: Number, default: 330 },
    onClickCancel: { type: Function as PropType<() => void>, required: true },
    onClickConfirm: { type: Function as PropType<() => void>, required: true },
  },
  setup(props) {
    return {
      buttonWidth: props.width - 12,

      styles: {
        "--width": `${props.width}px`,
      },
    };
  },
});
