import { inject, InjectionKey, provide } from "vue";
import { UserService } from "@/ts/services/user-service";
import { hasValue, isNullish } from "@/ts/utils/common-util";
import { App } from "@vue/runtime-core";

export const userServiceKey: InjectionKey<UserService> = Symbol("userService");

export function provideUserService(userService: UserService, app?: App): void {
  const _provide = hasValue(app) ? app.provide : provide;
  _provide(userServiceKey, userService);
}

export function useUserService(): UserService {
  const userService = inject(userServiceKey);

  if (isNullish(userService))
    throw new Error("useUserService: userService not provided");

  return userService;
}
