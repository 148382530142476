
import { defineComponent, PropType } from "vue";
import GuardianSubPageHeading from "@/views/guardian/_components/GuardianSubPageHeading/GuardianSubPageHeading.vue";
import UploadArea from "@/components/other/UploadArea/UploadArea.vue";
import ConfirmImportButton from "@/components/buttons/ConfirmImportButton/ConfirmImportButton.vue";
import GuardianListTable from "@/views/guardian/_components/GuardianListTable/GuardianListTable.vue";
import GuardianColumnsSelect from "@/views/guardian/_components/GuardianColumnsSelect/GuardianColumnsSelect.vue";
import { LoadableData } from "@/ts/app/loadable-data";
import { Guardian } from "@/ts/objects/entity/guardian";
import {
  GuardianColumnVisibility,
  GuardianColumnVisibilityAll,
} from "@/ts/app/columns/visibility/guardian-column-visibility";
import { names } from "@/ts/app/object-name";
import LoadingBlock from "@/components/loading/LoadingBlock/LoadingBlock.vue";

export default defineComponent({
  name: "GuardianImport",
  components: {
    LoadingBlock,
    GuardianColumnsSelect,
    GuardianListTable,
    ConfirmImportButton,
    UploadArea,
    GuardianSubPageHeading,
  },
  props: {
    saving: { type: Boolean, required: true },
    guardians: {
      type: Object as PropType<LoadableData<Guardian[]>>,
      required: true,
    },

    onSelectFile: {
      type: Function as PropType<(file: File) => void>,
      required: true,
    },
    onUnselectFile: {
      type: Function as PropType<() => void>,
      required: true,
    },

    columnVisibility: {
      type: Object as PropType<GuardianColumnVisibilityAll>,
      required: true,
    },
    isColumnSelectOpen: { type: Boolean, required: true },
    toggleColumnSelectVisibility: {
      type: Function as PropType<() => void>,
      required: true,
    },
    onChangeColumnVisibility: {
      type: Function as PropType<(v: GuardianColumnVisibility) => void>,
      required: true,
    },

    onConfirmImport: { type: Function as PropType<() => void>, required: true },

    onClickBack: { type: Function as PropType<() => void>, required: true },
  },
  setup(props) {
    return {
      onUnselectFileInternal: () => {
        props.onUnselectFile();
      },

      googleMailDisplayName: names.googleMail.d,
      childrenDisplayName: names.children.d,
      studentDisplayName: names.student.d,
      guardianDisplayName: names.guardian.d,
    };
  },
});
