import {
  Student,
  studentFromRespOrError,
  studentToReq,
} from "@/ts/objects/entity/student";
import {
  StudentSearchCondition,
  studentSearchConditionToRaw,
} from "@/ts/objects/search-condition/student-search-condition";
import { Result } from "@/ts/app/result";
import { SearchResult } from "@/ts/app/search-result";
import {
  delay,
  formatISO8601,
  hasValue,
  isNullish,
} from "@/ts/utils/common-util";
import log from "loglevel";
import {
  Configuration,
  DefaultApi as UserDefaultApi,
} from "@/ts/api/user-service";
import { AxiosInstance } from "axios";
import { doReq } from "@/ts/utils/app-util";
import {
  Class,
  classFromRespOrError,
  classToReq,
} from "@/ts/objects/entity/class";
import {
  GuardianSearchCondition,
  guardianSearchConditionToRaw,
} from "@/ts/objects/search-condition/guardian-search-condition";
import {
  Guardian,
  guardianFromRespOrError,
  guardianToReq,
} from "@/ts/objects/entity/guardian";
import {
  Teacher,
  teacherFromRespOrError,
  teacherToReq,
} from "@/ts/objects/entity/teacher";
import {
  TeacherSearchCondition,
  teacherSearchConditionToRaw,
} from "@/ts/objects/search-condition/teacher-search-condition";
import {
  StudentCustomColumnNames,
  studentCustomColumnNamesFromRespOrError,
  studentCustomColumnNamesToReq,
} from "@/ts/objects/value/student-custom-column-names";
import { fakeStudentCustomColumnNames } from "@/ts/objects/value/student-custom-column-names-fake";
import { errors, handleThrownError } from "@/ts/app/error/app-error";
import { names } from "@/ts/app/object-name";
import { v4 as uuidv4 } from "uuid";
import {
  asSchoolTypeOrError,
  SchoolType,
} from "@/ts/objects/value/school-type";
import { Term } from "@/ts/objects/entity/term";
import {
  dateValueToDisplayValue,
  displayValueToDateValueOrError,
} from "@/ts/objects/value/date-value";
import { School } from "@/ts/objects/entity/school";
import { fakeSchool } from "@/ts/objects/entity/school-fake";
import { fakeClassesAsArray } from "@/ts/objects/entity/class-fake";
import { fakeTerms } from "@/ts/objects/entity/term-fake";
import { fakeGuardians } from "@/ts/objects/entity/guardian-fake";
import { fakeStudents } from "@/ts/objects/entity/student-fake";
import { fakeTeachers } from "@/ts/objects/entity/teacher-fake";
import isUndefined from "lodash/isUndefined";
import omitBy from "lodash/omitBy";
import orderBy from "lodash/orderBy";
import { SyncWithGwsResponse } from "@/ts/objects/response/sync-with-gws-response";
import { AuthorizeResponse } from "@/ts/objects/response/authorize-response";
import { UserType } from "@/ts/objects/value/user-type";
import { constants } from "@/ts/app/constant";
import {
  BasicUserInfo,
  basicUserInfoFromRespOrError,
} from "@/ts/objects/value/basic-user-info";

export abstract class UserService {
  abstract isAdmin(userId: string): Promise<Result<{ isAdmin: boolean }>>;

  /**
   * ユーザー基本情報を取得する。
   * 結果はブラウザキャッシュされる。
   */
  abstract getBasicUserInfo(userId: string): Promise<Result<BasicUserInfo>>;

  abstract getTeacher(userId: string): Promise<Result<Teacher>>;

  abstract listTeachersPaged(
    searchCondition: TeacherSearchCondition,
    page: number, // 1始まり
  ): Promise<Result<SearchResult<Teacher>>>;

  abstract listTeachers(
    userIds?: string[],
    classId?: string,
  ): Promise<Result<Teacher[]>>;

  /**
   * postリクエストにより、教職員の一覧を取得する。
   * 通常のlistTeachersだとURLが長くなりすぎてエラーになる場合の対策。
   */
  abstract listTeachersByPost(userIds: string[]): Promise<Result<Teacher[]>>;

  abstract patchTeacher(
    teacher: Partial<Teacher> & { readonly userId: string },
  ): Promise<Result<Teacher>>;

  abstract batchPatchTeacher(
    teachers: readonly (Partial<Teacher> & { readonly userId: string })[],
  ): Promise<Result<true>>;

  abstract deleteTeacher(userId: string): Promise<Result<true>>;

  /**
   * admin roleを付与または削除する。
   *
   * @param userId
   * @param isAdmin trueなら付与、falseなら削除。
   */
  abstract toggleAdminRole(
    userId: string,
    isAdmin: boolean,
  ): Promise<Result<{ isAdmin: boolean }>>;

  abstract getStudent(userId: string): Promise<Result<Student>>;

  abstract listStudentsPaged(
    searchCondition: StudentSearchCondition,
    page: number, // 1始まり
  ): Promise<Result<SearchResult<Student>>>;

  abstract listStudents(
    userIds?: string[],
    classId?: string,
    classSchoolYear?: number,
    sortBy?: "gradeClassStudentNumber" | "nameKana" | "createdAt" | "updatedAt",
  ): Promise<Result<Student[]>>;

  /**
   * postリクエストにより、児童生徒の一覧を取得する。
   * 通常のlistStudentsだとURLが長くなりすぎてエラーになる場合の対策。
   */
  abstract listStudentsByPost(userIds: string[]): Promise<Result<Student[]>>;

  abstract patchStudent(
    student: Partial<Student> & { readonly userId: string },
  ): Promise<Result<Student>>;

  abstract uploadStudentPicture(
    userId: string,
    file: File,
  ): Promise<Result<Student>>;

  abstract deleteStudentPicture(userId: string): Promise<Result<true>>;

  abstract batchPatchStudent(
    students: readonly (Partial<Student> & { readonly userId: string })[],
  ): Promise<Result<true>>;

  abstract deleteStudent(userId: string): Promise<Result<true>>;

  abstract getStudentCustomColumnNames(): Promise<
    Result<StudentCustomColumnNames>
  >;

  abstract patchStudentCustomColumnNames(
    customColumnNames: Partial<StudentCustomColumnNames>,
  ): Promise<Result<StudentCustomColumnNames>>;

  abstract getGuardian(userId: string): Promise<Result<Guardian>>;

  abstract listGuardiansPaged(
    searchCondition: GuardianSearchCondition,
    page: number,
  ): Promise<Result<SearchResult<Guardian>>>;

  abstract listGuardians(userIds?: string[]): Promise<Result<Guardian[]>>;

  /**
   * postリクエストにより、保護者の一覧を取得する。
   * 通常のlistGuardiansだとURLが長くなりすぎてエラーになる場合の対策。
   */
  abstract listGuardiansByPost(userIds: string[]): Promise<Result<Guardian[]>>;

  abstract patchGuardian(
    guardian: Partial<Guardian> & { readonly userId: string },
  ): Promise<Result<Guardian>>;

  abstract uploadGuardianPicture(
    userId: string,
    guardianNumber: 1 | 2,
    file: File,
  ): Promise<Result<Guardian>>;

  abstract deleteGuardianPicture(
    userId: string,
    guardianNumber: 1 | 2,
  ): Promise<Result<true>>;

  abstract batchPatchGuardian(
    guardians: readonly (Partial<Guardian> & { readonly userId: string })[],
  ): Promise<Result<true>>;

  abstract deleteGuardian(userId: string): Promise<Result<true>>;

  abstract getClass(classId: string): Promise<Result<Class>>;

  abstract listClasses(schoolYear: number): Promise<Result<Class[]>>;

  abstract postClass(
    cls: Omit<Partial<Class>, "classId">,
  ): Promise<Result<Class>>;

  abstract patchClass(
    cls: Partial<Class> & { readonly classId: string },
  ): Promise<Result<Class>>;

  abstract deleteClass(classId: string): Promise<Result<true>>;

  abstract listTerms(
    schoolYear?: number,
    schoolType?: SchoolType,
    termNumber?: number,
    noCache?: boolean,
  ): Promise<Result<Term[]>>;

  abstract createOrUpdateTerm(term: Term): Promise<Result<Term>>;

  abstract deleteTerm(
    schoolYear: number,
    schoolType: SchoolType,
    termNumber: number,
  ): Promise<Result<true>>;

  abstract getSchool(noCache: boolean): Promise<Result<School>>;

  abstract patchSchool(school: { schoolName: string }): Promise<Result<School>>;

  abstract uploadSchoolLogo(file: File): Promise<Result<School>>;

  abstract deleteSchoolLogo(): Promise<Result<School>>;

  abstract getCurrentSchoolYear(
    schoolType: SchoolType,
  ): Promise<Result<number>>; // TODO getCurrentTermに変えても良い。必要なら。

  abstract updateFirebaseUser(): Promise<Result<{ updated: boolean }>>;

  abstract authorize(redirectUrl: string): Promise<Result<AuthorizeResponse>>;

  abstract syncWithGWS(
    userType: UserType,
  ): Promise<Result<SyncWithGwsResponse>>;
}

export type UserServiceMockInitialData = {
  readonly teachers?: Teacher[];
  readonly students?: Student[];
  readonly guardians?: Guardian[];
  readonly classes?: Class[];
  readonly terms?: Term[];
  readonly school?: School;
  readonly adminUserIds?: string[];
  readonly studentCustomColumnNames?: StudentCustomColumnNames;
};

export class UserServiceMock extends UserService {
  private teachers: Teacher[];
  private students: Student[];
  private guardians: Guardian[];
  private classes: Class[];
  private terms: Term[];
  private school: School;
  private adminUserIds: string[];
  private studentCustomColumnNames: StudentCustomColumnNames;

  constructor(
    data?: UserServiceMockInitialData,
    private fakeLatencyMillis: number = 500,
  ) {
    super();

    const defaults = {
      teachers: Object.values(fakeTeachers()),
      students: Object.values(fakeStudents()),
      guardians: Object.values(fakeGuardians()),
      classes: fakeClassesAsArray(),
      terms: fakeTerms(),
      school: fakeSchool,
      adminUserIds: ["admin000", "teacher000"],
      studentCustomColumnNames: fakeStudentCustomColumnNames(),
    };
    const _data = isNullish(data) ? defaults : { ...defaults, ...data };

    this.teachers = _data.teachers;
    this.students = _data.students;
    this.guardians = _data.guardians;
    this.classes = _data.classes;
    this.terms = _data.terms;
    this.school = _data.school;
    this.adminUserIds = _data.adminUserIds;
    this.studentCustomColumnNames = _data.studentCustomColumnNames;
  }

  async isAdmin(userId: string): Promise<Result<{ isAdmin: boolean }>> {
    const isAdmin = this.adminUserIds.includes(userId);

    return { ok: true, data: { isAdmin } };
  }

  async getBasicUserInfo(
    this: this,
    userId: string,
  ): Promise<Result<BasicUserInfo>> {
    await delay(this.fakeLatencyMillis);

    const _teacher = this.teachers.find((t) => t.userId === userId);
    if (hasValue(_teacher))
      return {
        ok: true,
        data: {
          userId,
          googleMail: _teacher.googleMail,
          userType: "teacher",
          name: _teacher.name,
          photoUrl: _teacher.photoUrl,
        },
      };

    const _student = this.students.find((s) => s.userId === userId);
    if (hasValue(_student))
      return {
        ok: true,
        data: {
          userId,
          googleMail: _student.googleMail,
          userType: "student",
          name: _student.name,
          photoUrl: _student.photoUrl,
        },
      };

    const _guardian = this.guardians.find((g) => g.userId === userId);
    if (hasValue(_guardian))
      return {
        ok: true,
        data: {
          userId,
          googleMail: _guardian.googleMail,
          userType: "guardian",
          name: _guardian.name,
          photoUrl: _guardian.photoUrl,
        },
      };

    return { ok: false, error: errors.fetchFailed(names.basicUserInfo) };
  }

  async getTeacher(this: this, userId: string): Promise<Result<Teacher>> {
    await delay(this.fakeLatencyMillis);

    const _teacher = this.teachers.find((s) => s.userId === userId);
    if (isNullish(_teacher))
      return { ok: false, error: errors.fetchFailed(names.teacher) };

    return { ok: true, data: _teacher };
  }

  async listTeachersPaged(
    this: this,
    searchCondition: TeacherSearchCondition,
    page: number, // 1始まり
  ): Promise<Result<SearchResult<Teacher>>> {
    await delay(this.fakeLatencyMillis);

    const start = (page - 1) * searchCondition.numResults.n;
    const end = start + searchCondition.numResults.n;
    return {
      ok: true,
      data: {
        data: this.teachers.slice(start, end),
        numResultsPerPage: searchCondition.numResults.n,
        numSearchResults: this.teachers.length,
      },
    };
  }

  async listTeachers(
    userIds?: string[],
    classId?: string,
  ): Promise<Result<Teacher[]>> {
    await delay(this.fakeLatencyMillis);

    const found = this.teachers.filter(
      (t) =>
        (isNullish(userIds) || userIds.some((uid) => uid === t.userId)) &&
        (isNullish(classId) || t.classes.some((c) => c.classId === classId)),
    );
    return {
      ok: true,
      data: found,
    };
  }

  async listTeachersByPost(userIds: string[]): Promise<Result<Teacher[]>> {
    await delay(this.fakeLatencyMillis);

    const found = this.teachers.filter((t) =>
      userIds.some((uid) => uid === t.userId),
    );
    return {
      ok: true,
      data: found,
    };
  }

  async patchTeacher(
    this: this,
    teacher: Partial<Teacher> & { readonly userId: string },
  ): Promise<Result<Teacher>> {
    await delay(this.fakeLatencyMillis);

    log.debug(
      `UserServiceMock: patchTeacher: teacher=${JSON.stringify(teacher)}`,
    );

    const _teacher = this.teachers.find((t) => t.userId === teacher.userId);
    if (isNullish(_teacher))
      return { ok: false, error: errors.updateFailed(names.teacher) };

    const updatedTeacher: Teacher = {
      ..._teacher,
      ...teacher,
    };

    this.teachers = [
      ...this.teachers.filter((t) => t.userId !== teacher.userId),
      updatedTeacher,
    ];

    return {
      ok: true,
      data: updatedTeacher,
    };
  }

  async batchPatchTeacher(
    teachers: readonly (Partial<Teacher> & { readonly userId: string })[],
  ): Promise<Result<true>> {
    await delay(this.fakeLatencyMillis);
    log.debug(
      `UserServiceMock: batchPatchTeacher: teachers=${JSON.stringify(
        teachers,
      )}`,
    );
    for (const teacher of teachers) {
      const _teacher = this.teachers.find((t) => t.userId === teacher.userId);
      if (isNullish(_teacher))
        return { ok: false, error: errors.updateFailed(names.teacher) };
    }

    return {
      ok: true,
      data: true,
    };
  }

  async deleteTeacher(this: this, userId: string): Promise<Result<true>> {
    await delay(this.fakeLatencyMillis);

    this.teachers = this.teachers.filter((t) => t.userId !== userId);

    return { ok: true, data: true };
  }

  async toggleAdminRole(
    this: this,
    userId: string,
    isAdmin: boolean,
  ): Promise<Result<{ isAdmin: boolean }>> {
    await delay(this.fakeLatencyMillis);

    const _adminUserIds = this.adminUserIds.filter((id) => id !== userId);

    if (isAdmin) this.adminUserIds = [..._adminUserIds, userId];
    else this.adminUserIds = _adminUserIds;

    return { ok: true, data: { isAdmin } };
  }

  async getStudent(this: this, userId: string): Promise<Result<Student>> {
    await delay(this.fakeLatencyMillis);

    const _student = this.students.find((s) => s.userId === userId) ?? null;
    if (isNullish(_student))
      return { ok: false, error: errors.fetchFailed(names.student) };

    return { ok: true, data: _student };
  }

  async listStudentsPaged(
    this: this,
    searchCondition: StudentSearchCondition,
    page: number,
  ): Promise<Result<SearchResult<Student>>> {
    await delay(this.fakeLatencyMillis);

    const start = (page - 1) * searchCondition.numResults.n;
    const end = start + searchCondition.numResults.n;
    return {
      ok: true,
      data: {
        data: this.students.slice(start, end),
        numResultsPerPage: searchCondition.numResults.n,
        numSearchResults: this.students.length,
      },
    };
  }

  async listStudents(
    this: this,
    userIds?: string[],
    classId?: string,
    _classSchoolYear?: number,
    _sortBy?:
      | "gradeClassStudentNumber"
      | "nameKana"
      | "createdAt"
      | "updatedAt",
  ): Promise<Result<Student[]>> {
    await delay(this.fakeLatencyMillis);

    const found = this.students.filter(
      (s) =>
        (isNullish(userIds) || userIds.some((uid) => uid === s.userId)) &&
        (isNullish(classId) || s.classes.some((c) => c.classId === classId)),
    );
    return {
      ok: true,
      data: found,
    };
  }

  async listStudentsByPost(
    this: this,
    userIds: string[],
  ): Promise<Result<Student[]>> {
    await delay(this.fakeLatencyMillis);

    const found = this.students.filter((s) =>
      userIds.some((uid) => uid === s.userId),
    );
    return {
      ok: true,
      data: found,
    };
  }

  async patchStudent(
    this: this,
    student: Partial<Student> & { readonly userId: string },
  ): Promise<Result<Student>> {
    await delay(this.fakeLatencyMillis);

    log.debug(
      `UserServiceMock: patchStudent: student=${JSON.stringify(student)}`,
    );

    const _student = this.students.find((s) => s.userId === student.userId);
    if (isNullish(_student))
      return { ok: false, error: errors.updateFailed(names.student) };

    const updatedStudent: Student = {
      ..._student,
      ...student,
    };

    this.students = [
      ...this.students.filter((s) => s.userId !== student.userId),
      updatedStudent,
    ];

    return {
      ok: true,
      data: updatedStudent,
    };
  }

  async uploadStudentPicture(
    this: this,
    userId: string,
    _file: File,
  ): Promise<Result<Student>> {
    await delay(this.fakeLatencyMillis);

    log.debug(`UserServiceMock: uploadStudentPicture`);

    const _student = this.students.find((s) => s.userId === userId);
    if (isNullish(_student))
      return { ok: false, error: errors.updateFailed(names.student) };
    return {
      ok: true,
      data: _student,
    };
  }

  async deleteStudentPicture(
    this: this,
    userId: string,
  ): Promise<Result<true>> {
    await delay(this.fakeLatencyMillis);

    log.debug(`UserServiceMock: deleteStudentPicture`);

    const _student = this.students.find((s) => s.userId === userId);
    if (isNullish(_student))
      return { ok: false, error: errors.updateFailed(names.student) };
    return { ok: true, data: true };
  }

  async batchPatchStudent(
    this: this,
    students: readonly (Partial<Student> & { readonly userId: string })[],
  ): Promise<Result<true>> {
    await delay(this.fakeLatencyMillis);
    log.debug(
      `UserServiceMock: batchPatchStudent: students=${JSON.stringify(
        students,
      )}`,
    );
    for (const student of students) {
      const _student = this.students.find((s) => s.userId === student.userId);
      if (isNullish(_student))
        return { ok: false, error: errors.updateFailed(names.student) };
    }

    return {
      ok: true,
      data: true,
    };
  }

  async deleteStudent(this: this, userId: string): Promise<Result<true>> {
    await delay(this.fakeLatencyMillis);

    this.students = this.students.filter((s) => s.userId !== userId);

    return { ok: true, data: true };
  }

  async getStudentCustomColumnNames(): Promise<
    Result<StudentCustomColumnNames>
  > {
    await delay(this.fakeLatencyMillis);
    return {
      ok: true,
      data: this.studentCustomColumnNames,
    };
  }

  async patchStudentCustomColumnNames(
    customColumnNames: Partial<StudentCustomColumnNames>,
  ): Promise<Result<StudentCustomColumnNames>> {
    await delay(this.fakeLatencyMillis);

    this.studentCustomColumnNames = {
      ...this.studentCustomColumnNames,
      ...omitBy(customColumnNames, isUndefined),
    };

    return {
      ok: true,
      data: this.studentCustomColumnNames,
    };
  }

  async getGuardian(this: this, userId: string): Promise<Result<Guardian>> {
    await delay(this.fakeLatencyMillis);

    const _guardian = this.guardians.find((s) => s.userId === userId);
    if (isNullish(_guardian))
      return { ok: false, error: errors.fetchFailed(names.guardian) };

    return { ok: true, data: _guardian };
  }

  async listGuardiansPaged(
    this: this,
    searchCondition: GuardianSearchCondition,
    page: number,
  ): Promise<Result<SearchResult<Guardian>>> {
    await delay(this.fakeLatencyMillis);

    const start = (page - 1) * searchCondition.numResults.n;
    const end = start + searchCondition.numResults.n;
    return {
      ok: true,
      data: {
        data: this.guardians.slice(start, end),
        numResultsPerPage: searchCondition.numResults.n,
        numSearchResults: this.guardians.length,
      },
    };
  }

  async listGuardians(userIds?: string[]): Promise<Result<Guardian[]>> {
    await delay(this.fakeLatencyMillis);

    const found = this.guardians.filter(
      (g) => isNullish(userIds) || userIds.some((uid) => uid === g.userId),
    );
    return {
      ok: true,
      data: found,
    };
  }

  async listGuardiansByPost(
    this: this,
    userIds: string[],
  ): Promise<Result<Guardian[]>> {
    await delay(this.fakeLatencyMillis);

    const found = this.guardians.filter((g) =>
      userIds.some((uid) => uid === g.userId),
    );
    return {
      ok: true,
      data: found,
    };
  }

  async patchGuardian(
    this: this,
    guardian: Partial<Guardian> & { readonly userId: string },
  ): Promise<Result<Guardian>> {
    await delay(this.fakeLatencyMillis);

    log.debug(
      `UserServiceMock: patchGuardian: guardian=${JSON.stringify(guardian)}`,
    );

    const _guardian = this.guardians.find((s) => s.userId === guardian.userId);
    if (isNullish(_guardian))
      return { ok: false, error: errors.updateFailed(names.guardian) };

    const updatedGuardian: Guardian = {
      ..._guardian,
      ...guardian,
    };

    this.guardians = [
      ...this.guardians.filter((g) => g.userId !== guardian.userId),
      updatedGuardian,
    ];

    return {
      ok: true,
      data: updatedGuardian,
    };
  }

  async uploadGuardianPicture(
    this: this,
    userId: string,
    guardianNumber: 1 | 2,
    _file: File,
  ): Promise<Result<Guardian>> {
    await delay(this.fakeLatencyMillis);

    log.debug(
      `UserServiceMock: uploadGuardianPicture: guardianNumber=${guardianNumber}`,
    );

    const _guardian = this.guardians.find((s) => s.userId === userId);
    if (isNullish(_guardian))
      return { ok: false, error: errors.updateFailed(names.guardian) };
    return {
      ok: true,
      data: _guardian,
    };
  }

  async deleteGuardianPicture(
    this: this,
    userId: string,
    guardianNumber: 1 | 2,
  ): Promise<Result<true>> {
    await delay(this.fakeLatencyMillis);

    log.debug(
      `UserServiceMock: deleteGuardianPicture: guardianNumber=${guardianNumber}`,
    );

    const _guardian = this.guardians.find((s) => s.userId === userId);
    if (isNullish(_guardian))
      return { ok: false, error: errors.updateFailed(names.guardian) };
    return { ok: true, data: true };
  }

  async batchPatchGuardian(
    this: this,
    guardians: readonly (Partial<Guardian> & { readonly userId: string })[],
  ): Promise<Result<true>> {
    await delay(this.fakeLatencyMillis);
    log.debug(
      `UserServiceMock: batchPatchGuardian: guardians=${JSON.stringify(
        guardians,
      )}`,
    );

    for (const guardian of guardians) {
      const _guardian = this.guardians.find(
        (g) => g.userId === guardian.userId,
      );
      if (isNullish(_guardian))
        return { ok: false, error: errors.updateFailed(names.guardian) };
    }

    return {
      ok: true,
      data: true,
    };
  }

  async deleteGuardian(this: this, userId: string): Promise<Result<true>> {
    await delay(this.fakeLatencyMillis);

    this.guardians = this.guardians.filter((g) => g.userId !== userId);

    return { ok: true, data: true };
  }

  async getClass(this: this, classId: string): Promise<Result<Class>> {
    await delay(this.fakeLatencyMillis);

    const cls = this.classes.find((c) => c.classId === classId);

    if (isNullish(cls))
      return {
        ok: false,
        error: errors.fetchFailed(names.class),
      };

    return {
      ok: true,
      data: cls,
    };
  }

  async listClasses(this: this, schoolYear: number): Promise<Result<Class[]>> {
    await delay(this.fakeLatencyMillis);

    const classes = this.classes.filter((c) => c.schoolYear === schoolYear);

    return {
      ok: true,
      data: orderBy(classes, ["schoolYear", "grade", "classNo"]),
    };
  }

  async postClass(
    this: this,
    cls: Omit<Class, "classId">,
  ): Promise<Result<Class>> {
    await delay(this.fakeLatencyMillis);

    const classId = uuidv4();
    const newClass = { ...cls, classId };
    this.classes = [...this.classes, newClass];
    return { ok: true, data: newClass };
  }

  async patchClass(
    this: this,
    cls: Partial<Class> & { readonly classId: string },
  ): Promise<Result<Class>> {
    await delay(this.fakeLatencyMillis);

    const _cls = this.classes.find((c) => c.classId === cls.classId);
    if (isNullish(_cls))
      return { ok: false, error: errors.updateFailed(names.class) };

    const updatedClass = { ..._cls, ...cls };
    this.classes = [
      ...this.classes.filter((c) => c.classId !== cls.classId),
      updatedClass,
    ];
    return { ok: true, data: updatedClass };
  }

  async deleteClass(this: this, classId: string): Promise<Result<true>> {
    await delay(this.fakeLatencyMillis);

    const _cls = this.classes.find((c) => c.classId === classId);
    if (isNullish(_cls))
      return { ok: false, error: errors.deleteFailed(names.class) };

    this.classes = this.classes.filter((c) => c.classId !== classId);
    return { ok: true, data: true };
  }

  async listTerms(
    this: this,
    schoolYear?: number,
    schoolType?: SchoolType,
    termNumber?: number,
    _noCache?: boolean,
  ): Promise<Result<Term[]>> {
    await delay(this.fakeLatencyMillis);

    return {
      ok: true,
      data: this.terms.filter(
        (t) =>
          (schoolYear === undefined || t.schoolYear === schoolYear) &&
          (schoolType === undefined || t.schoolType === schoolType) &&
          (termNumber === undefined || t.termNumber === termNumber),
      ),
    };
  }

  async createOrUpdateTerm(this: this, term: Term): Promise<Result<Term>> {
    await delay(this.fakeLatencyMillis);

    this.terms = [
      ...this.terms.filter(
        (t) =>
          !(
            t.schoolType === term.schoolType &&
            t.schoolYear === term.schoolYear &&
            t.termNumber === term.termNumber
          ),
      ),
      term,
    ];
    return { ok: true, data: term };
  }

  async deleteTerm(
    this: this,
    schoolYear: number,
    schoolType: SchoolType,
    termNumber: number,
  ): Promise<Result<true>> {
    await delay(this.fakeLatencyMillis);

    const idx = this.terms.findIndex(
      (t) =>
        t.schoolType === schoolType &&
        t.schoolYear === schoolYear &&
        t.termNumber === termNumber,
    );
    if (idx < 0) {
      return { ok: false, error: errors.deleteFailed(names.term) };
    }

    this.terms.splice(idx, 1);
    return { ok: true, data: true };
  }

  async getSchool(_noCache: boolean): Promise<Result<School>> {
    await delay(this.fakeLatencyMillis);

    return { ok: true, data: this.school };
  }

  async patchSchool(school: { schoolName: string }): Promise<Result<School>> {
    await delay(this.fakeLatencyMillis);

    this.school = {
      ...this.school,
      schoolName: school.schoolName,
    };
    return { ok: true, data: this.school };
  }

  async uploadSchoolLogo(_file: File): Promise<Result<School>> {
    await delay(this.fakeLatencyMillis);

    this.school = {
      ...this.school,
      logoGcsUrl: "some-url",
    };
    return { ok: true, data: this.school };
  }

  async deleteSchoolLogo(): Promise<Result<School>> {
    await delay(this.fakeLatencyMillis);

    this.school = {
      ...this.school,
      logoGcsUrl: null,
    };
    return { ok: true, data: this.school };
  }

  async getCurrentSchoolYear(
    this: this,
    schoolType: SchoolType,
  ): Promise<Result<number>> {
    await delay(this.fakeLatencyMillis);

    return { ok: true, data: 2000 };
  }

  async updateFirebaseUser(this: this): Promise<Result<{ updated: boolean }>> {
    return { ok: true, data: { updated: false } };
  }

  async authorize(
    this: this,
    _redirectUrl: string,
  ): Promise<Result<AuthorizeResponse>> {
    await delay(this.fakeLatencyMillis);

    log.debug(`UserServiceMock: authorize`);
    return {
      ok: true,
      data: { needAuth: false },
    };
  }

  async syncWithGWS(
    this: this,
    _userType: UserType,
  ): Promise<Result<SyncWithGwsResponse>> {
    await delay(this.fakeLatencyMillis);

    log.debug(`UserServiceMock: syncWithGWS`);
    return {
      ok: true,
      data: {
        createdTeachers: 0,
        updatedTeachers: 0,
        createdStudents: 0,
        updatedStudents: 0,
        createdGuardians: 0,
        updatedGuardians: 0,
      },
    };
  }
}

export class UserServiceImpl extends UserService {
  private readonly userService: UserDefaultApi;

  constructor(
    serviceBasePath: string,
    axiosConf: Configuration | undefined,
    axiosInstance: AxiosInstance,
  ) {
    super();
    this.userService = new UserDefaultApi(
      axiosConf,
      serviceBasePath,
      axiosInstance,
    );
  }

  async isAdmin(
    this: this,
    userId: string,
  ): Promise<Result<{ isAdmin: boolean }>> {
    const resp = await doReq(() =>
      this.userService.listRolebinding(userId, constants.adminRoleId),
    );
    if (!resp.ok) return resp;

    const isAdmin = resp.data.some(
      (rb) => rb.userId === userId && rb.roleId === constants.adminRoleId,
    );

    return { ok: true, data: { isAdmin } };
  }

  async getBasicUserInfo(
    this: this,
    userId: string,
  ): Promise<Result<BasicUserInfo>> {
    const resp = await doReq(() => this.userService.getBasicUserInfo(userId));
    if (!resp.ok) return resp;

    return { ok: true, data: basicUserInfoFromRespOrError(resp.data) };
  }

  async getTeacher(this: this, userId: string): Promise<Result<Teacher>> {
    const resp = await doReq(() => this.userService.getTeacher(userId));
    if (!resp.ok) return resp;

    return { ok: true, data: teacherFromRespOrError(resp.data) };
  }

  async listTeachersPaged(
    this: this,
    searchCondition: TeacherSearchCondition,
    page: number, // 1始まり
  ): Promise<Result<SearchResult<Teacher>>> {
    const rawSearchCond = teacherSearchConditionToRaw(searchCondition);

    const offset = (page - 1) * rawSearchCond.numResults.n;

    const resp = await doReq(() =>
      this.userService.listTeacher(
        undefined,
        rawSearchCond.queryText,
        rawSearchCond.teacherType.teacherType,
        rawSearchCond.schoolYear,
        rawSearchCond.class.schoolType,
        rawSearchCond.class.gradeNumber,
        rawSearchCond.class.classIds,
        rawSearchCond.class.noClass,
        rawSearchCond.sort.sortBy,
        rawSearchCond.sort.sortOrder,
        rawSearchCond.numResults.n,
        offset,
      ),
    );
    if (!resp.ok) return resp;

    return {
      ok: true,
      data: {
        data: resp.data.data.map((t) => teacherFromRespOrError(t)),
        numResultsPerPage: rawSearchCond.numResults.n,
        numSearchResults: resp.data.totalCount,
      },
    };
  }

  async listTeachers(
    this: this,
    userIds?: string[],
    classId?: string,
  ): Promise<Result<Teacher[]>> {
    const resp = await doReq(() =>
      this.userService.listTeacher(
        userIds,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        hasValue(classId) ? [classId] : undefined,
      ),
    );
    if (!resp.ok) return resp;

    return {
      ok: true,
      data: resp.data.data.map((t) => teacherFromRespOrError(t)),
    };
  }

  async listTeachersByPost(userIds: string[]): Promise<Result<Teacher[]>> {
    const resp = await doReq(() =>
      this.userService.listTeacherByPost({ userIds }),
    );
    if (!resp.ok) return resp;

    return {
      ok: true,
      data: resp.data.data.map((t) => teacherFromRespOrError(t)),
    };
  }

  async patchTeacher(
    this: this,
    teacher: Partial<Teacher> & { readonly userId: string },
  ): Promise<Result<Teacher>> {
    const resp = await doReq(() =>
      this.userService.patchTeacher(teacher.userId, teacherToReq(teacher)),
    );
    if (!resp.ok) return resp;

    return { ok: true, data: teacherFromRespOrError(resp.data) };
  }

  async batchPatchTeacher(
    this: this,
    teachers: readonly (Partial<Teacher> & { readonly userId: string })[],
  ): Promise<Result<true>> {
    const resp = await doReq(() =>
      this.userService.batchPatchTeacher(
        teachers.map((t) => ({
          userId: t.userId,
          request: teacherToReq(t),
        })),
        { timeout: 30000 },
      ),
    );
    if (!resp.ok) return resp;

    return { ok: true, data: true };
  }

  async deleteTeacher(this: this, userId: string): Promise<Result<true>> {
    const resp = await doReq(() => this.userService.deleteTeacher(userId));
    if (!resp.ok) return resp;

    return { ok: true, data: true };
  }

  async getStudent(this: this, userId: string): Promise<Result<Student>> {
    const resp = await doReq(() => this.userService.getStudent(userId));
    if (!resp.ok) return resp;

    return { ok: true, data: studentFromRespOrError(resp.data) };
  }

  async toggleAdminRole(
    this: this,
    userId: string,
    isAdmin: boolean,
  ): Promise<Result<{ isAdmin: boolean }>> {
    const key = `${userId}:${constants.adminRoleId}`;
    const resp = await (isAdmin
      ? doReq(() => this.userService.putRolebinding(key))
      : doReq(() => this.userService.deleteRolebinding(key)));
    if (!resp.ok) return resp;

    return { ok: true, data: { isAdmin } };
  }

  async listStudentsPaged(
    this: this,
    searchCondition: StudentSearchCondition,
    page: number,
  ): Promise<Result<SearchResult<Student>>> {
    const rawSearchCond = studentSearchConditionToRaw(searchCondition);

    const offset = (page - 1) * rawSearchCond.numResults.n;

    const resp = await doReq(() =>
      this.userService.listStudent(
        undefined,
        rawSearchCond.queryText,
        undefined,
        rawSearchCond.class.schoolType,
        rawSearchCond.class.gradeNumber,
        rawSearchCond.class.classIds,
        rawSearchCond.class.noClass,
        rawSearchCond.inSchoolState.inSchoolState,
        rawSearchCond.inSchoolState.on,
        rawSearchCond.schoolYear,
        rawSearchCond.sort.sortBy,
        rawSearchCond.sort.sortOrder,
        rawSearchCond.numResults.n,
        offset,
      ),
    );
    if (!resp.ok) return resp;

    return {
      ok: true,
      data: {
        data: resp.data.data.map((s) => studentFromRespOrError(s)),
        numResultsPerPage: rawSearchCond.numResults.n,
        numSearchResults: resp.data.totalCount,
      },
    };
  }

  async listStudents(
    this: this,
    userIds?: string[],
    classId?: string,
    classSchoolYear?: number,
    sortBy?: "gradeClassStudentNumber" | "nameKana" | "createdAt" | "updatedAt",
  ): Promise<Result<Student[]>> {
    const resp = await doReq(() =>
      this.userService.listStudent(
        userIds,
        undefined,
        undefined,
        undefined,
        undefined,
        hasValue(classId) ? [classId] : undefined,
        undefined,
        undefined,
        undefined,
        classSchoolYear,
        sortBy,
      ),
    );
    if (!resp.ok) return resp;
    return {
      ok: true,
      data: resp.data.data.map((s) => studentFromRespOrError(s)),
    };
  }

  async listStudentsByPost(
    this: this,
    userIds: string[],
  ): Promise<Result<Student[]>> {
    const resp = await doReq(() =>
      this.userService.listStudentByPost({ userIds }),
    );
    if (!resp.ok) return resp;
    return {
      ok: true,
      data: resp.data.data.map((s) => studentFromRespOrError(s)),
    };
  }

  async patchStudent(
    this: this,
    student: Partial<Student> & { readonly userId: string },
  ): Promise<Result<Student>> {
    const resp = await doReq(() =>
      this.userService.patchStudent(student.userId, studentToReq(student)),
    );
    if (!resp.ok) return resp;
    return { ok: true, data: studentFromRespOrError(resp.data) };
  }

  async uploadStudentPicture(
    this: this,
    userId: string,
    file: File,
  ): Promise<Result<Student>> {
    const resp = await doReq(() =>
      this.userService.uploadStudentPicture(userId, file),
    );
    if (!resp.ok) return resp;
    return { ok: true, data: studentFromRespOrError(resp.data) };
  }

  async deleteStudentPicture(
    this: this,
    userId: string,
  ): Promise<Result<true>> {
    const resp = await doReq(() =>
      this.userService.deleteStudentPicture(userId),
    );
    if (!resp.ok) return resp;
    return { ok: true, data: true };
  }

  async batchPatchStudent(
    this: this,
    students: readonly (Partial<Student> & { readonly userId: string })[],
  ): Promise<Result<true>> {
    const resp = await doReq(() =>
      this.userService.batchPatchStudent(
        students.map((s) => ({
          userId: s.userId,
          request: studentToReq(s),
        })),
        { timeout: 30000 },
      ),
    );
    if (!resp.ok) return resp;
    return { ok: true, data: true };
  }

  async deleteStudent(this: this, userId: string): Promise<Result<true>> {
    const resp = await doReq(() => this.userService.deleteStudent(userId));
    if (!resp.ok) return resp;

    return { ok: true, data: true };
  }

  async getStudentCustomColumnNames(
    this: this,
  ): Promise<Result<StudentCustomColumnNames>> {
    const resp = await doReq(() =>
      this.userService.getStudentCustomColumnDef(),
    );
    if (!resp.ok) return resp;
    return {
      ok: true,
      data: studentCustomColumnNamesFromRespOrError(resp.data),
    };
  }

  async patchStudentCustomColumnNames(
    this: this,
    customColumnNames: Partial<StudentCustomColumnNames>,
  ): Promise<Result<StudentCustomColumnNames>> {
    const resp = await doReq(() =>
      this.userService.patchStudentCustomColumnDef(
        studentCustomColumnNamesToReq(customColumnNames),
      ),
    );
    if (!resp.ok) return resp;
    return {
      ok: true,
      data: studentCustomColumnNamesFromRespOrError(resp.data),
    };
  }

  async getGuardian(this: this, userId: string): Promise<Result<Guardian>> {
    const resp = await doReq(() => this.userService.getGuardian(userId));
    if (!resp.ok) return resp;

    return { ok: true, data: guardianFromRespOrError(resp.data) };
  }

  async listGuardiansPaged(
    this: this,
    searchCondition: GuardianSearchCondition,
    page: number,
  ): Promise<Result<SearchResult<Guardian>>> {
    const rawSearchCond = guardianSearchConditionToRaw(searchCondition);

    const offset = (page - 1) * rawSearchCond.numResults.n;

    const resp = await doReq(() =>
      this.userService.listGuardian(
        undefined,
        rawSearchCond.queryText,
        rawSearchCond.class.schoolType,
        rawSearchCond.class.gradeNumber,
        rawSearchCond.class.classIds,
        rawSearchCond.class.noClass,
        rawSearchCond.inSchoolState.inSchoolState,
        rawSearchCond.inSchoolState.on,
        rawSearchCond.schoolYear,
        rawSearchCond.sort.sortBy,
        rawSearchCond.sort.sortOrder,
        rawSearchCond.numResults.n,
        offset,
      ),
    );
    if (!resp.ok) return resp;
    return {
      ok: true,
      data: {
        data: resp.data.data.map((s) => guardianFromRespOrError(s)),
        numResultsPerPage: rawSearchCond.numResults.n,
        numSearchResults: resp.data.totalCount,
      },
    };
  }

  async listGuardians(
    this: this,
    userIds?: string[],
  ): Promise<Result<Guardian[]>> {
    const resp = await doReq(() => this.userService.listGuardian(userIds));
    if (!resp.ok) return resp;
    return {
      ok: true,
      data: resp.data.data.map((g) => guardianFromRespOrError(g)),
    };
  }

  async listGuardiansByPost(
    this: this,
    userIds: string[],
  ): Promise<Result<Guardian[]>> {
    const resp = await doReq(() =>
      this.userService.listGuardianByPost({ userIds }),
    );
    if (!resp.ok) return resp;
    return {
      ok: true,
      data: resp.data.data.map((g) => guardianFromRespOrError(g)),
    };
  }

  async patchGuardian(
    this: this,
    guardian: Partial<Guardian> & { readonly userId: string },
  ): Promise<Result<Guardian>> {
    const resp = await doReq(() =>
      this.userService.patchGuardian(guardian.userId, guardianToReq(guardian)),
    );
    if (!resp.ok) return resp;
    return { ok: true, data: guardianFromRespOrError(resp.data) };
  }

  async uploadGuardianPicture(
    this: this,
    userId: string,
    guardianNumber: 1 | 2,
    file: File,
  ): Promise<Result<Guardian>> {
    const resp = await doReq(() =>
      guardianNumber === 1
        ? this.userService.uploadGuardianPicture1(userId, file)
        : this.userService.uploadGuardianPicture2(userId, file),
    );
    if (!resp.ok) return resp;
    return { ok: true, data: guardianFromRespOrError(resp.data) };
  }

  async deleteGuardianPicture(
    this: this,
    userId: string,
    guardianNumber: 1 | 2,
  ): Promise<Result<true>> {
    const resp = await doReq(() =>
      guardianNumber === 1
        ? this.userService.deleteGuardianPicture1(userId)
        : this.userService.deleteGuardianPicture2(userId),
    );
    if (!resp.ok) return resp;
    return { ok: true, data: true };
  }

  async batchPatchGuardian(
    this: this,
    guardians: readonly (Partial<Guardian> & { readonly userId: string })[],
  ): Promise<Result<true>> {
    const resp = await doReq(() =>
      this.userService.batchPatchGuardian(
        guardians.map((g) => ({
          userId: g.userId,
          request: guardianToReq(g),
        })),
        { timeout: 30000 },
      ),
    );
    if (!resp.ok) return resp;
    return { ok: true, data: true };
  }

  async deleteGuardian(this: this, userId: string): Promise<Result<true>> {
    const resp = await doReq(() => this.userService.deleteGuardian(userId));
    if (!resp.ok) return resp;

    return { ok: true, data: true };
  }

  async getClass(this: this, classId: string): Promise<Result<Class>> {
    const resp = await doReq(() => this.userService.getClass(classId));
    if (!resp.ok) return resp;
    return { ok: true, data: classFromRespOrError(resp.data) };
  }

  async listClasses(this: this, schoolYear: number): Promise<Result<Class[]>> {
    const resp = await doReq(() => this.userService.listClass(schoolYear));
    if (!resp.ok) return resp;
    return { ok: true, data: resp.data.map((r) => classFromRespOrError(r)) };
  }

  async postClass(
    this: this,
    cls: Omit<Partial<Class>, "classId">,
  ): Promise<Result<Class>> {
    const resp = await doReq(() => this.userService.postClass(classToReq(cls)));
    if (!resp.ok) return resp;
    return { ok: true, data: classFromRespOrError(resp.data) };
  }

  async patchClass(
    this: this,
    cls: Partial<Class> & { readonly classId: string },
  ): Promise<Result<Class>> {
    const resp = await doReq(() =>
      this.userService.patchClass(cls.classId, classToReq(cls)),
    );
    if (!resp.ok) return resp;
    return { ok: true, data: classFromRespOrError(resp.data) };
  }

  async deleteClass(this: this, classId: string): Promise<Result<true>> {
    const resp = await doReq(() => this.userService.deleteClass(classId));
    if (!resp.ok) return resp;
    return { ok: true, data: true };
  }

  async listTerms(
    this: this,
    schoolYear?: number,
    schoolType?: SchoolType,
    termNumber?: number,
    noCache?: boolean,
  ): Promise<Result<Term[]>> {
    const resp = await doReq(() =>
      this.userService.listTerm(schoolType, schoolYear, termNumber, noCache),
    );
    if (!resp.ok) return resp;
    try {
      return {
        ok: true,
        data: resp.data.map((t): Term => {
          return {
            schoolYear: t.schoolYear,
            schoolType: asSchoolTypeOrError(t.schoolType),
            termNumber: t.termNumber,
            startDate: displayValueToDateValueOrError(
              t.startDate,
              names.startDate,
            ),
            endDate: displayValueToDateValueOrError(t.endDate, names.endDate),
          };
        }),
      };
    } catch (e) {
      const appError = handleThrownError("UserService.listTerms", e);
      return { ok: false, error: appError };
    }
  }

  async createOrUpdateTerm(this: this, term: Term): Promise<Result<Term>> {
    const resp = await doReq(() =>
      this.userService.putTerm(
        `${term.schoolYear}:${term.schoolType}:${term.termNumber}`,
        {
          startDate: dateValueToDisplayValue(term.startDate),
          endDate: dateValueToDisplayValue(term.endDate),
        },
      ),
    );
    if (!resp.ok) return resp;
    return {
      ok: true,
      data: {
        schoolYear: resp.data.schoolYear,
        schoolType: asSchoolTypeOrError(resp.data.schoolType),
        termNumber: resp.data.termNumber,
        startDate: displayValueToDateValueOrError(
          resp.data.startDate,
          names.startDate,
        ),
        endDate: displayValueToDateValueOrError(
          resp.data.endDate,
          names.endDate,
        ),
      },
    };
  }

  async deleteTerm(
    this: this,
    schoolYear: number,
    schoolType: SchoolType,
    termNumber: number,
  ): Promise<Result<true>> {
    const resp = await doReq(() =>
      this.userService.deleteTerm(`${schoolYear}:${schoolType}:${termNumber}`),
    );
    if (!resp.ok) return resp;
    return { ok: true, data: true };
  }

  async getSchool(this: this, noCache: boolean): Promise<Result<School>> {
    const resp = await doReq(() => this.userService.getSchool(noCache));
    if (!resp.ok) return resp;
    return {
      ok: true,
      data: {
        schoolName: resp.data.schoolName,
        logoGcsUrl: resp.data.logoGcsUrl ?? null,
      },
    };
  }

  async patchSchool(
    this: this,
    school: { schoolName: string },
  ): Promise<Result<School>> {
    const resp = await doReq(() =>
      this.userService.patchSchool({
        schoolName: school.schoolName,
      }),
    );
    if (!resp.ok) return resp;
    return {
      ok: true,
      data: {
        schoolName: resp.data.schoolName,
        logoGcsUrl: resp.data.logoGcsUrl ?? null,
      },
    };
  }

  async uploadSchoolLogo(this: this, file: File): Promise<Result<School>> {
    const resp = await doReq(() => this.userService.uploadSchoolLogo(file));
    if (!resp.ok) return resp;
    return {
      ok: true,
      data: {
        schoolName: resp.data.schoolName,
        logoGcsUrl: resp.data.logoGcsUrl ?? null,
      },
    };
  }

  async deleteSchoolLogo(): Promise<Result<School>> {
    const resp = await doReq(() => this.userService.deleteSchoolLogo());
    if (!resp.ok) return resp;
    return {
      ok: true,
      data: {
        schoolName: resp.data.schoolName,
        logoGcsUrl: resp.data.logoGcsUrl ?? null,
      },
    };
  }

  async getCurrentSchoolYear(
    this: this,
    schoolType: SchoolType,
  ): Promise<Result<number>> {
    const textNow = formatISO8601(new Date());

    const resp = await doReq(() =>
      this.userService.getTermByTime(schoolType, textNow),
    );
    if (!resp.ok) return resp;
    return { ok: true, data: resp.data.schoolYear };
  }

  async updateFirebaseUser(this: this): Promise<Result<{ updated: boolean }>> {
    const resp = await doReq(() => this.userService.firebaseAuthUser());
    if (!resp.ok) return resp;
    return { ok: true, data: { updated: resp.data.updated === true } };
  }

  async authorize(
    this: this,
    redirectUrl: string,
  ): Promise<Result<AuthorizeResponse>> {
    const resp = await doReq(() =>
      this.userService.oauthAuthorize({ redirectUrl }),
    );
    if (!resp.ok) return resp;

    if (resp.data.needAuth) {
      return {
        ok: true,
        data: {
          needAuth: true,
          authUrl: resp.data.authUrl ?? "",
        },
      };
    } else {
      return {
        ok: true,
        data: {
          needAuth: false,
        },
      };
    }
  }

  async syncWithGWS(
    this: this,
    userType: UserType,
  ): Promise<Result<SyncWithGwsResponse>> {
    const resp = await doReq(() =>
      this.userService.syncWithGWS({ userType }, { timeout: 30000 }),
    );
    if (!resp.ok) return resp;

    return {
      ok: true,
      data: {
        createdTeachers: resp.data.created.teachers.length,
        updatedTeachers: resp.data.updated.teachers.length,
        createdStudents: resp.data.created.students.length,
        updatedStudents: resp.data.updated.students.length,
        createdGuardians: resp.data.created.guardians.length,
        updatedGuardians: resp.data.updated.guardians.length,
      },
    };
  }
}
