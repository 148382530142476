
import { computed, defineComponent, ref } from "vue";
import StudentCustomColumnEdit from "@/views/student/StudentCustomColumnEdit/StudentCustomColumnEdit.vue";
import {
  applyResult,
  LoadableData,
  loadableDataNull,
  toLoading,
} from "@/ts/app/loadable-data";
import {
  StudentCustomColumnId,
  StudentCustomColumnNames,
} from "@/ts/objects/value/student-custom-column-names";
import {
  getChangesByTopLevelKey,
  hasValue,
  isNullish,
} from "@/ts/utils/common-util";
import cloneDeep from "lodash/cloneDeep";
import { useUserService } from "@/composables/provide-user-service";
import { useStudentStore } from "@/store/student-store";
import { useAppToast } from "@/composables/use-app-toast";
import { useRouter } from "vue-router";
import { useAppStore } from "@/store/app-store";

export default defineComponent({
  name: "StudentCustomColumnEditContainer",
  components: { StudentCustomColumnEdit },
  setup() {
    const appStore = useAppStore();
    const userService = useUserService();
    const studentStore = useStudentStore();
    const router = useRouter();
    const { showError, showSuccess } = useAppToast();

    const isAdmin = computed(() => appStore.isAdmin);

    const freeze = ref(false);

    const customColumnNames = ref<LoadableData<StudentCustomColumnNames>>(
      loadableDataNull(),
    );
    const fetchCustomColumnNames = async () => {
      const result = await userService.getStudentCustomColumnNames();
      customColumnNames.value = applyResult(customColumnNames.value, result);
    };
    fetchCustomColumnNames();

    const unsavedData = ref<StudentCustomColumnNames | null>(null);

    const onInput = (id: StudentCustomColumnId, value: string) => {
      const _unsavedData = unsavedData.value;
      if (isNullish(_unsavedData)) return;

      _unsavedData[id] = value;
    };

    const onClickBack = () => router.back();
    const startEditing = () => {
      // 編集中なら何もしない。
      if (hasValue(unsavedData.value) || freeze.value) return;

      const _customColumnNames = customColumnNames.value;

      unsavedData.value = cloneDeep(_customColumnNames.data);
    };
    const cancelEditing = () => {
      if (freeze.value) return;

      unsavedData.value = null;
    };
    const save = async () => {
      const _customColumnNames = customColumnNames.value;
      const _unsavedData = unsavedData.value;
      if (
        !_customColumnNames.hasData ||
        isNullish(_unsavedData) ||
        freeze.value
      )
        return;

      freeze.value = true;

      customColumnNames.value = toLoading(customColumnNames.value);
      unsavedData.value = null;
      const result = await userService.patchStudentCustomColumnNames(
        getChangesByTopLevelKey(_customColumnNames.data, _unsavedData),
      );
      customColumnNames.value = applyResult(customColumnNames.value, result);
      if (result.ok) {
        showSuccess("変更を保存しました。");
      } else {
        showError(`変更の保存に失敗しました: ${result.error.displayMessage}`);
      }

      await studentStore.reloadCustomColumnNames(userService);

      freeze.value = false;

      await fetchCustomColumnNames();
    };

    return {
      isAdmin,

      customColumnNames,
      unsavedData,

      onInput,

      onClickBack,
      startEditing,
      cancelEditing,
      save,
    };
  },
});
