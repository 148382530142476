import { isNullish } from "@/ts/utils/common-util";

// TODO pngだけ、とかに一次制限できるようにする？ しょせん拡張子くらいしか見れないが・・・。
export function useFileInput(processFile: (file: File) => void): {
  onChangeFileInput: (e: Event) => void;
} {
  const onChangeFileInput = (e: Event) => {
    if (!(e.target instanceof HTMLInputElement)) return;

    const files = e.target.files;
    if (isNullish(files) || files.length === 0) return;
    const file = files[0];
    if (!(file instanceof File)) return;

    processFile(file);
  };

  return { onChangeFileInput };
}
