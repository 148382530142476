import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ClassDetails = _resolveComponent("ClassDetails")!

  return (_openBlock(), _createBlock(_component_ClassDetails, {
    "is-admin": _ctx.isAdmin,
    cls: _ctx.cls,
    data: _ctx.data,
    "edit-state": _ctx.editState,
    "on-update": _ctx.onUpdate,
    "on-click-back": _ctx.onClickBack,
    "start-editing": _ctx.startEditing,
    "cancel-editing": _ctx.cancelEditing,
    save: _ctx.save,
    "on-click-delete": _ctx.onClickDelete
  }, null, 8, ["is-admin", "cls", "data", "edit-state", "on-update", "on-click-back", "start-editing", "cancel-editing", "save", "on-click-delete"]))
}