import { LocationQuery, LocationQueryValue } from "vue-router";
import { isNullish } from "@/ts/utils/common-util";
import { teacherTypes } from "@/ts/objects/value/teacher-type";

const _subTypes = {
  all: "all",
  staff: "staff",
  teacher: "teacher",
} as const;

export const searchConditionTeacherTypeSubTypes = Object.values(_subTypes);

export type SearchConditionTeacherTypeSubType =
  typeof searchConditionTeacherTypeSubTypes[number];

export function isSearchConditionTeacherTypeSubType(
  value: unknown,
): value is SearchConditionTeacherTypeSubType {
  return (
    typeof value === "string" &&
    searchConditionTeacherTypeSubTypes.some((v) => v === value)
  );
}

export function asSearchConditionTeacherTypeSubTypeOrNull(
  value: unknown,
): SearchConditionTeacherTypeSubType | null {
  if (isSearchConditionTeacherTypeSubType(value)) return value;
  return null;
}

export type SearchConditionTeacherType = {
  searchConditionType: "teacher-type";
  subType: SearchConditionTeacherTypeSubType;
};

export function searchConditionTeacherTypeDefault(): SearchConditionTeacherType {
  return {
    searchConditionType: "teacher-type",
    subType: "all",
  };
}

export function searchConditionTeacherTypeToDisplayValue(
  cond: SearchConditionTeacherType,
): string {
  switch (cond.subType) {
    case "all":
      return "すべて";
    case "staff":
      return teacherTypes.staff;
    case "teacher":
      return teacherTypes.teacher;
  }
}

/**
 * Vue側のクエリパラメタ。
 * （API側ではなく。）
 */
type QueryParams = {
  readonly teacherType: string | undefined;
};

export function searchConditionTeacherTypeFromQueryParams(
  query: LocationQuery,
): SearchConditionTeacherType {
  const _query = query as Record<
    keyof QueryParams,
    LocationQueryValue | LocationQueryValue[] | undefined
  >;
  const subType = asSearchConditionTeacherTypeSubTypeOrNull(_query.teacherType);

  if (isNullish(subType)) return searchConditionTeacherTypeDefault();

  return {
    searchConditionType: "teacher-type",
    subType,
  };
}

export function searchConditionTeacherTypeToQueryParams(
  cond: SearchConditionTeacherType,
): QueryParams {
  switch (cond.subType) {
    case "all":
      return { teacherType: undefined };
    case "staff":
      return { teacherType: "staff" };
    case "teacher":
      return { teacherType: "teacher" };
  }
}

// すべてand。
export type SearchConditionTeacherTypeRaw = {
  teacherType?: string;
};

export function searchConditionTeacherTypeToRaw(
  cond: SearchConditionTeacherType,
): SearchConditionTeacherTypeRaw {
  switch (cond.subType) {
    case "all":
      return { teacherType: undefined };
    case "staff":
      return { teacherType: "staff" };
    case "teacher":
      return { teacherType: "teacher" };
  }
}
