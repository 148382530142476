import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-826702f6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "label" }
const _hoisted_2 = { class: "label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.enabled)
    ? (_openBlock(), _createBlock(_component_router_link, {
        key: 0,
        class: "base-link-button-container",
        style: _normalizeStyle(_ctx.styles),
        to: _ctx.to
      }, {
        default: _withCtx(() => [
          (_ctx.icon != null)
            ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                key: 0,
                class: "icon",
                icon: [_ctx.iconType, _ctx.icon]
              }, null, 8, ["icon"]))
            : _createCommentVNode("", true),
          _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.label), 1)
        ]),
        _: 1
      }, 8, ["style", "to"]))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: "base-link-button-container disabled",
        style: _normalizeStyle(_ctx.styles)
      }, [
        (_ctx.icon != null)
          ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
              key: 0,
              class: "icon",
              icon: [_ctx.iconType, _ctx.icon]
            }, null, 8, ["icon"]))
          : _createCommentVNode("", true),
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.label), 1)
      ], 4))
}