import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6fbfac9a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "text"
}
const _hoisted_2 = ["accept", "disabled"]
const _hoisted_3 = { class: "image-wrap editable" }
const _hoisted_4 = ["src"]
const _hoisted_5 = ["accept", "disabled"]
const _hoisted_6 = ["href"]
const _hoisted_7 = ["src"]
const _hoisted_8 = {
  key: 3,
  class: "image-wrap readonly"
}
const _hoisted_9 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuDropdown = _resolveComponent("MenuDropdown")!

  return (_openBlock(), _createElementBlock("div", {
    class: "editable-item-image-container",
    style: _normalizeStyle(_ctx.styles)
  }, [
    (_ctx.fileUrl == null)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          class: _normalizeClass(["empty", { editable: _ctx.editable }])
        }, [
          (_ctx.editable)
            ? (_openBlock(), _createElementBlock("span", _hoisted_1, "Click to upload"))
            : _createCommentVNode("", true),
          _createElementVNode("input", {
            type: "file",
            "data-testid": "image-input",
            accept: _ctx.accept,
            onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onChangeFileInput && _ctx.onChangeFileInput(...args))),
            disabled: !_ctx.editable
          }, null, 40, _hoisted_2)
        ], 2))
      : (_ctx.editable)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createElementVNode("label", _hoisted_3, [
              _createElementVNode("img", {
                class: "image",
                src: _ctx.fileUrl,
                alt: "image"
              }, null, 8, _hoisted_4),
              _createElementVNode("input", {
                type: "file",
                "data-testid": "image-input",
                accept: _ctx.accept,
                onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onChangeFileInput && _ctx.onChangeFileInput(...args))),
                disabled: !_ctx.editable
              }, null, 40, _hoisted_5)
            ]),
            _createVNode(_component_MenuDropdown, {
              class: "menu-button",
              color: "dark-gray",
              size: 12,
              "icon-size": 10,
              items: _ctx.menuItems,
              enabled: _ctx.editable,
              "options-width": 60,
              "options-align": "left",
              "on-select": _ctx.onSelectMenuItem
            }, null, 8, ["items", "enabled", "on-select"])
          ], 64))
        : (_ctx.openOnClick)
          ? (_openBlock(), _createElementBlock("a", {
              key: 2,
              class: "image-wrap readonly open-on-click",
              target: "_blank",
              href: _ctx.fileUrl
            }, [
              _createElementVNode("img", {
                class: "image",
                src: _ctx.fileUrl,
                alt: "image"
              }, null, 8, _hoisted_7)
            ], 8, _hoisted_6))
          : (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createElementVNode("img", {
                class: "image",
                src: _ctx.fileUrl,
                alt: "image"
              }, null, 8, _hoisted_9)
            ]))
  ], 4))
}