import { defineStore } from "pinia";
import { SearchResult } from "@/ts/app/search-result";
import { Class } from "@/ts/objects/entity/class";
import { UserService } from "@/ts/services/user-service";
import { AppError } from "@/ts/app/error/app-error";
import log from "loglevel";
import {
  defaultTeacherProfileColumnVisibility,
  getTeacherColumnVisibility,
  TeacherColumnVisibility,
  TeacherColumnVisibilityAll,
} from "@/ts/app/columns/visibility/teacher-column-visibility";
import { Teacher } from "@/ts/objects/entity/teacher";
import { TeacherSearchCondition } from "@/ts/objects/search-condition/teacher-search-condition";
import { RemovableRef, useLocalStorage } from "@vueuse/core";
import { localStorageKeys } from "@/ts/app/constant";
import pick from "lodash/pick";
import { teacherListProfileColumnIds } from "@/ts/app/columns/user-list/teacher-list-columns";

export const useTeacherStore = defineStore("teacher", {
  state: (): {
    columnVisibility: RemovableRef<TeacherColumnVisibilityAll>;
    teacherSearchResult: SearchResult<Teacher> | null;
    allClassesOnSchoolYear: Class[] | null;
  } => ({
    columnVisibility: useLocalStorage(
      localStorageKeys.teacherColumnVisibility,
      getTeacherColumnVisibility({
        profileColumnVisibility: {
          state: {
            googleMail: true,
            name: true,
          },
        },
      }),
    ),
    teacherSearchResult: null,
    allClassesOnSchoolYear: null,
  }),
  actions: {
    updateColumnVisibility(v: TeacherColumnVisibility) {
      // わざわざpickする理由は、仕様変更によりlocalStorage内のcolumnVisibilityが古くなっている可能性があるから。
      // 読み出すときに修正したいがややこしそうなので、せめてここで修正していく。
      switch (v.groupName) {
        case "profile": {
          this.columnVisibility = {
            ...this.columnVisibility,
            profileColumnVisibility: {
              groupName: "profile",
              state: {
                ...defaultTeacherProfileColumnVisibility(false).state,
                ...pick(v.state, teacherListProfileColumnIds),
              },
            },
          };
          break;
        }
      }
    },
    clearTeachers() {
      this.teacherSearchResult = null;
    },
    async fetchTeachers(
      userService: UserService,
      searchCondition: TeacherSearchCondition,
      page: number,
      onSuccess: () => void = () => {},
      onFail: (e: AppError) => void = () => {},
    ) {
      log.debug(
        `teacherStore: fetchTeachers: searchCondition=${JSON.stringify(
          searchCondition,
        )}, page=${page}`,
      );
      const resp = await userService.listTeachersPaged(searchCondition, page);
      if (!resp.ok) {
        onFail(resp.error);
        return;
      }

      this.teacherSearchResult = resp.data;
      onSuccess();
    },
    clearClasses() {
      this.allClassesOnSchoolYear = null;
    },
    async fetchClasses(
      userService: UserService,
      schoolYear: number,
      onSuccess: () => void = () => {},
      onFail: (e: AppError) => void = () => {},
    ) {
      const resp = await userService.listClasses(schoolYear);
      if (!resp.ok) {
        onFail(resp.error);
        return;
      }

      this.allClassesOnSchoolYear = resp.data;
      onSuccess();
    },
  },
});
