
import { computed, defineComponent, ref, watch, watchEffect } from "vue";
import GuardianList from "@/views/guardian/GuardianList/GuardianList.vue";
import { useAppStore } from "@/store/app-store";
import { useRouter } from "vue-router";
import { useGuardianStore } from "@/store/guardian-store";
import {
  GuardianSearchCondition,
  guardianSearchConditionToQueryParams,
} from "@/ts/objects/search-condition/guardian-search-condition";
import { useGuardianRoute } from "@/router/use-guardian-route";
import debounce from "lodash/debounce";
import { searchConditionClassDefault } from "@/ts/objects/search-condition/search-condition-class";
import { useSearchConditionPopup } from "@/ts/composables/use-search-condition-popup";
import { isNullish } from "@/ts/utils/common-util";
import log from "loglevel";
import { useUserService } from "@/composables/provide-user-service";
import { downloadAsCSV, getToday, unparseCSV } from "@/ts/utils/app-util";
import { names } from "@/ts/app/object-name";
import { format } from "date-fns";
import { guardianToExportingCSVRow } from "@/ts/app/columns/csv/guardian-csv";
import { usePushRouteAfterAuth } from "@/composables/use-push-route-after-auth";
import { ExportDropdownOptionValue } from "@/components/dropdowns/ExportDropdown/export-dropdown";
import {
  guardianColumnVisibilityToListColumnIds,
  GuardianListColumnId,
  guardianListColumnIds,
} from "@/ts/app/columns/user-list/guardian-list-columns";
import { getGuardianColumnDefs } from "@/ts/app/columns/def/guardian/guardian-column";

export default defineComponent({
  name: "GuardianListContainer",
  components: { GuardianList },
  setup() {
    const appStore = useAppStore();
    const guardiansStore = useGuardianStore();
    const router = useRouter();
    const userService = useUserService();

    const isAdmin = computed(() => appStore.isAdmin);

    const {
      searchPageNum,
      queryText,
      schoolYear,
      searchConditionClass,
      searchConditionInSchoolState,
      searchConditionSort,
      searchConditionNumResults,
    } = useGuardianRoute(getToday(), appStore.currentSchoolYear);

    const searchCondition = computed(
      (): GuardianSearchCondition => ({
        schoolYear: schoolYear.value,
        queryText: queryText.value,
        inSchoolState: searchConditionInSchoolState.value,
        class: searchConditionClass.value,
        sort: searchConditionSort.value,
        numResults: searchConditionNumResults.value,
      }),
    );

    // fetchGuardians
    const debouncedFetchGuardiansInternal = debounce(() => {
      guardiansStore.fetchGuardians(
        userService,
        searchCondition.value,
        searchPageNum.value,
      );
    }, 1); // これでちゃんと検索条件がリアクティブになるようだが、気を付けておくこと。
    const fetchGuardians = () => {
      guardiansStore.clearGuardians();
      debouncedFetchGuardiansInternal();
    };

    // reload
    const reload = async (
      page: number = 1,
      schoolYearChanged: boolean = false,
    ) => {
      if (schoolYearChanged) {
        // 年度を変更したら、クラス条件のみリセットする。
        searchConditionClass.value = searchConditionClassDefault(
          schoolYear.value,
        );
      }

      searchPageNum.value = page;
      await router.push({
        path: "/guardian/list",
        query: {
          page,
          ...guardianSearchConditionToQueryParams(searchCondition.value),
        },
      });
      fetchGuardians();
    };

    // 年度ポップアップ
    const [
      isSchoolYearPopupActive,
      changeSchoolYearPopupActiveness,
      onConfirmConditionSchoolYear,
    ] = useSearchConditionPopup(schoolYear, () => reload(1, true));

    // クラスポップアップ
    const [
      isClassPopupActive,
      changeClassPopupActiveness,
      onConfirmConditionClass,
    ] = useSearchConditionPopup(searchConditionClass, reload);

    // 在学状態ポップアップ
    const [
      isInSchoolStatePopupActive,
      changeInSchoolStatePopupActiveness,
      onConfirmConditionInSchoolState,
    ] = useSearchConditionPopup(searchConditionInSchoolState, reload);

    // 並び替え条件ポップアップ
    const [
      isSortPopupActive,
      changeSortPopupActiveness,
      onConfirmConditionSort,
    ] = useSearchConditionPopup(searchConditionSort, reload);

    // 件数ポップアップ
    const [
      isNumResultsPopupActive,
      changeNumResultsPopupActiveness,
      onConfirmConditionNumResults,
    ] = useSearchConditionPopup(searchConditionNumResults, reload);

    const searchResult = computed(() => guardiansStore.guardianSearchResult);

    const numResults = ref<number | null>(null); // 検索結果数だが、表示に使うために古い値も保持する。よって正確な値ではないので注意。
    watchEffect(() => {
      const _searchResult = searchResult.value;
      if (isNullish(_searchResult)) return;
      numResults.value = _searchResult.numSearchResults;
    });

    fetchGuardians();

    const debouncedFetchClassesInternal = debounce(
      () => guardiansStore.fetchClasses(userService, schoolYear.value),
      1,
    );
    const fetchClasses = () => {
      guardiansStore.clearClasses();
      debouncedFetchClassesInternal();
    };
    watch(schoolYear, fetchClasses);
    fetchClasses();

    const allClasses = computed(
      () => guardiansStore.allClassesOnSchoolYear ?? [],
    );

    const isColumnSelectOpen = ref(true);
    const columnVisibility = computed(() => guardiansStore.columnVisibility);

    const pushRouteAfterAuth = usePushRouteAfterAuth(userService);
    const syncWithGWS = () => pushRouteAfterAuth("/guardian/sync");

    const allColumnDefs = getGuardianColumnDefs();
    const onClickExportButton = (value: ExportDropdownOptionValue) => {
      log.debug("GuardianListContainer: onClickExportButton");

      const _searchResult = searchResult.value;
      const _columnVisibility = columnVisibility.value;
      if (isNullish(_searchResult)) return;

      const _guardian = _searchResult.data;
      const columnIds: GuardianListColumnId[] =
        value === "export-all-columns"
          ? guardianListColumnIds
          : guardianColumnVisibilityToListColumnIds(_columnVisibility);

      const csvText = unparseCSV(
        _guardian,
        (g) => guardianToExportingCSVRow(g, columnIds),
        allColumnDefs,
      );
      downloadAsCSV(
        csvText,
        `${names.guardian.d}_${format(new Date(), "yyyyMMdd'T'HHmmss")}.csv`,
      );
    };

    return {
      isAdmin,

      columnVisibility,
      schoolYear,

      searchResult,
      numResults,
      pageNumber: searchPageNum,

      searchCondition,

      allClasses,

      onInputQueryText: (text: string) => {
        queryText.value = text;
      },
      onConfirmQueryText: () => {
        reload();
      },

      isSchoolYearPopupActive,
      changeSchoolYearPopupActiveness,
      onConfirmConditionSchoolYear,

      isClassPopupActive,
      changeClassPopupActiveness,
      onConfirmConditionClass,

      isInSchoolStatePopupActive,
      changeInSchoolStatePopupActiveness,
      onConfirmConditionInSchoolState,

      isSortPopupActive,
      changeSortPopupActiveness,
      onConfirmConditionSort,

      isNumResultsPopupActive,
      changeNumResultsPopupActiveness,
      onConfirmConditionNumResults,

      isColumnSelectOpen,
      toggleColumnSelectVisibility: () => {
        isColumnSelectOpen.value = !isColumnSelectOpen.value;
      },

      syncWithGWS,
      onClickExportButton,

      userIdToEditPath: (userId: string) => {
        return `/guardian/edit/${userId}`;
      },

      movePageTo: (n: number) => {
        searchPageNum.value = n;
        reload(n);
      },

      onChangeColumnVisibility: guardiansStore.updateColumnVisibility,
    };
  },
});
