
import { computed, defineComponent, PropType } from "vue";
import { useFileUrl } from "@/composables/provide-firebase-app";
import { isNullish } from "@/ts/utils/common-util";
import { useFileInput } from "@/composables/use-file-input";
import { DropdownItem } from "@/ts/app/dropdown-item";
import MenuDropdown from "@/components/dropdowns/MenuDropdown/MenuDropdown.vue";

export default defineComponent({
  name: "EditableItemImage",
  components: { MenuDropdown },
  props: {
    gcsUrl: { type: String, required: false },
    /**
     * 編集可能状態であればtrue。
     */
    editable: { type: Boolean, required: true },
    /**
     * trueなら、クリック時に画像を新しいタブで開く。
     */
    openOnClick: { type: Boolean, required: false, default: false },

    /**
     * 許容する画像タイプの一覧。ファイル選択を開くときに用いる。
     * 指定しなければ、画像に制限するだけで、画像の種類は問わない。
     *
     * https://www.iana.org/assignments/media-types/media-types.xhtml#image
     * から選ぶ。
     */
    acceptedImageTypes: { type: Array as PropType<string[]>, required: false },

    paddingTop: { type: Number, default: 6, required: false },
    paddingRight: { type: Number, default: 6, required: false },
    paddingBottom: { type: Number, default: 6, required: false },
    paddingLeft: { type: Number, default: 6, required: false },

    /**
     * ファイルを選択したときに呼び出される。
     * また、削除を選択したときは、引数nullで呼び出される。
     */
    onInput: {
      type: Function as PropType<(file: File | null) => void>,
      required: true,
    },
  },
  setup(props) {
    const { onChangeFileInput } = useFileInput((file: File) => {
      if (!props.editable) return;
      props.onInput(file);
    });

    const fileUrl = useFileUrl(computed(() => props.gcsUrl));

    const accept = computed<string>(() => {
      const _acceptedImageTypes = props.acceptedImageTypes;
      if (isNullish(_acceptedImageTypes) || _acceptedImageTypes.length === 0) {
        return "image/*";
      }

      return _acceptedImageTypes.map((v) => `image/${v}`).join(",");
    });

    const menuItems: DropdownItem[] = [{ value: "delete", label: "Delete" }];
    const onSelectMenuItem = (value: string | null) => {
      if (!props.editable) return;
      switch (value) {
        case "delete":
          props.onInput(null);
          break;
      }
    };

    return {
      fileUrl,
      accept,

      onChangeFileInput,

      menuItems,
      onSelectMenuItem,

      styles: {
        "--padding-top": `${props.paddingTop}px`,
        "--padding-right": `${props.paddingRight}px`,
        "--padding-bottom": `${props.paddingBottom}px`,
        "--padding-left": `${props.paddingLeft}px`,
      },
    };
  },
});
